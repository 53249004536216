const INIT_STATE = {
  getPaymentTallies: [],
  getPaymentTalliesSuccess: "",
  getPaymentTalliesError: "",

  getPaymentsList: [],
  getPaymentsListSuccess: "",
  getPaymentsListError: "",

  getNewPaymentsList: [],
  getNewPaymentsListSuccess: "",
  getNewPaymentsListError: "",

  getPaymentSummary: "",
  getPaymentSummarySuccess: "",
  getPaymentSummaryError: "",

  sendForApprovalPaymentSuccess: "",
  sendForApprovalPaymentError: "",

  movePaymentSuccess: "",
  movePaymentError: "",

  approvePaymentSuccess: "",
  approvePaymentError: "",

  declinePaymentSuccess: "",
  declinePaymentError: "",

  holdPaymentSuccess: "",
  holdPaymentError: "",

  addCommentData: [],
  addCommentSuccess: "",
  addCommentError: "",

  postPayment: "",
  postPaymentSuccess: "",
  postPaymentError: "",

  exportPayment: "",
  exportPaymentSuccess: "",
  exportPaymentError: "",

  primeEFTFile: [],
  primeEFTFileSuccess: "",
  primeEFTFileError: "",

  primePositivePay: [],
  primePositivePaySuccess: "",
  primePositivePayError: "",

  primePrintCheque: [],
  primePrintChequeSuccess: "",
  primePrintChequeError: "",

  printCheque: [],
  printChequeSuccess: "",
  printChequeError: "",

  getRemittanceSettings: [],
  getRemittanceSettingsSuccess: "",
  getRemittanceSettingsError: "",

  saveAndSendRemittance: [],
  saveAndSendRemittanceSuccess: "",
  saveAndSendRemittanceError: "",

  getPayments: [],
  getPaymentsSuccess: "",
  getPaymentsError: "",

  getPaymentDetails: [],
  getPaymentDetailsSuccess: "",
  getPaymentDetailsError: "",

  updateApprovalGroup: [],
  updateApprovalGroupSuccess: "",
  updateApprovalGroupError: "",
};

export default function (state = INIT_STATE, action) {
  switch (action.type) {
    //----------------------****Get Payments Details****---------------------
    case "GET_PAYMENT_TALLIES_INIT":
      return {
        ...state,
        getPaymentTallies: [],
        getPaymentTalliesSuccess: "",
        getPaymentTalliesError: "",
      };
    case "GET_PAYMENT_TALLIES_SUCCESS":
      return {
        ...state,
        getPaymentTallies: action.payload.paymentTallies || [],
        getPaymentTalliesSuccess: action.payload.result[0].description,
      };
    case "GET_PAYMENT_TALLIES_FAIL":
      return {
        ...state,
        getPaymentTalliesError: action.payload,
      };

    //----------------------****Get Payments****-----------------------------
    case "GET_PAYMENTS_LIST_INIT":
      return {
        ...state,
        getPaymentsList: [],
        getPaymentsListSuccess: "",
        getPaymentsListError: "",
      };
    case "GET_PAYMENTS_LIST_SUCCESS":
      return {
        ...state,
        getPaymentsList: action.payload || [],
        getPaymentsListSuccess: action.payload.result[0].description,
      };
    case "GET_PAYMENTS_LIST_FAIL":
      return {
        ...state,
        getPaymentsListError: action.payload,
      };

    // ---------Get______New____payments____List------------//

    case "GET_NEW_PAYMENTS_LIST_INIT":
      return {
        ...state,
        getNewPaymentsList: "",
        getNewPaymentsListSuccess: "",
        getNewPaymentsListError: "",
      };
    case "GET_NEW_PAYMENT_LIST_SUCCESS":
      return {
        ...state,
        getNewPaymentsList: action.payload || "",
        getNewPaymentsListSuccess: action.payload.result[0].description,
      };

    case "GET_NEW_PAYEMENT_LIST_FAIL":
      return {
        ...state,
        getNewPaymentsListError: action.payload,
      };

    //----------------------****Get Payments Details****---------------------
    case "GET_PAYMENT_SUMMARY_INIT":
      return {
        ...state,
        getPaymentSummary: "",
        getPaymentSummarySuccess: "",
        getPaymentSummaryError: "",
      };

    case "GET_PAYMENTS_SUMMARY_SUCCESS":
      return {
        ...state,
        getPaymentSummary: action.payload.paymentSummary,
        getPaymentSummarySuccess: action.payload.result[0].description,
      };

    case "GET_PAYMENTS_SUMMARY_FAIL":
      return {
        ...state,
        getPaymentSummaryError: action.payload,
      };

    //----------------------****Send For Approval DistChange****-----------------------------
    case "SEND_FOR_APPROVAL_PAYMENT_INIT":
      return {
        ...state,
        sendForApprovalPaymentSuccess: "",
        sendForApprovalPaymentError: "",
      };
    case "SEND_FOR_APPROVAL_PAYMENT_SUCCESS":
      return {
        ...state,
        sendForApprovalPaymentSuccess: action.payload.result[1].description,
      };
    case "SEND_FOR_APPROVAL_PAYMENT_FAIL":
      return {
        ...state,
        sendForApprovalPaymentError: action.payload,
      };

    //----------------------****Move Payment****-----------------------------
    case "MOVE_PAYMENT_INIT":
      return {
        ...state,
        movePaymentSuccess: "",
        movePaymentError: "",
      };
    case "MOVE_PAYMENT_SUCCESS":
      return {
        ...state,
        movePaymentSuccess: action.payload.result[0].description,
      };
    case "MOVE_PAYMENT_FAIL":
      return {
        ...state,
        movePaymentError: action.payload,
      };

    //----------------------****Move Payment****-------------------------
    case "APPROVE_PAYMENT_INIT":
      return {
        ...state,
        approvePaymentSuccess: "",
        approvePaymentError: "",
      };
    case "APPROVE_PAYMENT_SUCCESS":
      return {
        ...state,
        approvePaymentSuccess: action.payload.result[0].description,
      };
    case "APPROVE_PAYMENT_FAIL":
      return {
        ...state,
        approvePaymentError: action.payload,
      };

    //----------------------****Decline Payment****-------------------------
    case "DECLINE_PAYMENT_INIT":
      return {
        ...state,
        declinePaymentSuccess: "",
        declinePaymentError: "",
      };
    case "DECLINE_PAYMENT_SUCCESS":
      return {
        ...state,
        declinePaymentSuccess: action.payload.result[0].description,
      };
    case "DECLINE_PAYMENT_FAIL":
      return {
        ...state,
        declinePaymentError: action.payload,
      };

    //----------------------****Hold Payment****-------------------------
    case "HOLD_PAYMENT_INIT":
      return {
        ...state,
        holdPaymentSuccess: "",
        holdPaymentError: "",
      };
    case "HOLD_PAYMENT_SUCCESS":
      return {
        ...state,
        holdPaymentSuccess: action.payload.result[0].description,
      };
    case "HOLD_PAYMENT_FAIL":
      return {
        ...state,
        holdPaymentError: action.payload,
      };

    //----------------------****Add Invoice Comment****-----------------------------
    case "ADD_PAYMENT_COMMENT_INIT":
      return {
        ...state,
        addCommentData: [],
        addCommentSuccess: "",
        addCommentError: "",
      };
    case "ADD_PAYMENT_COMMENT_SUCCESS":
      return {
        ...state,
        addCommentData: action.payload.comments || [],
        addCommentSuccess: action.payload.result[0].description,
      };
    case "ADD_PAYMENT_COMMENT_FAIL":
      return {
        ...state,
        addCommentError: action.payload,
      };

    //----------------------****Post DistChange****-----------------------------
    case "POST_PAYMENT_INIT":
      return {
        ...state,
        postPayment: "",
        postPaymentSuccess: "",
        postPaymentError: "",
      };
    case "POST_PAYMENT_SUCCESS":
      return {
        ...state,
        postPayment: action.payload,
        postPaymentSuccess: action.payload.result[0].description,
      };
    case "POST_PAYMENT_FAIL":
      return {
        ...state,
        postPaymentError: action.payload,
      };

    //----------------------****Export Payment****-----------------------------
    case "EXPORT_PAYMENT_INIT":
      return {
        ...state,
        exportPayment: "",
        exportPaymentSuccess: "",
        exportPaymentError: "",
      };
    case "EXPORT_PAYMENT_SUCCESS":
      return {
        ...state,
        exportPayment: action.payload.export || "",
        exportPaymentSuccess: action.payload.result[0].description,
      };
    case "EXPORT_PAYMENT_FAIL":
      return {
        ...state,
        exportPaymentError: action.payload,
      };

    //----------------------****Prime EFT Payment****-----------------------------
    case "GET_REMITTANCE_SETTING_INIT":
      return {
        ...state,
        getRemittanceSettings: [],
        getRemittanceSettingsSuccess: "",
        getRemittanceSettingsError: "",
      };
    case "GET_REMITTANCE_SETTING_SUCCESS":
      return {
        ...state,
        getRemittanceSettings: action.payload.remittanceSettings || "",
        getRemittanceSettingsSuccess: action.payload.result[0].description,
      };
    case "GET_REMITTANCE_SETTING_FAIL":
      return {
        ...state,
        getRemittanceSettingsError: action.payload,
      };

    //----------------------****Save And Send Remittances Payment****-----------------------------
    case "SAVE_AND_SEND_REMITTANCES_INIT":
      return {
        ...state,
        saveAndSendRemittances: [],
        saveAndSendRemittancesSuccess: "",
        saveAndSendRemittancesError: "",
      };
    case "SAVE_AND_SEND_REMITTANCES_SUCCESS":
      return {
        ...state,
        saveAndSendRemittance: action.payload.remittanceSettings || "",
        saveAndSendRemittanceSuccess: action.payload.result[0].description,
      };
    case "SAVE_AND_SEND_REMITTANCES_FAIL":
      return {
        ...state,
        saveAndSendRemittanceError: action.payload,
      };

    //----------------------****Prime EFT Payment****-----------------------------
    case "PRIME_EFT_FILE_INIT":
      return {
        ...state,
        primeEFTFile: [],
        primeEFTFileSuccess: "",
        primeEFTFileError: "",
      };
    case "PRIME_EFT_FILE_SUCCESS":
      return {
        ...state,
        primeEFTFile: action.payload.EFTGenerator || "",
        primeEFTFileSuccess: action.payload.result[0].description,
      };
    case "PRIME_EFT_FILE_FAIL":
      return {
        ...state,
        primeEFTFileError: action.payload,
      };
    //----------------------****primePositivePay Payment****-----------------------------

    case "PRIME_POSITIVE_PAY_INIT":
      return {
        ...state,
        primePositivePay: [],
        primePositivePaySuccess: "",
        primePositivePayError: "",
      };
    case "PRIME_POSITIVE_PAY_SUCCESS":
      return {
        ...state,
        primePositivePay: action.payload.positivePayOptions || "",
        primePositivePaySuccess: action.payload.result[0].description,
      };
    case "PRIME_POSITIVE_PAY_FAIL":
      return {
        ...state,
        primePositivePayError: action.payload,
      };

    //----------------------****primePrintCheque Payment****-----------------------------

    case "PRIME_PRINT_CHEQUE_INIT":
      return {
        ...state,
        primePrintCheque: [],
        primePrintChequeSuccess: "",
        primePrintChequeError: "",
      };
    case "PRIME_PRINT_CHEQUE_SUCCESS":
      return {
        ...state,
        primePrintCheque: action.payload.chequePrintOptions || "",
        primePrintChequeSuccess: action.payload.result[0].description,
      };
    case "PRIME_PRINT_CHEQUE_FAIL":
      return {
        ...state,
        primePrintChequeError: action.payload,
      };

    //----------------------****printCheque Payment****-----------------------------
    case "PRINT_CHEQUE_INIT":
      return {
        ...state,
        printCheque: [],
        printChequeSuccess: "",
        printChequeError: "",
      };
    case "PRINT_CHEQUE_SUCCESS":
      return {
        ...state,
        primePrintCheque: action.payload.previewList || "",
        primePrintChequeSuccess: action.payload.result[0].description,
      };
    case "PRINT_CHEQUE_FAIL":
      return {
        ...state,
        primePrintChequeError: action.payload,
      };

    //----------------------****Get Payments****-----------------------------
    case "GET_PAYMENTS_INIT":
      return {
        ...state,
        getPayments: [],
        getPaymentsSuccess: "",
        getPaymentsError: "",
      };
    case "GET_PAYMENTS_SUCCESS":
      return {
        ...state,
        getPayments: action.payload.payments || [],
        getPaymentsSuccess: action.payload.result[0].description,
      };
    case "GET_PAYMENTS_FAIL":
      return {
        ...state,
        getPaymentsError: action.payload,
      };
    //----------------------****Get Payments Details****-----------------------------
    case "GET_PAYMENT_DETAILS_INIT":
      return {
        ...state,
        getPaymentDetails: [],
        getPaymentDetailsSuccess: "",
        getPaymentDetailsError: "",
      };
    case "GET_PAYMENT_DETAILS_SUCCESS":
      return {
        ...state,
        getPaymentDetails: action.payload.paymentDetails || [],
        getPaymentDetailsSuccess: action.payload.result[0].description,
      };
    case "GET_PAYMENT_DETAILS_FAIL":
      return {
        ...state,
        getPaymentDetailsError: action.payload,
      };
    //----------------------****Update Approval Group ****-----------------------------
    case "UPDATE_APPROVAL_GROUP_INIT":
      return {
        ...state,
        updateApprovalGroup: [],
        updateApprovalGroupSuccess: "",
        updateApprovalGroupError: "",
      };
    case "UPDATE_APPROVAL_GROUP_SUCCESS":
      return {
        ...state,
        updateApprovalGroup: action.paymentResponse || [],
        updateApprovalGroupSuccess: action.payload.result[0].description,
      };
    case "UPDATE_APPROVAL_GROUP_FAIL":
      return {
        ...state,
        updateApprovalGroupError: action.payload,
      };
    //----------------------****Clear States When Producton Login****--------------------
    case "CLEAR_STATES_WHEN_LOGIN_PRODUCTION":
      //when user login production on dashboard then remove data of previous Production
      return {
        ...state,
        getPayments: [],
        getPaymentsSuccess: "",
        getPaymentsError: "",

        getPaymentDetails: [],
        getPaymentDetailsSuccess: "",
        getPaymentDetailsError: "",
      };
    //----------------------****Clear States After Logout****-----------------------------
    case "CLEAR_STATES_AFTER_LOGOUT":
      return {
        ...state,
        getPayments: [],
        getPaymentsSuccess: "",
        getPaymentsError: "",

        getPaymentDetails: [],
        getPaymentDetailsSuccess: "",
        getPaymentDetailsError: "",
      };
    //----------------------****Clear Payments States****-----------------------------
    case "CLEAR_PAYMENT_STATES":
      return {
        ...state,
        getPaymentTallies: [],
        getPaymentTalliesSuccess: "",
        getPaymentTalliesError: "",

        getPaymentsList: [],
        getPaymentsListSuccess: "",
        getPaymentsListError: "",

        getPaymentSummary: "",
        getPaymentSummarySuccess: "",
        getPaymentSummaryError: "",

        sendForApprovalPaymentSuccess: "",
        sendForApprovalPaymentError: "",

        movePaymentSuccess: "",
        movePaymentError: "",

        getPayments: [],
        getPaymentsSuccess: "",
        getPaymentsError: "",

        getPaymentDetails: [],
        getPaymentDetailsSuccess: "",
        getPaymentDetailsError: "",
      };
    default:
      return state;
  }
}
