import React, { useState } from "react";
import { Modal, Button, ListGroup } from 'react-bootstrap';
import './ErrorModal.css';

/**
 * Render the blockers.
 * @param {Array.<string>} blockers
 * @returns {JSX.Element}
 */
const renderBlockers = (blockers) => {
  return (
    <ListGroup variant="flush" as="ul">
        {blockers.map((blocker, index) => (
            <ListGroup.Item key={index} as="li" className="no-border">
                <li className="list">{blocker}</li>
            </ListGroup.Item>
        ))}
    </ListGroup>
);
}

/**
 * ErrorModal component.
 * @param {ErrorModalProps} props - The props for the component.
 * @returns {JSX.Element} The rendered component.
 */
const ErrorModal = ({
    onClose,
    errorMessages = [],
    errorTitle = "Warning",
}) => {

    const [isShown, setIsShown] = useState(true);

    return (
      <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={isShown}
          onHide={() => setIsShown(false)}
      >
          <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter" className="text-warning">
                  {errorTitle}
              </Modal.Title>
          </Modal.Header>
          <Modal.Body>
              {errorMessages.map((error, index) => (
                  <div key={index}>
                      <p>{error.title}. {error.solution}</p>
                      {renderBlockers(error.blockers)}
                  </div>
              ))}
          </Modal.Body>
          <Modal.Footer>
              <Button onClick={() => { setIsShown(false); onClose(); }}>Close</Button>
          </Modal.Footer>
      </Modal>
  );
};

export default ErrorModal;

/**
 * Processes error messages and returns an array of ErrorMessage objects.
 * @param {Array.<{ description: string, status: string }>} errors - The array of error objects to process.
 * @returns {Array.<ErrorMessage>} The processed array of ErrorMessage objects.
 */
export const getErrorMessages = (errors) => {
    return errors
        .map(error => {
            const description = error.description;
            let title = description;
            let blockers = [];
            let solution = "";

            const followingIndex = description.indexOf("following");
            const colonIndex = description.indexOf(":");

            if (followingIndex !== -1 && colonIndex !== -1) {
                title = description.split(":")[0]?.trim();
                const blockersString = description.split(":").pop();
                const blockersAndReason = blockersString.split(",");
                solution = blockersAndReason[blockersAndReason.length - 1].split('.')[1]?.trim();
                blockers = blockersAndReason.map(
                    blocker => blocker.split('.')[0]?.trim()
                );
            }

            return {
                title,
                blockers,
                solution
            };
        });
}
/**
 * @typedef {Object} ErrorMessage
 * @property {string} title
 * @property {Array.<string>} blockers
 * @property {string} solution
 */

/**
 * Props for the ErrorModal component.
 * @typedef {Object} ErrorModalProps
 * @property {Function} onClose - Function to call when the modal is closed.
 * @property {ErrorMessage[]} errorMessages - Array of error messages.
 * @property {string} errorTitle - Title of the error modal.
 */