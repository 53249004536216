import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import "./TransferRecords.css";
import $ from "jquery";
import TopNav from "../../Common/TopNav/TopNav";
import "../../../Containers/Setup/SetupSASS/setupStyle.scss";

import { handleAPIErr, tableSettingTest } from "../../../Utils/Helpers";

import {
  primeTransferTransactions,
  clearUserStates,
} from "../../../Actions/UserActions/UserActions";
import { BulkTransferRecords } from "../../Modals/BulkTransferRecords/BulkTransferRecords";
import AdminHeader from "../../Common/AdminHeader/AdminHeader";
import VideoTutorials from "../../Common/Alerts/VideoTutorials";

class TransferRecords extends Component {
  constructor() {
    super();
    this.state = {
      columns: [], //column headings
      userList: [], //users lists
      pageLength: 10,
      backgroundImg: "",
      selectedUserTally: null,
      showBulkTransferModal: false,
      jqueryTableID: "example2",
    };
  }
  async componentDidMount() {
    document.body.style.zoom = "85%"; // temporary fix for the zooming issue of setup pages

    await this.setState({
      themeColor: this.props.user.setting.Color || "",
      backgroundImg: this.props.user.setting.uploadBackgroundImage || "",
    });

    const root = document.documentElement;
    root.style.setProperty("--user-setup-bg-color", this.state.themeColor);
    root.style.setProperty("--background-Image", this.state.backgroundImg);

    await this.getUsersList();
  }

  // async componentDidUpdate(prevProps,prevState) {
  //   if (this.state.pasteUserSuccess !== prevState.pasteUserSuccess) {
  //     await this.getUsersList();
  //   }
  // }
  componentWillUnmount() {
    document.body.style.zoom = "67%"; // Revert the temporary fix for the zooming issue of setup pages
  }

  //user setup popup
  userSetupTableSetting = () => {
    window.$("#usersteup-modal").DataTable({
      dom: "Rlfrtip",
      // stateSave: true,
      // stateSaveCallback: function (settings, data) {
      //   localStorage.setItem('DataTables_usersteup-modal', JSON.stringify(data))
      // },
      // stateLoadCallback: function (settings) {
      //   return JSON.parse(localStorage.getItem('DataTables_' + settings.sInstance))
      // },
      language: {
        searchPlaceholder: "Search",
      },
      searching: false,
      paging: false,
      info: false,
      columnDefs: [
        {
          targets: -1,
          orderable: false,
        },
      ],
      order: [[1, "asc"]],
      colReorder: {
        fixedColumnsRight: 5,
        fixedColumnsLeft: 5,
      },
    });
  };
  //main user list table
  tableSetting = () => {
    let { columns } = this.state;
    let aoColumns = [];

    //adding the column names
    aoColumns[0] = { sName: "placeholder" };
    columns.map((c) => aoColumns.push({ sName: c.name }));
    aoColumns.push({ sName: "endPlaceholder" });

    let result = tableSettingTest(columns, aoColumns, "example2");
    this.setState({ ...result });
  };
  //get users list
  getUsersList = async () => {
    this.setState({
      isLoading: true,
    });
    await this.props.primeTransferTransactions();
    //success case of get users list
    if (this.props.user.primeTransferTransactionSuccess) {
      this.setState(
        {
          columns: [
            {
              name: "User Login",
              field: "userLogin",
            },
            {
              name: "Orders",
              field: "orderTally",
            },
            {
              name: "Invoices",
              field: "invoiceTally",
            },
            {
              name: "Expenses",
              field: "expenseTally",
            },
            {
              name: "Documents",
              field: "documentTally",
            },
            {
              name: "Timecards",
              field: "timecardTally",
            },
          ],
          userList: this.props.user.primeTransferTransaction,
        },
        () => this.tableSetting()
      );
    }
    //error case of get users list
    if (this.props.user.primeTransferTransactionError) {
      handleAPIErr(this.props.user.primeTransferTransactionError, this.props);
    }
    this.props.clearUserStates();
    this.setState({ isLoading: false });
  };

  render() {
    let { themeColor } = this.state;
    return (
      <>
        {this.state.isLoading ? <div className="se-pre-con"></div> : ""}
        <div
          style={{ backgroundImage: "url(" + this.state.backgroundImg + ")" }}
          className="user_setup_main"
        >
          <header>
            <TopNav sideBarZoomLevel={"85%"} />
            <AdminHeader title="Transfer Records" />
            <VideoTutorials />
          </header>
          <div className="col-sm-12 table_white_box table_white_box_sass">
            <div className="user_setup_plus_Icons">
              <ul>
                <li>
                  <div></div>
                </li>
              </ul>
            </div>
            {/* new tale add start */}
            <table
              id="example2"
              className=" user_setup_table user_setup_table_sass"
              width="100%"
            >
              <thead style={{ backgroundColor: themeColor }}>
                <tr>
                  <th>
                    <span></span>
                  </th>
                  <th>
                    <span className="user_setup_hed">User Login</span>
                  </th>
                  <th>
                    <span className="user_setup_hed">Orders</span>
                  </th>
                  <th>
                    <span className="user_setup_hed">Invoices</span>
                  </th>
                  <th>
                    <span className="user_setup_hed">Expenses</span>
                  </th>
                  <th>
                    <span className="user_setup_hed">Documents</span>
                  </th>
                  <th>
                    <span className="user_setup_hed">Timecards</span>
                  </th>
                  <th>
                    <span></span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {this.state.userList?.map((u, i) => {
                  return (
                    <tr
                      key={u.userLogin}
                      onClick={(e) => {
                        this.setState({
                          selectedUserTally: u,
                          showBulkTransferModal: true,
                        });
                      }}
                      className="cursorPointer"
                    >
                      <td></td>
                      <td>{u.userLogin}</td>
                      <td>{u.orderTally}</td>
                      <td>{u.invoiceTally}</td>
                      <td>{u.expenseTally}</td>
                      <td>{u.documentTally}</td>
                      <td>{u.timecardTally}</td>
                      <td>
                        <span></span>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            {/* end new table */}
          </div>
        </div>

        {this.state.showBulkTransferModal && (
          <BulkTransferRecords
            closeModal={() => this.setState({ showBulkTransferModal: false })}
            userTallyRecord={this.state.selectedUserTally}
            userList={this.state.userList}
            onSuccess={this.getUsersList}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
});
export default connect(mapStateToProps, {
  primeTransferTransactions,
  clearUserStates,
})(TransferRecords);
