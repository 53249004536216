import React, { useState, useEffect, Component } from "react";
import Modal from "react-bootstrap/Modal";
import $ from "jquery";
import Dropdown from "react-bootstrap/Dropdown";
import Select from "react-select";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import { _customStyles } from "../../../../../Constants/Constants";
import { useSelector, useDispatch, connect } from "react-redux";
import {
  handleAPIErr,
  toBase64,
  handleValueOptions,
  handleHideUnhideRows,
} from "../../../../../Utils/Helpers";
import * as Validation from "../../../../../Utils/Validation";
import * as AccountActions from "../../../../../Actions/AccountActions/AccountActions";

const uuidv1 = require("uuid/v1");
class CreateFinancialLayoutModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addNewReportToggle: false,
      reportTypesVal: { label: "", value: "" },
      reportTypes: [],
      reportOptionVal: { label: "", value: "" },
      reportOptions: [],
      layouts: "",
      menuIsOpen: false,
      reportFile: "",
      reportName: "",
      privateCheck: false,
      showHiddenRows: false,
      reportSettings: [],
      clonedReportSettings: [],
      reDrawDT: false, //re draw datatable
      formErrors: {
        reportTypesVal: "",
        reportOptionVal: "",
        reportFile: "",
        reportName: "",
      },
    };
  }
  componentWillReceiveProps() {
    let { reportOptions, reportOptionVal, layouts } = this.props;
    this.setState({ reportOptions, reportOptionVal });
  }
  // handleReportTypes = (type) => {
  //   let { formErrors } = this.state;

  //   formErrors = Validation.handleValidation(
  //     "reportTypesVal",
  //     type.value,
  //     formErrors
  //   );

  //   let areaRef = type.value || "";

  //   let getReportOptions = reportState.getReportOptions || [];
  //   reportOptions = [];

  //   let found = getReportOptions.find((rop) => rop.areaRef === areaRef);
  //   if (found) {
  //     let reportLayouts = found.reportLayouts || [];

  //     reportLayouts.map((l, i) => {
  //       reportOptions.push({
  //         label: l.layout,
  //         value: l.id,
  //         locked: l.locked,
  //       });
  //     });
  //   }
  //   this.setState((prev) => ({
  //     ...prev,
  //     reportTypesVal: type,
  //     reportOptionVal: { label: "", value: "" },
  //     reportOptions,
  //     formErrors,
  //   }));
  // };
  handleReportOptions = (layout) => {
    let { formErrors } = this.state;

    formErrors = Validation.handleValidation(
      "reportOptionVal",
      layout.value,
      formErrors
    );

    this.setState((prev) => ({
      ...prev,
      reportOptionVal: layout,
      addNewReportToggle: false,
      reportFile: "",
      reportName: "",
      private: false,
      formErrors,
    }));
  };

  handleFieldChange = async (e) => {
    let { name, value } = e.target;
    let { formErrors } = this.state;

    formErrors = Validation.handleValidation("reportName", value, formErrors);
    this.setState((prev) => ({ ...prev, [name]: value, formErrors }));
  };

  menuOpened = async () => {
    this.setState((prev) => ({ ...prev, menuIsOpen: true }));
    $(document).ready(async function () {
      $('<i class="fa fa-trash report--del"></i>').appendTo(
        ".report_menu.custon_select-selector-inner__option"
      );
      $(".report_menu.custon_select-selector-inner__option i").on(
        "click",
        async function (e) {
          e.preventDefault();
          var p_id = $(this).parent().attr("id");
          let id_split = p_id && p_id.split("-"); //["react", "select", "3", "option", "1"]
          let id = id_split[id_split.length - 1];

          let { reportOptions } = this.qstate;
          let rep_opt = reportOptions.length > 0 && reportOptions[id];
          //call delete report options API
        }
      );
    });
  };
  menuClosed = () => {
    this.setState((prev) => ({ ...prev, menuIsOpen: false }));
  };
  clearStates = () => {
    this.setState({
      addNewReportToggle: false,
      reportTypesVal: { label: "", value: "" },
      reportTypes: [],
      reportOptionVal: { label: "", value: "" },
      reportOptions: [],
      menuIsOpen: false,
      reportFile: "",
      reportName: "",
      privateCheck: false,
      reportSettings: [],
      showHiddenRows: false,
      formErrors: {
        reportTypesVal: "",
        reportOptionVal: "",
        reportFile: "",
        reportName: "",
      },
    });
    this.props.closeModal("openAccountReportModal");
  };
  onSaveReport = async () => {
    this.setState({ isLoading: true });
    let { reportOptionVal } = this.state;

    let data = {
      inherit: reportOptionVal.value,
      layout: this.state.layouts,
    };
    if (data.inherit != "") {
      await this.props.createFinancialLayout(data);
      if (this.props.account.createFinancialLayoutSuccess) {
        toast.success(this.props.account.createFinancialLayoutSuccess);
        await this.props.getLayoutOptions();

        this.setState({ layouts: "" });
        this.props.closeModal("openAccountReportModal");
      }
      if (this.props.account.createFinancialLayoutError) {
        handleAPIErr(this.props.account.createFinancialLayoutError, this.props);
      }
    } else {
      toast.error("Select a Report Option First");
    }
    this.setState({ isLoading: false });
  };

  render() {
    let {
      reportOptionVal,
      reportOptions,

      reportName,
      formErrors,
    } = this.state;
    return (
      <>
        {this.state.isLoading ? <div className="se-pre-con"></div> : ""}

        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={this.props.openAccountReportModal}
          onHide={this.clearStates}
          className="modal_704 mx-auto reports-v4-modal"
        >
          <Modal.Body>
            <div className="container-fluid p-0">
              <div className="main_wrapper">
                <div className="row d-flex h-100 p-0">
                  <div className="col-12 justify-content-center align-self-center">
                    <div className="setting_form_main p-0">
                      <div className="setting_header thead_bg">
                        <h3 className="Indirecttaxcode-poup_heading">
                          Create Layout
                        </h3>
                        <div className="Indirecttaxcode-poup_can-sav-btn">
                          <button
                            onClick={this.onSaveReport}
                            className="btn can-btn1"
                          >
                            Save
                          </button>
                        </div>
                      </div>
                      <div className="reportv4-modal-inner">
                        <div className="row mt-4">
                          <div className="col-md-12">
                            <div className="modal_input">
                              <label>Type your new Layout</label>
                              <input
                                type="text"
                                className="form-control pl-0"
                                id="usr"
                                name="layouts"
                                value={this.state.layouts}
                                onChange={this.handleFieldChange}
                              />
                              <div className="text-danger error-12">
                                {formErrors.reportName !== ""
                                  ? formErrors.reportName
                                  : ""}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="form-group col-md-12">
                            <div className="custon_select">
                              <label>Select Report Option</label>

                              <Select
                                className="width-selector"
                                closeMenuOnSelect={true}
                                value={reportOptionVal}
                                classNamePrefix="report_menu custon_select-selector-inner"
                                onMenuOpen={this.menuOpened}
                                onMenuClose={this.menuClosed}
                                onChange={this.handleReportOptions}
                                options={reportOptions}
                                theme={(theme) => ({
                                  ...theme,
                                  border: 0,
                                  borderRadius: 0,
                                  colors: {
                                    ...theme.colors,
                                    primary25: "#f2f2f2",
                                    primary: "#f2f2f2",
                                  },
                                })}
                              />
                              <div className="text-danger error-12">
                                {formErrors.reportOptionVal !== ""
                                  ? formErrors.reportOptionVal
                                  : ""}
                              </div>
                            </div>
                          </div>
                          {/* {addNewReportToggle && (
                            <div className=" pl-3 pr-3 w-100">
                              <div className="row">
                                <div className="col-12">
                                  <div className="form-group custon_select mt-3">
                                    <div className="modal_input">
                                      <label>Report Name</label>
                                      <input
                                        type="text"
                                        className="form-control pl-0"
                                        id="usr"
                                        name="reportName"
                                        value={reportName}
                                        // onChange={handleFieldChange}
                                      />
                                      <div className="text-danger error-12">
                                        {formErrors.reportName !== ""
                                          ? formErrors.reportName
                                          : ""}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-12 align-self-center mb-2 supp2-roprt-check">
                                  <div className="form-group remember_check">
                                    <input
                                      type="checkbox"
                                      id="remember"
                                      checked={privateCheck}
                                      // onChange={(e) => handleReportCheckbox(e)}
                                    />
                                    <label htmlFor="remember"></label>
                                    <p className="reports-excel rc-lineheight">
                                      Private:
                                    </p>
                                  </div>
                                </div>
                                <div className="col-12 mt-2 mb-2">
                                  <div className="form-group custon_select  text-center mb-0 border-rad-5">
                                    <div id="drop-area-report">
                                      <input
                                        type="file"
                                        id="fileElem-rep"
                                        className="form-control d-none"
                                        accept="application/pdf"
                                        onChange={(e) => {
                                          // uploadReportFile(e.target.files);
                                        }}
                                        onClick={(event) => {
                                          event.currentTarget.value = null;
                                        }} //to upload the same file again
                                      />

                                      <label
                                        className="upload-label"
                                        htmlFor="fileElem-rep"
                                      >
                                        <div className="upload-text">
                                          <img
                                            src="images/drag-file.png"
                                            className="import_icon img-fluid"
                                            alt="upload-report"
                                          />
                                        </div>
                                      </label>
                                    </div>
                                  </div>
                                  <div className="text-danger error-12">
                                    {formErrors.reportFile !== ""
                                      ? formErrors.reportFile
                                      : ""}
                                  </div>
                                </div>
                              </div>
                            </div>
                          )} */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  account: state.account,
});
export default connect(mapStateToProps, {
  createFinancialLayout: AccountActions.createFinancialLayout,
})(CreateFinancialLayoutModal);
