import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { getUsersList, primeTransferTransactions } from "../../../Actions/UserActions/UserActions";

export const TransferRecords = ({ closeModal, selectedItemCount, itemNameList, onSubmit }) => {
    const dispatch = useDispatch();
    const userList = useSelector(state => state.user.primeTransferTransaction);
    const [selectedUser, setSelectedUser] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);

    useEffect(() => {
        if (!userList){
            fetchUserList();
        }
    }, []);

    const fetchUserList = async () => {
        setIsLoading(true);
        await dispatch(primeTransferTransactions())
        setIsLoading(false);
    }

    const handleSubmit = () => {
        if (selectedUser) {
            onSubmit(selectedUser.value);
            closeModal();
        }
    }

    return (
        <>
            {isLoading ? <div className="se-pre-con"></div> : ""}
            <Modal
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show
                // onHide={clearStates}
                className="forgot_email_modal modal_704 mx-auto"
            >
                <Modal.Body>
                    <div className="container-fluid ">
                        <div className="main_wrapper p-10">
                            <div className="row d-flex h-100">
                                <div className="col-12 justify-content-center align-self-center form_mx_width">
                                    <div className="forgot_form_main">
                                        <div className="forgot_header">
                                            <div className="modal-top-header">
                                                <div className="row bord-btm">
                                                    <div className="col-auto pl-0 d-flex align-items-end">
                                                        <h6 className="text-left def-blue mr-2">Transfer Items</h6>
                                                        <span>{`(${selectedItemCount ?? 'All'} record${!selectedItemCount || selectedItemCount > 1 ? 's' : ''})`}</span>
                                                    </div>

                                                    <div className="col d-flex justify-content-end s-c-main">
                                                        <button
                                                            disabled={!selectedUser}
                                                            onClick={() => {
                                                                if (showConfirmation) {
                                                                    handleSubmit();
                                                                } else if (selectedUser){
                                                                    setShowConfirmation(true);
                                                                }
                                                            }}
                                                            type="button"
                                                            className="btn-save"
                                                        >
                                                            <span className="fa fa-check"></span>
                                                            {showConfirmation ? 'SAVE' : 'OK'}
                                                        </button>
                                                        <button
                                                            onClick={() => closeModal("openMoveModal")}
                                                            type="button"
                                                            className="btn-save"
                                                        >
                                                            <span className="fa fa-ban"></span>
                                                            Discard
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="forgot_body px-3">
                                            <div className="row mt-4">
                                                <div className="col-md-12">
                                                    {!showConfirmation && <div className="form-group custon_select">
                                                        <label>New user</label>
                                                        <Select
                                                            className="width-selector"
                                                            value={selectedUser}
                                                            classNamePrefix="custon_select-selector-inner"
                                                            options={userList ? userList?.map(u => ({ label: u.userLogin, value: u.userLogin })) : []}
                                                            onChange={(u) => setSelectedUser(u)}
                                                            theme={(theme) => ({
                                                                ...theme,
                                                                border: 0,
                                                                borderRadius: 0,
                                                                colors: {
                                                                    ...theme.colors,
                                                                    primary25: "#f2f2f2",
                                                                    primary: "#f2f2f2",
                                                                },
                                                            })}
                                                        />
                                                    </div>}

                                                    {showConfirmation && <div>
                                                        <span>
                                                            {selectedItemCount ? 'The following records will be transferred' : 'All the records will be transferred'} to {selectedUser.value}.
                                                        </span>

                                                        {itemNameList && <div className="d-flex flex-column mt-3" style={{maxHeight: '400px', overflowX: 'auto'}}>
                                                            {itemNameList.map(name => <span key={name}>
                                                                - {name}
                                                            </span>)}
                                                        </div>}
                                                    </div>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}
