import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import Select from "react-select";
import { options, _customStyles } from "../../../../../Constants/Constants";
import DatePicker from "react-datepicker";
import { connect } from "react-redux";
import * as AccountActions from "../../../../../Actions/AccountActions/AccountActions";
import * as Validation from "../../../../../Utils/Validation";
import $ from "jquery";
import {
  addDragAndDropFileListners,
  handleAPIErr,
  handleHideUnhideRows,
  toBase64,
  handleValueOptions,
  downloadAttachments,
} from "../../../../../Utils/Helpers";
import { toast } from "react-toastify";
// import $ from "jquery";

class CancelReissueModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,

      reportTypes: [],
      reportOptionVal: [{ label: "Select Report Option", value: "" }],
      reportOptions: [],

      advancedOptions: [],
      clonneAdvanceOptions: [],
      newRefId: "",
      showHiddenRows: false,

      reDrawDT: false, //re draw datatable
      companyOptVal: [],
      companyOpt: [],
      companyOptions: [],

      reportTypeValue: [{ label: "Select Report Type", value: "" }],
      reportLayouts: [],
      reportLayoutsVal: [],

      formErrors: {
        reportTypesVal: "",
        reportOptionVal: "",
        reportFile: "",
        reportName: "",
      },

      addNewReportToggle: false,

      menuIsOpen: false,
      reportFile: "",
      reportName: "",
      privateCheck: false,
    };
  }

  componentWillReceiveProps() {
    let {
      reportTypeValue,
      reportOptionVal,
      advancedOptions,
      clonneAdvanceOptions,
      reportTypes,
      reportLayouts,
      reportLayoutsVal,
      cancelReissue,
      newRefId,
    } = this.props.state;

    this.setState({
      reportTypeValue,
      reportOptionVal,
      advancedOptions,
      clonneAdvanceOptions,
      reportTypes,
      reportLayouts,
      reportLayoutsVal,
      newRefId,
      cancelReissue,
    });
  }

  getCancelReIssueCheque = async (name) => {
    this.setState({ isLoading: true });

    // this.state.cancelReissue.map((item) => {
    //   if (this.state.advancedOptions) {
    //     item.advancedOptions = this.state.advancedOptions;
    //   }
    //   return item;
    // });
    let bankCode = document.getElementsByClassName(
      "custon_select-selector-inner__single-value css-1uccc91-singleValue"
    )[0].innerText;
    let data = {
      bankCode: bankCode,
      guidList: this.state.newRefId,
      cancelReIssue: this.state.cancelReissue,
    };
    await this.props.getCancelReIssueCheque(data);

    if (this.props.account.getCancelReIssueChequeSuccess) {
      if (this.props.account?.getCancelReIssueChequeSuccess[1]?.description) {
        toast.success(
          this.props.account?.getCancelReIssueChequeSuccess[1]?.description
        );
      }
      if (this.props.account?.getCancelReIssueChequeSuccess[0]?.description) {
        toast.success(
          this.props.account?.getCancelReIssueChequeSuccess[0]?.description
        );
      }
      let attachment =
        this?.props?.account?.getCancelReIssueCheque?.attachment || [];
      let fileName =
        this?.props?.account?.getCancelReIssueCheque?.fileName || [];
      let resp = {
        attachment: attachment,
        contentType: "application/pdf",
        fileName: fileName,
      };
      if (attachment !== "") {
        downloadAttachments(resp, fileName);
      } else {
        toast.error("Attachment Not Found.");
      }
      this.props.closeModal(name);
    }
    if (this.props.account.getCancelReIssueChequeError) {
      handleAPIErr(this.props.account.getCancelReIssueChequeError, this.props);
    }
    console.log(this.state.isLoading);
    this.setState({ isLoading: false });
  };
  _handleValueOptions = async (type, val, item, index) => {
    let { advancedOptions, clonneAdvanceOptions } = this.state;
    let result = handleValueOptions(
      type,
      val,
      item,
      index,
      advancedOptions,
      clonneAdvanceOptions
    );
    this.setState({
      ...this.state,
      advancedOptions: result.advancedList,
      clonneAdvanceOptions: result.clonedAdvancedList,
    });
  };
  _handleHideUnhideRows = (item) => {
    let { advancedOptions, clonneAdvanceOptions, showHiddenRows } = this.state;

    let result = handleHideUnhideRows(
      item,
      "#reportv4-table",
      "reports__settings",
      advancedOptions,
      clonneAdvanceOptions,
      showHiddenRows
    );

    this.setState({
      advancedOptions: result.advancedList,
      clonneAdvanceOptions: result.clonedAdvancedList,
      showHiddenRows: result.showHiddenRows,
      reDrawDT: true,
    });
  };

  handleShowHiddenRows = async () => {
    let table = window.$("#reportv4-table").DataTable();
    table.destroy();

    let { advancedOptions, clonneAdvanceOptions, showHiddenRows } = this.state;

    showHiddenRows = !showHiddenRows;

    if (showHiddenRows) {
      //show hidden rows
      advancedOptions = clonneAdvanceOptions;
    } else {
      //hide again hidden rows
      let list = advancedOptions.filter((l) => !l.hide);
      advancedOptions = list;
    }

    this.setState({
      advancedOptions,
      showHiddenRows,
      reDrawDT: true, //to re daraw the datatable
    });
  };
  handleReportTypes = (type) => {
    let { formErrors } = this.state;

    formErrors = Validation.handleValidation(
      "reportTypesVal",
      type.value,
      formErrors
    );

    let value = type.value || "";

    let reportLayouts = this.state.reportLayouts || [];
    let reportOptions = [];

    let found = reportLayouts.find((rop) => rop.id === value);

    if (found) {
      reportOptions.push({
        label: found.layout,
        value: found.id,
        selected: 1,
      });
    }
    this.setState({
      reportLayouts: reportLayouts,
      reportOptionVal: type,
      formErrors,
    });
  };

  onCreateJournal = async () => {
    this.setState({ isLoading: true });
    let { advancedOptions, newRefId } = this.state;
    let saveJournal = {
      bankCode: "B1",
      guidList: newRefId,
      options: advancedOptions,
    };
    await this.props.createJournal(saveJournal);
    if (this.props.account.createJournalSuccess) {
      toast.success(this.props.account.createJournalSuccess);
    }
    if (this.props.account.createJournalError) {
      handleAPIErr(this.props.account.createJournalError, this.props);
    }
    this.props.closeModal("openCancelReissueModal");
    this.setState({ isLoading: false });
  };

  _handleReportTypes = (type) => {
    let { formErrors } = this.state;
    formErrors = Validation.handleValidation(
      "reportTypesVal",
      type.value,
      formErrors
    );

    let value = type.value || "";

    let reportLayoutsVal = this.state.reportTypes || [];

    let reportOptions = [];

    let found = reportLayoutsVal.find((rop) => rop.value === value);

    if (found) {
      reportOptions.push({
        label: found.label,
        value: found.value,
        selected: 1,
      });
    }
    this.setState({
      reportTypesVal: type,
      reportTypeValue: reportOptions,
      formErrors,
    });
  };
  handleReportOptions = (layout) => {
    let { formErrors } = this.state;
    formErrors = Validation.handleValidation(
      "reportOptionVal",
      layout.value,
      formErrors
    );

    this.setState({ reportOptionVal: layout, formErrors });

    if (layout.value) {
      //clear states on file and report name
      this.setState({
        addNewReportToggle: false,
        reportFile: "",
        reportName: "",
        private: false,

        formErrors: {
          reportFile: "",
          reportName: "",
        },
      });
    }
  };

  handleFieldChange = (e) => {
    let { formErrors } = this.state;
    let { name, value } = e.target;
    formErrors = Validation.handleValidation(name, value, formErrors);
    this.setState({ [name]: value, formErrors });
  };
  handleReportCheckbox = (e) => {
    let { checked } = e.target;
    this.setState((prev) => ({ ...prev, privateCheck: checked }));
  };
  addNewReport = async () => {
    this.setState((prevState) => ({
      addNewReportToggle: !prevState.addNewReportToggle,
    }));

    if (this.state.addNewReportToggle) {
      //add new report case then report options set to default

      this.setState((prevState) => ({
        reportOptionVal: { label: "Select Report Options", value: "" },
        formErrors: {
          ...prevState.formErrors,
          reportOptionVal: "",
        },
      }));

      //adding drag and drop attachments listeners
      addDragAndDropFileListners("drop-area-report", this.uploadReportFile);
      //end
    } else {
      //clear states on file and report name
      this.setState({
        reportFile: "",
        reportName: "",
        private: false,

        formErrors: {
          reportFile: "",
          reportName: "",
        },
      });
    }
  };
  uploadReportFile = async (f) => {
    let { formErrors } = this.state;
    let type = f[0].type;
    let file = f[0];
    let size = f[0].size;

    if (type == "mrt") {
      if (size <= 2000000) {
        const result = await toBase64(file).catch((e) => e);
        if (result instanceof Error) {
          toast.error(result.message);
          return;
        } else {
          formErrors = Validation.handleValidation(
            "reportFile",
            result,
            formErrors
          );
          this.setState({
            reportFile: result,
            formErrors,
          });
        }
      } else {
        toast.error("Maximum Image Size 2MB");
      }
    } else {
      toast.error("Please Select only Images of type: '.mrt'");
    }
  };
  menuOpened = async () => {
    this.setState({ menuIsOpen: true });
    let _this = this;
    $(document).ready(async function () {
      $('<i class="fa fa-trash report--del"></i>').appendTo(
        ".report_menu.custon_select-selector-inner__option"
      );
      $(".report_menu.custon_select-selector-inner__option i").on(
        "click",
        async function (e) {
          e.preventDefault();
          var p_id = $(this).parent().attr("id");
          let id_split = p_id && p_id.split("-"); //["react", "select", "3", "option", "1"]
          let id = id_split[id_split.length - 1];

          let { reportOptions } = _this.state;
          let rep_opt = reportOptions.length > 0 && reportOptions[id];
          //call delete report options API
          await _this.deleteReport(rep_opt.value, rep_opt.locked);
        }
      );
    });
  };

  menuClosed = () => {
    this.setState({ menuIsOpen: false });
  };
  render() {
    return (
      <>
        {this.state.isLoading ? <div className="se-pre-con"></div> : ""}

        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={this.props.openCancelReissueModal}
          onHide={() => this.props.closeModal("openCancelReissueModal")}
          className="modal_704 mx-auto reports-v4-modal"
        >
          <Modal.Body>
            <div className="container-fluid p-0">
              <div className="main_wrapper">
                <div className="row d-flex h-100 p-0">
                  <div className="col-12 justify-content-center align-self-center">
                    <div className="setting_form_main p-0">
                      <div className="setting_header thead_bg">
                        <h3 className="Indirecttaxcode-poup_heading">
                          Cancel/ReIssue
                        </h3>
                        <div className="Indirecttaxcode-poup_can-sav-btn">
                          {/* <button
                            onClick={this.onCreateJournal}
                            className="btn can-btn1"
                          >
                            <img
                              src="images/user-setup/check-white.png"
                              alt="check"
                            />
                            Save
                          </button> */}
                          <button
                            onClick={() =>
                              this.props.closeModal("openCancelReissueModal")
                            }
                            className="btn can-btn1 pl-3"
                          >
                            <img
                              src="images/user-setup/cancel-white.png"
                              alt="cancel"
                            />
                            Cancel
                          </button>
                          <button className="btn can-btn1 pl-2">
                            <img
                              src="images/user-setup/dots-h.png"
                              alt="dots"
                            />
                          </button>
                        </div>
                      </div>
                      <div className="reportv4-modal-inner">
                        <div className="row mt-4">
                          <div className="col-md-12">
                            <div className="form-group custon_select">
                              <label>Report Type</label>

                              <Select
                                className="width-selector"
                                value={this.state.reportTypeValue}
                                classNamePrefix="custon_select-selector-inner"
                                options={this.state.reportTypes}
                                onChange={this._handleReportTypes}
                                theme={(theme) => ({
                                  ...theme,
                                  border: 0,
                                  borderRadius: 0,
                                  colors: {
                                    ...theme.colors,
                                    primary25: "#f2f2f2",
                                    primary: "#f2f2f2",
                                  },
                                })}
                              />
                              <div className="text-danger error-12">
                                {this.state.formErrors.reportTypesVal !== ""
                                  ? this.state.formErrors.reportTypesVal
                                  : ""}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="form-group col-md-12">
                            <div className="custon_select">
                              <label>Report Option</label>

                              <Select
                                className="width-selector"
                                // onMenuOpen={this.state.menuIsOpen}
                                closeMenuOnSelect={true}
                                value={this.state.reportOptionVal}
                                classNamePrefix="report_menu custon_select-selector-inner"
                                onMenuOpen={this.menuOpened}
                                onMenuClose={this.menuClosed}
                                onChange={this.handleReportTypes}
                                options={this.state.reportLayouts}
                                theme={(theme) => ({
                                  ...theme,
                                  border: 0,
                                  borderRadius: 0,
                                  colors: {
                                    ...theme.colors,
                                    primary25: "#f2f2f2",
                                    primary: "#f2f2f2",
                                  },
                                })}
                              />
                              <div className="text-danger error-12">
                                {this.state.formErrors.reportOptionVal !== ""
                                  ? this.state.formErrors.reportOptionVal
                                  : ""}
                              </div>
                            </div>
                          </div>
                          {this.addNewReportToggle && (
                            <div className=" pl-3 pr-3 w-100">
                              <div className="row">
                                <div className="col-12">
                                  <div className="form-group custon_select mt-3">
                                    <div className="modal_input">
                                      <label>Report Name</label>
                                      <input
                                        type="text"
                                        className="form-control pl-0"
                                        id="usr"
                                        name="reportName"
                                        value={this.state.reportName}
                                        onChange={this.handleFieldChange}
                                      />
                                      <div className="text-danger error-12">
                                        {this.state.formErrors.reportName !== ""
                                          ? this.state.formErrors.reportName
                                          : ""}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-12 align-self-center mb-2 supp2-roprt-check">
                                  <div className="form-group remember_check">
                                    <input
                                      type="checkbox"
                                      id="remember"
                                      checked={this.state.privateCheck}
                                      onChange={(e) =>
                                        this.handleReportCheckbox(e)
                                      }
                                    />
                                    <label htmlFor="remember"></label>
                                    <p className="reports-excel rc-lineheight">
                                      Private:
                                    </p>
                                  </div>
                                </div>
                                <div className="col-12 mt-2 mb-2">
                                  <div className="form-group custon_select  text-center mb-0 border-rad-5">
                                    <div id="drop-area-report">
                                      <input
                                        type="file"
                                        id="fileElem-rep"
                                        className="form-control d-none"
                                        accept="application/pdf"
                                        onChange={(e) => {
                                          this.uploadReportFile(e.target.files);
                                        }}
                                        onClick={(event) => {
                                          event.currentTarget.value = null;
                                        }} //to upload the same file again
                                      />

                                      <label
                                        className="upload-label"
                                        htmlFor="fileElem-rep"
                                      >
                                        <div className="upload-text">
                                          <img
                                            src="images/drag-file.png"
                                            className="import_icon img-fluid"
                                            alt="upload-report"
                                          />
                                        </div>
                                      </label>
                                    </div>
                                  </div>
                                  <div className="text-danger error-12">
                                    {this.state.formErrors.reportFile !== ""
                                      ? this.state.formErrors.reportFile
                                      : ""}
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>

                        <div className="reports_popup__table">
                          <div className="reconciliation-table1 table_white_box">
                            <table
                              className="table"
                              id="reportv4-table"
                              width="100%"
                            >
                              <thead className="thead_bg hover-border">
                                <tr>
                                  <th scope="col"></th>
                                  <th scope="col">
                                    <span className="user_setup_hed">
                                      Category
                                    </span>
                                  </th>
                                  <th scope="col">
                                    <span className="user_setup_hed">
                                      Description
                                    </span>
                                  </th>
                                  <th scope="col">
                                    <span className="user_setup_hed">
                                      Value
                                    </span>
                                  </th>
                                  <th scope="col">
                                    <span className="user_setup_hed">Hide</span>
                                  </th>
                                  <th className="table__inner--th">
                                    <div className="dropdown">
                                      <button
                                        aria-haspopup="true"
                                        aria-expanded="true"
                                        id=""
                                        type="button"
                                        className="dropdown-toggle btn dept-tbl-menu "
                                        data-toggle="dropdown"
                                      >
                                        <span className="fa fa-bars "></span>
                                      </button>
                                      <div className="dropdown-menu dept-menu-list dropdown-menu-right">
                                        <div className="pr-0 dropdown-item">
                                          <div className="form-group remember_check mm_check4">
                                            <input
                                              type="checkbox"
                                              id="showHiddenRows"
                                              name="showHiddenRows"
                                              checked={
                                                this.state.showHiddenRows
                                              }
                                              onClick={
                                                this.handleShowHiddenRows
                                              }
                                            />
                                            <label
                                              htmlFor="showHiddenRows"
                                              className="mr-0"
                                            >
                                              Show Hidden Rows
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state?.advancedOptions?.map((list, a) => {
                                  return (
                                    <tr>
                                      <td></td>
                                      <td>{list.category}</td>
                                      <td>{list.description}</td>
                                      {list.valueType === "List" ? (
                                        <td className="pt-0 pb-0 text-left  value__field--wrapperdept ">
                                          <Select
                                            classNamePrefix="custon_select-selector-inner main__dropdown--wrappertwo select__font-wrapper                                        "
                                            value={{
                                              label: list.value,
                                              value: list.value,
                                            }}
                                            options={list.options}
                                            onChange={(obj) =>
                                              this._handleValueOptions(
                                                "list",
                                                obj,
                                                list,
                                                a
                                              )
                                            }
                                            styles={_customStyles}
                                            theme={(theme) => ({
                                              ...theme,
                                              border: 0,
                                              borderRadius: 0,
                                              colors: {
                                                ...theme.colors,
                                                primary25: "#f2f2f2",
                                                primary: "#f2f2f2",
                                              },
                                            })}
                                          />
                                        </td>
                                      ) : list.valueType === "Date" ? (
                                        <td>
                                          <div className="table_input_field">
                                            <DatePicker
                                              selected={Number(list.value)}
                                              dateFormat="d MMM yyyy"
                                              autoComplete="off"
                                              onChange={(date) =>
                                                this._handleValueOptions(
                                                  "date",
                                                  date,
                                                  list,
                                                  a
                                                )
                                              }
                                              // readOnly={list.readOnly}
                                            />
                                          </div>
                                        </td>
                                      ) : list.valueType === "Check" ? (
                                        // <td>

                                        //     <div className="form-group remember_check  text-center pt-0 float-left">
                                        //       <input
                                        //         type="checkbox"
                                        //         id={`chk${i}`}
                                        //         checked={
                                        //           list.value === "Y" ||
                                        //           list.value === "1"
                                        //             ? true
                                        //             : false
                                        //         }
                                        //         onChange={(e) =>
                                        //           this._handleValueOptions(
                                        //             "checkbox",
                                        //             e,
                                        //             list,
                                        //             i
                                        //           )
                                        //         }
                                        //       />
                                        //       <label
                                        //         htmlFor={`chk${i}`}
                                        //       ></label>

                                        //   </div>
                                        // </td>
                                        <td>
                                          <div className="col-auto p-0">
                                            <div className="remember_check  text-center pt-0 float-left">
                                              <input
                                                type="checkbox"
                                                id={`chk${a}`}
                                                checked={
                                                  list.value === "Y" ||
                                                  list.value === "1"
                                                    ? true
                                                    : false
                                                }
                                                readOnly={
                                                  list.readOnly === "N"
                                                    ? false
                                                    : true
                                                }
                                                onChange={(e) =>
                                                  this._handleValueOptions(
                                                    "checkbox",
                                                    e,
                                                    list,
                                                    a
                                                  )
                                                }
                                              />
                                              <label
                                                htmlFor={`chk${a}`}
                                              ></label>
                                            </div>
                                          </div>
                                        </td>
                                      ) : list.valueType === "Numeric" ||
                                        list.valueType === "Number" ? (
                                        <td>
                                          <div className="table_input_field">
                                            <input
                                              type="number"
                                              value={list.value}
                                              onChange={(e) =>
                                                this._handleValueOptions(
                                                  "number",
                                                  e,
                                                  list,
                                                  a
                                                )
                                              }
                                            />
                                          </div>
                                        </td>
                                      ) : list.valueType === "Range" ||
                                        list.valueType === "Text" ? (
                                        <td>
                                          <div className="table_input_field">
                                            <input
                                              type="text"
                                              value={list.value}
                                              onChange={(e) =>
                                                this._handleValueOptions(
                                                  "text",
                                                  e,
                                                  list,
                                                  a
                                                )
                                              }
                                            />
                                          </div>
                                        </td>
                                      ) : (
                                        <td>{list.value}</td>
                                      )}

                                      <td>
                                        <div className="custom-radio">
                                          <label
                                            className="check_main remember_check"
                                            htmlFor={`hideUnhideRows${a}`}
                                          >
                                            <input
                                              type="checkbox"
                                              className="custom-control-input"
                                              name={"hideUnhideRows"}
                                              id={`hideUnhideRows${a}`}
                                              checked={false}
                                              onChange={(e) =>
                                                this._handleHideUnhideRows(list)
                                              }
                                            />

                                            {/* <span className='click_checkmark'></span> */}
                                            <span
                                              className={
                                                list.hide
                                                  ? "dash_checkmark bg_clr"
                                                  : "dash_checkmark"
                                              }
                                            ></span>
                                          </label>
                                        </div>
                                      </td>
                                      <td></td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                            <div className=" cancel-cheque-button ">
                              {/* <b> */}
                              <button
                                onClick={() =>
                                  this.getCancelReIssueCheque(
                                    "openCancelReissueModal"
                                  )
                                }
                              >
                                CONFIRM
                              </button>
                              {/* </b> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  account: state.account,
});
export default connect(mapStateToProps, {
  getCancelReIssueCheque: AccountActions.getCancelReIssueCheque,
})(CancelReissueModal);
