import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Redirect } from "react-router-dom";
import { isMaxAuthEnabled } from "../../../Utils/authHelpers";

class TopNav extends Component {
  constructor(props) {
    super();
    this.state = {
      openUserSetupMenu: false,
      openAccountsMenu: false,
      sideBarZoomLevel: props.sideBarZoomLevel ?? "inherit",
      openAdministrationMenu: false,
    };
  }

  handleUserSetup = () => {
    this.setState({ openUserSetupMenu: !this.state.openUserSetupMenu });
  };
  handleAccounts = () => {
    this.setState({ openAccountsMenu: !this.state.openAccountsMenu });
  };

  handleAdministration = () => {
    this.setState({
      openAdministrationMenu: !this.state.openAdministrationMenu,
    });
  };

  componentDidMount() {
    window.$(function () {
      "use strict";
      (function () {
        window.$(".setup_menu").on("click", function () {
          var id = window.$(this).attr("data-target");
          if (id === "#top_nav_toggle1") {
            window.$(`${id}`).toggleClass("show");
          }
        });
        window.$(".dash_menu_toggle.top--nav").on("click", function () {
          window.$(".setup_menu").click();
        });
      })();
    });
  }

  render() {
    let url = localStorage.getItem("API_URL") || "";
    if (!url) {
      return <Redirect to={"/login"} />;
    }

    let blockPO = localStorage.getItem("blockPO") || "N";
    let blockPayments = localStorage.getItem("blockPayments") || "N";
    let blockTimecards = localStorage.getItem("blockTimecards") || "N";
    let blockJournals = localStorage.getItem("blockJournals") || "N";
    let blockDistChange = localStorage.getItem("blockDistChange") || "N";
    let supplierApproval = localStorage.getItem("supplierApproval") || "N";
    let blockSupplier = localStorage.getItem("blockSupplier") || "N";
    let blockInvoice = localStorage.getItem("blockInvoice") || "N";
    let blockDocuments = localStorage.getItem("blockDocuments") || "N";
    let blockExpense = localStorage.getItem("blockExpense") || "N";

    let userType = localStorage.getItem("userType") || "";
    userType = userType.toLowerCase();
    let link = this.props.user.getHelpPage || "#";
    return (
      <>
        {/* top nav toggle*/}
        <div className="collapse" id="top_nav_toggle1">
          <aside className="side-nav sidebar--scroll--wrapper" id="">
            <ul
              className="categories sidebar--scroll--inner"
              style={{ zoom: this.state.sideBarZoomLevel }}
            >
              <img
                src="images/menu-toggle.png"
                data-show="show-side-navigation1"
                className="dash_menu_toggle top--nav"
                alt="menu-toggle"
              />
              <li>
                <Link to="/dashboard">
                  <img
                    src="images/menu-home.png"
                    className="dash_menu_item"
                    alt="menu-toggle"
                  />
                  Home
                </Link>
              </li>
              {blockPO === "Y" ? (
                ""
              ) : (
                <li>
                  <Link to="/order">
                    <img
                      src="images/menu-state.png"
                      className="dash_menu_item"
                      alt="menu-toggle"
                    />
                    Orders
                  </Link>
                </li>
              )}

            {blockPO === "Y" || !isMaxAuthEnabled() ? (
              <></>
            ) : (
              <li>
                <a target='_blank' rel='noopener noreferrer' href={ window.config.TPH_MAX_DOMAIN }>
                <img
                    src="images/menu-orders-max.png"
                    style={{ width: "30px" }}
                    className="dash_menu_item"
                    alt="menu-toggle"
                  />
                  Orders (beta)
                </a>
              </li>
            )}

              {blockInvoice === "Y" ? (
                ""
              ) : (
                <li>
                  <Link to="/invoice">
                    <img
                      src="images/menu-invoice.png"
                      className="dash_menu_item"
                      alt="menu-toggle"
                    />
                    Invoices
                  </Link>
                </li>
              )}
              {blockExpense === "Y" ? (
                ""
              ) : (
                <li>
                  <Link to="/expenses">
                    <img
                      src="images/menu-invoice.png"
                      className="dash_menu_item"
                      alt="menu-toggle"
                    />
                    Expenses
                  </Link>
                </li>
              )}
              {blockDocuments === "Y" ? (
                ""
              ) : (
                <li>
                  <Link to="/documents">
                    <img
                      src="images/menu-invoice.png"
                      className="dash_menu_item"
                      alt="menu-toggle"
                    />
                    Documents
                  </Link>
                </li>
              )}
              <li>
                <Link
                  to={supplierApproval === "N" ? "/suppliers2" : "/suppliers"}
                >
                  <img
                    src="images/menu-suppliers.png"
                    className="dash_menu_item"
                    alt="menu-toggle"
                  />
                  Suppliers
                </Link>
              </li>
              {/* Supplier approval screen  */}
              {/* Supplier approval screen  */}

              {/* new pages add  */}
              {blockJournals === "Y" ? (
                ""
              ) : (
                <li>
                  <Link to="/journals">
                    <img
                      src="images/journal.png"
                      className="dash_menu_item"
                      alt="menu-toggle"
                    />
                    Journals
                  </Link>
                </li>
              )}
              {blockDistChange === "Y" ? (
                ""
              ) : (
                <li>
                  <Link to="/dist-changes">
                    <img
                      src="images/dist-changes.png"
                      className="dash_menu_item"
                      alt="menu-toggle"
                    />
                    Dist Changes
                  </Link>
                </li>
              )}

              {blockPayments === "Y" ? (
                ""
              ) : (
                <li>
                  <Link to="/payments">
                    <img
                      src="images/credit-card.png"
                      className="dash_menu_item"
                      alt="menu-toggle"
                    />
                    Payments
                  </Link>
                </li>
              )}

              {blockTimecards === "Y" ? (
                ""
              ) : (
                <li>
                  <Link to="/timecards">
                    <img
                      src="images/credit-card.png"
                      className="dash_menu_item"
                      alt="menu-toggle"
                    />
                    Timecards
                  </Link>
                </li>
              )}

              <li>
                <Link to="/report">
                  <img
                    src="images/menu-report.png"
                    className="dash_menu_item"
                    alt="menu-toggle"
                  />
                  Reports
                </Link>
              </li>
              {(userType === "admin" || userType === "sysadmin") && (
                <li>
                  <span
                    className="usersetu_side_menu_span "
                    onClick={this.handleUserSetup}
                  >
                    <img
                      src="images/menu-steup.png"
                      className="dash_menu_item"
                      alt="menu-toggle"
                    />
                    Setup
                  </span>
                  {this.state.openUserSetupMenu ? (
                    <ul className="usersetu_sidesub_menu">
                      <li>
                        <Link to="/user-setup">User Setup</Link>
                      </li>
                      <li>
                        <Link to="/approval-setup">Approval Setup</Link>
                      </li>
                      <li>
                        <Link to="/custom-fields">Custom Fields</Link>
                      </li>
                      <li>
                        <Link to="/custom-line-type">Custom Line Types</Link>
                      </li>
                      <li>
                        <Link to="/departments">Departments</Link>
                      </li>
                      <li>
                        <Link to="/chart-of-accounts">Chart of Accounts</Link>
                      </li>
                      <li>
                        <Link to="/indirect-tax-codes">Tax Codes</Link>
                      </li>
                      <li>
                        <Link to="/currencies">Currency</Link>
                      </li>
                      {/* <li>
                      <Link to="/currencies-details">Currency Details</Link>
                    </li> */}
                      <li>
                        <Link to="/business-units">Business Unit</Link>
                      </li>
                      <li>
                        <Link to="/chart-setup">Chart Setup</Link>
                      </li>
                      <li>
                        <Link to="/tracking-codes-layout">
                          Tracking Codes Layout
                        </Link>
                      </li>
                      <li>
                        <Link to="/order-template">Order Template</Link>
                      </li>
                      <li>
                        <Link to="/email-template">Email Template</Link>
                      </li>
                      <li>
                        <Link to="/invoice-ocr-setup">Invoice OCR Setup</Link>
                      </li>
                      <li>
                        <Link to="/user-defaults">User Defaults</Link>
                      </li>
                      <li>
                        <Link to="/system-defaults">System Defaults</Link>
                      </li>
                      <li>
                        <Link to="/batch-list">Batch List</Link>
                      </li>
                    </ul>
                  ) : (
                    ""
                  )}
                </li>
              )}

              {(userType === "admin" || userType === "sysadmin") && (
                <li>
                  <span
                    className="usersetu_side_menu_span "
                    onClick={this.handleAccounts}
                  >
                    <img
                      src="images/menu-steup.png"
                      className="dash_menu_item"
                      alt="menu-toggle"
                    />
                    Accounts
                  </span>
                  {this.state.openAccountsMenu ? (
                    <ul className="usersetu_sidesub_menu">
                      <li>
                        <Link to="/period-close">Period Close</Link>
                      </li>
                      <li>
                        <Link to="/general-ledger">General Ledger</Link>
                      </li>
                      <li>
                        <Link to="/bank-rec">Bank Rec</Link>
                      </li>
                      <li>
                        <Link to="/financial-reports-worksheet">
                          Financial Reports Worksheet
                        </Link>
                      </li>
                      <li>
                        <Link to="/cashflow-forecasting">
                          Cashflow/Forecasting
                        </Link>
                      </li>

                      <li>
                        <Link to="/vat-submission">Vat Submission</Link>
                      </li>
                    </ul>
                  ) : (
                    ""
                  )}
                </li>
              )}
              {(userType === "admin" || userType === "sysadmin") && (
                <li>
                  <span
                    className="usersetu_side_menu_span "
                    onClick={this.handleAdministration}
                  >
                    <img
                      src="images/lock.png"
                      className="dash_menu_item"
                      alt="menu-toggle"
                    />
                    Administration
                  </span>
                  {this.state.openAdministrationMenu ? (
                    <ul className="usersetu_sidesub_menu">
                      <li>
                        <Link to="/transfer-records">Transfer Records</Link>
                      </li>
                      <li>
                        <Link to="/audit-logs">Audit Logs</Link>
                      </li>
                      <li>
                        <Link to="/incoming-emails">Incoming Emails</Link>
                      </li>
                      <li>
                        <Link to="/outgoing-emails">Outgoing Emails</Link>
                      </li>
                    </ul>
                  ) : (
                    ""
                  )}
                </li>
              )}
              <li>
                <a href={link} target="_blank">
                  <img
                    src="images/menu-help.png"
                    className="dash_menu_item"
                    alt="menu-toggle"
                  />
                  Help Center
                </a>
              </li>
              <li>
                <Link to="/search">
                  <img
                    src="images/menu-search.png"
                    className="dash_menu_item"
                    alt="menu-toggle"
                  />
                  Search
                </Link>
              </li>
            </ul>
          </aside>
        </div>
        {/* end */}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps, {})(TopNav);
