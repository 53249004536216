const initialState = {
  primeApprovalTransferLoading: false,
  failedToLoadPrimeApprovalTransfer: false,
  transferTalliesLoading: false,
  failedToLoadTransferTalliesLoading: false,
  transferring: false,
  transferStatus: "",
  userList: [],
  modules: [],
  tallies: [],
};

export default function ApprovalTransfer(state = initialState, action) {
  switch (action.type) {
    case "PRIME_APPROVAL_TRANSFER_INIT":
      return {
        ...state,
        primeApprovalTransferLoading: true,
        failedToLoadPrimeApprovalTransfer: false,
        userList: [],
        modules: [],
      };
    case "PRIME_APPROVAL_TRANSFER_SUCCESS":
      return {
        ...state,
        primeApprovalTransferLoading: false,
        failedToLoadPrimeApprovalTransfer: false,
        userList: action.payload.userList,
        modules: action.payload.modules,
      };
    case "PRIME_APPROVAL_TRANSFER_FAILED":
      return {
        ...state,
        primeApprovalTransferLoading: false,
        failedToLoadPrimeApprovalTransfer: true,
        userList: [],
        modules: [],
      };
    case "GET_APPROVAL_TRANSFER_TALLIES_INIT":
      return {
        ...state,
        transferTalliesLoading: true,
        failedToLoadTransferTalliesLoading: false,
        tallies: [],
      };
    case "GET_APPROVAL_TRANSFER_TALLIES_SUCCESS":
      return {
        ...state,
        transferTalliesLoading: false,
        failedToLoadTransferTalliesLoading: false,
        tallies: action.payload.modules,
      };
    case "GET_APPROVAL_TRANSFER_TALLIES_FAILED":
      return {
        ...state,
        transferTalliesLoading: false,
        failedToLoadTransferTalliesLoading: true,
        tallies: [],
      };

    case "TRANSFER_APPROVALS_INIT":
      return {
        ...state,
        transferring: true,
        transferStatus: "TRANSFERRING",
      };
    case "TRANSFER_APPROVALS_SUCCESS":
      return {
        ...state,
        transferring: false,
        transferStatus: "TRANSFER_COMPLETED",
      };
    case "TRANSFER_APPROVALS_FAILED":
      return {
        ...state,
        transferring: false,
        transferStatus: "TRANSFER_FAILED",
      };
    default:
      return state;
  }
}
