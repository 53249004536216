import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Select from "react-select";
import { getCountryCodeList } from "../../Utils/Helpers";
import { phone } from 'phone';

const MFAQRCodeModal = ({
    closeModal,
    setup2FA,
    qrCodeImage,
    validateCodeTOTP,
    showTOTPMFAModal,
    validatePhoneNumber,
    mfaMode,
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [userCode, setUserCode] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [countryCode, setCountryCode] = useState('');
    const [countryCodeList, setCountryCodeList] = useState([]);
    const [errors, setErrors] = useState({
        userCode: null,
        phoneNumber: null,
    });

    useEffect(() => {
        setIsLoading(true);
        setCountryCodeList(getCountryCodeList());
        setIsLoading(false);
    }, []);

    const handleUserCodeSubmit = (e) => {
        e.preventDefault();
        setIsLoading(true);
        setErrors({ ...errors, userCode: null });

        if (!userCode) {
            setErrors({
                ...errors,
                userCode: 'Code is required'
            });
            return;
        }
        validateCodeTOTP(userCode);
        setUserCode('');
        setIsLoading(false);
    }

    const handlePhoneNumberSubmit = (e) => {
        e.preventDefault();
        setIsLoading(true);
        setErrors({ ...errors, phoneNumber: null });

        if (!phoneNumber || !countryCode) {
            setErrors({
                ...errors,
                phoneNumber: 'Phone number is required'
            });
            return;
        }
        if (phoneNumber.length > 10 || phoneNumber.match(/[a-zA-Z]/)?.length > 0) {
            setErrors({
                ...errors,
                phoneNumber: 'Please enter a valid phone number'
            });
            return;
        }
        const formattedPhoneNumber = phone(phoneNumber, { country: countryCode.value });

        validatePhoneNumber(formattedPhoneNumber.phoneNumber);
        setPhoneNumber('');
        setCountryCode('');
        setIsLoading(false);
    }

    return (
        <Modal
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={showTOTPMFAModal}
            onHide={closeModal}
            className="forgot_email_modal modal_704 mx-auto"
            backdrop="static"
        >

            <Modal.Body>
                <div className="container-fluid ">
                    <div className="main_wrapper p-10">
                        <div className="row d-flex h-100">
                            <div className="col-12 justify-content-center align-self-center form_mx_width">
                                <div className="forgot_form_main">
                                    <div className="forgot_header">
                                        <div className="modal-top-header">
                                            <div className="row bord-btm">
                                                <div className="col-auto pl-0">
                                                    <h6 className="text-left def-blue">{setup2FA ? 'QR Code' : 'Pin code'}</h6>
                                                </div>
                                                <div className="col d-flex justify-content-end s-c-main">
                                                    <button
                                                        onClick={() =>
                                                            closeModal()
                                                        }
                                                        type="button"
                                                        className="btn-save"
                                                    >
                                                        <span className="fa fa-ban"></span>
                                                        Cancel
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="forgot_body">

                                        {/* display MFA setup screen */}
                                        {setup2FA === true &&
                                            <div className="col col-12" style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                marginTop: 20
                                            }}>
                                                <p style={{
                                                    marginTop: 15
                                                }}>
                                                    This production requires the use of MFA (Multi Factor Authentication).  Using your Authenticator Application of choice, please scan the QR code to continue setting up MFA.  Once set up, enter the corresponding 6 digits code here to continue with the login.
                                                </p>

                                                <div className="d-flex flex-column w-100 justify-content-center align-items-center">
                                                    <img src={qrCodeImage} alt="QR Code" style={{
                                                        width: '200px',
                                                        height: '200px'
                                                    }} />
                                                    <form onSubmit={handleUserCodeSubmit} style={{
                                                        marginTop: 10
                                                    }}>
                                                        <div className="d-flex justify-content-center align-items-start">

                                                            <div className="form-group mt-1">
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="userCode"
                                                                    placeholder="Code"
                                                                    name="userCode"
                                                                    onChange={(e) => setUserCode(e.target.value)}
                                                                    value={userCode}
                                                                    maxLength={6}
                                                                />
                                                                {errors.userCode && <div className="text-danger error-12">
                                                                    {errors.userCode}
                                                                </div>}
                                                            </div>
                                                            <button type="submit" disabled={isLoading} className="btn btn-primary login_blue ml-2">Submit</button>
                                                        </div>
                                                    </form>
                                                </div>

                                                <p style={{ width: '100%', borderBottom: '1px solid #ddd', marginTop: 20 }}></p>

                                                <p style={{
                                                    marginTop: 20
                                                }}>
                                                    Optionally, enter in your mobile number below to set up MFA using SMS:
                                                </p>

                                                <div>
                                                    <form onSubmit={handlePhoneNumberSubmit} style={{
                                                        marginTop: 10
                                                    }}>
                                                        <div className="d-flex justify-content-center align-items-start">


                                                            <Select
                                                                className="mr-2 mt-1"
                                                                placeholder="Country Code"
                                                                value={countryCode}
                                                                options={countryCodeList}
                                                                onChange={e => {
                                                                    setCountryCode(e);
                                                                }}
                                                                styles={{
                                                                    control: (baseStyles, state) => ({
                                                                        ...baseStyles,
                                                                        width: '130px',
                                                                    }),
                                                                }}
                                                                theme={(theme) => ({
                                                                    ...theme,
                                                                    border: 0,
                                                                    borderRadius: 0,
                                                                    width: 129,
                                                                    colors: {
                                                                        ...theme.colors,
                                                                        primary25: "#f2f2f2",
                                                                        primary: "#f2f2f2",
                                                                    },
                                                                })}
                                                            />

                                                            <div className="form-group mt-1">
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="userPhoneNumber"
                                                                    placeholder="Phone number"
                                                                    name="userPhoneNumber"
                                                                    onChange={(e) => setPhoneNumber(e.target.value)}
                                                                    value={phoneNumber}
                                                                />
                                                                {errors.phoneNumber && <div className="text-danger error-12">
                                                                    {errors.phoneNumber}
                                                                </div>}
                                                            </div>
                                                            <button type="submit" disabled={isLoading} className="btn btn-primary login_blue ml-2">Submit</button>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        }

                                        {/* display verification code input */}
                                        {setup2FA === false &&
                                            <div className="col col-12 d-flex justify-content-end s-c-main" style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                marginTop: 20
                                            }}>
                                                <form onSubmit={handleUserCodeSubmit}>
                                                    <p>Please enter the pin code {mfaMode === 'SMS' ? 'sent to your mobile' : 'from your Authenticator Application'} here:</p>
                                                    <div className="form-group" style={{
                                                        display: 'flex',
                                                        justifyContent: 'flex-end'
                                                    }}>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="6 digit code"
                                                            name="userCode"
                                                            value={userCode}
                                                            maxLength={6}
                                                            onChange={(e) => setUserCode(e.target.value)}
                                                            style={{
                                                                marginRight: 5
                                                            }}
                                                        />
                                                        <button type="submit" disabled={isLoading} className="btn btn-primary login_blue ml-2">Submit</button>
                                                    </div>
                                                </form>
                                            </div>
                                        }
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
};

export default MFAQRCodeModal;
