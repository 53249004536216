import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Modal from "react-bootstrap/Modal";
import { useProductionLogin } from "../../LoginTable/useProductionLogin";
import ProductionList from "../../LoginTable/ProductionList";
import "./BusinessUnit.css";

export default function BusinessUnit(props) {
  const productionSwitch = useProductionLogin();
  const productionName = localStorage.getItem("loginProduction");
  const productions = useSelector((state) => state.user.productions);
  const [isLoading, setLoading] = useState(false);

  function handleOnProductionClick(production) {
    productionSwitch.login(production);
  }

  useEffect(() => {
    setLoading(productionSwitch.isLoading);
  }, [productionSwitch.isLoading]);

  return (
    <>
      {isLoading ? <div className="se-pre-con"></div> : ""}
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={props.openBusinessUnitModal}
        onHide={() => props.closeModal("openBusinessUnitModal")}
        className="forgot_email_modal modal_704 mx-auto business_unit_modal_content"
      >
        <Modal.Body className="business-unit-body">
          <div className="container-fluid ">
            <div className="main_wrapper p-10">
              <div className="row d-flex h-100">
                <div className="col-12 justify-content-center align-self-center form_mx_width">
                  <div className="forgot_form_main">
                    <div className="forgot_header"></div>
                    <div className="forgot_body">
                      <div className="row mt-4">
                        <div className="col-12">
                          <div className="login_table_list">
                            <div className="production-list">
                              <ProductionList
                                productions={productions}
                                currentProductionName={productionName}
                                onProductionClick={handleOnProductionClick}
                                onProductionDoubleClick={
                                  handleOnProductionClick
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
