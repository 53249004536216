import React, { Component } from "react";
import { Link } from "react-router-dom";
import * as AccountActions from "../../../../Actions/AccountActions/AccountActions";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { downloadAttachments, handleAPIErr } from "../../../../Utils/Helpers";
import moment from "moment";
import DatePicker from "react-datepicker";
import ReactPaginate from "react-paginate";
import Import from "../../../Modals/Accounts/Import/Import";
import BankTransactionSettings from "../../../Modals/Accounts/BankRecModals/BankTransactionSettings/BankTransactionSettings";
import { type } from "jquery";
class BankTransaction extends Component {
  constructor() {
    super();
    this.state = {
      isLoading: false,
      addNewReportToggle: false,
      openImportModal: false,
      openBankTransactionSettingsModal: false,
      type: "transactions",
      bankTransactionColumn: [],
      bankTransactionRows: [],
      transaction: [],
      reportTypesVal: { label: "", value: "" },
      reportTypes: [{ label: "", value: "" }],
      newRefId: "",
      refId: [],
      page: 1,
      totalPages: "",
      totalRecords: "",
      pageStart: "",
      pageEnd: "",
      date: null,
      reportOptionVal: { label: "", value: "" },
      reportOptions: [],
      menuIsOpen: false,
      filtersArr: [
        { filter: "category", condition: "equal", value: "settings" },
        { filter: "description", condition: "equal", value: "abc" },
      ],
      fltrsColmnFirst: [
        { id: "1", filter: "category", name: "Category", checked: false },
        { id: "2", filter: "description", name: "Description", checked: false },
        { id: "3", filter: "value", name: "Value", checked: false },
      ],
      fltrsColmnSecond: [
        { id: "4", condition: "Contains", checked: false },
        { id: "5", condition: "Doesn't contain", checked: false },
        { id: "6", condition: "Equal", checked: false },
        { id: "7", condition: "Not Equal", checked: false },
        { id: "8", condition: "Starts With", checked: false },
        { id: "9", condition: "Over", checked: false },
        { id: "10", condition: "Under", checked: false },
        { id: "11", condition: "Over Equal", checked: false },
        { id: "12", condition: "Under Equal", checked: false },
      ],
      reportFile: "",
      reportName: "",
      private: false,

      formErrors: {
        reportTypesVal: "",
        reportOptionVal: "",
        reportFile: "",
        reportName: "",
      },
      tableId: "",
    };
  }

  // async componentDidMount() {
  //   await this.getBankTransactions();
  // }
  componentWillReceiveProps(props) {
    this.clearState();
    let transaction = props?.transaction || [];
    let bankTransactionRows = props?.bankTransactionRows || [];
    let bankTransactionColumn = props?.bankTransactionColumn || [];
    let totalPages = props?.totalPages || [];
    let totalRecords = props?.totalRecords || [];
    let pageStart = props?.pageStart || [];
    let pageEnd = props?.pageEnd || [];
    let tableId = props?.tableId || [];

    this.setState({
      bankTransactionColumn,
      bankTransactionRows,
      tableId,
      transaction,
      totalPages,
      totalRecords,
      pageEnd,
      pageStart,
    });
  }
  onImport = async (type, importData) => {
    this.setState({ isLoading: true });
    let bankCode = document.getElementsByClassName(
      "custon_select-selector-inner__single-value css-1uccc91-singleValue"
    )[0].innerText;
    let requestData = {
      bankCode: bankCode,
      type: type,
      import: importData,
    };
    await this.props.getImportExcel(requestData);
    if (this.props.account.getImportExcelSuccess) {
      toast.success(this.props.account.getImportExcelSuccess);
      await this.props.getBankTransactions1();
    }
    if (this.props.account.getImportExcelError) {
      handleAPIErr(this.props.account.getImportExcelError, this.props);
    }
    this.setState({ isLoading: false });
  };
  getExportExcel = async () => {
    this.setState({ isLoading: true });
    let data = {
      bankCode: "B1",
      type: "transactions",
      guidList: this.state.newRefId,
    };
    await this.props.getExportExcel(data);
    if (this.props.account.getExportExcelSuccess) {
      toast.success(this.props.account.getExportExcelSuccess);
      let excelData = this.props.account.getExportExcel || "";

      let obj = {
        contentType: "application/vnd.ms-excel",
        attachment: excelData || "",
      };
      downloadAttachments(obj, "BankTransaction");
    }
    if (this.props.account.getExportExcelError) {
      handleAPIErr(this.props.account.getExportExcelError, this.props);
    }

    this.setState({ isLoading: false });
  };
  getReconcileBank = async () => {
    this.setState({ isLoading: true });
    let bankCode = "B1";

    await this.props.getReconcileBank(bankCode);
    if (this.props.account.getReconcileBankSuccess) {
      toast.success(this.props.account.getReconcileBankSuccess);
    }
    if (this.props.account.getReconcileBankError) {
      handleAPIErr(this.props.account.getReconcileBankError, this.props);
    }
    this.setState({ isLoading: false });
  };
  updateBankTransactions = async () => {
    this.setState({ isLoading: true });
    let { transaction } = this.state;
    // let transactions = [{ transactions: transaction }];

    let bankCode = document.getElementsByClassName(
      "custon_select-selector-inner__single-value css-1uccc91-singleValue"
    )[0].innerText;
    let data = {
      bankCode: bankCode,
      transactions: transaction,
    };
    await this.props.updateBankTransactions(data);
    if (this.props.account.updateBankTransactionsSuccess) {
      toast.success(this.props.account.updateBankTransactionsSuccess);
    }
    if (this.props.account.updateBankTransactionsError) {
      handleAPIErr(this.props.account.updateBankTransactionsError, this.props);
    }
    this.setState({ isLoading: false });
  };
  deleteBankTransactions = async () => {
    this.setState({ isLoading: true });
    let data = {
      bankCode: "B1",
      guidList: this.state.newRefId,
    };
    await this.props.deleteBankTransactions(data);
    if (this.props.account.deleteBankTransactionsSuccess) {
      toast.success(this.props.account.deleteBankTransactionsSuccess);
      await this.props.getBankTransactions1();
      // await this.props.getBankTransactions("B1");
      // let transaction = this.props.account.getBankTransactions || [];
      // this.setState({ transaction });
    }
    if (this.props.account.deleteBankTransactionsError) {
      handleAPIErr(this.props.account.deleteBankTransactionsError, this.props);
    }
    this.setState({ isLoading: false });
  };
  handleCheckboxes = (e, data) => {
    let { refId, bankTransactionRows, newRefId } = this.state;

    // let bankChequeRows = JSON.parse(JSON.stringify(this.state.bankChequeRows));
    let { name, checked } = e.target;
    if (data === "allCheck" && name === "checkboxAll") {
      let multipleTransCopy = [];
      if (checked) {
        bankTransactionRows.map((m) => {
          m.checked = true;
          multipleTransCopy.push(m.refId);
          return m;
        });
      } else {
        bankTransactionRows.map((m) => {
          m.checked = false;
          return m;
        });
      }
      refId = [...multipleTransCopy];
    } else {
      if (checked) {
        bankTransactionRows.map(async (po, i) => {
          if (data.refId === po.refId) {
            po.checked = true;
          }
          return po;
        });
        refId.push(data.refId);
      } else {
        bankTransactionRows.map(async (po, i) => {
          if (data.refId === po.refId) {
            po.checked = false;
          }
          return po;
        });
        let filteredMultiRefId = refId.filter((t) => t != data.refId);
        refId = filteredMultiRefId;
      }
    }
    newRefId = refId + [];
    this.setState({
      bankTransactionRows,
      refId,
      newRefId,
    });
  };
  handleChangeGeneralLedgerList = async (e, data, i, j) => {
    data[j] = e.target.value;
  };

  handleCheck = (e, data, i, j) => {
    data[j] = e.target.checked ? "Y" : "N";
    // data[i] = data;
    return data;
  };
  GetFormattedDate = (dateParam) => {
    var todayTime = new Date(dateParam);
    var month = todayTime.getMonth() + 1;
    var day = todayTime.getDate();
    var year = todayTime.getFullYear();
    return year + "-" + month + "-" + day;
  };

  openModal = (name) => {
    this.setState({ [name]: true });
  };
  closeModal = (name) => {
    this.setState({ [name]: false });
  };
  clearState = () => {
    this.setState({
      bankTransactionRows: [],
      bankTransactionColumn: [],
    });
  };
  render() {
    return (
      <>
        {this.state.isLoading ? <div className="se-pre-con"></div> : ""}
        <div className="forgot_form_main sup-inner-pad position-relative">
          <div className="forgot_header mt-4">
            <div className="modal-top-header">
              <div className="row bord-btm">
                <div className="col-10 col-md-9 pl-0">
                  <h6 className="text-left def-blue">
                    <span>
                      {" "}
                      <img
                        src="images/arrow_up.png"
                        className="import_icon img-fluid pr-3 ml-3 sideBarAccord"
                        alt="arrow_up"
                        data-toggle="collapse"
                        data-target="#BankTransactions"
                      />{" "}
                    </span>
                    Bank Transactions
                  </h6>
                </div>

                <div className="col-1 col-md-2  d-flex justify-content-end s-c-main">
                  <button
                    className="new_poedit_add_btn btn__plus--wrapper"
                    type="button"
                    tabIndex="2231"
                    id="id_save111"
                    onClick={this.addDailyTimesRow}
                    style={{ cursor: "pointer", color: "#30679A" }}
                  >
                    <i
                      className="fa fa-plus-circle icon--font_20"
                      aria-hidden="true"
                    ></i>
                  </button>
                  <button
                    className="new_poedit_add_btn btn__plus--wrapper "
                    type="button"
                    tabIndex="2231"
                    id="id_save111"
                    onClick={this.deleteBankTransactions}
                    title="Do you really wanna delete the selected record?"
                    style={{ cursor: "pointer", color: "#30679A" }}
                  >
                    <i
                      className="fa fa-trash icon--font_20"
                      aria-hidden="true"
                      title="Do you really wanna delete the selected record?"
                    ></i>
                  </button>
                </div>

                <div className="col-1 col-md-1 d-flex justify-content-end s-c-main">
                  <Link
                    to="#"
                    className="zom-img float-right mt-2 mr-2 icon_dots5"
                  >
                    <img
                      src="images/more.png"
                      className=" img-fluid"
                      alt="user"
                    />{" "}
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="documents_attatchments2 supplier2_sidetoggle mm-blok5">
            <div
              onClick={() => this.getExportExcel()}
              className="main-sec-attach main-bg"
            >
              Export
            </div>
            <div
              className="main-sec-attach main-bg"
              onClick={() => this.openModal("openImportModal")}
            >
              <span
                className="export_crd"
                data-toggle="collapse"
                data-target="#export"
              >
                {/* <span className="fa fa-angle-up float-left mr-2 sideBarAccord1"></span> */}
                Import
              </span>
            </div>
            <div
              className="main-sec-attach main-bg"
              // onClick={() => this.openModal("openAccountReportModal")}
              onClick={() => this.getReconcileBank()}
            >
              <span
                className="export_crd"
                data-toggle="collapse"
                data-target="#export"
              >
                {/* <span className="fa fa-angle-up float-left mr-2 sideBarAccord1"></span> */}
                Reconcile
              </span>
            </div>
            <div
              className="main-sec-attach main-bg"
              onClick={() => this.openModal("openAccountReportModal")}
            >
              <span
                className="export_crd"
                data-toggle="collapse"
                data-target="#export"
              >
                {/* <span className="fa fa-angle-up float-left mr-2 sideBarAccord1"></span> */}
                Bank Import
              </span>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-10 col-md-12 col-sm-12 m-auto">
              <div className="forgot_body collapse show " id="BankTransactions">
                {/* filter dropdown 1 */}
                <div id="supplier_filter_dropdpwn1">
                  <div className="filter_dropdpwn1_toparea">
                    <div className="col-sm-12 p-0">
                      <h2>
                        Active Filters{" "}
                        <img
                          onClick={() =>
                            this.setState({
                              filter_dropdpwn1: false,
                              filter_dropdpwn2: false,
                            })
                          }
                          src="images/cross.png"
                          alt=""
                          className="float-right pr-2 pop-cros-1"
                        />
                      </h2>
                    </div>

                    <div className="clear"></div>
                    <div className="col-sm-12 p-0">
                      {this.state.filtersArr.length > 0 && (
                        <form action="#" className="">
                          <div className="form-group mb-0">
                            <label htmlFor="test2" className="co_postaol_radio">
                              <input
                                type="radio"
                                id="test2"
                                name="filterRadioGroup"
                                value="AND"
                                checked={this.state.filterRadioGroup === "AND"}
                                onChange={this.handleRadioButtons}
                              />
                              AND
                            </label>

                            <label htmlFor="test1" className="co_postaol_radio">
                              <input
                                type="radio"
                                id="test1"
                                name="filterRadioGroup"
                                value="OR"
                                checked={this.state.filterRadioGroup === "OR"}
                                onChange={this.handleRadioButtons}
                              />
                              OR
                            </label>
                          </div>
                        </form>
                      )}
                    </div>
                  </div>
                  <div className="clear20"></div>
                  <div className="col-sm-12 p0 detail_filter_table_1">
                    <table
                      width="100%"
                      border="0"
                      cellSpacing="0"
                      cellPadding="0"
                      className="detail_detail_detail_detail_drop-table"
                    >
                      {this.state.filtersArr.length > 0
                        ? this.state.filtersArr?.map((f, i) => {
                            return (
                              <tr>
                                <td
                                  align="left"
                                  width="20"
                                  className="cursorPointer"
                                >
                                  <img
                                    onClick={() => this.removeFilter(f, i)}
                                    src="images/close-icon_filter.png"
                                    width="9"
                                    height="9"
                                    alt=""
                                  />
                                </td>
                                <td align="left">{f.name} </td>
                                <td align="center">
                                  <div className="rel">
                                    <select
                                    //   onChange={(e) =>
                                    //     this.onChangefltrConditns(
                                    //       f.filter,
                                    //       e,
                                    //       i
                                    //     )
                                    //   }
                                    >
                                      {this.state.fltrsColmnSecond.map(
                                        (fc, i) => {
                                          return (
                                            <option
                                              value={fc.condition}
                                              selected={
                                                f.condition === fc.condition
                                              }
                                            >
                                              {fc.condition}
                                            </option>
                                          );
                                        }
                                      )}
                                    </select>
                                    <i className="fa fa-angle-down my-select"></i>
                                  </div>
                                </td>
                                <td align="right">
                                  <input
                                    placeholder="Value"
                                    type={
                                      f.filter === "Rate Date" ? "date" : "text"
                                    }
                                    onChange={(e) =>
                                      this.handleFilterValue(
                                        e,
                                        "firstPopup",
                                        f,
                                        i
                                      )
                                    }
                                    className="input-cy"
                                    value={f.value}
                                  />
                                </td>
                              </tr>
                            );
                          })
                        : "No filters applied to this view"}
                    </table>
                  </div>
                  <div className="col-sm-12 p-0 pt-3 active_filters">
                    <h2>Active Filters</h2>
                    <div className="save-filter">
                      {/* <span
                              onClick={() =>
                                this.openModal("openSupplierFilterModal")
                              }
                            >
                              Save filter
                            </span> */}
                      <Link to="#" onClick={this.addActiveFilters}>
                        Save filter
                      </Link>
                    </div>
                    <ul className="active_filter_list">
                      {this.state.activeFilters?.map((a, i) => {
                        return (
                          <li>
                            {this.state.actvFilter === i ? (
                              <span
                                onClick={() => this.deleteActiveFilter(a, i)}
                                className="fa fa-close cursorPointer activeFilter"
                              ></span>
                            ) : (
                              <span className="cursorPointer">
                                <img
                                  onClick={() => this.deleteActiveFilter(a, i)}
                                  src="images/close-icon-gray.png"
                                />
                              </span>
                            )}

                            <span
                              className={
                                this.state.actvFilter === i
                                  ? "cursorPointer activeFilter"
                                  : "cursorPointer"
                              }
                              onClick={() => this.handleActiveFilter(a, i)}
                            >
                              {" "}
                              {a.description}
                            </span>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                  <div className="col-sm-12 active_filters_table2"></div>
                  <div className="clear"></div>
                  <div className="col-sm-12 p-0 active_filters">
                    <h2>Workspace Filters</h2>
                    <div className="save-filter">
                      <Link to="#" onClick={this.addWorkspaceFilters}>
                        Save filter
                      </Link>
                    </div>
                    <ul className="active_filter_list">
                      {this.state.workSpaceFilters?.map((w, i) => {
                        return (
                          <li>
                            {this.state.activeWorkFilter === i ? (
                              <span
                                onClick={() => this.deleteWorkSpaceFilter(w, i)}
                                className="fa fa-close cursorPointer activeFilter"
                              ></span>
                            ) : (
                              <span className="cursorPointer">
                                <img
                                  onClick={() =>
                                    this.deleteWorkSpaceFilter(w, i)
                                  }
                                  src="images/close-icon-gray.png"
                                />
                              </span>
                            )}

                            <span
                              className={
                                this.state.activeWorkFilter === i
                                  ? "cursorPointer activeFilter"
                                  : "cursorPointer"
                              }
                              onClick={() => this.handleWorkSpaceFilter(w, i)}
                            >
                              {" "}
                              {w.description}
                            </span>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                  <div className="clear10"></div>
                  <div>
                    <button
                      className="ml-2 clear-filter"
                      onClick={this.clearAllFilters}
                    >
                      Clear
                    </button>

                    <div className="pull-right plus_icon-filter_bottom">
                      <img
                        onClick={() =>
                          this.setState({ filter_dropdpwn2: true })
                        }
                        src="images/user-setup/plus_icon-filter_bottom.png"
                        alt=""
                      />
                    </div>
                  </div>
                </div>

                {/* second drop down 2*/}
                <div id="supplier_filter_dropdpwn2">
                  <div className="filter_dropdpwn2_toparea p-0">
                    <div className="col-sm-12 p-0">
                      <h2 className="pl-3 pt-3 pb-1">Add Filters</h2>
                      <div className="can-sav-btn">
                        <button
                          onClick={this.saveFilters}
                          className="btn can-btn1"
                        >
                          <img src="images/save-check.png" alt="check"></img>
                          Save
                        </button>
                        <button
                          onClick={this.closeFilterPopup}
                          className="btn can-btn1 pl-3 close_it"
                        >
                          <img src="images/cancel.png" alt="check"></img>
                          Cancel
                        </button>
                      </div>
                      <hr />
                    </div>
                    <div className="row pb-30">
                      <div className="col sec-pop pr-0">
                        <ul>
                          {this.state.fltrsColmnFirst.map((f, i) => {
                            return (
                              <li
                                className={f.checked ? "b-active" : ""}
                                key={i}
                                onClick={() => this.addFilters(f, "first")}
                              >
                                {f.name}
                                {f.checked ? (
                                  <span className="fa fa-check icon-check"></span>
                                ) : (
                                  ""
                                )}
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                      <div className="col sec-pop pl-0 pr-0 check_active_ul ">
                        <ul className="pr-0">
                          <ul className="pr-0">
                            {this.state.fltrsColmnSecond.map((f, i) => {
                              return (
                                <li
                                  className={f.checked ? "b-active" : ""}
                                  key={i}
                                  onClick={() => this.addFilters(f, "second")}
                                >
                                  {f.condition}
                                  {f.checked ? (
                                    <span className="fa fa-check icon-check"></span>
                                  ) : (
                                    ""
                                  )}
                                </li>
                              );
                            })}
                          </ul>
                        </ul>
                      </div>
                      <div className="col sec-pop1 pl-0">
                        <ul>
                          <li className="border-bottom">
                            <div className="">
                              <input
                                placeholder="Value"
                                type={this.state.showDate ? "date" : "text"}
                                className="cus-in"
                                name="filterValue"
                                value={this.state.filterValue}
                                onChange={(e) =>
                                  this.handleFilterValue(e, "secondPopup")
                                }
                              />
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="clear10"></div>
                  </div>
                </div>
                {/* end dropdown */}

                <div className="col-12 col-lg-4 col-md-6 pl-md-0 mt-4">
                  <div className="d-flex justify-content-center h-100">
                    <div className="searchbar">
                      <input
                        className="search_input"
                        type="text"
                        placeholder="Search..."
                        name="detailsListSearch"
                        value={this.state.detailsListSearch}
                        onChange={this.onDetailsListSearch}
                      />
                      <a
                        href="javascript:void(0)"
                        className="search_icon search-boredr"
                      >
                        <i className="fa fa-search"></i>
                      </a>
                      <a
                        href="javascript:void(0)"
                        className="search_icon"
                        id="filter_dropdown"
                      >
                        <i className="fa fa-filter"></i>
                      </a>
                    </div>
                  </div>
                </div>

                <div className="row mt-3">
                  <div className="col-12">
                    <div className="login_form">
                      <div className="login_table_list table-responsive tab-1-line supplier2_table ">
                        <table
                          className="table  busines_unit_table shadow-remove"
                          style={{ borderBottom: "1px solid #111" }}
                          id="transactionsDetail"
                        >
                          <thead>
                            <tr>
                              <th
                                scope="col"
                                className="exp_th1 mm_contact-name"
                              >
                                <div className="form-group">
                                  <label className="dash_container dash_remember table-check unckeck">
                                    <input
                                      type="checkbox"
                                      name={"checkboxAll"}
                                      id={"chk1"}
                                      // checked={checkAllJrnltem}
                                      onChange={(e) =>
                                        this.handleCheckboxes(e, "allCheck")
                                      }
                                    />
                                    <span
                                      id="chk1"
                                      className="dash_checkmark"
                                    ></span>
                                  </label>
                                </div>
                              </th>
                              {this.state.bankTransactionColumn?.map(
                                (col, i) => {
                                  return (
                                    <th
                                      scope="col"
                                      key={i}
                                      className="text-left exp-supplier-th"
                                    >
                                      <span className="table_lines_hed">
                                        {" "}
                                        {col.ColName}
                                      </span>
                                    </th>
                                  );
                                }
                              )}
                              <th>
                                <span
                                  className="user_setup_hed2"
                                  onClick={() =>
                                    this.openModal(
                                      "openBankTransactionSettingsModal"
                                    )
                                  }
                                >
                                  {" "}
                                  <img
                                    src="./images/user-setup/bars.png"
                                    alt="bars"
                                  ></img>
                                </span>
                              </th>
                            </tr>
                            <tr className="text-left"></tr>
                          </thead>
                          <tbody>
                            {this.state.bankTransactionRows?.map((item, i) => {
                              return (
                                <tr key={i}>
                                  {item.data === null ? (
                                    ""
                                  ) : (
                                    <td>
                                      <div
                                        className="col align-self-center text-center pr-0"
                                        style={{ marginLeft: "14px" }}
                                      >
                                        <div className="form-group mb-0 check-line">
                                          <label className="dash_container dash_remember table-check unckeck">
                                            <input
                                              type="checkbox"
                                              name={"checkboxAll"}
                                              id={"chk1" + i}
                                              checked={item.checked}
                                              onChange={(e) =>
                                                this.handleCheckboxes(e, item)
                                              }
                                            />
                                            <span
                                              id={"chk1" + i}
                                              className="dash_checkmark"
                                            ></span>
                                          </label>
                                        </div>
                                      </div>
                                    </td>
                                  )}
                                  {this.state.bankTransactionColumn?.map(
                                    (c, j) => {
                                      let ColType = c.ColType;
                                      if (
                                        ColType === "Number" ||
                                        ColType === "Numeric"
                                      ) {
                                        return (
                                          <>
                                            {item.data === null ? (
                                              <td className="text-left"></td>
                                            ) : (
                                              <td
                                                className="text-left"
                                                data-order={item.data[j]}
                                              >
                                                <input
                                                  type="number"
                                                  name="chartSort"
                                                  value={item.data[j]}
                                                  className="input_height wd-108"
                                                  readOnly={
                                                    c.Editable ? false : true
                                                  }
                                                  onBlur={
                                                    this.updateBankTransactions
                                                  }
                                                  onChange={(e) => {
                                                    this.handleChangeGeneralLedgerList(
                                                      e,
                                                      item.data,
                                                      i,
                                                      j
                                                    );
                                                  }}
                                                />
                                              </td>
                                            )}
                                          </>
                                        );
                                      } else if (ColType === "Date") {
                                        return (
                                          <>
                                            {item.data === null ? (
                                              <td className="text-left"></td>
                                            ) : (
                                              <td
                                                className="text-left"
                                                data-order={item.data[j]}
                                              >
                                                <input
                                                  type="date"
                                                  name="chartSort"
                                                  // required
                                                  // pattern="\d{2}-\d{2}-\d{4}"
                                                  // value={moment(
                                                  //   item.data[j]
                                                  // ).format("YYYY-DD-MM")}
                                                  // value={() =>
                                                  //   this.GetFormattedDate(
                                                  //     item.data[j]
                                                  //   )
                                                  // }
                                                  value={item.data[j]
                                                    .split("/")
                                                    .reverse()
                                                    .join("-")}
                                                  className="input_height wd-130"
                                                  readOnly={
                                                    c.Editable ? false : true
                                                  }
                                                  onBlur={
                                                    this.updateBankTransactions
                                                  }
                                                  onChange={(e) => {
                                                    this.handleChangeGeneralLedgerList(
                                                      e,
                                                      item.data,
                                                      i,
                                                      j
                                                    );
                                                  }}
                                                />
                                              </td>
                                            )}
                                          </>
                                        );
                                      } else if (ColType === "Check") {
                                        return (
                                          <>
                                            {item.data === null ? (
                                              <td className="text-left"></td>
                                            ) : (
                                              <td
                                                className="text-left"
                                                data-order={item.data[j]}
                                                key={j}
                                              >
                                                <input
                                                  type="checkbox"
                                                  name="chartSort"
                                                  // value={item.data[j]}
                                                  checked={
                                                    // item.checked
                                                    item.data[j] === "Y" ||
                                                    item.data[j] === "1"
                                                      ? true
                                                      : false
                                                    // item.data[j]
                                                  }
                                                  className="input_height wd-108"
                                                  readOnly={
                                                    c.Editable ? false : true
                                                  }
                                                  onBlur={
                                                    this.updateBankTransactions
                                                  }
                                                  onChange={(e) => {
                                                    this.handleCheck(
                                                      e,
                                                      item.data,
                                                      i,
                                                      j
                                                    );
                                                  }}
                                                />
                                              </td>
                                            )}
                                          </>
                                        );
                                      } else {
                                        return (
                                          <>
                                            {item.data === null ? (
                                              <td className="text-left"></td>
                                            ) : (
                                              <td
                                                className="text-left"
                                                data-order={item.data[j]}
                                              >
                                                <input
                                                  type="text"
                                                  name="chartSort"
                                                  value={item.data[j]}
                                                  className="input_height wd-108"
                                                  readOnly={
                                                    c.Editable ? false : true
                                                  }
                                                  onBlur={
                                                    this.updateBankTransactions
                                                  }
                                                  onChange={(e) => {
                                                    this.handleChangeGeneralLedgerList(
                                                      e,
                                                      item.data,
                                                      i,
                                                      j
                                                    );
                                                  }}
                                                />
                                              </td>
                                            )}
                                          </>
                                        );
                                      }
                                    }
                                  )}
                                  <td></td>
                                  {/* <td
                                    className="text-left"
                                    data-order={""}
                                  ></td> */}
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                        <div
                          style={{
                            display: "flex",
                            marginTop: "29px",
                          }}
                        >
                          <span className="mb__zero1">
                            Showing {this.state.pageStart} to{" "}
                            {this.state.pageEnd} of {this.state.totalRecords}{" "}
                            entries
                          </span>{" "}
                          <ReactPaginate
                            tabIndex="-1"
                            previousLabel={"Previous"}
                            pageLinkClassName={"bank-transaction-link"}
                            pageClassName={"bank-transaction-paginate  "}
                            activeLinkClassName={"active-link"}
                            nextLabel={"Next"}
                            breakLabel={"..."}
                            breakClassName={"break-me"}
                            pageCount={this.state.totalPages}
                            marginPagesDisplayed={1}
                            pageRangeDisplayed={2}
                            onPageChange={this.props.handlePageClick}
                            containerClassName={
                              "pagination cursorPointer align-center-container"
                            }
                            activeClassName={"active"}
                            forcePage={this.state.page - 1}
                            previousLinkClassName={"label-color"}
                            nextLinkClassName={"label-color"}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Import
          openImportModal={this.state.openImportModal}
          type={this.state.type}
          onImport={this.onImport}
          closeModal={this.closeModal}
        />
        <BankTransactionSettings
          openBankTransactionSettingsModal={
            this.state.openBankTransactionSettingsModal
          }
          openModal={this.openModal}
          closeModal={this.closeModal}
          handleChangeSettings={this.props.handleChangeSettings}
          display={this.props.display}
          bankTransactionColumn={this.state.bankTransactionColumn}
          getBankTransactions1={this.props.getBankTransactions1}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  account: state.account,
  chart: state.chart,
});

export default connect(mapStateToProps, {
  getBankTransactions: AccountActions.getBankTransactions,
  getImportExcel: AccountActions.getImportExcel,
  getExportExcel: AccountActions.getExportExcel,
  updateBankTransactions: AccountActions.updateBankTransactions,
  deleteBankTransactions: AccountActions.deleteBankTransactions,
  getReconcileBank: AccountActions.getReconcileBank,
})(BankTransaction);
