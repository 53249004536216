import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Form, Row, Col, InputGroup } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import DatePicker from "react-datepicker";
import moment from "moment";
import { toast } from "react-toastify";
import TopNav from "../../Common/TopNav/TopNav";
import AdminHeader from "../../Common/AdminHeader/AdminHeader";
// import VideoTutorials from "../../Common/Alerts/VideoTutorials";
import {
  resetInbox,
  fetchEmails,
  fetchEmailSortOptions,
  downloadEmailAttachments,
  approvalEmail
} from "../../../Actions/EmailActions/EmailsAction";
import "react-datepicker/dist/react-datepicker.css";
import "./EmailInbox.css";
import { ActionButtons } from "./ActionButtons";
import { handleAPIErr } from "../../../Utils/Helpers"

export function EmailInbox({ inboxType, props }) {
  const title = inboxType === "incoming" ? "Incoming" : "Outgoing";

  const paginationTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} entries
    </span>
  );

  const pagingConfig = {
    page: 1,
    paginationSize: 5,
    sizePerPage: 50,
    withFirstAndLast: true,
    showTotal: true,
    paginationTotalRenderer: paginationTotal,
    hideSizePerPage: true,
    firstPageText: "First",
    lastPageText: "Last",
    nextPageText: "Next",
    prePageText: "Previous",
    hidePageListOnlyOnePage: true,
  };

  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.emails.loading);
  const emails = useSelector((state) => state.emails.emails);
  const index = useSelector((state) => state.emails.index);
  const totalRecords = useSelector((state) => state.emails.totalRecords);
  const sortOptions = useSelector((state) => state.emails.sort);
  const attachments = useSelector((state) => state.emails.attachments);
  const errorFetchEmail = useSelector((state) => state.emails.errorFetchEmail);
  const failedDownloadAttachments = useSelector(
    (state) => state.emails.downloadFail
  );
  const [pagination, setPagination] = useState(pagingConfig);
  const [keywordText, setKeywordText] = useState("");
  const [keyword, setKeyword] = useState("");
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const [sort, setSort] = useState("");
  const [sortOrder, setSortOrder] = useState("Ascend");
  const [currentIndex, setCurrentIndex] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [showCustomPageSize, setShowCustomPageSize] = useState(false);
  const [expandedRows, setExpandedRows] = useState([]);
  const [expandedView, setExpandedView] = useState("message");
  const [fromDateAdjusted, setFromDateAdjusted] = useState(false);
  const [toDateAdjusted, setToDateAdjusted] = useState(false);
  const [fromDateSelected, setFromDateSelected] = useState('');
  const [toDateSelected, setToDateSelected] = useState('');

  const columns = [
    {
      dataField: "id",
      text: "ID",
    },
    {
      dataField: "address",
      text: "To Email",
    },
    {
      dataField: "subject",
      text: "Subject",
    },
    {
      dataField: "messageSize",
      text: "Message Size",
    },
    {
      dataField: "attachmentCount",
      text: "Attachment Count",
    },
    {
      dataField: "triggeredBy",
      text: "Triggered By",
    },
    {
      dataField: "status",
      text: "Status",
    },
    {
      dataField: "dateUnix",
      text: "TimeStamp",
      style: { width: "180px" },
      formatter: (timestamp) => {
        return moment(timestamp * 1000).format("DD/MM/YYYY hh:mm A");
      },
    },
    {
      dataField: "id",
      style: { width: "240px" },
      formatter: (id, email) => {
        return (
          <ActionButtons
            recordId={id}
            attachmentCount={email.attachmentCount}
            onDownloadClick={handleDownloadClick}
            onShowAttachmentsClick={handleShowAttachments}
            onShowMessageClick={handleMessageExpand}
          />
        );
      },
    },
  ];

  const columnsIncoming = [
    {
      dataField: "id",
      text: "ID",
    },
    {
      dataField: "address",
      text: "To Email",
    },
    {
      dataField: "fromAddress",
      text: "From Email",
    },
    {
      dataField: "subject",
      text: "Subject",
    },
    {
      dataField: "type",
      text: "Type",
    },
    {
      dataField: "messageSize",
      text: "Message Size",
    },
    {
      dataField: "attachmentCount",
      text: "Attachment Count",
    },
    {
      dataField: "triggeredBy",
      text: "Triggered By",
    },
    {
      dataField: "status",
      text: "Status",
    },
    {
      dataField: "dateUnix",
      text: "TimeStamp",
      style: { width: "180px" },
      formatter: (timestamp) => {
        return moment(timestamp * 1000).format("DD/MM/YYYY hh:mm A");
      },
    },
    {
      dataField: "id",
      style: { width: "300px" },
      formatter: (id, email) => {
        return (
          <ActionButtons
            recordId={id}
            attachmentCount={email.attachmentCount}
            onDownloadClick={handleDownloadClick}
            onShowAttachmentsClick={handleShowAttachments}
            onShowMessageClick={handleMessageExpand}
            email={email}
            inboxType={inboxType}
            onApprovalClick={handleApprovalClick}
          />
        );
      },
    },
  ];

  const expandMessageView = {
    renderer: (row) => (
      <div className="expanded-reading-view">
        {expandedView === "attachments" ? (
          <>
            {row.attachmentCount > 0 && row.attachments?.length > 0 ? (
              <div className="email-attachments-view">
                <ul>
                  {row.attachments.map(({ file }) => (
                    <li key={file}>{file}</li>
                  ))}
                </ul>
              </div>
            ) : null}
          </>
        ) : (
          <div className="email-message-view">{`${row.message}`}</div>
        )}
      </div>
    ),
    expanded: expandedRows,
    onExpand: handleOnMessageExpand,
  };

  function dateToUnix(date) {
    const inputDate = new Date(date);
    const year = inputDate.getFullYear();
    const month = String(inputDate.getMonth() + 1).padStart(2, "0");
    const day = String(inputDate.getDate()).padStart(2, "0");
    const formattedDateString = `${year}/${month}/${day} 00:00:00`;
    const timestamp = moment(formattedDateString, "YYYY/MM/DD hh:mm:ss")
      .utc()
      .valueOf();
    return timestamp;
  }

  function search() {
    dispatch(
      fetchEmails(
        inboxType,
        keyword,
        dateToUnix(fromDate),
        dateToUnix(toDate),
        sort,
        sortOrder,
        currentIndex,
        pageSize
      )
    );
  }

  function initPage() {
    dispatch(resetInbox());
    dispatch(fetchEmailSortOptions());
  }

  function updatePagination() {
    const config = {
      ...pagingConfig,
      sizePerPage: parseInt(pageSize),
      page: parseInt(currentIndex),
      totalSize: parseInt(totalRecords),
    };
    setPagination(config);
  }

  function downloadAttachments() {
    if (failedDownloadAttachments) {
      toast.error("Unable to download attachments. Please try again");
      console.log("Attachments are empty. Unable to download attachments");
      return;
    }

    if (attachments) {
      const isValidURL = (string) => {
        const res = string.match(
          /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi
        );
        return res !== null;
      };

      const element = document.createElement("a");
      if (isValidURL(attachments)) {
        element.setAttribute("href", attachments);
      } else {
        element.setAttribute("href", "data:text/plain;base64," + attachments);
      }

      element.setAttribute("download", "Attachment.zip");

      element.style.display = "none";
      document.body.appendChild(element);

      element.click();

      document.body.removeChild(element);
    }
  }

  const handleTableChange = (_type, { page }) => {
    setCurrentIndex(page);
  };

  function handleShowAttachments(e, recordId) {
    e.preventDefault();
    e.stopPropagation();

    setExpandedView("attachments");
    if (!expandedRows.includes(recordId)) {
      setExpandedRows([...expandedRows, recordId]);
    } else {
      setExpandedRows(expandedRows.filter((x) => x !== recordId));
    }
  }

  function handleDownloadClick(e, recordId) {
    e.preventDefault();
    e.stopPropagation();

    dispatch(downloadEmailAttachments(recordId, inboxType));
  }

  function handleApprovalClick(e, email) {
    e.preventDefault();
    e.stopPropagation();

    dispatch(approvalEmail(email.address, email.fromAddress, email.subject));
  }

  function handleMessageExpand(e, recordId) {
    e.preventDefault();
    e.stopPropagation();

    setExpandedView("message");
    if (!expandedRows.includes(recordId)) {
      setExpandedRows([...expandedRows, recordId]);
    } else {
      setExpandedRows(expandedRows.filter((x) => x !== recordId));
    }
  }

  function handleOnMessageExpand(row, isExpand, rowIndex, e) {
    if (isExpand) {
      setExpandedRows([...expandedRows, row.id]);
    } else {
      setExpandedRows(expandedRows.filter((x) => x !== row.id));
    }
  }

  function handleSearch() {
    setKeyword(keywordText);
    setCurrentIndex(1);
  }

  function handleKeywordChange(e) {
    e.preventDefault();
    setKeywordText(e.target.value);
  }

  function handleKeywordKeyDown(e) {
    if (e.key === "Enter") {
      e.preventDefault();
      setKeyword(e.target.value);
      setCurrentIndex(1);
    }
  }

  function handleSortOrderChange(e) {
    setSortOrder(e.target.value);
  }

  function handleSortChange(e) {
    setSort(e.target.value);
  }

  function handleToDateChange(date) {
    const dateInAEDT = new Date(date);
    dateInAEDT.setHours(0, 0, 0, 0);
    const parsedDate = dateInAEDT.getTime() - (dateInAEDT.getTimezoneOffset() * 60000);
    setToDateAdjusted(true);
    setToDateSelected(date);
    setToDate(parsedDate);
    setCurrentIndex(1);
  }

  function handleFromDateChange(date) {
    const dateInAEDT = new Date(date);
    dateInAEDT.setHours(0, 0, 0, 0);
    const parsedDate = dateInAEDT.getTime() - (dateInAEDT.getTimezoneOffset() * 60000);
    setFromDateAdjusted(true);
    setFromDateSelected(date);
    setFromDate(parsedDate);
    setCurrentIndex(1);
  }

  function handlePageSizeChange(e) {
    const size = e.target.value;
    if (size === "CUSTOM") {
      setShowCustomPageSize(true);
    } else {
      setShowCustomPageSize(false);
      setCurrentIndex(1);
      setPageSize(e.target.value);
    }
  }

  function handleCustomPageSizeKeyboardEvent(e) {
    const updatePageSize = () => {
      const value = parseInt(e.target.value);
      if (!isNaN(value) && value > 0) {
        setCurrentIndex(1);
        setPageSize(e.target.value);
      }
    };

    if (e.type === "blur") {
      updatePageSize();
    } else if (e.type === "keydown" && e.code === "Enter") {
      e.preventDefault();
      updatePageSize();
    }
  }

  const formatDateWithoutTimeZone = (timestamp) => {
    if(!timestamp) return null;
    const date = new Date(timestamp);
    const userTimezoneOffset = date.getTimezoneOffset() * 60000;
   const utcDate = new Date(date.getTime() + userTimezoneOffset);
   return utcDate;
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(initPage, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(updatePagination, [emails, index, totalRecords, pageSize]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(search, [
    inboxType,
    keyword,
    fromDate,
    toDate,
    sort,
    sortOrder,
    currentIndex,
    pageSize,
  ]);

  useEffect(downloadAttachments, [attachments, failedDownloadAttachments]);

  useEffect(() => {
    if(errorFetchEmail) {
      handleAPIErr(errorFetchEmail, props)
    }
  }, [errorFetchEmail])


  function getColumns() {
    if(inboxType === "incoming") {
      return columnsIncoming
    } else {
      return columns
    }
  }
  
  return (
    <>
      {isLoading ? <div className="se-pre-con"></div> : ""}
      <div className="user_setup_main">
        <header>
          <TopNav />
          <AdminHeader title={`${title} Emails`} />
          {/* <VideoTutorials /> */}
        </header>
        <div className="table_white_box table_white_box_sass">
          <div className="filter-panel">
            <Row>
              <Col>
                <div className="search-filters-wrapper">
                  <Form>
                    <Row>
                      <Form.Group as={Col}>
                        <InputGroup>
                          <Form.Control
                            placeholder="Search"
                            type="text"
                            onChange={handleKeywordChange}
                            onKeyDown={handleKeywordKeyDown}
                          />
                          <button
                            type="button"
                            className="search-button"
                            onClick={handleSearch}
                          >
                            <img src="images/search-icon.png" alt="Search" />
                          </button>
                        </InputGroup>
                      </Form.Group>
                      <Form.Group as={Col}>
                        <Row>
                          <Col xs="auto">
                            <Form.Label
                              htmlFor="fromDatePicker"
                              className="col-form-label"
                            >
                              From
                            </Form.Label>
                          </Col>
                          <Col>
                            <DatePicker
                              id="fromDatePicker"
                              dateFormat="dd/MM/yyyy"
                              className="form-control"
                              selected={!fromDateAdjusted ? formatDateWithoutTimeZone(fromDate) : fromDateSelected}
                              onChange={handleFromDateChange}
                            />
                          </Col>
                        </Row>
                      </Form.Group>
                      <Form.Group as={Col}>
                        <Row>
                          <Col xs="auto">
                            <Form.Label
                              htmlFor="toDatePicker"
                              className="col-form-label"
                            >
                              To
                            </Form.Label>
                          </Col>
                          <Col>
                            <DatePicker
                              id="toDatePicker"
                              dateFormat="dd/MM/yyyy"
                              className="form-control"
                              selected={!toDateAdjusted ? formatDateWithoutTimeZone(toDate) : toDateSelected}
                              onChange={handleToDateChange}
                            />
                          </Col>
                        </Row>
                      </Form.Group>
                    </Row>
                  </Form>
                </div>
              </Col>
              <Col lg="auto">
                <div className="sort-config-wrapper">
                  <Form>
                    <Row>
                      <Form.Group as={Col} className="sortby-controller">
                        <Form.Control
                          as="select"
                          value={sort}
                          onChange={handleSortChange}
                        >
                          <option value="">Sort By...</option>
                          {sortOptions && sortOptions.map((sortOption) => (
                            <option
                              key={sortOption.option}
                              value={sortOption.option}
                            >
                              {sortOption.option}
                            </option>
                          ))}
                        </Form.Control>
                      </Form.Group>
                      <Form.Group as={Col}>
                        <Form.Control
                          as="select"
                          value={sortOrder}
                          onChange={handleSortOrderChange}
                        >
                          <option value="Ascend">ASC</option>
                          <option value="Descend">DESC</option>
                        </Form.Control>
                      </Form.Group>
                      <Form.Group as={Col}>
                        <Form.Control
                          as="select"
                          onChange={handlePageSizeChange}
                        >
                          <option value="50">50</option>
                          <option value="100">100</option>
                          <option value="150">150</option>
                          <option value="200">200</option>
                          <option value="CUSTOM">Custom</option>
                        </Form.Control>
                      </Form.Group>
                      {showCustomPageSize ? (
                        <Form.Group as={Col}>
                          <Form.Control
                            type="number"
                            onKeyDown={handleCustomPageSizeKeyboardEvent}
                          ></Form.Control>
                        </Form.Group>
                      ) : null}
                    </Row>
                  </Form>
                </div>
              </Col>
            </Row>
          </div>
          <div id="email-table-wrapper">
            <BootstrapTable
              remote
              striped
              hover
              keyField="id"
              data={emails}
              columns={getColumns()}
              expandRow={expandMessageView}
              loading={isLoading}
              onTableChange={handleTableChange}
              pagination={paginationFactory(pagination)}
            />
          </div>
        </div>
      </div>
    </>
  );
}
