import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import ReactSelect from "react-select";

const LoginWithExternalIDPModal = ({ isOpen, onClose, onSubmit }) => {
  const [companyId, setCompanyId] = useState("");
  const [companyIdError, setCompanyIdError] = useState(null);
  const [region, setRegion] = useState(-1);
  const [regionError, setRegionError] = useState(null);

  const handleSubmit = () => {
    setCompanyIdError(null);
    setRegionError(null);
    if (!companyId || companyId === "") {
      setCompanyIdError("Company id is required");
      return;
    }

    if (region === -1) {
      setRegionError("Region is required");
      return;
    }

    onSubmit(companyId, region.value);
  };

  return (
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={isOpen}
      onHide={onClose}
      className="forgot_email_modal"
    >
      <Modal.Body>
        <div className="container-fluid">
          <div className="main_wrapper">
            <div className="row d-flex h-100">
              <div className="col-12 justify-content-center align-self-center form_mx_width">
                <div className="forgot_form_main">
                  <h4>Login with external IDP</h4>
                  <div className="form-group">
                    <label>Tenant ID</label>
                    <input
                      type="text"
                      className="form-control"
                      id="companyID2"
                      placeholder="Tenant ID"
                      name="companyID"
                      tabIndex="5"
                      value={companyId}
                      onChange={(e) => setCompanyId(e.target.value)}
                    />
                    <div className="text-danger error-12">
                      {companyIdError ?? ""}
                    </div>
                  </div>

                  <div className="form-group">
                    <label>Region</label>
                    <ReactSelect
                      classNamePrefix="custon_select-selector-inner main__dropdown--wrapper1"
                      value={region}
                      options={[
                        { label: "AU", value: "AU" },
                        { label: "UK", value: "UK" },
                      ]}
                      onChange={(obj) => {
                        setRegion(obj);
                      }}
                    />
                    <div className="text-danger error-12">
                      {regionError ?? ""}
                    </div>
                  </div>
                  <div className="d-flex justify-content-end">
                    <button
                      type="button"
                      className={"btn btn_white float-left"}
                      id="id_cancel"
                      tabIndex="7"
                      onClick={() => {
                        onClose();
                        setCompanyId(null);
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      tabIndex="6"
                      className={"btn btn_blue float-right ml-2"}
                      id="id_proceed"
                      onClick={handleSubmit}
                    >
                      Login
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default LoginWithExternalIDPModal;
