const INITIAL_STATE = {
  loading: false,
  logs: [],
  totalRecords: 0,
  index: 0,
  hasError: false,
  categories: [],
  sort: [],
};

export default function AuditLogReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "AUDIT_LOG_INIT":
      return {
        ...state,
        loading: true,
        hasError: false,
      };
    case "AUDIT_LOG_FETCHED":
      return {
        ...state,
        logs: action.payload.plogRecords,
        totalRecords: action.payload.totalRecords,
        index: action.payload.page,
        loading: false,
        hasError: false,
      };
    case "AUDIT_LOG_FETCH_FAILED":
      return {
        ...state,
        loading: false,
        hasError: true,
      };

    case "AUDIT_OPTIONS_INIT":
      return {
        ...state,
        categories: [],
        sort: [],
      };
    case "AUDIT_OPTIONS_FETCHED":
      return {
        ...state,
        categories: action.payload.categories,
        sort: action.payload.sort,
      };
    case "AUDIT_OPTIONS_FAILED":
      return {
        ...state,
        categories: [],
        sort: [],
      };
    default:
      return state;
  }
}
