const INIT_STATE = {
  webSocketConnectSuccess: "",
  webSocketConnectFailed: "",
  webSocketDisconnected: "",
  webSocketMessageReceived: "",
};

export default function WebSocketReducer(state = INIT_STATE, action) {
  switch (action.type) {
    case "WEB_SOCKET_CONNECTED":
      return {
        ...state,
        webSocketConnectSuccess: true,
        webSocketConnectFailed: false,
      };

    case "WEB_SOCKET_DISCONNECTED":
      return {
        ...state,
        webSocketDisconnected: true,
        webSocketConnectSuccess: false,
        webSocketConnectFailed: false,
        webSocketMessageReceived: "",
      };

    case "WEB_SOCKET_MESSAGE_RECEIVED":
      return {
        ...state,
        webSocketMessageReceived: action.payload,
      };
    default:
      return state;
  }
}
