import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import "../BankSettings/BankSettings.css";

export default function Settings(props) {
  const onSave = () => {
    props.getBankTransactions1();
    props.closeModal("openBankTransactionSettingsModal");
  };
  return (
    <>
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={props.openBankTransactionSettingsModal}
        onHide={() => props.closeModal("openBankTransactionSettingsModal")}
        className="modal__setting mx-auto"
      >
        <Modal.Body>
          <div className="container-fluid p-0">
            <div className="main_wrapper">
              <div className="row d-flex h-100 p-0">
                <div className="col-12 justify-content-center align-self-center">
                  <div className="setting_form_main p-0">
                    <div className="setting_header thead_bg">
                      <h3 className="settings_heading">Settings</h3>
                      <div className="settings_can-sav-btn">
                        <button className="btn can-btn1" onClick={onSave}>
                          <img
                            src="images/user-setup/check-white.png"
                            alt="check"
                          />
                          Save
                        </button>
                        <button
                          onClick={() =>
                            props.closeModal("openBankTransactionSettingsModal")
                          }
                          className="btn can-btn1 pl-3"
                        >
                          <img
                            src="images/user-setup/cancel-white.png"
                            alt="cancel"
                          />
                          Cancel
                        </button>
                      </div>
                    </div>
                    <div className="setting_body">
                      <div className="settings_display_row">
                        <label>
                          Display Rows Per Page
                          <span className="number-wrapper">
                            <input
                              type="number"
                              name="display"
                              min="1"
                              onBlur={(e) => props.handleChangeSettings(e)}
                              defaultValue={props.display}
                            />
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
