import React, { useState, useEffect, useMemo } from "react";
import Modal from "react-bootstrap/Modal";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import { toast } from "react-toastify";
import {
  handleValidation,
  handleWholeValidation,
} from "../../../../Utils/Validation";
export default function Import(props) {
  const [state, setState] = useState({
    importData: "",
    isLoading: false,
    formErrors: {
      importData: "",
    },
  });

  useEffect(() => {}, [props]);

  const onImport = async () => {
    let { formErrors, importData } = state;
    formErrors = handleWholeValidation({ importData }, formErrors);
    if (!formErrors.importData) {
      await props.onImport(props.type, importData);
      //2nd param valid only for import invoice case
      clearStates();
    }
    setState((prev) => ({ ...prev, formErrors }));
  };
  const handleChangeData = (e) => {
    let { value } = e.target;
    let { formErrors } = state;

    formErrors = handleValidation("importData", value, formErrors);
    setState((prev) => ({ ...prev, importData: value, formErrors }));
  };
  const clearStates = () => {
    setState((prev) => ({
      ...prev,
      importData: "",
      formErrors: {
        importData: "",
      },
    }));
    props.closeModal("openImportModal");
  };

  return (
    <>
      {state.isLoading ? <div className="se-pre-con"></div> : ""}

      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={props.openImportModal}
        onHide={clearStates}
        className="modal_704 mx-auto reports-v4-modal"
      >
        <Modal.Body>
          <div className="container-fluid p-0">
            <div className="main_wrapper">
              <div className="row d-flex h-100 p-0">
                <div className="col-12 justify-content-center align-self-center">
                  <div className="setting_form_main p-0">
                    <div className="setting_header thead_bg">
                      <h3 className="Indirecttaxcode-poup_heading">Import</h3>
                      <div className="Indirecttaxcode-poup_can-sav-btn">
                        <button className="btn can-btn1" onClick={onImport}>
                          <img
                            src="images/user-setup/check-white.png"
                            alt="check"
                          />
                          Save
                        </button>
                        <button
                          onClick={clearStates}
                          className="btn can-btn1 pl-3"
                        >
                          <img
                            src="images/user-setup/cancel-white.png"
                            alt="cancel"
                          />
                          Cancel
                        </button>
                        <button className="btn can-btn1 pl-2">
                          <img src="images/user-setup/dots-h.png" alt="dots" />
                        </button>
                      </div>
                    </div>

                    <div className="reportv4-modal-inner">
                      <div className="row no-gutters mt-2">
                        <div className="col-12 mt-1">
                          <div className="form-group custon_select text-center mb-0 w-100 ">
                            <textarea
                              rows="4"
                              className="w-100"
                              name="importData"
                              form="usrform"
                              id="importData"
                              defaultValue={state.importData}
                              placeholder="Paste Spreadsheet Data Here..."
                              onBlur={handleChangeData}
                            />
                          </div>
                          <div className="text-danger error-12  user-required-field">
                            {/* {formErrors.excelData ? formErrors.excelData : ""} */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
