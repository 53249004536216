export default function ShowAttachmentsButton() {
    return (
      <svg
        width={32}
        height={32}
        fill="#000000"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 52 52"
        enable-background="new 0 0 52 52"
      >
        <g>
          <path
            d="M17.6,36.7c0.6,0.6,1.5,0.5,2.1,0l10-10c0.7-0.7,1.9-0.8,2.8,0c0.9,0.8,0.8,2.2,0,3L20.2,41.8
		c-2.7,2.7-7.2,2.7-9.9,0l-0.1-0.1c-2.7-2.7-2.7-7.2,0-9.9l21.7-21.7c2.7-2.7,7.2-2.7,9.9,0l0.1,0.1c2.7,2.7,2.7,7.2,0,9.9l-0.1,0.1
		c-0.5,0.5-0.6,1.2-0.2,1.8c0.6,1.1,1.1,2.3,1.4,3.5c0.2,0.8,1.1,1,1.7,0.5c0.8-0.8,1.5-1.6,1.5-1.6c5.1-5.1,5.1-13.4,0-18.5L46,5.9
		c-5.1-5.1-13.4-5.1-18.5,0L5.8,27.5c-5.1,5.1-5.1,13.4,0,18.5L6,46.2c5.1,5.1,13.3,5.1,18.4,0l12.4-12.3c3.2-3.2,3.1-8.4-0.2-11.6
		c-3.2-3.1-8.4-2.9-11.5,0.3l-9.8,9.8c-0.6,0.6-0.6,1.6,0,2.2L17.6,36.7z"
          />
        </g>
      </svg>
    );
}