import React, { Component } from "react";

import Modal from "react-bootstrap/Modal";
import Select from "react-select";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";

import moment from "moment";
import { toast } from "react-toastify";
import momentZones from "moment-timezone";
import $ from "jquery";
import { connect } from "react-redux";

import { _customStyles } from "../../../../../Constants/Constants";

import '../ColumnsPopupForm/ColumnsPopupForm.css'
//setting all dates and time in GMT format
momentZones.tz.setDefault("GMT");
class CategoryPopupForm extends Component {
  constructor() {
    super();
    this.state = {
      isLoading: false,
    };
  }

  async componentWillReceiveProps() {}

  clearStates = async () => {
    await this.props.closeModal("openCategoryPopupFormModal");
  };

  render() {
    return (
      <>
        {this.state.isLoading ? <div className="se-pre-con"></div> : ""}

        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={this.props.openCategoryPopupFormModal}
          onHide={this.clearStates}
          className="forgot_email_modal modal_704 mx-auto"
        >
          <Modal.Body>
          <div className="container-fluid p-0">
            <div className="main_wrapper">
              <div className="row d-flex h-100 p-0">
                <div className="col-12 justify-content-center align-self-center">
                  <div className="setting_form_main p-0">
                    <div className="setting_header thead_bg">
                      <h3 className="Indirecttaxcode-poup_heading">V Category's</h3>
                      <div className="Indirecttaxcode-poup_can-sav-btn">
                        <button className="btn can-btn1">
                          <img
                            src="images/user-setup/check-white.png"
                            alt="check"
                          />
                          Save
                        </button>
                        <button
                          onClick={this.clearStates}
                          className="btn can-btn1 pl-3"
                        >
                          <img
                            src="images/user-setup/cancel-white.png"
                            alt="cancel"
                          />
                          Cancel
                        </button>
                        <button className="btn can-btn1 pl-2">
                          <img src="images/user-setup/dots-h.png" alt="dots" />
                        </button>
                      </div>
                    </div>
                      <div className="user_setup-poup_body user-setup-modal-inner">
                        <div className="row w-100">
                          <div className="col-md-12">
                            <form action="" className="mm-new-form">
                              <div className="form-group d-flex">
                                <label htmlFor="" className="pr-2 ">
                                Sequence
                                </label>
                                <input type="number" className="form-control" />
                              </div>
                              <div className="form-group d-flex">
                                <label htmlFor="" className="pr-2 ">
                                Description
                                </label>
                                <input type="text" className="form-control" />
                              </div>
                              <div className="form-group d-flex">
                                <label htmlFor="" className="pr-2 ">
                                Toatl Description
                                </label>
                                <input type="text" className="form-control" />
                              </div>
                              <div className="form-group d-flex">
                                <label htmlFor="" className="pr-2 ">
                                Chart Sort Form
                                </label>
                                <input type="text" className="form-control" />
                              </div>
                              <div className="form-group d-flex">
                                <label htmlFor="" className="pr-2 ">
                                Chart Sort To
                                </label>
                                <input type="text" className="form-control" />
                              </div>
                              <div className="form-group d-flex">
                                <label htmlFor="" className="pr-2 ">
                                Chart Code Form
                                </label>
                                <input type="text" className="form-control" />
                              </div>
                              <div className="form-group d-flex">
                                <label htmlFor="" className="pr-2 ">
                                Chart Code To
                                </label>
                                <input type="text" className="form-control" />
                              </div>
                              <div className="form-group d-flex">
                                <label htmlFor="" className="pr-2 ">
                                Subtotal
                                </label>
                                <input type="number" className="form-control" />
                              </div>
                              <div className="form-group d-flex">
                                <label htmlFor="" className="pr-2 ">
                                Subtotal Description
                                </label>
                                <input type="text" className="form-control" />
                              </div>
                              
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => ({});
export default connect(mapStateToProps, {})(CategoryPopupForm);
