import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserAttribute,
} from "amazon-cognito-identity-js";
import cookie from "react-cookies";
import QR from "qrcode";
import * as Validation from "../../Utils/Validation";
import * as UserActions from "../../Actions/UserActions/UserActions";
import { getCognitoUserPoolWrapper } from "../../Config/CognitoConfig";
import MFAQRCodeModal from "./MFAQRCodeModal";
import LoginWithExternalIDPModal from "./LoginWithExternalIDPModal";
import ResetPasswordModal from "./ResetPasswordModal";
import { setupBackendRegion, isValidString, extractErrorMessage } from "../../Utils/Helpers";
import { getProductJWTTokens, setCognitoTokens } from "../../Utils/authHelpers";
import UserCompanySelectModal from "./UserCompanySelectModal";
import Verify2FACode from "../Modals/Verify2FACode/Verify2FACode";

const Login = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state) => state.user);

  const { userLogin, setUserLogin } = useState("");
  const { companyID, setCompanyID } = useState("");
  const { userPassword } = useState("");
  const [state, setState] = useState({
    formErrors: {},
    SSOAuthTenantId: null,
    userLogin: undefined,
    companyID: undefined,
    tenantId: undefined,
    region: undefined,
    userPassword: undefined,
  });
  const [companyIdTrigger, setCompanyIdTrigger] = useState(false);
  const [setup2FA, setSetup2FA] = useState(null);
  const [qrCodeImage, setQrCodeImage] = useState("");
  const [showTOTPMFAModal, setShowTOTPMFAModal] = useState(false);
  const [showLoginWithSSOModal, setShowLoginWithSSOModal] = useState(false);
  const [showUserCompanySelectModal, setShowUserCompanySelectModal] =
    useState(false);
  const [showResetPasswordModal, setShowResetPasswordModal] = useState(false);
  const [cognitoUser, setCognitoUser] = useState(null);
  const [mfaMode, setMfaMode] = useState(null); // TOTP | SMS
  const [configSetupCompleted, setConfigSetupCompleted] = useState(false);
  const [isCognitoFlow, setIsCognitoFlow] = useState(true);
  const [userCompanyList, setUserCompanyList] = useState([]);
  const [showCognitoRequestPinCode, setShowCognitoRequestPinCode] = useState(false);

  useEffect(() => {
    let chkUserLogin = cookie.load("userLogin");
    let chkCID = cookie.load("companyID");
    let chkPass = cookie.load("userPassword");
    if (
      chkUserLogin !== undefined &&
      chkCID !== undefined &&
      chkPass !== undefined
    ) {
      setUserLogin(chkUserLogin);
      setCompanyID(chkCID);
      setState((prevState) => ({ ...prevState, ...{ rememberme: true } }));
    }
  }, []);

  /**
   * If API details are already set, assume user is logged in and redirect to dashboard so we can restrict user from
   * accessing the login page while being logged in. If the session is expired/invalid then the user will be redirected back to login
   */
  useEffect(() => {
    const isCognito = localStorage.getItem("isCognito") === "true";
    const apiUrl = localStorage.getItem("API_URL");
    const accessToken = getProductJWTTokens().accessToken;
    const params = new URLSearchParams(document.location.search);
    const isSSOLogin = isValidString(localStorage.getItem("SSOAuthTenantId")) && params.has("code");

    if (apiUrl && ((!isCognito && !isSSOLogin) || accessToken)) {
      history.push("/dashboard");
    }
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(document.location.search);
    if (params.has("code")) {
      // continue SSO login
      const authCode = params.get("code");
      loginWithAuthCode(authCode);
    }
  }, [document.location.search]);

  /**
   * Handle user login results
   */
  useEffect(() => {
    if (user && user.loginSuccess) {
      if (isValidString(state.companyID)) {
        localStorage.setItem("companyID", state.companyID);
      }
      if (user.loginResp && user.loginResp.twoFactor === "Y") {
        handleTwoFactorFlow();
      } else {
        history.push("/login-table", { isCognitoFlow, companyID: state.companyID, });
      }
    }
    if (user.loginError) {
      toast.error(user.loginError);
    }
  }, [user]);

  function handleTwoFactorFlow() {

    const isPinCodeSent = user?.reqPinCodeSuccess !== '';
    const is2FARequired = user.loginResp.twoFactor === "Y";
    if (isPinCodeSent && is2FARequired) {
      toast.success(user.reqPinCodeSuccess);
      localStorage.setItem("userContact", user.reqPinCode.userContact);
      setShowResetPasswordModal(true);
    }

    const is2FAPinSentForNonCognitoLogin = isPinCodeSentToEmail(user?.loginResp?.results);

    if (is2FAPinSentForNonCognitoLogin && is2FARequired) {
      setShowCognitoRequestPinCode(true);
    }

    if (user && user.reqPinCodeError) {
      toast.error(user.reqPinCodeError);
    }
  }

  useEffect(() => {
    let id = document.getElementById("name");
    if (id) {
      document.getElementById("name").focus();
    }
  }, []);

  useEffect(() => {
    if (state.SSOAuthTenantId) {
      checkCompany(null, false, state.SSOAuthTenantId, state.region);
    }
  }, [state.SSOAuthTenantId]);

  /**
   * Check company has a success result, check the checked url and set the rest of the urls and ids
   * depending on the region and finally create a cognito user to initiate the login process
   */
  useEffect(() => {
    if (!configSetupCompleted) return;

    // if we are in the SSO login flow: continue that flow
    if (state.SSOAuthTenantId) {
      initiateSSOLogin();
      return;
    }

    /*
     * creating and setting the cognito user will initiate the login through
     * the useEffect with the cognitoUser dependency
     */
    setCognitoUser(
      new CognitoUser({
        Username: `${state.userLogin.toLowerCase()}_${state.companyID}` ?? "", // make it lowercase so user can type in any case
        Pool: getCognitoUserPoolWrapper(),
      })
    );
  }, [configSetupCompleted]);

  // try to login once the cognito user is created
  useEffect(() => {
    if (cognitoUser) {
      login();
    }
  }, [cognitoUser]);

  useEffect(() => {
    if (user.loginResp && user.loginResp.resetPassword === "Y") {
      history.push("/ressetPassword", {
        ressetPassword: true,
        resetPasswordFromLogin: true,
      });
    }
  }, [user.loginResp]);

  const handleFieldChange = (e) => {
    const { name, value } = e.currentTarget;
    const values = { [name]: value };
    setState((prevState) => ({ ...prevState, ...values }));
  };

  // check company and setup backend urls
  const checkCompany = async (e, validateInput = true, SSOAuthTenantId, region) => {
    e?.preventDefault();

    setConfigSetupCompleted(false);
    setState((prevState) => ({ ...prevState, ...{ isLoading: true } }));

    let { userLogin, companyID, userPassword, formErrors } = state;

    if (validateInput) {
      if (!userLogin || !companyID || !userPassword) {
        toast.error("All the fields are required");
        setState((prevState) => ({ ...prevState, ...{ isLoading: false } }));
        return;
      }

      formErrors = Validation.handleWholeValidation(
        { userLogin, companyID, userPassword },
        formErrors
      );
      if (
        formErrors.userLogin ||
        formErrors.companyID ||
        formErrors.userPassword
      ) {
        setState({
          ...state,
          formErrors: formErrors,
        });
        return;
      }
    }
    await setUpLocalStorage(companyID, region);
    setState((prevState) => ({ ...prevState, ...{ isLoading: false } }));
  };

  const setUpLocalStorage = async (companyID, region, ignoreCompanyId = false) => {
    const setupConfigOptions = {
      selectedRegion: state.region || region,
      companyID,
    };
    console.log('The region we get!', setupConfigOptions);
    console.log('state.region', state.region);
    console.log('region', region);
    console.log('iscalled with ignorecompany flag?', ignoreCompanyId);
    // I could see that this is what happens when we are in the SSO login flow
    let backendSetupSuccess = null;
    if (setupConfigOptions.selectedRegion || setupConfigOptions.companyID) {
      backendSetupSuccess = await setupBackendRegion(
        setupConfigOptions
      );
    } else {
      console.log('setupConfigOptions.selectedRegion', setupConfigOptions.selectedRegion);
      console.log('setupConfigOptions.companyID', setupConfigOptions.companyID);
    }

    if (backendSetupSuccess) {
      setConfigSetupCompleted(true);
    } else {
      console.log("backendSetupFailed", backendSetupSuccess);
    }
  }

  /**
   * Set the users preferred MFA method to TOTP
   */
  const setUserMFA = () => {
    const totpMfaSettings = {
      PreferredMfa: true,
      Enabled: true,
    };
    cognitoUser.setUserMfaPreference(
      null,
      totpMfaSettings,
      function (err, result) {
        if (err) {
          toast.error(err.message);
        }
        // continue auth flow to clarion
        loginToClarion();
      }
    );
  };

  /**
   * Starts the MFA setup by fetching the secret and displaying
   * an auth QR
   */
  const initiateMFASetup = () => {
    cognitoUser.associateSoftwareToken({
      associateSecretCode: async (secretCode) => {
        setState((prevState) => ({ ...prevState, ...{ isLoading: false } }));
        setSetup2FA(true);
        const url = `otpauth://totp/Digital%20Paper%20Flow:(${state.userLogin})?secret=${secretCode}&issuer=DigitalPaperFlow`;
        const image = await QR.toDataURL(url);
        setQrCodeImage(image);
        setShowTOTPMFAModal(true);
      },
      onFailure: function (err) {
        setState((prevState) => ({ ...prevState, ...{ isLoading: false } }));
        toast.error(
          "Failed to setup MFA for your company. Please contact support."
        );
      },
    });
  };

  /**
   * Validate totp entered by the user and set MFA for the user
   */
  const validateCodeTOTP = (code) => {
    setState((prevState) => ({ ...prevState, ...{ isLoading: true } }));
    if (setup2FA) {
      // validate QR code verification code
      cognitoUser.verifySoftwareToken(code, "My TOTP device", {
        onSuccess: function (result) {
          setUserMFA();
        },
        onFailure: function (err) {
          setState((prevState) => ({ ...prevState, ...{ isLoading: false } }));
          toast.error("Invalid verification code");
          console.error(err.message || JSON.stringify(err));
        },
      });
    } else {
      // validate code
      cognitoUser.sendMFACode(
        code,
        {
          onSuccess: () => {
            const accessToken = cognitoUser
              .getSignInUserSession()
              .getIdToken()
              .getJwtToken();
            const refreshToken = cognitoUser
              .getSignInUserSession()
              .getRefreshToken()
              .getToken();

            setCognitoTokens(accessToken, refreshToken);

            loginToClarion();
          },
          onFailure: (err) => {
            setState((prevState) => ({
              ...prevState,
              ...{ isLoading: false },
            }));
            if (err.code === "UserNotFoundException") {
              const errorMessage = extractErrorMessage(err?.message)
              toast.error(errorMessage);
            } else {
              toast.error("invalid TOTOP");
            }
          },
        },
        mfaMode === "SMS" ? undefined : "SOFTWARE_TOKEN_MFA"
      );
    }
    setShowTOTPMFAModal(false);
  };

  /**
   * Set phone_number attribute to the user and auth again to verify it
   */
  const handlePhoneNumberMFASubmit = (phoneNumber) => {
    setState((prevState) => ({ ...prevState, isLoading: true }));
    setShowTOTPMFAModal(false);

    const phoneNumberAttribute = new CognitoUserAttribute({
      Name: "phone_number",
      Value: phoneNumber,
    });
    const attributeList = [phoneNumberAttribute];

    cognitoUser.updateAttributes(attributeList, (err, result) => {
      if (err) {
        toast.error(
          "Failed to setup MFA for your company. Please contact support."
        );
        return;
      }
      const smsMfaSettings = {
        PreferredMfa: true,
        Enabled: true,
      };
      cognitoUser.setUserMfaPreference(
        smsMfaSettings,
        null,
        function (err, result) {
          if (err) {
            toast.error(
              "Failed to setup MFA for your company. Please contact support."
            );
          }

          // logout and login again so the mobile number can be verified through a verification code
          cognitoUser.signOut(() => {
            login();
          });
        }
      );
    });
  };

  const login = async () => {
    if (!state.userLogin && !state.companyID && !state.userPassword) {
      toast.error("All the fields are required");
      return;
    }
    setIsCognitoFlow(false);
    setState((prevState) => ({ ...prevState, ...{ isLoading: true } }));

    const cognitoStatus = await UserActions.checkCognitoStatusInCompanyId(
      state.companyID
    );

    // only follow Cognito flow if the flag is set to true
    const isMFAEnabled = cognitoStatus.isMFAEnabled === true;
    const isCognito = cognitoStatus.isCognitoEnabled === true;
    if (isCognito) {
      localStorage.setItem("isCognito", isCognito);

          if (isMFAEnabled) {
            const authDetails = new AuthenticationDetails({
              Username: `${state.userLogin.toLowerCase()}_${state.companyID}`,
              Password: state.userPassword,
              ValidationData: {
                companyId: state.companyID,
                betaEnv: `${window.config?.betaEnv}`,
              },
            });

            // get access and refresh tokens, and reset them before login to ensure we are not using expired tokens
            // previous ticket to fix this: https://tphglobal.atlassian.net/browse/TPHP-2481, however it did a clear out of the entire local state which broke the MFA flow
            // previous PR for reference: https://github.com/TPHGlobal/digital-paper-flow-client/commit/d1af83e60d36e2665992a1ba330a1cd066362707

            const productAccessToken = localStorage.getItem(
              "PRODUCT_ACCESS_TOKEN"
            );
            const productRefreshToken = localStorage.getItem(
              "PRODUCT_REFRESH_TOKEN"
            );
            const cognitoAccessToken = localStorage.getItem(
              "COGNITO_ACCESS_TOKEN"
            );
            const cognitoRefreshToken = localStorage.getItem(
              "COGNITO_REFRESH_TOKEN"
            );

            if (productAccessToken) {
              localStorage.removeItem("PRODUCT_ACCESS_TOKEN");
            }

            if (productRefreshToken) {
              localStorage.removeItem("PRODUCT_REFRESH_TOKEN");
            }

            if (cognitoAccessToken) {
              localStorage.removeItem("COGNITO_ACCESS_TOKEN");
            }

            if (cognitoRefreshToken) {
              localStorage.removeItem("COGNITO_REFRESH_TOKEN");
            }

            cognitoUser.setAuthenticationFlowType("USER_PASSWORD_AUTH");
            cognitoUser.authenticateUser(authDetails, {
              onSuccess: function (result) {
                // save the cognito access token to storage
                const accessToken = result?.getIdToken().getJwtToken();
                const refreshToken = result?.getRefreshToken().getToken();
                setCognitoTokens(accessToken, refreshToken);

                // check and set preferred MFA setting only if it's not yet set
                cognitoUser.getUserData((err, data) => {
                  if (err) {
                    alert(err.message || JSON.stringify(err));
                    return;
                  }

                  const { PreferredMfaSetting } = data;

                  if (!PreferredMfaSetting) {
                    // register users auth app with cognito
                    initiateMFASetup();
                  }
                });
              },
              onFailure: function (err) {
                if (err.code === "UserNotFoundException") {
                  const errorMessage = extractErrorMessage(err?.message)
                  toast.error(errorMessage);
                } else {
                  toast.error("Invalid username or password!");
                }
                setState((prevState) => ({
                  ...prevState,
                  ...{ isLoading: false },
                }));
              },
              totpRequired: async function () {
                // this method will run for auth app flow
                setState((prevState) => ({
                  ...prevState,
                  ...{ isLoading: false },
                }));
                setSetup2FA(false);
                setMfaMode("TOTP");
                setShowTOTPMFAModal(true);
              },
              mfaRequired: async (deliveryDetails) => {
                // this method will run for SMS flow
                setState((prevState) => ({
                  ...prevState,
                  ...{ isLoading: false },
                }));
                // (new CognitoUser()).
                setSetup2FA(false);
                setMfaMode("SMS");
                setShowTOTPMFAModal(true);
              },
            });

            if (user.loginError) {
              if (user.loginError === "User is already logged in.") {
                history.push("/dashboard");
              } else {
                toast.error(user.loginError);
              }
            }
            if (user.loginSuccess) {
              toast.success(user.loginSuccess);
              localStorage.setItem("userLogin", userLogin.toLowerCase());
              //set cookies
              if (state.rememberme) {
                cookie.save("userLogin", userLogin, {
                  path: "/",
                });
                cookie.save("companyID", companyID, {
                  path: "/",
                });
                cookie.save("userPassword", userPassword, {
                  path: "/",
                });
              } else {
                cookie.remove("userLogin", {
                  path: "/",
                });
                cookie.remove("companyID", {
                  path: "/",
                });
                cookie.remove("userPassword", {
                  path: "/",
                });
              }

              //to change password if 'RessetPassword = Y'
              let resetPassword =
                user.loginResp &&
                user.loginResp.resetPassword &&
                user.loginResp.resetPassword.toLowerCase() === "y"
                  ? true
                  : false;

              //redirect to verify/two factor page if 'twoFactor = Y'
              let twoFactor =
                user.loginResp &&
                user.loginResp.twoFactor &&
                user.loginResp.twoFactor.toLowerCase() === "y"
                  ? true
                  : false;

              if (resetPassword) {
                history.push("/ressetPassword", { ressetPassword: true });
              } else if (!twoFactor) {
                history.push("/login-table", {
                  companyID: state.companyID,
                });
              } else {
                let data = {
                  actionType: "RequestPinCode",
                  userLogin: userLogin,
                  companyID: companyID,
                };

                dispatch(UserActions.requestPinCode(data));

                if (user.reqPinCodeSuccess) {
                  toast.success(user.reqPinCodeSuccess);
                  localStorage.setItem(
                    "userContact",
                    user.reqPinCode.userContact
                  );
                  history.push("/verify", {
                    userLogin: userLogin,
                    companyID: companyID,
                  });
                }
                if (user.reqPinCodeError) {
                  toast.error(user.reqPinCodeError);
                }
              }
            }
          } else {
            const authenticationData = {
              Username: `${state.userLogin}_${state.companyID}`,
              Password: state.userPassword,
              ValidationData: {
                companyId: state.companyID,
                betaEnv: `${window.config?.betaEnv}`,
              },
            };

            const authenticationDetails = new AuthenticationDetails(
              authenticationData
            );

            /* The cognito integration */
            try {
              cognitoUser.setAuthenticationFlowType("USER_PASSWORD_AUTH");

              await cognitoUser.authenticateUser(authenticationDetails, {
                onSuccess: async (result) => {
                  const accessToken = result.getIdToken()?.getJwtToken();
                  const refreshToken = result.getRefreshToken()?.getToken();
                  setCognitoTokens(accessToken, refreshToken);

                  setState((prevState) => ({
                    ...prevState,
                    ...{ isLoading: true },
                  }));

                  loginToClarion();
                },
                onFailure: (err) => {
                  setState((prevState) => ({
                    ...prevState,
                    ...{ isLoading: false },
                  }));
                  if (err.code === "UserNotFoundException") {
                    const errorMessage = extractErrorMessage(err?.message)
                    toast.error(errorMessage);
                  } else {
                    toast.error("Invalid username or password!");
                  }
                },
              });
            } catch (e) {
              setState((prevState) => ({
                ...prevState,
                ...{ isLoading: false },
              }));
              toast.error("Password attempts exceeded!");
            }
          }
    } else {
      setIsCognitoFlow(false);
      localStorage.setItem("isCognito", false);
      await loginToClarion();
    }
  };

  const populateEnvironment = async () => {
    const storedApiGateWayUrl = localStorage.getItem("API_GATEWAY_URL");
    const storedCognitoClientAppRedirectUri = localStorage.getItem("COGNITO_CLIENT_APP_REDIRECT_URI")
    const isEnviromentAvailable = isValidString(storedApiGateWayUrl) && isValidString(storedCognitoClientAppRedirectUri);
    const SSOAuthTenantId = sessionStorage.getItem('SSOAuthTenantId');
    const region = sessionStorage.getItem('region');
    if (
      !isEnviromentAvailable     
    ) {
      console.info('Enviroment will be populated');
      await setUpLocalStorage(SSOAuthTenantId, region, true);
    }  else {
      console.info('Enviroment is already populated');
    }
  }

  const loginWithAuthCode = async (authCode) => {
    try {
      setState({ ...state, isLoading: true });
      localStorage.setItem("isCognito", true);
      await populateEnvironment();
      const response = await dispatch(
        UserActions.getAccessTokenFromCode(authCode)
      );

      setCognitoTokens(
        response?.data?.accessToken,
        response?.data?.refreshToken
      );

      const userCompanies = await UserActions.getUserCompanies();

      if (!userCompanies) {
        toast.error(
          "This company is not setup for SSO logins. Please contact admin."
        );
        setCognitoTokens(undefined, undefined);
        setState({ ...state, isLoading: false });
        return;
      }

      if (userCompanies.length === 1) {
        localStorage.setItem("companyID", userCompanies[0]);
        await dispatch(UserActions.logInSSOUser(userCompanies[0]));
      } else {
        // show company list for the user to pick one to login
        setUserCompanyList(userCompanies);
        setShowUserCompanySelectModal(true);
      }
    } catch (error) {
      toast.error("Failed to login", error);
    } finally {
      setState({ ...state, isLoading: false });
    }
  };

  const handleUserSelectCompany = async (selectedCompanyId) => {
    setState({ ...state, isLoading: true });
    localStorage.setItem("companyID", selectedCompanyId);
    await dispatch(UserActions.logInSSOUser(selectedCompanyId));
    setState({ ...state, isLoading: false });
  };

  const loginToClarion = async () => {
    const userDataClarion = {
      actionType: "LoginUser",
      userLogin: state.userLogin,
      companyID: state.companyID,
      userPassword: state.userPassword,
    };
    await dispatch(UserActions.logInUser(userDataClarion));
    setState((prevState) => ({ ...prevState, ...{ isLoading: false } }));
  };

  const initiateSSOLogin = async () => {
    try {
      setState((prevState) => ({ ...prevState, ...{ isLoading: true } }));
      const response = await dispatch(
        UserActions.getIdentityProviderOfTenant(state.SSOAuthTenantId)
      );

      if (response?.data?.IdentityProvider) {
        localStorage.setItem("SSOAuthTenantId", state.SSOAuthTenantId);
        const authUri = `${localStorage.getItem(
          "COGNITO_CLIENT_APP_DOMAIN"
        )}/oauth2/authorize?identity_provider=${
          response.data.IdentityProvider
        }&response_type=CODE&client_id=${localStorage.getItem(
          "COGNITO_CLIENT_APP_ID"
        )}&redirect_uri=${localStorage.getItem(
          "COGNITO_CLIENT_APP_REDIRECT_URI"
        )}`;
        if (state.SSOAuthTenantId) {
          sessionStorage.setItem('SSOAuthTenantId', state.SSOAuthTenantId);
        }
        if (state.region) {
          sessionStorage.setItem('region', state.region);
        }
        sessionStorage.setItem('isSSOLogin', true);
        console.log('Before initating SSO Login!, region=', state.region)
        await setUpLocalStorage(state.SSOAuthTenantId, state.region, true);
        window.location.assign(authUri);
      } else {
        // show error
        toast.error(
          "This company is not setup for SSO logins. Please contact admin."
        );
        setState({ ...state, SSOAuthTenantId: null });
      }
    } catch (error) {
      toast.error(
        "This company is not setup for SSO logins. Please contact admin."
      );
      setState({ ...state, SSOAuthTenantId: null });
    } finally {
      setState((prevState) => ({ ...prevState, ...{ isLoading: false } }));
    }
  };

  const handleSSOModalSubmit = (tenantId, region) => {
    setState({ ...state, SSOAuthTenantId: tenantId, tenantId, region });
  };

  const closeQRMFAModal = () => {
    setQrCodeImage("");
    setShowTOTPMFAModal(false);
  };

  const settingRememberMe = (status) =>
    setState((prevState) => ({ ...prevState, ...{ rememberme: status } }));

  useEffect(() => {
    if (state.userLogin && state.userPassword && !state.companyID) {
      setCompanyIdTrigger(true);
    } else if (state.userLogin && state.userPassword && state.companyID) {
      setCompanyIdTrigger(false);
    }
  }, [state]);

  return (
    <>
      {state.isLoading ? <div className="se-pre-con"></div> : ""}
      <LoginWithExternalIDPModal
        isOpen={showLoginWithSSOModal}
        onClose={() => setShowLoginWithSSOModal(false)}
        onSubmit={handleSSOModalSubmit}
      />
      <UserCompanySelectModal
        isOpen={showUserCompanySelectModal}
        onClose={() => setShowUserCompanySelectModal(false)}
        onSubmit={handleUserSelectCompany}
        companyList={userCompanyList}
      />
      {showTOTPMFAModal && (
        <MFAQRCodeModal
          closeModal={closeQRMFAModal}
          setup2FA={setup2FA}
          qrCodeImage={qrCodeImage}
          validateCodeTOTP={validateCodeTOTP}
          showTOTPMFAModal={showTOTPMFAModal}
          validatePhoneNumber={handlePhoneNumberMFASubmit}
          mfaMode={mfaMode}
        />
      )}
      {showResetPasswordModal && (
        <ResetPasswordModal onClose={() => setShowResetPasswordModal(false)} />
      )}
      <div className="container-fluid pl-0">
        <div className="main-wrap">
          <div className="row">
            <div className="col-12 col-md-6 pr-sm-0 pr-xs-0 order2">
              <div className="left_slider">
                <div
                  id="login_left_slider"
                  className="carousel slide mm_login_slider"
                  data-ride="carousel"
                >
                  <ol className="carousel-indicators">
                    <li
                      data-target="#login_left_slider"
                      data-slide-to="0"
                      className="active"
                    ></li>
                    <li data-target="#login_left_slider" data-slide-to="1"></li>
                    <li data-target="#login_left_slider" data-slide-to="2"></li>
                  </ol>
                  <div className="carousel-inner">
                    <div className="carousel-item active">
                      <img
                        src="images/1st_card.png"
                        className="d-block img-fluid mx-auto"
                        alt="slide"
                      />
                      <div className="carousel-caption">
                        <h5>Dashboard Page</h5>
                        <p>
                          Your dashboard quickly shows you recent activity and a
                          summary of orders and invoices.
                        </p>
                      </div>
                    </div>
                    <div className="carousel-item">
                      <img
                        src="images/2nd_card.png"
                        className="d-block img-fluid mx-auto"
                        alt="slide"
                      />
                      <div className="carousel-caption">
                        <h5>Orders Screen</h5>
                        <p>Visually see orders when drafting and approving.</p>
                      </div>
                    </div>
                    <div className="carousel-item">
                      <img
                        src="images/3rd_card.png"
                        className="d-block img-fluid mx-auto"
                        alt="slide"
                      />
                      <div className="carousel-caption">
                        <h5>Invoices Screen</h5>
                        <p>OCR Invoices for quick entry and PO matching.</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="login-badges">
                  <a
                    href="https://play.google.com/store/apps/details?id=tph.digital_paper_flow"
                    target="_blank"
                  >
                    <img
                      src="images/google-play-store.png"
                      className="img-fluid"
                      alt="google-play"
                    />
                  </a>
                  <a
                    href="https://apps.apple.com/app/id1576617760"
                    style={{
                      display: "inline-block",
                    }}
                    target="_blank"
                  >
                    <img
                      src="images/apple-store.png"
                      className="img-fluid"
                      alt="google-play"
                    />
                  </a>
                </div>
                <p className="copy_right">@ Copyright TPH Technologies 2018</p>
              </div>
            </div>

            <div className="col-12 col-md-6 order1">
              <div className="login_side">
                <div className="row">
                  <div className="col-12">
                    <div className="site_log">
                      <img
                        src="images/logo.png"
                        className="d-block img-fluid"
                        alt="Logo"
                      />
                    </div>
                  </div>
                  {
                    showCognitoRequestPinCode ? 
                    (
                      <Verify2FACode
                        userLogin={state.userLogin}
                        companyID={state.companyID}
                      ></Verify2FACode>
                    ) 
                    : (
                    <></>
                    )
                  }
                  <div className="col-12">
                    <div className="login_form">
                      <form onSubmit={checkCompany}>
                        <p>Welcome back! Please login to your account.</p>
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            id="name"
                            placeholder="User Login"
                            name="userLogin"
                            value={state.userLogin}
                            onChange={handleFieldChange}
                            tabIndex="1"
                          />
                          <div className="text-danger error-12">
                            {state && state.userLogin === ""
                              ? "Username is required!"
                              : ""}
                          </div>
                        </div>
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            id="id"
                            placeholder="Company ID"
                            name="companyID"
                            value={state.companyID}
                            onChange={handleFieldChange}
                            tabIndex="2"
                          />
                          <div className="text-danger error-12">
                            {(state && state.companyID === "") ||
                            companyIdTrigger
                              ? "Company ID is required!"
                              : ""}
                          </div>
                        </div>
                        <div className="form-group">
                          <input
                            type="password"
                            className="form-control"
                            id="pass"
                            placeholder="Password"
                            name="userPassword"
                            value={state.userPassword}
                            onChange={handleFieldChange}
                            tabIndex="3"
                          />
                          <div className="text-danger error-12">
                            {state && state.userPassword === ""
                              ? "Password is required!"
                              : ""}
                          </div>
                        </div>

                        <div className="row mt-35">
                          <div className="col align-self-center">
                            <div className="form-group remember_check mm_check2">
                              <input
                                type="checkbox"
                                id="remember"
                                name="rememberme"
                                checked={state.rememberme}
                                onChange={(e) =>
                                  settingRememberMe(e.target.checked)
                                }
                              />
                              <label htmlFor="remember"> Remember Me</label>
                            </div>
                          </div>
                          <div className="col text-right align-self-center">
                            <button
                              type="button"
                              onClick={() => setShowResetPasswordModal(true)}
                              className="btn forgot_btn"
                            >
                              Forgot Password
                            </button>
                          </div>
                          <div className="col-12">
                            <div className="login_btn">
                              <button
                                id="login"
                                type="submit"
                                className="btn btn-primary login_blue"
                              >
                                Login
                              </button>
                              <button
                                type="button"
                                className="btn btn-primary login_blue ml-2"
                                onClick={() => setShowLoginWithSSOModal(true)}
                              >
                                Login with SSO
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              <p className="term"><a href="https://tphglobal.com/terms-of-service/" target="_blank">Term of use</a>. <a href="https://tphglobal.com/privacy-policy-and-data-protection-information" target="_blank">Privacy policy.</a></p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

/**
   * Checks if the result has pin code sent to email
   * @param {Array.<{status: string, description: string}>} results 
   * @returns {boolean}
   */
export function isPinCodeSentToEmail(results) {
  if (!Array.isArray(results)) {
    console.warn('[WARN] Not a valid result array');
    return false;
  }
  const isValidResult = results.every((result) => {
    const keys = Object.keys(result);
    const hasDescription = keys.includes('description');
    const hasStatus = keys.includes("status");
    if (!hasDescription || !hasStatus) {
      console.warn('Does not have status and description');
      return false;
    }
    return true;
  });
  if (!isValidResult) {
    console.warn("[WARN] Not all results have status and description");
    return false;
  }
  for (const result of results) {
    const isSuccess = String(result.status)?.toLowerCase() === "success";
    const isPinSent = String(result.description)?.toLowerCase()?.trim()?.replace(/\s+/g, '')?.replace('.', '') === "pincodeemailsent";
    if (isSuccess && isPinSent) {
      return true;
    }
  }
  console.warn('Not even one result has pin code sent to email');
  console.log(results);
  return false;
}

export default Login;
