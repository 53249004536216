import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Modal from "react-bootstrap/Modal";
import Select from "react-select";
import Dropdown from "react-bootstrap/Dropdown";
import DisplayExpensesMoreDetails from "../DisplayExpensesMoreDetails/DisplayExpensesMoreDetails";
import { updateApprovalGroup } from "../../../Actions/ExpenseActions/ExpenseActions";
import { handleAPIErr } from "../../../Utils/Helpers";
import { toast } from "react-toastify";
const ExpensesMoreDetails = (props) => {
  const [
    openDisplayExpensesMoreDetailsModal,
    setopenDisplayExpensesMoreDetailsModal,
  ] = useState(false);
  const dispatch = useDispatch();
  const [state, setState] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const expenseData = useSelector((state) => state.expense);
  let { showExpTalliesTabPane, closeModal, updateExpenseSummaryState } = props;
  showExpTalliesTabPane = showExpTalliesTabPane
    ? showExpTalliesTabPane.toLowerCase()
    : "";

  const handleChangeType = (data) => {
    setState((prev) => ({
      ...prev,
      approverGroup: data,
    }));
  };

  const onChangeHandler = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const periodOption = () => {
    if (props.state.approverOptions.length > 0) {
      let newArray = [];
      props.state.approverOptions.map((item) =>
        newArray.push({ value: item.groupName, label: item.groupName })
      );
      return newArray;
    }
  };

  const onSave = async (e) => {
    e.preventDefault();

    let expenseSummary = {
      approvalGroup: state.approverGroup.value,
      guid: state.guid,
    };

    setIsLoading(true);

    await dispatch(updateApprovalGroup(expenseSummary));

    updateExpenseSummaryState();

    let { getExpenseList, activeExpense } = props.state;
    let activeExpenseToUpdate = getExpenseList.find(
      (fi) => fi.id === activeExpense
    );
    await props.getExpenseSummary(activeExpenseToUpdate, true);
    closeModal("openExpenseMoreDetailsModal");

    setIsLoading(false);
  };

  useEffect(() => {
    setState((prev) => ({
      ...prev,
      ...props?.state,
    }));
  }, [props]);

  return (
    <>
      {isLoading ? <div className="se-pre-con"></div> : ""}
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={state?.openExpenseMoreDetailsModal}
        onHide={() => closeModal("openExpenseMoreDetailsModal")}
        className="forgot_email_modal modal_704 mx-auto"
      >
        <Modal.Body>
          <div className="container-fluid ">
            <div className="main_wrapper p-10">
              <div className="row d-flex h-100">
                <div className="col-12 justify-content-center align-self-center form_mx_width">
                  <div className="forgot_form_main">
                    <div className="forgot_header">
                      <div className="modal-top-header">
                        <div className="row bord-btm">
                          <div className="col d-flex justify-content-end s-c-main">
                            <button
                              onClick={onSave}
                              type="button"
                              className="btn-save"
                            >
                              <img
                                src="images/save-check.png"
                                className="mr-2"
                                alt="display-icon"
                              />
                              Save
                            </button>
                            <button
                              onClick={() =>
                                props.closeModal("openExpenseMoreDetailsModal")
                              }
                              type="button"
                              className="btn-save"
                            >
                              <img
                                src="images/cancel.png"
                                className="mr-2"
                                alt="display-icon"
                              />
                              Cancel
                            </button>
                            <Dropdown
                              alignRight="false"
                              drop="down"
                              className="border-0"
                            >
                              <Dropdown.Toggle id="dropdown-basic-two">
                                <img
                                  src="images/more.png"
                                  className="img-fluid"
                                  alt="user"
                                />
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.Item
                                  variant="success"
                                  onClick={() =>
                                    setopenDisplayExpensesMoreDetailsModal(true)
                                  }
                                >
                                  <button
                                    type="button"
                                    className="btn-save def-blue"
                                  >
                                    <img
                                      src="images/display-icon.png"
                                      className="mr-2"
                                      alt="display-icon"
                                    />
                                    Display
                                  </button>
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="forgot_body">
                      <div className="row mt-4">
                        <div className="col-12">
                          <div className="form-group custon_select">
                            <label>Approval Group</label>
                            <Select
                              className={
                                showExpTalliesTabPane !== "draft"
                                  ? "disable_bg disable_border"
                                  : "width-selector"
                              }
                              value={state?.approverGroup}
                              classNamePrefix="custon_select-selector-inner"
                              options={periodOption()}
                              onChange={(e) =>
                                showExpTalliesTabPane === "draft"
                                  ? handleChangeType(e)
                                  : ""
                              }
                              isDisabled={showExpTalliesTabPane !== "draft"}
                              theme={(theme) => ({
                                ...theme,
                                border: 0,
                                borderRadius: 0,
                                colors: {
                                  ...theme.colors,
                                  primary25: "#f2f2f2",
                                  primary: "#f2f2f2",
                                },
                              })}
                            />
                          </div>
                        </div>

                        <div className="col-12">
                          <div className="form-group custon_select">
                            <label>Tran</label>
                            <div className="modal_input">
                              <input
                                name="tran"
                                type="text"
                                className={
                                  showExpTalliesTabPane !== "draft"
                                    ? "disable_bg disable_border"
                                    : "form-control"
                                }
                                onChange={
                                  showExpTalliesTabPane === "draft"
                                    ? onChangeHandler
                                    : null
                                }
                                id="exp-tran"
                                value={state?.tran || ""}
                                disabled
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="form-group custon_select">
                            <label>Currency</label>
                            <div className="modal_input">
                              <input
                                name="currency"
                                type="text"
                                className={
                                  showExpTalliesTabPane !== "draft"
                                    ? "disable_bg disable_border"
                                    : "form-control"
                                }
                                onChange={
                                  showExpTalliesTabPane === "draft"
                                    ? onChangeHandler
                                    : null
                                }
                                id="exp-currency"
                                value={state?.currency || ""}
                                disabled
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-12">
                          <div className="form-group custon_select">
                            <label>SupplierCode</label>
                            <div className="modal_input">
                              <input
                                name="supplierCode"
                                type="text"
                                className={
                                  showExpTalliesTabPane !== "draft"
                                    ? "disable_bg disable_border"
                                    : "form-control"
                                }
                                id="exp-suppliercode"
                                value={state?.supplierCode || ""}
                                onChange={
                                  showExpTalliesTabPane === "draft"
                                    ? onChangeHandler
                                    : null
                                }
                                disabled
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <DisplayExpensesMoreDetails
        openDisplayExpensesMoreDetailsModal={
          openDisplayExpensesMoreDetailsModal
        }
        setopenDisplayExpensesMoreDetailsModal={
          setopenDisplayExpensesMoreDetailsModal
        }
        data={props}
      />
    </>
  );
};
export default ExpensesMoreDetails;
