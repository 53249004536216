import React, { Component } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import Dropdown from "react-bootstrap/Dropdown";
import $ from "jquery";
import { _customStyles } from "../../../Constants/Constants";
import DatePicker from "react-datepicker";

import TopNav from "../../Common/TopNav/TopNav";
import * as AccountActions from "../../../Actions/AccountActions/AccountActions";
// import { clearStatesAfterLogout } from "../../Actions/UserActions/UserActions";
import * as Validation from "../../../Utils/Validation";
import {
  toBase64,
  addDragAndDropFileListners,
  removeDragAndDropFileListners,
  handleHideUnhideRows,
  handleValueOptions,
  handleAPIErr,
} from "../../../Utils/Helpers";
import "../PeriodClose/PeriodClose.css";
import "./VatSubmission.css";
const uuidv1 = require("uuid/v1");
class VatSubmission extends Component {
  constructor() {
    super();
    this.state = {
      isLoading: false,
      addNewReportToggle: false,
      reportTypesVal: { label: "", value: "" },
      vatRego: 1,
      vatPeriod: "1",
      canSubmit: 0,
      reportId: null,
      reportTypes: [],
      reportOptionVal: [{ label: "Select Report Option", value: "" }],
      reportOptions: [],
      advancedList: [],
      calculations: [],
      clonnedCalculations: [],
      clonnedAdvancedList: [],
      availableReports: [],
      availabletypes: "",
      menuIsOpen: false,

      reportFile: "",
      reportName: "",
      private: false,
      showHiddenRows: false,
      showHiddenRowsCal: false,
      reDrawDT: false, //re draw datatable
      reportTypeValue: null,
      reportLayouts: [],
      reportLayoutsVal: [],
      PrimeVatSubmission: [],
      agree: false,
      formErrors: {
        reportTypesVal: "",
        reportOptionVal: "",
        reportFile: "",
        reportName: "",
      },
    };
  }

  async componentDidMount() {
    this.getPrimeVatSubmission();
  }

  componentWillUnmount() {
    //removing drag and drop attachments listeners
    removeDragAndDropFileListners("drop-area-report", this.uploadReportFile);
  }

  getPrimeVatSubmission = async () => {
    this.setState({ isLoading: true });
    await this.props.getPrimeVatSubmission();

    if (this.props.account.getPrimeVatSubmissionSuccess) {
      toast.success(this.props.account.getPrimeVatSubmissionSuccess);
      let PrimeVatSubmission = this.props.account.getPrimeVatSubmission || [];
      let vatRego = PrimeVatSubmission[0].vatRego;
      let vatPeriod = PrimeVatSubmission[0]?.vatPeriod || "";
      let advancedList = PrimeVatSubmission[0]?.advancedOptions;
      let calculations = PrimeVatSubmission[0]?.calculations;
      let availableReports = PrimeVatSubmission[0]?.availableReports || [];
      let reportId = PrimeVatSubmission[0]?.reportID || 0;
      let hmrc = PrimeVatSubmission[0]?.hmrc || [];
      let canSubmit = hmrc[0]?.canSubmit || 0;
      let reportTypeOption = [];
      let reportTypesVal =
        availableReports.length > 0
          ? {
              label: availableReports[0].reportType,
              value: availableReports[0].areaRef,
            }
          : null;
      availableReports.map((item) => {
        reportTypeOption.push({ label: item.reportType, value: item.areaRef });
      });
      //let reportTypeValue = reportTypeOption.filter((item) => item);
      let reportLayoutsVal = availableReports[0]?.reportLayouts || [];
      let reportOptions = [];
      let reportOptionVal =
        reportLayoutsVal.length > 0
          ? { label: reportLayoutsVal[0].layout, value: reportLayoutsVal[0].id }
          : null;
      reportLayoutsVal.map((item) => {
        reportOptions.push({ label: item.layout, value: item.id });
        if (item.id === reportId) {
          reportOptionVal = { label: item.layout, value: item.id };
        }
      });

      advancedList.map((lst, i) => {
        if ((lst.type && lst.type.toLowerCase() === "list") || "droplist") {
          let valOptns = [];
          if (lst.options && lst.options.length > 0) {
            lst.options.map((o, i) => {
              valOptns.push({ label: o.value, value: o.value });
            });
          }
          lst.options = valOptns;
        }
        lst.id = uuidv1();
        lst.hide = false;
        return lst;
      });
      // calculations?.map((lst, i) => {
      //   if ((lst.type && lst.type.toLowerCase() === "list") || "droplist") {
      //     let valOptns = [];
      //     if (lst.options && lst.options.length > 0) {
      //       lst.options.map((o, i) => {
      //         valOptns.push({ label: o.value, value: o.value });
      //       });
      //     }
      //     lst.options = valOptns;
      //   }
      //   lst.id = uuidv1();
      //   lst.hide = false;
      //   return lst;
      // });

      this.setState({
        reportTypesVal,
        reportOptionVal,
        reportOptions,
        advancedList,
        calculations,
        clonnedAdvancedList: advancedList,
        clonnedCalculations: calculations,
        reportTypes: reportTypeOption,
        reportLayouts: reportOptions,
        reportLayoutsVal,
        PrimeVatSubmission,
        vatRego,
        vatPeriod,
        canSubmit,
        reportId,
      });
    }

    if (this.props.account.PrimePeriodCloseError) {
      handleAPIErr(this.props.account.PrimePeriodCloseError, this.props);
    }

    this.setState({ isLoading: false });
  };

  calculationVatSubmission = async () => {
    this.setState({ isLoading: true });

    let data = [
      {
        submitSuccess: 1,
        reportID: this.state.reportId,
        vatPeriod: this.state.vatPeriod,
        vatRego: this.state.vatRego,
        availableReports: this.state.PrimeVatSubmission[0].availableReports,
        advancedOptions: this.state.advancedList,
        calculations: this.state.calculations,
      },
    ];
    await this.props.calculationVatSubmission(data);

    if (this.props.account.calculateVatSubmissionSuccess) {
      // toast.success(this.props.account.calculationVatSubmissionSuccess);
      let PrimeVatSubmission =
        this.props.account.calculationVatSubmission || [];
      let vatRego = PrimeVatSubmission[0].vatRego;
      let vatPeriod = PrimeVatSubmission[0]?.vatPeriod || "";
      let advancedList = PrimeVatSubmission[0]?.advancedOptions;
      let calculations = PrimeVatSubmission[0]?.calculations;
      let availableReports = PrimeVatSubmission[0]?.availableReports || [];
      let reportId = PrimeVatSubmission[0]?.reportID || 0;
      let hmrc = PrimeVatSubmission[0]?.hmrc || [];
      let canSubmit = hmrc[0]?.canSubmit || 0;
      let reportTypeOption = [];
      let reportTypeValue =
        availableReports.length > 0
          ? {
              label: availableReports[0].reportType,
              value: availableReports[0].areaRef,
            }
          : null;
      availableReports.map((item) => {
        reportTypeOption.push({ label: item.reportType, value: item.areaRef });
      });
      //let reportTypeValue = reportTypeOption.filter((item) => item);
      let reportLayoutsVal = availableReports[0]?.reportLayouts || [];
      let reportOptions = [];
      let reportOptionVal =
        reportLayoutsVal.length > 0
          ? { label: reportLayoutsVal[0].layout, value: reportLayoutsVal[0].id }
          : null;
      reportLayoutsVal.map((item) => {
        reportOptions.push({ label: item.layout, value: item.id });
        if (item.id === reportId) {
          reportOptionVal = { label: item.layout, value: item.id };
        }
      });

      advancedList.map((lst, i) => {
        if ((lst.type && lst.type.toLowerCase() === "list") || "droplist") {
          let valOptns = [];
          if (lst.options && lst.options.length > 0) {
            lst.options.map((o, i) => {
              valOptns.push({ label: o.value, value: o.value });
            });
          }
          lst.options = valOptns;
        }
        lst.id = uuidv1();
        lst.hide = false;
        return lst;
      });
      calculations.map((lst, i) => {
        if ((lst.type && lst.type.toLowerCase() === "list") || "droplist") {
          let valOptns = [];
          if (lst.options && lst.options.length > 0) {
            lst.options.map((o, i) => {
              valOptns.push({ label: o.value, value: o.value });
            });
          }
          lst.options = valOptns;
        }
        lst.id = uuidv1();
        lst.hide = false;
        return lst;
      });

      this.setState({
        reportTypeValue,
        reportOptionVal,
        reportOptions,
        advancedList,
        calculations,
        clonnedAdvancedList: advancedList,
        clonnedCalculations: calculations,
        reportTypes: reportTypeOption,
        reportLayouts: reportOptions,
        reportLayoutsVal,
        PrimeVatSubmission,
        vatRego,
        vatPeriod,
        canSubmit,
        reportId,
      });
    }

    if (this.props.account.calculateVatSubmissionError) {
      handleAPIErr(this.props.account.calculateVatSubmissionError, this.props);
    }

    this.setState({ isLoading: false });
  };

  checkObligationuserHMRCAuthorized = async () => {
    this.setState({ isLoading: true });
    let hmrcAuthorized = "0";
    await this.props.userHMRCAuthorized();
    if (this.props.account.userHMRCAuthorizedSuccess) {
      toast.success(this.props.account.userHMRCAuthorizedSuccess);
      hmrcAuthorized =
        this.props.account.userHMRCAuthorized?.hmrcAuthorized || "";
    }
    if (this.props.account.userHMRCAuthorizedError) {
      handleAPIErr(this.props.accept.userHMRCAuthorizedError, this.props);
    }

    if (hmrcAuthorized === "0") {
      await this.primeHMRCTokenAuthorization();
    }
    this.setState({ isLoading: false });
  };
  submituserHMRCAuthorized = async () => {
    this.setState({ isLoading: true });
    let hmrcAuthorized = "0";
    if (this.state.canSubmit === 0) {
      await this.props.userHMRCAuthorized();
      if (this.props.account.userHMRCAuthorizedSuccess) {
        toast.success(this.props.account.userHMRCAuthorizedSuccess);
        hmrcAuthorized =
          this.props.account.userHMRCAuthorized?.hmrcAuthorized || "";
      }
      console.log("Submit.");
    } else {
      if (this.props.account.userHMRCAuthorizedError) {
        handleAPIErr(this.props.accept.userHMRCAuthorizedError, this.props);
      }
      toast.error("You must first run the calculation.");
    }
    if (hmrcAuthorized === "0") {
      await this.primeHMRCTokenAuthorization();
    }
    this.setState({ isLoading: false });
  };
  primeHMRCTokenAuthorization = async () => {
    this.setState({ isLoading: true });
    await this.props.primeHMRCTokenAuthorization();
    if (this.props.account.primeHMRCTokenAuthorizationSuccess) {
      toast.success(this.props.account.primeHMRCTokenAuthorizationSuccess);
      await this.authenticateHMRCUser();
    }
    if (this.props.account.primeHMRCTokenAuthorizationError) {
      handleAPIErr(
        this.props.account.primeHMRCTokenAuthorizationError,
        this.props
      );
    }

    this.setState({ isLoading: false });
  };
  authenticateHMRCUser = async () => {
    this.setState({ isLoading: true });
    await this.props.authenticateHMRCUser();
    if (this.props.account.authenticateHMRCUserSuccess) {
      toast.success(this.props.account.authenticateHMRCUserSuccess);
    }
    if (this.props.account.authenticateHMRCUserError) {
      handleAPIErr(this.props.account.authenticateHMRCUserError, this.props);
    }
    this.setState({ isLoading: false });
  };

  _handleValueOptions = async (type, val, item, index) => {
    let { advancedList, clonnedAdvancedList } = this.state;
    let result = handleValueOptions(
      type,
      val,
      item,
      index,
      advancedList,
      clonnedAdvancedList
    );
    this.setState({
      ...this.state,
      advancedList: result.advancedList,
      clonnedAdvancedList: result.clonedAdvancedList,
    });
  };

  _handlesHideUnhideRows = (item) => {
    let { advancedList, clonnedAdvancedList, showHiddenRows } = this.state;

    let result = handleHideUnhideRows(
      item,
      "#reportv4-table",
      "reports__settings",
      advancedList,
      clonnedAdvancedList,
      showHiddenRows
    );

    this.setState({
      advancedList: result.advancedList,
      clonnedAdvancedList: result.clonedAdvancedList,
      showHiddenRows: result.showHiddenRows,
      reDrawDT: true,
    });
  };

  handleShowHiddenRows = async () => {
    let table = window.$("#reportv4-table").DataTable();
    table.destroy();

    let { advancedList, clonnedAdvancedList, showHiddenRows } = this.state;

    showHiddenRows = !showHiddenRows;

    if (showHiddenRows) {
      //show hidden rows
      advancedList = clonnedAdvancedList;
    } else {
      //hide again hidden rows
      let list = advancedList.filter((l) => !l.hide);
      advancedList = list;
    }

    this.setState({
      advancedList,
      showHiddenRows,
      reDrawDT: true, //to re daraw the datatable
    });
  };

  _handlesHideUnhideRowsCal = (item) => {
    let { calculations, clonnedCalculations, showHiddenRowsCal } = this.state;

    let result = handleHideUnhideRows(
      item,
      "#reportv4-table",
      "reports__settings",
      calculations,
      clonnedCalculations,
      showHiddenRowsCal
    );

    this.setState({
      calculations: result.advancedList,
      clonnedCalculations: result.clonedAdvancedList,
      showHiddenRowsCal: result.showHiddenRows,
      reDrawDT: true,
    });
  };

  handleShowHiddenRowsCal = async () => {
    let table = window.$("#reportv4-table").DataTable();
    table.destroy();

    let { calculations, clonnedCalculations, showHiddenRowsCal } = this.state;

    showHiddenRowsCal = !showHiddenRowsCal;

    if (showHiddenRowsCal) {
      //show hidden rows
      calculations = clonnedCalculations;
    } else {
      //hide again hidden rows
      let list = calculations.filter((l) => !l.hide);
      calculations = list;
    }

    this.setState({
      calculations,
      showHiddenRowsCal,
      reDrawDT: true, //to re daraw the datatable
    });
  };

  handleReportTypes = (type) => {
    let { formErrors } = this.state;
    formErrors = Validation.handleValidation(
      "reportTypesVal",
      type.value,
      formErrors
    );

    let value = type.value || "";

    let reportLayouts = this.state.reportLayouts || [];
    let reportOptions = [];

    let found = reportLayouts.find((rop) => rop.value === value);

    if (found) {
      reportOptions.push({
        label: found.label,
        value: found.value,
        selected: 1,
      });
    }

    this.setState({
      reportLayouts: reportOptions,
      reportOptionVal: type,
      formErrors,
      reportId: found.value,
    });
  };

  _handleReportTypes = (type) => {
    let { formErrors } = this.state;
    formErrors = Validation.handleValidation(
      "reportTypesVal",
      type.value,
      formErrors
    );

    let value = type.value || "";

    let reportLayoutsVal = this.state.reportTypes || [];
    let reportOptions = [];

    let found = reportLayoutsVal.find((rop) => rop.value === value);

    if (found) {
      reportOptions.push({
        label: found.label,
        value: found.value,
        selected: 1,
      });
    }
    this.setState({
      reportTypesVal: type,
      reportTypes: reportOptions,
      formErrors,
    });
  };

  menuOpened = async () => {
    this.setState({ menuIsOpen: true });
    let _this = this;
    $(document).ready(async function () {
      $('<i class="fa fa-trash report--del"></i>').appendTo(
        ".report_menu.custon_select-selector-inner__option"
      );
      $(".report_menu.custon_select-selector-inner__option i").on(
        "click",
        async function (e) {
          e.preventDefault();
          var p_id = $(this).parent().attr("id");
          let id_split = p_id && p_id.split("-"); //["react", "select", "3", "option", "1"]
          let id = id_split[id_split.length - 1];

          let { reportOptions } = _this.state;
          let rep_opt = reportOptions.length > 0 && reportOptions[id];
          //call delete report options API
          await _this.deleteReport(rep_opt.value, rep_opt.locked);
        }
      );
    });
  };

  menuClosed = () => {
    this.setState({ menuIsOpen: false });
  };

  //   //a function that checks  api error
  //   handleApiRespErr = async (error) => {
  //     if (
  //       error === "Session has expired. Please login again." ||
  //       error === "User has not logged in."
  //     ) {
  //       this.props.clearStatesAfterLogout();
  //       this.props.history.push("/login");
  //       toast.error(error);
  //     } else if (error === "User has not logged into a production.") {
  //       toast.error(error);
  //       this.props.history.push("/login-table");
  //     } else {
  //       //Netork Error || api error
  //       toast.error(error);
  //     }
  //   };

  //   handleReportTypes = async (type) => {
  //     let { formErrors } = this.state;

  //     formErrors = Validation.handleValidation(
  //       "reportTypesVal",
  //       type.value,
  //       formErrors
  //     );

  //     this.setState({
  //       reportTypesVal: type,
  //       reportOptionVal: { label: "Select Report Options", value: "" },
  //       reportOptions: [],
  //       formErrors,
  //     });
  //     if (type.value) {
  //       //call API to get report options according to selected type
  //       this.setState({ isLoading: true });
  //       await this.props.getReportOptions(type.value);
  //       //success case of get Report Options
  //       if (this.props.report.getReportOptionsSuccess) {
  //         // toast.success(this.props.report.getReportOptionsSuccess);
  //         //report options List
  //         if (
  //           this.props.report.getReportOptions &&
  //           this.props.report.getReportOptions.length > 0
  //         ) {
  //           let getReportOptions = this.props.report.getReportOptions;
  //           let getReportOptionsArr = [];
  //           getReportOptions.map((t, i) => {
  //             getReportOptionsArr.push({
  //               label: t.layout,
  //               value: t.id,
  //               locked: t.locked,
  //             });
  //           });
  //           this.setState({
  //             reportOptions: getReportOptionsArr,
  //           });
  //         }
  //       }
  //       //error case of get Report Options
  //       if (this.props.report.getReportOptionsError) {
  //         this.handleApiRespErr(this.props.report.getReportOptionsError);
  //       }
  //       this.props.clearReportsStates();
  //       this.setState({ isLoading: false });
  //     }
  //   };

  //   handleReportOptions = (layout) => {
  //     let { formErrors } = this.state;
  //     formErrors = Validation.handleValidation(
  //       "reportOptionVal",
  //       layout.value,
  //       formErrors
  //     );

  //     this.setState({ reportOptionVal: layout, formErrors });

  //     if (layout.value) {
  //       //clear states on file and report name
  //       this.setState({
  //         addNewReportToggle: false,
  //         reportFile: "",
  //         reportName: "",
  //         private: false,

  //         formErrors: {
  //           reportFile: "",
  //           reportName: "",
  //         },
  //       });
  //     }
  //   };

  addNewReport = async () => {
    await this.setState((prevState) => ({
      addNewReportToggle: !prevState.addNewReportToggle,
    }));

    if (this.state.addNewReportToggle) {
      //add new report case then report options set to default

      this.setState((prevState) => ({
        reportOptionVal: { label: "Select Report Options", value: "" },
        formErrors: {
          ...prevState.formErrors,
          reportOptionVal: "",
        },
      }));

      //adding drag and drop attachments listeners
      addDragAndDropFileListners("drop-area-report", this.uploadReportFile);
      //end
    } else {
      //clear states on file and report name
      this.setState({
        reportFile: "",
        reportName: "",
        private: false,

        formErrors: {
          reportFile: "",
          reportName: "",
        },
      });
    }
  };

  handleCheckbox = async (e) => {
    this.setState({ private: e.target.checked });
  };

  handleFieldChange = (e) => {
    let { formErrors } = this.state;
    let { name, value } = e.target;
    formErrors = Validation.handleValidation(name, value, formErrors);
    this.setState({ [name]: value, formErrors });
  };

  uploadReportFile = async (f) => {
    let { formErrors } = this.state;
    let type = f[0].type;
    let file = f[0];
    let size = f[0].size;

    if (type == "mrt") {
      if (size <= 2000000) {
        const result = await toBase64(file).catch((e) => e);
        if (result instanceof Error) {
          toast.error(result.message);
          return;
        } else {
          formErrors = Validation.handleValidation(
            "reportFile",
            result,
            formErrors
          );
          this.setState({
            reportFile: result,
            formErrors,
          });
        }
      } else {
        toast.error("Maximum Image Size 2MB");
      }
    } else {
      toast.error("Please Select only Images of type: '.mrt'");
    }
  };

  deleteReport = async (reportID, locked) => {
    let reportType = this.state.reportTypesVal.value || "";
    if (locked != "Y") {
      if (reportType && reportID) {
        this.setState({ isLoading: true });
        await this.props.deleteReport(reportType, reportID);

        //success case of delete Report success
        if (this.props.report.deleteReportSuccess) {
          toast.success(this.props.report.deleteReportSuccess);
          //report options List
          if (
            this.props.report.deleteReport &&
            this.props.report.deleteReport.length > 0
          ) {
            let reportOptions = this.props.report.deleteReport;
            let reportOptionsArr = [];
            reportOptions.map((t, i) => {
              reportOptionsArr.push({
                label: t.layout,
                value: t.id,
                locked: t.locked,
              });
            });
            this.setState({
              reportOptions: reportOptionsArr,
            });
          }

          this.setState({
            addNewReportToggle: false,
            reportFile: "",
            reportName: "",
            private: false,
            reportOptionVal: { label: "Select Report Options", value: "" },
            formErrors: {
              reportFile: "",
              reportName: "",
            },
          });
        }
        //error case of Delete Report
        if (this.props.report.deleteReportError) {
          this.handleApiRespErr(this.props.report.deleteReportError);
        }

        this.setState({ isLoading: false });
      } else {
        toast.error("Report Type OR Report ID is Missing!");
      }
    } else {
      toast.error("You can't delete this Report!");
    }
  };

  onSave = async () => {
    let formErrors = this.state.formErrors;
    if (!this.state.reportTypesVal.value) {
      formErrors.reportTypesVal = "This Field is Required.";
    }
    if (this.state.addNewReportToggle) {
      //add new report case
      if (!this.state.reportName) {
        formErrors.reportName = "This Field is Required.";
      }
      if (!this.state.reportFile) {
        formErrors.reportFile = "MRT File is Required.";
      }
    } else {
      //use reports from the drop down list
      if (!this.state.reportOptionVal.value) {
        formErrors.reportOptionVal = "This Field is Required.";
      }
    }
    this.setState({
      formErrors: formErrors,
    });

    let check = false;

    if (this.state.addNewReportToggle) {
      if (!formErrors.reportFile && !formErrors.reportName) {
        check = true;
      }
    } else {
      if (!formErrors.reportOptionVal) {
        check = true;
      }
    }

    if (!formErrors.reportTypesVal && check) {
      let {
        reportTypesVal,
        reportOptionVal,
        reportFile,
        reportName,
        private: privateCheck,
      } = this.state;
      let data = "";
      if (reportOptionVal.value) {
        //get report data according to available report options
        data = {
          reportType: reportTypesVal.value,
          reportID: reportOptionVal.value,
        };
      } else {
        //create new reports and get data according to that report
        data = {
          reportType: reportTypesVal.value,
          reportFile,
          reportName,
          private: privateCheck,
        };
      }
      if (data) {
        this.setState({ isLoading: true });
        await this.props.getReportData(data);
        //success case of get Report Data
        if (this.props.report.getReportDataSuccess) {
          toast.success(this.props.report.getReportDataSuccess);
          let jsonData = this.props.report.getReportData.jsonData || "";
          let reportFile = this.props.report.getReportData.reportFile || "";
          let key = this.props.report.getReportData.key || "";

          if (jsonData && reportFile && key) {
            localStorage.setItem("reportFile", reportFile);
            localStorage.setItem("jsonData", jsonData);
            localStorage.setItem("key", key);
            var path =
              window.location.protocol +
              "//" +
              window.location.host +
              "/report-view";

            window.open(path, "_blank");
          }
        }
        //error case of get Report Data
        if (this.props.report.getReportDataError) {
          this.handleApiRespErr(this.props.report.getReportDataError);
        }

        this.setState({ isLoading: false });
      }
    }
  };

  agreeHanle = (e) => {
    this.setState({ agree: !this.state.agree });
  };

  render() {
    let { advancedList } = this.state;
    return (
      <>
        {this.state.isLoading ? <div className="se-pre-con"></div> : ""}

        <div className="user_setup_main">
          <header>
            <TopNav />

            <div className="user_setup_heading">
              <div className="header_menu">
                <Link to="/dashboard">
                  <img
                    src="images/dash-logo.png"
                    className="img-fluid"
                    alt="logo"
                  />
                </Link>
                <Link
                  className="setup_menu"
                  to="#"
                  data-target="#top_nav_toggle1"
                >
                  <img src="images/top-menu.png" className="" alt="top-menu" />
                </Link>
              </div>
              <h2>Vat Submission</h2>
              <span>
                <img
                  src="./images/user-setup/lock.png"
                  alt="lock"
                  className="img-fluid"
                />
              </span>
            </div>
            <div className="user_setup_headerbox">
              <div className="user_setup_play_div">
                <ul>
                  <li>
                    <p className="user_setup_play_video">Video</p>
                  </li>
                  <li>
                    <p className="user_setup_play_tuturial">Tutorials</p>
                  </li>
                </ul>
                <span className="user_setup_play_icon">
                  <img
                    src="./images/user-setup/play.png"
                    alt="play"
                    className="img-fluid"
                  />
                </span>
              </div>
              <div className="user_setup_header_rightbox">
                <p>
                  In our{" "}
                  <span>
                    <a href="#">Video</a>
                  </span>{" "}
                  learn how to use vat submission Read our{" "}
                  <span>
                    <a href="#">help article</a>
                  </span>{" "}
                  to learn More
                </p>
              </div>
              <span>
                <img
                  className="close_top_sec"
                  src="images/user-setup/cross.png"
                  alt="cross"
                ></img>
              </span>
            </div>
          </header>
          <div className="col-sm-12 table_white_box">
            <section id="">
              <div className="container-fluid ">
                <div className="main_wrapper p-10">
                  <div className="forgot_body px-3">
                    <div className="row mt-4">
                      <div className="col-xl-3 col-lg-0"></div>
                      <div className="col-md-3">
                        {/* dropdown coding start */}
                        <div className="form-group custon_select">
                          <label htmlFor="">Report Type</label>
                          <Select
                            className="width-selector"
                            value={this.state.reportTypesVal}
                            classNamePrefix="custon_select-selector-inner"
                            options={this.state.reportTypes}
                            onChange={this._handleReportTypes}
                            theme={(theme) => ({
                              ...theme,
                              border: 0,
                              borderRadius: 0,
                              colors: {
                                ...theme.colors,
                                primary25: "#f2f2f2",
                                primary: "#f2f2f2",
                              },
                            })}
                          />
                          <div className="text-danger error-12">
                            {this.state.formErrors.reportTypesVal !== ""
                              ? this.state.formErrors.reportTypesVal
                              : ""}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row mt-4">
                      <div class="col-xl-3 col-lg-0"></div>
                      <div className="form-group col-md-3 ">
                        <label htmlFor="">Report Option</label>
                        {/* dropdown coding start */}
                        <div className="custon_select">
                          {/* <Select
                                  className="width-selector"
                                  value={this.state.reportOptionVal}
                                  classNamePrefix="report_menu custon_select-selector-inner"
                                  options={this.state.reportOptions}
                                  onChange={this.handleReportOptions}
                                  theme={theme => ({
                                    ...theme,
                                    border: 0,
                                    borderRadius: 0,
                                    colors: {
                                      ...theme.colors,
                                      primary25: "#f2f2f2",
                                      primary: "#f2f2f2"
                                    }
                                  })}
                                /> */}

                          <Select
                            className="width-selector"
                            // onMenuOpen={this.state.menuIsOpen}
                            closeMenuOnSelect={true}
                            value={this.state.reportOptionVal}
                            classNamePrefix="report_menu custon_select-selector-inner"
                            onMenuOpen={this.menuOpened}
                            onMenuClose={this.menuClosed}
                            onChange={this.handleReportTypes}
                            options={this.state.reportLayouts}
                            theme={(theme) => ({
                              ...theme,
                              border: 0,
                              borderRadius: 0,
                              colors: {
                                ...theme.colors,
                                primary25: "#f2f2f2",
                                primary: "#f2f2f2",
                              },
                            })}
                          />

                          <span
                            className="input_field_icons rit-icon-input"
                            // data-toggle="collapse"
                            // data-target="#asd"
                            onClick={this.addNewReport}
                          >
                            <i className="fa fa-plus"></i>
                          </span>
                        </div>
                        <div className="text-danger error-12">
                          {this.state.formErrors.reportOptionVal !== ""
                            ? this.state.formErrors.reportOptionVal
                            : ""}
                        </div>

                        {this.state.addNewReportToggle && (
                          // <div className="collapse pl-3 pr-3 w-100 id="asd">
                          <div className=" pl-3 pr-3 w-100">
                            <div className="row">
                              <div className="col-12 mt-2 mb-2">
                                <div className="form-group custon_select  text-center mb-0 border-rad-5">
                                  <div id="drop-area-report">
                                    <input
                                      type="file"
                                      id="fileElem-rep"
                                      className="form-control d-none"
                                      accept="application/pdf"
                                      onChange={(e) => {
                                        this.uploadReportFile(e.target.files);
                                      }}
                                      onClick={(event) => {
                                        event.currentTarget.value = null;
                                      }} //to upload the same file again
                                    />

                                    <label
                                      className="upload-label"
                                      htmlFor="fileElem-rep"
                                    >
                                      <div className="upload-text">
                                        <img
                                          src="images/drag-file.png"
                                          className="import_icon img-fluid"
                                          alt="upload-report"
                                        />
                                      </div>
                                    </label>
                                  </div>
                                </div>
                                <div className="text-danger error-12">
                                  {this.state.formErrors.reportFile !== ""
                                    ? this.state.formErrors.reportFile
                                    : ""}
                                </div>
                              </div>
                              <div className="col-12">
                                <div className="form-group custon_select mt-3">
                                  <div className="modal_input">
                                    <label>Report Name</label>
                                    <input
                                      type="text"
                                      className="form-control pl-0"
                                      id="usr"
                                      name="reportName"
                                      value={this.state.reportName}
                                      onChange={this.handleFieldChange}
                                    />
                                    <div className="text-danger error-12">
                                      {this.state.formErrors.reportName !== ""
                                        ? this.state.formErrors.reportName
                                        : ""}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-12 align-self-center mb-2">
                                <div className="form-group remember_check">
                                  <input
                                    type="checkbox"
                                    id="remember"
                                    checked={this.state.private}
                                    onChange={this.handleCheckbox}
                                  />
                                  <label htmlFor="remember"></label>
                                  <p className="pt-1">Private:</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <section>
                      <div className="row mt-4">
                        <div class="col-xl-3 col-lg-0"></div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label htmlFor="" className="pr-2">
                              VAT Rego
                            </label>
                            <input
                              type="text"
                              value={this.state.vatRego}
                              onChange={this.handleFieldChange}
                              name="vatRego"
                              className="form-control mm-new_box_input"
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="" className="pr-2">
                              VAT Period
                            </label>
                            <input
                              type="text"
                              value={this.state.vatPeriod}
                              onChange={this.handleFieldChange}
                              name="vatPeriod"
                              className="form-control mm-new_box_input"
                            />
                          </div>
                          <div className="form-group mm-new-btns2">
                            <button
                              className="btn  p-2 ms-btn-size"
                              type="button"
                              onClick={this.calculationVatSubmission}
                            >
                              Calculate
                            </button>
                            <button
                              className="btn p-2 ms-btn-size"
                              type="button"
                              onClick={this.checkObligationuserHMRCAuthorized}
                            >
                              Check Obligation
                            </button>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
            </section>

            <div className="row">
              <div className="col-xl-6 col-md-12 col-sm-12 m-auto w_50">
                <div className="user_setup_popup__table new_advance_table m-auto p-3">
                  <h2>Advanced</h2>
                  <table className="table" id="usersteup-modal">
                    <thead className="thead_bg thead_bg_sass hover-border">
                      <tr>
                        <th scope="col">
                          <span className="user_setup_hed">Category</span>
                        </th>
                        <th scope="col">
                          <span className="user_setup_hed">Description</span>
                        </th>
                        <th scope="col" className="value__field--wrapperuser">
                          <span className="user_setup_hed">Value</span>
                        </th>
                        <th scope="col" className="text__right__contentuser">
                          <span className="user_setup_hed">Hide</span>
                        </th>
                        <th className="table__inner--th">
                          <div className="menu_bars_dropdown">
                            <Dropdown
                              alignRight="false"
                              drop="up"
                              className="analysis-card-dropdwn "
                            >
                              <Dropdown.Toggle variant="" id="">
                                <span className="fa fa-bars "></span>
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.Item to="#/action-1" className="">
                                  <div
                                    className="pr-0"
                                    onClick={this.handleShowHiddenRows}
                                  >
                                    <div className="form-group remember_check form-group_sass remember_check_sass mm_check4">
                                      <input
                                        type="checkbox"
                                        id="showHiddenRows"
                                        name="showHiddenRows"
                                        checked={this.state.showHiddenRows}
                                        onClick={this.handleShowHiddenRows}
                                      />
                                      <label
                                        htmlFor="showHiddenRows"
                                        className="mr-0"
                                      >
                                        Show Hidden Rows
                                      </label>
                                    </div>
                                  </div>
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {advancedList.map((list, i) => {
                        return (
                          <tr key={i}>
                            <td className=" ">{list.category}</td>
                            <td>{list.description}</td>
                            {list.valueType === "List" ? (
                              <td className="pt-0 pb-0 text-left">
                                <Select
                                  classNamePrefix="custon_select-selector-inner main__dropdown--wrapper1"
                                  styles={_customStyles}
                                  value={{
                                    label: list.value,
                                    value: list.value,
                                  }}
                                  options={list.valueOptions}
                                  onChange={(obj) =>
                                    this._handleValueOptions(
                                      "list",
                                      obj,
                                      list,
                                      i
                                    )
                                  }
                                  theme={(theme) => ({
                                    ...theme,
                                    border: 0,
                                    borderRadius: 0,
                                    colors: {
                                      ...theme.colors,
                                      primary25: "#f2f2f2",
                                      primary: "#f2f2f2",
                                    },
                                  })}
                                />
                              </td>
                            ) : list.valueType === "MultiList" ? (
                              <td className="pt-0 pb-0 text-left">
                                <Select
                                  className="basic-multi-select main__dropdown--wrapper1"
                                  name="multiple-op"
                                  // classNamePrefix="custon_select-selector-inner"
                                  defaultValue={{
                                    label: "Select Approval Group",
                                    value: "",
                                  }}
                                  value={list.multiValue || []}
                                  options={list.valueOptions}
                                  isClearable={false}
                                  onChange={(obj) =>
                                    this._handleValueOptions(
                                      "multiList",
                                      obj,
                                      list,
                                      i
                                    )
                                  }
                                  isMulti
                                  theme={(theme) => ({
                                    ...theme,
                                    border: 0,
                                    borderRadius: 0,
                                    colors: {
                                      ...theme.colors,
                                      primary25: "#f2f2f2",
                                      primary: "#f2f2f2",
                                    },
                                  })}
                                />
                              </td>
                            ) : list.valueType === "Date" ? (
                              <td>
                                <div className="table_input_field">
                                  <DatePicker
                                    selected={Number(list.value)}
                                    dateFormat="d MMM yyyy"
                                    autoComplete="off"
                                    onChange={(date) =>
                                      this._handleValueOptions(
                                        "date",
                                        date,
                                        list,
                                        i
                                      )
                                    }
                                  />
                                </div>
                              </td>
                            ) : list.valueType === "Check" ? (
                              <td>
                                <div className="col-auto p-0">
                                  <div className="form-group remember_check  text-center pt-0 float-left">
                                    <input
                                      type="checkbox"
                                      id={`chk${i}`}
                                      checked={
                                        list.value === "Y" || list.value === "1"
                                          ? true
                                          : false
                                      }
                                      onChange={(e) =>
                                        this._handleValueOptions(
                                          "checkbox",
                                          e,
                                          list,
                                          i
                                        )
                                      }
                                    />
                                    <label htmlFor={`chk${i}`}></label>
                                  </div>
                                </div>
                              </td>
                            ) : list.valueType === "Numeric" ? (
                              <td>
                                <div className="table_input_field">
                                  <input
                                    type="number"
                                    value={list.value}
                                    onChange={(e) =>
                                      this._handleValueOptions(
                                        "number",
                                        e,
                                        list,
                                        i
                                      )
                                    }
                                  />
                                </div>
                              </td>
                            ) : list.valueType === "Text" ? (
                              <td>
                                <div className="table_input_field">
                                  <input
                                    type="text"
                                    value={list.value}
                                    onChange={(e) =>
                                      this._handleValueOptions(
                                        "text",
                                        e,
                                        list,
                                        i
                                      )
                                    }
                                  />
                                </div>
                              </td>
                            ) : (
                              <td>{list.value}</td>
                            )}
                            <td className="text__right--user">
                              <div className="custom-radio">
                                <label
                                  className="check_main check_main_sass remember_check"
                                  htmlFor={`hideUnhideRows${i}`}
                                >
                                  <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    name={"hideUnhideRows"}
                                    id={`hideUnhideRows${i}`}
                                    checked={false}
                                    onChange={(e) =>
                                      this._handlesHideUnhideRows(list)
                                    }
                                  />
                                  <span
                                    className={
                                      list.hide
                                        ? "dash_checkmark bg_clr"
                                        : "dash_checkmark"
                                    }
                                  ></span>
                                </label>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <section>
              <div className="row">
                <div className="col-xl-6 col-md-12 col-sm-12 m-auto w_50">
                  <div className="user_setup_popup__table new_advance_table m-auto p-3">
                    <h2>Calculation</h2>
                    <table className="table" id="usersteup-modal">
                      <thead className="thead_bg thead_bg_sass hover-border">
                        <tr>
                          <th scope="col">
                            <span className="user_setup_hed">Category</span>
                          </th>
                          <th scope="col">
                            <span className="user_setup_hed">Description</span>
                          </th>
                          <th scope="col" className="value__field--wrapperuser">
                            <span className="user_setup_hed">Value</span>
                          </th>
                          <th scope="col" className="text__right__contentuser">
                            <span className="user_setup_hed">Hide</span>
                          </th>
                          <th className="table__inner--th">
                            <div className="menu_bars_dropdown">
                              <Dropdown
                                alignRight="false"
                                drop="up"
                                className="analysis-card-dropdwn "
                              >
                                <Dropdown.Toggle variant="" id="">
                                  <span className="fa fa-bars "></span>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                  <Dropdown.Item to="#/action-1" className="">
                                    <div
                                      className="pr-0"
                                      onClick={this.handleShowHiddenRowsCal}
                                    >
                                      <div className="form-group remember_check form-group_sass remember_check_sass mm_check4">
                                        <input
                                          type="checkbox"
                                          id="showHiddenRowsCal"
                                          name="showHiddenRowsCal"
                                          checked={this.state.showHiddenRowsCal}
                                          onClick={this.handleShowHiddenRowsCal}
                                        />
                                        <label
                                          htmlFor="showHiddenRowsCal"
                                          className="mr-0"
                                        >
                                          Show Hidden Rows
                                        </label>
                                      </div>
                                    </div>
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.calculations?.map((list, i) => {
                          return (
                            <tr key={i}>
                              <td className=" ">{list.category}</td>
                              <td>{list.description}</td>
                              {list.valueType === "List" ? (
                                <td className="pt-0 pb-0 text-left">
                                  <Select
                                    classNamePrefix="custon_select-selector-inner main__dropdown--wrapper1"
                                    styles={_customStyles}
                                    value={{
                                      label: list.value,
                                      value: list.value,
                                    }}
                                    options={list.valueOptions}
                                    onChange={(obj) =>
                                      this._handleValueOptions(
                                        "list",
                                        obj,
                                        list,
                                        i
                                      )
                                    }
                                    theme={(theme) => ({
                                      ...theme,
                                      border: 0,
                                      borderRadius: 0,
                                      colors: {
                                        ...theme.colors,
                                        primary25: "#f2f2f2",
                                        primary: "#f2f2f2",
                                      },
                                    })}
                                  />
                                </td>
                              ) : list.valueType === "MultiList" ? (
                                <td className="pt-0 pb-0 text-left">
                                  <Select
                                    className="basic-multi-select main__dropdown--wrapper1"
                                    name="multiple-op"
                                    // classNamePrefix="custon_select-selector-inner"
                                    defaultValue={{
                                      label: "Select Approval Group",
                                      value: "",
                                    }}
                                    value={list.multiValue || []}
                                    options={list.valueOptions}
                                    isClearable={false}
                                    onChange={(obj) =>
                                      this._handleValueOptions(
                                        "multiList",
                                        obj,
                                        list,
                                        i
                                      )
                                    }
                                    isMulti
                                    theme={(theme) => ({
                                      ...theme,
                                      border: 0,
                                      borderRadius: 0,
                                      colors: {
                                        ...theme.colors,
                                        primary25: "#f2f2f2",
                                        primary: "#f2f2f2",
                                      },
                                    })}
                                  />
                                </td>
                              ) : list.valueType === "Date" ? (
                                <td>
                                  <div className="table_input_field">
                                    <DatePicker
                                      selected={Number(list.value)}
                                      dateFormat="d MMM yyyy"
                                      autoComplete="off"
                                      onChange={(date) =>
                                        this._handleValueOptions(
                                          "date",
                                          date,
                                          list,
                                          i
                                        )
                                      }
                                    />
                                  </div>
                                </td>
                              ) : list.valueType === "Check" ? (
                                <td>
                                  <div className="col-auto p-0">
                                    <div className="form-group remember_check  text-center pt-0 float-left">
                                      <input
                                        type="checkbox"
                                        id={`chk${i}`}
                                        checked={
                                          list.value === "Y" ||
                                          list.value === "1"
                                            ? true
                                            : false
                                        }
                                        onChange={(e) =>
                                          this._handleValueOptions(
                                            "checkbox",
                                            e,
                                            list,
                                            i
                                          )
                                        }
                                      />
                                      <label htmlFor={`chk${i}`}></label>
                                    </div>
                                  </div>
                                </td>
                              ) : list.valueType === "Numeric" ? (
                                <td>
                                  <div className="table_input_field">
                                    <input
                                      type="number"
                                      value={list.value}
                                      onChange={(e) =>
                                        this._handleValueOptions(
                                          "number",
                                          e,
                                          list,
                                          i
                                        )
                                      }
                                    />
                                  </div>
                                </td>
                              ) : list.valueType === "Text" ? (
                                <td>
                                  <div className="table_input_field">
                                    <input
                                      type="text"
                                      value={list.value}
                                      onChange={(e) =>
                                        this._handleValueOptions(
                                          "text",
                                          e,
                                          list,
                                          i
                                        )
                                      }
                                    />
                                  </div>
                                </td>
                              ) : (
                                <td>{list.value}</td>
                              )}
                              <td className="text__right--user">
                                <div className="custom-radio">
                                  <label
                                    className="check_main check_main_sass remember_check"
                                    htmlFor={`hideUnhideRowsCal${i}`}
                                  >
                                    <input
                                      type="checkbox"
                                      className="custom-control-input"
                                      name={"hideUnhideRows"}
                                      id={`hideUnhideRowsCal${i}`}
                                      checked={false}
                                      onChange={(e) =>
                                        this._handlesHideUnhideRowsCal(list)
                                      }
                                    />
                                    <span
                                      className={
                                        list.hide
                                          ? "dash_checkmark bg_clr"
                                          : "dash_checkmark"
                                      }
                                    ></span>
                                  </label>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              {/* <div className="row">
                <div className="col-xl-6 col-md-12 col-sm-12 m-auto m-auto">
                  <h3 className="mm-calculte-text">Calculation</h3>
                  <form action="" className="mm-new-form">
                    <div className="form-group d-flex">
                      <label htmlFor="" className="pr-2 ">
                        Vat Due on Outputs:
                      </label>
                      <input type="number" className="form-control" />
                    </div>
                    <div className="form-group d-flex">
                      <label htmlFor="" className="pr-2 ">
                        Vat Due on EC Acquisition:
                      </label>
                      <input type="number" className="form-control" />
                    </div>
                    <div className="form-group d-flex">
                      <label htmlFor="" className="pr-2 ">
                        Total Vat:
                      </label>
                      <input type="number" className="form-control" />
                    </div>
                    <div className="form-group d-flex">
                      <label htmlFor="" className="pr-2 ">
                        VAT Reclaimed On Inputs:
                      </label>
                      <input type="number" className="form-control" />
                    </div>
                    <div className="form-group d-flex">
                      <label htmlFor="" className="pr-2 ">
                        Net VAT:
                      </label>
                      <input type="number" className="form-control" />
                    </div>
                    <div className="form-group d-flex">
                      <label htmlFor="" className="pr-2 ">
                        Net Sales And Outputs:
                      </label>
                      <input type="number" className="form-control" />
                    </div>
                    <div className="form-group d-flex">
                      <label htmlFor="" className="pr-2 ">
                        Net Purchases And Intputs:
                      </label>
                      <input type="number" className="form-control" />
                    </div>
                    <div className="form-group d-flex">
                      <label htmlFor="" className="pr-2 ">
                        Net EC Supplies:
                      </label>
                      <input type="number" className="form-control" />
                    </div>
                    <div className="form-group d-flex">
                      <label htmlFor="" className="pr-2 ">
                        Net EC Acquisitions:
                      </label>
                      <input type="number" className="form-control" />
                    </div>
                    <div className="form-group d-flex">
                      <label htmlFor="" className="pr-2 ">
                        Vat Rego:
                      </label>
                      <input type="number" className="form-control" />
                    </div>
                  </form>
                </div>
              </div> */}
              <section className="">
                <div className="row">
                  <div className="col-xl-6 col-md-12 col-sm-12 m-auto mm-vetinfo-main">
                    <div className="mm-vet-info">
                      <p>
                        When you submit this VAT information you are making a
                        legal daclaration that the information is true and
                        complete. A fasle declaration can result in prosecution.
                      </p>
                      <div className="">
                        <label htmlFor="agree-me">
                          <input
                            type="checkbox"
                            id="agree-me"
                            onClick={(e) => this.agreeHanle(e)}
                            checked={this.state.agree}
                          />
                          I agree
                        </label>
                        <button
                          className="btn mn-btn-submet"
                          disabled={!this.state.agree ? "disabled" : ""}
                          type="button"
                          onClick={this.submituserHMRCAuthorized}
                        >
                          Submit
                        </button>
                        <label htmlFor="">No Vet Period</label>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </section>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  account: state.account,
});

export default connect(mapStateToProps, {
  getPrimeVatSubmission: AccountActions.getPrimeVatSubmission,
  calculationVatSubmission: AccountActions.calculateVatSubmission,
  userHMRCAuthorized: AccountActions.userHMRCAuthorized,
  primeHMRCTokenAuthorization: AccountActions.primeHMRCTokenAuthorization,
  authenticateHMRCUser: AccountActions.authenticateHMRCUser,
})(VatSubmission);
