import React, { Component, useContext } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { _customStyles } from "../../../../Constants/Constants";
import * as AccountActions from "../../../../Actions/AccountActions/AccountActions";
import { handleAPIErr, handleValueOptions } from "../../../../Utils/Helpers";
import Reports from "../../../Modals/Accounts/Reports/Reports";

class BankDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      addNewReportToggle: false,
      reportTypesVal: { label: "", value: "" },
      reportTypes: [{ label: "", value: "" }],
      openAccountReportModal: false,
      reportOptionVal: { label: "", value: "" },
      reportOptions: [],
      menuIsOpen: false,
      bankDetails: [],
      clonedBankDetails: [],
      bank: [],
      statementBalance: [],
      bankBalance: [],
      adjustmentBankBalance: [],
      variance: [],
      period: [],
      filtersArr: [
        { filter: "category", condition: "equal", value: "settings" },
        { filter: "description", condition: "equal", value: "abc" },
      ],
      fltrsColmnFirst: [
        { id: "1", filter: "category", name: "Category", checked: false },
        { id: "2", filter: "description", name: "Description", checked: false },
        { id: "3", filter: "value", name: "Value", checked: false },
      ],
      fltrsColmnSecond: [
        { id: "4", condition: "Contains", checked: false },
        { id: "5", condition: "Doesn't contain", checked: false },
        { id: "6", condition: "Equal", checked: false },
        { id: "7", condition: "Not Equal", checked: false },
        { id: "8", condition: "Starts With", checked: false },
        { id: "9", condition: "Over", checked: false },
        { id: "10", condition: "Under", checked: false },
        { id: "11", condition: "Over Equal", checked: false },
        { id: "12", condition: "Under Equal", checked: false },
      ],
      reportFile: "",
      reportName: "",
      private: false,

      formErrors: {
        reportTypesVal: "",
        reportOptionVal: "",
        reportFile: "",
        reportName: "",
      },
    };
  }
  // async componentDidMount() {
  //   await this.getBankRecSummary();
  // }
  componentWillReceiveProps(props) {
    let bankDetails = props?.bankDetails || [];

    let bank = props?.bank || [{ label: "no", value: "no" }];
    let statementBalance = props?.statementBalance || [];
    let bankBalance = props?.bankBalance || [];
    let adjustmentBankBalance = props?.adjustmentBankBalance || [];
    let variance = props?.variance || [];
    let period = props?.period || [];
    this.setState({
      bankDetails,
      clonedBankDetails: bankDetails,
      bank,
      statementBalance,
      bankBalance,
      adjustmentBankBalance,
      variance,
      period,
    });
  }
  // getBankRecSummary = async (bankId) => {
  //   this.setState({ isLoading: true });
  //   let {
  //     bank,
  //     statementBalance,
  //     bankBalance,
  //     adjustmentBankBalance,
  //     variance,
  //     period,
  //   } = this.state;
  //   let bankCode = bankId ? bankId : "B1";
  //   await this.props.getBankRecSummary(bankCode);
  //   if (this.props.account.getBankRecSummarySuccess) {
  //     // toast.success(this.props.account.getBankRecSummarySuccess);
  //     let bankDetails = this.props.account.getBankRecSummary;
  //     bankDetails.map((lst, i) => {
  //       if ((lst.type && lst.type.toLowerCase() === "list") || "droplist") {
  //         let valOptns = [];
  //         if (lst.options && lst.options.length > 0) {
  //           lst.options.map((o, i) => {
  //             valOptns.push({ label: o.option, value: o.option });
  //           });
  //         }
  //         lst.options = valOptns;
  //       }
  //       return lst;
  //     });

  //     bank.push(bankDetails[0]);
  //     statementBalance.push(bankDetails[1]);
  //     bankBalance.push(bankDetails[2]);
  //     adjustmentBankBalance.push(bankDetails[3]);
  //     variance.push(bankDetails[4]);
  //     period.push(bankDetails[5]);
  //     this.setState({
  //       bankDetails,
  //       clonedBankDetails: bankDetails,
  //       bank,
  //       statementBalance,
  //       bankBalance,
  //       adjustmentBankBalance,
  //       variance,
  //       period,
  //     });
  //   }
  //   if (this.props.account.getBankRecSummaryError) {
  //     handleAPIErr(this.props.account.getBankRecSummaryError, this.props);
  //   }
  //   this.setState({ isLoading: false });
  // };
  getUpdateStatement = async (newBalance) => {
    this.setState({ isLoading: true });
    let bankCode = document.getElementsByClassName(
      "custon_select-selector-inner__single-value css-1uccc91-singleValue"
    )[0].innerText;
    await this.props.getUpdateStatement(bankCode, newBalance);
    if (this.props.account.getUpdateStatementSuccess) {
      toast.success(this.props.account.getUpdateStatementSuccess);

      let bankDetails = this?.props?.account.getUpdateStatement || [];
      bankDetails.map((lst, i) => {
        if ((lst.type && lst.type.toLowerCase() === "list") || "droplist") {
          let valOptns = [];
          if (lst.options && lst.options.length > 0) {
            lst.options.map((o, i) => {
              valOptns.push({ label: o.option, value: o.option });
            });
          }
          lst.options = valOptns;
        }
        return lst;
      });

      let bank = [];
      bank.push(bankDetails[0]);
      let statementBalance = [];
      statementBalance.push(bankDetails[1]);
      let bankBalance = [];
      bankBalance.push(bankDetails[2]);
      let adjustmentBankBalance = [];
      adjustmentBankBalance.push(bankDetails[3]);
      let variance = [];
      variance.push(bankDetails[4]);
      let period = [];
      period.push(bankDetails[5]);
      this.setState({
        bankDetails,
        clonedBankDetails: bankDetails,
        bank,
        statementBalance,
        bankBalance,
        adjustmentBankBalance,
        variance,
        period,
      });
    }
    if (this.props.account.getUpdateStatementError) {
      handleAPIErr(this.props.account.getUpdateStatementError, this.props);
    }
    this.setState({ isLoading: false });
  };
  getPostBank = async () => {
    this.setState({ isLoading: true });
    let bankCode = "B1";
    await this.props.getPostBank(bankCode);
    if (this.props.account.getPostBankSuccess) {
      toast.success(this.props.account.getPostBankSuccess);
      let attachment = this?.props?.account?.getPostBank.attachment || "";
      let fileName = this?.props?.getPostBank.fileName || "";

      // let obj = {
      //   contentType: "application/pdf",
      //   attachment: attachment || "",
      // };
      // downloadAttachments(obj, `${fileName}`);
    }
    if (this.props.account.getPostBankError) {
      handleAPIErr(this.props.account.getPostBankError, this.props);
    }
    this.setState({ isLoading: false });
  };

  handleSelectFields = async (type, val, item, index, bankCode) => {
    this.setState({ isLoading: true });
    // let pageReload = this.props.pageReload;
    // pageReload();

    let { bankDetails, clonedBankDetails } = this.state;
    let result = handleValueOptions(
      type,
      val,
      item,
      index,
      bankDetails,
      clonedBankDetails
    );
    this.setState(
      {
        bankDetails: result.advancedList,
        clonedBankDetails: result.clonedAdvancedList,
      },
      () => {
        if (item.name == "Bank") {
          this.props.pageReload();
        }
      }
    );
    this.setState({ isLoading: false });
  };

  handleFieldChange = (e, i) => {
    let { variance, adjustmentBankBalance, statementBalance, bankBalance } =
      this.state;
    if (e.target.name === "adjustmentBankBalance") {
      adjustmentBankBalance[i].value = e.target.value;
    }
    if (e.target.name === "statementBalance") {
      statementBalance[i].value = e.target.value;
    }
    if (e.target.name === "variance") {
      variance[i].value = e.target.value;
    }
    if (e.target.name === "bankBalance") {
      bankBalance[i].value = e.target.value;
    }
    this.setState({
      variance,
      adjustmentBankBalance,
      statementBalance,
      bankBalance,
    });
  };
  openModal = (name) => {
    if (name === "openAccountReportModal") {
      this.setState({ [name]: true });
    }
  };
  closeModal = (name) => {
    if (name === "openAccountReportModal") {
      this.setState({ [name]: false });
    }
  };
  render() {
    let {
      bank,
      statementBalance,
      bankBalance,
      adjustmentBankBalance,
      variance,
      period,
    } = this.state;
    return (
      <>
        {this.state.isLoading ? <div className="se-pre-con"></div> : ""}

        {/* Bank Details */}

        <div className="forgot_form_main sup-inner-pad position-relative">
          <div className="forgot_header mt-4">
            <div className="modal-top-header">
              <div className="row bord-btm">
                <div className="col-10 col-md-9 pl-0">
                  <h6 className="text-left def-blue">
                    <span>
                      {" "}
                      <img
                        src="images/arrow_up.png"
                        className="import_icon img-fluid pr-3 ml-3 sideBarAccord"
                        alt="arrow_up"
                        data-toggle="collapse"
                        data-target="#supplierDetails"
                      />{" "}
                    </span>
                    Bank Details
                  </h6>
                </div>
                <div className="col-2 col-md-3  d-flex justify-content-end s-c-main">
                  <Link
                    to="#"
                    className="zom-img float-right ml-md-5 pl-2 pr-2 mr-0 more-d mt-0 icon_dots"
                  >
                    <img
                      src="images/more.png"
                      className=" img-fluid"
                      alt="user"
                    />{" "}
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="documents_attatchments2 supplier2_sidetoggle mm-blok1">
            <div
              // onClick={() => this.openModal("openReportModal")}
              className="main-sec-attach main-bg"
            >
              Undo
            </div>
            <div
              // onClick={() => this.openModal("openReportModal")}
              className="main-sec-attach main-bg"
            >
              Redo
            </div>
            <div
              onClick={() => this.openModal("openAccountReportModal")}
              className="main-sec-attach main-bg"
            >
              Reports
            </div>
            <div
              className="main-sec-attach main-bg"
              // onClick={() => this.openModal("openAccountReportModal")}
              onClick={() => this.getPostBank()}
            >
              <span
                className="export_crd"
                data-toggle="collapse"
                data-target="#export"
              >
                {/* <span className="fa fa-angle-up float-left mr-2 sideBarAccord1"></span> */}
                Post
              </span>
            </div>
          </div>

          <div className="forgot_body collapse show" id="supplierDetails">
            <div className="row mt-4">
              <div className="col-md-3 mt-2">
                <div className="form-group custon_select mm_pr">
                  {bank.map((item, i) => {
                    return (
                      <>
                        <Select
                          key={i}
                          className="width-selector"
                          value={{ label: item.value, value: item.value }}
                          onChange={(e) => {
                            this.handleSelectFields(
                              "list",
                              e,
                              item,
                              i,
                              item.value
                            );
                          }}
                          readOnly={item.readOnly === 0 ? true : false}
                          classNamePrefix="custon_select-selector-inner"
                          options={item.options}
                          theme={(theme) => ({
                            ...theme,
                            border: 0,
                            borderRadius: 0,
                            colors: {
                              ...theme.colors,
                              primary25: "#f2f2f2",
                              primary: "#f2f2f2",
                            },
                          })}
                        />
                        <label className="move_label inactive">
                          {item.name}
                        </label>
                        <div className="text-danger error-12">
                          {this.state.formErrors.currency !== ""
                            ? this.state.formErrors.currency
                            : ""}
                        </div>
                      </>
                    );
                  })}
                </div>
                <div className="form-group custon_select mm_pr">
                  <div className="modal_input">
                    {statementBalance.map((item, i) => {
                      return (
                        <>
                          <input
                            type="number"
                            className="form-control mt-0"
                            id="usr"
                            name="statementBalance"
                            value={item.value}
                            readOnly={item.readOnly ? true : false}
                            onChange={(e) => this.handleFieldChange(e, i)}
                            onBlur={() => this.getUpdateStatement(item.value)}
                          />
                          <label className="move_label inactive">
                            {item.name}
                          </label>
                        </>
                      );
                    })}
                  </div>
                </div>
                <div className="form-group custon_select mm_pr">
                  <div className="modal_input">
                    {bankBalance.map((item, i) => {
                      return (
                        <>
                          <input
                            type="number"
                            className="form-control mt-0"
                            id="usr"
                            name="bankBalance"
                            value={item.value}
                            onChange={(e) => this.handleFieldChange(e, i)}
                            readOnly={item.readOnly ? true : false}
                          />
                          <label className="move_label inactive">
                            {item.name}
                          </label>
                        </>
                      );
                    })}
                  </div>
                  <div className="text-danger error-12">
                    {/* {this.state.formErrors.name !== ""
                                    ? this.state.formErrors.name
                                    : ""} */}
                  </div>
                </div>
              </div>
              <div className="col-3 "></div>
              {/* <div className="col-md-3 m-auto">
                    <div className="form-group custon_select mm_pr">
                      <div className="modal_input">
                        <input
                          type="text"
                          className="form-control mt-0"
                          id="usr"
                          name="name"
                          value={this.state.name}
                          onChange={this.handleFieldChange}
                        />
                        <label className="move_label inactive">
                          Statement Balance
                        </label>
                      </div>
                    </div>
                  </div> */}

              <div className="col-md-3">
                <div className="form-group custon_select mm_pr">
                  <div className="modal_input">
                    {adjustmentBankBalance.map((item, i) => {
                      return (
                        <>
                          <input
                            type="number"
                            className="form-control mt-0"
                            readOnly={item.readOnly ? true : false}
                            id="usr"
                            name="adjustmentBankBalance"
                            value={item.value}
                            onChange={(e) => this.handleFieldChange(e, i)}
                          />
                          <label className="move_label inactive">
                            {item.name}
                          </label>
                        </>
                      );
                    })}
                  </div>

                  <div className="text-danger error-12">
                    {this.state.formErrors.taxID !== ""
                      ? this.state.formErrors.taxID
                      : ""}
                  </div>
                </div>

                <div className="form-group custon_select mm_pr">
                  <div className="modal_input">
                    {variance.map((item, i) => {
                      return (
                        <>
                          <input
                            type="number"
                            className="form-control mt-0"
                            id="usr"
                            name="variance"
                            value={item.value}
                            onChange={(e) => this.handleFieldChange(e, i)}
                            readOnly={item.readOnly ? true : false}
                          />
                          <label className="move_label inactive">
                            {item.name}
                          </label>
                        </>
                      );
                    })}
                  </div>
                  <div className="text-danger error-12">
                    {/* {this.state.formErrors.name !== ""
                                    ? this.state.formErrors.name
                                    : ""} */}
                  </div>
                </div>
                {period.map((item, i) => {
                  return (
                    <div className="form-group custon_select mm_pr pt-2">
                      <Select
                        className="width-selector"
                        key={i}
                        value={item.value}
                        onChange={(e) =>
                          this.handleSelectFields("list", e, item, i)
                        }
                        readOnly={item.readOnly ? true : false}
                        classNamePrefix="custon_select-selector-inner"
                        options={item.options}
                        theme={(theme) => ({
                          ...theme,
                          border: 0,
                          borderRadius: 0,
                          colors: {
                            ...theme.colors,
                            primary25: "#f2f2f2",
                            primary: "#f2f2f2",
                          },
                        })}
                      />
                      <label className="move_label inactive pt-2">
                        {item.name}
                      </label>
                      <div className="text-danger error-12">
                        {this.state.formErrors.currency !== ""
                          ? this.state.formErrors.currency
                          : ""}
                      </div>
                    </div>
                  );
                })}
              </div>

              {/* <div className="col-md-6">
                              <div className="form-group custon_select">
                                <div className="modal_input pt-2">
                                  <input
                                    type="text"
                                    className="form-control mt-0"
                                    id="usr"
                                    name="email"
                                    value={this.state.email}
                                    onChange={this.handleFieldChange}
                                  />
                                  <label className="move_label inactive pt-2">
                                    Email
                                  </label>
                                </div>
                                <div className="text-danger error-12">
                                  {this.state.formErrors.email !== ""
                                    ? this.state.formErrors.email
                                    : ""}
                                </div>
                              </div>
                            </div> */}

              {/* <div className="col-md-3 m-auto">
                    <div className="form-group custon_select">
                      <div className="modal_input pt-2">
                        <input
                          type="text"
                          className="form-control mt-0"
                          id="usr"
                          name="taxID"
                          value="1000$"
                          onChange={this.handleFieldChange}
                          disabled
                        />
                        <label className="move_label inactive pt-2">
                          Adj Bank Balance
                        </label>
                      </div>
                      <div className="text-danger error-12">
                        {this.state.formErrors.taxID !== ""
                          ? this.state.formErrors.taxID
                          : ""}
                      </div>
                    </div>
                  </div> */}

              {/* <div className="col-md-3">
                    <div className="form-group custon_select">
                      <div className="modal_input pt-2">
                        <input
                          type="text"
                          className="form-control mt-0"
                          id="usr"
                          name="name"
                          value="1099"
                          onChange={this.handleFieldChange}
                          disabled
                        />
                        <label className="move_label inactive pt-2">
                          Variance
                        </label>
                      </div>
                      <div className="text-danger error-12">
                        {/* {this.state.formErrors.name !== ""
                                    ? this.state.formErrors.name
                                    : ""} */}

              {/* <div className="col-md-3 m-auto">
                    <div className="form-group custon_select mm_pr pt-2">
                      <Select
                        className="width-selector"
                        value={this.state.currency}
                        onChange={(e) => this.handleSelectFields(e, "currency")}
                        classNamePrefix="custon_select-selector-inner"
                        options={this.state.currencyList}
                        theme={(theme) => ({
                          ...theme,
                          border: 0,
                          borderRadius: 0,
                          colors: {
                            ...theme.colors,
                            primary25: "#f2f2f2",
                            primary: "#f2f2f2",
                          },
                        })}
                      />
                      <label className="move_label inactive pt-2">Period</label>
                      <div className="text-danger error-12">
                        {this.state.formErrors.currency !== ""
                          ? this.state.formErrors.currency
                          : ""}
                      </div>
                    </div>
                  </div> */}
              <div className="col-12 border border-bottom mt-3"></div>
            </div>
          </div>
        </div>
        <Reports
          openAccountReportModal={this.state.openAccountReportModal}
          closeModal={this.closeModal}
          reportType="Bank Reconciliation"
          locationProps={this.props}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({ account: state.account });

export default connect(mapStateToProps, {
  getBankRecSummary: AccountActions.getBankRecSummary,
  getUpdateStatement: AccountActions.getUpdateStatement,
  getPostBank: AccountActions.getPostBank,
  getAdjustments: AccountActions.getAdjustments,
  getBankTransactions: AccountActions.getBankTransactions,
})(BankDetails);
