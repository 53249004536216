import React, { Component, useContext } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import $ from "jquery";
import { _customStyles } from "../../../Constants/Constants";
import TopNav from "../../Common/TopNav/TopNav";
// import { clearStatesAfterLogout } from "../../Actions/UserActions/UserActions";
import * as AccountActions from "../../../Actions/AccountActions/AccountActions";
import * as Validation from "../../../Utils/Validation";
import {
  tableSetting,
  toBase64,
  addDragAndDropFileListners,
  removeDragAndDropFileListners,
  handleAPIErr,
} from "../../../Utils/Helpers";
import BankDetails from "./BankDetails/BankDetails";
import BankCheques from "./BankCheques/BankCheques";
import BankAdjustments from "./BankAdjustments/BankAdjustments";
import BankJournals from "./BankJournals/BankJournals";
import BankTransaction from "./BankTransaction/BankTransaction";
import BankAttachments from "./BankAttachments/BankAttachments";
import UserReducer from "../../../Reducers/UserReducer/UserReducer";
// import "./PeriodClose.css";
class BankRec extends Component {
  constructor() {
    super();
    this.state = {
      isLoading: false,
      addNewReportToggle: false,
      reportTypesVal: { label: "", value: "" },
      reportTypes: [{ label: "", value: "" }],

      bankDetails: [],
      bank: [],
      statementBalance: [],
      bankBalance: [],
      adjustmentBankBalance: [],
      variance: [],
      period: [],

      cheques: [],
      bankChequeColumns: [],
      bankChequeRows: [],

      journals: [],
      journalsColumns: [],
      journalsRows: [],

      adjustments: [],
      adjusmentsColumns: [],
      adjusmentsRows: [],

      bankTransactionColumn: [],
      bankTransactionRows: [],
      tableId: "",
      transaction: [],
      totalPages: "",
      totalRecords: "",
      pageStart: "",
      pageEnd: "",
      display: localStorage.getItem("orderDPR") || 10,
      Page: 1,

      attachments: [],
      attachmentSize: 0,

      reportOptionVal: { label: "", value: "" },
      reportOptions: [],
      menuIsOpen: false,
      advancedList: [
        {
          category: "Bank",
          description: "Account",
          valueOptions: [
            { label: "List 1", value: "value" },
            { label: "List 2", value: "value" },
          ],

          valueType: "List",
          hide: false,
        },
        {
          category: "Bank",
          description: "Account",
          valueOptions: [
            { label: "List 1", value: "value" },
            { label: "List 2", value: "value" },
          ],

          valueType: "List",
          hide: false,
        },
        {
          category: "Bank",
          description: "Account",
          valueOptions: [
            { label: "List 1", value: "value" },
            { label: "List 2", value: "value" },
          ],

          valueType: "Text",
          hide: false,
        },
        {
          category: "Bank",
          description: "Account",
          valueOptions: [
            { label: "List 1", value: "value" },
            { label: "List 2", value: "value" },
          ],
          valueType: "Text",
          hide: false,
        },
      ],
      filtersArr: [
        { filter: "category", condition: "equal", value: "settings" },
        { filter: "description", condition: "equal", value: "abc" },
      ],
      fltrsColmnFirst: [
        { id: "1", filter: "category", name: "Category", checked: false },
        { id: "2", filter: "description", name: "Description", checked: false },
        { id: "3", filter: "value", name: "Value", checked: false },
      ],
      fltrsColmnSecond: [
        { id: "4", condition: "Contains", checked: false },
        { id: "5", condition: "Doesn't contain", checked: false },
        { id: "6", condition: "Equal", checked: false },
        { id: "7", condition: "Not Equal", checked: false },
        { id: "8", condition: "Starts With", checked: false },
        { id: "9", condition: "Over", checked: false },
        { id: "10", condition: "Under", checked: false },
        { id: "11", condition: "Over Equal", checked: false },
        { id: "12", condition: "Under Equal", checked: false },
      ],
      reportFile: "",
      reportName: "",
      private: false,

      formErrors: {
        reportTypesVal: "",
        reportOptionVal: "",
        reportFile: "",
        reportName: "",
      },
    };
  }

  async componentDidMount() {
    $(document).ready(function () {
      $(".sideBarAccord").click(function () {
        $(this).toggleClass("rorate_0");
      });
      $(".icon_dots").click(function () {
        $(".mm-blok1").toggleClass("doc_sidebar2");
      });
      $(".icon_dots2").click(function () {
        $(".mm-blok2").toggleClass("doc_sidebar2");
      });
      $(".icon_dots3").click(function () {
        $(".mm-blok3").toggleClass("doc_sidebar2");
      });
      $(".icon_dots4").click(function () {
        $(".mm-blok4").toggleClass("doc_sidebar2");
      });
      $(".icon_dots5").click(function () {
        $(".mm-blok5").toggleClass("doc_sidebar2");
      });

      $("#filter_dropdown").click(function () {
        $("#supplier_filter_dropdpwn1").toggle();
        $("#supplier_filter_dropdpwn2").hide();
      });
      $(".plus_icon-filter_bottom").click(function () {
        $("#supplier_filter_dropdpwn2").toggle();
      });
      $(".pop-cros-1").click(function () {
        $("#supplier_filter_dropdpwn1").hide();
        $("#supplier_filter_dropdpwn2").hide();
      });
      $(".close_it").click(function () {
        $("#supplier_filter_dropdpwn2").hide();
      });
    });

    await this.getBankRecSummary();
    /*await this.getCheques();
    await this.getJournals();
    await this.getAdjustments();
    await this.getBankTransactions();
    await this.getBankRecAttachments();*/
  }

  componentWillUnmount() {
    //removing drag and drop attachments listeners
    removeDragAndDropFileListners("drop-area-report", this.uploadReportFile);
  }

  pageReload = async () => {
    await this.getBankRecSummary();
    /*await this.getCheques();
    await this.getJournals();
    await this.getAdjustments();
    await this.getBankTransactions();
    await this.getBankRecAttachments();*/
  };

  //bankDetails
  getBankRecSummary = async () => {
    this.setState({ isLoading: true, attachments: [] });
    let bankDetails = [];
    let bank = [];

    let statementBalance = [];

    let bankBalance = [];

    let adjustmentBankBalance = [];

    let variance = [];

    let period = [];
    /*let {
      bank,
      statementBalance,
      bankBalance,
      adjustmentBankBalance,
      variance,
      period,
    } = this.state;*/
    let bankCode = "";
    if (
      document.getElementsByClassName(
        "custon_select-selector-inner__single-value css-1uccc91-singleValue"
      ).length > 0
    ) {
      bankCode = document.getElementsByClassName(
        "custon_select-selector-inner__single-value css-1uccc91-singleValue"
      )[0].innerText;
    } else {
      bankCode = "B1";
    }

    await this.props.getBankRecSummary(bankCode);
    if (this.props.account.getBankRecSummarySuccess) {
      // toast.success(this.props.account.getBankRecSummarySuccess);
      bankDetails = this?.props?.account.getBankRecSummary || [];
      bankDetails.map((lst, i) => {
        if ((lst.type && lst.type.toLowerCase() === "list") || "droplist") {
          let valOptns = [];
          if (lst.options && lst.options.length > 0) {
            lst.options.map((o, i) => {
              valOptns.push({ label: o.option, value: o.option });
            });
          }
          lst.options = valOptns;
        }
        return lst;
      });

      bank.push(bankDetails[0]);

      statementBalance.push(bankDetails[1]);

      bankBalance.push(bankDetails[2]);

      adjustmentBankBalance.push(bankDetails[3]);

      variance.push(bankDetails[4]);

      period.push(bankDetails[5]);
      this.setState({
        bankDetails,
        clonedBankDetails: bankDetails,
        bank,
        statementBalance,
        bankBalance,
        adjustmentBankBalance,
        variance,
        period,
      });
    }
    if (this.props.account.getBankRecSummaryError) {
      handleAPIErr(this.props.account.getBankRecSummaryError, this.props);
    }
    this.setState({ isLoading: false });
    this.setState(
      {
        bankDetails,
        clonedBankDetails: bankDetails,
        bank,
        statementBalance,
        bankBalance,
        adjustmentBankBalance,
        variance,
        period,
      },
      async () => {
        await this.getCheques();
        await this.getJournals();
        await this.getAdjustments();
        await this.getBankTransactions();
        await this.getBankRecAttachments();
      }
    );
  };
  //bankCheques
  getCheques = async () => {
    this.setState({ isLoading: true });
    let bankCode = document.getElementsByClassName(
      "custon_select-selector-inner__single-value css-1uccc91-singleValue"
    )[0]?.innerText;
    await this.props.getCheques(bankCode);
    if (this.props.account.getChequesSuccess) {
      // toast.success(this.props.account.getChequesSuccess);
      let cheques = this?.props?.account.getCheques || [];
      let bankChequeColumns = this?.props?.account.getCheques[0].Columns;
      let bankChequeRows = this?.props?.account.getCheques[0].Rows;
      this.setState(
        {
          bankChequeColumns,
          bankChequeRows,
          cheques,
        },
        () => {
          setTimeout(this.tableSettingCheque, 1000);
          // this.tableSettingCheque()
        }
      );
    }
    if (this.props.account.getChequesError) {
      handleAPIErr(this.props.account.getChequesError, this.props);
    }

    this.setState({ isLoading: false });
  };
  //main Cheque list table
  tableSettingCheque = () => {
    window.$("#chequesDetail").DataTable({
      //dom: "Rlfrtip",
      sDom: "ltipr",
      stateSave: true,
      language: {
        searchPlaceholder: "Search",
      },
      searching: true,
      paging: true,
      info: true,
      //ordering: false,
      columnDefs: [
        {
          targets: -1,
          orderable: false,
        },
      ],
      order: [[1, "asc"]],
      scrollX: true,
    });
  };
  //bankJournals
  getJournals = async () => {
    this.setState({ isLoading: true });
    let bankCode = document.getElementsByClassName(
      "custon_select-selector-inner__single-value css-1uccc91-singleValue"
    )[0]?.innerText;
    await this.props.getJournals(bankCode);
    if (this.props.account.getJournalsSuccess) {
      let journals = this?.props?.account.getJournals || [];
      let journalsColumns = this?.props?.account.getJournals[0].Columns;
      let journalsRows = this?.props?.account.getJournals[0].Rows;
      this.setState({ journalsRows, journalsColumns, journals }, () => {
        setTimeout(this.tableSettingJournals, 1000);
        //this.tableSettingJournals();
      });
    }
    if (this.props.account.getJournalsError) {
      handleAPIErr(this.props.account.getJournalsError, this.props);
    }
    this.setState({ isLoading: false });
  };

  //main Journals list table
  tableSettingJournals = () => {
    window.$("#journalsDetail").DataTable({
      //dom: "Rlfrtip",
      sDom: "ltipr",
      stateSave: true,
      language: {
        searchPlaceholder: "Search",
      },
      searching: true,
      paging: true,
      info: true,
      //ordering: false,
      columnDefs: [
        {
          targets: -1,
          orderable: false,
        },
      ],
      order: [[1, "asc"]],
      scrollX: true,
    });
  };

  //bankAdjustments
  getAdjustments = async () => {
    this.setState({ isLoading: true });
    let bankCode = document.getElementsByClassName(
      "custon_select-selector-inner__single-value css-1uccc91-singleValue"
    )[0]?.innerText;
    await this.props.getAdjustments(bankCode);
    if (this.props.account.getAdjustmentsSuccess) {
      // toast.success(this.props.account.getAdjustmentsSuccess);

      let adjustments = this?.props?.account.getAdjustments || [];
      let adjusmentsColumns = adjustments[0].Columns;
      let adjusmentsRows = adjustments[0].Rows;

      this.setState({ adjusmentsRows, adjusmentsColumns, adjustments }, () => {
        setTimeout(this.tableSettingAdjustments, 1000);
        //this.tableSettingAdjustments();
      });
    }
    if (this.props.account.getAdjustmentsError) {
      handleAPIErr(this.props.account.getAdjustmentsError, this.props);
    }
    this.setState({ isLoading: false });
  };
  //main Adjustments list table
  tableSettingAdjustments = () => {
    window.$("#adjustmentsDetail").DataTable({
      //dom: "Rlfrtip",
      sDom: "ltipr",
      stateSave: true,
      language: {
        searchPlaceholder: "Search",
      },
      searching: true,
      paging: true,
      info: true,
      //ordering: false,
      columnDefs: [
        {
          targets: -1,
          orderable: false,
        },
      ],
      order: [[1, "asc"]],
      //scrollX: true
    });
  };
  //bankTransactions
  getBankTransactions = async () => {
    this.setState({ isLoading: true });
    let { display, Page } = this.state;
    let bankCode = document.getElementsByClassName(
      "custon_select-selector-inner__single-value css-1uccc91-singleValue"
    )[0]?.innerText;
    let obj = { displayRecords: display, page: Page };
    await this.props.getBankTransactions(bankCode, obj);

    if (this.props.account.getBankTransactionsSuccess) {
      // toast.success(this.props.account.getBankTransactionsSuccess);
      let transaction = this?.props?.account.getBankTransactions || [];

      let tableId = transaction?.tableId;
      let bankTransactionColumn = transaction.transactions[0].Columns;
      let bankTransactionRows = transaction.transactions[0]?.Rows || [];
      let totalPages = transaction.totalPages;
      let totalRecords = transaction.totalRecords;
      let pageStart = transaction.pageStart || "";
      let pageEnd = transaction.pageEnd || "";

      bankTransactionRows.sort((a, b) => (a.data[0] < b.data[0] ? -1 : 1));

      this.setState(
        {
          bankTransactionColumn,
          bankTransactionRows,
          tableId,
          transaction,
          totalRecords,
          totalPages,
          pageStart,
          pageEnd,
        },
        () => {
          //setTimeout(this.tableSettingTransactions, 3000);
        }
      );
    }

    if (this.props.account.getBankTransactionsError) {
      handleAPIErr(this.props.account.getBankTransactionsError, this.props);
    }
    this.setState({ isLoading: false });
  };

  handleChangeSettings = (e, i) => {
    localStorage.setItem("orderDPR", e.target.value); // no of display order pages

    this.setState({ display: e.target.value });
  };

  handlePageClick = ({ selected }) => {
    this.setState(
      { Page: selected + 1, pageStart: "", pageEnd: "", totalRecords: "" },
      () => {
        this.getBankTransactions();
      }
    );
  };

  //main Transactions list table
  tableSettingTransactions = () => {
    window.$("#transactionsDetail").DataTable({
      //dom: "Rlfrtip",
      sDom: "ltipr",
      language: {
        searchPlaceholder: "Search",
      },
      searching: true,
      paging: true,
      info: true,
      //ordering: false,
      columnDefs: [
        {
          targets: -1,
          orderable: false,
        },
      ],
      order: [[1, "asc"]],
      //scrollX: true
    });
  };
  //bankAttachments
  getBankRecAttachments = async () => {
    this.setState({ isLoading: true });
    let bankCode = document.getElementsByClassName(
      "custon_select-selector-inner__single-value css-1uccc91-singleValue"
    )[0]?.innerText;
    await this.props.getBankRecAttachments(bankCode);
    if (this.props.account.getBankRecAttachmentsSuccess) {
      // toast.success(this.props.account.getBankRecAttachmentsSuccess);
      let attachments = this?.props?.account?.getBankRecAttachments || [];
      let attachmentSize = 0;
      attachments.map((a, i) => {
        attachmentSize += Number(a.fileSize) || 0;
      });
      this.setState({ attachments, attachmentSize });
    }
    if (this.props.account.getBankRecAttachmentsError) {
      handleAPIErr(this.props.account.getBankRecAttachmentsError, this.props);
    }
    this.setState({ isLoading: false });
  };
  menuOpened = async () => {
    this.setState({ menuIsOpen: true });
    let _this = this;
    $(document).ready(async function () {
      $('<i class="fa fa-trash report--del"></i>').appendTo(
        ".report_menu.custon_select-selector-inner__option"
      );
      $(".report_menu.custon_select-selector-inner__option i").on(
        "click",
        async function (e) {
          e.preventDefault();
          var p_id = $(this).parent().attr("id");
          let id_split = p_id && p_id.split("-"); //["react", "select", "3", "option", "1"]
          let id = id_split[id_split.length - 1];

          let { reportOptions } = _this.state;
          let rep_opt = reportOptions.length > 0 && reportOptions[id];
          //call delete report options API
          await _this.deleteReport(rep_opt.value, rep_opt.locked);
        }
      );
    });
  };

  menuClosed = () => {
    this.setState({ menuIsOpen: false });
  };
  addNewReport = async () => {
    await this.setState((prevState) => ({
      addNewReportToggle: !prevState.addNewReportToggle,
    }));

    if (this.state.addNewReportToggle) {
      //add new report case then report options set to default

      this.setState((prevState) => ({
        reportOptionVal: { label: "Select Report Options", value: "" },
        formErrors: {
          ...prevState.formErrors,
          reportOptionVal: "",
        },
      }));

      //adding drag and drop attachments listeners
      addDragAndDropFileListners("drop-area-report", this.uploadReportFile);
      //end
    } else {
      //clear states on file and report name
      this.setState({
        reportFile: "",
        reportName: "",
        private: false,

        formErrors: {
          reportFile: "",
          reportName: "",
        },
      });
    }
  };

  uploadAttachment = async (f) => {
    let { attachmentSize } = this.state;
    let type = f[0].type;
    let name = f[0].name;
    let file = f[0];
    let size = f[0].size;
    if (type == "application/pdf") {
      if (size <= 10485760) {
        //10MB = 10485760 Bytes
        if (attachmentSize < 30932992) {
          //30932992  -> 29.5 MB
          if (Number(size) + Number(attachmentSize) < 30932992) {
            const result = await toBase64(file).catch((e) => e);
            if (result instanceof Error) {
              toast.error(result.message);
              return;
            } else {
              await this.addAttachment(result, name);
            }
          } else {
            let remaining_KBs = (30932992 - attachmentSize) / 1024; //available space
            remaining_KBs = Number(remaining_KBs).toFixed(2);
            toast.error(
              `You can upload a file of size ${remaining_KBs}KB, Attachmnents limit 29.5MB.`
            );
          }
        } else {
          toast.error(
            "You can't add more attachments. Attachments limit 29.5MB! "
          );
        }
      } else {
        toast.error(
          "This file exceeds the 10MB limit. Please upload a smaller file."
        );
      }
    } else {
      toast.error("Please Select only Attachments of type: 'pdf'");
    }
  };

  handleCheckbox = async (e) => {
    this.setState({ private: e.target.checked });
  };

  handleFieldChange = (e) => {
    let { formErrors } = this.state;
    let { name, value } = e.target;
    formErrors = Validation.handleValidation(name, value, formErrors);
    this.setState({ [name]: value, formErrors });
  };

  uploadReportFile = async (f) => {
    let { formErrors } = this.state;
    let type = f[0].type;
    let file = f[0];
    let size = f[0].size;

    if (type == "mrt") {
      if (size <= 2000000) {
        const result = await toBase64(file).catch((e) => e);
        if (result instanceof Error) {
          toast.error(result.message);
          return;
        } else {
          formErrors = Validation.handleValidation(
            "reportFile",
            result,
            formErrors
          );
          this.setState({
            reportFile: result,
            formErrors,
          });
        }
      } else {
        toast.error("Maximum Image Size 2MB");
      }
    } else {
      toast.error("Please Select only Images of type: '.mrt'");
    }
  };

  deleteReport = async (reportID, locked) => {
    let reportType = this.state.reportTypesVal.value || "";
    if (locked != "Y") {
      if (reportType && reportID) {
        this.setState({ isLoading: true });
        await this.props.deleteReport(reportType, reportID);

        //success case of delete Report success
        if (this.props.report.deleteReportSuccess) {
          toast.success(this.props.report.deleteReportSuccess);
          //report options List
          if (
            this.props.report.deleteReport &&
            this.props.report.deleteReport.length > 0
          ) {
            let reportOptions = this.props.report.deleteReport;
            let reportOptionsArr = [];
            reportOptions.map((t, i) => {
              reportOptionsArr.push({
                label: t.layout,
                value: t.id,
                locked: t.locked,
              });
            });
            this.setState({
              reportOptions: reportOptionsArr,
            });
          }

          this.setState({
            addNewReportToggle: false,
            reportFile: "",
            reportName: "",
            private: false,
            reportOptionVal: { label: "Select Report Options", value: "" },
            formErrors: {
              reportFile: "",
              reportName: "",
            },
          });
        }
        //error case of Delete Report
        if (this.props.report.deleteReportError) {
          this.handleApiRespErr(this.props.report.deleteReportError);
        }

        this.setState({ isLoading: false });
      } else {
        toast.error("Report Type OR Report ID is Missing!");
      }
    } else {
      toast.error("You can't delete this Report!");
    }
  };

  onSave = async () => {
    let formErrors = this.state.formErrors;
    if (!this.state.reportTypesVal.value) {
      formErrors.reportTypesVal = "This Field is Required.";
    }
    if (this.state.addNewReportToggle) {
      //add new report case
      if (!this.state.reportName) {
        formErrors.reportName = "This Field is Required.";
      }
      if (!this.state.reportFile) {
        formErrors.reportFile = "MRT File is Required.";
      }
    } else {
      //use reports from the drop down list
      if (!this.state.reportOptionVal.value) {
        formErrors.reportOptionVal = "This Field is Required.";
      }
    }
    this.setState({
      formErrors: formErrors,
    });

    let check = false;

    if (this.state.addNewReportToggle) {
      if (!formErrors.reportFile && !formErrors.reportName) {
        check = true;
      }
    } else {
      if (!formErrors.reportOptionVal) {
        check = true;
      }
    }

    if (!formErrors.reportTypesVal && check) {
      let {
        reportTypesVal,
        reportOptionVal,
        reportFile,
        reportName,
        private: privateCheck,
      } = this.state;
      let data = "";
      if (reportOptionVal.value) {
        //get report data according to available report options
        data = {
          reportType: reportTypesVal.value,
          reportID: reportOptionVal.value,
        };
      } else {
        //create new reports and get data according to that report
        data = {
          reportType: reportTypesVal.value,
          reportFile,
          reportName,
          private: privateCheck,
        };
      }
      if (data) {
        this.setState({ isLoading: true });
        await this.props.getReportData(data);
        //success case of get Report Data
        if (this.props.report.getReportDataSuccess) {
          toast.success(this.props.report.getReportDataSuccess);
          let jsonData = this.props.report.getReportData.jsonData || "";
          let reportFile = this.props.report.getReportData.reportFile || "";
          let key = this.props.report.getReportData.key || "";

          if (jsonData && reportFile && key) {
            localStorage.setItem("reportFile", reportFile);
            localStorage.setItem("jsonData", jsonData);
            localStorage.setItem("key", key);
            var path =
              window.location.protocol +
              "//" +
              window.location.host +
              "/report-view";

            window.open(path, "_blank");
          }
        }
        //error case of get Report Data
        if (this.props.report.getReportDataError) {
          this.handleApiRespErr(this.props.report.getReportDataError);
        }

        this.setState({ isLoading: false });
      }
    }
  };

  openModal = async (name) => {
    if (
      name === "openChartOfAccountsModal" ||
      name == "openMultipleChangeModal"
    ) {
      if (this.state.checkedList.length > 0) {
        this.setState({ [name]: true });
      } else {
        toast.error("Select Chart Account First");
      }
    } else if (name === "openChartSortModal") {
      this.setState({ [name]: true }, () => {
        this.getChartSorts();
      });
    } else {
      this.setState({ [name]: true });
    }
  };
  render() {
    let {
      bankDetails,
      bank,
      statementBalance,
      bankBalance,
      adjustmentBankBalance,
      variance,
      period,

      cheques,
      bankChequeColumns,
      bankChequeRows,

      journals,
      journalsColumns,
      journalsRows,

      adjusmentsRows,
      adjusmentsColumns,
      adjustments,

      bankTransactionColumn,
      bankTransactionRows,
      tableId,
      transaction,
      totalRecords,
      totalPages,
      pageStart,
      pageEnd,

      attachments,
      attachmentSize,

      primeCreateJournalOptions,
    } = this.state;

    return (
      <>
        {this.state.isLoading ? <div className="se-pre-con"></div> : ""}

        <div className="user_setup_main">
          <header>
            <TopNav />

            <div className="user_setup_heading">
              <div className="header_menu">
                <Link to="/dashboard">
                  <img
                    src="images/dash-logo.png"
                    className="img-fluid"
                    alt="logo"
                  />
                </Link>
                <Link
                  className="setup_menu"
                  to="#"
                  data-target="#top_nav_toggle1"
                >
                  <img src="images/top-menu.png" className="" alt="top-menu" />
                </Link>
              </div>
              <h2 style={{ marginRight: "130px" }}>Bank Rec</h2>
              <span>
                <img
                  src="./images/user-setup/lock.png"
                  alt="lock"
                  className="img-fluid"
                />
              </span>
            </div>
            <div className="user_setup_headerbox">
              <div className="user_setup_play_div">
                <ul>
                  <li>
                    <p className="user_setup_play_video">Video</p>
                  </li>
                  <li>
                    <p className="user_setup_play_tuturial">Tutorials</p>
                  </li>
                </ul>
                <span className="user_setup_play_icon">
                  <img
                    src="./images/user-setup/play.png"
                    alt="play"
                    className="img-fluid"
                  />
                </span>
              </div>
              <div className="user_setup_header_rightbox">
                <p>
                  In our{" "}
                  <span>
                    <a href="#">Video</a>
                  </span>{" "}
                  learn how to use bank rec Read our{" "}
                  <span>
                    <a href="#">help article</a>
                  </span>{" "}
                  to learn More
                </p>
              </div>
              <span>
                <img
                  className="close_top_sec"
                  src="images/user-setup/cross.png"
                  alt="cross"
                ></img>
              </span>
            </div>
          </header>
          <div className="col-sm-12 table_white_box">
            <div className="col-md-8 col-sm-12 m-auto">
              {/* Bank Details */}
              {bankDetails && (
                <BankDetails
                  bankDetails={bankDetails}
                  pageReload={this.pageReload}
                  bank={bank}
                  statementBalance={statementBalance}
                  bankBalance={bankBalance}
                  adjustmentBankBalance={adjustmentBankBalance}
                  variance={variance}
                  period={period}
                />
              )}
              {/* Cheques */}

              {cheques && (
                <BankCheques
                  getCheques1={this.getCheques}
                  cheques={cheques}
                  bankChequeRows={bankChequeRows}
                  bankChequeColumns={bankChequeColumns}
                />
              )}

              {/* Journals */}
              {journals && (
                <BankJournals
                  journals={journals}
                  journalsColumns={journalsColumns}
                  journalsRows={journalsRows}
                />
              )}
              {/* Adjustments */}
              {adjustments && (
                <BankAdjustments
                  adjustments={this.state.adjustments}
                  adjusmentsColumns={this.state.adjusmentsColumns}
                  adjusmentsRows={this.state.adjusmentsRows}
                  getAdjustments1={this.getAdjustments}
                />
              )}

              {/* Bank Transactions */}
              {transaction && (
                <BankTransaction
                  bankTransactionColumn={bankTransactionColumn}
                  bankTransactionRows={bankTransactionRows}
                  tableId={tableId}
                  transaction={transaction}
                  totalPages={totalPages}
                  totalRecords={totalRecords}
                  pageStart={pageStart}
                  pageEnd={pageEnd}
                  getBankTransactions1={this.getBankTransactions}
                  handleChangeSettings={this.handleChangeSettings}
                  handlePageClick={this.handlePageClick}
                  display={this.state.display}
                />
              )}

              {/* Attachment */}
              {attachments && (
                <BankAttachments
                  attachments={attachments}
                  attachmentSize={attachmentSize}
                  getBankRecAttachments1={this.getBankRecAttachments}
                />
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  account: state.account,
});
export default connect(mapStateToProps, {
  getBankRecSummary: AccountActions.getBankRecSummary,
  getCheques: AccountActions.getCheques,
  getJournals: AccountActions.getJournals,
  getAdjustments: AccountActions.getAdjustments,
  getBankTransactions: AccountActions.getBankTransactions,
  getBankRecAttachments: AccountActions.getBankRecAttachments,
  getPrimeCreateJournal: AccountActions.getPrimeCreateJournal,
})(BankRec);
