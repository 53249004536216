import axiosDPFAPIClient from "../../Utils/axiosDPFAPIClient";

// ---------------------****Payments Actions****-----------------------------
//----------------------**** Get Payment Tallies *****-----------------------------
export const getPaymentTallies = () => async (dispatch) => {
  dispatch({
    type: "GET_PAYMENT_TALLIES_INIT",
  });

  const url = "/DPFAPI/PaymentRequest?actionType=GetPaymentTallies";

  try {
    let response = await axiosDPFAPIClient.get(url);
    let getPaymentTallies =
      (response && response.data && response.data.PaymentResponse) || "";
    if (getPaymentTallies && getPaymentTallies.result.length > 0) {
      if (
        getPaymentTallies.result[0] &&
        getPaymentTallies.result[0].status === "Failed"
      ) {
        dispatch({
          type: "GET_PAYMENT_TALLIES_FAIL",
          payload:
            getPaymentTallies.result[0].description ||
            "Error While Getting Payment Tallies.",
        });
      }
      if (
        getPaymentTallies.result[0] &&
        getPaymentTallies.result[0].status === "Success"
      ) {
        dispatch({
          type: "GET_PAYMENT_TALLIES_SUCCESS",
          payload: getPaymentTallies,
        });
      }
    } else {
      dispatch({
        type: "GET_PAYMENT_TALLIES_FAIL",
        payload: "Error While Getting Payment Tallies.",
      });
    }
  } catch (error) {
    dispatch({
      type: "GET_PAYMENT_TALLIES_FAIL",
      payload: error,
    });
  }
};

//----------------------****Get Payments List*****-------------------------------
export const getPaymentsList = (data) => async (dispatch) => {
  dispatch({
    type: "GET_PAYMENTS_LIST_INIT",
  });

  let type = data.type || "";
  const url = `/DPFAPI/PaymentRequest?actionType=GetPaymentsList&stageType=${type}`;

  try {
    let result = await axiosDPFAPIClient.get(url);

    let res = (result && result.data && result.data.PaymentResponse) || "";
    if (res && res.result.length > 0) {
      if (res.result[0] && res.result[0].status === "Success") {
        dispatch({
          type: "GET_PAYMENTS_LIST_SUCCESS",
          payload: res,
        });
      } else {
        dispatch({
          type: "GET_PAYMENTS_LIST_FAIL",
          payload:
            res.result[0].description || "Error While Getting Payments List.",
        });
      }
    } else {
      dispatch({
        type: "GET_PAYMENTS_LIST_FAIL",
        payload: "Error While Getting Payments List.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Getting Payments List.";
    dispatch({
      type: "GET_PAYMENTS_LIST_FAIL",
      payload: error,
    });
  }
};

export const getNewPaymentsList = (data) => async (dispatch) => {
  dispatch({
    type: "GET_NEW_PAYMENTS_LIST_INIT",
  });
  const url = "/DPFAPI/PaymentRequest";
  let obj = {
    actionType: "GetPaymentsList",
    ...data,
  };

  try {
    let response = await axiosDPFAPIClient.post(url, obj);

    let resp =
      (response && response.data && response.data.PaymentResponse) || "";
    if (resp && resp.result.length > 0) {
      if (resp.result[0] && resp.result[0].status === "Failed") {
        dispatch({
          type: "GET_NEW_PAYEMENT_LIST_FAIL",
          payload:
            resp.result[0].description || "Error While Getting Payment Summary",
        });
      }
      if ((resp.result[0] && resp.result[0].status === "Success") || 1) {
        dispatch({
          type: "GET_NEW_PAYMENT_LIST_SUCCESS",
          payload: resp,
        });
      }
    } else {
      dispatch({
        type: "GET_NEW_PAYEMENT_LIST_FAIL",
        payload: "Error While Getting payment Summary.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Getting payment Summary.";
    dispatch({
      type: "GET_NEW_PAYEMENT_LIST_FAIL",
      payload: error,
    });
  }
};
//----------------------****Get Payments Summary*****-------------------------------
export const getPaymentSummary = (data) => async (dispatch) => {
  dispatch({
    type: "GET_PAYMENT_SUMMARY_INIT",
  });

  let guid = data.guid || "";
  const url = '/DPFAPI/PaymentRequest';

  const payload = {
    actionType: 'GetPaymentSummary',
    guid
  }

  try {
    let result = await axiosDPFAPIClient.post(url, payload);
    let res = (result && result.data && result.data.PaymentResponse) || "";

    if (res.result[1] && res.result[1].status === "Success") {
      dispatch({
        type: "GET_PAYMENTS_SUMMARY_SUCCESS",
        payload: res,
      });
    } else if (res.result[0] && res.result[0].status === "Success") {
      dispatch({
        type: "GET_PAYMENTS_SUMMARY_SUCCESS",
        payload: res,
      });
    } else {
      dispatch({
        type: "GET_PAYMENTS_SUMMARY_FAIL",
        payload:
          res.result[0].description || "Error While Getting Payments Summary.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Getting Payments Summary.";
    dispatch({
      type: "GET_PAYMENTS_SUMMARY_FAIL",
      payload: error,
    });
  }
};
//----------------------****sendForApproval Payment*****------------------------------
export const sendForApprovalPayment = (guidList) => async (dispatch) => {
  dispatch({
    type: "SEND_FOR_APPROVAL_PAYMENT_INIT",
  });
  const url = "/DPFAPI/PaymentRequest";
  let data = {
    actionType: "SendForApproval",
    guidList,
  };

  try {
    let response = await axiosDPFAPIClient.post(url, data);
    let sendForApproval =
      (response && response.data && response.data.PaymentResponse) || "";
    if (sendForApproval && sendForApproval.result.length > 0) {
      if (
        sendForApproval.result[1] &&
        sendForApproval.result[1].status === "Failed"
      ) {
        dispatch({
          type: "SEND_FOR_APPROVAL_PAYMENT_FAIL",
          payload:
            sendForApproval.result[0].description ||
            "Error While Sending For Approval Payment.",
        });
      }
      if (
        sendForApproval.result[1] &&
        sendForApproval.result[1].status === "Success"
      ) {
        dispatch({
          type: "SEND_FOR_APPROVAL_PAYMENT_SUCCESS",
          payload: sendForApproval,
        });
      }
    } else {
      dispatch({
        type: "SEND_FOR_APPROVAL_PAYMENT_FAIL",
        payload: "Error While Sending For Approval Payment.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Sending For Approval Payment.";
    dispatch({
      type: "SEND_FOR_APPROVAL_PAYMENT_FAIL",
      payload: error,
    });
  }
};

//----------------------****Move Dist Change*****-----------------------------
export const movePayment = (guid) => async (dispatch) => {
  dispatch({
    type: "MOVE_PAYMENT_INIT",
  });
  const url = "/DPFAPI/PaymentRequest";
  let data = {
    actionType: "MovePayment",
    guid,
  };

  try {
    let response = await axiosDPFAPIClient.post(url, data);
    let resp =
      (response && response.data && response.data.PaymentResponse) || "";
    if (resp && resp.result.length > 0) {
      if (resp.result[0] && resp.result[0].status === "Failed") {
        dispatch({
          type: "MOVE_PAYMENT_FAIL",
          payload: resp.result[0].description || "Error While Moving Payment.",
        });
      }
      if (resp.result[0] && resp.result[0].status === "Success") {
        dispatch({
          type: "MOVE_PAYMENT_SUCCESS",
          payload: resp,
        });
      }
    } else {
      dispatch({
        type: "MOVE_PAYMENT_FAIL",
        payload: "Error While Moving Payment.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Moving Payment.";
    dispatch({
      type: "MOVE_PAYMENT_FAIL",
      payload: error,
    });
  }
};

//----------------------****Approve Dist Change*****-----------------------------
export const approvePayment = (guid) => async (dispatch) => {
  dispatch({
    type: "APPROVE_PAYMENT_INIT",
  });
  const url = "/DPFAPI/PaymentRequest";
  let data = {
    actionType: "ApprovePayment",
    guid,
  };

  try {
    let response = await axiosDPFAPIClient.post(url, data);
    let resp =
      (response && response.data && response.data.PaymentResponse) || "";
    if (resp && resp.result.length > 0) {
      if (resp.result[0] && resp.result[0].status === "Failed") {
        dispatch({
          type: "APPROVE_PAYMENT_FAIL",
          payload:
            resp.result[0].description || "Error While Approving a Payment.",
        });
      }
      if (resp.result[0] && resp.result[0].status === "Success") {
        dispatch({
          type: "APPROVE_PAYMENT_SUCCESS",
          payload: resp,
        });
      }
    } else {
      dispatch({
        type: "APPROVE_PAYMENT_FAIL",
        payload: "Error While Approving a Payment.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Approving a Payment.";
    dispatch({
      type: "APPROVE_PAYMENT_FAIL",
      payload: error,
    });
  }
};

//----------------------****Decline Payment*****-----------------------------
export const declinePayment = (guid) => async (dispatch) => {
  dispatch({
    type: "DECLINE_PAYMENT_INIT",
  });
  const url = "/DPFAPI/PaymentRequest";
  let data = {
    actionType: "DeclinePayment",
    guid,
  };

  try {
    let response = await axiosDPFAPIClient.post(url, data);
    let resp =
      (response && response.data && response.data.PaymentResponse) || "";
    if (resp && resp.result.length > 0) {
      if (resp.result[0] && resp.result[0].status === "Failed") {
        dispatch({
          type: "DECLINE_PAYMENT_FAIL",
          payload:
            resp.result[0].description || "Error While Declining a Payment.",
        });
      }
      if (resp.result[0] && resp.result[0].status === "Success") {
        dispatch({
          type: "DECLINE_PAYMENT_SUCCESS",
          payload: resp,
        });
      }
    } else {
      dispatch({
        type: "DECLINE_PAYMENT_FAIL",
        payload: "Error While Declining a Payment.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Declining a Payment.";
    dispatch({
      type: "DECLINE_PAYMENT_FAIL",
      payload: error,
    });
  }
};

//----------------------****Hold Payment*****--------------------------------
export const holdPayment = (guid) => async (dispatch) => {
  dispatch({
    type: "HOLD_PAYMENT_INIT",
  });
  const url = "/DPFAPI/PaymentRequest";
  let data = {
    actionType: "HoldPayment",
    guid,
  };

  try {
    let response = await axiosDPFAPIClient.post(url, data);
    let resp =
      (response && response.data && response.data.PaymentResponse) || "";
    if (resp && resp.result.length > 0) {
      if (resp.result[0] && resp.result[0].status === "Failed") {
        dispatch({
          type: "HOLD_PAYMENT_FAIL",
          payload:
            resp.result[0].description || "Error While holding a Payment.",
        });
      }
      if (resp.result[0] && resp.result[0].status === "Success") {
        dispatch({
          type: "HOLD_PAYMENT_SUCCESS",
          payload: resp,
        });
      }
    } else {
      dispatch({
        type: "HOLD_PAYMENT_FAIL",
        payload: "Error While holding a Payment.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While holding a Payment.";
    dispatch({
      type: "HOLD_PAYMENT_FAIL",
      payload: error,
    });
  }
};

//----------------------****Add Payment Comment*****-------------------------
export const addComment = (tran) => async (dispatch) => {
  dispatch({
    type: "ADD_PAYMENT_COMMENT_INIT",
  });
  const url = "/DPFAPI/PaymentRequest";
  let data = {
    actionType: "AddComment",
    ...tran,
  };

  try {
    let response = await axiosDPFAPIClient.post(url, data);
    let resp =
      (response && response.data && response.data.PaymentResponse) || "";
    if (resp && resp.result.length > 0) {
      if (resp.result[0] && resp.result[0].status === "Failed") {
        dispatch({
          type: "ADD_PAYMENT_COMMENT_FAIL",
          payload:
            resp.result[0].description || "Error While holding a Payment.",
        });
      }
      if (resp.result[0] && resp.result[0].status === "Success") {
        dispatch({
          type: "ADD_PAYMENT_COMMENT_SUCCESS",
          payload: resp,
        });
      }
    } else {
      dispatch({
        type: "ADD_PAYMENT_COMMENT_FAIL",
        payload: "Error While holding a Payment.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While holding a Payment.";
    dispatch({
      type: "ADD_PAYMENT_COMMENT_FAIL",
      payload: error,
    });
  }
};

//----------------------****Post Payment*****------------------------------
export const postPayment = (data) => async (dispatch) => {
  debugger;
  dispatch({
    type: "POST_PAYMENT_INIT",
  });
  const url = "/DPFAPI/PaymentRequest";
  let obj = {
    actionType: "PostPayment",
    ...data,
  };

  try {
    let response = await axiosDPFAPIClient.post(url, obj);
    let resp =
      (response && response.data && response.data.PaymentResponse) || "";
    if (resp && resp.result.length > 0) {
      if (resp.result[0] && resp.result[0].status === "Success") {
        dispatch({
          type: "POST_PAYMENT_SUCCESS",
          payload: resp,
        });
      } else {
        dispatch({
          type: "POST_PAYMENT_FAIL",
          payload: resp.result[0].description || "Error While Posting Payment.",
        });
      }
    } else {
      dispatch({
        type: "POST_PAYMENT_FAIL",
        payload: "Error While Posting Payment.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Posting Payment.";
    dispatch({
      type: "POST_PAYMENT_FAIL",
      payload: error,
    });
  }
};

//----------------------****Export DST*****----------------------------
export const exportPayment = (guidList) => async (dispatch) => {
  dispatch({
    type: "EXPORT_PAYMENT_INIT",
  });
  const url = "/DPFAPI/PaymentRequest";
  let data = {
    actionType: "ExportPayments",
    guidList,
  };

  try {
    let response = await axiosDPFAPIClient.post(url, data);
    let resp =
      (response && response.data && response.data.PaymentResponse) || "";
    if (resp && resp.result.length > 0) {
      if (resp.result[0] && resp.result[0].status === "Success") {
        dispatch({
          type: "EXPORT_PAYMENT_SUCCESS",
          payload: resp,
        });
      } else {
        dispatch({
          type: "EXPORT_PAYMENT_FAIL",
          payload:
            resp.result[0].description || "Error While Exporting Payment.",
        });
      }
    } else {
      dispatch({
        type: "EXPORT_PAYMENT_FAIL",
        payload: "Error While Exporting Payment.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Exporting Payment.";
    dispatch({
      type: "EXPORT_PAYMENT_FAIL",
      payload: error,
    });
  }
};

//----------------------****GetRemittanceSettings *****----------------------------
export const getRemittanceSettings = () => async (dispatch) => {
  dispatch({
    type: "GET_REMITTANCE_SETTING_INIT",
  });
  const url = "/DPFAPI/PaymentRequest";
  let data = {
    actionType: "GetRemittanceSettings",
  };

  try {
    let response = await axiosDPFAPIClient.post(url, data);
    let resp =
      (response && response.data && response.data.PaymentResponse) || "";
    if (resp && resp.result.length > 0) {
      if (resp.result[0] && resp.result[0].status === "Success") {
        dispatch({
          type: "GET_REMITTANCE_SETTING_SUCCESS",
          payload: resp,
        });
      } else {
        dispatch({
          type: "GET_REMITTANCE_SETTING_FAIL",
          payload:
            resp.result[0].description ||
            "Error in getRemittanceSettings Payment.",
        });
      }
    } else {
      dispatch({
        type: "GET_REMITTANCE_SETTING_FAIL",
        payload: "Error in getRemittanceSettings Payment.",
      });
    }
  } catch (err) {
    const error = err.message || "Error in getRemittanceSettings Payment.";
    dispatch({
      type: "GET_REMITTANCE_SETTING_FAIL",
      payload: error,
    });
  }
};

//----------------------****SaveAndSendRemittances *****----------------------------
export const saveAndSendRemittances = (tranList) => async (dispatch) => {
  dispatch({
    type: "SAVE_AND_SEND_REMITTANCES_INIT",
  });
  const url = "/DPFAPI/PaymentRequest";
  let data = {
    actionType: "SaveAndSendRemittances",
    ...tranList,
  };

  try {
    let response = await axiosDPFAPIClient.post(url, data);
    let resp =
      (response && response.data && response.data.PaymentResponse) || "";
    if (resp && resp.result.length > 0) {
      if (resp.result[0] && resp.result[0].status === "Success") {
        dispatch({
          type: "SAVE_AND_SEND_REMITTANCES_SUCCESS",
          payload: resp,
        });
      } else {
        dispatch({
          type: "SAVE_AND_SEND_REMITTANCES_FAIL",
          payload:
            resp.result[0].description ||
            "Error in saveAndSendRemittances Payment.",
        });
      }
    } else {
      dispatch({
        type: "SAVE_AND_SEND_REMITTANCES_FAIL",
        payload: "Error in saveAndSendRemittances Payment.",
      });
    }
  } catch (err) {
    const error = err.message || "Error in saveAndSendRemittances Payment.";
    dispatch({
      type: "SAVE_AND_SEND_REMITTANCES_FAIL",
      payload: error,
    });
  }
};

//----------------------****primeEFTFile *****----------------------------
export const primeEFTFile = () => async (dispatch) => {
  dispatch({
    type: "PRIME_EFT_FILE_INIT",
  });
  const url = "/DPFAPI/PaymentRequest";
  let data = {
    actionType: "PrimeEFTFile",
  };

  try {
    let response = await axiosDPFAPIClient.post(url, data);
    let resp =
      (response && response.data && response.data.PaymentResponse) || "";
    if (resp && resp.result.length > 0) {
      if (resp.result[0] && resp.result[0].status === "Success") {
        dispatch({
          type: "PRIME_EFT_FILE_SUCCESS",
          payload: resp,
        });
      } else {
        dispatch({
          type: "PRIME_EFT_FILE_FAIL",
          payload:
            resp.result[0].description || "Error in primeEFTFile Payment.",
        });
      }
    } else {
      dispatch({
        type: "PRIME_EFT_FILE_FAIL",
        payload: "Error in primeEFTFile Payment.",
      });
    }
  } catch (err) {
    const error = err.message || "Error in primeEFTFile Payment.";
    dispatch({
      type: "PRIME_EFT_FILE_FAIL",
      payload: error,
    });
  }
};

//----------------------****primePositivePay *****----------------------------
export const primePositivePay = () => async (dispatch) => {
  dispatch({
    type: "PRIME_POSITIVE_PAY_INIT",
  });
  const url = "/DPFAPI/PaymentRequest";
  let data = {
    actionType: "PrimePositivePay",
  };

  try {
    let response = await axiosDPFAPIClient.post(url, data);
    let resp =
      (response && response.data && response.data.PaymentResponse) || "";
    if (resp && resp.result.length > 0) {
      if (resp.result[0] && resp.result[0].status === "Success") {
        dispatch({
          type: "PRIME_POSITIVE_PAY_SUCCESS",
          payload: resp,
        });
      } else {
        dispatch({
          type: "PRIME_POSITIVE_PAY_FAIL",
          payload:
            resp.result[0].description || "Error in PrimePositivePay Payment.",
        });
      }
    } else {
      dispatch({
        type: "PRIME_POSITIVE_PAY_FAIL",
        payload: "Error in PrimePositivePay Payment.",
      });
    }
  } catch (err) {
    const error = err.message || "Error in PrimePositivePay Payment.";
    dispatch({
      type: "PRIME_POSITIVE_PAY_FAIL",
      payload: error,
    });
  }
};

//----------------------****primePrintCheque *****----------------------------
export const primePrintCheque = (guidList) => async (dispatch) => {
  dispatch({
    type: "PRIME_PRINT_CHEQUE_INIT",
  });
  const url = "/DPFAPI/PaymentRequest";
  let data = {
    actionType: "PrimePrintCheque",
    guidList: guidList,
  };

  try {
    let response = await axiosDPFAPIClient.post(url, data);
    let resp =
      (response && response.data && response.data.PaymentResponse) || "";
    if (resp && resp.result.length > 0) {
      if (resp.result[0] && resp.result[0].status === "Success") {
        dispatch({
          type: "PRIME_PRINT_CHEQUE_SUCCESS",
          payload: resp,
        });
      } else {
        dispatch({
          type: "PRIME_PRINT_CHEQUE_FAIL",
          payload:
            resp.result[0].description || "Error in PrimePositivePay Payment.",
        });
      }
    } else {
      dispatch({
        type: "PRIME_PRINT_CHEQUE_FAIL",
        payload: "Error in PrimePositivePay Payment.",
      });
    }
  } catch (err) {
    const error = err.message || "Error in PrimePositivePay Payment.";
    dispatch({
      type: "PRIME_PRINT_CHEQUE_FAIL",
      payload: error,
    });
  }
};

//----------------------****PrintCheque *****----------------------------
export const printCheque = (printChequeData) => async (dispatch) => {
  dispatch({
    type: "PRINT_CHEQUE_INIT",
  });
  const url = "/DPFAPI/PaymentRequest";
  let data = {
    actionType: "PrintCheque",
    ...printChequeData,
  };

  try {
    let response = await axiosDPFAPIClient.post(url, data);
    let resp =
      (response && response.data && response.data.PaymentResponse) || "";
    if (resp && resp.result.length > 0) {
      if (resp.result[0] && resp.result[0].status === "Success") {
        dispatch({
          type: "PRINT_CHEQUE_SUCCESS",
          payload: resp,
        });
      } else {
        dispatch({
          type: "PRINT_CHEQUE_FAIL",
          payload:
            resp.result[0].description || "Error in primeEFTFile Payment.",
        });
      }
    } else {
      dispatch({
        type: "PRINT_CHEQUE_FAIL",
        payload: "Error in primeEFTFile Payment.",
      });
    }
  } catch (err) {
    const error = err.message || "Error in print cheque Payment.";
    dispatch({
      type: "PRINT_CHEQUE_FAIL",
      payload: error,
    });
  }
};

//----------------------****Get Payments*****-------------------------------
export const getPayments =
  ({ currency, supplierCode }) =>
    async (dispatch) => {
      dispatch({
        type: "GET_PAYMENTS_INIT",
      });
      const url = `/DPFAPI/PaymentRequest?actionType=GetPayments&currency=${currency}&supplierCode=${supplierCode}`;

      try {
        let result = await axiosDPFAPIClient.get(url);
        let res = (result && result.data && result.data.PaymentResponse) || "";
        if (res && res.result.length > 0) {
          if (res.result[0] && res.result[0].status === "Success") {
            dispatch({
              type: "GET_PAYMENTS_SUCCESS",
              payload: res,
            });
          } else {
            dispatch({
              type: "GET_PAYMENTS_FAIL",
              payload:
                res.result[0].description || "Error While Getting Payments.",
            });
          }
        } else {
          dispatch({
            type: "GET_PAYMENTS_FAIL",
            payload: "Error While Getting Payments.",
          });
        }
      } catch (err) {
        const error = err.message || "Error While Getting Payments.";
        dispatch({
          type: "GET_PAYMENTS_FAIL",
          payload: error,
        });
      }
    };
//----------------------****Get Payments Detail*****------------------------
export const getPaymentDetails = (chequeIDs) => async (dispatch) => {
  dispatch({
    type: "GET_PAYMENT_DETAILS_INIT",
  });
  const url = `/DPFAPI/PaymentRequest?actionType=GetPaymentDetails&chequeID=[${chequeIDs}]`;

  try {
    let result = await axiosDPFAPIClient.get(url);
    let res = (result && result.data && result.data.PaymentResponse) || "";
    if (res && res.result.length > 0) {
      if (res.result[0] && res.result[0].status === "Success") {
        dispatch({
          type: "GET_PAYMENT_DETAILS_SUCCESS",
          payload: res,
        });
      } else {
        dispatch({
          type: "GET_PAYMENT_DETAILS_FAIL",
          payload:
            res.result[0].description || "Error While Getting Payment Details.",
        });
      }
    } else {
      dispatch({
        type: "GET_PAYMENT_DETAILS_FAIL",
        payload: "Error While Getting Payment Details.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Getting Payment Details.";
    dispatch({
      type: "GET_PAYMENT_DETAILS_FAIL",
      payload: error,
    });
  }
};

//----------------------****Update Approval Group*****--------------------------

export const updateApprovalGroup = (data) => async (dispatch) => {
  dispatch({
    type: "UPDATE_APPROVAL_GROUP_INIT",
  });
  const url = "/DPFAPI/PaymentRequest";
  let obj = {
    actionType: "UpdateApprovalGroup",
    ...data,
  };

  try {
    let result = await axiosDPFAPIClient.post(url, obj);
    let resp = (result && result.data && result.data.PaymentResponse) || "";

    if (resp && resp.result.length > 0) {
      if (resp.result[0] && resp.result[0].status === "Success") {
        dispatch({
          type: "UPDATE_APPROVAL_GROUP_SUCCESS",
          payload: resp,
        });
      } else {
        dispatch({
          type: "UPDATE_APPROVAL_GROUP_FAIL",
          payload:
            resp.result[0].description || "Error While Update Approval Group.",
        });
      }
    } else {
      dispatch({
        type: "UPDATE_APPROVAL_GROUP_FAIL",
        payload: "Error While Update Approval Group.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Update Approval Group.";
    dispatch({
      type: "UPDATE_APPROVAL_GROUP_FAIL",
      payload: error,
    });
  }
};

//----------------------****Export Payments*****-----------------------------
export const exportPayments = (chequeIDs) => async (dispatch) => {
  dispatch({
    type: "EXPORT_PAYMENTS_INIT",
  });
  const url = "/DPFAPI/PaymentRequest";

  let obj = {
    actionType: "ExportPayments",
    chequeIDs,
  };
  try {
    let result = await axiosDPFAPIClient.post(url, obj);
    let res = (result && result.data && result.data.PaymentResponse) || "";
    if (res && res.result.length > 0) {
      if (res.result[0] && res.result[0].status === "Success") {
        dispatch({
          type: "EXPORT_PAYMENTS_SUCCESS",
          payload: res,
        });
      } else {
        dispatch({
          type: "EXPORT_PAYMENTS_FAIL",
          payload:
            res.result[0].description || "Error While Exporting Payments.",
        });
      }
    } else {
      dispatch({
        type: "EXPORT_PAYMENTS_FAIL",
        payload: "Error While Exporting Payments.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Exporting Payments.";
    dispatch({
      type: "EXPORT_PAYMENTS_FAIL",
      payload: error,
    });
  }
};
//----------------------****Clear Payment States In Store****-----------------
export function clearPaymentStates() {
  return async (dispatch) => {
    dispatch({
      type: "CLEAR_PAYMENT_STATES",
    });
  };
}
