import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
// import "./ChartOfAccounts.css";
import $ from "jquery";
import Dropdown from "react-bootstrap/Dropdown";
import TopNav from "../../Common/TopNav/TopNav";
import { toast } from "react-toastify";
import Filter from "../../Setup/Filter/Filter";
import Attachments from "../../Modals/Accounts/Attachments/Attachments";
import Reports from "../../Modals/Accounts/Reports/Reports";

import Settings from "../../Modals/SetupModals/Settings/Settings";
import * as ChartActions from "../../../Actions/ChartActions/ChartActions";

import * as AccountActions from "../../../Actions/AccountActions/AccountActions";
import {
  tableSetting,
  handleSaveSettings,
  handleCloseSettingModal,
  handleAPIErr,
  filterBox,
  downloadAttachments,
} from "../../../Utils/Helpers";
import * as Validation from "../../../Utils/Validation";
import _ from "lodash";
import EditHistory from "../../Modals/Accounts/EditHistory/EditHistory";
import ExportAccount from "../../Modals/Accounts/ExportAccount/ExportAccount";
import DistributionChange from "../../Modals/Accounts/DistributionChange/DistributionChange";
import Import from "../../Modals/Accounts/Import/Import";
import "./GeneralLedger.css";
import { getAccountAttachment } from "../../../Actions/AccountActions/AccountActions";
const uuidv1 = require("uuid/v1");
class GeneralLedger extends Component {
  constructor() {
    super();
    this.state = {
      attachments: [],
      attachId: 99,
      attachmentSize: "",
      id: 2,
      tran: "",
      openSettingsModal: false,
      openMultipleChangeModal: false,
      openChartOfAccountsModal: false,
      openInsertChartAccountModal: false,
      openChartSortModal: false,
      openChartCodeModal: false,
      openAccountAttachmentsModal: false,
      openAccountReportModal: false,
      openEditHistoryModal: false,
      openExportAccountModal: false,
      openDistributionChangeModal: false,
      openImportModal: false,
      getLedgersList: [],
      getLedgersRrow: [],
      getLedgersColmun: [],
      ledgerHistoryColumns: [],
      ledgerHistoryRows: [],
      refId: [],
      newRefId: "",

      importOptions: [],
      columns: [],
      pageLength: 10,
      chartAccountID: "",
      accountSortCode: "",
      chartSort: "",
      chartCode: "",
      description: "",
      post: "N",
      level: "",
      secLevel: "",
      format: "",
      type: "",
      active: "N",
      initBalance: 0.0,
      tab: "draft",
      extChart: "",
      user: "",
      excelAreas: [],
      excelOptions: [],
      clonnedExcelOptions: [],
      formErrors: {
        description: "",
        chartSort: "",
        chartCode: "",
        accountSortCode: "",
        chartSort_CA: "",
      },
      chartCodesList: [],
      showSuggestion: false,
      clonedChartCodesList: [],
      addEditCheck: "",
      checkedList: [],
      checkAll: false,

      chartSort_CA: "",
      includeEFC: "N",
      includeBudget: "N",
      includeFringe: "N",
      overwriteDescription: "N",

      mc_level: "",
      mc_level_flag: "N",
      mc_type: "",
      mc_type_flag: "N",
      mc_post: "",
      mc_post_flag: "N",
      mc_active: "",
      mc_active_flag: "N",
      mc_seclevel: "",
      mc_seclevel_flag: "N",
      mc_fringe: "",
      mc_fringe_flag: "N",
      mc_desc: "",
      mc_desc_flag: "N",

      excludeReconcile: 0,
      excludeZero: 0,
    };
  }

  async componentDidMount() {
    filterBox("generalledger");
    this.getLedgers();
  }

  reloadLedgers = async (e, id) => {
    let table = window.$("#generalledger").DataTable();
    table.destroy();
    if (e.target.checked) {
      this.setState({ [id]: 1 }, () => {
        this.getLedgers();
      });
    } else {
      this.setState({ [id]: 0 }, () => {
        this.getLedgers();
      });
    }
  };

  getLedgers = async () => {
    this.setState({ isLoading: true });
    let getLedgersList = [];
    let getLedgersColmun = [];
    let getLedgersRrow = [];
    let data = {
      excludeReconcile: this.state.excludeReconcile,
      excludeZero: this.state.excludeZero,
    };
    await this.props.getLedgers(data);

    if (this.props.account.getLedgersSuccess) {
      getLedgersList = this.props.account.getLedgers || [];
      getLedgersColmun = this.props.account.getLedgers[0].Columns || [];

      getLedgersRrow = this.props.account.getLedgers[0].Rows || [];
      getLedgersList.map((lst) => (lst.checked = false));
      let columns = [];
      //adding the column names
      columns[0] = { name: "ID" };
      columns[1] = { name: "Cu" };
      columns[2] = { name: "Lo" };
      columns[3] = { name: "Eps" };
      columns[4] = { name: "Prod" };
      columns[5] = { name: "Detl" };
      columns[6] = { name: "Set" };
      columns[7] = { name: "IN" };
      columns[8] = { name: "F" };
      columns[9] = { name: "TX" };
      columns[10] = { name: "Description" };
      columns[11] = { name: "Date" };
      columns[12] = { name: "Voucher" };
      columns[13] = { name: "Vendor" };
      columns[14] = { name: "Invoice" };
      columns[15] = { name: "PO" };
      columns[16] = { name: "CD" };
      columns[17] = { name: "EMPRef" };
      columns[18] = { name: "Amount" };
      columns[19] = { name: "Period" };
      columns[20] = { name: "Cheque" };
      columns[21] = { name: "Batch" };
      columns[22] = { name: "Tran" };
      columns[23] = { name: "Line" };
      columns[24] = { name: "Asset Disposal" };
      columns[25] = { name: "Sale Amount" };
      columns[26] = { name: "Exp" };
      columns[27] = { name: "Mod" };
      columns[28] = { name: "Vat" };
      this.setState(
        {
          getLedgersList,
          getLedgersColmun,
          isLoading: false,
          getLedgersRrow,
          columns,
        },
        () => {
          this.tableSetting();
        }
      );
    }

    if (this.props.account.getLedgersError) {
      handleAPIErr(this.props.account.getLedgersError, this.props);
    }
  };
  async;

  getChartSorts = async () => {
    if (!this.props.chart.getChartSorts) {
      this.setState({ isLoading: true });
      await this.props.getChartSorts();
      //success case of Get Chart Sorts
      if (this.props.chart.getChartSortsSuccess) {
        // toast.success(this.props.chart.getChartSortsSuccess);
      }
      //error case of Get Chart Sorts
      if (this.props.chart.getChartSortsError) {
        handleAPIErr(this.props.chart.getChartSortsError, this.props);
      }
      //   this.props.clearChartStates();
      this.setState({ isLoading: false });
    }
  };
  getUpdatedChartSort = (chartSort) => {
    this.setState({ chartSort });
  };

  getExportLedger = async () => {
    this.setState({ isLoading: true });
    let data = {
      guidList: this.state.newRefId,
    };
    await this.props.getExportLedger(data);
    if (this.props.account.getExportLedgerSuccess) {
      toast.success(this.props.account.getExportLedgerSuccess);
      let excelData = this.props.account.getExportLedger || "";

      let obj = {
        contentType: "application/vnd.ms-excel",
        attachment: excelData || "",
      };
      downloadAttachments(obj, "LedgerExport");
    }
    if (this.props.account.getExportLedgerError) {
      handleAPIErr(this.props.account.getExportLedgerError, this.props);
    }

    this.setState({ isLoading: false });
  };
  getReconcile = async () => {
    this.setState({ isLoading: true });
    let data = {
      guidList: this.state.newRefId,
    };
    await this.props.getReconcile(data);
    if (this.props.account.getReconcileSuccess) {
      toast.success(this.props.account.getReconcileSuccess);
    }
    if (this.props.account.getReconcileError) {
      handleAPIErr(this.props.account.getReconcileError, this.props);
    }

    this.setState({ isLoading: false });
  };

  getAdvancedExcelExportOptions = async () => {
    this.setState({ isLoading: true });
    let getLedgersRrow = this.state.getLedgersRrow || [];
    let check = getLedgersRrow.find((item) => item.checked);
    if (check) {
      await this.props.getAdvancedExcelExportOptions();
    }

    if (this.props.account.getAdvancedExcelExportSuccess) {
      toast.success(this.props.account.getAdvancedExcelExportSuccess);

      let advancedExportExcel = this.props.account.getAdvancedExcelExport || [];
      let excelAreasData = advancedExportExcel.excelAreas || [];
      let excelOptions = advancedExportExcel.excelOptions || [];

      let { excelAreas } = this.state;
      excelAreasData.map((opt) => {
        excelAreas.push({
          label: opt.option,
          value: opt.option,
        });
      });

      excelOptions.map((lst, i) => {
        if (
          (lst.valueType && lst.valueType.toLowerCase() === "list") ||
          "droplist"
        ) {
          let valOptns = [];
          if (lst.options && lst.options.length > 0) {
            lst.options.map((o, i) => {
              valOptns.push({ label: o.value, value: o.value });
            });
          }
          lst.options = valOptns;
        }
        lst.id = uuidv1();
        lst.hide = false;
        return lst;
      });

      this.setState({
        excelAreas,
        excelOptions,
        clonnedExcelOptions: excelOptions,
      });
    }

    if (this.props.account.getAdvancedExcelExportError) {
      handleAPIErr(this.props.account.getAdvancedExcelExportError, this.props);
    }

    this.openModal("openExportAccountModal");
    this.setState({ isLoading: false });
  };
  primeAccount = async () => {
    this.setState({ isLoading: true });
    await this.props.primeAccount();
    if (this.props.chart.primeAccountSuccess) {
      let primeAccount =
        JSON.parse(JSON.stringify(this.props.chart.primeAccount)) || "";
      let chartAccountID = primeAccount.chartAccountID || "";
      let description = primeAccount.description || "";
      let accountSortCode = primeAccount.accountSortCode || "";
      let post = primeAccount.post || "N";
      let level = primeAccount.level || "";
      let secLevel = primeAccount.secLevel || "";
      let format = primeAccount.format || "";
      let type = primeAccount.type || "";
      let active = primeAccount.active || "N";
      let initBalance = primeAccount.initBalance || 0.0;
      let extChart = primeAccount.extChart || "";
      let user = primeAccount.user || "";
      this.setState(
        {
          chartAccountID,
          accountSortCode,
          description,
          post,
          level,
          secLevel,
          format,
          type,
          active,
          initBalance,
          extChart,
          user,
          addEditCheck: "add",
        },
        () => {
          this.openModal("InsertChartModal");
        }
      );
    }
    if (this.props.chart.primeAccountError) {
      handleAPIErr(this.props.chart.primeAccountError, this.props);
    }
    // this.props.clearChartStates();
    this.setState({ isLoading: false });
  };
  addAccount = async () => {
    let {
      chartAccountID,
      accountSortCode,
      chartSort,
      chartCode,
      description,
      post,
      level,
      secLevel,
      format,
      type,
      active,
      initBalance,
      extChart,
      user,
      formErrors,
    } = this.state;

    // var value=chartSort.concat("."+chartCode);
    let data = {
      chartAccount: {
        chartAccountID,
        accountSortCode,
        description,
        active,
        level,
        secLevel,
        format,
        type,
        post,
        initBalance,
        extChart,
        user,
      },
    };
    formErrors = Validation.handleWholeValidation(
      { description, accountSortCode },
      formErrors
    );
    // this.setState({formErrors})
    if (!formErrors.description && !formErrors.accountSortCode) {
      this.setState({ isLoading: true });
      await this.props.addAccount(data);
      if (this.props.chart.addAccountSuccess) {
        // toast.success("Chart Account Inserted Successfully");
        this.closeModal("InsertChartModal");
        window.location.reload();
      }
      if (this.props.chart.addAccountError) {
        handleAPIErr(this.props.chart.addAccountError, this.props);
      }
      //   this.props.clearChartStates();
    }
    this.setState({ isLoading: false, formErrors });
  };
  handleAddEdit = () => {
    let { addEditCheck } = this.state;
    if (addEditCheck === "add") {
      this.addAccount();
    } else if (addEditCheck === "update") {
      this.updateAccount();
    }
  };
  openModal = async (name) => {
    let getLedgersRrow = this.state.getLedgersRrow || [];
    let check = getLedgersRrow.find((item) => item.checked);

    if (name == "openImportModal") {
      this.setState({ [name]: true }, () => {
        this.primeImportLedger();
      });
    } else if (name === "openEditHistoryModal") {
      this.setState({ [name]: true }, () => {
        this.getLedgerHistory();
      });
    } else if (name == "openDistributionChangeModal") {
      if (check) {
        this.setState({ [name]: true }, async () => {
          let promises = [];
          promises.push(this.getChartSorts());
          promises.push(this.getChartCodes());
          await Promise.all(promises);
        });
      } else {
        toast.error("Please tick lines for Distribution Change!");
      }
    } else if (name == "openExportAccountModal") {
      if (check) {
        this.setState({ [name]: true });
      } else {
        toast.error("Please tick lines for Distribution Change!");
      }
    } else {
      this.setState({ [name]: true });
    }
  };
  closeModal = (name) => {
    this.setState({ [name]: false });
    // if (
    //   name === "InsertChartModal" ||
    //   name === "openChartOfAccountsModal" ||
    //   name === "openImportAccountModal" ||
    //   name === "openMultipleChangeModal"
    // ) {
    this.clearStates();
    // }
  };
  tableSetting = () => {
    let { columns } = this.state;
    let aoColumns = [];
    //adding the column names
    aoColumns[0] = { sName: "checkbox" };
    columns.map((c) => aoColumns.push({ sName: c.name }));
    aoColumns[columns.length + 1] = { sName: "menus" };

    let result = tableSetting(columns, aoColumns, "generalledger");
    this.setState({ ...result });
  };
  handleChangeSettings = (e, i) => {
    const { name, value } = e.target;
    if (name === "pageLength") {
      this.setState({ pageLength: value });
    } else {
      let { columns } = this.state;
      columns[i].hide = e.target.checked;
      this.setState({ columns });
    }
  };
  handleSaveSettings = () => {
    let { columns, pageLength } = this.state;
    handleSaveSettings(columns, "generalledger", pageLength);
    this.closeModal("openSettingsModal");
  };
  handleCloseSettingModal = () => {
    let { columns } = this.state;
    let result = handleCloseSettingModal(columns, "generalledger");
    this.setState({ ...result }, () => {
      this.closeModal("openSettingsModal");
    });
  };

  handleCheckBox = (e) => {
    let { name, value } = e.target;
    if (value === "Y") {
      this.setState({ [name]: "N" });
    } else {
      this.setState({ [name]: "Y" });
    }
  };
  deleteChartAccount = async () => {
    let { chartAccountID, accList, checkedList } = this.state;
    let filtersList = [];
    let chartAccountIDs = _.map(checkedList, "chartAccountID"); //-> [1,2,3, ...]
    if (checkedList.length > 0) {
      this.setState({ isLoading: true });
      await this.props.deleteChartAccount(chartAccountIDs);

      if (this.props.chart.deleteChartAccountSuccess) {
        toast.success("Chart Account Deleted Successfully");
        let table = window.$("#generalledger").DataTable();
        table.colReorder.reset();
        table.destroy();

        filtersList = _.pullAllBy(accList, checkedList, "chartAccountID");
        accList = filtersList;
        this.setState(
          {
            accList,
            chartAccountID: "",
          },
          () => {
            //update datatable cache after updating accounts state
            // this.tableSetting();
          }
        );
      }
      if (this.props.chart.deleteChartAccountError) {
        handleAPIErr(this.props.chart.deleteChartAccountError, this.props);
      }
      //   this.props.clearChartStates();
      this.setState({ isLoading: false, checkedList: [] });
    } else {
      toast.error("Please Select Chart Account First!");
    }
  };
  handleChangeField = (e) => {
    const { name, value } = e.target;
    let { formErrors, showSuggestion } = this.state;
    formErrors = Validation.handleValidation(name, value, formErrors);
    this.setState({ [name]: value, formErrors });
  };
  getChartCodes = async () => {
    this.setState({ isLoading: true });
    await this.props.getChartCodes();
    //success case of Get Chart Codes
    if (this.props.chart.getChartCodesSuccess) {
      // toast.success(this.props.chart.getChartCodesSuccess);
      let getChartCodes = this.props.chart.getChartCodes || "";
      this.setState({
        chartCodesList: getChartCodes.chartCodes || [],
        clonedChartCodesList: getChartCodes.chartCodes || [],
      });
    }

    //success case of Get Chart Codes
    if (this.props.chart.getChartCodesSuccess) {
      // toast.success(this.props.chart.getChartCodesSuccess);
    }
    //error case of Get Chart Codes
    if (this.props.chart.getChartCodesError) {
      handleAPIErr(this.props.chart.getChartCodesError, this.props);
    }
    // this.props.clearChartStates();
    this.setState({ isLoading: false });
  };
  //get new chart code value through chart code modal
  getUpdatedChartCode = (chartCode) => {
    this.setState({ chartCode });
  };
  handleCheckboxes = (e, data) => {
    let { refId, getLedgersRrow, newRefId } = this.state;

    // let bankChequeRows = JSON.parse(JSON.stringify(this.state.bankChequeRows));
    let { name, checked } = e.target;
    if (data === "all") {
      let multipleTransCopy = [];
      if (checked) {
        getLedgersRrow.map((m) => {
          m.checked = true;
          multipleTransCopy.push(m.refId);
          return m;
        });
      } else {
        getLedgersRrow.map((m) => {
          m.checked = false;
          return m;
        });
      }
      refId = [...multipleTransCopy];
    } else {
      if (checked) {
        getLedgersRrow.map(async (po, i) => {
          if (data.refId === po.refId) {
            po.checked = true;
          }
          return po;
        });
        refId.push(data.refId);
      } else {
        getLedgersRrow.map(async (po, i) => {
          if (data.refId === po.refId) {
            po.checked = false;
          }
          return po;
        });
        let filteredMultiRefId = refId.filter((t) => t != data.refId);
        refId = filteredMultiRefId;
      }
    }
    newRefId = refId + [];

    this.setState({
      getLedgersRrow,
      refId,
      newRefId,
    });
  };
  handleChangeChartCode = async (e) => {
    let value = e.target.value;

    let clonedChartCodesList = [...this.state.chartCodesList];

    if (!value) {
      clonedChartCodesList = [];
    } else {
      let chartCodesListFilterdData = clonedChartCodesList.filter((c) => {
        return (
          (c.code.toUpperCase().includes(value.toUpperCase()) ||
            c.description.toUpperCase().includes(value.toUpperCase())) &&
          c.sort.toUpperCase() === this.state.chartSort.toUpperCase()
        );
      });
      clonedChartCodesList = chartCodesListFilterdData;
    }

    this.setState({
      chartCode: value,
      showSuggestion: true,
      clonedChartCodesList,
    });
  };
  //hide chart code suggestion box
  onBlurChartCode = () => {
    let { formErrors, chartCode } = this.state;
    formErrors = Validation.handleValidation(
      "chartCode",
      chartCode,
      formErrors
    );
    this.setState({ formErrors });
    setTimeout(() => {
      this.setState({ showSuggestion: false });
    }, 200);
  };
  //when select code from suggestions -> auto-completion
  changeChartCode = (obj) => {
    let chartCode = obj.code || "";
    this.setState({ chartCode });
  };
  handleChangeGeneralLedgerList = (e, data, i, j) => {
    let { journalLines } = this.state;
    data[j] = e.target.value;

    this.setState({ journalLines });
  };
  clearStates = () => {
    this.setState({
      chartAccountID: "",
      accountSortCode: "",
      chartSort: "",
      chartCode: "",
      description: "",
      post: "N",
      level: "",
      secLevel: "",
      format: "",
      type: "",
      active: "N",
      initBalance: 0.0,
      extChart: "",
      user: "",
      chartSort_CA: "",
      includeEFC: "N",
      includeBudget: "N",
      includeFringe: "N",
      excelAreas: [],
      importOptions: [],
      overwriteDescription: "N",
      formErrors: {
        description: "",
        chartSort: "",
        chartCode: "",
        addEditCheck: "",
      },
    });
  };
  getAccount = async (e, obj) => {
    if (e.target.cellIndex === 0 || e.target.cellIndex === undefined) {
      return;
    }
    this.setState({ isLoading: true });
    await this.props.getAccount(obj.chartAccountID);
    if (this.props.chart.getAccountSuccess) {
      let getAccount =
        JSON.parse(JSON.stringify(this.props.chart.getAccount)) || "";
      let chartAccountID = getAccount.chartAccountID || "";
      let description = getAccount.description || "";
      let accountSortCode = getAccount.accountSortCode || "";
      let post = getAccount.post || "N";
      let level = getAccount.level || "";
      let secLevel = getAccount.secLevel || "";
      let format = getAccount.format || "";
      let type = getAccount.type || "";
      let active = getAccount.active || "N";
      let initBalance = getAccount.initBalance || 0.0;
      let extChart = getAccount.extChart || "";
      let user = getAccount.user || "";
      this.setState(
        {
          chartAccountID,
          accountSortCode,
          description,
          post,
          level,
          secLevel,
          format,
          type,
          active,
          initBalance,
          extChart,
          user,
          addEditCheck: "update",
        },
        () => {
          this.openModal("InsertChartModal");
        }
      );
    }
    if (this.props.chart.getAccountError) {
      handleAPIErr(this.props.chart.getAccountError, this.props);
    }
    // this.props.clearChartStates();
    this.setState({ isLoading: false });
  };
  updateAccount = async () => {
    let {
      chartAccountID,
      accountSortCode,
      // chartSort,
      // chartCode,
      description,
      post,
      level,
      secLevel,
      format,
      type,
      active,
      initBalance,
      extChart,
      user,
      formErrors,
      accList,
    } = this.state;

    let data = {
      chartAccount: {
        chartAccountID,
        accountSortCode,
        description,
        post,
        level,
        secLevel,
        format,
        type,
        active,
        initBalance,
        extChart,
        user,
      },
    };
    formErrors = Validation.handleWholeValidation(
      { description, accountSortCode },
      formErrors
    );
    if (!formErrors.description && !formErrors.accountSortCode) {
      this.setState({
        isLoading: true,
      });
      await this.props.updateAccount(data);

      //success case of update department
      if (this.props.chart.updateAccountSuccess) {
        toast.success(this.props.chart.updateAccountSuccess);
        let found = accList.findIndex(
          (d) => d.chartAccountID === chartAccountID
        );
        if (found != -1) {
          let table = window.$("#generalledger").DataTable();

          accList[found] = data.chartAccount;

          this.setState(
            {
              accList: [...accList],
            },
            () => {
              table.row(found).invalidate("dom").draw(false);
              this.closeModal("InsertChartModal");
            }
          );
        }
      }
      //error case of update department
      if (this.props.chart.updateAccountError) {
        handleAPIErr(this.props.chart.updateAccountError, this.props);
      }
      //   this.props.clearChartStates();
    }
    this.setState({ formErrors, isLoading: false });
  };
  handleMultipleCheckBox = (e, obj, index) => {
    let { accList, checkAll, checkedList } = this.state;
    let { checked } = e.target;
    if (obj === "all") {
      checkedList = [];
      if (checked) {
        accList.map((obj, i) => {
          obj.checked = true;
          let _val = { chartAccountID: obj.chartAccountID };
          checkedList.push(_val);
          return obj;
        });
      } else {
        accList.map((obj, j) => {
          obj.checked = false;
          return obj;
        });
      }
      this.setState({ accList, checkAll: checked, checkedList });
    } else {
      if (checked) {
        obj.checked = checked;
        accList[index] = obj;
        let _check = accList.findIndex((c) => c.checked === false);
        if (_check === -1) {
          checkAll = true;
        }
        let _val = { chartAccountID: obj.chartAccountID };
        checkedList.push(_val);
        this.setState({ accList, checkAll, checkedList });
      } else {
        obj.checked = checked;
        accList[index] = obj;
        var test = checkedList.filter(
          (check) => check.chartAccountID !== obj.chartAccountID
        );
        this.setState({ checkAll: false, accList, checkedList: test });
      }
    }
  };
  copyAccount = async () => {
    let {
      accList,
      chartSort_CA,
      includeEFC,
      includeBudget,
      includeFringe,
      overwriteDescription,
      formErrors,
      checkedList,
    } = this.state;
    let chartAccountIDs = _.map(checkedList, "chartAccountID"); //-> [1,2,3, ...]
    // chartAccountIDs = chartAccountIDs.map((obj) => obj = parseFloat(obj.replace(/,/g, '')))
    let data = {
      copyOptions: {
        chartAccountID: chartAccountIDs,
        chartSort: chartSort_CA,
        includeEFC,
        includeBudget,
        includeFringe,
        overwriteDescription,
      },
    };
    formErrors = Validation.handleWholeValidation({ chartSort_CA }, formErrors);

    if (!formErrors.chartSort_CA) {
      this.setState({
        isLoading: true,
      });
      await this.props.copyAccount(data);

      //success case of update department
      if (this.props.chart.copyAccountSuccess) {
        toast.success(this.props.chart.copyAccountSuccess);
        checkedList.map((d, i) => {
          let obj = accList.find((e) => e.chartAccountID === d.chartAccountID);
          obj.checked = false;
        });
        this.closeModal("openChartOfAccountsModal");
        checkedList = [];
      }
      //error case of update department
      if (this.props.chart.copyAccountError) {
        handleAPIErr(this.props.chart.copyAccountError, this.props);
      }
      //   this.props.clearChartStates();
    }
    this.setState({ formErrors, isLoading: false, checkedList });
  };
  handleDropDown = (data, key) => {
    let { mc_desc, mc_post, mc_active } = this.state;
    if (key === "mc_desc") {
      mc_desc = data;
    } else if (key === "mc_post") {
      mc_post = data;
    } else {
      //mc_active case
      mc_active = data;
    }
    this.setState({ mc_desc, mc_post, mc_active });
  };
  onMultipleChange = async () => {
    let {
      mc_level,
      mc_level_flag,
      mc_type,
      mc_type_flag,
      mc_post,
      mc_post_flag,
      mc_active,
      mc_active_flag,
      mc_seclevel,
      mc_seclevel_flag,
      mc_fringe,
      mc_fringe_flag,
      mc_desc,
      mc_desc_flag,
      checkedList,
      accList,
    } = this.state;
    let chartAccountIDs = _.map(checkedList, "chartAccountID"); //-> [1,2,3, ...]

    // chartAccountIDs = chartAccountIDs.map((obj) => obj = parseFloat(obj.replace(/,/g, '')))
    mc_desc = mc_desc.value;
    mc_post = mc_post.value;
    mc_active = mc_active.value;
    let data = {
      chartAccountID: chartAccountIDs,
      changeOptions: {
        level: mc_level,
        leveFlag: mc_level_flag,
        type: mc_type,
        typeFlag: mc_type_flag,
        post: mc_post,
        postFlag: mc_post_flag,
        active: mc_active,
        activeFlag: mc_active_flag,
        secLevel: mc_seclevel,
        secLevelFlag: mc_seclevel_flag,
        fringe: mc_fringe,
        fringeFlag: mc_fringe_flag,
        descType: mc_desc,
        descTypeFlag: mc_desc_flag,
      },
    };
    if (
      mc_desc_flag === "Y" ||
      mc_fringe_flag === "Y" ||
      mc_seclevel_flag === "Y" ||
      mc_active_flag === "Y" ||
      mc_post_flag === "Y" ||
      mc_type_flag === "Y" ||
      mc_level_flag === "Y"
    ) {
      this.setState({
        isLoading: true,
      });
      await this.props.multipleChange(data);

      //success case of update department
      if (this.props.chart.multiChangeAccountSuccess) {
        toast.success(this.props.chart.multiChangeAccountSuccess);
        checkedList.map((d, i) => {
          let obj = accList.find((e) => e.chartAccountID === d.chartAccountID);
          obj.checked = false;

          if (mc_seclevel_flag === "Y") {
            obj.secLevel = mc_seclevel;
          }
          if (mc_active_flag === "Y") {
            obj.active = mc_active;
          }
          if (mc_post_flag === "Y") {
            obj.post = mc_post;
          }
          if (mc_type_flag === "Y") {
            obj.type = mc_type;
          }
          if (mc_level_flag === "Y") {
            obj.level = mc_level;
          }
          if (mc_fringe_flag === "Y") {
            obj.initBalance = mc_fringe;
          }
        });

        this.setState({ accList }, () => {
          let table = window.$("#generalledger").DataTable();

          checkedList.map((d, i) => {
            let found = accList.findIndex(
              (e) => e.chartAccountID === d.chartAccountID
            );
            if (found != -1) {
              table.row(found).invalidate("dom").draw();
            }
          });
        });

        this.closeModal("openMultipleChangeModal");
        checkedList = [];
      }
      //error case of update department
      if (this.props.chart.multiChangeAccountError) {
        handleAPIErr(this.props.chart.multiChangeAccountError, this.props);
      }
      //   this.props.clearChartStates();
      this.setState({ isLoading: false, checkedList });
    } else {
      toast.error("No Option Selected");
    }
  };
  exportChartAccount = async () => {
    let { chartAccountID, accList, checkedList } = this.state;
    let chartAccountIDs = _.map(checkedList, "chartAccountID"); //-> [1,2,3, ...]
    if (checkedList.length > 0) {
      this.setState({ isLoading: true });
      await this.props.exportChartAccount(chartAccountIDs);

      if (this.props.chart.exportChartAccountSuccess) {
        toast.success("Chart Account Exported Successfully");
        let resp = JSON.parse(
          JSON.stringify(this.props.chart.exportChartAccount)
        );

        let obj = {
          contentType: "application/vnd.ms-excel",
          attachment: resp,
        };
        downloadAttachments(obj, "AccountCodes");
        checkedList.map((d, i) => {
          let obj = accList.find((e) => e.chartAccountID === d.chartAccountID);
          obj.checked = false;
        });
        checkedList = [];
      }
      if (this.props.chart.exportChartAccountError) {
        handleAPIErr(this.props.chart.exportChartAccountError, this.props);
      }
      //   this.props.clearChartStates();
      this.setState({ isLoading: false, checkedList });
    } else {
      toast.error("Please Select Chart Account First!");
    }
  };
  onImport = async (excelData) => {
    this.setState({ isLoading: true });
    await this.props.pasteAccount({ excelData });
    if (this.props.chart.pasteChartAccountSuccess) {
      toast.success(this.props.chart.pasteChartAccountSuccess);
      this.closeModal("openImportModal");
    }
    if (this.props.chart.pasteChartAccountError) {
      handleAPIErr(this.props.chart.pasteChartAccountError, this.props);
    }
    // this.props.clearChartStates();

    this.setState({ isLoading: false });
  };

  handleClick = (e) => {
    $(".sideBarAccord").toggleClass("rorate_0");
  };

  updateLedgers = async () => {
    this.setState({ isLoading: true });
    let { getLedgersList } = this.state;
    let ledgers = [{ ledgers: getLedgersList }];
    await this.props.updateLedgers(ledgers);

    if (this.props.account.updateLedgersSuccess) {
      toast.success(this.props.account.updateLedgersSuccess);
    }
    if (this.props.account.updateLedgersError) {
      handleAPIErr(this.props.account.updateLedgersError, this.props);
    }
    this.setState({ isLoading: false });
  };

  primeImportLedger = async () => {
    this.setState({ isLoading: true });
    let { importOptions } = this.state;
    await this.props.primeImportLedger();
    if (this.props.account.primeImportLedgerSuccess) {
      toast.success(this.props.account.primeImportLedgerSuccess);
      let importOpt = this.props.account.primeImportLedger.importOptions || [];

      importOpt.map((item) => {
        importOptions.push({ label: item.option, value: item.option });
      });
      this.setState({ importOptions });
    }
    if (this.props.account.primeImportLedgerError) {
      handleAPIErr(this.props.account.primeAccountError, this.props);
    }
    this.setState({ isLoading: false });
  };

  getLedgerHistory = async () => {
    let { page, displayRecords } = this.state;
    this.setState({ isLoading: true });
    let ledgerData = {
      page,
      displayRecords,
    };
    await this.props.getLedgerHistory(ledgerData);
    if (this.props.account.getLedgerHistorySuccess) {
      let getLedgerHistory = this.props.account.getLedgerHistory || [];
      let ledgerHistoryColumns =
        (getLedgerHistory && getLedgerHistory[0].Columns) || [];
      let ledgerHistoryRows =
        (getLedgerHistory && getLedgerHistory[0].Rows) || [];
      let columns = [];
      //adding the column names

      columns[0] = { name: "Cu" };
      columns[1] = { name: "Lo" };
      columns[2] = { name: "Eps" };
      columns[3] = { name: "Prod" };
      columns[4] = { name: "Detl" };
      columns[5] = { name: "Set" };
      columns[6] = { name: "IN" };
      columns[7] = { name: "F" };
      columns[8] = { name: "TX" };
      columns[9] = { name: "Description" };
      columns[10] = { name: "Amount" };
      columns[11] = { name: "Invoice+" };
      columns[12] = { name: "Original" };
      columns[13] = { name: "Export" };
      columns[14] = { name: "ID" };
      columns[15] = { name: "Tran" };
      columns[16] = { name: "Line" };
      columns[17] = { name: "Dist Change" };

      this.setState({ ledgerHistoryColumns, ledgerHistoryRows, columns }, () =>
        this.ledgerHistoryTableSetting()
      );
    }
    this.setState({ isLoading: false });
  };

  ledgerHistoryTableSetting = () => {
    let { columns } = this.state;
    let aoColumns = [];
    //adding the column names
    aoColumns[0] = { sName: "checkbox" };
    columns.map((c) => aoColumns.push({ sName: c.name }));
    aoColumns[columns.length + 1] = { sName: "menus" };

    let result = tableSetting(columns, aoColumns, "ledgerhistory");

    this.setState({ ...result });
  };

  addAttachment = async (attachment, fileName) => {
    this.setState({ isLoading: true });
    let data = {
      fileName: attachment[0].fileName,
      attachment: attachment[0].attachment,
      guid: this.state.refId[0],
    };
    if (this.state.refId.length === 1) {
      await this.props.addAttachment(data);
    } else {
      toast.warn("please select one record at a once");
    }

    if (this.props.account.addAccountAttachmentSuccess) {
      toast.success(this.props.account.addAccountAttachmentSuccess);
      let attachments = this.props.account.addAccountAttachment || [];

      let attachmentSize = 0;
      attachments.map((a, i) => {
        attachmentSize += Number(a.fileSize) || 0;
      });

      this.setState({ attachments, attachmentSize });
    }
    if (this.props.account.addAccountAttachmentError) {
      handleAPIErr(this.props.account.addAccountAttachmentError, this.props);
    }
    // this.props.clearReportsStates();
    this.setState({ isLoading: false });
  };

  getAttachment = async (record) => {
    // console.log("get attachments", recordID, type, fileName);
    // this.setState({ isLoading: true });

    // await this.props.getAccountAttachment(this.state.tran, recordID);
    if (this.props.account.getAccountAttachmentSuccess) {
      toast.success(this.props.account.getAccountAttachmentSuccess);
      let resp = this.props.account.getAccountAttachment;
      // downloadAttachments(resp, fileName);
    }
    if (this.props.account.getAccountAttachmentError) {
      handleAPIErr(this.props.account.getAccountAttachmentError, this.props);
    }
    // this.props.clearInvoiceStates();
    // this.setState({ isLoading: false });
  };

  getAttachments = async () => {
    this.setState({ isLoading: true });
    let { attachId } = this.state;
    await this.props.getAttachments(attachId);
    if (this.props.account.getAccountAttachmentsSuccess) {
      toast.success(this.props.account.getAccountAttachmentsSuccess);
      let attachments = this.props.account.getAccountAttachments;

      this.setState({ attachments });
    }
    if (this.props.account.getAccountAttachmentsError) {
      handleAPIErr(this.props.account.getAccountAttachmentsError, this.props);
    }
    // this.props.clearInvoiceStates();
    this.setState({ isLoading: false });
  };

  render() {
    return (
      <>
        {this.state.isLoading ? <div className="se-pre-con"></div> : ""}

        <div className="user_setup_main mm-genrel_lager">
          <header>
            <TopNav />

            <div className="user_setup_heading">
              <div className="header_menu">
                <Link to="/dashboard">
                  <img
                    src="images/dash-logo.png"
                    className="img-fluid"
                    alt="logo"
                  />
                </Link>
                <Link
                  className="setup_menu"
                  to="#"
                  data-target="#top_nav_toggle1"
                >
                  <img src="images/top-menu.png" className="" alt="top-menu" />
                </Link>
              </div>
              <h2>General Ledger</h2>
              <span>
                <img
                  src="./images/user-setup/lock.png"
                  alt="lock"
                  className="img-fluid"
                />
              </span>
            </div>
            <div className="user_setup_headerbox">
              <div className="user_setup_play_div">
                <ul>
                  <li>
                    <p className="user_setup_play_video">Video</p>
                  </li>
                  <li>
                    <p className="user_setup_play_tuturial">Tutorials</p>
                  </li>
                </ul>
                <span className="user_setup_play_icon">
                  <img
                    src="./images/user-setup/play.png"
                    alt="play"
                    className="img-fluid"
                  />
                </span>
              </div>
              <div className="user_setup_header_rightbox">
                <p>
                  In our{" "}
                  <span>
                    <a href="#">Video</a>
                  </span>{" "}
                  learn how to use general ledger Read our{" "}
                  <span>
                    <a href="#">help article</a>
                  </span>{" "}
                  to learn More
                </p>
              </div>
              <span>
                <img
                  className="close_top_sec"
                  src="images/user-setup/cross.png"
                  alt="cross"
                ></img>
              </span>
            </div>
          </header>
          <div className="col-sm-12 table_white_box">
            {/* Filter */}
            <Filter />
            {/* End Filter */}

            {/* new tale add start */}

            <div className="user_setup_plus_Icons">
              <ul>
                {/* <li>
                  <button
                    className="btn user_setup_rbtns"
                    type="button"
                    onClick={this.primeAccount}
                  >
                    <span className="round_plus">
                      <i className="fa fa-plus-circle" aria-hidden="true"></i>
                    </span>
                  </button>
                </li>
                <li>
                  <button
                    className="btn user_setup_rbtns"
                    type="button"
                    onClick={this.deleteChartAccount}
                  >
                    <span className="round_file">
                      {" "}
                      <img
                        src="./images/user-setup/delete.png"
                        alt="filter"
                      ></img>
                    </span>
                  </button>
                </li> */}
                <li></li>
              </ul>
            </div>
            <div className="mm-table-outer2">
              <div>
                <Dropdown
                  alignRight="false"
                  drop="down"
                  className="analysis-card-dropdwn setting_popup_dots"
                >
                  <Dropdown.Toggle variant="sucess" id="dropdown-basic">
                    <span className="dots_img">
                      <img
                        src="./images/user-setup/dots.png"
                        alt="filter"
                      ></img>
                    </span>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item
                      onClick={() => this.openModal("openAccountReportModal")}
                    >
                      &nbsp;&nbsp;Reports
                    </Dropdown.Item>

                    <Dropdown className="mm_toggle_new_main">
                      {/* new item start  */}
                      <div className="attach-text">
                        {/* PO Attachments */}
                        <span
                          onClick={this.handleClick}
                          className="fa fa-angle-up float-left mr-2 sideBarAccord"
                          data-toggle="collapse"
                          data-target="#Attachments2"
                        ></span>
                        <span
                          className="mm_name_attached2"
                          onClick={() =>
                            this.openModal("openAccountAttachmentsModal")
                          }
                        >
                          Attachments
                          <span className="ml-3 font-weight-bold"></span>
                          {this.state.attachments.length}
                          <a class="float-right mr-3" href="#">
                            <img
                              src="images/add.png"
                              class=" img-fluid sidebarr_plus "
                              alt="user"
                            />
                          </a>
                        </span>
                      </div>
                      <div className="collapse" id="Attachments2">
                        {this.state.attachments.map((a, i) => {
                          return (
                            <div
                              // onClick={() =>
                              //   this.getAttachment(
                              //     a
                              //   )
                              // }
                              key={i}
                              className="main-sec-attach"
                            >
                              {a.file}{" "}
                              <span className="fa fa-angle-right"></span>
                            </div>
                          );
                        })}
                      </div>
                      {/* end  */}
                    </Dropdown>

                    <Dropdown.Item onClick={this.getAdvancedExcelExportOptions}>
                      &nbsp;&nbsp;Export
                    </Dropdown.Item>

                    <Dropdown.Item onClick={() => this.getExportLedger()}>
                      &nbsp;&nbsp;Export Ledger
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => this.getExportExcel()}>
                      &nbsp;&nbsp;Import
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => this.openModal("openEditHistoryModal")}
                    >
                      &nbsp;&nbsp;Edit History
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() =>
                        this.openModal("openDistributionChangeModal")
                      }
                    >
                      &nbsp;&nbsp;Distribution Change
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => this.props.history.push("/dist-changes")}
                    >
                      &nbsp;&nbsp;Dist Change List
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => this.getReconcile()}>
                      &nbsp;&nbsp;Reconcile
                    </Dropdown.Item>
                    <Dropdown
                      className="mm_toggle_new_main"
                      //  onClick = {() => this.getLedgers()}
                    >
                      <span className="mm_name_attached2">
                        {" "}
                        &nbsp;&nbsp;Exclude Reconcile
                      </span>
                      <span class="list-group-item mm-newswitch">
                        <label class="switch ">
                          <input
                            type="checkbox"
                            class="default"
                            defaultChecked={this.state.excludeReconcile}
                            id="excludeReconcile"
                            onChange={(e) =>
                              this.reloadLedgers(e, "excludeReconcile")
                            }
                          />
                          <span className="slider round"></span>
                        </label>
                      </span>
                    </Dropdown>
                    <Dropdown className="mm_toggle_new_main">
                      <span className="mm_name_attached2">
                        {" "}
                        &nbsp;&nbsp;Exclude Zero
                      </span>

                      <span class="list-group-item mm-newswitch">
                        <label class="switch ">
                          <input
                            type="checkbox"
                            class="default"
                            defaultChecked={this.state.excludeZero}
                            id="excludeZero"
                            onChange={(e) =>
                              this.reloadLedgers(e, "excludeZero")
                            }
                          />
                          <span className="slider round"></span>
                        </label>
                      </span>
                    </Dropdown>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
            <div className="pb-1 login_form table-genrel">
              <table
                id="generalledger"
                width="100%"
                className=" user_setup_table ladger-table-new"
              >
                <thead>
                  <tr>
                    <th scope="col" className="exp_th1 mm_contact-name">
                      <div className="form-group">
                        <label className="dash_container dash_remember table-check unckeck">
                          <input
                            type="checkbox"
                            name={"chk1"}
                            id={"chk1"}
                            // checked={checkAllJrnltem}
                            onChange={(e) => this.handleCheckboxes(e, "all")}
                          />
                          <span id="chk1" className="dash_checkmark"></span>
                        </label>
                      </div>
                    </th>
                    {this.state.getLedgersColmun?.map((m, i) => {
                      return (
                        <th
                          scope="col"
                          key={i}
                          className="text-left exp-supplier-th"
                        >
                          <span className="table_lines_hed"> {m.ColName}</span>
                        </th>
                      );
                    })}

                    <th>
                      <span
                        className="user_setup_hed2"
                        onClick={() => this.openModal("openSettingsModal")}
                      >
                        {" "}
                        <img
                          src="./images/user-setup/bars.png"
                          alt="bars"
                        ></img>
                      </span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.getLedgersRrow?.map((item, i) => {
                    return (
                      <tr key={i}>
                        <td>
                          <div className="col align-self-center text-center pr-0">
                            <div className="form-group mb-0 check-line">
                              <label className="dash_container dash_remember table-check unckeck">
                                <input
                                  type="checkbox"
                                  name={"chk1"}
                                  id={"chk1" + i}
                                  checked={item.checked}
                                  onChange={(e) =>
                                    this.handleCheckboxes(e, item)
                                  }
                                />
                                <span
                                  id={"chk1" + i}
                                  className="dash_checkmark"
                                ></span>
                              </label>
                            </div>
                          </div>
                        </td>

                        {this.state.getLedgersColmun?.map((c, j) => {
                          let ColType = c.ColType;
                          if (ColType === "Number" || ColType === "Numeric") {
                            return (
                              <td
                                className="text-left"
                                data-order={item.data[j]}
                              >
                                <input
                                  type="number"
                                  name="chartSort"
                                  value={item.data[j]}
                                  className="input_height wd-108"
                                  readOnly={c.Editable ? false : true}
                                  onBlur={this.updateLedgers}
                                  onChange={(e) => {
                                    this.handleChangeGeneralLedgerList(
                                      e,
                                      item.data,
                                      i,
                                      j
                                    );
                                  }}
                                />
                              </td>
                            );
                          } else if (ColType === "Date") {
                            return (
                              <>
                                <td
                                  className="text-left"
                                  data-order={item.data[j]}
                                >
                                  <input
                                    type="Date"
                                    name="chartSort"
                                    value={item.data[j]}
                                    className="input_height wd-108"
                                    readOnly={c.Editable ? false : true}
                                    onBlur={this.updateLedgers}
                                    onChange={(e) => {
                                      this.handleChangeGeneralLedgerList(
                                        e,
                                        item.data,
                                        i,
                                        j
                                      );
                                    }}
                                  />
                                </td>
                              </>
                            );
                          } else {
                            return (
                              <>
                                <td
                                  className="text-left"
                                  data-order={item.data[j]}
                                >
                                  <input
                                    type="text"
                                    name="chartSort"
                                    value={item.data[j]}
                                    className="input_height wd-108"
                                    readOnly={c.Editable ? false : true}
                                    onBlur={this.updateLedgers}
                                    onChange={(e) => {
                                      this.handleChangeGeneralLedgerList(
                                        e,
                                        item.data,
                                        i,
                                        j
                                      );
                                    }}
                                  />
                                </td>
                              </>
                            );
                          }
                        })}
                        <td className="text-left" data-order={""}></td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            {/* end new table */}
          </div>
        </div>
        <Settings
          openSettingsModal={this.state.openSettingsModal}
          openModal={this.openModal}
          closeModal={this.closeModal}
          columns={this.state.columns}
          pageLength={this.state.pageLength}
          handleChangeSettings={this.handleChangeSettings}
          handleSaveSettings={this.handleSaveSettings}
          handleCloseSettingModal={this.handleCloseSettingModal}
        />
        {/* <MultipleChangeModal
          openMultipleChangeModal={this.state.openMultipleChangeModal}
          openModal={this.openModal}
          closeModal={this.closeModal}
          state={this.state}
          handleChangeField={this.handleChangeField}
          handleCheckBox={this.handleCheckBox}
          handleDropDown={this.handleDropDown}
          onMultipleChange={this.onMultipleChange}
        /> */}

        {/* <ChartOfAccountsModal
          openChartOfAccountsModal={this.state.openChartOfAccountsModal}
          openModal={this.openModal}
          closeModal={this.closeModal}
          state={this.state}
          handleChangeField={this.handleChangeField}
          handleCheckBox={this.handleCheckBox}
          copyAccount={this.copyAccount}
        /> */}
        {/* <InsertChartModal
          state={this.state}
          openModal={this.openModal}
          closeModal={this.closeModal}
          getChartSorts={this.props.chart.getChartSorts || ""} //api response (get chart sort)
          getUpdatedChartSort={this.getUpdatedChartSort}
          chartCodes={this.state.chartCodesList || []} //api response (all chart codes)
          getUpdatedChartCode={this.getUpdatedChartCode}
          handleChangeChartCode={this.handleChangeChartCode}
          onBlurChartCode={this.onBlurChartCode}
          changeChartCode={this.changeChartCode}
          handleChangeField={this.handleChangeField}
          handleCheckBox={this.handleCheckBox}
          handleAddEdit={this.handleAddEdit}
        /> */}

        <Attachments
          openAccountAttachmentsModal={this.state.openAccountAttachmentsModal}
          closeModal={this.closeModal}
          addAttachment={this.addAttachment}
          attachments={this.state.attachments}
          tran={this.state.id}
          props={this.props}
          getAttachment={this.getAttachment}
          draft={this.state.tab === "draft" ? true : false} //to hide/show "Drag Files in or Click to Upload" box
          ordersPage={true}
        />
        <Reports
          openAccountReportModal={this.state.openAccountReportModal}
          closeModal={this.closeModal}
          reportType="General Ledger"
          locationProps={this.props}
        />
        <EditHistory
          openEditHistoryModal={this.state.openEditHistoryModal}
          closeModal={this.closeModal}
          ledgerHistoryColumns={this.state.ledgerHistoryColumns}
          ledgerHistoryRows={this.state.ledgerHistoryRows}
          columns={this.state.columns}
        />
        <ExportAccount
          openExportAccountModal={this.state.openExportAccountModal}
          closeModal={this.closeModal}
          clonnedExcelOptions={this.state.clonnedExcelOptions}
          excelOptions={this.state.excelOptions}
          excelAreas={this.state.excelAreas}
          tableRows={this.state.getLedgersRrow || []}
        />
        <DistributionChange
          openDistributionChangeModal={this.state.openDistributionChangeModal}
          closeModal={this.closeModal}
          chartSorts={this.props.chart.getChartSorts || ""} //api response (get chart sort)
          chartCodes={this.state.chartCodesList || []} //api response (all chart codes)
          tableRows={this.state.getLedgersRrow || []}
        />

        <Import
          openImportModal={this.state.openImportModal}
          closeModal={this.closeModal}
          importOptions={this.state.importOptions}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  account: state.account,
  chart: state.chart,
});
export default connect(mapStateToProps, {
  getLedgers: AccountActions.getLedgers,
  getExportLedger: AccountActions.getExportLedger,
  getReconcile: AccountActions.getReconcile,
  updateLedgers: AccountActions.updateLedgers,
  getChartCodes: ChartActions.getChartCodes,
  getChartSorts: ChartActions.getChartSorts,
  getLedgerHistory: AccountActions.getLedgerHistory,
  getAdvancedExcelExportOptions: AccountActions.getAdvancedExcelExportOptions,
  primeImportLedger: AccountActions.primeImportLedger,
  addAttachment: AccountActions.addAttachment,
  getAttachments: AccountActions.getAttachments,
  getAccountAttachment: AccountActions.getAccountAttachment,
  //   deleteChartAccount: ChartActions.deleteChartAccount,
  //   clearChartStates: ChartActions.clearChartStates,
  //   getChartSorts: ChartActions.getChartSorts,
  //   getChartCodes: ChartActions.getChartCodes,
  //   primeAccount: ChartActions.primeAccount,
  //   addAccount: ChartActions.addAccount,
  //   getAccount: ChartActions.getAccount,
  //   updateAccount: ChartActions.updateAccount,
  //   multipleChange: ChartActions.multipleChange,
  //   copyAccount: ChartActions.copyAccount,
  //   exportChartAccount: ChartActions.exportChartAccount,
  //   pasteAccount: ChartActions.pasteAccount,
})(GeneralLedger);
