import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';
import * as AWS from 'aws-sdk';

export const getCognitoUserPoolWrapper = () => {

	const poolData = {
		UserPoolId: localStorage.getItem('COGNITO_USER_POOL_ID'),
		ClientId: localStorage.getItem('COGNITO_CLIENT_APP_ID'),
		endpoint: localStorage.getItem('COGNITO_PROXY_URL'),
	};

	AWS.config.region = window.config.AWS_REGION;
	AWS.config.credentials = new AWS.CognitoIdentityCredentials({
		IdentityPoolId: window.config.REACT_APP_COGNITO_IDENTITY_POOL_ID
	});

	return new AmazonCognitoIdentity.CognitoUserPool(poolData);
}