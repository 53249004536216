import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import ReactSelect from "react-select";

const UserCompanySelectModal = ({ isOpen, onClose, onSubmit, companyList }) => {
  const [company, setCompany] = useState(-1);
  const [companyError, setCompanyError] = useState(null);

  const handleSubmit = () => {
    setCompanyError(null);

    if (company === -1) {
      setCompanyError("Company is required");
      return;
    }

    onSubmit(company.value);
  };

  return (
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={isOpen}
      onHide={onClose}
      className="forgot_email_modal"
    >
      <Modal.Body>
        <div className="container-fluid">
          <div className="main_wrapper">
            <div className="row d-flex h-100">
              <div className="col-12 justify-content-center align-self-center form_mx_width">
                <div className="forgot_form_main">
                  <h4>Select a Company to Login</h4>

                  <div className="form-group">
                    <label>Company</label>
                    <ReactSelect
                      classNamePrefix="custon_select-selector-inner main__dropdown--wrapper1"
                      value={company}
                      options={companyList.map((c) => ({ label: c, value: c }))}
                      onChange={(obj) => {
                        setCompany(obj);
                      }}
                    />
                    <div className="text-danger error-12">
                      {companyError ?? ""}
                    </div>
                  </div>
                  <div className="d-flex justify-content-end">
                    <button
                      type="button"
                      className={"btn btn_white float-left"}
                      id="id_cancel"
                      tabIndex="7"
                      onClick={() => {
                        onClose();
                        setCompany(-1);
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      tabIndex="6"
                      className={"btn btn_blue float-right ml-2"}
                      id="id_proceed"
                      onClick={handleSubmit}
                    >
                      Login
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default UserCompanySelectModal;
