import moment from "moment";
import axiosDPFAPIClient from "../../Utils/axiosDPFAPIClient";
import { isValidString } from "../../Utils/Helpers";
// ---------------------****Timecard Actions****-----------------------------

//----------------------****Get Timecard Tallies*****------------------------
export const getTimecardTallies = (data) => async (dispatch) => {
  dispatch({
    type: "GET_TIMECARD_TALLIES_INIT",
  });

  let url = `/DPFAPI/TimecardRequest?actionType=GetTimecardTallies`;

  try {
    let result = await axiosDPFAPIClient.get(url);
    let resp = (result && result.data && result.data.TimecardResponse) || "";
    if (resp && resp.result.length > 0) {
      if (resp.result[0] && resp.result[0].status === "Success") {
        dispatch({
          type: "GET_TIMECARD_TALLIES_SUCCESS",
          payload: resp,
        });
      } else {
        dispatch({
          type: "GET_TIMECARD_TALLIES_FAIL",
          payload:
            resp.result[0].description ||
            "Error While Getting Timecard Tallies.",
        });
      }
    } else {
      dispatch({
        type: "GET_TIMECARD_TALLIES_FAIL",
        payload: "Error While Getting Timecard Tallies.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Getting Timecard Tallies.";
    dispatch({
      type: "GET_TIMECARD_TALLIES_FAIL",
      payload: error,
    });
  }
};
//----------------------****Get Timecard List*****---------------------------
export const getTimecardList = (data) => async (dispatch) => {
  dispatch({
    type: "GET_TIMECARD_LIST_INIT",
  });

  let timecardType = data.type || "";
  let teamTimecards = data.teamTimecard || "N";

  let url = `/DPFAPI/TimecardRequest?actionType=GetTimecardList&timecardType=${timecardType}&teamTimecards=${teamTimecards}`;

  try {
    let result = await axiosDPFAPIClient.get(url);
    let resp = (result && result.data && result.data.TimecardResponse) || "";
    if (resp && resp.result.length > 0) {
      if (resp.result[0] && resp.result[0].status === "Success") {
        dispatch({
          type: "GET_TIMECARD_LIST_SUCCESS",
          payload: resp,
        });
      } else {
        dispatch({
          type: "GET_TIMECARD_LIST_FAIL",
          payload:
            resp.result[0].description || "Error While Getting Timecard List.",
        });
      }
    } else {
      dispatch({
        type: "GET_TIMECARD_LIST_FAIL",
        payload: "Error While Getting Timecard List.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Getting Timecard List.";
    dispatch({
      type: "GET_TIMECARD_LIST_FAIL",
      payload: error,
    });
  }
};
//----------------------****Get New Timecard List*****---------------------------
export const getNewTimecardList = (data) => async (dispatch) => {
  dispatch({
    type: "GET_NEW_TIMECARD_LIST_INIT",
  });

  const url = "/DPFAPI/TimecardRequest";
  let obj = {
    actionType: "GetNewTimecardList",
    ...data,
  };

  try {
    let result = await axiosDPFAPIClient.post(url, obj);
    let resp = (result && result.data && result.data.TimecardResponse) || "";

    if (resp && resp.result.length > 0) {
      if (resp.result[0] && resp.result[0].status === "Success") {
        dispatch({
          type: "GET_NEW_TIMECARD_LIST_SUCCESS",
          payload: resp,
        });
      } else {
        dispatch({
          type: "GET_NEW_TIMECARD_LIST_FAIL",
          payload:
            resp.result[0].description || "Error While Getting Timecard List.",
        });
      }
    } else {
      dispatch({
        type: "GET_NEW_TIMECARD_LIST_FAIL",
        payload: "Error While Getting Timecard List.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Getting Timecard List.";
    dispatch({
      type: "GET_NEW_TIMECARD_LIST_FAIL",
      payload: error,
    });
  }
};

export const updateProcessingStatus = (guid, status) => async (dispatch) => {
  
  dispatch({
    type: "UPDATE_TIMECARD_PROCESSING_STATUS",
    payload: {
      guid,
      status,
    },
  });
};
//----------------------****Insert Timecard *****----------------------------
export const primeTimecard = (timecard) => async (dispatch) => {
  dispatch({
    type: "PRIME_TIMECARD_INIT",
  });
  const url = "/DPFAPI/TimecardRequest";
  let data = {
    actionType: "PrimeTimecard",
  };

  try {
    let result = await axiosDPFAPIClient.post(url, data);
    let resp = (result && result.data && result.data.TimecardResponse) || "";
    if (resp && resp.result.length > 0) {
      if (resp.result[0] && resp.result[0].status === "Success") {
        dispatch({
          type: "PRIME_TIMECARD_SUCCESS",
          payload: resp,
        });
      } else {
        dispatch({
          type: "PRIME_TIMECARD_FAIL",
          payload:
            resp.result[0].description || "Error While Priming Timecard.",
        });
      }
    } else {
      dispatch({
        type: "PRIME_TIMECARD_FAIL",
        payload: "Error While Priming Timecard.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Priming Timecard.";
    dispatch({
      type: "PRIME_TIMECARD_FAIL",
      payload: error,
    });
  }
};
//----------------------****Insert Timecard *****----------------------------
export const insertTimecard = (timecard) => async (dispatch) => {
  dispatch({
    type: "INSERT_TIMECARD_INIT",
  });
  const url = "/DPFAPI/TimecardRequest";
  let data = {
    actionType: "InsertTimecard",
    timecard,
  };

  try {
    let result = await axiosDPFAPIClient.post(url, data);
    let resp = (result && result.data && result.data.TimecardResponse) || "";
    if (resp && resp.result.length > 0) {
      if (resp.result[0] && resp.result[0].status === "Success") {
        dispatch({
          type: "INSERT_TIMECARD_SUCCESS",
          payload: resp,
        });
      } else {
        dispatch({
          type: "INSERT_TIMECARD_FAIL",
          payload: resp.result[0].description || "Error While Adding Timecard.",
        });
      }
    } else {
      dispatch({
        type: "INSERT_TIMECARD_FAIL",
        payload: "Error While Adding Timecard.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Adding Timecard.";
    dispatch({
      type: "INSERT_TIMECARD_FAIL",
      payload: error,
    });
  }
};
//----------------------****Get EmployeeDetails List  *****------------------
export const getEmployeeDetailsList = (employeeCode) => async (dispatch) => {
  dispatch({
    type: "Get_Employee_Details_List_INIT",
  });
  const url = "/DPFAPI/TimecardRequest";
  let obj = {
    actionType: "GetEmployeeDetailsList",
    employeeCode: employeeCode,
  };

  try {
    let result = await axiosDPFAPIClient.post(url, obj);

    let resp = (result && result.data && result.data.TimecardResponse) || "";

    if (resp && resp.result.length > 0) {
      if (resp.result[0] && resp.result[0].status === "Success") {
        dispatch({
          type: "Get_Employee_Details_List_SUCCESS",
          payload: resp,
        });
      } else {
        dispatch({
          type: "Get_Employee_Details_List_FAIL",
          payload:
            resp.result[0].description ||
            "Error While Refreshing The Employees.",
        });
      }
    } else {
      dispatch({
        type: "Get_Employee_Details_List_FAIL",
        payload: "Error While Refreshing The Employees.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Refreshing The Employees.";
    dispatch({
      type: "Get_Employee_Details_List_FAIL",
      payload: error,
    });
  }
};

/**
 * Formats the travelFrom, travelTo related entities in the TimeCard
 * to string if they are not string
 * @param {TimeCard} data 
 * @returns {TimeCard}
 */
const formatTimeUpdateTimeCard = (data) => {
  const clonedTimeCard = {...data};
  const dailyTimes = clonedTimeCard?.dailyTimes?.length || 0;
  for (let index = 0; index < dailyTimes; index++) {
    const time = clonedTimeCard.dailyTimes[index];
    const travelFrom = isValidString(time.travelFrom, true) ? time.travelFrom : moment.utc(time.travelFrom).format('HH:mm');
    const travelTo = isValidString(time.travelTo, true) ? time.travelTo : moment.utc(time.travelTo).format('HH:mm');
    clonedTimeCard.dailyTimes[index].travelFrom = travelFrom;
    clonedTimeCard.dailyTimes[index].travelTo = travelTo;
  }

  return clonedTimeCard;
}

//----------------------****Update Timecard *****----------------------------
export const updateTimecard = (timecard) => async (dispatch) => {
  dispatch({
    type: "UPDATE_TIMECARD_INIT",
  });
  const url = "/DPFAPI/TimecardRequest";
  const formattedTimeCard = formatTimeUpdateTimeCard(timecard);
  let data = {
    actionType: "UpdateTimecard",
    timecard: formattedTimeCard,
  };

  try {
    let result = await axiosDPFAPIClient.post(url, data);
    let resp = (result && result.data && result.data.TimecardResponse) || "";
    if (resp && resp.result.length > 0) {
      if (resp.result[0] && resp.result[0].status === "Success") {
        dispatch({
          type: "UPDATE_TIMECARD_SUCCESS",
          payload: resp,
        });
      } else {
        dispatch({
          type: "UPDATE_TIMECARD_FAIL",
          payload:
            resp.result[0].description || "Error While Updating Timecard.",
        });
      }
    } else {
      dispatch({
        type: "UPDATE_TIMECARD_FAIL",
        payload: "Error While Updating Timecard.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Updating Timecard.";
    dispatch({
      type: "UPDATE_TIMECARD_FAIL",
      payload: error,
    });
  }
};

export const updateTimecardStatus = (guid, status) => async (dispatch) => {
  dispatch({
    type: "UPDATE_TIMECARD_STATUS",
    payload: { guid, status },
  });
};

//----------------------****Get Single Timecard *****------------------------
export const getTimecard = (selectedGuid) => async (dispatch) => {
  dispatch({
    type: "GET_TIMECARD_INIT",
  });
  const url = `/DPFAPI/TimecardRequest?actionType=GetTimecard&guid=${selectedGuid}`;

  try {
    let result = await axiosDPFAPIClient.get(url);
    let resp = (result && result.data && result.data.TimecardResponse) || "";
    if (resp && resp.result.length > 0) {
      if (resp.result[0] && resp.result[0].status === "Success") {
        dispatch({
          type: "GET_TIMECARD_SUCCESS",
          payload: resp,
        });
      } else {
        dispatch({
          type: "GET_TIMECARD_FAIL",
          payload:
            resp.result[0].description || "Error While Getting Timecard.",
        });
      }
    } else {
      dispatch({
        type: "GET_TIMECARD_FAIL",
        payload: "Error While Getting Timecard.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Getting Timecard.";
    dispatch({
      type: "GET_TIMECARD_FAIL",
      payload: error,
    });
  }
};
//----------------------****Get Timecard Summary *****-----------------------
export const getTimecardSummary = (selectedGuid) => async (dispatch) => {
  dispatch({
    type: "GET_TIMECARD_SUMMARY_INIT",
  });
  const url = `/DPFAPI/TimecardRequest?actionType=GetTimecardSummary&guid=${selectedGuid}`;

  try {
    let result = await axiosDPFAPIClient.get(url);
    let resp = (result && result.data && result.data.TimecardResponse) || "";
    if (resp && resp.result.length > 0) {
      if (resp.result[0] && resp.result[0].status === "Success") {
        dispatch({
          type: "GET_TIMECARD_SUMMARY_SUCCESS",
          payload: resp,
        });
      } else {
        dispatch({
          type: "GET_TIMECARD_SUMMARY_FAIL",
          payload:
            resp.result[0].description ||
            "Error While Getting Timecard Summary.",
        });
      }
    } else {
      dispatch({
        type: "GET_TIMECARD_SUMMARY_FAIL",
        payload: "Error While Getting Timecard Summary.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Getting Timecard Summary.";
    dispatch({
      type: "GET_TIMECARD_SUMMARY_FAIL",
      payload: error,
    });
  }
};
//----------------------****Update Timecard Summary*****----------------------
export const updateSummary = (data) => async (dispatch) => {
  dispatch({
    type: "UPDATE_SUMMARY_INIT",
  });
  const url = "/DPFAPI/TimecardRequest";
  let obj = {
    actionType: "UpdateSummary",
    timecardSummary: {
      ...data,
    },
  };

  try {
    let result = await axiosDPFAPIClient.post(url, obj);
    let resp = (result && result.data && result.data.TimecardResponse) || "";
    if (resp && resp.result.length > 0) {
      if (resp.result[0] && resp.result[0].status === "Success") {
        dispatch({
          type: "UPDATE_SUMMARY_SUCCESS",
          payload: resp,
        });
      } else {
        dispatch({
          type: "UPDATE_SUMMARY_FAIL",
          payload:
            resp.result[0].description || "Error While Updating Summary.",
        });
      }
    } else {
      dispatch({
        type: "UPDATE_SUMMARY_FAIL",
        payload: "Error While Updating Summary.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Updating Summary.";
    dispatch({
      type: "UPDATE_SUMMARY_FAIL",
      payload: error,
    });
  }
};
//----------------------****Delete Timecard *****----------------------------
export const deleteTimecard = (selectedGuid) => async (dispatch) => {
  dispatch({
    type: "DELETE_TIMECARD_INIT",
  });
  const url = "/DPFAPI/TimecardRequest";
  let data = {
    actionType: "DeleteTimecard",
    guid: selectedGuid,
  };

  try {
    let result = await axiosDPFAPIClient.post(url, data);
    let resp = (result && result.data && result.data.TimecardResponse) || "";
    if (resp && resp.result.length > 0) {
      if (resp.result[0] && resp.result[0].status === "Success") {
        dispatch({
          type: "DELETE_TIMECARD_SUCCESS",
          payload: resp,
        });
      } else {
        dispatch({
          type: "DELETE_TIMECARD_FAIL",
          payload:
            resp.result[0].description || "Error While Deleting Timecard.",
        });
      }
    } else {
      dispatch({
        type: "DELETE_TIMECARD_FAIL",
        payload: "Error While Deleting Timecard.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Deleting Timecard.";
    dispatch({
      type: "DELETE_TIMECARD_FAIL",
      payload: error,
    });
  }
};
//----------------------****Move Timecard *****------------------------------
export const moveToApproverTimecard = (guid) => async (dispatch) => {
  dispatch({
    type: "MOVE_TIMECARD_INIT",
  });
  const url = "/DPFAPI/TimecardRequest";
  let data = {
    actionType: "MoveToApprove",
    guid:guid
  };

  try {
    let result = await axiosDPFAPIClient.post(url, data);
    let resp = (result && result.data && result.data.TimecardResponse) || "";
    if (resp && resp.result.length > 0) {
      if (resp.result[0] && resp.result[0].status === "Success") {
        dispatch({
          type: "MOVE_TIMECARD_SUCCESS",
          payload: resp,
        });
      } else {
        dispatch({
          type: "MOVE_TIMECARD_FAIL",
          payload: resp.result[0].description || "Error While Moving Timecard.",
        });
      }
    } else {
      dispatch({
        type: "MOVE_TIMECARD_FAIL",
        payload: "Error While Moving Timecard.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Moving Timecard.";
    dispatch({
      type: "MOVE_TIMECARD_FAIL",
      payload: error,
    });
  }
};
//----------------------****Move Timecard *****------------------------------
export const moveTimecard = (guid) => async (dispatch) => {
  dispatch({
    type: "MOVE_TIMECARD_INIT",
  });
  const url = "/DPFAPI/TimecardRequest";
  let data = {
    actionType: "MoveTimecard",
    guid:guid
  };

  try {
    let result = await axiosDPFAPIClient.post(url, data);
    let resp = (result && result.data && result.data.TimecardResponse) || "";
    if (resp && resp.result.length > 0) {
      if (resp.result[0] && resp.result[0].status === "Success") {
        dispatch({
          type: "MOVE_TIMECARD_SUCCESS",
          payload: resp,
        });
      } else {
        dispatch({
          type: "MOVE_TIMECARD_FAIL",
          payload: resp.result[0].description || "Error While Moving Timecard.",
        });
      }
    } else {
      dispatch({
        type: "MOVE_TIMECARD_FAIL",
        payload: "Error While Moving Timecard.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Moving Timecard.";
    dispatch({
      type: "MOVE_TIMECARD_FAIL",
      payload: error,
    });
  }
};
//----------------------****Devline Timecard *****----------------------------
export const declineTimecard = (obj) => async (dispatch) => {
  dispatch({
    type: "DECLINE_TIMECARD_INIT",
  });
  const url = "/DPFAPI/TimecardRequest";
  let data = {
    actionType: "DeclineTimecard",
    ...obj,
  };

  try {
    let result = await axiosDPFAPIClient.post(url, data);
    let resp = (result && result.data && result.data.TimecardResponse) || "";
    if (resp && resp.result.length > 0) {
      if (resp.result[0] && resp.result[0].status === "Success") {
        dispatch({
          type: "DECLINE_TIMECARD_SUCCESS",
          payload: resp,
        });
      } else {
        dispatch({
          type: "DECLINE_TIMECARD_FAIL",
          payload:
            resp.result[0].description || "Error While Declining Timecard.",
        });
      }
    } else {
      dispatch({
        type: "DECLINE_TIMECARD_FAIL",
        payload: "Error While Declining Timecard.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Declining Timecard.";
    dispatch({
      type: "DECLINE_TIMECARD_FAIL",
      payload: error,
    });
  }
};

//----------------------****Devline Timecard *****----------------------------
export const downloadTimecard = (params) => async (dispatch) => {
  dispatch({
    type: "DOWNLOAD_TIMECARD_INIT",
  });
  const url = "/DPFAPI/TimecardRequest";
  let data = {
    actionType: "DownloadAttachments",
    ...params,
  };

  try {
    let result = await axiosDPFAPIClient.post(url, data);
    let resp = (result && result.data && result.data.TimecardResponse) || "";
    if (resp && resp.result.length > 0) {
      if (resp.result[0] && resp.result[0].status === "Success") {
        dispatch({
          type: "DOWNLOAD_TIMECARD_SUCCESS",
          payload: resp,
        });
      } else {
        dispatch({
          type: "DOWNLOAD_TIMECARD_FAIL",
          payload:
            resp.result[0].description || "Error While Downloading Timecard.",
        });
      }
    } else {
      dispatch({
        type: "DOWNLOAD_TIMECARD_FAIL",
        payload: "Error While Downloading Timecard.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Downloading Timecard.";
    dispatch({
      type: "DOWNLOAD_TIMECARD_FAIL",
      payload: error,
    });
  }
};

//----------------------****Send for Approval Timecard *****------------------
export const sendForApproval = (tranData) => async (dispatch) => {
  dispatch({
    type: "SEND_FOR_APPROVAL_TIMECARD_INIT",
  });
  const url = "/DPFAPI/TimecardRequest";
  let data = {
    actionType: "SendForApproval",
    ...tranData,
  };

  try {
    let result = await axiosDPFAPIClient.post(url, data);
    let resp = (result && result.data && result.data.TimecardResponse) || "";
    if (resp && resp.result.length > 0) {
      // display success message only if all the steps are successfully
      if (resp.result.every((res) => res.status === "Success")) {
        dispatch({
          type: "SEND_FOR_APPROVAL_TIMECARD_SUCCESS",
          payload: resp,
        });
      } else {
        // find a failed step and display the error message
        const failedTimeCard = resp.result.find(
          (res) => res.status !== "Success"
        );
        if (failedTimeCard) {
          dispatch({
            type: "SEND_FOR_APPROVAL_TIMECARD_FAIL",
            payload:
              failedTimeCard.description ||
              "Error While Sending Timecard For Approval.",
          });
        }
      }
    } else {
      dispatch({
        type: "SEND_FOR_APPROVAL_TIMECARD_FAIL",
        payload: "Error While Sending Timecard For Approval.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Sending Timecard For Approval.";
    dispatch({
      type: "SEND_FOR_APPROVAL_TIMECARD_FAIL",
      payload: error,
    });
  }
};
//----------------------****Hold Timecard *****-------------------------------
export const holdTimecard = (tranData) => async (dispatch) => {
  dispatch({
    type: "HOLD_TIMECARD_INIT",
  });
  const url = "/DPFAPI/TimecardRequest";
  let data = {
    actionType: "HoldTimecard",
    ...tranData,
  };

  try {
    let result = await axiosDPFAPIClient.post(url, data);
    let resp = (result && result.data && result.data.TimecardResponse) || "";
    if (resp && resp.result.length > 0) {
      if (resp.result[0] && resp.result[0].status === "Success") {
        dispatch({
          type: "HOLD_TIMECARD_SUCCESS",
          payload: resp,
        });
      } else {
        dispatch({
          type: "HOLD_TIMECARD_FAIL",
          payload:
            resp.result[0].description || "Error While Holding Timecard.",
        });
      }
    } else {
      dispatch({
        type: "HOLD_TIMECARD_FAIL",
        payload: "Error While Holding Timecard.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Holding Timecard.";
    dispatch({
      type: "HOLD_TIMECARD_FAIL",
      payload: error,
    });
  }
};
//----------------------****Approve Timecard *****-----------------------------
export const approveTimecard = (tranData) => async (dispatch) => {
  dispatch({
    type: "APPROVE_TIMECARD_INIT",
  });
  const url = "/DPFAPI/TimecardRequest";
  let data = {
    actionType: "ApproveTimecard",
    ...tranData,
  };

  try {
    let result = await axiosDPFAPIClient.post(url, data);
    let resp = (result && result.data && result.data.TimecardResponse) || "";
    if (resp && resp.result.length > 0) {
      if (resp.result[0] && resp.result[0].status === "Success") {
        dispatch({
          type: "APPROVE_TIMECARD_SUCCESS",
          payload: resp,
        });
      } else {
        dispatch({
          type: "APPROVE_TIMECARD_FAIL",
          payload:
            resp.result[0].description || "Error While Approving Timecard.",
        });
      }
    } else {
      dispatch({
        type: "APPROVE_TIMECARD_FAIL",
        payload: "Error While Approving Timecard.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Approving Timecard.";
    dispatch({
      type: "APPROVE_TIMECARD_FAIL",
      payload: error,
    });
  }
};
//----------------------****Add Timecard Attachments*****----------------------
export const addAttachment = (data) => async (dispatch) => {
  dispatch({
    type: "ADD_TIMECARD_ATTACHMENTS_INIT",
  });
  const url = "/DPFAPI/TimecardRequest";
  let obj = {
    actionType: "AddAttachment",
    ...data,
  };

  try {
    let result = await axiosDPFAPIClient.post(url, obj);
    let resp = (result && result.data && result.data.TimecardResponse) || "";
    if (resp && resp.result.length > 0) {
      if (resp.result[0] && resp.result[0].status === "Success") {
        dispatch({
          type: "ADD_TIMECARD_ATTACHMENTS_SUCCESS",
          payload: resp,
        });
      } else {
        dispatch({
          type: "ADD_TIMECARD_ATTACHMENTS_FAIL",
          payload:
            resp.result[0].description ||
            "Error While Adding Timecard Attachments.",
        });
      }
    } else {
      dispatch({
        type: "ADD_TIMECARD_ATTACHMENTS_FAIL",
        payload: "Error While Adding Timecard Attachments.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Adding Timecard Attachments.";
    dispatch({
      type: "ADD_TIMECARD_ATTACHMENTS_FAIL",
      payload: error,
    });
  }
};
//----------------------****Add Comment *****------------------
export const addComment = (comment) => async (dispatch) => {
  dispatch({
    type: "ADD_TIMECARD_COMMENT_INIT",
  });
  const url = "/DPFAPI/TimecardRequest";
  let data = {
    actionType: "AddComment",
    ...comment,
  };

  try {
    let result = await axiosDPFAPIClient.post(url, data);
    let resp = (result && result.data && result.data.TimecardResponse) || "";
    if (resp && resp.result.length > 0) {
      if (resp.result[0] && resp.result[0].status === "Success") {
        dispatch({
          type: "ADD_TIMECARD_COMMENT_SUCCESS",
          payload: resp,
        });
      } else {
        dispatch({
          type: "ADD_TIMECARD_COMMENT_FAIL",
          payload: resp.result[0].description || "Error While Adding Comment.",
        });
      }
    } else {
      dispatch({
        type: "ADD_TIMECARD_COMMENT_FAIL",
        payload: "Error While Adding Comment.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Adding Comment.";
    dispatch({
      type: "ADD_TIMECARD_COMMENT_FAIL",
      payload: error,
    });
  }
};
//----------------------****Get Timecard Attachments List *****-----------------
export const getAttachmentsList = (selectedGuid) => async (dispatch) => {
  dispatch({
    type: "GET_TIMECARD_ATTACHMENTS_LIST_INIT",
  });
  const url = `/DPFAPI/TimecardRequest?actionType=GetAttachmentsList&guid=${selectedGuid}`;

  try {
    let result = await axiosDPFAPIClient.get(url);
    let resp = (result && result.data && result.data.TimecardResponse) || "";
    if (resp && resp.result.length > 0) {
      if (resp.result[0] && resp.result[0].status === "Success") {
        dispatch({
          type: "GET_TIMECARD_ATTACHMENTS_LIST_SUCCESS",
          payload: resp,
        });
      } else {
        dispatch({
          type: "GET_TIMECARD_ATTACHMENTS_LIST_FAIL",
          payload:
            resp.result[0].description ||
            "Error While Getting Timecard Attachment List.",
        });
      }
    } else {
      dispatch({
        type: "GET_TIMECARD_ATTACHMENTS_LIST_FAIL",
        payload: "Error While Getting Timecard Attachment List.",
      });
    }
  } catch (err) {
    const error =
      err.message || "Error While Getting Timecard Attachment List.";
    dispatch({
      type: "GET_TIMECARD_ATTACHMENTS_LIST_FAIL",
      payload: error,
    });
  }
};
//----------------------****Get Employee List *****-----------------------------
export const getEmployeeList = () => async (dispatch) => {
  dispatch({
    type: "GET_EMPLOYEE_LIST_INIT",
  });
  const url = `/DPFAPI/TimecardRequest?actionType=GetEmployeeList`;

  try {
    let result = await axiosDPFAPIClient.get(url);
    let resp = (result && result.data && result.data.TimecardResponse) || "";
    if (resp && resp.result.length > 0) {
      if (resp.result[0] && resp.result[0].status === "Success") {
        dispatch({
          type: "GET_EMPLOYEE_LIST_SUCCESS",
          payload: resp,
        });
      } else {
        dispatch({
          type: "GET_EMPLOYEE_LIST_FAIL",
          payload:
            resp.result[0].description || "Error While Getting Employee List.",
        });
      }
    } else {
      dispatch({
        type: "GET_EMPLOYEE_LIST_FAIL",
        payload: "Error While Getting Employee List.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Getting Employee List.";
    dispatch({
      type: "GET_EMPLOYEE_LIST_FAIL",
      payload: error,
    });
  }
};
//----------------------****Copy Last Weeks Times *****------------------
export const copyLastWeeksTimes = (obj) => async (dispatch) => {
  dispatch({
    type: "COPY_LAST_WEEKS_TIMES_INIT",
  });
  const url = "/DPFAPI/TimecardRequest";
  let data = {
    actionType: "CopyLastWeeksTimes",
    ...obj,
  };

  try {
    let result = await axiosDPFAPIClient.post(url, data);
    let resp = (result && result.data && result.data.TimecardResponse) || "";
    if (resp && resp.result.length > 0) {
      if (resp.result[0] && resp.result[0].status === "Success") {
        dispatch({
          type: "COPY_LAST_WEEKS_TIMES_SUCCESS",
          payload: resp,
        });
      } else {
        dispatch({
          type: "COPY_LAST_WEEKS_TIMES_FAIL",
          payload:
            resp.result[0].description ||
            "Error While Copying Last Weeks Times.",
        });
      }
    } else {
      dispatch({
        type: "COPY_LAST_WEEKS_TIMES_FAIL",
        payload: "Error While Copying Last Weeks Times.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Copying Last Weeks Times.";
    dispatch({
      type: "COPY_LAST_WEEKS_TIMES_FAIL",
      payload: error,
    });
  }
};
//----------------------****Regenerate Timecard *****------------------
export const regenerateTimecard = (selectedGuid, selectAllParams) => async (dispatch) => {
  dispatch({
    type: "REGENERATE_TIMECARD_INIT",
  });
  const url = "/DPFAPI/TimecardRequest";
  let data = {
    actionType: "RegenerateTimecard",
  };

  if (selectAllParams?.allSelected) {
    data.selectAll = "Y";
    data.batchList = selectAllParams.selectedBatchNos;
    data.teamTimecards = selectAllParams.teamTimecard;
    data.timecardType = selectAllParams.tcType;
    data.search = selectAllParams.timecardListSearch;
  } else {
    data.guid = selectedGuid;
  }

  try {
    let result = await axiosDPFAPIClient.post(url, data);
    let resp = (result && result.data && result.data.TimecardResponse) || "";
    if (resp && resp.result.length > 0) {
      if (resp.result[0] && resp.result[0].status === "Success") {
        dispatch({
          type: "REGENERATE_TIMECARD_SUCCESS",
          payload: resp,
        });
      } else {
        dispatch({
          type: "REGENERATE_TIMECARD_FAIL",
          payload:
            resp.result[0].description || "Error While Regenerating Timecard.",
        });
      }
    } else {
      dispatch({
        type: "REGENERATE_TIMECARD_FAIL",
        payload: "Error While Regenerating Timecard.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Regenerating Timecard.";
    dispatch({
      type: "REGENERATE_TIMECARD_FAIL",
      payload: error,
    });
  }
};
//----------------------****Send Reports *****------------------
export const sendReports = (batchNo) => async (dispatch) => {
  dispatch({
    type: "SEND_REPORTS_INIT",
  });
  const url = "/DPFAPI/TimecardRequest";
  let data = {
    actionType: "SendReports",
    batchNo,
  };

  try {
    let result = await axiosDPFAPIClient.post(url, data);
    let resp = (result && result.data && result.data.TimecardResponse) || "";
    if (resp && resp.result.length > 0) {
      if (resp.result[0] && resp.result[0].status === "Success") {
        dispatch({
          type: "SEND_REPORTS_SUCCESS",
          payload: resp,
        });
      } else {
        dispatch({
          type: "SEND_REPORTS_FAIL",
          payload: resp.result[0].description || "Error While Sending Report.",
        });
      }
    } else {
      dispatch({
        type: "SEND_REPORTS_FAIL",
        payload: "Error While Sending Report.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Sending Report.";
    dispatch({
      type: "SEND_REPORTS_FAIL",
      payload: error,
    });
  }
};
//----------------------****Lock and Invoice *****------------------
export const lockAndInvoice = (batchNo) => async (dispatch) => {
  dispatch({
    type: "LOCK_AND_INVOICE_INIT",
  });
  const url = "/DPFAPI/TimecardRequest";
  let data = {
    actionType: "LockAndInvoice",
    batchNo,
  };

  try {
    let result = await axiosDPFAPIClient.post(url, data);
    let resp = (result && result.data && result.data.TimecardResponse) || "";
    if (resp && resp.result.length > 0) {
      if (resp.result[0] && resp.result[0].status === "Success") {
        dispatch({
          type: "LOCK_AND_INVOICE_SUCCESS",
          payload: resp,
        });
      } else {
        dispatch({
          type: "LOCK_AND_INVOICE_FAIL",
          payload:
            resp.result[0].description || "Error While Locking and Invoice.",
        });
      }
    } else {
      dispatch({
        type: "LOCK_AND_INVOICE_FAIL",
        payload: "Error While Locking and Invoice.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Locking and Invoice.";
    dispatch({
      type: "LOCK_AND_INVOICE_FAIL",
      payload: error,
    });
  }
};
//----------------------****Create EFT File *****------------------
export const createEFTFile = (batchNo) => async (dispatch) => {
  dispatch({
    type: "CREATE_EFT_FILE_INIT",
  });
  const url = "/DPFAPI/TimecardRequest";
  let data = {
    actionType: "CreateEFTFile",
    batchNo,
  };

  try {
    let result = await axiosDPFAPIClient.post(url, data);
    let resp = (result && result.data && result.data.TimecardResponse) || "";
    if (resp && resp.result.length > 0) {
      if (resp.result[0] && resp.result[0].status === "Success") {
        dispatch({
          type: "CREATE_EFT_FILE_SUCCESS",
          payload: resp,
        });
      } else {
        dispatch({
          type: "CREATE_EFT_FILE_FAIL",
          payload:
            resp.result[0].description || "Error While Creating EFT file.",
        });
      }
    } else {
      dispatch({
        type: "CREATE_EFT_FILE_FAIL",
        payload: "Error While Creating EFT file.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Creating EFT file.";
    dispatch({
      type: "CREATE_EFT_FILE_FAIL",
      payload: error,
    });
  }
};
//----------------------****Send Pays Slips *****------------------
export const sendPayslips = (batchNo) => async (dispatch) => {
  dispatch({
    type: "SEND_PAY_SLIPS_INIT",
  });
  const url = "/DPFAPI/TimecardRequest";
  let data = {
    actionType: "SendPayslips",
    batchNo,
  };

  try {
    let result = await axiosDPFAPIClient.post(url, data);
    let resp = (result && result.data && result.data.TimecardResponse) || "";
    if (resp && resp.result.length > 0) {
      if (resp.result[0] && resp.result[0].status === "Success") {
        dispatch({
          type: "SEND_PAY_SLIPS_SUCCESS",
          payload: resp,
        });
      } else {
        dispatch({
          type: "SEND_PAY_SLIPS_FAIL",
          payload:
            resp.result[0].description || "Error While Sending Pays Slips.",
        });
      }
    } else {
      dispatch({
        type: "SEND_PAY_SLIPS_FAIL",
        payload: "Error While Sending Pays Slips.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Sending Pays Slips.";
    dispatch({
      type: "SEND_PAY_SLIPS_FAIL",
      payload: error,
    });
  }
};
//----------------------****Post Data *****------------------
export const postData = (batchNo) => async (dispatch) => {
  dispatch({
    type: "POST_DATA_INIT",
  });
  const url = "/DPFAPI/TimecardRequest";
  let data = {
    actionType: "PostData",
    batchNo,
  };

  try {
    let result = await axiosDPFAPIClient.post(url, data);
    let resp = (result && result.data && result.data.TimecardResponse) || "";
    if (resp && resp.result.length > 0) {
      if (resp.result[0] && resp.result[0].status === "Success") {
        dispatch({
          type: "POST_DATA_SUCCESS",
          payload: resp,
        });
      } else {
        dispatch({
          type: "POST_DATA_FAIL",
          payload: resp.result[0].description || "Error While Posting Data.",
        });
      }
    } else {
      dispatch({
        type: "POST_DATA_FAIL",
        payload: "Error While Posting Data.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Posting Data.";
    dispatch({
      type: "POST_DATA_FAIL",
      payload: error,
    });
  }
};
//----------------------****Resend STP File *****------------------
export const resendSTPFile = (batchNo, amendment) => async (dispatch) => {
  dispatch({
    type: "RESEND_STP_FILE_INIT",
  });
  const url = "/DPFAPI/TimecardRequest";
  let data = {
    actionType: "ResendSTPFile",
    batchNo,
    amendment,
  };

  try {
    let result = await axiosDPFAPIClient.post(url, data);
    let resp = (result && result.data && result.data.TimecardResponse) || "";
    if (resp && resp.result.length > 0) {
      if (resp.result[0] && resp.result[0].status === "Success") {
        dispatch({
          type: "RESEND_STP_FILE_SUCCESS",
          payload: resp,
        });
      } else {
        dispatch({
          type: "RESEND_STP_FILE_FAIL",
          payload:
            resp.result[0].description || "Error While Resending STP File.",
        });
      }
    } else {
      dispatch({
        type: "RESEND_STP_FILE_FAIL",
        payload: "Error While Resending STP File.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Resending STP File.";
    dispatch({
      type: "RESEND_STP_FILE_FAIL",
      payload: error,
    });
  }
};
//----------------------****Send Year End STP File *****------------------
export const sendYearEndSTPFile = (amendment) => async (dispatch) => {
  dispatch({
    type: "SEND_YEAR_END_STP_FILE_INIT",
  });
  const url = "/DPFAPI/TimecardRequest";
  let data = {
    actionType: "SendYearEndSTPFile",
    amendment,
  };

  try {
    let result = await axiosDPFAPIClient.post(url, data);
    let resp = (result && result.data && result.data.TimecardResponse) || "";
    if (resp && resp.result.length > 0) {
      if (resp.result[0] && resp.result[0].status === "Success") {
        dispatch({
          type: "SEND_YEAR_END_STP_FILE_SUCCESS",
          payload: resp,
        });
      } else {
        dispatch({
          type: "SEND_YEAR_END_STP_FILE_FAIL",
          payload:
            resp.result[0].description ||
            "Error While Sending Year End STP File.",
        });
      }
    } else {
      dispatch({
        type: "SEND_YEAR_END_STP_FILE_FAIL",
        payload: "Error While Sending Year End STP File.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Sending Year End STP File.";
    dispatch({
      type: "SEND_YEAR_END_STP_FILE_FAIL",
      payload: error,
    });
  }
};
//----------------------****Move Batch *****------------------
export const moveBatch = (tranData) => async (dispatch) => {
  dispatch({
    type: "MOVE_TIMECARD_BATCH_INIT",
  });
  const url = "/DPFAPI/TimecardRequest";
  let data = {
    actionType: "MoveBatch",
    ...tranData,
  };

  try {
    let result = await axiosDPFAPIClient.post(url, data);
    let resp = (result && result.data && result.data.TimecardResponse) || "";
    if (resp && resp.result.length > 0) {
      if (resp.result[0] && resp.result[0].status === "Success") {
        dispatch({
          type: "MOVE_TIMECARD_BATCH_SUCCESS",
          payload: resp,
        });
      } else {
        dispatch({
          type: "MOVE_TIMECARD_BATCH_FAIL",
          payload: resp.result[0].description || "Error While Moving Batch.",
        });
      }
    } else {
      dispatch({
        type: "MOVE_TIMECARD_BATCH_FAIL",
        payload: "Error While Moving Batch.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Moving Batch.";
    dispatch({
      type: "MOVE_TIMECARD_BATCH_FAIL",
      payload: error,
    });
  }
};
//----------------------****Export Timecard *****------------------
export const exportTimecard = (tranData) => async (dispatch) => {
  dispatch({
    type: "EXPORT_TIMECARD_INIT",
  });
  const url = "/DPFAPI/TimecardRequest";
  let data = {
    actionType: "ExportTimecard",
    ...tranData,
  };

  try {
    let result = await axiosDPFAPIClient.post(url, data);
    let resp = (result && result.data && result.data.TimecardResponse) || "";
    if (resp && resp.result.length > 0) {
      if (resp.result[0] && resp.result[0].status === "Success") {
        dispatch({
          type: "EXPORT_TIMECARD_SUCCESS",
          payload: resp,
        });
      } else {
        dispatch({
          type: "EXPORT_TIMECARD_FAIL",
          payload:
            resp.result[0].description || "Error While Exporting Timecard.",
        });
      }
    } else {
      dispatch({
        type: "EXPORT_TIMECARD_FAIL",
        payload: "Error While Exporting Timecard.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Exporting Timecard.";
    dispatch({
      type: "EXPORT_TIMECARD_FAIL",
      payload: error,
    });
  }
};
//----------------------****Import Timecard *****------------------
export const importTimecard = (excelData) => async (dispatch) => {
  dispatch({
    type: "IMPORT_TIMECARD_INIT",
  });
  const url = "/DPFAPI/TimecardRequest";
  let data = {
    actionType: "ImportTimecard",
    excelData,
  };

  try {
    let result = await axiosDPFAPIClient.post(url, data);
    let resp = (result && result.data && result.data.TimecardResponse) || "";
    if (resp && resp.result.length > 0) {
      if (resp.result[0] && resp.result[0].status === "Success") {
        dispatch({
          type: "IMPORT_TIMECARD_SUCCESS",
          payload: resp,
        });
      } else {
        dispatch({
          type: "IMPORT_TIMECARD_FAIL",
          payload:
            resp.result[0].description || "Error While Importing Timecard.",
        });
      }
    } else {
      dispatch({
        type: "IMPORT_TIMECARD_FAIL",
        payload: "Error While Importing Timecard.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Importing Timecard.";
    dispatch({
      type: "IMPORT_TIMECARD_FAIL",
      payload: error,
    });
  }
};
//----------------------****Export Distribution *****------------------
export const exportDistribution = (tranData) => async (dispatch) => {
  dispatch({
    type: "EXPORT_DISTRIBUTION_INIT",
  });
  const url = "/DPFAPI/TimecardRequest";
  let data = {
    actionType: "ExportDistribution",
    ...tranData,
  };

  try {
    let result = await axiosDPFAPIClient.post(url, data);
    let resp = (result && result.data && result.data.TimecardResponse) || "";
    if (resp && resp.result.length > 0) {
      if (resp.result[0] && resp.result[0].status === "Success") {
        dispatch({
          type: "EXPORT_DISTRIBUTION_SUCCESS",
          payload: resp,
        });
      } else {
        dispatch({
          type: "EXPORT_DISTRIBUTION_FAIL",
          payload:
            resp.result[0].description || "Error While Exporting Distribution.",
        });
      }
    } else {
      dispatch({
        type: "EXPORT_DISTRIBUTION_FAIL",
        payload: "Error While Exporting Distribution.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Exporting Distribution.";
    dispatch({
      type: "EXPORT_DISTRIBUTION_FAIL",
      payload: error,
    });
  }
};
//----------------------****Export TPH Payroll *****------------------
export const exportTPHPayroll = (tranData) => async (dispatch) => {
  dispatch({
    type: "EXPORT_TPH_PAYROLL_INIT",
  });
  const url = "/DPFAPI/TimecardRequest";
  let data = {
    actionType: "ExportTPHPayroll",
    ...tranData,
  };

  try {
    let result = await axiosDPFAPIClient.post(url, data);
    let resp = (result && result.data && result.data.TimecardResponse) || "";
    if (resp && resp.result.length > 0) {
      if (resp.result[0] && resp.result[0].status === "Success") {
        dispatch({
          type: "EXPORT_TPH_PAYROLL_SUCCESS",
          payload: resp,
        });
      } else {
        dispatch({
          type: "EXPORT_TPH_PAYROLL_FAIL",
          payload:
            resp.result[0].description || "Error While Exporting TPH Payroll.",
        });
      }
    } else {
      dispatch({
        type: "EXPORT_TPH_PAYROLL_FAIL",
        payload: "Error While Exporting TPH Payroll.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Exporting TPH Payroll.";
    dispatch({
      type: "EXPORT_TPH_PAYROLL_FAIL",
      payload: error,
    });
  }
};
//----------------------****Refresh Employees *****------------------
export const refreshEmployees = () => async (dispatch) => {
  dispatch({
    type: "REFRESH_EMPLOYEES_INIT",
  });
  const url = "/DPFAPI/TimecardRequest?actionType=RefreshEmployees";

  try {
    let result = await axiosDPFAPIClient.get(url);
    let resp = (result && result.data && result.data.TimecardResponse) || "";
    if (resp && resp.result.length > 0) {
      if (resp.result[0] && resp.result[0].status === "Success") {
        dispatch({
          type: "REFRESH_EMPLOYEES_SUCCESS",
          payload: resp,
        });
      } else {
        dispatch({
          type: "REFRESH_EMPLOYEES_FAIL",
          payload:
            resp.result[0].description ||
            "Error While Refreshing The Employees.",
        });
      }
    } else {
      dispatch({
        type: "REFRESH_EMPLOYEES_FAIL",
        payload: "Error While Refreshing The Employees.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Refreshing The Employees.";
    dispatch({
      type: "REFRESH_EMPLOYEES_FAIL",
      payload: error,
    });
  }
};
//----------------------****Get Timecard Attachment *****----------------------
export const getAttachment = (data) => async (dispatch) => {
  dispatch({
    type: "GET_TIMECARD_ATTACHMENT_INIT",
  });
  const url = "/DPFAPI/TimecardRequest";
  let obj = {
    actionType: "GetAttachment",
    recordID: data,
  };

  try {
    let result = await axiosDPFAPIClient.post(url, obj);
    let resp = (result && result.data && result.data.TimecardResponse) || "";
    if (resp && resp.result.length > 0) {
      if (resp.result[0] && resp.result[0].status === "Success") {
        dispatch({
          type: "GET_TIMECARD_ATTACHMENT_SUCCESS",
          payload: resp,
        });
      } else {
        dispatch({
          type: "GET_TIMECARD_ATTACHMENT_FAIL",
          payload:
            resp.result[0].description ||
            "Error While fetching Timecard Attachment!.",
        });
      }
    } else {
      dispatch({
        type: "GET_TIMECARD_ATTACHMENT_FAIL",
        payload: "Error While, fetching Timecard Attachment.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While fetching Timecard Attachment.";
    dispatch({
      type: "GET_TIMECARD_ATTACHMENT_FAIL",
      payload: error,
    });
  }
};

//----------------------****Delete Attachment *****----------------------------
export const deleteAttachment = (recordID) => async (dispatch) => {
  dispatch({
    type: "DELETE_TIMECARD_ATTACHMENT_INIT",
  });
  const url = "/DPFAPI/TimecardRequest";
  let data = {
    actionType: "DeleteAttachment",
    recordID,
  };

  try {
    let result = await axiosDPFAPIClient.post(url, data);
    let resp = (result && result.data && result.data.TimecardResponse) || "";
    if (resp && resp.result.length > 0) {
      if (resp.result[0] && resp.result[0].status === "Success") {
        dispatch({
          type: "DELETE_TIMECARD_ATTACHMENT_SUCCESS",
          payload: resp,
        });
      } else {
        dispatch({
          type: "DELETE_TIMECARD_ATTACHMENT_FAIL",
          payload:
            resp.result[0].description || "Error While Deleting Attachment.",
        });
      }
    } else {
      dispatch({
        type: "DELETE_TIMECARD_ATTACHMENT_FAIL",
        payload: "Error While Deleting Attachment.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Deleting Attachment.";
    dispatch({
      type: "DELETE_TIMECARD_ATTACHMENT_FAIL",
      payload: error,
    });
  }
};

//----------------------****Tax Balance *****----------------------------
export const taxBalance = (data) => async (dispatch) => {
  dispatch({
    type: "TAX_BALANCE_INIT",
  });
  const url = "/DPFAPI/TimecardRequest";
  let data = {
    actionType: "TaxBalance",
  };

  try {
    let result = await axiosDPFAPIClient.post(url, data);
    let resp = (result && result.data && result.data.TimecardResponse) || "";
    if (resp && resp.result.length > 0) {
      if (resp.result[0] && resp.result[0].status === "Success") {
        dispatch({
          type: "TAX_BALANCE_SUCCESS",
          payload: resp,
        });
      } else {
        dispatch({
          type: "TAX_BALANCE_FAIL",
          payload: resp.result[0].description || "Error in tax balance",
        });
      }
    } else {
      dispatch({
        type: "TAX_BALANCE_FAIL",
        payload: "Error in tax balance",
      });
    }
  } catch (err) {
    const error = err.message || "Error in tax balance";
    dispatch({
      type: "TAX_BALANCE_FAIL",
      payload: error,
    });
  }
};

//----------------------****Calculate Day*****----------------------
export const calculateDay = (data) => async (dispatch) => {
  dispatch({
    type: "CALCULATE_DAY_INIT",
  });
  const url = "/DPFAPI/TimecardRequest";
  let obj = {
    actionType: "CalculateDay",
    ...data,
  };

  try {
    let result = await axiosDPFAPIClient.post(url, obj);
    let resp = (result && result.data && result.data.TimecardResponse) || "";
    if (resp && resp.result.length > 0) {
      if (resp.result[0] && resp.result[0].status === "Success") {
        dispatch({
          type: "CALCULATE_DAY_SUCCESS",
          payload: resp,
        });
      } else {
        dispatch({
          type: "CALCULATE_DAY_FAIL",
          payload: resp.result[0].description || "Error While Calculating Day.",
        });
      }
    } else {
      dispatch({
        type: "CALCULATE_DAY_FAIL",
        payload: "Error While Calculating Day.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Calculating Day.";
    dispatch({
      type: "CALCULATE_DAY_FAIL",
      payload: error,
    });
  }
};

//----------------------****ModifyTimeCard  Timecard *****----------------------------
export const modifyTimecard = (selectedGuid) => async (dispatch) => {
  dispatch({
    type: "MODIFY_TIMECARD_INIT",
  });
  const url = "/DPFAPI/TimecardRequest";
  let data = {
    actionType: "ModifyTimecard",
    guid: selectedGuid,
    // modify: "N",
  };

  try {
    let response = await axiosDPFAPIClient.post(url, data);
    let modifyTimecardResp =
      (response && response.data && response.data.TimecardResponse) || "";
    if (modifyTimecardResp && modifyTimecardResp.result.length > 0) {
      if (
        modifyTimecardResp.result[0] &&
        modifyTimecardResp.result[0].status === "Failed"
      ) {
        dispatch({
          type: "MODIFY_TIMECARD_FAIL",
          payload:
            modifyTimecardResp.result[0].description ||
            "Error While ModifyTimeCard TIMECARD.",
        });
      }
      if (
        modifyTimecardResp.result[0] &&
        modifyTimecardResp.result[0].status === "Success"
      ) {
        dispatch({
          type: "MODIFY_TIMECARD_SUCCESS",
          payload: modifyTimecardResp,
        });
      }
    } else {
      dispatch({
        type: "MODIFY_TIMECARD_FAIL",
        payload: "Error While ModifyTimeCard TIMECARD.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While ModifyTimeCard TIMECARD.";
    dispatch({
      type: "MODIFY_TIMECARD_FAIL",
      payload: error,
    });
  }
};
//----------------------****Clear Timecard states****---------------------------
export function clearTimecardStates() {
  return async (dispatch) => {
    dispatch({
      type: "CLEAR_TIMECARD_STATES",
    });
  };
}

//----------------------**** MoveToNextApprover*****------------------------------
export const moveToNextApprover = (data) => async (dispatch) => {
  dispatch({
    type: "TIMECARD_MOVE_TO_NEXT_APPROVER_INIT",
  });
  const url = "/DPFAPI/TimecardRequest";
  let obj = {
    actionType: "MoveToNextApprover",
    ...data,
  };

  try {
    let response = await axiosDPFAPIClient.post(url, obj);
    let resp =
      (response && response.data && response.data.TimecardResponse) || "";
    if (resp && resp.result.length > 0) {
      if (resp.result[0] && resp.result[0].status === "Success") {
        dispatch({
          type: "TIMECARD_MOVE_TO_NEXT_APPROVER_SUCCESS",
          payload: resp,
        });
      } else {
        dispatch({
          type: "TIMECARD_MOVE_TO_NEXT_APPROVER_FAIL",
          payload:
            resp.result[0].description ||
            "EError While Moving To Next Approver.",
        });
      }
    } else {
      dispatch({
        type: "TIMECARD_MOVE_TO_NEXT_APPROVER_FAIL",
        payload: "Error While Moving To Next Approver.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Moving To Next Approver.";
    dispatch({
      type: "TIMECARD_MOVE_TO_NEXT_APPROVER_FAIL",
      payload: error,
    });
  }
};

//----------------------**** MoveToPreviousApprover*****------------------------------
export const moveToPrevApprover = (data) => async (dispatch) => {
  dispatch({
    type: "TIMECARD_MOVE_TO_PREV_APPROVER_INIT",
  });
  const url = "/DPFAPI/TimecardRequest";
  let obj = {
    actionType: "MoveToPrevApprover",
    ...data,
  };

  try {
    let response = await axiosDPFAPIClient.post(url, obj);
    let resp =
      (response && response.data && response.data.TimecardResponse) || "";
    if (resp && resp.result.length > 0) {
      if (resp.result[0] && resp.result[0].status === "Success") {
        dispatch({
          type: "TIMECARD_MOVE_TO_PREV_APPROVER_SUCCESS",
          payload: resp,
        });
      } else {
        dispatch({
          type: "TIMECARD_MOVE_TO_PREV_APPROVER_FAIL",
          payload:
            resp.result[0].description ||
            "EError While Moving To Prev Approver.",
        });
      }
    } else {
      dispatch({
        type: "TIMECARD_MOVE_TO_PREV_APPROVER_FAIL",
        payload: "Error While Moving To Prev Approver.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Moving To Prev Approver.";
    dispatch({
      type: "TIMECARD_MOVE_TO_PREV_APPROVER_FAIL",
      payload: error,
    });
  }
};

//----------------------**** Clear Employee *****------------------------------
export const clearEmployee = (guid) => async (dispatch) => {
  dispatch({
    type: "CLEAR_EMPLOYEE_INIT",
  });
  const url = "/DPFAPI/TimecardRequest";
  let obj = {
    actionType: "ClearEmployee",
    guid,
  };

  try {
    let response = await axiosDPFAPIClient.post(url, obj);
    let resp =
      (response && response.data && response.data.TimecardResponse) || "";
    if (resp && resp.result.length > 0) {
      if (resp.result[0] && resp.result[0].status === "Success") {
        dispatch({
          type: "CLEAR_EMPLOYEE_SUCCESS",
          payload: resp,
        });
      } else {
        dispatch({
          type: "CLEAR_EMPLOYEE_FAIL",
          payload:
            resp.result[0].description ||
            "Error While While Clearing the Employee.",
        });
      }
    } else {
      dispatch({
        type: "CLEAR_EMPLOYEE_FAIL",
        payload: "Error While Clearing the Employee.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Clearing the Employee.";
    dispatch({
      type: "CLEAR_EMPLOYEE_FAIL",
      payload: error,
    });
  }
};

//----------------------****Send Email Approver*****------------------------------
export const resendEmail = (selectedGuid, selectAllParams) => async (dispatch) => {
  dispatch({
    type: "SEND_EMAIL_APPROVER_INIT",
  });
  const url = "/DPFAPI/TimecardRequest";
  let obj = {
    actionType: "SendEmail",
  };
  if (selectAllParams?.allSelected) {
    obj.selectAll = "Y";
    obj.batchList = selectAllParams.selectedBatchNos;
    obj.teamTimecards = selectAllParams.teamTimecard;
    obj.timecardType = selectAllParams.tcType;
    obj.search = selectAllParams.timecardListSearch;
  } else {
    obj.guid = selectedGuid;
  }

  try {
    let result = await axiosDPFAPIClient.post(url, obj);
    let resp = (result && result.data && result.data.TimecardResponse) || "";
    if (resp && resp.result.length > 0) {
      if (resp.result[0] && resp.result[0].status === "Success") {
        dispatch({
          type: "SEND_EMAIL_APPROVER_SUCCESS_TC",
          payload: resp.result[0].description,
        });
      } else {
        dispatch({
          type: "SEND_EMAIL_APPROVER_FAIL_TC",
          payload:
            resp.result[0].description ||
            "Error While Resending Email to User.",
        });
      }
    } else {
      dispatch({
        type: "SEND_EMAIL_APPROVER_FAIL_TC",
        payload: "Error While Resending Email to User.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Resending Email to User.";
    dispatch({
      type: "SEND_EMAIL_APPROVER_FAIL_TC",
      payload: error,
    });
  }
};

export const transferTimecards = (payload) => async (dispatch) => {
  dispatch({
    type: "TRANSFER_TIMECARD_INIT",
  });
  const url = "/DPFAPI/TimecardRequest";
  let obj = {
    actionType: "TransferTimecard",
    ...payload,
  };

  try {
    let result = await axiosDPFAPIClient.post(url, obj);
    let resp = (result && result.data && result.data.TimecardResponse) || "";
    if (resp && resp.result.length > 0) {
      if (resp.result[0] && resp.result[0].status === "Success") {
        dispatch({
          type: "TRANSFER_TIMECARD_SUCCESS",
          payload: resp.result[0].description,
        });
      } else {
        dispatch({
          type: "TRANSFER_TIMECARD_FAIL",
          payload:
            resp.result[0].description ||
            "Error While Resending Email to User.",
        });
      }
    } else {
      dispatch({
        type: "TRANSFER_TIMECARD_FAIL",
        payload: "Error While Resending Email to User.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Resending Email to User.";
    dispatch({
      type: "TRANSFER_TIMECARD_FAIL",
      payload: error,
    });
  }
};

/**
 * Represents a timecard entry
 *
 * @typedef {Object} TimeCard
 * @property {string} guid - A unique identifier for the entry.
 * @property {string} employeeCode - The employee's code (if applicable).
 * @property {string} department - The department associated with the entry.
 * @property {string} position - The employee's position.
 * @property {string} approverGroup - The group responsible for approving this entry.
 * @property {string} totalHours - The total hours worked for this entry.
 * @property {number} weekEndingDate - Unix timestamp for the week ending date.
 * @property {Array<DailyTime>} dailyTimes - An array of daily time details.
 * @property {Array<Attachment>} attachments - An array of attached files.
 * @property {Array<AdvancedList>} advancedList - An array of additional custom data.
 */

/**
 * Represents a daily time entry.
 *
 * @typedef {Object} DailyTime
* @property {string} payAs - Indicates the type of payment (e.g., "Worked").
 * @property {string} date - The date of the entry (e.g., "2024-07-01").
 * @property {string} day - The day of the week (e.g., "Monday").
 * @property {string} camCall - The duration of a camera call (e.g., "0:00").
 * @property {string} camWrap - The duration of wrapping up a camera call (e.g., "0:00").
 * @property {string|moment.Moment} travelTo - The travel start time or moment object.
 * @property {string} startWork - The start time for work (e.g., "9:00").
 * @property {string} startMB1 - The start time for the first meal break (e.g., "00:00").
 * @property {string} finishMB1 - The end time for the first meal break (e.g., "00:00").
 * @property {string} nonDeductableMB1 - Indicates whether the first meal break is non-deductible (e.g., "0").
 * @property {string} startMB2 - The start time for the second meal break (e.g., "00:00").
 * @property {string} finishMB2 - The end time for the second meal break (e.g., "00:00").
 * @property {string} nonDeductableMB2 - Indicates whether the second meal break is non-deductible (e.g., "0").
 * @property {string} startMB3 - The start time for the third meal break (e.g., "00:00").
 * @property {string} finishMB3 - The end time for the third meal break (e.g., "00:00").
 * @property {string} nonDeductableMB3 - Indicates whether the third meal break is non-deductible (e.g., "0").
 * @property {string} totalMB - The total duration of meal breaks (e.g., "0:45").
 * @property {string} finishWork - The end time for work (e.g., "19:15").
 * @property {string|moment.Moment} travelFrom - The travel end time or moment object.
 * @property {string} totalHours - The total hours worked (e.g., "01:00").
 * @property {string} pmtFlags - Payment-related flags (e.g., "CON").
 * @property {string} notes - Additional notes for the entry.
 * @property {string} internalNotes - Internal notes (if any).
 * @property {number} rate - The hourly rate (e.g., 0).
 * @property {string} template - The template used (if applicable).
 * @property {Array<Object>} timeCalc - An array of additional time calculation details?
 * @property {number} lineNo - Line number (if part of a larger dataset).
 * @property {Array<AdvancedList>} advancedList - An array of additional custom data for this entry.
 */

/**
 * Represents an attachment.
 *
 * @typedef {Object} Attachment
 * @property {string} fileName - The name of the attached file.
 * @property {number} recordID - A unique identifier for the attachment.
 * @property {string} fileType - The type of the attached file (e.g., "application/pdf").
 * @property {number} fileSize - The size of the attached file in bytes.
 * @property {number} preview - Indicates whether a preview is available (0 or 1).
 */

/**
 * Represents additional custom data.
 *
 * @typedef {Object} AdvancedList
 * @property {string} category - The category of the custom data.
 * @property {string} description - A description of the custom data.
 * @property {string} value - The value associated with the custom data.
 * @property {string} valueType - The type of the value (e.g., "Text").
 * @property {string} readOnly - Indicates whether the value is read-only ("Y" or "N").
 * @property {Array<string>} valueOptions - An array of possible value options.
 */