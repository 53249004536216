import React, { Component } from 'react'
import Modal from 'react-bootstrap/Modal'
import './Settings.css'

export default function Settings(props) {
  return (
    <>
      <Modal
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        centered
        show={props.openSettingsModal}
        onHide={props.handleCloseSettingModal}
        className='modal__setting mx-auto'
      >
        <Modal.Body>
          <div className='container-fluid p-0'>
            <div className='main_wrapper'>
              <div className='row d-flex h-100 p-0'>
                <div className='col-12 justify-content-center align-self-center'>
                  <div className='setting_form_main p-0'>
                    <div className='setting_header thead_bg'>
                      <h3 className='settings_heading'>Settings</h3>
                      <div className='settings_can-sav-btn'>
                        <button className='btn can-btn1'
                          onClick={props.handleSaveSettings}
                        >
                          <img
                            src='images/user-setup/check-white.png'
                            alt='check'
                          />
                          Save
                        </button>
                        <button
                          onClick={
                            props.handleCloseSettingModal
                          }

                          className='btn can-btn1 pl-3'
                        >
                          <img
                            src='images/user-setup/cancel-white.png'
                            alt='cancel'
                          />
                          Cancel
                        </button>
                      </div>
                    </div>
                    <div className='setting_body'>
                      <div className='hide_checks'>
                        <h2>Hide</h2>
                        <ul>
                          {
                            props.columns.map((col, i) => {
                              return <li key={i}>
                                <div className='custom-radio'>
                                  <label className='settings_check_main' htmlFor={i}>
                                    <input
                                      type='checkbox'
                                      id={i}
                                      name='hideColumns'
                                      checked={col.hide}
                                      onChange={(e) => props.handleChangeSettings(e, i)}
                                    />
                                    <span className='settings_checkmark'></span>
                                    {col.ColName}
                                  </label>
                                </div>
                              </li>
                            })
                          }
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

