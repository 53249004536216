import React, { Component, useContext } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { _customStyles } from "../../../../Constants/Constants";
import * as AccountActions from "../../../../Actions/AccountActions/AccountActions";
import DatePicker from "react-datepicker";
import {
  downloadAttachments,
  filterBox,
  handleAPIErr,
} from "../../../../Utils/Helpers";
import Reports from "../../../Modals/Accounts/Reports/Reports";
import CancelReissueModal from "../../../Modals/Accounts/BankRecModals/CancelReissue/CancelReissueModal";
import BankChequesSettings from "../../../Modals/Accounts/BankRecModals/BankChequesSettings/BankChequesSettings";
import Import from "../../../../Containers/Modals/Accounts/Import/Import";
const uuidv1 = require("uuid/v1");

class BankCheques extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      addNewReportToggle: false,
      openAccountReportModal: false,
      openCancelReissueModal: false,
      openBankChequesSettingsModal: false,
      openImportModal: false,
      type: "cheques",
      reportTypes: [],
      reportOptionVal: [{ label: "Select Report Option", value: "" }],
      reportOptions: [],

      reportTypeValue: [{ label: "Select Report Type", value: "" }],
      reportLayouts: [],
      reportLayoutsVal: [],

      cancelReissue: [],
      advancedOptions: [],
      availableReports: [],
      clonneAdvanceOptions: [],

      menuIsOpen: false,
      bankChequeColumns: [],
      bankChequeRows: [],
      cheques: [],
      refId: [],
      newRefId: "",
      filtersArr: [
        { filter: "category", condition: "equal", value: "settings" },
        { filter: "description", condition: "equal", value: "abc" },
      ],
      fltrsColmnFirst: [
        { id: "1", filter: "category", name: "Category", checked: false },
        { id: "2", filter: "description", name: "Description", checked: false },
        { id: "3", filter: "value", name: "Value", checked: false },
      ],
      fltrsColmnSecond: [
        { id: "4", condition: "Contains", checked: false },
        { id: "5", condition: "Doesn't contain", checked: false },
        { id: "6", condition: "Equal", checked: false },
        { id: "7", condition: "Not Equal", checked: false },
        { id: "8", condition: "Starts With", checked: false },
        { id: "9", condition: "Over", checked: false },
        { id: "10", condition: "Under", checked: false },
        { id: "11", condition: "Over Equal", checked: false },
        { id: "12", condition: "Under Equal", checked: false },
      ],
      reportFile: "",
      reportName: "",
      private: false,

      formErrors: {
        reportTypesVal: "",
        reportOptionVal: "",
        reportFile: "",
        reportName: "",
      },
      pageLength: 10,
    };
  }

  componentWillReceiveProps(props) {
    let cheques = props?.cheques || [];
    let bankChequeColumns = props?.bankChequeColumns || [];
    let bankChequeRows = props?.bankChequeRows || [];
    this.setState({ cheques, bankChequeColumns, bankChequeRows });
  }
  componentDidMount() {
    filterBox("chequesDetail");
  }
  onImport = async (type, importData) => {
    this.setState({ isLoading: true });
    let bankCode = document.getElementsByClassName(
      "custon_select-selector-inner__single-value css-1uccc91-singleValue"
    )[0].innerText;
    let requestData = {
      bankCode: bankCode,
      type: type,
      import: importData,
    };
    await this.props.getImportExcel(requestData);
    if (this.props.account.getImportExcelSuccess) {
      toast.success(this.props.account.getImportExcelSuccess);
      await this.props.getCheques1();
    }
    if (this.props.account.getImportExcelError) {
      handleAPIErr(this.props.account.getImportExcelError, this.props);
    }
    this.setState({ isLoading: false });
  };
  getExportExcel = async () => {
    this.setState({ isLoading: true });
    let bankCode = document.getElementsByClassName(
      "custon_select-selector-inner__single-value css-1uccc91-singleValue"
    )[0].innerText;

    let data = {
      bankCode: bankCode,
      type: "cheques",
      guidList: this.state.newRefId,
    };
    await this.props.getExportExcel(data);
    if (this.props.account.getExportExcelSuccess) {
      toast.success(this.props.account.getExportExcelSuccess);
      let excelData = this.props.account.getExportExcel || "";

      let obj = {
        contentType: "application/vnd.ms-excel",
        attachment: excelData || "",
      };
      downloadAttachments(obj, "chequesExport");
    }
    if (this.props.account.getExportExcelError) {
      handleAPIErr(this.props.account.getExportExcelError, this.props);
    }

    this.setState({ isLoading: false });
  };
  updateCheques = async () => {
    this.setState({ isLoading: true });

    let { cheques } = this.state;
    // let transactions = [{ transactions: transaction }];
    let bankCode = document.getElementsByClassName(
      "custon_select-selector-inner__single-value css-1uccc91-singleValue"
    )[0].innerText;

    let data = {
      bankCode: bankCode,
      cheques: cheques,
    };
    await this.props.getUpdateCheques(data);
    if (this.props.account.getUpdateChequesSuccess) {
      toast.success(this.props.account.getUpdateChequesSuccess);
    }
    if (this.props.account.getUpdateChequesError) {
      handleAPIErr(this.props.account.getUpdateChequesError, this.props);
    }
    this.setState({ isLoading: false });
  };
  getUpdateStatus = async (pre) => {
    this.setState({ isLoading: true });
    let { newRefId } = this.state;
    let idList = newRefId;

    let bankCode = document.getElementsByClassName(
      "custon_select-selector-inner__single-value css-1uccc91-singleValue"
    )[0].innerText;
    let data = {
      bankCode: bankCode,
      type: "cheques",
      presented: pre,
      idList: idList,
    };

    await this.props.getUpdateStatus(data);

    if (this.props.account.getUpdateStatusSuccess) {
      toast.success(this.props.account.getUpdateStatusSuccess);
    }
    if (this.props.account.getUpdateStatusError) {
      handleAPIErr(this.props.account.getUpdateStatusError, this.props);
    }

    this.setState({ isLoading: false });
  };
  getPrimeCancelReIssue = async (name) => {
    this.setState({ isLoading: true });

    await this.props.getPrimeCancelReIssue();
    if (this.props.account.getPrimeCancelReIssueSuccess) {
      toast.success(this.props.account.getPrimeCancelReIssueSuccess);

      let cancelReissue = this.props.account.getPrimeCancelReIssue || [];
      let advancedOptions = cancelReissue[0].advancedOptions || [];
      let availableReports = cancelReissue[0].availableReports || [];
      let reportId = cancelReissue[0].reportId;

      let reportTypeOption = [];
      availableReports.map((item) => {
        reportTypeOption.push({ label: item.reportType, value: item.areaRef });
      });
      let reportTypeValue = reportTypeOption.filter((item) => item);
      let reportLayoutsVal = availableReports[0].reportLayouts;
      let reportOption = [];

      reportLayoutsVal.map((item) => {
        reportOption.push({ label: item.layout, value: item.id });
      });
      let reportOptionVal =
        reportOption.find((item) => item.value == reportId) || "";
      advancedOptions.map((lst, i) => {
        if ((lst.type && lst.type.toLowerCase() === "list") || "droplist") {
          let valOptns = [];
          if (lst.options && lst.options.length > 0) {
            lst.options.map((o, i) => {
              valOptns.push({ label: o.value, value: o.value });
            });
          }
          lst.options = valOptns;
        }
        lst.id = uuidv1();
        lst.hide = false;
        return lst;
      });

      this.setState({
        reportTypeValue,
        reportOptionVal,
        advancedOptions,
        clonneAdvanceOptions: advancedOptions,
        reportTypes: reportTypeOption,
        reportLayouts: reportOption,
        reportLayoutsVal,
        cancelReissue,
      });
    }
    if (this.props.account.getPrimeCancelReIssueError) {
      handleAPIErr(this.props.account.getPrimeCancelReIssueError, this.props);
    }
    this.openModal(name);
    this.setState({ isLoading: false });
  };
  handleChangeGeneralLedgerList = (e, data, i, j) => {
    console.log("ok", e.target.value);
    data[j] = e.target.value;
  };
  handleCheck = (e, data, i, j) => {
    data[j] = e.target.checked ? "Y" : "N";
    // data[i] = data;
    // console.log(data, j, item);
    // if (data[j] === "1") {
    //   data = [j] = 0;
    // } else {
    //   data[i] = 1;
    // }

    return data;
  };
  handleCheckboxes = (e, data) => {
    let { refId, bankChequeRows } = this.state;

    // let bankChequeRows = JSON.parse(JSON.stringify(this.state.bankChequeRows));
    let { name, checked } = e.target;
    if (data === "allCheck" && name === "checkboxAll") {
      let multipleTransCopy = [];
      if (checked) {
        bankChequeRows.map((m) => {
          m.checked = true;
          multipleTransCopy.push(m.refId);
          return m;
        });
      } else {
        bankChequeRows.map((m) => {
          m.checked = false;
          return m;
        });
      }
      refId = [...multipleTransCopy];
    } else {
      if (checked) {
        bankChequeRows.map(async (po, i) => {
          if (data.refId === po.refId) {
            po.checked = true;
          }
          return po;
        });
        refId.push(data.refId);
      } else {
        bankChequeRows.map(async (po, i) => {
          if (data.refId === po.refId) {
            po.checked = false;
          }
          return po;
        });
        let filteredMultiRefId = refId.filter((t) => t != data.refId);
        refId = filteredMultiRefId;
      }
    }
    let newRefId = refId + [];

    this.setState({
      bankChequeRows,
      refId,
      newRefId,
    });
  };

  handleChangeSettings = (e, i) => {
    const { name, value } = e.target;
    if (name === "pageLength") {
      this.setState({ pageLength: value });
    } else {
      let { columns } = this.state;
      columns[i].hide = e.target.checked;
      this.setState({ columns });
    }
  };

  openModal = (name) => {
    if (name === "openBankChequesSettingsModal") {
      let pageLength = window.$("#chequesDetail").DataTable().page.len() || 10;
      this.setState({ pageLength });
      console.log("pageLength=>", pageLength);
    }
    this.setState({ [name]: true });
  };
  closeModal = (name) => {
    if (name === "openBankChequesSettingsModal") {
      this.handleSaveSettings();
    }
    this.setState({ [name]: false });
  };

  handleSearch = (e) => {
    let { value } = e.target;
    var table = window.$("#chequesDetail").DataTable().search(value).draw();
  };

  handleSaveSettings = () => {
    let { pageLength } = this.state;
    window.$("#chequesDetail").DataTable().page.len(Number(pageLength)).draw();
  };
  // onChangeHandlerDate = async (data, j, e) => {
  //   console.log(e);
  //   let date = new Date(e).getTime();
  //   console.log(date);

  //   data[j] = date;
  //   console.log(data);
  //   await this.updateCheques();
  //   return data;
  // };

  render() {
    let { bankChequeColumns, bankChequeRows } = this.state;
    return (
      <>
        {this.state.isLoading ? <div className="se-pre-con"></div> : ""}
        {/* Cheques */}

        <div className="forgot_form_main sup-inner-pad position-relative">
          <div className="forgot_header mt-4">
            {/* <Filter /> */}
            <div className="modal-top-header">
              <div className="row bord-btm">
                <div className="col-10 col-md-9 pl-0">
                  <h6 className="text-left def-blue">
                    <span>
                      {" "}
                      <img
                        src="images/arrow_up.png"
                        className="import_icon img-fluid pr-3 ml-3 sideBarAccord"
                        alt="arrow_up"
                        data-toggle="collapse"
                        data-target="#Cheques"
                      />{" "}
                    </span>
                    Cheques
                  </h6>
                </div>
                <div className="col-2 col-md-3 d-flex justify-content-end s-c-main">
                  <Link
                    to="#"
                    className="zom-img float-right ml-md-5 pl-2 pr-2 mr-0 more-d mt-0 icon_dots2"
                  >
                    <img
                      src="images/more.png"
                      className=" img-fluid"
                      alt="user"
                    />{" "}
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="documents_attatchments2 supplier2_sidetoggle mm-blok2">
            <div
              // onClick={() => this.openModal("openReportModal")}
              onClick={() => this.getUpdateStatus(1)}
              className="main-sec-attach main-bg"
            >
              Present
            </div>
            <div
              // onClick={() => this.openModal("openReportModal")}
              onClick={() => this.getUpdateStatus(0)}
              className="main-sec-attach main-bg"
            >
              UnPresent
            </div>
            <div
              // onClick={() => this.openModal("openReportModal")}
              onClick={() => this.getExportExcel()}
              className="main-sec-attach main-bg"
            >
              Export
            </div>
            <div
              className="main-sec-attach main-bg"
              // onClick={() => this.openModal("openAccountReportModal")}
              onClick={() => this.openModal("openImportModal")}
            >
              <span
                className="export_crd"
                data-toggle="collapse"
                data-target="#export"
              >
                {/* <span className="fa fa-angle-up float-left mr-2 sideBarAccord1"></span> */}
                Import
              </span>
            </div>
            <div
              className="main-sec-attach main-bg"
              // onClick={() => this.openModal("openAccountReportModal")}
              onClick={() =>
                this.getPrimeCancelReIssue("openCancelReissueModal")
              }
            >
              <span
                className="export_crd"
                data-toggle="collapse"
                data-target="#export"
              >
                {/* <span className="fa fa-angle-up float-left mr-2 sideBarAccord1"></span> */}
                Cancel\Re-Issue
              </span>
            </div>
          </div>

          <div className="row">
            <div className="col-xl-10 col-md-12 col-sm-12 m-auto">
              <div className="forgot_body collapse show " id="Cheques">
                {/* filter dropdown 1 */}
                <div id="supplier_filter_dropdpwn1">
                  <div className="filter_dropdpwn1_toparea">
                    <div className="col-sm-12 p-0">
                      <h2>
                        Active Filters{" "}
                        <img
                          onClick={() =>
                            this.setState({
                              filter_dropdpwn1: false,
                              filter_dropdpwn2: false,
                            })
                          }
                          src="images/cross.png"
                          alt=""
                          className="float-right pr-2 pop-cros-1"
                        />
                      </h2>
                    </div>

                    <div className="clear"></div>
                    <div className="col-sm-12 p-0">
                      {this.state.filtersArr.length > 0 && (
                        <form action="#" className="">
                          <div className="form-group mb-0">
                            <label htmlFor="test2" className="co_postaol_radio">
                              <input
                                type="radio"
                                id="test2"
                                name="filterRadioGroup"
                                value="AND"
                                checked={this.state.filterRadioGroup === "AND"}
                                onChange={this.handleRadioButtons}
                              />
                              AND
                            </label>

                            <label htmlFor="test1" className="co_postaol_radio">
                              <input
                                type="radio"
                                id="test1"
                                name="filterRadioGroup"
                                value="OR"
                                checked={this.state.filterRadioGroup === "OR"}
                                onChange={this.handleRadioButtons}
                              />
                              OR
                            </label>
                          </div>
                        </form>
                      )}
                    </div>
                  </div>
                  <div className="clear20"></div>
                  <div className="col-sm-12 p0 detail_filter_table_1">
                    <table
                      width="100%"
                      border="0"
                      cellSpacing="0"
                      cellPadding="0"
                      className="detail_detail_detail_detail_drop-table"
                    >
                      {this.state.filtersArr.length > 0
                        ? this.state.filtersArr?.map((f, i) => {
                            return (
                              <tr>
                                <td
                                  align="left"
                                  width="20"
                                  className="cursorPointer"
                                >
                                  <img
                                    onClick={() => this.removeFilter(f, i)}
                                    src="images/close-icon_filter.png"
                                    width="9"
                                    height="9"
                                    alt=""
                                  />
                                </td>
                                <td align="left">{f.name} </td>
                                <td align="center">
                                  <div className="rel">
                                    <select
                                      onChange={(e) =>
                                        this.onChangefltrConditns(
                                          f.filter,
                                          e,
                                          i
                                        )
                                      }
                                    >
                                      {this.state.fltrsColmnSecond.map(
                                        (fc, i) => {
                                          return (
                                            <option
                                              value={fc.condition}
                                              selected={
                                                f.condition === fc.condition
                                              }
                                            >
                                              {fc.condition}
                                            </option>
                                          );
                                        }
                                      )}
                                    </select>
                                    <i className="fa fa-angle-down my-select"></i>
                                  </div>
                                </td>
                                <td align="right">
                                  <input
                                    placeholder="Value"
                                    type={
                                      f.filter === "Rate Date" ? "date" : "text"
                                    }
                                    onChange={(e) =>
                                      this.handleFilterValue(
                                        e,
                                        "firstPopup",
                                        f,
                                        i
                                      )
                                    }
                                    className="input-cy"
                                    value={f.value}
                                  />
                                </td>
                              </tr>
                            );
                          })
                        : "No filters applied to this view"}
                    </table>
                  </div>
                  <div className="col-sm-12 p-0 pt-3 active_filters">
                    <h2>Active Filters</h2>
                    <div className="save-filter">
                      <span
                        onClick={() =>
                          this.openModal("openSupplierFilterModal")
                        }
                      >
                        Save filter
                      </span>
                      <Link to="#" onClick={this.addActiveFilters}>
                        Save filter
                      </Link>
                    </div>
                    <ul className="active_filter_list">
                      {this.state.activeFilters?.map((a, i) => {
                        return (
                          <li>
                            {this.state.actvFilter === i ? (
                              <span
                                onClick={() => this.deleteActiveFilter(a, i)}
                                className="fa fa-close cursorPointer activeFilter"
                              ></span>
                            ) : (
                              <span className="cursorPointer">
                                <img
                                  onClick={() => this.deleteActiveFilter(a, i)}
                                  src="images/close-icon-gray.png"
                                />
                              </span>
                            )}

                            <span
                              className={
                                this.state.actvFilter === i
                                  ? "cursorPointer activeFilter"
                                  : "cursorPointer"
                              }
                              onClick={() => this.handleActiveFilter(a, i)}
                            >
                              {" "}
                              {a.description}
                            </span>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                  <div className="col-sm-12 active_filters_table2"></div>
                  <div className="clear"></div>
                  <div className="col-sm-12 p-0 active_filters">
                    <h2>Workspace Filters</h2>
                    <div className="save-filter">
                      <Link to="#" onClick={this.addWorkspaceFilters}>
                        Save filter
                      </Link>
                    </div>
                    <ul className="active_filter_list">
                      {this.state.workSpaceFilters?.map((w, i) => {
                        return (
                          <li>
                            {this.state.activeWorkFilter === i ? (
                              <span
                                onClick={() => this.deleteWorkSpaceFilter(w, i)}
                                className="fa fa-close cursorPointer activeFilter"
                              ></span>
                            ) : (
                              <span className="cursorPointer">
                                <img
                                  onClick={() =>
                                    this.deleteWorkSpaceFilter(w, i)
                                  }
                                  src="images/close-icon-gray.png"
                                />
                              </span>
                            )}

                            <span
                              className={
                                this.state.activeWorkFilter === i
                                  ? "cursorPointer activeFilter"
                                  : "cursorPointer"
                              }
                              onClick={() => this.handleWorkSpaceFilter(w, i)}
                            >
                              {" "}
                              {w.description}
                            </span>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                  <div className="clear10"></div>
                  <div>
                    <button
                      className="ml-2 clear-filter"
                      onClick={this.clearAllFilters}
                    >
                      Clear
                    </button>

                    <div className="pull-right plus_icon-filter_bottom">
                      <img
                        onClick={() =>
                          this.setState({ filter_dropdpwn2: true })
                        }
                        src="images/user-setup/plus_icon-filter_bottom.png"
                        alt=""
                      />
                    </div>
                  </div>
                </div>

                {/* second drop down 2*/}
                <div id="supplier_filter_dropdpwn2">
                  <div className="filter_dropdpwn2_toparea p-0">
                    <div className="col-sm-12 p-0">
                      <h2 className="pl-3 pt-3 pb-1">Add Filters</h2>
                      <div className="can-sav-btn">
                        <button
                          onClick={this.saveFilters}
                          className="btn can-btn1"
                        >
                          <img src="images/save-check.png" alt="check"></img>
                          Save
                        </button>
                        <button
                          onClick={this.closeFilterPopup}
                          className="btn can-btn1 pl-3 close_it"
                        >
                          <img src="images/cancel.png" alt="check"></img>
                          Cancel
                        </button>
                      </div>
                      <hr />
                    </div>
                    <div className="row pb-30">
                      <div className="col sec-pop pr-0">
                        <ul>
                          {this.state.bankChequeColumns.map((f, i) => {
                            return (
                              <li
                                className={f.checked ? "b-active" : ""}
                                key={i}
                                onClick={() => this.addFilters(f, "first")}
                              >
                                {f.name}
                                {f.checked ? (
                                  <span className="fa fa-check icon-check"></span>
                                ) : (
                                  ""
                                )}
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                      <div className="col sec-pop pl-0 pr-0 check_active_ul ">
                        <ul className="pr-0">
                          <ul className="pr-0">
                            {this.state.bankChequeColumns.map((f, i) => {
                              return (
                                <li
                                  className={f.checked ? "b-active" : ""}
                                  key={i}
                                  onClick={() => this.addFilters(f, "second")}
                                >
                                  {f.condition}
                                  {f.checked ? (
                                    <span className="fa fa-check icon-check"></span>
                                  ) : (
                                    ""
                                  )}
                                </li>
                              );
                            })}
                          </ul>
                        </ul>
                      </div>
                      <div className="col sec-pop1 pl-0">
                        <ul>
                          <li className="border-bottom">
                            <div className="">
                              <input
                                placeholder="Value"
                                type={this.state.showDate ? "date" : "text"}
                                className="cus-in"
                                name="filterValue"
                                value={this.state.filterValue}
                                onChange={(e) =>
                                  this.handleFilterValue(e, "secondPopup")
                                }
                              />
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="clear10"></div>
                  </div>
                </div>
                {/* end dropdown */}

                <div className="col-12 col-lg-4 col-md-6 pl-md-0 mt-4">
                  <div className="d-flex justify-content-center h-100">
                    <div className="searchbar">
                      <input
                        className="search_input"
                        type="text"
                        placeholder="Search..."
                        name="detailsListSearch"
                        value={this.state.detailsListSearch}
                        onChange={(e) => this.handleSearch(e)}
                      />
                      <a
                        href="javascript:void(0)"
                        className="search_icon search-boredr"
                      >
                        <i className="fa fa-search"></i>
                      </a>
                      <a
                        href="javascript:void(0)"
                        className="search_icon"
                        id="filter_dropdown"
                      >
                        <i className="fa fa-filter"></i>
                      </a>
                    </div>
                  </div>
                </div>

                <div className="row mt-3">
                  <div className="col-12">
                    <div className="login_form">
                      <div className="login_table_list table-responsive tab-1-line supplier2_table ">
                        <table
                          className="table  busines_unit_table shadow-remove "
                          id="chequesDetail"
                        >
                          <thead>
                            <tr>
                              <th
                                scope="col"
                                className="exp_th1 mm_contact-name"
                              >
                                <div className="form-group">
                                  <label className="dash_container dash_remember table-check unckeck">
                                    <input
                                      type="checkbox"
                                      name="checkboxAll"
                                      id={"chk1"}
                                      // checked={checkAllJrnltem}
                                      onChange={(e) =>
                                        this.handleCheckboxes(e, "allCheck")
                                      }
                                    />
                                    <span
                                      id="chk1"
                                      className="dash_checkmark"
                                    ></span>
                                  </label>
                                </div>
                              </th>
                              {bankChequeColumns?.map((col, i) => {
                                return (
                                  <th
                                    scope="col"
                                    key={i}
                                    className="text-left exp-supplier-th "
                                  >
                                    <span className="table_lines_hed ">
                                      {" "}
                                      {col.ColName}
                                    </span>
                                  </th>
                                );
                              })}
                              <th>
                                <span
                                  className="user_setup_hed2"
                                  onClick={() =>
                                    this.openModal(
                                      "openBankChequesSettingsModal"
                                    )
                                  }
                                >
                                  {" "}
                                  <img
                                    src="./images/user-setup/bars.png"
                                    alt="bars"
                                  ></img>
                                </span>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {bankChequeRows?.map((item, i) => {
                              return (
                                <tr key={i}>
                                  {item.data === null ? (
                                    ""
                                  ) : (
                                    <td key={i}>
                                      <div
                                        className="col align-self-center text-center pr-0"
                                        style={{ marginLeft: "14px" }}
                                      >
                                        <div className="form-group mb-0 check-line">
                                          <label className="dash_container dash_remember table-check unckeck">
                                            <input
                                              type="checkbox"
                                              name={"chk1"}
                                              id={`chk${i}`}
                                              checked={item.checked}
                                              onChange={(e) =>
                                                this.handleCheckboxes(e, item)
                                              }
                                            />
                                            <span
                                              id={`chk${i}`}
                                              className="dash_checkmark"
                                            ></span>
                                          </label>
                                        </div>
                                      </div>
                                    </td>
                                  )}
                                  {bankChequeColumns?.map((c, j) => {
                                    let ColType = c.ColType;
                                    if (
                                      ColType === "Number" ||
                                      ColType === "Numeric"
                                    ) {
                                      return (
                                        <>
                                          {item.data === null ? (
                                            <td className="text-left"></td>
                                          ) : (
                                            <td
                                              className="text-left"
                                              data-order={item.data[j]}
                                              key={j}
                                            >
                                              <input
                                                type="number"
                                                name="chartSort"
                                                value={item.data[j]}
                                                className="input_height wd-108"
                                                readOnly={
                                                  c.Editable ? false : true
                                                }
                                                onBlur={this.updateCheques}
                                                onChange={(e) => {
                                                  this.handleChangeGeneralLedgerList(
                                                    e,
                                                    item.data,
                                                    i,
                                                    j
                                                  );
                                                }}
                                              />
                                            </td>
                                          )}
                                        </>
                                      );
                                    } else if (ColType === "Date") {
                                      return (
                                        <>
                                          {item.data === null ? (
                                            <td className="text-left"></td>
                                          ) : (
                                            <td
                                              className="text-left"
                                              data-order={item.data[j]}
                                              key={j}
                                            >
                                              <input
                                                type="date"
                                                name="date"
                                                value={item.data[j].trim()}
                                                className="input_height wd-130"
                                                readOnly={
                                                  c.Editable ? false : true
                                                }
                                                onBlur={this.updateCheques}
                                                onChange={(e) => {
                                                  this.handleChangeGeneralLedgerList(
                                                    e,
                                                    item.data,
                                                    i,
                                                    j
                                                  );
                                                }}
                                              />

                                              {/* <DatePicker
                                                name="invoiceDate"
                                                id="invoice-Date"
                                                tabIndex="3334"
                                                dateFormat="d MMM yyyy"
                                                autoComplete="off"
                                                onChange={(e) =>
                                                  this.onChangeHandlerDate(
                                                    item.data,
                                                    j,
                                                    e
                                                  )
                                                }
                                                className={
                                                  showInvoiceTallisTabPane !==
                                                  "draft"
                                                    ? "disable_bg disable_border"
                                                    : "form-control"
                                                }
                                                disabled={
                                                  showInvoiceTallisTabPane !==
                                                  "draft"
                                                }
                                                selected={
                                                  Number(item.data[j]) * 1000 ||
                                                  ""
                                                }
                                              /> */}
                                            </td>
                                          )}
                                        </>
                                      );
                                    } else if (ColType === "Check") {
                                      return (
                                        <>
                                          {item.data === null ? (
                                            <td className="text-left"></td>
                                          ) : (
                                            <td
                                              className="text-left"
                                              data-order={item.data[j]}
                                              key={j}
                                            >
                                              <input
                                                type="checkbox"
                                                // value={item.data[j]}
                                                checked={
                                                  item.data[j] === "Y" ||
                                                  item.data[j] === "1"
                                                    ? true
                                                    : false
                                                }
                                                className="input_height wd-108"
                                                readOnly={
                                                  c.Editable === "1"
                                                    ? false
                                                    : true
                                                }
                                                onBlur={this.updateCheques}
                                                onChange={(e) => {
                                                  this.handleCheck(
                                                    e,
                                                    item.data,
                                                    i,
                                                    j
                                                  );
                                                }}
                                              />
                                            </td>
                                          )}
                                        </>
                                      );
                                    } else {
                                      return (
                                        <>
                                          {item.data === null ? (
                                            ""
                                          ) : (
                                            <td
                                              className="text-left"
                                              data-order={item.data[j]}
                                              key={j}
                                            >
                                              <input
                                                type="text"
                                                name="chartSort"
                                                value={item.data[j]}
                                                className="input_height wd-108"
                                                readOnly={
                                                  c.Editable ? false : true
                                                }
                                                onBlur={this.updateCheques}
                                                onChange={(e) => {
                                                  this.handleChangeGeneralLedgerList(
                                                    e,
                                                    item.data,
                                                    i,
                                                    j
                                                  );
                                                }}
                                              />
                                            </td>
                                          )}
                                        </>
                                      );
                                    }
                                  })}
                                  <td></td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <CancelReissueModal
          openCancelReissueModal={this.state.openCancelReissueModal}
          closeModal={this.closeModal}
          state={this.state}
          locationProps={this.props}
        />
        <BankChequesSettings
          openBankChequesSettingsModal={this.state.openBankChequesSettingsModal}
          openModal={this.openModal}
          closeModal={this.closeModal}
          pageLength={this.state.pageLength}
          handleChangeSettings={this.handleChangeSettings}
        />
        <Import
          openImportModal={this.state.openImportModal}
          type={this.state.type}
          onImport={this.onImport}
          closeModal={this.closeModal}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  account: state.account,
});
export default connect(mapStateToProps, {
  getCheques: AccountActions.getCheques,
  getImportExcel: AccountActions.getImportExcel,
  getExportExcel: AccountActions.getExportExcel,
  getUpdateCheques: AccountActions.getUpdateCheques,
  getUpdateStatus: AccountActions.getUpdateStatus,
  getPrimeCancelReIssue: AccountActions.getPrimeCancelReIssue,
})(BankCheques);
