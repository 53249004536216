import React, { Component, Fragment, createContext } from "react";
import { Link } from "react-router-dom";
import { Document, Page, pdfjs } from "react-pdf";
import Select from "react-select";
import ReactPaginate from "react-paginate";

import { connect } from "react-redux";
import $, { get } from "jquery";
import Header from "../Common/Header/Header";
import TopNav from "../Common/TopNav/TopNav";
import Activity from "../Modals/Activity/Activity";
import Changes from "../Modals/Changes/Changes";
import Attachments from "../Modals/Attachments/Attachments";
import Comments from "../Modals/Comments/Comments";
import * as PaymentActions from "../../Actions/PaymentActions/PaymentActions";
import * as PaginationActions from "../../Actions/paginationActions/paginationActions";
import Dropdown from "react-bootstrap/Dropdown";
import PaymentsMoreDetails from "../Modals/PaymentsMoreDetails/PaymentsMoreDetails";
import Post from "../Modals/Post/Post";
import Import from "../Modals/Import/Import";
import Report from "../Modals/Report/Report";
import { options } from "../../Constants/Constants";
import Remittances from "../Modals/Remittances/Remittances";
import PositivePay from "../Modals/PositivePay/PositivePay";
import ChequePrint from "../Modals/ChequePrint/ChequePrint";
import EftFile from "../Modals/EFTFile/EFTFile";
import CheckApproval from "../Modals/CheckApproval/CheckApproval";
import moment from "moment";
import {
  handleAPIErr,
  zoomIn,
  zoomOut,
  handleDropdownZooming,
  downloadAttachments,
  handleValueOptions,
  handleHideUnhideRows,
} from "../../Utils/Helpers";
import { toast } from "react-toastify";
const uuidv1 = require("uuid/v1");

class Payments extends Component {
  constructor(props) {
    super();
    this.state = {
      emailAddress: [{ label: "", value: "" }],
      getPaymentTallies: [],
      activePaymentTallis: "",
      showPaymentTallisTabPane: "",
      reportOptionVal: { label: "Select Report", value: "" },
      companyOptVal: [{ label: "Select Company", value: "", selected: 0 }],
      reportOpt: [],
      activePayment: "",
      getPaymentsList: [],
      clonedGetPaymentsList: [],
      filteredPaymentsList: [],

      reportOptionsArr: [],
      companyOpt: [],

      selectedItems: [],
      chequeID: "",

      paymentSummary: [],
      approvalDate: "",
      approvalGroup: "",
      approvers: [],
      payAttachments: [],
      activity: [],
      showHiddenRows: false,
      paymentsMoreDetails: "", //when click on payments more details

      payComments: [],
      locked: "",
      previewList: [],
      pageNumber: 1,
      numPages: null,
      rotate: 0,
      scaling: 3.4,
      numPagesArr: [],
      dropdownZoomingValue: { label: "40%", value: "40%" },
      multipleTrans: [],
      guid: "",
      tran: "",
      processDate: "",
      layout: "",
      advancedOptions: [],
      reportOptions: [],
      subject: "",
      message: "",
      companyOptions: [],
      // viewTeam: "N",
      viewTeam: localStorage.getItem("teamOrders") || "N",

      sortFilterCheckPayment: "ASC",
      sortFilterInvc: "date",
      paymentsListSearch: props.pageData?.searchTerm ?? "",
      toggleRightSidebar: true,
      openRemittancesModal: false,
      openPositivePayModal: false,
      openChequePrintModal: false,
      openPaymentsMoreDetailsModal: false,
      openCheckApprovalModal: false,
      openEftFileModal: false,
      activePOTallis: 1,
      showPayTallisTabPane: "draft",
      display: localStorage.getItem("orderDPR") || 10, //The number of orders to display per page
      totalPages: "",
      pageStart: "",
      pageEnd: "",
      page: props.pageData?.pageNumber ?? 1, //The page to retrieve. Returns the first page if left as zero.
      resetPage: true,
      totalPayments: "",
      zero: false,
      close: false,
      sortFilter: props.pageData?.sortKey ?? "Date",
      sortFilterPay: props.pageData?.sortOrder ?? "paymentType",
      description: "",
      accountName: "",
      supplier: "",
      approvalOptions: "",
      accountCode: "",
      paid: "",
      supplierCode: "",
      userName: "",
      amount: "",
      checked: false,
      allSelected: false,
      selectedGuid:null,
    };
  }

  async componentDidMount() {
    this.getPaymentTallies();
    //right hand side bar setting with paym Image
    window.addEventListener(
      "resize",
      () => {
        $(".mm_ordr1").addClass("mm_order_pdfMain");

        if ($(window).width() > 991) {
          this.setState({ toggleRightSidebar: true });
        }
        if ($(window).width() <= 991) {
          this.setState({ toggleRightSidebar: false });
        }
      },
      false
    );
    // end

    let { viewTeam, sortFilterInvc, sortFilterCheckPayment } = this.state;
    //Team Invoice Check
    viewTeam = localStorage.getItem("teamInvoice") || "N";
    //getting default sorting list setting from localstorage
    sortFilterInvc = localStorage.getItem("sortFilterInvc") || "supplierName";
    sortFilterCheckPayment =
      localStorage.getItem("sortFilterCheckPayment") || "ASC";
    this.setState({ viewTeam, sortFilterInvc, sortFilterCheckPayment });

    //focus search input field by pressing Tab key
    document.onkeydown = function (evt) {
      evt = evt || window.event;
      if (evt.keyCode == 9) {
        evt.preventDefault();
        let id = document.getElementById("POListSearchId");
        if (id) {
          document.getElementById("POListSearchId").focus();
        }
      }
    };

    $(document).ready(function () {
      var vw = $(window).width();
      var nav = $(".navbar.fixed-top").height();
      var underNav = $(".order-tabs").height();
      var wh = $(window).height();
      var h = wh - nav - 60;
      var rsb = wh - nav - underNav - 20;
      var pdfDiv = wh - nav - underNav - 80;
      var pdfWid = vw - 740;
      $("#overload_image").css("width", pdfWid);
      $("#order--dynamic--height").css("height", h);
      $(".side-attachments,.side-attachments-2").css("height", rsb);
      $("#maped_image").css("height", pdfDiv);
      $(window).on("load", function () {
        var vw = $(window).width();
        var nav = $(".navbar.fixed-top").height();
        var underNav = $(".order-tabs").height();
        var wh = $(window).height();
        var h = wh - nav - 60;
        var rsb = wh - nav - underNav - 20;
        var pdfDiv = wh - nav - underNav - 80;
        var pdfWid = vw - 740;
        $("#overload_image").css("width", pdfWid);
        $("#order--dynamic--height").css("height", h);
        $(".side-attachments,.side-attachments-2").css("height", rsb);
        $("#maped_image").css("height", pdfDiv);
      });
      $(window).resize(function () {
        var vw = $(window).width();
        var nav = $(".navbar.fixed-top").height();
        var underNav = $(".order-tabs").height();
        var wh = $(window).height();
        var h = wh - nav - 60;
        var rsb = wh - nav - underNav - 20;
        var pdfDiv = wh - nav - underNav - 80;
        var pdfWid = vw - 740;
        $("#overload_image").css("width", pdfWid);
        $("#order--dynamic--height").css("height", h);
        $(".side-attachments,.side-attachments-2").css("height", rsb);
        $("#maped_image").css("height", pdfDiv);
      });
    });
    $("#expand").on("click", function (e) {
      e.preventDefault();
      $(".maped_image").addClass("mm_pdf_img");
    });

    $(".cus-arro-div2").on("click", function (e) {
      e.preventDefault();
      $(".order_pdf_new").toggleClass("order_left_auto");
    });
    $("#full_screen").on("click", function (e) {
      e.preventDefault();
      $(".explore_img").addClass("fit_top_bottom");
    });
    // end
    // sideBarAccord
    $(".invoice-inherit").click(function () {
      $(".invoice-inherit .sideBarAccord1 ").toggleClass("rotate_0");
    });
    $(".sideBarAccord").click(function () {
      $(this).toggleClass("rorate_0");
    });
    $(".export_crd").click(function () {
      $(".export_crd .sideBarAccord1").toggleClass("rotate_0");
    });
    $(".invoice-inherit2").click(function () {
      $(".sideBarAccord2 ").toggleClass("rotate_0");
    });
    $(".sideBarAccord").dblclick(function () {
      $(this).toggleClass("rorate_0");
    });
    $(".sideBarAccord1").dblclick(function () {
      $(this).toggleClass("rorate_0");
    });
    $(".sideBarAccord2").dblclick(function () {
      $(this).toggleClass("rorate_0");
    });
  }

  componentWillUnmount() {
    if (this.state.resetPage) {
      this.props.resetLastPage("payments");
    }
  }

  getPaymentTallies = async (type, check) => {
    this.setState({ isLoading: true, checked: false });
    let { getPaymentTallies } = this.state;
    await this.props.getPaymentTallies();

    let paymentTally = "";
    let { activePaymentTallis, showPaymentTallisTabPane } = this.state;

    let paymentTalliesArr = [];
    if (this.props.payments.getPaymentTalliesSuccess) {
      getPaymentTallies = this.props.payments.getPaymentTallies || [];

      let paymentType = [];
      let userType = localStorage.getItem("userType");
      userType = userType ? userType.toLowerCase() : "";

      if (userType == "operator") {
        paymentType = ["draft", "pending", "declined", "approved", "all"];
      } else if (userType == "approver") {
        paymentType = [
          "approve",
          "hold",
          "pending",
          "declined",
          "approved",
          "all",
        ];
      } else if (userType == "op/approver") {
        paymentType = [
          "draft",
          "approve",
          "hold",
          "pending",
          "declined",
          "approved",
          "all",
        ];
      }

      if (paymentType.length > 0) {
        paymentType.map((t, i) => {
          let obj = getPaymentTallies.find(
            (tl) => tl.type && tl.type.toLowerCase() === t
          );
          if (obj) {
            paymentTalliesArr.push(obj);
          }
        });
      } else {
        paymentTalliesArr = getPaymentTallies;
      }

      let _type = "";

      if (type) {
        _type = type;
      } else if (paymentTalliesArr.length > 0) {
        _type = paymentTalliesArr[0].type;
      }

      paymentTalliesArr.map((paym, i) => {
        if (paym.type === _type) {
          let id = uuidv1();
          paym.id = id;
          paymentTally = paym;
          activePaymentTallis = id;
          showPaymentTallisTabPane = paym.type;
        } else {
          paym.id = uuidv1();
        }
        return paym;
      });
    }

    if (this.props.payments.getPaymentTalliesError) {
      handleAPIErr(this.props.payments.getPaymentTalliesError, this.props);
    }

    this.setState({
      isLoading: false,
      getPaymentTallies: paymentTalliesArr,
      activePaymentTallis,
      showPaymentTallisTabPane,
    });

    if (paymentTally) {
      //to call get invoices List baseed on first invoice tallies
      this.checkPOList_API(paymentTally, check);
    }
  };
  checkPOList_API(data, check) {
    let usePageLoading = localStorage.getItem("usePageLoading") || "N";
    usePageLoading = usePageLoading.toLocaleLowerCase();
    if (usePageLoading === "yy") {
      // alert("neww");
      this.getNewPaymentsList(data, check);
    } else {
      this.getPaymentsList(data, check);
    }
  }

  handlePageClick = ({ selected }) => {
    let { activePaymentTallis, showPaymentTallisTabPane } = this.state;

    let obj = {
      id: activePaymentTallis,
      type: showPaymentTallisTabPane,
    };

    this.setState(
      { page: selected + 1, pageStart: "", pageEnd: "", totalPayments: "" },
      () => {
        this.savePageState();
        this.getNewPaymentsList(obj);
      }
    );
  };

  savePageState = () => {
    const { page, paymentsListSearch } = this.state;
    this.props.setLastPage("payments", {
      pageNumber: page,
      searchTerm: paymentsListSearch,
    });
  };

  getPaymentsList = async (data, check) => {
    let activePayment = "";
    let getPaymentsList = [];
    let clonedGetPaymentsList = [];
    let filteredPaymentsList = [];
    let totalPages = "";
    let pageStart = "";
    let pageEnd = "";
    let totalPayments = "";
    let {
      zero,
      close,
      page,
      display,
      sortFilter,
      viewTeam,
      paymentsListSearch,
    } = this.state;

    if (check === "tallies") {
      zero = false;
      close = false;
    }
    if (check) {
      page = 1;
    }
    this.clearStates();
    this.setState({
      isLoading: true,
      activePaymentTallis: data.id,
      showPaymentTallisTabPane: data.type,
      zero: false,
      close: false,
      fullyApproved: false,
    });

    await this.props.getPaymentsList(data);
    let firstPayment = "";

    if (this.props.payments.getPaymentsListSuccess) {
      let listData = this.props.payments.getPaymentsList || [];
      getPaymentsList = listData.payments;
      totalPages = listData.totalPages;
      pageStart = listData.pageStart;
      pageEnd = listData.pageEnd;
      totalPayments = listData.totalPayments;

      let sortFilterInvc = this.state.sortFilterInvc;
      let sortFilterCheckPayment = this.state.sortFilterCheckPayment;

      getPaymentsList
        .sort((a, b) => {
          if (sortFilterInvc === "amount" || sortFilterInvc === "tran") {
            let valueA = Number(a[sortFilterInvc]);
            let valueB = Number(b[sortFilterInvc]);
            //for ascending order
            if (sortFilterCheckPayment === "ASC") {
              if (valueA < valueB) {
                return -1;
              }
              if (valueA > valueB) {
                return 1;
              }
              return 0;
              // codes must be equal
            } else {
              //for descending order

              if (valueA > valueB) {
                return -1;
              }
              if (valueA < valueB) {
                return 1;
              }
              return 0;
              // codes must be equal
            }
          } else if (sortFilterInvc === "date") {
            let valueA = new Date(a.date);
            let valueB = new Date(b.date);
            //for ascending order
            if (sortFilterCheckPayment === "ASC") {
              if (valueA < valueB) {
                return -1;
              }
              if (valueA > valueB) {
                return 1;
              }
              return 0;
              // codes must be equal
            } else {
              //for descending order

              if (valueA > valueB) {
                return -1;
              }
              if (valueA < valueB) {
                return 1;
              }
              return 0;
              // codes must be equal
            }
          } else if (sortFilterInvc) {
            let valueA = a[sortFilterInvc]?.toString().toUpperCase();
            let valueB = b[sortFilterInvc]?.toString().toUpperCase();
            //for ascending order
            if (sortFilterCheckPayment === "ASC") {
              if (valueA < valueB) {
                return -1;
              }
              if (valueA > valueB) {
                return 1;
              }
              return 0;
              // codes must be equal
            } else {
              //for descending order

              if (valueA > valueB) {
                return -1;
              }
              if (valueA < valueB) {
                return 1;
              }
              return 0;
              // codes must be equal
            }
          }
        })
        .map((invc, i) => {
          if (i == 0) {
            let id = uuidv1();
            invc.id = id;
            invc.checked = false;
            firstPayment = invc;
            activePayment = id;
          } else {
            invc.id = uuidv1();
            invc.checked = false;
          }
          return invc;
        });

      clonedGetPaymentsList = getPaymentsList;
      filteredPaymentsList = getPaymentsList;
    }

    if (this.props.payments.getPaymentsListError) {
      handleAPIErr(this.props.payments.getPaymentsListError, this.props);
    }
    this.setState({
      activePayment,
      getPaymentsList,
      clonedGetPaymentsList,
      filteredPaymentsList,
      isLoading: false,
      totalPages,
      pageStart,
      pageEnd,
      totalPayments,
    });
    if (firstPayment) {
      this.getPaymentSummary(firstPayment, true);
    }
    this.props.clearPaymentStates();
  };
  getNewPaymentsList = async (data, check) => {
    let activePayment = "";
    let getPaymentsList = [];
    let clonedGetPaymentsList = [];
    let filteredPaymentsList = [];
    let totalPages = "";
    let pageStart = "";
    let pageEnd = "";
    let totalPayments = "";
    let {
      zero,
      close,
      page,
      display,
      sortFilter,
      viewTeam,
      paymentsListSearch,
    } = this.state;

    if (check === "tallies") {
      zero = false;
      close = false;
    }
    if (check) {
      page = this.props.pageData?.pageNumber ?? 1;
    }
    this.clearStates();

    this.setState({
      isLoading: true,
      activePaymentTallis: data.id,
      showPaymentTallisTabPane: data.type,
      zero,
      close,
      page,
    });
    let obj = {
      display,
      paymentType: data.type,
      paymentOrder: sortFilter,
      page,
      search: paymentsListSearch,
      teamPayments: viewTeam,
    };
    let firstPO = "";

    await this.props.getNewPaymentsList(obj);
    // success case for getNewPaymentsList
    if (this.props.payments.getNewPaymentsListSuccess) {
      let paymentData = this.props.payments.getNewPaymentsList || "";

      getPaymentsList = paymentData.payments;
      pageStart = paymentData.pageStart || "";
      pageEnd = paymentData.pageEnd || "";
      totalPages = paymentData.totalPages || "";
    }

    getPaymentsList.map((paym, i) => {
      if (i === 0) {
        let id = uuidv1();
        paym.id = id;
        firstPO = paym;
        paym.checked = false;
        activePayment = id;
      } else {
        paym.id = uuidv1();
        paym.checked = false;
      }
      return paym;
    });
    clonedGetPaymentsList = getPaymentsList;
    filteredPaymentsList = getPaymentsList;
    let { editPaymentTran, editPaymenCheck } =
      (this.props.history &&
        this.props.history.location &&
        this.props.history.location.state) ||
      "";
    if (
      // tallies &&
      // tallies === "Draft" &&
      editPaymenCheck &&
      editPaymentTran
    ) {
      let checkPayment = clonedGetPaymentsList.find(
        (l) => l.tran === editPaymentTran
      );
      if (checkPayment) {
        activePayment = checkPayment.id;
        firstPO.id = activePayment.id;
      }
    }
    // error case
    if (this.props.getNewPaymentsListError) {
      handleAPIErr(this.props.getNewPaymentsListSuccess, this.props);
    }
    await this.props.clearPaymentStates();
    this.setState({
      isLoading: false,
      totalPages,
      totalPayments,
      pageEnd,
      pageStart,
      activePayment,
      getPaymentsList,
      clonedGetPaymentsList,
      filteredPaymentsList,
    });
  };

  handleChangeInvoiceListSearch = (e) => {
    let searchedText = e.target.value;
    this.setState({ paymentsListSearch: searchedText }, () => {
      this.savePageState();
      const filteredPaymentsList = JSON.parse(
        JSON.stringify(this.state.filteredPaymentsList)
      );

      if (!searchedText) {
        let sortFilterCheckPayment = this.state.sortFilterCheckPayment;

        if (sortFilterCheckPayment === "ASC") {
          sortFilterCheckPayment = "DESC";
        } else {
          sortFilterCheckPayment = "ASC";
        }
        this.setState(
          { getPaymentsList: filteredPaymentsList, sortFilterCheckPayment },
          () => {
            this.handleSortInvoiceLists(this.state.sortFilterInvc);
            // scroll to active invoice
            var elmnt = document.getElementById(this.state.activePayment);
            if (elmnt) {
              elmnt.scrollIntoView();
            }
          }
        );
      }
    });
  };

  onPaymentListSearch = (e) => {
    if (e.key === "Enter" || e.key === "Tab") {
      let paymentsListSearch = this.state.paymentsListSearch.trim();

      if (paymentsListSearch) {
        const filteredPaymentsList = JSON.parse(
          JSON.stringify(this.state.filteredPaymentsList)
        );

        let paymentListFilterdData = [];
        paymentListFilterdData = filteredPaymentsList.filter((c) => {
          return c.supplierName
            .toUpperCase()
            .includes(paymentsListSearch.toUpperCase());
        });

        this.setState({ getPaymentsList: paymentListFilterdData });
      }
    }
  };

  //sorting on invocies list
  handleSortInvoiceLists = (name, check) => {
    let { sortFilterCheckPayment } = this.state;
    if (this.state.sortFilterInvc != name) {
      this.state.sortFilterInvc = "DESC";
    }

    if (sortFilterCheckPayment === "DESC") {
      sortFilterCheckPayment = "ASC";
    } else {
      sortFilterCheckPayment = "DESC";
    }

    localStorage.setItem("sortFilterInvc", name);
    localStorage.setItem("sortFilterCheckPayment", sortFilterCheckPayment);

    const filteredPaymentsList = JSON.parse(
      JSON.stringify(this.state.filteredPaymentsList)
    );
    let paymentListFilterdData = [];
    if (name === "amount" || name === "tran") {
      paymentListFilterdData = filteredPaymentsList.sort(function (a, b) {
        let valueA = Number(a[name]);
        let valueB = Number(b[name]);
        //for ascending order
        if (sortFilterCheckPayment === "ASC") {
          if (valueA < valueB) {
            return -1;
          }
          if (valueA > valueB) {
            return 1;
          }
          return 0;
          // codes must be equal
        } else {
          //for descending order

          if (valueA > valueB) {
            return -1;
          }
          if (valueA < valueB) {
            return 1;
          }
          return 0;
          // codes must be equal
        }
      });
    } else if (name === "date" || name === "approvalDate") {
      paymentListFilterdData = filteredPaymentsList.sort(function (a, b) {
        let valueA = "";
        let valueB = "";

        if (name === "date") {
          valueA = new Date(a.date);
          valueB = new Date(b.date);
        } else {
          // valueA = new Date(a.approvalDate);
          // valueB = new Date(b.approvalDate);
          valueA = new Date(
            moment(a.approvalDate.replace(/\s/g, ""), "DD/MM/YYYY")
          );
          valueB = new Date(
            moment(b.approvalDate.replace(/\s/g, ""), "DD/MM/YYYY")
          );
        }

        //for ascending order
        if (sortFilterCheckPayment === "ASC") {
          if (valueA < valueB) {
            return -1;
          }
          if (valueA > valueB) {
            return 1;
          }
          return 0;
          // codes must be equal
        } else {
          //for descending order

          if (valueA > valueB) {
            return -1;
          }
          if (valueA < valueB) {
            return 1;
          }
          return 0;
          // codes must be equal
        }
      });
    } else if (name) {
      paymentListFilterdData = filteredPaymentsList.sort(function (a, b) {
        let valueA = a[name].toString().toUpperCase();
        let valueB = b[name].toString().toUpperCase();

        //for ascending order
        if (sortFilterCheckPayment === "ASC") {
          if (valueA < valueB) {
            return -1;
          }
          if (valueA > valueB) {
            return 1;
          }
          return 0;
          // codes must be equal
        } else {
          //for descending order

          if (valueA > valueB) {
            return -1;
          }
          if (valueA < valueB) {
            return 1;
          }
          return 0;
          // codes must be equal
        }
      });
    }
    this.setState({
      getPaymentsList: paymentListFilterdData,
      sortFilterInvc: name,
      sortFilterCheckPayment,
    });
  };
  getPaymentSummary = async (data) => {
    this.setState({ isLoading: true, activePayment: data.id, selectedGuid:data.guid });
    await this.props.getPaymentSummary(data);

    if (this.props.payments.getPaymentSummarySuccess) {
      // paymentSummary = this.props.payments.getDistchangeSummary;

      let paymentSummary =
        (this.props.payments.getPaymentSummary &&
          this.props.payments.getPaymentSummary &&
          JSON.parse(JSON.stringify(this.props.payments.getPaymentSummary))) ||
        "";

      let approvalDate = paymentSummary[0].approvalDate || "";
      let approvalGroup = paymentSummary[0].approvalGroup || "";

      let tran = paymentSummary[0].tranNo || "";

      const guid = paymentSummary[0].guid || "";

      let approvers = paymentSummary[0].approvers || [];

      let payAttachments =
        (paymentSummary && paymentSummary[0].attachments) || [];
      let attachmentSize = 0;
      payAttachments.map((a, i) => {
        attachmentSize += Number(a.fileSize) || 0;
      });

      let locked = (paymentSummary && paymentSummary[0].locked) || [];

      let activity = (paymentSummary && paymentSummary[0].activity) || [];

      let payComments = (paymentSummary && paymentSummary[0].comments) || [];
      let previewList = (paymentSummary && paymentSummary[0].previewList) || [];
      let description = (paymentSummary && paymentSummary[0].description) || "";
      let accountName = (paymentSummary && paymentSummary[0].accountName) || "";
      let supplier = (paymentSummary && paymentSummary[0].supplier) || "";
      let accountCode = (paymentSummary && paymentSummary[0].accountCode) || "";
      let paid = (paymentSummary && paymentSummary[0].paid) || "";
      let supplierCode =
        (paymentSummary && paymentSummary[0].supplierCode) || "";
      let userName = (paymentSummary && paymentSummary[0].userName) || "";
      let approvalOptions =
        (paymentSummary && paymentSummary[0].approvalOptions) || [];
      let amount = (paymentSummary && paymentSummary[0].amount) || [];

      //to show primary PDF first
      // previewList = previewList.sort((a, b) =>
      //   a.primaryDoc.toLowerCase() < b.primaryDoc.toLowerCase() ? 1 : -1
      // );

      // let attachments = paymentSummary.attachments || []

      this.setState({
        guid,
        tran,
        approvalDate,
        approvalGroup,
        approvers,
        payAttachments,
        activity,
        payComments,
        locked,
        previewList,
        description,
        accountName,
        supplier,
        approvalOptions,
        accountCode,
        paid,
        supplierCode,
        userName,
        amount,
      });
    }

    this.setState({ isLoading: false });
  };

  sendForApprovalPayment = async () => {
    let { guid, multipleTrans } = this.state;

    let _trans = "";
    if (multipleTrans.length > 0) {
      if (multipleTrans.length == 1) {
        _trans = multipleTrans[0];
      } else {
        _trans = multipleTrans;
      }
    } else {
      _trans = guid;
    }

    if (_trans) {
      this.setState({
        isLoading: true,
      });
      await this.props.sendForApprovalPayment(_trans); // send For Approval Payment
      //success case of send For Approval Payment
      if (this.props.payments.sendForApprovalPaymentSuccess) {
        toast.success(this.props.payments.sendForApprovalPaymentSuccess);
        await this.getPaymentTallies(this.state.showPaymentTallisTabPane, true); //to refresh the list
      }
      //error case of send For Approval Payment
      if (this.props.payments.sendForApprovalPaymentError) {
        handleAPIErr(
          this.props.payments.sendForApprovalPaymentError,
          this.props
        );
      }
      alert("payement");

      this.setState({ isLoading: false });
      this.props.clearPaymentStates();
    } else {
      toast.error("Please Select Payment list first");
    }
  };

  movePayment = async () => {
    let { guid, multipleTrans } = this.state;

    let _trans = "";
    if (multipleTrans.length > 0) {
      if (multipleTrans.length == 1) {
        _trans = multipleTrans[0];
      } else {
        _trans = multipleTrans;
      }
    } else {
      _trans = guid;
    }

    if (_trans) {
      this.setState({
        isLoading: true,
      });
      await this.props.movePayment(_trans); // send For Approval Payment
      //success case of send For Approval Payment
      if (this.props.payments.movePaymentSuccess) {
        toast.success(this.props.payments.movePaymentSuccess);
        await this.getPaymentTallies(this.state.showPaymentTallisTabPane, true); //to refresh the list
      }
      //error case of send For Approval Payment
      if (this.props.payments.movePaymentError) {
        handleAPIErr(this.props.payments.movePaymentError, this.props);
      }
      this.setState({ isLoading: false });
      this.props.clearPaymentStates();
    } else {
      toast.error("Please Select Payment list first");
    }
  };

  approvePayment = async () => {
    debugger;
    let { guid, multipleTrans } = this.state;

    let _trans = "";
    if (multipleTrans.length > 0) {
      if (multipleTrans.length == 1) {
        _trans = multipleTrans[0];
      } else {
        _trans = multipleTrans;
      }
    } else {
      _trans = guid;
    }

    if (_trans) {
      this.setState({
        isLoading: true,
      });
      await this.props.approvePayment(_trans); // send For Approval Payment
      //success case of send For Approval Payment
      if (this.props.payments.approvePaymentSuccess) {
        toast.success(this.props.payments.approvePaymentSuccess);
        await this.getPaymentTallies(this.state.showPaymentTallisTabPane, true); //to refresh the list
      }
      //error case of send For Approval Payment
      if (this.props.payments.approvePaymentError) {
        handleAPIErr(this.props.payments.approvePaymentError, this.props);
      }
      this.setState({ isLoading: false });
      this.props.clearPaymentStates();
    } else {
      toast.error("Please Select Payment list first");
    }
  };

  declinePayment = async () => {
    let { guid, multipleTrans } = this.state;

    let _trans = "";
    if (multipleTrans.length > 0) {
      if (multipleTrans.length == 1) {
        _trans = multipleTrans[0];
      } else {
        _trans = multipleTrans;
      }
    } else {
      _trans = guid;
    }

    if (_trans) {
      this.setState({
        isLoading: true,
      });
      await this.props.declinePayment(_trans); // send For Approval Payment
      //success case of send For Approval Payment
      if (this.props.payments.declinePaymentSuccess) {
        toast.success(this.props.payments.declinePaymentSuccess);
        await this.getPaymentTallies(this.state.showPaymentTallisTabPane, true); //to refresh the list
      }
      //error case of send For Approval Payment
      if (this.props.payments.declinePaymentError) {
        handleAPIErr(this.props.payments.declinePaymentError, this.props);
      }
      this.setState({ isLoading: false });
      this.props.clearPaymentStates();
    } else {
      toast.error("Please Select Payment list first");
    }
  };

  holdPayment = async () => {
    let { guid, multipleTrans } = this.state;

    let _trans = "";
    if (multipleTrans.length > 0) {
      if (multipleTrans.length == 1) {
        _trans = multipleTrans[0];
      } else {
        _trans = multipleTrans;
      }
    } else {
      _trans = guid;
    }

    if (_trans) {
      this.setState({
        isLoading: true,
      });
      await this.props.holdPayment(_trans); // send For Approval Payment
      //success case of send For Approval Payment
      if (this.props.payments.holdPaymentSuccess) {
        toast.success(this.props.payments.holdPaymentSuccess);
        await this.getPaymentTallies(this.state.showPaymentTallisTabPane, true); //to refresh the list
      }
      //error case of send For Approval Payment
      if (this.props.payments.holdPaymentError) {
        handleAPIErr(this.props.payments.holdPaymentError, this.props);
      }
      this.setState({ isLoading: false });
      this.props.clearPaymentStates();
    } else {
      toast.error("Please Select Payment list first");
    }
  };

  //when click on more details link
  handleMoreDetails = (detail) => {
    this.openModal("openPaymentsMoreDetailsModal");
  };

  //add commnet
  addComment = async (comment, params) => {
    if (this.state.guid) {
      if (comment) {
        this.setState({ isLoading: true });
        let data = {
          comment,
          ...params,
          guid: this.state.guid,
        };
        await this.props.addComment(data);
        if (this.props.payments.addCommentSuccess) {
          toast.success(this.props.payments.addCommentSuccess);
          let payComments = this.props.payments.addCommentData || [];
          this.setState({ payComments });
        }
        if (this.props.payments.addCommentError) {
          handleAPIErr(this.props.payments.addCommentError, this.props);
        }
        this.props.clearPaymentStates();
        this.setState({ isLoading: false });
      } else {
        toast.error("Please Enter Comment!");
      }
    } else {
      toast.error("Please Select Pyment First!");
    }
  };

  postPayment = async (data) => {
    // let { multipleTrans, id } = this.state;

    let { period, reportID, generateReport } = data;

    let obj = {
      guidList: this.state.guid,
      // period,
      reportID,
      generateReport: generateReport ? "Y" : "N",
    };

    this.setState({ isLoading: true });
    await this.props.postPayment(obj);
    if (this.props.payments.postPaymentSuccess) {
      toast.success(this.props.payments.postPaymentSuccess);

      let jsonData = this.props.payments.postPayment.reportJson || "";
      let reportFile = this.props.payments.postPayment.stReport || "";
      if (jsonData && reportFile) {
        localStorage.setItem("reportFile", reportFile);
        localStorage.setItem("jsonData", jsonData);
        localStorage.setItem("key", "test");
        var path =
          window.location.protocol +
          "//" +
          window.location.host +
          "/report-view";

        window.open(path, "_blank");
      }
      this.closeModal("openPostModal");
    }
    if (this.props.payments.postPaymentError) {
      handleAPIErr(this.props.payments.postPaymentError, this.props);
    }
    this.props.clearPaymentStates();
    this.setState({ isLoading: false });
  };

  exportPayment = async () => {
    let { guid, multipleTrans } = this.state;

    if (multipleTrans.length > 0) {
      this.setState({ isLoading: true });
      await this.props.exportPayment(guid);
      this.setState({ isLoading: false });

      if (this.props.payments.exportPaymentSuccess) {
        toast.success(this.props.payments.exportPaymentSuccess);

        let obj = {
          contentType: "application/vnd.ms-excel",
          attachment: this.props.payments.exportPayment || "",
        };
        downloadAttachments(obj, "orders");
      }
      if (this.props.payments.exportPaymentError) {
        handleAPIErr(this.props.payments.exportPaymentError, this.props);
      }
    } else {
      toast.error("Please Select Payment First!");
    }
  };

  primeEFTFile = async () => {
    this.setState({ isLoading: true });
    this.clearStates();

    await this.props.primeEFTFile();

    if (this.props.payments.primeEFTFileSuccess) {
      toast.success(this.props.payments.primeEFTFileSuccess);

      let eftFiledata = this.props.payments.primeEFTFile;

      let processDate = eftFiledata[0].processDate || "";
      let layout = eftFiledata[0].layout || "";
      let advancedOptions = eftFiledata[0].advancedOptions || "";

      advancedOptions.map((lst, i) => {
        if ((lst.type && lst.type.toLowerCase() === "list") || "droplist") {
          let valOptns = [];
          if (lst.options && lst.options.length > 0) {
            lst.options.map((o, i) => {
              valOptns.push({ label: o.value, value: o.value });
            });
          }
          lst.options = valOptns;
        }
        lst.id = uuidv1();
        lst.hide = false;
        return lst;
      });

      this.setState({
        advancedOptions,
        clonneAdvanceOptions: advancedOptions,
        processDate,
        layout,
      });
    }

    if (this.props.payments.primeEFTFileError) {
      handleAPIErr(this.props.payments.primeEFTFileError, this.props);
    }
    this.openModal("openEftFileModal");
    this.setState({ isLoading: false });
  };

  getRemittanceSettings = async () => {
    this.setState({ isLoading: true });
    // this.clearStates();

    let { emailAddress } = this.state;
    await this.props.getRemittanceSettings();

    if (this.props.payments.getRemittanceSettingsSuccess) {
      toast.success(this.props.payments.getRemittanceSettingsSuccess);

      let getRemittance = this.props.payments?.getRemittanceSettings || [];

      let advancedOptions = getRemittance[0].advancedOptions || "";
      let subject = getRemittance[0].subject || "";
      let message = getRemittance[0].message || "";
      let companyOptions = getRemittance[0].companyOptions || "";

      let { companyOpt } = this.state;
      companyOptions.map((item) => {
        companyOpt.push({ label: item.name, value: item.id });
      });

      advancedOptions.map((lst, i) => {
        if ((lst.type && lst.type.toLowerCase() === "list") || "droplist") {
          let valOptns = [];
          if (lst.options && lst.options.length > 0) {
            lst.options.map((o, i) => {
              valOptns.push({ label: o.option, value: o.option });
            });
          }
          lst.options = valOptns;
        }
        lst.id = uuidv1();
        lst.hide = false;
        return lst;
      });

      companyOptions.map((item) => {
        emailAddress.push({ label: item.email, value: item.value });
      });
      this.setState({
        advancedOptions,
        clonneAdvanceOptions: advancedOptions,
        subject,
        message,
        companyOptions,
        emailAddress,
        companyOpt,
      });
    }

    if (this.props.payments.getRemittanceSettingsError) {
      handleAPIErr(this.props.payments.getRemittanceSettingsError, this.props);
    }
    this.openModal("openRemittancesModal");
    this.setState({ isLoading: false });
    // this.clearStates();
  };

  primePositivePay = async () => {
    this.setState({ isLoading: true });
    this.clearStates();

    await this.props.primePositivePay();

    if (this.props.payments.primePositivePaySuccess) {
      toast.success(this.props.payments.primePositivePaySuccess);
      let primePositive = this.props.payments?.primePositivePay || [];

      let reportOptions = primePositive[0]?.reportOptions || [];
      let advancedOptions = primePositive[0]?.advancedOptions || [];

      let { reportOpt } = this.state;
      reportOptions.map((item) => {
        reportOpt.push({ label: item.layout, value: item.id });
      });

      advancedOptions.map((lst, i) => {
        if ((lst.type && lst.type.toLowerCase() === "list") || "droplist") {
          let valOptns = [];
          if (lst.options && lst.options.length > 0) {
            lst.options.map((o, i) => {
              valOptns.push({ label: o.value, value: o.value });
            });
          }
          lst.options = valOptns;
        }
        lst.id = uuidv1();
        lst.hide = false;
        return lst;
      });

      // companyOptions.map((item) => {
      //   emailAddress.push({ label: item.email, value: item.value });
      // });
      this.setState({
        advancedOptions,
        clonneAdvanceOptions: advancedOptions,
        reportOptions,
        reportOpt,
      });

      this.openModal("openPositivePayModal");
    }

    if (this.props.payments.primePositivePayError) {
      handleAPIErr(this.props.payments.primePositivePayError, this.props);
    }

    this.setState({ isLoading: false });
    this.clearStates();
  };

  primePrintCheque = async () => {
    this.setState({ isLoading: true });

    await this.props.primePrintCheque(this.state.selectedGuid);

    if (this.props.payments.primePrintChequeSuccess) {
      toast.success(this.props.payments.primePrintChequeSuccess);
      let primePositive = this.props.payments?.primePrintCheque || [];

      let reportOptions = primePositive[0]?.reportOptions || [];
      let selectedItems = primePositive[0]?.selectedItems || [];

      let advancedOptions = primePositive[0]?.advancedOptions || [];
      let chequeID = selectedItems[0].chequeID;
      let { reportOptionsArr } = this.state;
      reportOptions.map((opt) => {
        reportOptionsArr.push({
          label: opt.layout,
          value: opt.id,
        });
      });

      advancedOptions.map((lst, i) => {
        if ((lst.type && lst.type.toLowerCase() === "list") || "droplist") {
          let valOptns = [];
          if (lst.options && lst.options.length > 0) {
            lst.options.map((o, i) => {
              valOptns.push({ label: o.value, value: o.value });
            });
          }
          lst.options = valOptns;
        }
        lst.id = uuidv1();
        lst.hide = false;
        return lst;
      });

      this.setState({
        advancedOptions,
        clonneAdvanceOptions: advancedOptions,
        reportOptionsArr,
        chequeID,
        reportOptions,
        selectedItems,
      });
    }

    if (this.props.payments.primePrintChequeError) {
      handleAPIErr(this.props.payments.primePrintChequeError, this.props);
    }

    this.setState({ isLoading: false });
    // this.clearStates();
    this.openModal("openChequePrintModal");
  };

  printCheque = async (data) => {
    this.setState({ isLoading: true });
    await this.props.printCheque(data);

    if (this.props.payments.printChequeSuccess) {
      toast.success(this.props.payments.printChequeSuccess);
      let primePositive = this.props.payments?.printCheque || [];
    }

    if (this.props.payments.printChequeError) {
      handleAPIErr(this.props.payments.printChequeError, this.props);
    }
    this.closeModal("openChequePrintModal");

    this.setState({ isLoading: false });
  };

  saveAndSendRemittances = async (sendRemittance) => {
    this.setState({ isLoading: true });
    await this.props.saveAndSendRemittances(sendRemittance);

    if (this.props.payments.saveAndSendRemittancesSuccess) {
      toast.success(this.props.payments.saveAndSendRemittancesSuccess);
      let sendRemittance = this.props.payments?.saveAndSendRemittances || [];

      let advancedOptions = sendRemittance[0]?.advancedOptions || [];

      this.setState({ advancedOptions });
    }

    if (this.props.payments.saveAndSendRemittancesError) {
      handleAPIErr(this.props.payments.saveAndSendRemittancesError, this.props);
    }

    this.setState({ isLoading: false });
  };

  handleCheckbox = (e, data) => {
    this.setState({ checked: !this.state.checked });
    let { getPaymentsList, multipleTrans } = this.state;
    let { name, checked } = e.target;
    let allSelected = false;
    if (data === "checkAll" && name === "checkboxAll") {
      let multipleTransCopy = [];
      if (checked) {
        allSelected = true;
        getPaymentsList.map((m) => {
          m.checked = true;
          multipleTransCopy.push(m.guid);
          return m;
        });
      } else {
        allSelected = false;
        getPaymentsList.map((m) => {
          m.checked = false;
          return m;
        });
      }
      multipleTrans = [...multipleTransCopy];
    } else {
      if (checked) {
        getPaymentsList.map((invc, i) => {
          if (data.id === invc.id) {
            invc.checked = true;
          }
          return invc;
        });
        multipleTrans.push(data.guid);
      } else {
        getPaymentsList.map(async (invc, i) => {
          if (data.id === invc.id) {
            invc.checked = false;
          }
          return invc;
        });
        let filteredMultiTrans = multipleTrans.filter((t) => t !== data.guid);
        multipleTrans = filteredMultiTrans;
      }
    }
    this.setState({
      getPaymentsList,
      multipleTrans,
      allSelected,
    });
  };

  // handleCheckbox = (e, data) => {
  //   let { getPaymentsList, clonedGetPaymentsList, multipleTrans } = this.state;
  //   let arr = [];
  //   let arr1 = [];
  //   if (data === "checkAll") {
  //     getPaymentsList.map((item) => {()
  //       if (item.checked === true) {
  //         item.checked = false;
  //         multipleTrans = item.tran;
  //         return item;
  //       } else {
  //         item.checked = true;
  //         multipleTrans = item.tran;
  //       }
  //     });
  //     this.setState({ getPaymentsList, multipleTrans });
  //   } else {
  //     // getPaymentsList.map((i) => (i.checked = false));
  //     getPaymentsList.map((item) => {
  //       if (item.id === data.id) {
  //         if (item.checked === true) {
  //           item.checked = false;
  //         } else {
  //           item.checked = true;
  //           arr.push(item.tran);
  //         }
  //         arr1 = [...arr];
  //       }
  //     });
  //     this.setState({ getPaymentsList });
  //   }
  // };

  handleRightSidebar = () => {
    this.setState((prevState) => ({
      toggleRightSidebar: !prevState.toggleRightSidebar,
    }));
  };
  updatePaymentSummaryState = async () => {
    if (this.props.payments.updateApprovalGroupSuccess) {
      toast.success(this.props.payments.updateApprovalGroupSuccess);
    }
    if (this.props.payments.updateApprovalGroupError) {
      handleAPIErr(this.props.payments.updateApprovalGroupError, this.props);
    }
  };

  openModal = async (name) => {
    this.setState({ [name]: true });
  };

  closeModal = (name) => {
    this.setState({
      [name]: false,
    });
  };

  clearStates = () => {
    this.setState({
      advancedOptions: [],
      activePaymentTallis: "",
      showPaymentTallisTabPane: "",

      activePayment: "",
      getPaymentsList: [],
      clonedGetPaymentsList: [],
      subject: "",
      message: "",
      companyOptions: [],
      emailAddress: [],
      companyOpt: [],
      clonneAdvanceOptions: [],
      reportOptions: [],
      reportOpt: [],
      reportOptionsArr: [],
      approvalGroup: "",
      selectedItems: "",
      paymentssMoreDetails: "", //when click on payments more details
      openPaymentsMoreDetailsModal: false,
      description: "",
      accountName: "",
      supplier: "",
      approvalOptions: "",
      accountCode: "",
      paid: "",
      supplierCode: "",
      userName: "",
      amount: "",
      // filteredPaymentsList: [],
    });
  };

  settPreviewArrows = () => {
    // Make the arrows be a bit smarter. Dont appear
    //if only one attachment, hide the left or right arrow if on "first" or "last" document.
    if ($(".carousel-item.active").attr("id") === "0") {
      $(".carousel-control-prev").hide();
      $(".carousel-control-next").show();
    } else if ($(".carousel-inner .carousel-item:last").hasClass("active")) {
      $(".carousel-control-prev").show();
      $(".carousel-control-next").hide();
    } else {
      $(".carousel-control-prev").show();
      $(".carousel-control-next").show();
    }
  };

  onDocumentLoadSuccess = (data, index) => {
    let numPages = data.numPages;
    let { numPagesArr } = this.state;
    numPagesArr[index] = numPages;

    if (index === 0) {
      this.setState({ numPages });
    }
    this.setState({ numPagesArr }, () => this.settPreviewArrows());
  };
  onLoadSuccessPage = (page) => {
    var vw = $(window).width();
    var vw = $(".side-attachments").width();
    var nav = $(".navbar.fixed-top").height();
    var underNav = $(".order-tabs").height();
    var wh = $(window).height();
    var h = wh - nav - 60;
    var rsb = wh - nav - underNav - 20;
    // var pdfDiv = wh - nav - underNav - 80
    var pdfDiv = wh - nav;
    var wh = wh - nav - underNav;
    $("#order--dynamic--height").css("height", h);
    $(".side-attachments,.side-attachments-2").css("height", rsb);
    // $('#maped_image').css('height', pdfDiv)
    $(".invoice_pdf_canvas.invoice_pdf_new").css("height", wh);
  };
  goToPrevPage = async () => {
    let { pageNumber } = this.state;
    if (pageNumber - 1 >= 1) {
      this.setState({ pageNumber: pageNumber - 1 });
    }
  };

  goToNextPage = () => {
    let { pageNumber, numPages } = this.state;
    if (pageNumber + 1 <= numPages) {
      this.setState({ pageNumber: pageNumber + 1 });
    }
  };

  onSlideChange = () => {
    //carusal get active slide
    this.setState({ pageNumber: 1, rotate: 0 });
    //it takes time to render in DOM
    setTimeout(() => {
      var currentIndex = $(".carousel-item.active").attr("id");
      let numPages = this.state.numPagesArr[currentIndex] || 1;
      this.setState({ numPages });

      this.settPreviewArrows();
    }, 700);
  };

  zoomIn = async () => {
    $(".invoice_pdf_new").removeClass("invoice_carousel_pdf");
    $(".invoice_pdf_new").removeClass("full_screen_convas");

    let { scaling } = this.state;

    let { scale, dropdownZoomingValue, zoom } = zoomIn(scaling);

    this.setState(
      {
        scaling: scale,
        dropdownZoomingValue,
      },
      () => {
        localStorage.setItem("invoiceZoom", zoom);

        if (
          scaling == 2.5 ||
          scaling == 2.2 ||
          scaling == 1.9 ||
          scaling == 1.6 ||
          scaling == 1.3
        ) {
          if ($(window).width() >= 2045) {
            $(".invoice_pdf_new").addClass("invoice_pdf_new1");
          }
        } else {
          if ($(window).width() < 2045) {
            $(".invoice_pdf_new").removeClass("invoice_pdf_new1");
          }
        }
      }
    );
  };

  zoomOut = async () => {
    $(".invoice_pdf_new").removeClass("invoice_carousel_pdf");
    $(".invoice_pdf_new").removeClass("full_screen_convas");

    let { scaling } = this.state;

    let { scale, dropdownZoomingValue, zoom } = zoomOut(scaling);

    this.setState(
      {
        scaling: scale,
        dropdownZoomingValue,
      },
      () => {
        localStorage.setItem("invoiceZoom", zoom);
        if (
          scaling == 2.5 ||
          scaling == 2.2 ||
          scaling == 1.9 ||
          scaling == 1.6 ||
          scaling == 1.3
        ) {
          if ($(window).width() >= 2045) {
            $(".invoice_pdf_new").addClass("invoice_pdf_new1");
          }
        } else {
          if ($(window).width() < 2045) {
            $(".invoice_pdf_new").removeClass("invoice_pdf_new1");
          }
        }
      }
    );
  };

  handleDropdownZooming = async (data) => {
    $(".invoice_pdf_new").removeClass("invoice_carousel_pdf");
    $(".invoice_pdf_new").removeClass("full_screen_convas");

    let value = data.value;

    localStorage.setItem("invoiceZoom", value);

    let { scale, dropdownZoomingValue } = handleDropdownZooming(value);

    this.setState(
      {
        scaling: scale,
        dropdownZoomingValue,
      },
      () => {
        if (
          value == "25%" ||
          value == "20%" ||
          value == "15%" ||
          value == "10%" ||
          value == "5%"
        ) {
          if ($(window).width() < 2045) {
            $(".invoice_pdf_new").addClass("invoice_pdf_new1");
          }
        } else {
          if ($(window).width() < 2045) {
            $(".invoice_pdf_new").removeClass("invoice_pdf_new1");
          }
        }
      }
    );
  };

  handleHorizontalCross = () => {
    $(".mm_invoice_div").addClass("over_auto_remove");
    $(".invoice_pdf_new").addClass("full_screen_convas");

    var vw = $(window).width();
    var nav = $(".navbar.fixed-top").height();
    var underNav = $(".order-tabs").height();
    var wh = $(window).height();
    var pdfDiv = wh - nav;
    var wh = wh - nav - underNav;

    $(".invoice_pdf_new").removeClass("invoice_carousel_pdf");
    $(".invoice_pdf_canvas.invoice_pdf_new").css("height", wh);

    if ($(window).width() > 1500) {
      this.setState({
        scaling: 7,
        dropdownZoomingValue: { label: "100%", value: "100%" },
      });
    } else if ($(window).width() <= 1500) {
      this.setState({
        scaling: 3.1,
        dropdownZoomingValue: { label: "35%", value: "35%" },
      });
    }
  };

  handleHorizontalArrow = () => {
    $(".invoice_pdf_new").addClass("invoice_carousel_pdf");

    $(".invoice_pdf_new").removeClass("full_screen_convas");

    if ($(window).width() > 1500) {
      this.setState({
        scaling: 7,
        dropdownZoomingValue: { label: "100%", value: "100%" },
      });
    } else if ($(window).width() <= 1500) {
      this.setState({
        scaling: 3.1,
        dropdownZoomingValue: { label: "35%", value: "35%" },
      });
    }
  };

  handleRowsSetting = (e) => {
    // let { activePOTallis, showPOTallisTabPane } = this.state;

    // let obj = {
    //   id: activePOTallis,
    //   type: showPOTallisTabPane,
    // };
    localStorage.setItem("orderDPR", e.target.value); // no of display order pages

    this.setState({ display: e.target.value }, () => {
      // this.getNewPOList(obj);
    });
  };

  render() {
    let { showPaymentTallisTabPane } = this.state;
    let tab =
      (showPaymentTallisTabPane && showPaymentTallisTabPane.toLowerCase()) ||
      "";

    let userType = localStorage.getItem("userType");
    let approverCheck = false;
    let checkTwo = false;
    if (userType) {
      if (userType.toLowerCase() === "approver") {
        approverCheck = true;
      }
    }

    if (tab) {
      if (tab === "pending" || tab === "declined") {
        //when tab is pending or declined then everything is read only for Approver
        if (approverCheck) {
          checkTwo = true;
        }
      }
    }

    let usePageLoading = localStorage.getItem("usePageLoading") || "N";
    usePageLoading = usePageLoading.toLowerCase();

    return (
      <>
        {this.state.isLoading ? <div className="se-pre-con"></div> : ""}

        <div className="dashboard">
          {/* top nav bar */}
          <Header props={this.props} payments={true} />
          {/* end */}

          {/* body part */}

          <div className="dashboard_body_content dash__invoice--content">
            {/* top Nav menu*/}
            <TopNav />
            {/* end */}

            {/* side menu paym*/}
            <aside
              className="side-nav suppliers_side_nav side__content--invoice"
              id="show-side-navigation12"
            >
              <div className="cus-arro-div2">
                <img
                  src="images/arrow-r.png"
                  className=" img-fluid cus-arro-r"
                  alt="user"
                />
              </div>
              <div className="search">
                <div className="row">
                  <div className="col-auto mb-2 pr-0">
                    <div className="form-group remember_check custom-checkbox-ui">
                      <input
                        type="checkbox"
                        id={"order"}
                        name="checkboxAll"
                        checked={this.state.allSelected}
                        onChange={(e) => this.handleCheckbox(e, "checkAll")}
                      />
                      <label
                        htmlFor={"order"}
                        className="mr-0 custom-box"
                      ></label>
                    </div>
                  </div>
                  <div className="col-auto pr-md-0 align-self-center ml-1">
                    <Dropdown
                      alignRight={false}
                      drop="down"
                      className="analysis-card-dropdwn custom-my-radio user_drop_options"
                    >
                      <Dropdown.Toggle
                        variant="sucess"
                        id="dropdown-basic"
                        className="custom-angle-down"
                      >
                        <img src="images/angle-down.png" alt="arrow" />
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        {tab === "draft" ? (
                          <>
                            <Dropdown.Item
                              to="#/action-2"
                              className="f-20 flex-container-inner"
                              onClick={() =>
                                this.handleSortInvoiceLists("quickCheck")
                              }
                            >
                              <div className="custom-control custom-radio flex-container-inner">
                                <input
                                  type="radio"
                                  className="custom-control-input flex-container-inner-input"
                                  id="approvalDate"
                                  name="approvalDate"
                                  onChange={() => {}}
                                  checked={
                                    this.state.sortFilterInvc === "quickCheck"
                                  }
                                />
                                <label
                                  className="custom-control-label flex-container-inner-input"
                                  htmlFor="approvalDate"
                                >
                                  Quick Chq
                                </label>
                                {
                                  this.state.sortFilterInvc === "quickCheck" ?
                                    (
                                      this.state.sortFilterCheckPayment === "DESC" ? <i className="fa fa-angle-up ml-2 mt-1"></i> : <i className="fa fa-angle-down ml-2 mt-1"></i>
                                    ) : ("")
                                }
                              </div>
                            </Dropdown.Item>

                            <Dropdown.Item
                              to="#/action-2"
                              className="f-20 flex-container-inner"
                              onClick={() =>
                                this.handleSortInvoiceLists("dueDate")
                              }
                            >
                              <div className="custom-control custom-radio flex-container-inner">
                                <input
                                  type="radio"
                                  className="custom-control-input flex-container-inner-input"
                                  id="approvalDate"
                                  name="approvalDate"
                                  onChange={() => {}}
                                  checked={
                                    this.state.sortFilterInvc === "dueDate"
                                  }
                                />
                                <label
                                  className="custom-control-label flex-container-inner-input"
                                  htmlFor="approvalDate"
                                >
                                  Upto Due Date
                                </label>
                                {
                                  this.state.sortFilterInvc === "dueDate" ?
                                    (
                                      this.state.sortFilterCheckPayment === "DESC" ? <i className="fa fa-angle-up ml-2 mt-1"></i> : <i className="fa fa-angle-down ml-2 mt-1"></i>
                                    ) : ("")
                                }
                              </div>
                            </Dropdown.Item>

                            {/*<Dropdown.Item*/}
                            {/*  to="#/action-2"*/}
                            {/*  className="f-20 flex-container-inner"*/}
                            {/*  onClick={() =>*/}
                            {/*    this.handleSortInvoiceLists("approvalDate")*/}
                            {/*  }*/}
                            {/*>*/}
                            {/*  <div className="custom-control custom-radio flex-container-inner">*/}
                            {/*    <input*/}
                            {/*      type="radio"*/}
                            {/*      className="custom-control-input flex-container-inner-input"*/}
                            {/*      id="approvalDate"*/}
                            {/*      name="approvalDate"*/}
                            {/*      onChange={() => {}}*/}
                            {/*      checked={*/}
                            {/*        this.state.sortFilterInvc === "approvalDate"*/}
                            {/*      }*/}
                            {/*    />*/}
                            {/*    <label*/}
                            {/*      className="custom-control-label flex-container-inner-input"*/}
                            {/*      htmlFor="approvalDate"*/}
                            {/*    >*/}
                            {/*      Bank Code*/}
                            {/*    </label>*/}
                            {/*    {*/}
                            {/*      this.state.sortFilterInvc === "approvalDate" ?*/}
                            {/*        (*/}
                            {/*          this.state.sortFilterCheckPayment === "DESC" ? <i className="fa fa-angle-up ml-2 mt-1"></i> : <i className="fa fa-angle-down ml-2 mt-1"></i>*/}
                            {/*        ) : ("")*/}
                            {/*    }*/}
                            {/*  </div>*/}
                            {/*</Dropdown.Item>*/}
                          </>
                        ) : (
                          ""
                        )}
                        <Dropdown.Item
                          to="#/action-2"
                          className="f-20 flex-container-inner"
                          onClick={() =>
                            this.handleSortInvoiceLists("supplierName")
                          }
                        >
                          <div className="custom-control custom-radio flex-container-inner">
                            <input
                              type="radio"
                              className="custom-control-input flex-container-inner-input"
                              id="supplierName"
                              name="supplierName"
                              onChange={() => {}}
                              checked={
                                this.state.sortFilterInvc === "supplierName"
                              }
                            />
                            <label
                              className="custom-control-label flex-container-inner-input"
                              htmlFor="supplierName"
                            >
                              Supplier
                            </label>
                            {
                              this.state.sortFilterInvc === "supplierName" ?
                                (
                                  this.state.sortFilterCheckPayment === "DESC" ? <i className="fa fa-angle-up ml-2 mt-1"></i> : <i className="fa fa-angle-down ml-2 mt-1"></i>
                                ) : ("")
                            }
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item
                          to="#/action-2"
                          className="f-20 flex-container-inner"
                          onClick={() =>
                            this.handleSortInvoiceLists("invoiceNumber")
                          }
                        >
                          <div className="custom-control custom-radio flex-container-inner">
                            <input
                              type="radio"
                              className="custom-control-input flex-container-inner-input"
                              id="invoiceNumber"
                              name="invoiceNumber"
                              onChange={() => {}}
                              checked={
                                this.state.sortFilterInvc === "invoiceNumber"
                              }
                            />
                            <label
                              className="custom-control-label flex-container-inner-input"
                              htmlFor="invoiceNumber"
                            >
                              Invoice Number
                            </label>
                            {
                              this.state.sortFilterInvc === "invoiceNumber" ?
                                (
                                  this.state.sortFilterCheckPayment === "DESC" ? <i className="fa fa-angle-up ml-2 mt-1"></i> : <i className="fa fa-angle-down ml-2 mt-1"></i>
                                ) : ("")
                            }
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item
                          to="#/action-2"
                          className="f-20 flex-container-inner"
                          onClick={() => this.handleSortInvoiceLists("date")}
                        >
                          <div className="custom-control custom-radio flex-container-inner">
                            <input
                              type="radio"
                              className="custom-control-input flex-container-inner-input"
                              id="date"
                              name="date"
                              onChange={() => {}}
                              checked={this.state.sortFilterInvc === "date"}
                            />
                            <label
                              className="custom-control-label flex-container-inner-input"
                              htmlFor="date"
                            >
                              Date
                            </label>
                            {
                              this.state.sortFilterInvc === "date" ?
                                (
                                  this.state.sortFilterCheckPayment === "DESC" ? <i className="fa fa-angle-up ml-2 mt-1"></i> : <i className="fa fa-angle-down ml-2 mt-1"></i>
                                ) : ("")
                            }
                          </div>
                        </Dropdown.Item>

                        <Dropdown.Item
                          to="#/action-2"
                          className="f-20 flex-container-inner"
                          onClick={() => this.handleSortInvoiceLists("amount")}
                        >
                          <div className="custom-control custom-radio flex-container-inner">
                            <input
                              type="radio"
                              className="custom-control-input flex-container-inner-input"
                              id="amount"
                              name="amount"
                              onChange={() => {}}
                              checked={this.state.sortFilterInvc === "amount"}
                            />
                            <label
                              className="custom-control-label flex-container-inner-input"
                              htmlFor="amount"
                            >
                              Amount
                            </label>
                            {
                              this.state.sortFilterInvc === "amount" ?
                                (
                                  this.state.sortFilterCheckPayment === "DESC" ? <i className="fa fa-angle-up ml-2 mt-1"></i> : <i className="fa fa-angle-down ml-2 mt-1"></i>
                                ) : ("")
                            }
                          </div>
                        </Dropdown.Item>

                        <Dropdown.Item
                          to="#/action-2"
                          className="f-20 flex-container-inner"
                          onClick={() =>
                            this.handleSortInvoiceLists("userName")
                          }
                        >
                          <div className="custom-control custom-radio flex-container-inner">
                            <input
                              type="radio"
                              className="custom-control-input flex-container-inner-input"
                              id="userName"
                              name="userName"
                              onChange={() => {}}
                              checked={this.state.sortFilterInvc === "userName"}
                            />
                            <label
                              className="custom-control-label flex-container-inner-input"
                              htmlFor="userName"
                            >
                              Operator
                            </label>
                            {
                              this.state.sortFilterInvc === "userName" ?
                                (
                                  this.state.sortFilterCheckPayment === "DESC" ? <i className="fa fa-angle-up ml-2 mt-1"></i> : <i className="fa fa-angle-down ml-2 mt-1"></i>
                                ) : ("")
                            }
                          </div>
                        </Dropdown.Item>

                        {/*<Dropdown.Item*/}
                        {/*  to="#/action-2"*/}
                        {/*  className="f-20 flex-container-inner"*/}
                        {/*  onClick={() =>*/}
                        {/*    this.handleSortInvoiceLists("approver")*/}
                        {/*  }*/}
                        {/*>*/}
                        {/*  <div className="custom-control custom-radio flex-container-inner">*/}
                        {/*    <input*/}
                        {/*      type="radio"*/}
                        {/*      className="custom-control-input flex-container-inner-input"*/}
                        {/*      id="approver"*/}
                        {/*      name="approver"*/}
                        {/*      onChange={() => {}}*/}
                        {/*      checked={this.state.sortFilterInvc === "approver"}*/}
                        {/*    />*/}
                        {/*    <label*/}
                        {/*      className="custom-control-label flex-container-inner-input"*/}
                        {/*      htmlFor="approver"*/}
                        {/*    >*/}
                        {/*      Approver*/}
                        {/*    </label>*/}
                        {/*    {*/}
                        {/*      this.state.sortFilterInvc === "approver" ?*/}
                        {/*        (*/}
                        {/*          this.state.sortFilterCheckPayment === "DESC" ? <i className="fa fa-angle-up ml-2 mt-1"></i> : <i className="fa fa-angle-down ml-2 mt-1"></i>*/}
                        {/*        ) : ("")*/}
                        {/*    }*/}
                        {/*  </div>*/}
                        {/*</Dropdown.Item>*/}
                        {usePageLoading === "y" ? (
                          <div className="custom-control custom-radio cutm-pr-right">
                            <div className="settings_display_row flex__wrapper-inner">
                              <label className="labelwrapper__iner lable-inner-wrapper">
                                Display Rows Per Page
                              </label>
                              <input
                                className="form-control input__wrapper--inner "
                                type="number"
                                min="1"
                                defaultValue={this.state.display}
                                onBlur={this.handleRowsSetting}
                              />
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </Dropdown.Menu>
                    </Dropdown>{" "}
                  </div>
                  <div className="col input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text" id="basic-addon1">
                        <img
                          src="images/search-icon.png"
                          className="mx-auto"
                          alt="search-icon"
                        />
                      </span>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="What are you looking for"
                      aria-label="What are you looking for"
                      aria-describedby="basic-addon1"
                      name="POListSearch"
                      id="POListSearchId"
                      value={this.state.paymentsListSearch}
                      onChange={this.handleChangeInvoiceListSearch}
                      onKeyDown={this.onPaymentListSearch}
                    />
                  </div>
                </div>
              </div>

              <ul
                className={
                  Number(this.state.totalPages) !== 0
                    ? usePageLoading === "y"
                      ? "suppliers_list list__color-bottom"
                      : "suppliers_list"
                    : "suppliers_list"
                }
              >
                {this.state.getPaymentsList.map((l, i) => {
                  return (
                    <li
                      key={i}
                      className={
                        l.teamInvoice === "Y"
                          ? this.state.getPaymentsList[i + 1] &&
                            this.state.getPaymentsList[i + 1].teamInvoice &&
                            this.state.getPaymentsList[i + 1].teamInvoice ===
                              "Y"
                            ? "teamOrdersBg teamOrdersBorder2 cursorPointer"
                            : "teamOrdersBg teamOrdersBorder cursorPointer"
                          : this.state.activePayment === l.id
                          ? "active cursorPointer"
                          : "cursorPointer"
                      }
                      id={l.id}
                    >
                      <div className="row">
                        <div className="col-auto mb-2 pr-0">
                          <div className="form-group remember_check">
                            <input
                              type="checkbox"
                              id={"invoice" + i}
                              checked={l.checked}
                              name="checkbox"
                              onChange={(e) => this.handleCheckbox(e, l)}
                            />
                            <label
                              htmlFor={"invoice" + i}
                              className="mr-0"
                            ></label>
                          </div>
                        </div>
                        <div
                          onClick={() => this.getPaymentSummary(l)}
                          className="col pl-0"
                        >
                          <div className="invioce_data pr-sm-3">
                            <h4>{l.supplierName}</h4>
                            <div className="row">
                              <div className="col data-i">
                                <p> {l.invoiceNumber}</p>
                              </div>
                              <div className="col-auto data-i">
                                <p>
                                  {l.date ? l.date.trim() : ""}

                                  {/* {moment.unix(l.date).format("DD-MMM-YYYY")} */}
                                </p>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col data-i">
                                <p>
                                  <b>Tax:</b> {l.taxAmount}
                                </p>
                              </div>
                              <div className="col-auto data-i">
                                <p>
                                  <b>Due:</b>{" "}
                                  {l.dueDate ? l.dueDate.trim() : ""}
                                  {/* {moment.unix(l.dueDate).format("DD-MMM-YYYY")} */}
                                </p>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col data-i">
                                <p>
                                  <b>Total:</b> {l.amount}
                                </p>
                              </div>
                              <div className="col-auto data-i">
                                <div className="text-center cursorPointer">
                                  <p onClick={() => this.handleMoreDetails(l)}>
                                    <Link className="more-details-color" to="#">
                                      more details
                                    </Link>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  );
                })}
              </ul>
              {Number(this.state.totalPages) !== 0 ? (
                usePageLoading === "y" ? (
                  <div className="pagination__list-custom">
                    <p className="mb__zero pt-top-container">
                      Showing {this.state.pageStart} to {this.state.pageEnd} of{" "}
                      {this.state.totalPayments} entries
                    </p>
                    <ReactPaginate
                      tabIndex="-1"
                      previousLabel={"prev"}
                      pageLinkClassName={"pagination__list-custom-link   "}
                      pageClassName={"pagination__list-custom-li "}
                      activeLinkClassName={"pagination__tab-custm"}
                      nextLabel={"next"}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={this.state.totalPages}
                      marginPagesDisplayed={1}
                      pageRangeDisplayed={2}
                      onPageChange={this.handlePageClick}
                      containerClassName={
                        "pagination cursorPointer align-center-container"
                      }
                      activeClassName={"active"}
                      forcePage={this.state.page - 1}
                    />
                  </div>
                ) : (
                  ""
                )
              ) : (
                ""
              )}
            </aside>
            {/* {/ end /} */}

            <section id="contents" className="supplier pr-0 pt-0">
              <div className="body_content ordermain-padi body__invoice--top">
                <div className="container-fluid pl-0 ">
                  <div className="main_wrapper " id="order--dynamic--height">
                    <div className="row d-flex pl-15">
                      <div className="col-12 w-100 order-tabs p-md-0">
                        {/* paym Tallies */}
                        <div className="nav_tav_ul">
                          <ul className="nav nav-tabs">
                            {this.state.getPaymentTallies.map((t, i) => {
                              return (
                                <li
                                  key={i}
                                  className="nav-item cursorPointer"
                                  onClick={() =>
                                    this.getPaymentTallies(t.type, true)
                                  }
                                >
                                  <a
                                    className={
                                      this.state.activePaymentTallis === t.id
                                        ? "nav-link active"
                                        : "nav-link"
                                    }
                                    // data-toggle="tab"
                                    // href={"#" + `${t.type}`}
                                  >
                                    {t.type}{" "}
                                    <span className="stats">{t.count}</span>
                                  </a>
                                </li>
                              );
                            })}
                          </ul>
                        </div>

                        <div className="bg-gry w-100 float-left mm_top_nav">
                          <div className="w-100 float-left mm_lr_pad">
                            <div className="mm_tab_left order_left_icons">
                              <div className="tab-content">
                                {tab === "draft" && (
                                  <div className="tab-pane container active">
                                    <ul>
                                      <li className="cursorPointer">
                                        <img
                                          src="images/add.png"
                                          className=" img-fluid "
                                          alt="user"
                                        />{" "}
                                        <Link to="#">New</Link>{" "}
                                      </li>
                                      <li className="cursorPointer">
                                        <img
                                          src="images/pencill.png"
                                          className=" img-fluid "
                                          alt="user"
                                        />{" "}
                                        <Link to="#">Edit</Link>{" "}
                                      </li>
                                      <li className="cursorPointer">
                                        <img
                                          src="images/copy1.png"
                                          className=" img-fluid "
                                          alt="user"
                                        />{" "}
                                        <Link to="#"> Copy </Link>
                                      </li>
                                      <li className="cursorPointer">
                                        <img
                                          src="images/delete.svg"
                                          className="invoice-delete-icon img-fluid "
                                          alt="user"
                                        />{" "}
                                        <Link to="#"> Delete </Link>
                                      </li>
                                      <li className="cursorPointer">
                                        <img
                                          src="images/send.png"
                                          className=" img-fluid "
                                          alt="user"
                                        />{" "}
                                        <Link
                                          to="#"
                                          onClick={this.sendForApprovalPayment}
                                        >
                                          {" "}
                                          Send{" "}
                                        </Link>
                                      </li>
                                    </ul>
                                  </div>
                                )}
                                {tab === "approve" && (
                                  <div
                                    className={
                                      1 === 0
                                        ? "tab-pane container"
                                        : "tab-pane container active"
                                    }
                                  >
                                    <ul>
                                      <li
                                        onClick={this.approvePO}
                                        className="cursorPointer"
                                      >
                                        <img
                                          src="images/tick.png"
                                          className="img-fluid "
                                          alt="user"
                                        />{" "}
                                        <Link
                                          to="#"
                                          onClick={this.approvePayment}
                                        >
                                          {" "}
                                          Approve{" "}
                                        </Link>
                                      </li>
                                      <li
                                        onClick={this.holdPO}
                                        className="cursorPointer"
                                      >
                                        <img
                                          src="images/move.png"
                                          className=" img-fluid"
                                          alt="user"
                                        />{" "}
                                        <Link to="#" onClick={this.holdPayment}>
                                          {" "}
                                          Hold{" "}
                                        </Link>
                                      </li>
                                      <li
                                        onClick={() =>
                                          this.openModal("openDeclineModal")
                                        }
                                        className="cursorPointer"
                                      >
                                        <img
                                          src="images/decline.png"
                                          className=" img-fluid "
                                          alt="user"
                                        />{" "}
                                        <Link
                                          to="#"
                                          onClick={this.declinePayment}
                                        >
                                          {" "}
                                          Decline{" "}
                                        </Link>
                                      </li>
                                      <li
                                        onClick={this.openOrderDetailModal}
                                        className="cursorPointer"
                                      >
                                        <img
                                          src="images/pencill.png"
                                          className=" img-fluid "
                                          alt="user"
                                        />{" "}
                                        <Link to="#"> Edit </Link>
                                      </li>
                                    </ul>
                                  </div>
                                )}
                                {tab === "declined" && (
                                  <div
                                    className={
                                      1 === 0
                                        ? "tab-pane container"
                                        : "tab-pane container active"
                                    }
                                  >
                                    {!approverCheck && (
                                      <ul>
                                        <li className="cursorPointer">
                                          <img
                                            src="images/move.png"
                                            className=" img-fluid "
                                            alt="user"
                                          />{" "}
                                          <Link
                                            to="#"
                                            onClick={this.movePayment}
                                          >
                                            {" "}
                                            Move{" "}
                                          </Link>
                                        </li>
                                        )
                                      </ul>
                                    )}
                                  </div>
                                )}
                                {tab === "hold" && (
                                  <div
                                    className={
                                      1 === 0
                                        ? "tab-pane container"
                                        : "tab-pane container active"
                                    }
                                  >
                                    <ul>
                                      <li
                                        onClick={this.approvePO}
                                        className="cursorPointer"
                                      >
                                        <img
                                          src="images/tick.png"
                                          className=" img-fluid "
                                          alt="user"
                                        />{" "}
                                        <Link
                                          to="#"
                                          onClick={this.approvePayment}
                                        >
                                          {" "}
                                          Approve{" "}
                                        </Link>
                                      </li>
                                      <li
                                        onClick={() =>
                                          this.openModal("openDeclineModal")
                                        }
                                        className="cursorPointer"
                                      >
                                        <img
                                          src="images/decline.png"
                                          className=" img-fluid "
                                          alt="user"
                                        />{" "}
                                        <Link
                                          to="#"
                                          onClick={this.declinePayment}
                                        >
                                          {" "}
                                          Decline{" "}
                                        </Link>
                                      </li>
                                      <li
                                        onClick={this.openOrderDetailModal}
                                        className="cursorPointer"
                                      >
                                        <img
                                          src="images/pencill.png"
                                          className=" img-fluid "
                                          alt="user"
                                        />{" "}
                                        <Link to="#"> Edit </Link>
                                      </li>
                                    </ul>
                                  </div>
                                )}
                                {tab === "pending" && (
                                  <div
                                    className={
                                      1 === 0
                                        ? "tab-pane container"
                                        : "tab-pane container active"
                                    }
                                  >
                                    {!approverCheck && (
                                      <ul>
                                        <li
                                          onClick={() =>
                                            checkTwo
                                              ? this.openModal("")
                                              : this.openModal(
                                                  "openMoveToDraftModal"
                                                )
                                          }
                                          className="cursorPointer"
                                        >
                                          <img
                                            src="images/move.png"
                                            className=" img-fluid "
                                            alt="user"
                                          />{" "}
                                          <Link
                                            to="#"
                                            onClick={this.movePayment}
                                          >
                                            {" "}
                                            Move{" "}
                                          </Link>
                                        </li>
                                        <li
                                          onClick={() =>
                                            checkTwo
                                              ? this.openModal("")
                                              : this.openModal("openCopyModal")
                                          }
                                          className="cursorPointer"
                                        >
                                          <img
                                            src="images/copy1.png"
                                            className=" img-fluid "
                                            alt="user"
                                          />{" "}
                                          <Link to="#"> Copy </Link>
                                        </li>
                                      </ul>
                                    )}
                                  </div>
                                )}
                                {tab === "approved" && (
                                  <div
                                    className={
                                      1 === 0
                                        ? "tab-pane container"
                                        : "tab-pane container active"
                                    }
                                  >
                                    <ul>
                                      {!approverCheck && (
                                        <li
                                          onClick={() =>
                                            this.openModal("openCopyModal")
                                          }
                                          className="cursorPointer"
                                        >
                                          <img
                                            src="images/copy1.png"
                                            className=" img-fluid "
                                            alt="user"
                                          />{" "}
                                          <Link to="#"> Copy </Link>
                                        </li>
                                      )}
                                      <li
                                        onClick={() =>
                                          this.openModal("openCloseModal")
                                        }
                                        className="cursorPointer"
                                      >
                                        <img
                                          src="images/decline.png"
                                          className="top_0 img-fluid"
                                          alt="user"
                                        />{" "}
                                        <Link to="#"> Close </Link>
                                      </li>
                                      {!approverCheck && (
                                        <li
                                          onClick={() =>
                                            this.openModal("openModifyModal")
                                          }
                                          className="cursorPointer"
                                        >
                                          <img
                                            src="images/pencill.png"
                                            className=" img-fluid "
                                            alt="user"
                                          />{" "}
                                          <Link to="#"> Modify </Link>
                                        </li>
                                      )}
                                    </ul>
                                  </div>
                                )}
                                {tab === "all" && (
                                  <div
                                    className={
                                      1 === 0
                                        ? "tab-pane container"
                                        : "tab-pane container active"
                                    }
                                  >
                                    <ul>
                                      <li></li>
                                    </ul>
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="mm_tab_center order_right_icons ">
                              <div className="slider-panel">
                                <Link to="#" className="zom-img">
                                  <img
                                    onClick={this.zoomOut}
                                    src="images/minus.png"
                                    className=" img-fluid float-left"
                                    alt="user"
                                  />{" "}
                                </Link>
                                <Link to="#" className="zom-img">
                                  <img
                                    onClick={this.zoomIn}
                                    src="images/add.png"
                                    className=" img-fluid float-left"
                                    alt="user"
                                  />{" "}
                                </Link>
                                <Select
                                  className="width-selector"
                                  value={this.state.dropdownZoomingValue}
                                  classNamePrefix="custon_select-selector-inner"
                                  options={options}
                                  onChange={this.handleDropdownZooming}
                                  theme={(theme) => ({
                                    ...theme,
                                    border: 0,
                                    borderRadius: 0,
                                    colors: {
                                      ...theme.colors,
                                      primary25: "#f2f2f2",
                                      primary: "#f2f2f2",
                                    },
                                  })}
                                />
                                <Link to="#" className="zom-img">
                                  <img
                                    onClick={this.handleHorizontalCross}
                                    src="images/fulls.png"
                                    className="img-fluid float-left"
                                    alt="user"
                                    id="full_screen"
                                  />{" "}
                                </Link>
                                <Link to="#" className="zom-img">
                                  <img
                                    onClick={this.handleHorizontalArrow}
                                    src="images/twoarow.png"
                                    className="img-fluid float-left"
                                    alt="user"
                                    id="expand"
                                  />{" "}
                                </Link>

                                <Link
                                  to="#"
                                  className="zom-img float-right ml-md-5 pl-2 pr-2 mr-0 more-d mt-0"
                                >
                                  <img
                                    src="images/more.png"
                                    className=" img-fluid"
                                    alt="user"
                                  />{" "}
                                </Link>
                                <Link
                                  to="#"
                                  className="zom-img float-right mt-0"
                                  onClick={this.moveToNextPO}
                                >
                                  <img
                                    src="images/arow-r.png"
                                    className=" img-fluid lr-arrow-up"
                                    alt="user"
                                    href="#demo"
                                    data-slide="next"
                                  />{" "}
                                </Link>
                                <Link
                                  to="#"
                                  className="zom-img float-right mtop-1"
                                  onClick={this.moveToPrevPO}
                                >
                                  <img
                                    src="images/arow-l.png"
                                    className=" img-fluid lr-arrow-up"
                                    alt="user"
                                    href="#demo"
                                    data-slide="prev"
                                  />{" "}
                                </Link>
                                <div className="side-attachments-2 height-2 mm_order_sidebar aside__right--height">
                                  <div
                                    onClick={() =>
                                      this.openModal("openPostModal")
                                    }
                                    className="main-sec-attach main-bg"
                                  >
                                    Post
                                  </div>
                                  <div
                                    onClick={() =>
                                      this.openModal("openReportModal")
                                    }
                                    className="main-sec-attach main-bg"
                                  >
                                    Reports
                                  </div>
                                  <div className="main-sec-attach main-bg">
                                    <span
                                      className="export_crd"
                                      data-toggle="collapse"
                                      data-target="#export"
                                    >
                                      <span className="fa fa-angle-up float-left mr-2 sideBarAccord1"></span>
                                      Export
                                    </span>
                                  </div>
                                  <div className="collapse show" id="export">
                                    <div
                                      onClick={this.exportPayment}
                                      className="pl-2 mb-3"
                                    >
                                      <div className="form-group remember_check d-flex">
                                        <span className="text-mar cursorPointer ml-38">
                                          EXCEL
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    onClick={this.getRemittanceSettings}
                                    className="main-sec-attach main-bg"
                                  >
                                    Remittances
                                  </div>
                                  <div
                                    onClick={this.primePositivePay}
                                    className="main-sec-attach main-bg"
                                  >
                                    Positive Pay
                                  </div>
                                  {tab === "approved" && (
                                    <div
                                      onClick={this.primePrintCheque}
                                      className="main-sec-attach main-bg"
                                    >
                                      Cheque Print
                                    </div>
                                  )}
                                  <div
                                    onClick={this.primeEFTFile}
                                    className="main-sec-attach main-bg"
                                  >
                                    EFT File
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="img-section-t col-12 pl-0 h-100">
                      {this.state.getPaymentsList.length > 0 && (
                        <div
                          id="demo"
                          className={
                            this.state.toggleRightSidebar
                              ? " carousel slide invoice_carousel mm_invoice_div over_auto_remove"
                              : " carousel slide invoice_carousel "
                          }
                          // data-ride="carousel"
                          data-interval={false}
                        >
                          <ul className="carousel-indicators">
                            <li
                              data-target="#demo"
                              data-slide-to="0"
                              className="active"
                            ></li>
                            <li data-target="#demo" data-slide-to="1"></li>
                            <li data-target="#demo" data-slide-to="2"></li>
                          </ul>

                          <div className="carousel-inner">
                            {this.state.previewList.length > 0
                              ? this.state.previewList.map((p, i) => {
                                  return (
                                    <div
                                      key={i}
                                      className={
                                        i === 0
                                          ? "carousel-item active "
                                          : "carousel-item "
                                      }
                                      id={i}
                                    >
                                      <div className="text-center">
                                        <div className="invoice_pdf_canvas invoice_pdf_new pdf--buttons pdf__height--content expensis-home">
                                          {p.file ? (
                                            <>
                                              <Document
                                                file={
                                                  "data:application/pdf;base64," +
                                                  p.file
                                                }
                                                onLoadSuccess={(data) =>
                                                  this.onDocumentLoadSuccess(
                                                    data,
                                                    i
                                                  )
                                                }
                                                rotate={this.state.rotate}
                                              >
                                                <Page
                                                  pageNumber={
                                                    this.state.pageNumber
                                                  }
                                                  scale={this.state.scaling}
                                                  height="372"
                                                  onLoadSuccess={
                                                    this.onLoadSuccessPage
                                                  }
                                                />
                                              </Document>
                                              <div className="page-controls">
                                                <button
                                                  type="button"
                                                  disabled=""
                                                  // onClick={this.goToPrevPage}
                                                >
                                                  ‹
                                                </button>
                                                <span>
                                                  {this.state.pageNumber} of{" "}
                                                  {this.state.numPages}
                                                </span>{" "}
                                                <button
                                                  type="button"
                                                  // onClick={this.goToNextPage}
                                                >
                                                  ›
                                                </button>
                                              </div>
                                            </>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })
                              : "No Preview Found"}

                            {this.state.previewList.length > 1 && (
                              <>
                                <a
                                  className="carousel-control-prev"
                                  href="#demo"
                                  data-slide="prev"
                                  onClick={this.onSlideChange}
                                >
                                  <i>
                                    <span className="carousel-control-prev-icon"></span>
                                  </i>
                                </a>
                                <a
                                  className="carousel-control-next"
                                  href="#demo"
                                  data-slide="next"
                                  onClick={this.onSlideChange}
                                >
                                  <i>
                                    <span className="carousel-control-next-icon"></span>
                                  </i>
                                </a>
                              </>
                            )}
                          </div>
                        </div>
                      )}
                      <div
                        className={
                          this.state.toggleRightSidebar
                            ? "pdf__height--content mm_ordr1 order_pdf_new h-100"
                            : "pdf__height--content mm_ordr1 order_pdf_expand1 m_auto h-100"
                        }
                        id="overload_image"
                      >
                        {/* <div
                          id="maped_image "
                          className="order_pfd h-100"
                          style={{ background: "#fff" }}
                        >
                          <div
                            className="maped_image h-100 expand_it "
                            style={{
                              transform: this.state.scaling,
                              transformOrigin: "center top",
                            }}
                          >
                            <img
                              className="explore_img"
                              // src='images/newtest.png'
                              src={"images/image6.png"}
                              useMap="#image-map"
                              id="preview"
                              alt="preview"
                            />
                          </div>
                        </div> */}
                      </div>
                      <div
                        id="right-sidbar"
                        className="side-attachments mm_order_side aside__right--height"
                      >
                        {" "}
                        <div
                          onClick={this.handleRightSidebar}
                          className="cus-arro-div"
                        >
                          <img
                            src="images/arrow-r.png"
                            className=" img-fluid cus-arro-r"
                            alt="user"
                          />
                        </div>
                        <div className="side-attack">
                          <div className="main-sec-attach main-bg">
                            {/* paym Attachments */}
                            <span
                              className="fa fa-angle-up float-left mr-2 sideBarAccord"
                              data-toggle="collapse"
                              data-target="#Attachments"
                            ></span>
                            <span
                              className="name_attached"
                              onClick={() =>
                                this.openModal("openAttachmentsModal")
                              }
                            >
                              Attachments
                              <span className="ml-3 font-weight-bold">
                                {this.state.payAttachments.length}
                              </span>
                              <a className="float-right mr-3" href="#">
                                <img
                                  src="images/add.png"
                                  className=" img-fluid sidebarr_plus "
                                  alt="user"
                                />
                              </a>
                            </span>
                          </div>
                          <div className="collapse show" id="Attachments">
                            {this.state.payAttachments.map((a, i) => {
                              return (
                                <div
                                  onClick={() =>
                                    this.getAttachment(
                                      a.recordID,
                                      a.contentType,
                                      a.fileName
                                    )
                                  }
                                  key={i}
                                  className="main-sec-attach"
                                >
                                  {a.fileName}{" "}
                                  <span className="fa fa-angle-right"></span>
                                </div>
                              );
                            })}
                          </div>
                          {/* side menue Approvers / Approvals */}
                          <div className="main-sec-attach main-bg">
                            <span
                              className="invoice-inherit"
                              data-toggle="collapse"
                              data-target="#Approvals"
                            >
                              <span className="fa fa-angle-up float-left mr-2 sideBarAccord1"></span>
                              <span className="name_attached">Approvals</span>
                            </span>
                          </div>
                          <div className="collapse show" id="Approvals">
                            {this.state.approvalGroup &&
                              this.state.approvalGroup.trim() && (
                                <div className="main-sec-mid1">
                                  {/* <button
                                    type="button"
                                    className="btn-save1 add_comment_btns1"
                                    onClick={() =>
                                      this.openModal("openCheckApprovalModal")
                                    }
                                  >
                                    <span></span>
                                    Previous
                                  </button> */}
                                  <small>{this.state.approvalGroup}</small>
                                  {/* <button
                                    type="button"
                                    className="btn-save1 add_comment_btns"
                                    onClick={() =>
                                      this.openModal("openCheckApprovalModal")
                                    }
                                  >
                                    <span></span>
                                    Next
                                  </button> */}
                                </div>
                              )}
                            {this.state.approvers.map((a, i) => {
                              return (
                                <div
                                  key={i}
                                  className="main-sec-attach cus-check"
                                >
                                  <div className="form-group remember_check">
                                    {a.status === "Approved" ? (
                                      <input type="checkbox" id={i} checked />
                                    ) : (
                                      ""
                                    )}
                                    {a.status === "Declined" ? (
                                      <i
                                        className="fa fa-times circle-check1 float-left ml-1"
                                        aria-hidden="true"
                                      ></i>
                                    ) : a.status === "Current" ? (
                                      <i
                                        className="fa fa-circle-thin circle-check float-left ml-1"
                                        aria-hidden="true"
                                        style={{ marginTop: "3px" }}
                                      ></i>
                                    ) : (
                                      ""
                                    )}
                                    <label htmlFor={i}>
                                      {" "}
                                      <span
                                        className={
                                          a.status === "Declined"
                                            ? "order-right-color2 selected mm_lcapp"
                                            : a.status === "Current"
                                            ? "order-right-color1 selected mm_lcapp"
                                            : "text-mar"
                                        }
                                      >
                                        {a.approverName}
                                      </span>
                                      {a.status === "Declined" ? (
                                        <span className="current-approver mm_approre1">
                                          {" "}
                                          (user declined)
                                        </span>
                                      ) : a.status === "Current" ? (
                                        <span className="current-approver mm_approre1">
                                          {" "}
                                          (current approver)
                                        </span>
                                      ) : (
                                        ""
                                      )}
                                    </label>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                          {/* paym Comments */}
                          <div className="main-sec-attach main-bg">
                            <span
                              className="fa fa-angle-up float-left mr-2 sideBarAccord"
                              data-toggle="collapse"
                              data-target="#Comments"
                            ></span>
                            <span
                              className="name_attached"
                              onClick={() =>
                                this.openModal("openCommentsModal")
                              }
                            >
                              Comments
                              <span className="ml-3 font-weight-bold">
                                {this.state.payComments.length}
                              </span>
                              {this.state.showPaymentTallisTabPane ===
                                "Draft" ||
                              this.state.showPaymentTallisTabPane ===
                                "Pending" ||
                              this.state.showPaymentTallisTabPane ===
                                "Approve" ||
                              this.state.showPaymentTallisTabPane === "Hold" ||
                              this.state.showPaymentTallisTabPane ===
                                "Declined" ? (
                                <a className="float-right mr-3" href="#">
                                  <img
                                    src="images/add.png"
                                    className=" img-fluid sidebarr_plus "
                                    alt="user"
                                  />
                                </a>
                              ) : null}
                            </span>
                          </div>
                          <div className="collapse show" id="Comments">
                            {this.state.payComments.map((c, i) => {
                              return (
                                <div key={i} className="main-sec-attach1">
                                  <p className="m-clr s-bold mb-0">
                                    {c.userName}
                                  </p>
                                  {c.comment}
                                  <p className="gry-clr mb-0">
                                    {c.date} {c.time}
                                  </p>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
          {/* end */}
        </div>

        <Attachments
          openAttachmentsModal={this.state.openAttachmentsModal}
          closeModal={this.closeModal}
          attachments={this.state.payAttachments}
        />
        <Comments
          openCommentsModal={this.state.openCommentsModal}
          closeModal={this.closeModal}
          comments={this.state.payComments}
          addComment={this.addComment}
        />

        <Activity
          openActivityModal={this.state.openActivityModal}
          closeModal={this.closeModal}
          activity={this.state.poActivity}
        />
        <Changes
          openChangesModal={this.state.openChangesModal}
          closeModal={this.closeModal}
          changes={this.state.poChanges}
        />
        <Import state={this.state} closeModal={this.closeModal} />

        <Post
          openPostModal={this.state.openPostModal}
          closeModal={this.closeModal}
          postType="Payments"
          onSave={this.postPayment}
          locationProps={this.props}
        />
        <Report
          openReportModal={this.state.openReportModal}
          closeModal={this.closeModal}
          reportType="Payments"
          locationProps={this.props}
        />
        <Remittances
          openRemittancesModal={this.state.openRemittancesModal}
          closeModal={this.closeModal}
          advancedOptions={this.state.advancedOptions}
          _handleHideUnhideRows={this._handleHideUnhideRows}
          _handleValueOptions={this._handleValueOptions}
          handleShowHiddenRows={this.handleShowHiddenRows}
          selectedItems={this.state.selectedItems}
          subject={this.state.subject}
          message={this.state.message}
          companyOptions={this.state.companyOptions}
          emailAddress={this.state.emailAddress}
          companyOptVal={this.state.companyOptVal}
          companyOpt={this.state.companyOpt}
          saveAndSendRemittances={this.saveAndSendRemittances}
          tranList={this.state.tran}
          guid={this.state.guid}
          clonneAdvanceOptions={this.state.clonneAdvanceOptions}
        />
        <PositivePay
          openPositivePayModal={this.state.openPositivePayModal}
          closeModal={this.closeModal}
          advancedOptions={this.state.advancedOptions}
          _handleHideUnhideRows={this._handleHideUnhideRows}
          _handleValueOptions={this._handleValueOptions}
          handleShowHiddenRows={this.handleShowHiddenRows}
          reportOptionsArr={this.state.reportOptionsArr}
          reportOptions={this.state.reportOptions}
          selectedItems={this.state.selectedItems}
          chequeID={this.state.chequeID}
          showHiddenRows={this.state.showHiddenRows}
          reportOptionVal={this.state.reportOptionVal}
          reportOpt={this.state.reportOpt}
          clonneAdvanceOptions={this.state.clonneAdvanceOptions}
        />
        <ChequePrint
          openChequePrintModal={this.state.openChequePrintModal}
          closeModal={this.closeModal}
          advancedOptions={this.state.advancedOptions}
          _handleHideUnhideRows={this._handleHideUnhideRows}
          _handleValueOptions={this._handleValueOptions}
          handleShowHiddenRows={this.handleShowHiddenRows}
          reportOptionsArr={this.state.reportOptionsArr}
          reportOptions={this.state.reportOptions}
          selectedItems={this.state.selectedItems}
          chequeID={this.state.chequeID}
          showHiddenRows={this.state.showHiddenRows}
          clonneAdvanceOptions={this.state.clonneAdvanceOptions}
          printCheque={this.printCheque}
        />

        <PaymentsMoreDetails
          openModal={this.openModal}
          closeModal={this.closeModal}
          openPaymentsMoreDetailsModal={this.state.openPaymentsMoreDetailsModal}
          state={this.state}
          showPaymentTallisTabPane={showPaymentTallisTabPane}
          updatePaymentSummaryState={this.updatePaymentSummaryState}
          getPaymentSummary={this.getPaymentSummary}
        />

        <CheckApproval
          openCheckApprovalModal={this.state.openCheckApprovalModal}
          closeModal={this.closeModal}
        />

        <EftFile
          openEftFileModal={this.state.openEftFileModal}
          closeModal={this.closeModal}
          advancedOptions={this.state.advancedOptions}
          _handleHideUnhideRows={this._handleHideUnhideRows}
          _handleValueOptions={this._handleValueOptions}
          handleShowHiddenRows={this.handleShowHiddenRows}
          processDate={this.state.processDate}
          layout={this.state.layout}
          chequeID={this.state.chequeID}
          showHiddenRows={this.state.showHiddenRows}
          clonneAdvanceOptions={this.state.clonneAdvanceOptions}
        />
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    payments: state.payments,
    pageData: state.pagination.pageData["payments"],
  };
};

export default connect(mapStateToProps, {
  getPaymentTallies: PaymentActions.getPaymentTallies,
  getPaymentsList: PaymentActions.getPaymentsList,
  getNewPaymentsList: PaymentActions.getNewPaymentsList,
  getPaymentSummary: PaymentActions.getPaymentSummary,
  sendForApprovalPayment: PaymentActions.sendForApprovalPayment,
  movePayment: PaymentActions.movePayment,
  approvePayment: PaymentActions.approvePayment,
  declinePayment: PaymentActions.declinePayment,
  holdPayment: PaymentActions.holdPayment,
  addComment: PaymentActions.addComment,
  postPayment: PaymentActions.postPayment,
  exportPayment: PaymentActions.exportPayment,
  primeEFTFile: PaymentActions.primeEFTFile,
  primePositivePay: PaymentActions.primePositivePay,
  primePrintCheque: PaymentActions.primePrintCheque,
  printCheque: PaymentActions.printCheque,
  getRemittanceSettings: PaymentActions.getRemittanceSettings,
  saveAndSendRemittances: PaymentActions.saveAndSendRemittances,
  clearPaymentStates: PaymentActions.clearPaymentStates,
  setLastPage: PaginationActions.setLastPageNumber,
  resetLastPage: PaginationActions.resetLastPage,
})(Payments);
