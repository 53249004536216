import DPFClient from "../../Utils/axiosDPFAPIClient";

export const fetchAuditLogs =
  (
    keyword,
    from,
    to,
    category,
    sort,
    sortOrder = "Ascend",
    index = 1,
    numberOfRecords = 10
  ) =>
  async (dispatch) => {
    dispatch({
      type: "AUDIT_LOG_INIT",
    });

    const url = "/DPFAPI/AdminRequest";

    function toUnixDate(date) {
      return date ? Math.floor(new Date(date).getTime() / 1000) : "";
    }

    try {
      const {
        data: { AdminRequest_response },
      } = await DPFClient.post(url, {
        actionType: "GetAuditLogs",
        page: index,
        displayRecords: numberOfRecords,
        search: keyword,
        fromDateUnix: toUnixDate(from),
        toDateUnix: toUnixDate(to),
        category,
        sort,
        order: sortOrder,
      });

      dispatch({
        type: "AUDIT_LOG_FETCHED",
        payload: AdminRequest_response,
      });
    } catch (err) {
      const error = err.message || "Error loading audit logs.";
      dispatch({
        type: "AUDIT_LOG_FAIL",
        payload: error,
      });
    }
  };

export const fetchAuditOptions = () => async (dispatch) => {
  dispatch({
    type: "AUDIT_OPTIONS_INIT",
  });

  const url = "/DPFAPI/AdminRequest?actionType=GetAuditOptions";

  try {
    const {
      data: { AdminRequest_response },
    } = await DPFClient.get(url);
    dispatch({
      type: "AUDIT_OPTIONS_FETCHED",
      payload: AdminRequest_response,
    });
  } catch (err) {
    const error = err.message || "Error loading audit options.";
    dispatch({
      type: "AUDIT_OPTIONS_FAIL",
      payload: error,
    });
  }
};
