import React from "react";
import DownloadAttachmentsButton from "./DownloadAttachmentsButton";
import OpenEmailButton from "./OpenEmailButton";
import ShowAttachmentsButton from "./ShowAttachmentsButton";

export const ActionButtons = ({
  recordId, attachmentCount, onDownloadClick, onShowAttachmentsClick, onShowMessageClick, email, inboxType, onApprovalClick
}) => {
  return (
    <div>
      <button
        title="Download Attachments"
        className="action-button"
        type="button"
        onClick={(e) => onDownloadClick(e, recordId)}
        disabled={attachmentCount === 0}
      >
        <DownloadAttachmentsButton />
      </button>
      <button
        title="Show Attachments"
        className="action-button"
        type="button"
        onClick={(e) => onShowAttachmentsClick(e, recordId)}
        disabled={attachmentCount === 0}
      >
        <ShowAttachmentsButton />
      </button>
      <button
        title="View Message"
        className="action-button"
        type="button"
        onClick={(e) => onShowMessageClick(e, recordId)}
      >
        <OpenEmailButton />
      </button>
      { inboxType === "incoming" && (
      <button
        title="Approval Email"
        className="action-button"
        type="button"
        onClick={(e) => onApprovalClick(e, email)}
        disabled={email.type !== "Approval"}
      >
      <img
          src="images/refresh.png"
          className="mx-auto"
          width={32}
          height={32}
          alt="search-icon"
        />
      </button>
      )}
    </div>
  );
};
