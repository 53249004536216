import { toast } from "react-toastify";
import axiosDPFAPIClient from "./axiosDPFAPIClient";
import axiosAPIGatewayClient from "./axiosAPIGatewayClient";
import uww from "@fedeghe/upload-web-worker";
import store from "../Store";
import { FILE_UPLOAD_STATUS } from "../Reducers/FileUploadReducer/FileUploadReducer";

/**
 * Returns a set of unique file guids
 * @param {Number} count Number of Guids needed
 * @returns {Promise<String[]>} List of Guids
 */
const getUniqueFileGuids = async (count) => {
  try {
    const url = "/DPFAPI/SetupRequest";

    const payload = {
      actionType: "GenerateUniqueFileGUID",
      count,
    };

    const response = await axiosDPFAPIClient.post(url, payload);
    const data = response.data?.SetupResponse;
    if (data?.result?.length > 0 && data.result[0]?.status === "Success") {
      return data.guidList.map((o) => o.guid);
    }
    return [];
  } catch (error) {
    console.log(error);
    toast.error("Failed to fetch unique guids");
    return [];
  }
};

/**
 * Returns a preSigned url to upload files to S3
 * @param {{ filename: string, originalFilename: string, expires: number}} data
 * @returns {Promise<{ url: string, fields: Object }>} PreSigned url data object
 */
const getPreSignedUploadUrl = async (data) => {
  try {
    const result = await axiosAPIGatewayClient.post(
      "/documents/presignedurl/uploadurl",
      {
        filename: data.filename,
        originalFilename: data.originalFilename,
        expires: data.expires,
        method: "put",
      }
    );
    return result.data.url;
  } catch (error) {
    console.log(error);
    return null;
  }
};

/**
 * Uploads the given file blob to the given preSigned url
 * @param {{url: string, file: Blob, fileName: string, guid: string}[]} uploadEntries PreSigned url data object
 * @param {Promise} callback Callback to be called after all files are uploaded
 * @returns {Promise<boolean>}
 */
const uploadFilesToPreSignedUrls = async (uploadEntries, callback) => {
  uploadEntries.forEach((entry) => {
    const { url, file, fileName, guid } = entry;
    console.log(entry);
    try {
      uww.start({
        method: "PUT",
        file,
        url: url,
        headers: {
          "Content-disposition": `filename="${fileName}"`,
        },
        onStart: (data) => {
          store.dispatch({
            type: "FILE_UPLOAD_STARTED",
            payload: {
              id: data.id,
              fileName: fileName,
              documentGuid: guid,
            },
          });
        },
        onProgress: (data) => {
          store.dispatch({
            type: "UPDATE_FILE_UPLOAD_PROGRESS",
            payload: { id: data.id, progress: Number(data.progress.percent) },
          });
        },
        onEnd: (data) => {
          store.dispatch({
            type: "FILE_UPLOAD_SUCCESS",
            payload: { id: data.id },
          });

          const state = store.getState();
          const pendingFileUploads = state.fileUpload.fileUploadQueue?.filter(
            (u) => u.status !== FILE_UPLOAD_STATUS.COMPLETED
          )?.length;

          if (pendingFileUploads === 0) {
            Promise.resolve(callback(state.fileUpload.fileUploadQueue)).then(
              () => {
                store.dispatch({ type: "CLEAR_FILE_UPLOAD_STATE" });
              }
            );
          }
        },
      });

      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  });
};

/**
 * Returns whether the current production uses remote storage
 * @returns {boolean}
 */
export const isRemoteStorage = () =>
  localStorage.getItem("storageType") === "remote";

/**
 * Uploads the given file list to S3
 * @param {{ fileName: string, fileBlob: Blob }[]} fileList
 * @param {Promise} callback
 * @returns {Promise<boolean>}
 */
export const uploadFileListToS3 = async (fileList, callback) => {
  try {
    const fileGuids = await getUniqueFileGuids(fileList.length);

    const fileEntries = fileGuids.map((guid, i) => ({
      filename: guid,
      originalFilename: fileList[i].fileName,
      file: fileList[i].fileBlob,
      expires: 900,
    }));

    const preSignedUrlPromises = fileEntries.map(
      (entry, i) =>
        new Promise(async (resolve, reject) => {
          const url = await getPreSignedUploadUrl(entry);

          if (!url) {
            return reject();
          }
          return resolve({
            file: entry.file,
            fileName: entry.originalFilename,
            guid: entry.filename,
            url,
          });
        })
    );

    const uploadEntries = await Promise.all(preSignedUrlPromises);
    uploadFilesToPreSignedUrls(uploadEntries, callback);

    return true;
  } catch (error) {
    console.log(error);
    toast.error("Failed to upload files");
    return false;
  }
};

/**
 * Fetch the preSignedUrl and returns a Base64 without the Base64 header
 * @param {string} url PreSigned url
 * @returns Base63 string without the Base64 header
 */
export const getBase64FromPreSignedS3Url = (url) => {
  return new Promise((res) => {
    fetch(url)
      .then((res) => res.blob())
      .then((blob) => {
        const reader = new FileReader();
        reader.onload = () => {
          res(reader.result?.split(",").pop());
        };
        reader.readAsDataURL(blob);
      });
  });
};

/**
 * Opens the given preSignedUrl in a new tab
 * @param {*} url PreSigned url
 */
export const downloadFileFromPresignedUrl = (url) => {
  window.open(url);
};
