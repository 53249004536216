import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import * as AccountActions from "../../../../Actions/AccountActions/AccountActions";
import { downloadAttachments, handleAPIErr } from "../../../../Utils/Helpers";
import CreateJournal from "../../../Modals/Accounts/BankRecModals/CreateJournal/CreateJournal";
import Import from "../../../Modals/Accounts/Import/Import";
import BankAdjustmentsSettings from "../../../Modals/Accounts/BankRecModals/BankAdjustmentsSettings/BankAdjustmentsSettings";
const uuidv1 = require("uuid/v1");
class BankAdjustments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      addNewReportToggle: false,
      openAccountReportModal: false,
      openCreateJournalModal: false,
      openBankAdjustmentsSettingsModal: false,
      type: "adjustments",
      reportTypesVal: { label: "", value: "" },
      reportTypes: [{ label: "", value: "" }],
      reportOptionVal: { label: "", value: "" },
      reportOptions: [],
      menuIsOpen: false,
      adjusmentsColumns: [],
      adjusmentsRows: [],
      adjustments: [],
      date: null,
      refId: [],
      newRefId: "",
      options: [],
      clonedOptions: [],
      filtersArr: [
        { filter: "category", condition: "equal", value: "settings" },
        { filter: "description", condition: "equal", value: "abc" },
      ],
      fltrsColmnFirst: [
        { id: "1", filter: "category", name: "Category", checked: false },
        { id: "2", filter: "description", name: "Description", checked: false },
        { id: "3", filter: "value", name: "Value", checked: false },
      ],
      fltrsColmnSecond: [
        { id: "4", condition: "Contains", checked: false },
        { id: "5", condition: "Doesn't contain", checked: false },
        { id: "6", condition: "Equal", checked: false },
        { id: "7", condition: "Not Equal", checked: false },
        { id: "8", condition: "Starts With", checked: false },
        { id: "9", condition: "Over", checked: false },
        { id: "10", condition: "Under", checked: false },
        { id: "11", condition: "Over Equal", checked: false },
        { id: "12", condition: "Under Equal", checked: false },
      ],
      reportFile: "",
      reportName: "",
      private: false,

      formErrors: {
        reportTypesVal: "",
        reportOptionVal: "",
        reportFile: "",
        reportName: "",
      },
      pageLength: 10
    };
  }
  componentWillReceiveProps(props) {
    let adjustments = this.props?.adjustments || [];
    let adjusmentsRows = this.props?.adjusmentsRows || [];
    let adjusmentsColumns = this.props?.adjusmentsColumns || [];
    this.setState({
      adjustments,
      adjusmentsRows,
      adjusmentsColumns,
    });
  }
  getExportExcel = async () => {
    this.setState({ isLoading: true });
    let data = {
      bankCode: "B1",
      type: "adjustments",
      guidList: this.state.newRefId,
    };
    await this.props.getExportExcel(data);
    if (this.props.account.getExportExcelSuccess) {
      toast.success(this.props.account.getExportExcelSuccess);
      let excelData = this.props.account.getExportExcel || "";

      let obj = {
        contentType: "application/vnd.ms-excel",
        attachment: excelData || "",
      };
      downloadAttachments(obj, "adjustmentsExportData");
    }
    if (this.props.account.getExportExcelError) {
      handleAPIErr(this.props.account.getExportExcelError, this.props);
    }

    this.setState({ isLoading: false });
  };
  onImport = async (type, importData) => {
    this.setState({ isLoading: true });
    let bankCode = document.getElementsByClassName(
      "custon_select-selector-inner__single-value css-1uccc91-singleValue"
    )[0].innerText;
    let requestData = {
      bankCode: bankCode,
      type: type,
      import: importData,
    };
    await this.props.getImportExcel(requestData);
    if (this.props.account.getImportExcelSuccess) {
      toast.success(this.props.account.getImportExcelSuccess);
    }
    if (this.props.account.getImportExcelError) {
      handleAPIErr(this.props.account.getImportExcelError, this.props);
    }
    this.setState({ isLoading: false });
  };
  updateAdjustments = async () => {
    this.setState({ isLoading: true });
    let { adjustments } = this.state;
    let bankCode = "B1";
    let data = {
      bankCode,
      adjustments: adjustments,
    };
    await this.props.updateAdjustments(data);
    if (this.props.account.updateAdjustmentsSuccess) {
      toast.success(this.props.account.updateAdjustmentsSuccess);
    }
    if (this.props.account.updateAdjustmentsError) {
      handleAPIErr(this.props.account.updateAdjustmentsError, this.props);
    }
    this.setState({ isLoading: false });
  };
  deleteAdjustments = async () => {
    this.setState({ isLoading: true });

    let data = {
      bankCode: "B1",
      guidList: this.state.newRefId,
    };
    await this.props.deleteAdjustments(data);
    if (this.props.account.deleteAdjustmentsSuccess) {
      toast.success(this.props.account.deleteAdjustmentsSuccess);
      await this.props.getAdjustments1();
    }
    if (this.props.account.deleteAdjustmentsError) {
      handleAPIErr(this.props.account.deleteAdjustmentsError, this.props);
    }
    this.setState({ isLoading: false });
  };
  getReconcileAdjustments = async () => {
    this.setState({ isLoading: true });
    let bankCode = "B1";
    await this.props.getReconcileAdjustments(bankCode);
    if (this.props.account.getReconcileAdjustmentsSuccess) {
      toast.success(this.props.account.getReconcileAdjustmentsSuccess);
    }
    if (this.props.account.getReconcileAdjustmentsError) {
      handleAPIErr(this.props.account.getReconcileAdjustmentsError, this.props);
    }
    this.setState({ isLoading: false });
  };
  getUpdateStatus = async (pre) => {
    this.setState({ isLoading: true });
    let { newRefId } = this.state;
    let idList = newRefId;
    let data = {
      bankCode: "B1",
      type: "adjustments",
      presented: pre,
      idList: idList,
    };

    await this.props.getUpdateStatus(data);

    if (this.props.account.getUpdateStatusSuccess) {
      toast.success(this.props.account.getUpdateStatusSuccess);
    }
    if (this.props.account.getUpdateStatusError) {
      handleAPIErr(this.props.account.getUpdateStatusError, this.props);
    }

    this.setState({ isLoading: false });
  };
  getPrimeCreateJournal = async (name) => {
    this.setState({ isLoading: true });
    await this.props.getPrimeCreateJournal();
    if (this.props.account.getPrimeCreateJournalSuccess) {
      toast.success(this.props.account.getPrimeCreateJournalSuccess);
      let options = this?.props?.account?.getPrimeCreateJournal || [];

      options.map((lst, i) => {
        if (
          (lst.valueType && lst.valueType.toLowerCase() === "list") ||
          "droplist"
        ) {
          let valOptns = [];
          if (lst.options && lst.options.length > 0) {
            lst.options.map((o, i) => {
              valOptns.push({ label: o.value, value: o.value });
            });
          }
          lst.options = valOptns;
        }
        lst.id = uuidv1();
        lst.hide = false;
        return lst;
      });

      this.setState({ options, clonedOptions: options });
    }
    if (this.props.account.getPrimeCreateJournalError) {
      handleAPIErr(this.props.account.getPrimeCreateJournalError, this.props);
    }
    this.openModal(name);
    this.setState({ isLoading: false });
  };

  addAdjustmentsRow = async () => {
    let { adjusmentsRows, defaultUserFlags, filterData2, columns } = this.state;
    // window.$("#tableJournalLines").DataTable().destroy();
    let lastLineItem = adjusmentsRows[adjusmentsRows.length - 1];

    let data = 1;
    if (lastLineItem) {
      if (Number(lastLineItem.data[20]) || [] != NaN) {
        if (Number(lastLineItem.data[20]) || [] < 1) {
          data = 1;
        } else {
          data = Number(lastLineItem.data[20]) + 1;
        }
      } else {
        data = 1;
      }
    } else {
      data = 1;
    }

    //pre-fill the Chart Sort with the user's   chart sort.
    // let chartSort =
    //   (this.props.user.getDefaultValues &&
    //     this.props.user.getDefaultValues.defaultValues &&
    //     this.props.user.getDefaultValues.defaultValues.chartSort) ||
    //   "";

    // let flags = defaultUserFlags || []; //user's flags

    let obj = {
      data: ["362", "", "", "", "", data],
      parentRefId: "",
      refId: "362",
    };

    adjusmentsRows = await [...adjusmentsRows, obj];
    // journalLines.data.sort();

    //return false;
    //let journalLinesData = [];
    /*    journalLinesData = await this.handleShowHideColumns(
        filterData2,
        journalLines
      );
    
  
      columns.map(c => {
        journalLines.map(l => {
          l.trackingCodes.map(t => {
            if (c.prompt === t.prompt) {
             
              t[t.prompt] = t.value
            }
          })
        })
      })
  */

    this.setState({ adjusmentsRows, checkAllJrnltem: false }, () => {
      // this.updateAdjustments();
      // if (journalLines.length === 1) {
      //   this.calcMrgnForSuggestion();
      // }
    });
  };
  handleCheckboxes = (e, data) => {
    let { refId, adjusmentsRows, newRefId } = this.state;

    // let bankChequeRows = JSON.parse(JSON.stringify(this.state.bankChequeRows));
    let { name, checked } = e.target;
    if (data === "allCheck" && name === "checkboxAll") {
      let multipleTransCopy = [];
      if (checked) {
        adjusmentsRows.map((m) => {
          m.checked = true;
          multipleTransCopy.push(m.refId);
          return m;
        });
      } else {
        adjusmentsRows.map((m) => {
          m.checked = false;
          return m;
        });
      }
      refId = [...multipleTransCopy];
    } else {
      if (checked) {
        adjusmentsRows.map(async (po, i) => {
          if (data.refId === po.refId) {
            po.checked = true;
          }
          return po;
        });
        refId.push(data.refId);
      } else {
        adjusmentsRows.map(async (po, i) => {
          if (data.refId === po.refId) {
            po.checked = false;
          }
          return po;
        });
        let filteredMultiRefId = refId.filter((t) => t != data.refId);
        refId = filteredMultiRefId;
      }
    }
    newRefId = refId + [];
    this.setState({
      adjusmentsRows,
      refId,
      newRefId,
    });
  };

  handleChangeSettings = (e, i) => {
    const { name, value } = e.target;
    if (name === "pageLength") {
      this.setState({ pageLength: value });
    } else {
      let { columns } = this.state;
      columns[i].hide = e.target.checked;
      this.setState({ columns });
    }
  };

  openModal = (name) => {
    if(name === "openBankAdjustmentsSettingsModal"){
      let pageLength =window.$('#adjustmentsDetail').DataTable().page.len() || 10;
      this.setState({ pageLength });
    }
    this.setState({ [name]: true });
  };
  closeModal = (name) => {
    if(name === "openBankAdjustmentsSettingsModal"){
      this.handleSaveSettings();
    }
    this.setState({ [name]: false });
  };
  handleChangeGeneralLedgerList = async (e, data, i, j, type) => {
    // adjustments.map((adj) => {
    //   // tran[0].Rows = data;
    //   return adj;
    // });

    console.log("ok", e.target.value);
    data[j] = e.target.value;

    this.setState({});
  };
  handleCheck = (e, data, j) => {
    let { adjusmentsRows } = this.state;
    // adjustments.map((adj) => {
    //   // tran[0].Rows = data;
    //   return adj;
    // });
    let { checked } = e.target;
    if (data[j] === "Y") {
      adjusmentsRows.map((m) => {
        m.data[j] = "N";
        return m;
      });
    } else {
      adjusmentsRows.map((m) => {
        m.data[j] = "Y";
        return m;
      });
    }

    this.setState({ adjusmentsRows });
  };

  GetFormattedDate = (dateParam) => {
    var todayTime = new Date(dateParam);
    var month = todayTime.getMonth() + 1;
    var day = todayTime.getDate();
    var year = todayTime.getFullYear();
    return year + "-" + month + "-" + day;
  };


  handleSearch = (e) => {
    let { value } = e.target;
    var table= window.$('#adjustmentsDetail').DataTable().search(value).draw();

  }

  handleSaveSettings = () => {
    let { pageLength } = this.state;
    window.$('#adjustmentsDetail').DataTable().page.len(Number(pageLength)).draw();
  }
  
  render() {
    let { adjusmentsColumns, adjusmentsRows, options, clonedOptions } =
      this.state;
    return (
      <>
        {this.state.isLoading ? <div className="se-pre-con"></div> : ""}

        {/* Adjustments */}

        <div className="forgot_form_main sup-inner-pad position-relative">
          <div className="forgot_header mt-4">
            <div className="modal-top-header">
              <div className="row bord-btm">
                <div className="col-10 col-md-9 pl-0">
                  <h6 className="text-left def-blue">
                    <span>
                      {" "}
                      <img
                        src="images/arrow_up.png"
                        className="import_icon img-fluid pr-3 ml-3 sideBarAccord"
                        alt="arrow_up"
                        data-toggle="collapse"
                        data-target="#Adjustments"
                      />{" "}
                    </span>
                    Adjustments
                  </h6>
                </div>
                <div className="col-1 col-md-2  d-flex justify-content-end s-c-main">
                  <button
                    className="new_poedit_add_btn btn__plus--wrapper"
                    type="button"
                    tabIndex="2231"
                    id="id_save111"
                    onClick={this.addAdjustmentsRow}
                    style={{ cursor: "pointer", color: "#30679A" }}
                  >
                    <i
                      className="fa fa-plus-circle icon--font_20"
                      aria-hidden="true"
                    ></i>
                  </button>
                  <button
                    className="new_poedit_add_btn btn__plus--wrapper "
                    type="button"
                    tabIndex="2231"
                    id="id_save111"
                    onClick={this.deleteAdjustments}
                    style={{ cursor: "pointer", color: "#30679A" }}
                    title="Do you really wanna delete the selected record?"
                  >
                    <i
                      className="fa fa-trash icon--font_20"
                      aria-hidden="true"
                      title="Do you really wanna delete the selected record?"
                    ></i>
                  </button>
                </div>
                <div className="col-1 col-md-1 d-flex justify-content-end s-c-main">
                  <Link
                    to="#"
                    className="zom-img float-right mt-2 mr-2 icon_dots4"
                  >
                    <img
                      src="images/more.png"
                      className="img-fluid"
                      alt="user"
                    />{" "}
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="documents_attatchments2 supplier2_sidetoggle mm-blok4">
            <div
              // onClick={() => this.openModal("openReportModal")}
              onClick={() => this.getUpdateStatus(1)}
              className="main-sec-attach main-bg"
            >
              Present
            </div>
            <div
              // onClick={() => this.openModal("openReportModal")}
              onClick={() => this.getUpdateStatus(0)}
              className="main-sec-attach main-bg"
            >
              UnPresent
            </div>
            <div
              // onClick={() => this.openModal("openReportModal")}
              onClick={() => this.getExportExcel()}
              className="main-sec-attach main-bg"
            >
              Export
            </div>
            <div
              className="main-sec-attach main-bg"
              // onClick={() => this.openModal("openAccountReportModal")}
              onClick={() => this.openModal("openImportModal")}
            >
              <span
                className="export_crd"
                data-toggle="collapse"
                data-target="#export"
              >
                {/* <span className="fa fa-angle-up float-left mr-2 sideBarAccord1"></span> */}
                Import
              </span>
            </div>
            <div
              className="main-sec-attach main-bg"
              onClick={() =>
                this.getPrimeCreateJournal("openCreateJournalModal")
              }
            >
              <span
                className="export_crd"
                data-toggle="collapse"
                data-target="#export"
              >
                {/* <span className="fa fa-angle-up float-left mr-2 sideBarAccord1"></span> */}
                Create Journal
              </span>
            </div>
            <div
              className="main-sec-attach main-bg"
              // onClick={() => this.openModal("openAccountReportModal")}
              onClick={() => this.getReconcileAdjustments()}
            >
              <span
                className="export_crd"
                data-toggle="collapse"
                data-target="#export"
              >
                {/* <span className="fa fa-angle-up float-left mr-2 sideBarAccord1"></span> */}
                Reconcile
              </span>
            </div>
          </div>

          <div className="row">
            <div className="col-xl-10 col-md-12 col-sm-12 m-auto">
              <div className="forgot_body collapse show " id="Adjustments">
                {/* filter dropdown 1 */}
                <div id="supplier_filter_dropdpwn1">
                  <div className="filter_dropdpwn1_toparea">
                    <div className="col-sm-12 p-0">
                      <h2>
                        Active Filters{" "}
                        <img
                          onClick={() =>
                            this.setState({
                              filter_dropdpwn1: false,
                              filter_dropdpwn2: false,
                            })
                          }
                          src="images/cross.png"
                          alt=""
                          className="float-right pr-2 pop-cros-1"
                        />
                      </h2>
                    </div>

                    <div className="clear"></div>
                    <div className="col-sm-12 p-0">
                      {this.state.filtersArr.length > 0 && (
                        <form action="#" className="">
                          <div className="form-group mb-0">
                            <label htmlFor="test2" className="co_postaol_radio">
                              <input
                                type="radio"
                                id="test2"
                                name="filterRadioGroup"
                                value="AND"
                                checked={this.state.filterRadioGroup === "AND"}
                                onChange={this.handleRadioButtons}
                              />
                              AND
                            </label>

                            <label htmlFor="test1" className="co_postaol_radio">
                              <input
                                type="radio"
                                id="test1"
                                name="filterRadioGroup"
                                value="OR"
                                checked={this.state.filterRadioGroup === "OR"}
                                onChange={this.handleRadioButtons}
                              />
                              OR
                            </label>
                          </div>
                        </form>
                      )}
                    </div>
                  </div>
                  <div className="clear20"></div>
                  <div className="col-sm-12 p0 detail_filter_table_1">
                    <table
                      width="100%"
                      border="0"
                      cellSpacing="0"
                      cellPadding="0"
                      className="detail_detail_detail_detail_drop-table"
                    >
                      {this.state.filtersArr.length > 0
                        ? this.state.filtersArr?.map((f, i) => {
                            return (
                              <tr>
                                <td
                                  align="left"
                                  width="20"
                                  className="cursorPointer"
                                >
                                  <img
                                    onClick={() => this.removeFilter(f, i)}
                                    src="images/close-icon_filter.png"
                                    width="9"
                                    height="9"
                                    alt=""
                                  />
                                </td>
                                <td align="left">{f.name} </td>
                                <td align="center">
                                  <div className="rel">
                                    <select
                                    //   onChange={(e) =>
                                    //     this.onChangefltrConditns(
                                    //       f.filter,
                                    //       e,
                                    //       i
                                    //     )
                                    //   }
                                    >
                                      {this.state.fltrsColmnSecond.map(
                                        (fc, i) => {
                                          return (
                                            <option
                                              value={fc.condition}
                                              selected={
                                                f.condition === fc.condition
                                              }
                                            >
                                              {fc.condition}
                                            </option>
                                          );
                                        }
                                      )}
                                    </select>
                                    <i className="fa fa-angle-down my-select"></i>
                                  </div>
                                </td>
                                <td align="right">
                                  <input
                                    placeholder="Value"
                                    type={
                                      f.filter === "Rate Date" ? "date" : "text"
                                    }
                                    onChange={(e) =>
                                      this.handleFilterValue(
                                        e,
                                        "firstPopup",
                                        f,
                                        i
                                      )
                                    }
                                    className="input-cy"
                                    value={f.value}
                                  />
                                </td>
                              </tr>
                            );
                          })
                        : "No filters applied to this view"}
                    </table>
                  </div>
                  <div className="col-sm-12 p-0 pt-3 active_filters">
                    <h2>Active Filters</h2>
                    <div className="save-filter">
                      {/* <span
                                  onClick={() =>
                                    this.openModal("openSupplierFilterModal")
                                  }
                                >
                                  Save filter
                                </span> */}
                      <Link to="#" onClick={this.addActiveFilters}>
                        Save filter
                      </Link>
                    </div>
                    <ul className="active_filter_list">
                      {this.state.activeFilters?.map((a, i) => {
                        return (
                          <li>
                            {this.state.actvFilter === i ? (
                              <span
                                onClick={() => this.deleteActiveFilter(a, i)}
                                className="fa fa-close cursorPointer activeFilter"
                              ></span>
                            ) : (
                              <span className="cursorPointer">
                                <img
                                  onClick={() => this.deleteActiveFilter(a, i)}
                                  src="images/close-icon-gray.png"
                                />
                              </span>
                            )}

                            <span
                              className={
                                this.state.actvFilter === i
                                  ? "cursorPointer activeFilter"
                                  : "cursorPointer"
                              }
                              onClick={() => this.handleActiveFilter(a, i)}
                            >
                              {" "}
                              {a.description}
                            </span>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                  <div className="col-sm-12 active_filters_table2"></div>
                  <div className="clear"></div>
                  <div className="col-sm-12 p-0 active_filters">
                    <h2>Workspace Filters</h2>
                    <div className="save-filter">
                      <Link to="#" onClick={this.addWorkspaceFilters}>
                        Save filter
                      </Link>
                    </div>
                    <ul className="active_filter_list">
                      {this.state.workSpaceFilters?.map((w, i) => {
                        return (
                          <li>
                            {this.state.activeWorkFilter === i ? (
                              <span
                                onClick={() => this.deleteWorkSpaceFilter(w, i)}
                                className="fa fa-close cursorPointer activeFilter"
                              ></span>
                            ) : (
                              <span className="cursorPointer">
                                <img
                                  onClick={() =>
                                    this.deleteWorkSpaceFilter(w, i)
                                  }
                                  src="images/close-icon-gray.png"
                                />
                              </span>
                            )}

                            <span
                              className={
                                this.state.activeWorkFilter === i
                                  ? "cursorPointer activeFilter"
                                  : "cursorPointer"
                              }
                              onClick={() => this.handleWorkSpaceFilter(w, i)}
                            >
                              {" "}
                              {w.description}
                            </span>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                  <div className="clear10"></div>
                  <div>
                    <button
                      className="ml-2 clear-filter"
                      onClick={this.clearAllFilters}
                    >
                      Clear
                    </button>

                    <div className="pull-right plus_icon-filter_bottom">
                      <img
                        onClick={() =>
                          this.setState({ filter_dropdpwn2: true })
                        }
                        src="images/user-setup/plus_icon-filter_bottom.png"
                        alt=""
                      />
                    </div>
                  </div>
                </div>

                {/* second drop down 2*/}
                <div id="supplier_filter_dropdpwn2">
                  <div className="filter_dropdpwn2_toparea p-0">
                    <div className="col-sm-12 p-0">
                      <h2 className="pl-3 pt-3 pb-1">Add Filters</h2>
                      <div className="can-sav-btn">
                        <button
                          onClick={this.saveFilters}
                          className="btn can-btn1"
                        >
                          <img src="images/save-check.png" alt="check"></img>
                          Save
                        </button>
                        <button
                          onClick={this.closeFilterPopup}
                          className="btn can-btn1 pl-3 close_it"
                        >
                          <img src="images/cancel.png" alt="check"></img>
                          Cancel
                        </button>
                      </div>
                      <hr />
                    </div>
                    <div className="row pb-30">
                      <div className="col sec-pop pr-0">
                        <ul>
                          {this.state.fltrsColmnFirst.map((f, i) => {
                            return (
                              <li
                                className={f.checked ? "b-active" : ""}
                                key={i}
                                onClick={() => this.addFilters(f, "first")}
                              >
                                {f.name}
                                {f.checked ? (
                                  <span className="fa fa-check icon-check"></span>
                                ) : (
                                  ""
                                )}
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                      <div className="col sec-pop pl-0 pr-0 check_active_ul ">
                        <ul className="pr-0">
                          <ul className="pr-0">
                            {this.state.fltrsColmnSecond.map((f, i) => {
                              return (
                                <li
                                  className={f.checked ? "b-active" : ""}
                                  key={i}
                                  onClick={() => this.addFilters(f, "second")}
                                >
                                  {f.condition}
                                  {f.checked ? (
                                    <span className="fa fa-check icon-check"></span>
                                  ) : (
                                    ""
                                  )}
                                </li>
                              );
                            })}
                          </ul>
                        </ul>
                      </div>
                      <div className="col sec-pop1 pl-0">
                        <ul>
                          <li className="border-bottom">
                            <div className="">
                              <input
                                placeholder="Value"
                                type={this.state.showDate ? "date" : "text"}
                                className="cus-in"
                                name="filterValue"
                                value={this.state.filterValue}
                                onChange={(e) =>
                                  this.handleFilterValue(e, "secondPopup")
                                }
                              />
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="clear10"></div>
                  </div>
                </div>
                {/* end dropdown */}

                <div className="col-12 col-lg-4 col-md-6 pl-md-0 mt-4">
                  <div className="d-flex justify-content-center h-100">
                    <div className="searchbar">
                      <input
                        className="search_input"
                        type="text"
                        placeholder="Search..."
                        name="detailsListSearch"
                        value={this.state.detailsListSearch}
                        onChange={(e) =>
                          this.handleSearch(e)
                        }
                      />
                      <a
                        href="javascript:void(0)"
                        className="search_icon search-boredr"
                      >
                        <i className="fa fa-search"></i>
                      </a>
                      <a
                        href="javascript:void(0)"
                        className="search_icon"
                        id="filter_dropdown"
                      >
                        <i className="fa fa-filter"></i>
                      </a>
                    </div>
                  </div>
                </div>

                <div className="row mt-3">
                  <div className="col-12">
                    <div className="login_form">
                      <div className="login_table_list table-responsive tab-1-line supplier2_table ">
                        <table 
                          className="table busines_unit_table shadow-remove"
                          id="adjustmentsDetail"
                        >
                          <thead>
                            <tr>
                              <th
                                scope="col"
                                className="exp_th1 mm_contact-name"
                              >
                                <div className="form-group">
                                  <label className="dash_container dash_remember table-check unckeck">
                                    <input
                                      type="checkbox"
                                      name={"checkboxAll"}
                                      id={"chk1"}
                                      // checked={checkAllJrnltem}
                                      onChange={(e) =>
                                        this.handleCheckboxes(e, "allCheck")
                                      }
                                    />
                                    <span
                                      id="chk1"
                                      className="dash_checkmark"
                                    ></span>
                                  </label>
                                </div>
                              </th>
                              {this.state.adjusmentsColumns?.map((col, i) => {
                                return (
                                  <th
                                    scope="col"
                                    key={i}
                                    className="text-left exp-supplier-th "
                                  >
                                    <span className="table_lines_hed ">
                                      {" "}
                                      {col.ColName}
                                    </span>
                                  </th>
                                );
                              })}
                              <th>
                              <span
                              className="user_setup_hed2"
                              onClick={() => this.openModal("openBankAdjustmentsSettingsModal")}
                            >
                              {" "}
                              <img
                                src="./images/user-setup/bars.png"
                                alt="bars"
                              ></img>
                            </span>
                            </th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.adjusmentsRows?.map((item, i) => {
                              return (
                                <tr key={i}>
                                  {item.data === null ? (
                                    ""
                                  ) : (
                                    <td>
                                      <div
                                        className="col align-self-center text-center pr-0"
                                        style={{ marginLeft: "14px" }}
                                      >
                                        <div className="form-group mb-0 check-line">
                                          <label className="dash_container dash_remember table-check unckeck">
                                            <input
                                              type="checkbox"
                                              name={"checkboxAll"}
                                              id={"chk1" + i}
                                              checked={item.checked}
                                              onChange={(e) =>
                                                this.handleCheckboxes(e, item)
                                              }
                                            />
                                            <span
                                              id={"chk1" + i}
                                              className="dash_checkmark"
                                            ></span>
                                          </label>
                                        </div>
                                      </div>
                                    </td>
                                  )}
                                  {this.state.adjusmentsColumns?.map((c, j) => {
                                    let ColType = c.ColType;
                                    if (
                                      ColType === "Number" ||
                                      ColType === "Numeric"
                                    ) {
                                      return (
                                        <>
                                          {item.data === null ? (
                                            ""
                                          ) : (
                                            <td
                                              className="text-left"
                                              data-order={item.data[j]}
                                            >
                                              <input
                                                type="number"
                                                name="chartSort"
                                                value={item.data[j]}
                                                className="input_height wd-108"
                                                readOnly={
                                                  c.Editable ? false : true
                                                }
                                                onBlur={this.updateAdjustments}
                                                onChange={(e) => {
                                                  this.handleChangeGeneralLedgerList(
                                                    e,
                                                    item.data,
                                                    i,
                                                    j
                                                  );
                                                }}
                                              />
                                            </td>
                                          )}
                                        </>
                                      );
                                    } else if (ColType === "Date") {
                                      return (
                                        <>
                                          {item.data === null ? (
                                            ""
                                          ) : (
                                            <td
                                              className="text-left"
                                              data-order={item.data[j]}
                                            >
                                              <input
                                                type="date"
                                                name="chartSort"
                                                // required
                                                // pattern="\d{2}-\d{2}-\d{4}"
                                                // value={moment(
                                                //   item.data[j]
                                                // ).format("YYYY-DD-MM")}
                                                // value={() =>
                                                //   this.GetFormattedDate(
                                                //     item.data[j]
                                                //   )
                                                // }
                                                value={item.data[j]
                                                  .split("/")
                                                  .reverse()
                                                  .join("-")}
                                                className="input_height wd-130"
                                                readOnly={
                                                  c.Editable ? false : true
                                                }
                                                onBlur={this.updateAdjustments}
                                                onChange={(e) => {
                                                  this.handleChangeGeneralLedgerList(
                                                    e,
                                                    item.data,
                                                    i,
                                                    j
                                                  );
                                                }}
                                              />
                                            </td>
                                          )}
                                        </>
                                      );
                                    } else if (ColType === "Check") {
                                      return (
                                        <>
                                          {item.data === null ? (
                                            ""
                                          ) : (
                                            <td
                                              className="text-left"
                                              data-order={item.data[j]}
                                            >
                                              <input
                                                type="checkbox"
                                                checked={
                                                  item.data[j] === "Y"
                                                    ? true
                                                    : false
                                                }
                                                className="input_height wd-108"
                                                readOnly={
                                                  c.Editable === "1"
                                                    ? false
                                                    : true
                                                }
                                                onBlur={this.updateAdjustments}
                                                onChange={(e) => {
                                                  this.handleCheck(
                                                    e,
                                                    item.data,
                                                    j
                                                  );
                                                }}
                                              />
                                            </td>
                                          )}
                                        </>
                                      );
                                    } else {
                                      return (
                                        <>
                                          {item.data === null ? (
                                            ""
                                          ) : (
                                            <td
                                              className="text-left"
                                              data-order={item.data[j]}
                                            >
                                              <input
                                                type="text"
                                                name="chartSort"
                                                value={item.data[j]}
                                                className="input_height wd-108"
                                                readOnly={
                                                  c.Editable ? false : true
                                                }
                                                onBlur={this.updateAdjustments}
                                                onChange={(e) => {
                                                  this.handleChangeGeneralLedgerList(
                                                    e,
                                                    item.data,
                                                    i,
                                                    j
                                                  );
                                                }}
                                              />
                                            </td>
                                          )}
                                        </>
                                      );
                                    }
                                  })}
                                  <td></td>
                                  {/* <td
                                    className="text-left cursorpointer"
                                    data-order={""}
                                  ></td> */}
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {options && (
          <CreateJournal
            openCreateJournalModal={this.state.openCreateJournalModal}
            closeModal={this.closeModal}
            options={options}
            clonedOptions={clonedOptions}
            newRefId={this.state.newRefId}
            locationProps={this.props}
          />
        )}
        <Import
          openImportModal={this.state.openImportModal}
          type={this.state.type}
          onImport={this.onImport}
          closeModal={this.closeModal}
        />
        <BankAdjustmentsSettings
        openBankAdjustmentsSettingsModal={this.state.openBankAdjustmentsSettingsModal}
        openModal={this.openModal}
        closeModal={this.closeModal}
        pageLength={this.state.pageLength}
        handleChangeSettings={this.handleChangeSettings}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({ account: state.account });

export default connect(mapStateToProps, {
  getAdjustments: AccountActions.getAdjustments,
  getImportExcel: AccountActions.getImportExcel,
  getExportExcel: AccountActions.getExportExcel,
  updateAdjustments: AccountActions.updateAdjustments,
  deleteAdjustments: AccountActions.deleteAdjustments,
  getReconcileAdjustments: AccountActions.getReconcileAdjustments,
  getUpdateStatus: AccountActions.getUpdateStatus,
  getPrimeCreateJournal: AccountActions.getPrimeCreateJournal,
})(BankAdjustments);
