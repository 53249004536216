import React, { Component } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import Dropdown from "react-bootstrap/Dropdown";
import $ from "jquery";
import { _customStyles } from "../../../Constants/Constants";
import DatePicker from "react-datepicker";

import TopNav from "../../Common/TopNav/TopNav";
import * as AccountActions from "../../../Actions/AccountActions/AccountActions";
// import { clearStatesAfterLogout } from "../../Actions/UserActions/UserActions";
import * as Validation from "../../../Utils/Validation";
import {
  toBase64,
  addDragAndDropFileListners,
  removeDragAndDropFileListners,
  handleHideUnhideRows,
  handleValueOptions,
  handleAPIErr,
} from "../../../Utils/Helpers";
import "./PeriodClose.css";

const uuidv1 = require("uuid/v1");

class PeriodClose extends Component {
  constructor() {
    super();
    this.state = {
      isLoading: false,
      addNewReportToggle: false,
      // reportTypesVal: { label: "", value: "" },
      reportTypes: [],
      reportOptionVal: [{ label: "Select Report Option", value: "" }],
      reportOptions: [],
      advancedList: [],
      clonnedAdvancedList: [],
      availableReports: [],
      availabletypes: "",
      menuIsOpen: false,

      reportFile: "",
      reportName: "",
      private: false,
      showHiddenRows: false,
      reDrawDT: false, //re draw datatable

      reportTypeValue: [{ label: "Select Report Type", value: "" }],

      reportLayouts: [],
      reportLayoutsVal: [],
      primePeriod: [],
      formErrors: {
        reportTypesVal: "",
        reportOptionVal: "",
        reportFile: "",
        reportName: "",
      },
    };
  }

  async componentDidMount() {
    this.primePeriodClose();
  }

  primePeriodClose = async () => {
    this.setState({ isLoading: true });
    await this.props.PrimePeriodClose();

    if (this.props.account.PrimePeriodCloseSuccess) {
      toast.success(this.props.account.PrimePeriodCloseSuccess);
      let primePeriod = this.props.account.PrimePeriodClose || [];
      let advancedList = primePeriod[0].advancedOptions;
      let availableReports = primePeriod[0].availableReports;
      let reportId = primePeriod[0].reportId;
      let reportTypeOption = [];
      availableReports.map((item) => {
        reportTypeOption.push({ label: item.reportType, value: item.areaRef });
      });
      let reportTypeValue = reportTypeOption.filter((item) => item);
      let reportLayoutsVal = availableReports[0].reportLayouts;
      let reportOption = [];

      reportLayoutsVal.map((item) => {
        reportOption.push({ label: item.layout, value: item.id });
      });
      let reportOptionVal =
        reportOption.find((item) => item.value == reportId) || "";
      advancedList.map((lst, i) => {
        if ((lst.type && lst.type.toLowerCase() === "list") || "droplist") {
          let valOptns = [];
          if (lst.options && lst.options.length > 0) {
            lst.options.map((o, i) => {
              valOptns.push({ label: o.value, value: o.value });
            });
          }
          lst.options = valOptns;
        }
        lst.id = uuidv1();
        lst.hide = false;
        return lst;
      });

      this.setState({
        reportTypeValue,
        reportOptionVal,
        advancedList,
        clonnedAdvancedList: advancedList,
        reportTypes: reportTypeOption,
        reportLayouts: reportOption,
        reportLayoutsVal,
        primePeriod,
      });
    }

    if (this.props.account.PrimePeriodCloseError) {
      handleAPIErr(this.props.account.PrimePeriodCloseError, this.props);
    }

    this.setState({ isLoading: false });
  };

  _handleValueOptions = async (type, val, item, index) => {
    let { advancedList, clonnedAdvancedList } = this.state;
    let result = handleValueOptions(
      type,
      val,
      item,
      index,
      advancedList,
      clonnedAdvancedList
    );

    this.setState({
      ...this.state,
      advancedList: result.advancedList,
      clonnedAdvancedList: result.clonedAdvancedList,
    });
  };

  _handleHideUnhideRows = (item) => {
    let { advancedList, clonnedAdvancedList, showHiddenRows } = this.state;

    let result = handleHideUnhideRows(
      item,
      "#reportv4-table",
      "reports__settings",
      advancedList,
      clonnedAdvancedList,
      showHiddenRows
    );

    this.setState({
      advancedList: result.advancedList,
      clonnedAdvancedList: result.clonedAdvancedList,
      showHiddenRows: result.showHiddenRows,
      reDrawDT: true,
    });
  };

  handleShowHiddenRows = async () => {
    let table = window.$("#reportv4-table").DataTable();
    table.destroy();

    let { advancedList, clonnedAdvancedList, showHiddenRows } = this.state;

    showHiddenRows = !showHiddenRows;

    if (showHiddenRows) {
      //show hidden rows
      advancedList = clonnedAdvancedList;
    } else {
      //hide again hidden rows
      let list = advancedList.filter((l) => !l.hide);
      advancedList = list;
    }

    this.setState({
      advancedList,
      showHiddenRows,
      reDrawDT: true, //to re daraw the datatable
    });
  };

  handleReportTypes = (type) => {
    let { formErrors } = this.state;

    formErrors = Validation.handleValidation(
      "reportTypesVal",
      type.value,
      formErrors
    );

    let value = type.value || "";

    let reportLayouts = this.state.reportLayouts || [];
    let reportOptions = [];

    let found = reportLayouts.find((rop) => rop.id === value);

    if (found) {
      reportOptions.push({
        label: found.layout,
        value: found.id,
        selected: 1,
      });
    }
    this.setState({
      reportLayouts: reportLayouts,
      reportOptionVal: type,
      formErrors,
    });
  };

  _handleReportTypes = (type) => {
    let { formErrors } = this.state;
    formErrors = Validation.handleValidation(
      "reportTypesVal",
      type.value,
      formErrors
    );

    let value = type.value || "";

    let reportLayoutsVal = this.state.reportTypes || [];
    let reportOptions = [];

    let found = reportLayoutsVal.find((rop) => rop.value === value);

    if (found) {
      reportOptions.push({
        label: found.label,
        value: found.value,
        selected: 1,
      });
    }
    this.setState({
      reportTypesVal: type,
      reportTypeValue: reportOptions,
      formErrors,
    });
  };

  closePeriod = async () => {
    this.setState({ isLoading: true });
    let { primePeriod } = this.state;

    let periodClose = { periodClose: primePeriod };

    await this.props.closePeriod(periodClose);
    if (this.props.account.periodCloseSuccess) {
      toast.success(this.props.account.periodCloseSuccess);
    }

    if (this.props.account.periodCloseError) {
      handleAPIErr(this.props.account.periodCloseError, this.props);
    }

    this.setState({ isLoading: false });
  };

  render() {
    return (
      <>
        {this.state.isLoading ? <div className="se-pre-con"></div> : ""}

        <div className="user_setup_main">
          <header>
            <TopNav />

            <div className="user_setup_heading">
              <div className="header_menu">
                <Link to="/dashboard">
                  <img
                    src="images/dash-logo.png"
                    className="img-fluid"
                    alt="logo"
                  />
                </Link>
                <Link
                  className="setup_menu"
                  to="#"
                  data-target="#top_nav_toggle1"
                >
                  <img src="images/top-menu.png" className="" alt="top-menu" />
                </Link>
              </div>
              <h2>Period Close</h2>
              <span>
                <img
                  src="./images/user-setup/lock.png"
                  alt="lock"
                  className="img-fluid"
                />
              </span>
            </div>
            <div className="user_setup_headerbox">
              <div className="user_setup_play_div">
                <ul>
                  <li>
                    <p className="user_setup_play_video">Video</p>
                  </li>
                  <li>
                    <p className="user_setup_play_tuturial">Tutorials</p>
                  </li>
                </ul>
                <span className="user_setup_play_icon">
                  <img
                    src="./images/user-setup/play.png"
                    alt="play"
                    className="img-fluid"
                  />
                </span>
              </div>
              <div className="user_setup_header_rightbox">
                <p>
                  In our{" "}
                  <span>
                    <a href="#">Video</a>
                  </span>{" "}
                  learn how to use period close Read our{" "}
                  <span>
                    <a href="#">help article</a>
                  </span>{" "}
                  to learn More
                </p>
              </div>
              <span>
                <img
                  className="close_top_sec"
                  src="images/user-setup/cross.png"
                  alt="cross"
                ></img>
              </span>
            </div>
          </header>
          <div className="col-sm-12 table_white_box">
            <section id="">
              <div className="container-fluid ">
                <div className="main_wrapper p-10">
                  <div className="forgot_body px-3">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="user_setup_plus_Icons">
                          <ul>
                            <li>
                              <button
                                // onClick={this.onSave}
                                type="button"
                                className="btn-save"
                              >
                                <span className="fa fa-ban mr-2"></span>
                                Cancel
                              </button>
                              <button
                                onClick={this.closePeriod}
                                type="button"
                                className="btn-save"
                              >
                                <span className="fa fa-check mr-2"></span>
                                Run
                              </button>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="row mt-4">
                      <div className="col-xl-3 col-lg-0  "></div>
                      <div className="col-md-3  ">
                        {/* dropdown coding start */}
                        <div className="form-group custon_select">
                          <label htmlFor="">Report Type</label>
                          <Select
                            className="width-selector"
                            value={this.state.reportTypeValue}
                            classNamePrefix="custon_select-selector-inner"
                            options={this.state.reportTypes}
                            onChange={this._handleReportTypes}
                            theme={(theme) => ({
                              ...theme,
                              border: 0,
                              borderRadius: 0,
                              colors: {
                                ...theme.colors,
                                primary25: "#f2f2f2",
                                primary: "#f2f2f2",
                              },
                            })}
                          />
                          <div className="text-danger error-12">
                            {this.state.formErrors.reportTypesVal !== ""
                              ? this.state.formErrors.reportTypesVal
                              : ""}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row mt-4">
                      <div class="col-xl-3 col-lg-0  "></div>
                      <div className="form-group col-md-3">
                        <label htmlFor="">Report Option</label>
                        {/* dropdown coding start */}
                        <div className="custon_select">
                          <Select
                            className="width-selector"
                            closeMenuOnSelect={true}
                            value={this.state.reportOptionVal}
                            classNamePrefix="report_menu custon_select-selector-inner"
                            onMenuOpen={this.menuOpened}
                            onMenuClose={this.menuClosed}
                            onChange={this.handleReportTypes}
                            options={this.state.reportLayouts}
                            theme={(theme) => ({
                              ...theme,
                              border: 0,
                              borderRadius: 0,
                              colors: {
                                ...theme.colors,
                                primary25: "#f2f2f2",
                                primary: "#f2f2f2",
                              },
                            })}
                          />

                          <span
                            className="input_field_icons rit-icon-input"
                            // data-toggle="collapse"
                            // data-target="#asd"
                            onClick={this.addNewReport}
                          >
                            <i className="fa fa-plus"></i>
                          </span>
                        </div>
                        <div className="text-danger error-12">
                          {this.state.formErrors.reportOptionVal !== ""
                            ? this.state.formErrors.reportOptionVal
                            : ""}
                        </div>

                        {this.state.addNewReportToggle && (
                          // <div className="collapse pl-3 pr-3 w-100 id="asd">
                          <div className=" pl-3 pr-3 w-100">
                            <div className="row">
                              <div className="col-12 mt-2 mb-2">
                                <div className="form-group custon_select  text-center mb-0 border-rad-5">
                                  <div id="drop-area-report">
                                    <input
                                      type="file"
                                      id="fileElem-rep"
                                      className="form-control d-none"
                                      accept="application/pdf"
                                      onChange={(e) => {
                                        this.uploadReportFile(e.target.files);
                                      }}
                                      onClick={(event) => {
                                        event.currentTarget.value = null;
                                      }} //to upload the same file again
                                    />

                                    <label
                                      className="upload-label"
                                      htmlFor="fileElem-rep"
                                    >
                                      <div className="upload-text">
                                        <img
                                          src="images/drag-file.png"
                                          className="import_icon img-fluid"
                                          alt="upload-report"
                                        />
                                      </div>
                                    </label>
                                  </div>
                                </div>
                                <div className="text-danger error-12">
                                  {this.state.formErrors.reportFile !== ""
                                    ? this.state.formErrors.reportFile
                                    : ""}
                                </div>
                              </div>
                              <div className="col-12">
                                <div className="form-group custon_select mt-3">
                                  <div className="modal_input">
                                    <label>Report Name</label>
                                    <input
                                      type="text"
                                      className="form-control pl-0"
                                      id="usr"
                                      name="reportName"
                                      value={this.state.reportName}
                                      onChange={this.handleFieldChange}
                                    />
                                    <div className="text-danger error-12">
                                      {this.state.formErrors.reportName !== ""
                                        ? this.state.formErrors.reportName
                                        : ""}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-12 align-self-center mb-2">
                                <div className="form-group remember_check">
                                  <input
                                    type="checkbox"
                                    id="remember"
                                    checked={this.state.private}
                                    onChange={this.handleCheckbox}
                                  />
                                  <label htmlFor="remember"></label>
                                  <p className="pt-1">Private:</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <div className="row">
              <div className="col-xl-6 col-md-12 col-sm-12 m-auto w_50">
                <div className="user_setup_popup__table new_advance_table m-auto p-3">
                  <h2>Advanced</h2>
                  <table className="table" id="usersteup-modal">
                    <thead className="thead_bg thead_bg_sass hover-border">
                      <tr>
                        <th scope="col">
                          <span className="user_setup_hed">Category</span>
                        </th>
                        <th scope="col">
                          <span className="user_setup_hed">Description</span>
                        </th>
                        <th scope="col" className="value__field--wrapperuser">
                          <span className="user_setup_hed">value</span>
                        </th>
                        <th scope="col" className="text__right__contentuser">
                          <span className="user_setup_hed">hide</span>
                        </th>
                        <th className="table__inner--th">
                          <div className="menu_bars_dropdown">
                            <Dropdown
                              alignRight="false"
                              drop="up"
                              className="analysis-card-dropdwn "
                            >
                              <Dropdown.Toggle variant="" id="">
                                <span className="fa fa-bars "></span>
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.Item to="#/action-1" className="">
                                  <div
                                    className="pr-0"
                                    onClick={this.handleShowHiddenRows}
                                  >
                                    <div className="form-group remember_check form-group_sass remember_check_sass mm_check4">
                                      <input
                                        type="checkbox"
                                        id="showHiddenRows"
                                        name="showHiddenRows"
                                        checked={this.state.showHiddenRows}
                                        onClick={this.handleShowHiddenRows}
                                      />
                                      <label
                                        htmlFor="showHiddenRows"
                                        className="mr-0"
                                      >
                                        Show Hidden Rows
                                      </label>
                                    </div>
                                  </div>
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.advancedList.map((list, i) => {
                        return (
                          <tr key={i}>
                            <td className=" ">{list.category}</td>
                            <td>{list.description}</td>
                            {list.valueType === "List" ? (
                              <td className="pt-0 pb-0 text-left">
                                <Select
                                  classNamePrefix="custon_select-selector-inner main__dropdown--wrapper1"
                                  styles={_customStyles}
                                  value={{
                                    label: list.value,
                                    value: list.value,
                                  }}
                                  readOnly={
                                    list.readOnly === "Y" ? true : false
                                  }
                                  options={list.valueOptions}
                                  onChange={(obj) =>
                                    this._handleValueOptions(
                                      "list",
                                      obj,
                                      list,
                                      i
                                    )
                                  }
                                  theme={(theme) => ({
                                    ...theme,
                                    border: 0,
                                    borderRadius: 0,
                                    colors: {
                                      ...theme.colors,
                                      primary25: "#f2f2f2",
                                      primary: "#f2f2f2",
                                    },
                                  })}
                                />
                              </td>
                            ) : list.valueType === "MultiList" ? (
                              <td className="pt-0 pb-0 text-left">
                                <Select
                                  className="basic-multi-select main__dropdown--wrapper1"
                                  name="multiple-op"
                                  // classNamePrefix="custon_select-selector-inner"
                                  defaultValue={{
                                    label: "Select Approval Group",
                                    value: "",
                                  }}
                                  value={list.multiValue || []}
                                  options={list.valueOptions}
                                  readOnly={
                                    list.readOnly === "Y" ? true : false
                                  }
                                  isClearable={false}
                                  onChange={(obj) =>
                                    this._handleValueOptions(
                                      "multiList",
                                      obj,
                                      list,
                                      i
                                    )
                                  }
                                  isMulti
                                  theme={(theme) => ({
                                    ...theme,
                                    border: 0,
                                    borderRadius: 0,
                                    colors: {
                                      ...theme.colors,
                                      primary25: "#f2f2f2",
                                      primary: "#f2f2f2",
                                    },
                                  })}
                                />
                              </td>
                            ) : list.valueType === "Date" ? (
                              <td>
                                <div className="table_input_field">
                                  <DatePicker
                                    className={
                                      list.readOnly === "Y" ? "read_only" : ""
                                    }
                                    selected={Number(list.value)}
                                    dateFormat="d MMM yyyy"
                                    autoComplete="off"
                                    readOnly={
                                      list.readOnly === "Y" ? true : false
                                    }
                                    onChange={(date) =>
                                      this._handleValueOptions(
                                        "date",
                                        date,
                                        list,
                                        i
                                      )
                                    }
                                  />
                                </div>
                              </td>
                            ) : list.valueType === "Check" ? (
                              <td>
                                <div className="col-auto p-0">
                                  <div className="form-group remember_check  text-center pt-0 float-left">
                                    <input
                                      type="checkbox"
                                      id={`chk${i}`}
                                      checked={
                                        list.value === "Y" || list.value === "1"
                                          ? true
                                          : false
                                      }
                                      readOnly={
                                        list.readOnly === "Y" ? true : false
                                      }
                                      onChange={(e) =>
                                        this._handleValueOptions(
                                          "checkbox",
                                          e,
                                          list,
                                          i
                                        )
                                      }
                                    />
                                    <label htmlFor={`chk${i}`}></label>
                                  </div>
                                </div>
                              </td>
                            ) : list.valueType === "Numeric" ||
                              list.valueType === "Number" ? (
                              <td>
                                <div className="table_input_field">
                                  <input
                                    className={
                                      list.readOnly === "Y" ? "read_only" : ""
                                    }
                                    type="number"
                                    value={list.value}
                                    readOnly={
                                      list.readOnly === "Y" ? true : false
                                    }
                                    onChange={(e) =>
                                      this._handleValueOptions(
                                        "number",
                                        e,
                                        list,
                                        i
                                      )
                                    }
                                  />
                                </div>
                              </td>
                            ) : list.valueType === "Text" ? (
                              <td>
                                <div className="table_input_field">
                                  <input
                                    className={
                                      list.readOnly === "Y" ? "read_only" : ""
                                    }
                                    type="text"
                                    value={list.value}
                                    readOnly={
                                      list.readOnly === "Y" ? true : false
                                    }
                                    onChange={(e) =>
                                      this._handleValueOptions(
                                        "text",
                                        e,
                                        list,
                                        i
                                      )
                                    }
                                  />
                                </div>
                              </td>
                            ) : (
                              <td>{list.value}</td>
                            )}
                            <td className="text__right--user">
                              <div className="custom-radio">
                                <label
                                  className="check_main check_main_sass remember_check"
                                  htmlFor={`hideUnhideRows${i}`}
                                >
                                  <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    readOnly={
                                      list.readOnly === "Y" ? true : false
                                    }
                                    name={"hideUnhideRows"}
                                    id={`hideUnhideRows${i}`}
                                    checked={false}
                                    onChange={(e) =>
                                      this._handleHideUnhideRows(list)
                                    }
                                  />
                                  <span
                                    className={
                                      list.hide
                                        ? "dash_checkmark bg_clr"
                                        : "dash_checkmark"
                                    }
                                  ></span>
                                </label>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  account: state.account,
});

export default connect(mapStateToProps, {
  PrimePeriodClose: AccountActions.PrimePeriodClose,
  closePeriod: AccountActions.closePeriod,
})(PeriodClose);
