import { combineReducers } from "redux";
import UserReducer from "./UserReducer/UserReducer";
import SuppliersReducer from "./SuppliersReducer/SuppliersReducer";
import PO from "./POReducer/POReducer";
import Invoice from "./InvoiceRducer/InvoiceRducer";
import Chart from "./ChartReducer/ChartReducer";
import Report from "./ReportReducer/ReportReducer";
import Document from "./DocumentReducer/DocumentReducer";
import Expense from "./ExpenseReducer/ExpenseReducer";
import Setup from "./SetupReducer/SetupReducer";
import Payments from "./PaymentReducer/PaymentReducer";
import Journal from "./JournalReducer/JournalReducer";
import Timecard from "./TimecardReducer/TimecardReducer";
import DistChanges from "./DistChangesReducer/DistChangesReducer";
import Account from "./AccountReducer/AccountReducer";
import Pagination from './PaginationReducer/PaginationReducer';
import AuditLogs from "./AuditLogsReducer/AuditLogsReducer";
import Emails from "./EmailsReducer/EmailsReducer";
import ApprovalTransfer from "./SetupReducer/ApprovalTransferReducer";
import WebSocketReducer from "./WebSocketReducer/WebSocketReducer";
import FileUploadReducer from "./FileUploadReducer/FileUploadReducer";

const reducers = combineReducers({
  user: UserReducer,
  supplier: SuppliersReducer,
  poData: PO,
  invoice: Invoice,
  chart: Chart,
  report: Report,
  document: Document,
  expense: Expense,
  setup: Setup,
  approvalTransfer: ApprovalTransfer,
  payments: Payments,
  journal: Journal,
  timecard: Timecard,
  distchange: DistChanges,
  account: Account,
  pagination: Pagination,
  auditLogs: AuditLogs,
  emails: Emails,
  webSocket: WebSocketReducer,
  fileUpload: FileUploadReducer,
});

export default reducers;
