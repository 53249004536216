import React, { Component } from "react";
import { connect } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import Header from "../Common/Header/Header";
import TopNav from "../Common/TopNav/TopNav";
import * as DistchangeActions from "../../Actions/DistChangesActions/DistChangesActions";
import * as Helpers from "../../Utils/Helpers";
import { toast } from "react-toastify";

class Distchangesform extends Component {
  constructor() {
    super();
    this.state = {
      id: "",
      getDistchange: [],
      isLoading: false,

      amount: "",

      chartCode: "",
      chartSort: "",
      date: "",
      description: "",
      distDistributionsColumns: [],
      distDistributionsRows: [],
      distChangesRows: [],
      distChangesColumns: [],

      line: "",
      trackingCodes: "",
      tran: "",
      changes: [],
      distributions: [],
    };
  }

  async componentDidMount() {
    await this.getDistchange();
  }

  getDistchange = async () => {
    let id = this.props.history.location.state || "";
    if (!id) {
      toast.error("Document id is not found");
      this.props.history.push("/dist-changes");
    } else {
      this.setState({ isLoading: true });

      let { getDistchange } = this.state;

      let id =
        (this.props.history &&
          this.props.history.location &&
          this.props.history.location.state.id) ||
        "";

      await this.props.getDistchange(id);

      if (this.props.distchange.getDistchangeSuccess) {
        getDistchange = this.props.distchange.getDistchange || [];
        let distributions = getDistchange.distributions || [];
        let changes = getDistchange.changes || [];

        let distDistributionsRows = getDistchange.distributions[0].Rows || "";
        let distDistributionsColumns =
          getDistchange.distributions[0].Columns || "";
        let distChangesRows = getDistchange.changes[0].Rows || "";
        let distChangesColumns = getDistchange.changes[0].Columns || "";

        this.setState({
          distDistributionsRows,
          distDistributionsColumns,
          distChangesRows,
          distChangesColumns,
          distributions,
          changes,
          id,
        });
      }

      if (this.props.distchange.getDistchangeError) {
        Helpers.handleAPIErr(
          this.props.distchange.getDistchangeError,
          this.props
        );
      }
      this.setState({ isLoading: false });
    }
  };
  handleChangeDistChangeList = (e, data, i, j) => {
    let { journalLines } = this.state;
    data[j] = e.target.value;

    this.setState({ journalLines });
  };

  updateDistchange = async () => {
    this.setState({ isLoading: true });

    let { id, changes, distributions } = this.state;

    id =
      (this.props.history &&
        this.props.history.location &&
        this.props.history.location.state.id) ||
      "";

    let obj = {
      distributions,
      changes,
    };
    await this.props.updateDistchange(obj);
    if (this.props.distchange.updateDistchangeSuccess) {
      toast.success(this.props.distchange.updateDistchangeSuccess);
    }

    if (this.props.distchange.updateDistchangeError) {
      Helpers.handleAPIErr(
        this.props.distchange.updateDistchangeError,
        this.props
      );
      this.props.history.push("/dist-changes", {
        id,
      });
    }

    this.setState({ isLoading: false });
  };

  render() {
    return (
      <>
        {this.state.isLoading ? <div className="se-pre-con"></div> : ""}
        <div className="dashboard">
          <Header props={this.props} distchangesform={true} />
          <div className="dashboard_body_content">
            {/* top Nav menu*/}
            <TopNav />
            <section id="">
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                }}
              >
                <div className="container-fluid ">
                  <div className="main_wrapper mt-md-5 mt-2 sup-main-pad">
                    <div className="row d-flex justify-content-center h-60vh">
                      <div className="col-12 col-md-12 w-100 ">
                        <div className="forgot_form_main report_main sup-inner-pad">
                          <div className="forgot_header">
                            <div className="modal-top-header">
                              <div className="row">
                                <div className="col d-flex justify-content-end s-c-main">
                                  <button
                                    type="button"
                                    className={
                                      this.state.id_save
                                        ? "btn-save btn_focus"
                                        : "btn-save"
                                    }
                                    tabIndex="2236"
                                    id="id_save"
                                    onFocus={this.onFocus}
                                    onBlur={this.onBlur}
                                    onClick={this.updateDistchange}
                                  >
                                    <span className="fa fa-check"></span>
                                    Save
                                  </button>

                                  <button
                                    type="button"
                                    className={
                                      this.state.id_cancel
                                        ? "btn-save btn_focus"
                                        : "btn-save"
                                    }
                                    tabIndex="2237"
                                    id="id_cancel"
                                    onFocus={this.onFocus}
                                    onClick={() =>
                                      this.props.history.push("/dist-changes")
                                    }
                                  >
                                    <span className="fa fa-ban"></span>
                                    Cancel
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-12">
                                <div className="login_form">
                                  <div className="table-responsive">
                                    <table className="fold-table">
                                      <thead>
                                        <tr>
                                          <th
                                            scope="col"
                                            className="exp_th1 mm_contact-name"
                                          ></th>
                                          {this.state.distDistributionsColumns?.map(
                                            (col, i) => {
                                              return (
                                                <th
                                                  scope="col"
                                                  key={i}
                                                  className="text-left exp-supplier-th "
                                                >
                                                  <span className="table_lines_hed ">
                                                    {" "}
                                                    {col.ColName}
                                                  </span>
                                                </th>
                                              );
                                            }
                                          )}
                                          <th width="3%"></th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <span className="icon-th">
                                          <img
                                            src="images/angle-down.png"
                                            className="down_data"
                                            data-toggle="collapse"
                                            data-target="#asdf"
                                            alt="toggle-down"
                                          />
                                        </span>
                                        {this.state?.distDistributionsRows?.map(
                                          (item, i) => {
                                            return (
                                              <tr key={i}>
                                                <td></td>

                                                {this.state?.distDistributionsColumns?.map(
                                                  (c, j) => {
                                                    let ColType = c.ColType;
                                                    if (
                                                      ColType === "Number" ||
                                                      ColType === "Numeric"
                                                    ) {
                                                      return (
                                                        <td
                                                          className="text-left"
                                                          data-order={
                                                            item.data[j]
                                                          }
                                                        >
                                                          <input
                                                            type="number"
                                                            name="chartSort"
                                                            value={item.data[j]}
                                                            className="input_height wd-108"
                                                            readOnly={
                                                              c.Editable
                                                                ? false
                                                                : true
                                                            }
                                                            // onBlur={this.updateDistchange}
                                                            onChange={(e) => {
                                                              this.handleChangeDistChangeList(
                                                                e,
                                                                item.data,
                                                                i,
                                                                j
                                                              );
                                                            }}
                                                          />
                                                        </td>
                                                      );
                                                    } else if (
                                                      ColType === "Date"
                                                    ) {
                                                      return (
                                                        <>
                                                          <td
                                                            className="text-left"
                                                            data-order={
                                                              item.data[j]
                                                            }
                                                          >
                                                            <input
                                                              type="Date"
                                                              name="chartSort"
                                                              value={
                                                                item.data[j]
                                                              }
                                                              className="input_height wd-108"
                                                              readOnly={
                                                                c.Editable
                                                                  ? false
                                                                  : true
                                                              }
                                                              // onBlur={
                                                              //   this.updateDistchange
                                                              // }
                                                              onChange={(e) => {
                                                                this.handleChangeDistChangeList(
                                                                  e,
                                                                  item.data,
                                                                  i,
                                                                  j
                                                                );
                                                              }}
                                                            />
                                                          </td>
                                                        </>
                                                      );
                                                    } else {
                                                      return (
                                                        <>
                                                          <td
                                                            className="text-left"
                                                            data-order={
                                                              item.data[j]
                                                            }
                                                          >
                                                            <input
                                                              type="text"
                                                              name="chartSort"
                                                              value={
                                                                item.data[j]
                                                              }
                                                              className="input_height wd-108"
                                                              readOnly={
                                                                c.Editable
                                                                  ? false
                                                                  : true
                                                              }
                                                              // onBlur={
                                                              //   this.updateDistchange
                                                              // }
                                                              onChange={(e) => {
                                                                this.handleChangeDistChangeList(
                                                                  e,
                                                                  item.data,
                                                                  i,
                                                                  j
                                                                );
                                                              }}
                                                            />
                                                          </td>
                                                        </>
                                                      );
                                                    }
                                                  }
                                                )}
                                                <td>
                                                  <img
                                                    src="images/delete.svg"
                                                    class=" invoice-delete-icon cursorPointer"
                                                    alt="delete"
                                                  />
                                                </td>
                                              </tr>
                                            );
                                          }
                                        )}
                                        <tr className="fold collapse" id="asdf">
                                          <td
                                            colspan="12"
                                            style={{ padding: "0px" }}
                                          >
                                            <div className="fold-content">
                                              <table>
                                                <thead>
                                                  <tr>
                                                    <th
                                                      scope="col"
                                                      className="exp_th1 mm_contact-name"
                                                    ></th>
                                                    {this.state.distChangesColumns?.map(
                                                      (col, i) => {
                                                        return (
                                                          <th
                                                            scope="col"
                                                            key={i}
                                                            className="text-left exp-supplier-th "
                                                          >
                                                            <span className="table_lines_hed ">
                                                              {" "}
                                                              {col.ColName}
                                                            </span>
                                                          </th>
                                                        );
                                                      }
                                                    )}
                                                    <th width="3%">
                                                      <img
                                                        src="images/plus.png"
                                                        class="cursorPointer img-fluid"
                                                        alt="user"
                                                      ></img>
                                                    </th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  <span
                                                    style={{
                                                      visibility: "hidden",
                                                    }}
                                                  ></span>
                                                  {this.state?.distChangesRows?.map(
                                                    (item, i) => {
                                                      return (
                                                        <tr key={i}>
                                                          <td></td>
                                                          {this.state?.distChangesColumns?.map(
                                                            (c, j) => {
                                                              let ColType =
                                                                c.ColType;
                                                              if (
                                                                ColType ===
                                                                  "Number" ||
                                                                ColType ===
                                                                  "Numeric"
                                                              ) {
                                                                return (
                                                                  <td
                                                                    className="text-left"
                                                                    data-order={
                                                                      item.data[
                                                                        j
                                                                      ]
                                                                    }
                                                                  >
                                                                    <input
                                                                      type="number"
                                                                      name="chartSort"
                                                                      value={
                                                                        item
                                                                          .data[
                                                                          j
                                                                        ]
                                                                      }
                                                                      className="input_height wd-108"
                                                                      readOnly={
                                                                        c.Editable
                                                                          ? false
                                                                          : true
                                                                      }
                                                                      // onBlur={this.updateDistchange}
                                                                      onChange={(
                                                                        e
                                                                      ) => {
                                                                        this.handleChangeDistChangeList(
                                                                          e,
                                                                          item.data,
                                                                          i,
                                                                          j
                                                                        );
                                                                      }}
                                                                    />
                                                                  </td>
                                                                );
                                                              } else if (
                                                                ColType ===
                                                                "Date"
                                                              ) {
                                                                return (
                                                                  <>
                                                                    <td
                                                                      className="text-left"
                                                                      data-order={
                                                                        item
                                                                          .data[
                                                                          j
                                                                        ]
                                                                      }
                                                                    >
                                                                      <input
                                                                        type="Date"
                                                                        name="chartSort"
                                                                        value={
                                                                          item
                                                                            .data[
                                                                            j
                                                                          ]
                                                                        }
                                                                        className="input_height wd-108"
                                                                        readOnly={
                                                                          c.Editable
                                                                            ? false
                                                                            : true
                                                                        }
                                                                        // onBlur={
                                                                        //   this.updateDistchange
                                                                        // }
                                                                        onChange={(
                                                                          e
                                                                        ) => {
                                                                          this.handleChangeDistChangeList(
                                                                            e,
                                                                            item.data,
                                                                            i,
                                                                            j
                                                                          );
                                                                        }}
                                                                      />
                                                                    </td>
                                                                  </>
                                                                );
                                                              } else {
                                                                return (
                                                                  <>
                                                                    <td
                                                                      className="text-left"
                                                                      data-order={
                                                                        item
                                                                          .data[
                                                                          j
                                                                        ]
                                                                      }
                                                                    >
                                                                      <input
                                                                        type="text"
                                                                        name="chartSort"
                                                                        value={
                                                                          item
                                                                            .data[
                                                                            j
                                                                          ]
                                                                        }
                                                                        className="input_height wd-108"
                                                                        readOnly={
                                                                          c.Editable
                                                                            ? false
                                                                            : true
                                                                        }
                                                                        // onBlur={
                                                                        //   this.updateDistchange
                                                                        // }
                                                                        onChange={(
                                                                          e
                                                                        ) => {
                                                                          this.handleChangeDistChangeList(
                                                                            e,
                                                                            item.data,
                                                                            i,
                                                                            j
                                                                          );
                                                                        }}
                                                                      />
                                                                    </td>
                                                                  </>
                                                                );
                                                              }
                                                            }
                                                          )}
                                                          <td>
                                                            <img
                                                              src="images/delete.svg"
                                                              class=" invoice-delete-icon cursorPointer"
                                                              alt="delete"
                                                            />
                                                          </td>
                                                        </tr>
                                                      );
                                                    }
                                                  )}
                                                </tbody>
                                              </table>
                                            </div>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </section>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  distchange: state.distchange,
});

export default connect(mapStateToProps, {
  getDistchange: DistchangeActions.getDistchange,
  updateDistchange: DistchangeActions.updateDistchange,
})(Distchangesform);
