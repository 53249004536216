import React, { Component } from "react";
import { connect } from "react-redux";
import DatePicker from "react-datepicker";
import Select from "react-select";
import $ from "jquery";
import _ from "lodash";
import moment from "moment";
import store from "../../Store/index";
import * as JournalActions from "../../Actions/JournalActions/JournalActions";
import * as UserActions from "../../Actions/UserActions/UserActions";
import * as ChartActions from "../../Actions/ChartActions/ChartActions";
import "react-datepicker/dist/react-datepicker.css";
import Header from "../Common/Header/Header";
import TopNav from "../Common/TopNav/TopNav";
import {
  tableFlagsSetting,
  handleSaveFlagsSettings,
  handleCloseFlagsSettingModal,
  handleAPIErr,
  downloadAttachments,
  toBase64,
  addDragAndDropFileListners,
  removeDragAndDropFileListners,
} from "../../Utils/Helpers";
import * as Validation from "../../Utils/Validation";
import { _customStyles } from "../../Constants/Constants";
import { toast } from "react-toastify";
import Settings from "../Modals/Settings/Settings";
import { downloadFileFromPresignedUrl, isRemoteStorage, uploadFileListToS3 } from "../../Utils/S3StorageHelper";
const uuidv1 = require("uuid/v1");

class JournalForm extends Component {
  constructor() {
    super();
    this.state = {
      tran: "",
      batch: "",
      description: "",
      voucher: "",
      date: moment(new Date().setUTCHours(0, 0, 0)).unix() * 1000,
      attachments: [],
      attachmentSize: 0, //default 0 Bytes,  attachments should always less than 29.5 MB
      approvalGroup: {
        label: "Select Approval Group",
        value: "",
      },
      approvalOptions: [],
      journalLines: [],

      //get chart codes
      chartCodesList: [],
      clonedChartCodesList: [],
      defaultUserFlags: [], //default user flags
      checkAllJrnltem: false,
      formErrors: {
        approvalGroup: "",
        description: "",
      },
      primDocName: "",
      activeAtchID: "",
      columns: [], //column headings
      filterData2: [],
      pageLength: 10,
      openSettingsModal: false,
      openUserSetupModal: false,
      openUserAccessModal: false,
    };
  }

  async componentDidMount() {
    window.addEventListener(
      "resize",
      () => {
        // calculatin the margin for the chart code suggestion box
        this.calcMrgnForSuggestion();
      },
      false
    );
    addDragAndDropFileListners("drop-area-attach", this.uploadAttachment);

    let tran =
      (this.props.history.location &&
        this.props.history.location.state &&
        this.props.history.location.state.tran) ||
      "";
    let check = false;

    if (tran && tran === "addNewJournal") {
      //  Add Journal Case
      await this.insertJournal(); //add Journal to get trans
      check = true;
    } else if (tran) {
      //update journal case
      this.setState({
        tran,
        attachmentSize: 0,
        isLoading: true,
      });
      await this.getJournal(tran);
      check = true;
    } else {
      this.props.history.push("/journals");
    }

    let isDefaultValues = false;
    let promises = [];
    // let defVals =
    //   (this.props.user.getDefaultValues &&
    //     this.props.user.getDefaultValues.flags) ||
    //   [];

    // if (defVals.length === 0) {
    //   let defVals = localStorage.getItem("getDefaultValues") || "";
    //   defVals = defVals ? JSON.parse(defVals) : "";
    //   if (defVals && defVals.defaultValues) {
    //     //if localstorage contains the default values then update the Redux State no need to call API
    //     store.dispatch({
    //       type: "GET_DEFAULT_VALUES_SUCCESS",
    //       payload: defVals,
    //     });
    //   } else {
    //     promises.push(this.props.getDefaultValues());
    //   }
    // } else {
    //   isDefaultValues = true;
    // }
    if (check) {
      promises.push(this.getChartCodes());

      await Promise.all(promises);
    }

    // //success case of get default vaues
    // if (this.props.user.getDefaultValuesSuccess || isDefaultValues) {
    //   // toast.success(this.props.user.getDefaultValuesSuccess);
    //   let defaultUserFlags =
    //     (this.props.user.getDefaultValues &&
    //       this.props.user.getDefaultValues.flags) ||
    //     [];
    //   let _flags = [
    //     {
    //       prompt: "Chart Sort",
    //       field: "chartSort",
    //       hide: false,
    //       name: "Chart Sort",
    //     },
    //     {
    //       prompt: "Chart Code",
    //       field: "chartCode",
    //       hide: false,
    //       name: "Chart Code",
    //     },
    //     { prompt: "Date", field: "date", hide: false, name: "Date" },
    //     {
    //       prompt: "Description",
    //       field: "description",
    //       hide: false,
    //       name: "Description",
    //     },
    //     {
    //       prompt: "Supplier",
    //       field: "supplier",
    //       hide: false,
    //       name: "Supplier",
    //     },
    //     { prompt: "Invoice", field: "invoice", hide: false, name: "Invoice" },
    //     { prompt: "PO", field: "po", hide: false, name: "PO" },
    //     { prompt: "EmpRef", field: "empRef", hide: false, name: "EmpRef" },
    //     { prompt: "Tran", field: "tran", hide: false, name: "Tran" },
    //     { prompt: "Amount", field: "amount", hide: false, name: "Amount" },
    //     { prompt: "Line", field: "line", hide: false, name: "Line" },
    //   ];
    

    //   defaultUserFlags.reverse().map((f, i) => {
    //     let obj = {
    //       value: f.defaultValue || "",
    //       length: f.length,
    //       prompt: f.prompt,
    //       sequence: f.sequence,
    //       type: f.type,
    //       field: f.type,
    //       hide: false,
    //       name: f.prompt,
    //     };
    //     _flags.insert(2, obj);
    //   });

    //   let _flags2 = [];
    //   defaultUserFlags.map((f, i) => {
    //     let obj = {
    //       value: f.defaultValue || "",
    //       length: f.length,
    //       prompt: f.prompt,
    //       sequence: f.sequence,
    //       type: f.type,
    //     };
    //     _flags2.push(obj);
    //   });
    //   _flags2.reverse();
    //   this.setState({
    //     defaultUserFlags: _flags2,
    //     columns: _flags,
    //   });
    // }
    // //error case of get default vaues
    // if (this.props.user.getDefaultValuesError) {
    //   handleAPIErr(this.props.user.getDefaultValuesError, this.props);
    // }
    this.calcMrgnForSuggestion();
  }

  //main journal lines table
  tableJournalLines = () => {
    let { columns, journalLines } = this.state;
    let aoColumns = [];

    //adding the column names
    //(m.Width.search("%")>=0 || m.Width.search("px")>=0)? m.Width : m.Width+"px"
    aoColumns[0] = { sName: "checkbox", sWidth: "100px" };
    columns.map((c) => aoColumns.push({ sName: c.ColName, sWidth: "500px" }));
    aoColumns[columns.length + 1] = { sName: "menues", sWidth: "100%" };
    let result = tableFlagsSetting(columns, aoColumns, "tableJournalLines");
    this.setState({ ...result });
  };

  handleChangeSettings = (e, i) => {
    let { columns } = this.state;
    columns[i].hide = e.target.checked ? true : false;
    this.setState({ columns });
  };

  handleSaveSettings = () => {
    let { columns, pageLength } = this.state;
    handleSaveFlagsSettings(columns, "tableJournalLines");
    this.closeModal("openSettingsModal");
  };

  handleCloseSettingModal = () => {
    let { columns } = this.state;
    let result = handleCloseFlagsSettingModal(columns, "tableJournalLines");
    this.setState({ ...result }, () => {
      this.closeModal("openSettingsModal");
    });
  };

  componentWillUnmount() {
    //removing drag and drop attachments listeners
    removeDragAndDropFileListners("drop-area-attach", this.uploadAttachment);
  }
  openModal = (name) => {
    this.setState({ [name]: true });
  };
  closeModal = (name) => {
    this.setState({ [name]: false });
    // this.clearStates();
  };
  /*
  handleShowHideColumns = (filterData2, journalLines) => {
    let trackingCodes = [];


    journalLines.map((m, i) => {
      return (
        (m.chartSortFlag = true),
        (m.chartCodeFlag = true),
        (m.dateFlag = true),
        (m.descriptionFlag = true),
        (m.supplierFlag = true),
        (m.invoiceFlag = true),
        (m.poFlag = true),
        (m.empRefFlag = true),
        (m.tranFlag = true),
        (m.amountFlag = true),
        (m.lineFlag = true),
        m.trackingCodes.map((data, i) => {
          return (
            data.prompt.toLowerCase() === "in" ? data.tcFlag = true : "",
            data.prompt.toLowerCase() === "f" ? data.tcFlag = true : "",
            data.prompt.toLowerCase() === "tax" ? data.tcFlag = true : "",
            data.prompt.toLowerCase() === "st" ? data.tcFlag = true : "",
            data.prompt.toLowerCase() === "q" ? data.tcFlag = true : "",
            data.prompt.toLowerCase() === "frb" ? data.tcFlag = true : "",
            data.prompt.toLowerCase() === "srb" ? data.tcFlag = true : "",
            data.prompt.toLowerCase() === "rrb" ? data.tcFlag = true : "",
            data.prompt.toLowerCase() === "test 9" ? data.tcFlag = true : "",
            data.prompt.toLowerCase() === "test10" ? data.tcFlag = true : ""
          )
        })
      );
    });


    filterData2.map((mp) => {
      if (mp.prompt === "Chart Sort") {
        journalLines.map((m) => {
          m.chartSortFlag = false;
        });
      }
      if (mp.prompt === "Chart Code") {
        journalLines.map((m) => {
          m.chartCodeFlag = false;
        });
      }
      if (mp.prompt === "Date") {
        journalLines.map((m) => {
          m.dateFlag = false;
        });
      }
      if (mp.prompt === "Description") {
        journalLines.map((m) => {
          m.descriptionFlag = false;
        });
      }
      if (mp.prompt === "Supplier") {
        journalLines.map((m) => {
          m.supplierFlag = false;
        });
      }
      if (mp.prompt === "Invoice") {
        journalLines.map((m) => {
          m.invoiceFlag = false;
        });
      }
      if (mp.prompt === "PO") {
        journalLines.map((m) => {
          m.poFlag = false;
        });
      }
      if (mp.prompt === "EmpRef") {
        journalLines.map((m) => {
          m.empRefFlag = false;
        });
      }
      if (mp.prompt === "Tran") {
        journalLines.map((m) => {
          m.tranFlag = false;
        });
      }
      if (mp.prompt === "Amount") {
        journalLines.map((m) => {
          m.amountFlag = false;
        });
      }
      if (mp.prompt === "Line") {
        journalLines.map((m) => {
          m.lineFlag = false;
        });
      }
      if (mp.prompt.toLowerCase() === "in") {
        journalLines.map((m) => {
          m.trackingCodes.map(fm => {
            if (mp.prompt.toLowerCase() === fm.prompt.toLowerCase()) {
              fm.tcFlag = false
            }
            return fm;
          })
        });
      }
      if (mp.prompt.toLowerCase() === "f") {
        journalLines.map((m) => {
          m.trackingCodes.map(fm => {
            if (mp.prompt.toLowerCase() === fm.prompt.toLowerCase()) {
              fm.tcFlag = false
            }
            return fm;
          })
        });
      }
      if (mp.prompt.toLowerCase() === "tax") {
        journalLines.map((m) => {
          m.trackingCodes.map(fm => {
            if (mp.prompt.toLowerCase() === fm.prompt.toLowerCase()) {
              fm.tcFlag = false
            }
            return fm;
          })
        });
      }
      if (mp.prompt.toLowerCase() === "st") {
        journalLines.map((m) => {
          m.trackingCodes.map(fm => {
            if (mp.prompt.toLowerCase() === fm.prompt.toLowerCase()) {
              fm.tcFlag = false
            }
            return fm;
          })
        });
      }
      if (mp.prompt.toLowerCase() === "q") {
        journalLines.map((m) => {
          m.trackingCodes.map(fm => {
            if (mp.prompt.toLowerCase() === fm.prompt.toLowerCase()) {
              fm.tcFlag = false
            }
            return fm;
          })
        });
      }
      if (mp.prompt.toLowerCase() === "frb") {
        journalLines.map((m) => {
          m.trackingCodes.map(fm => {
            if (mp.prompt.toLowerCase() === fm.prompt.toLowerCase()) {
              fm.tcFlag = false
            }
            return fm;
          })
        });
      }
      if (mp.prompt.toLowerCase() === "srb") {
        journalLines.map((m) => {
          m.trackingCodes.map(fm => {
            if (mp.prompt.toLowerCase() === fm.prompt.toLowerCase()) {
              fm.tcFlag = false
            }
            return fm;
          })
        });
      }
      if (mp.prompt.toLowerCase() === "rrb") {
        journalLines.map((m) => {
          m.trackingCodes.map(fm => {
            if (mp.prompt.toLowerCase() === fm.prompt.toLowerCase()) {
              fm.tcFlag = false
            }
            return fm;
          })
        });
      }
      if (mp.prompt.toLowerCase() === "test 9") {
        journalLines.map((m) => {
          m.trackingCodes.map(fm => {
            if (mp.prompt.toLowerCase() === fm.prompt.toLowerCase()) {
              fm.tcFlag = false
            }
            return fm;
          })
        });
      }
      if (mp.prompt.toLowerCase() === "test 10") {
        journalLines.map((m) => {
          m.trackingCodes.map(fm => {
            if (mp.prompt.toLowerCase() === fm.prompt.toLowerCase()) {
              fm.tcFlag = false
            }
            return fm;
          })
        });
      }

    });
    return journalLines;
  };*/

  // insert journal
  insertJournal = async () => {
    let {
      tran,
      batch,
      description,
      voucher,
      date,
      attachments,
      attachmentSize,
      approvalGroup,
      approvalOptions,
      journalLines,
      lines,
    } = this.state;
    let columns = [];
    this.setState({ isLoading: true });
    await this.props.insertJournal();
    //success case of Insert Journal
    if (this.props.journal.insertJournalSuccess) {
      // toast.success(this.props.journal.insertJournalSuccess);

      let journal =
        (this.props.journal.insertJournal &&
          JSON.parse(JSON.stringify(this.props.journal.insertJournal))) ||
        "";
      tran = journal.tran || "";
      batch = journal.batch || "";
      description = journal.description || "";
      voucher = journal.voucher || "";
      date = journal.date || "";
      attachments = journal.attachments || [];

      attachmentSize = 0;
      attachments.map((a, i) => {
        attachmentSize += Number(a.fileSize) || 0;
      });

      approvalOptions = journal.approvalOptions || [];

      let aprvls = [];
      approvalOptions.map((a, i) => {
        aprvls.push({ label: a.option, value: a.option });
      });

      approvalGroup = journal.approvalGroup || "";
      let lines = journal.lines || [];
      let journalLinesTable = lines[0] || {};
      columns = journalLinesTable.Columns || [];

      journalLines = journal.journalLines || [];
      // journalLines.map((item) => {
      //   item.data[]
      // })
      /*journalLines.map((l) => {
        return (
          (l.checked = false),
          (l.chartSortFlag = true),
          (l.chartCodeFlag = true),
          (l.dateFlag = true),
          (l.descriptionFlag = true),
          (l.supplierFlag = true),
          (l.invoiceFlag = true),
          (l.poFlag = true),
          (l.empRefFlag = true),
          (l.tranFlag = true),
          (l.amountFlag = true),
          (l.lineFlag = true)
        );
      });*/

      approvalGroup = {
        label: approvalGroup || "Select Approval Group",
        value: approvalGroup || "",
      };
      approvalOptions = aprvls;
    }
    //error case of Insert  Journal
    if (this.props.journal.insertJournalError) {
      handleAPIErr(this.props.journal.insertJournalError, this.props);
    }
    this.props.clearJournalStates();
    this.setState(
      {
        tran,
        batch,
        description,
        voucher,
        date,
        attachments,
        attachmentSize,
        approvalGroup,
        approvalOptions,
        journalLines,
        isLoading: false,
        columns,
        lines,
      },
      () => {
        this.calcMrgnForSuggestion();
      }
    );
  };

  getJournal = async (transition, type) => {
    let {
      tran,
      batch,
      description,
      voucher,
      date,
      attachments,
      attachmentSize,
      approvalGroup,
      approvalOptions,
      journalLines,
      primDocName,
      activeAtchID,
      lines,
    } = this.state;
    let columns = [];
    await this.props.getJournal(transition);
    //success case of Get Journal
    if (this.props.journal.getJournalSuccess) {
      // toast.success(this.props.journal.getJournalSuccess);

      let journal =
        (this.props.journal.getJournal &&
          JSON.parse(JSON.stringify(this.props.journal.getJournal))) ||
        "";
      tran = journal.tran || "";
      batch = journal.batch || "";
      description = journal.description || "";
      voucher = journal.voucher || "";
      date = journal.date || "";
      attachments = journal.attachments || [];

      attachments.map((m) => {
        let id = uuidv1();
        m.id = id;
      });
      let isPrim = attachments.find((a) => a.primaryDoc.toLowerCase() === "y");
      primDocName = isPrim ? isPrim.fileName : "";
      activeAtchID = isPrim ? isPrim.id : "";

      attachmentSize = 0;
      attachments.map((a, i) => {
        attachmentSize += Number(a.fileSize) || 0;
      });

      approvalOptions = journal.approvalOptions || [];

      let aprvls = [];
      approvalOptions.map((a, i) => {
        aprvls.push({ label: a.option, value: a.option });
      });

      approvalGroup = journal.approvalGroup || "";
      lines = journal.lines || [];
      let journalLinesTable = lines[0] || {};
      columns = journalLinesTable.Columns || [];
      journalLines = journalLinesTable.Rows || [];
  
      // journalLines.map((item) => {
      //   item.data[19] = 0.0;
      // });
      /*journalLines.map((l) => {
        return (
          (l.checked = false),
          (l.chartSortFlag = true),
          (l.chartCodeFlag = true),
          (l.dateFlag = true),
          (l.descriptionFlag = true),
          (l.supplierFlag = true),
          (l.invoiceFlag = true),
          (l.poFlag = true),
          (l.empRefFlag = true),
          (l.tranFlag = true),
          (l.amountFlag = true),
          (l.lineFlag = true)
        );
      });*/

      approvalGroup = {
        label: approvalGroup || "Select Approval Group",
        value: approvalGroup || "",
      };
      approvalOptions = aprvls;
    }
    //error case of Get Journal
    if (this.props.journal.getJournalError) {
      handleAPIErr(this.props.journal.getJournalError, this.props);
    }
    this.props.clearJournalStates();
    this.setState(
      {
        tran,
        batch,
        description,
        voucher,
        date,
        attachments,
        attachmentSize,
        approvalGroup,
        approvalOptions,
        journalLines,
        isLoading: false,
        primDocName,
        activeAtchID,
        columns,
        lines,
      },
      () => {
        this.tableJournalLines();
        this.calcMrgnForSuggestion();
      }
    );
  };

  //calculate the dynamic margin from left for char code suggestion drop down
  calcMrgnForSuggestion = () => {
    if (
      $("#par").offset() &&
      $("#par").offset().left &&
      $("#cd_id") &&
      $("#cd_id").offset()
    ) {
      var dist_ho = Math.abs(
        $("#par").offset().left - $("#cd_id").offset().left
      ); // horizontal distance
      this.setState({ sugg_left: dist_ho });
    }
  };

  //to close date picker on tab change
  closeDatePicker = () => {
    $(".react-datepicker").hide();
  };

  handleChangeApprovalGroup = (approvalGroup) => {
    let { formErrors } = this.state;
    formErrors = Validation.handleValidation(
      "approvalGroup",
      approvalGroup.value,
      formErrors
    );

    this.setState({ approvalGroup, formErrors });
  };

  handleChangeField = (e) => {
    let { name, value } = e.target;
    let { formErrors } = this.state;
    formErrors = Validation.handleValidation(name, value, formErrors);
    this.setState({ [name]: value, formErrors });
  };

  //delete journal attachemnt
  deleteAttachment = async (attach) => {
    let id = attach.recordID;
    let { attachmentSize, attachments, primDocName, activeAtchID } = this.state;
    this.setState({ isLoading: true });
    let recordID = attach.recordID || "";

    await this.props.deleteAttachment(recordID);
    if (this.props.journal.deleteAttachmentSuccess) {
      // toast.success(this.props.journal.deleteAttachmentSuccess);
      let filteredAttachments = attachments.filter(
        (a) => a.recordID != recordID
      );
      if (id === activeAtchID) {
        primDocName = "";
        activeAtchID = "";
      }
      attachmentSize = Number(attachmentSize) - Number(attach.fileSize);
      this.setState({
        attachments: filteredAttachments,
        attachmentSize,
        primDocName,
        activeAtchID,
      });
    }
    if (this.props.journal.deleteAttachmentError) {
      handleAPIErr(this.props.journal.deleteAttachmentError, this.props);
    }
    this.props.clearJournalStates();

    this.setState({ isLoading: false });
  };

  getAttachment = async (recordID, type, fileName) => {
    this.setState({ isLoading: true });

    await this.props.getAttachment(recordID);
    if (this.props.journal.getAttachmentSuccess) {
      // toast.success(this.props.journal.getAttachmentSuccess);
      let resp = this.props.journal.getAttachment;
      if (resp.downloadPresignedURL) {
        downloadFileFromPresignedUrl(resp.downloadPresignedURL);
      } else {
        downloadAttachments(resp, fileName);
      }
    }
    if (this.props.journal.getAttachmentError) {
      handleAPIErr(this.props.journal.getAttachmentError, this.props);
    }
    this.props.clearJournalStates();
    this.setState({ isLoading: false });
  };

  // uplaod journal attchments
  uploadAttachment = async (f) => {
    let { attachmentSize } = this.state;
    let type = f[0].type;
    let name = f[0].name;
    let file = f[0];
    let size = f[0].size;
    if (
      type == "application/pdf" ||
      type ==
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
      type == "image/jpeg" ||
      type == "image/jpg" ||
      type == "image/png" ||
      type == "application/msword" ||
      type == "application/vnd.ms-excel" ||
      type ==
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
      type == "application/vnd.ms-powerpoint" ||
      type == "text/csv"
    ) {
      if (size <= 10485760) {
        //10MB = 10485760 Bytes
        if (attachmentSize < 30932992) {
          //30932992  -> 29.5 MB
          if (Number(size) + Number(attachmentSize) < 30932992) {
            const result = await toBase64(file).catch((e) => e);
            if (result instanceof Error) {
              toast.error(result.message);
              return;
            } else {
              await this.addAttachment([{attachment: result, fileName: name, fileBlob: file}]);
            }
          } else {
            let remaining_KBs = (30932992 - attachmentSize) / 1024; //available space
            remaining_KBs = Number(remaining_KBs).toFixed(2);
            toast.error(
              `You can upload a file of size ${remaining_KBs}KB, Attachmnents limit 29.5MB.`
            );
          }
        } else {
          toast.error(
            "You can't add more attachments. Attachments limit 29.5MB! "
          );
        }
      } else {
        toast.error(
          "This file exceeds the 10MB limit. Please upload a smaller file."
        );
      }
    } else {
      toast.error(
        "Please Select only Attachments of type: 'pdf', 'docx', 'CSV', '.xls', '.xlsx', 'spreadsheets' or 'images'"
      );
    }
  };

  //add attachment
  addAttachment = async (fileList) => {
    let { tran, activeAtchID, primDocName } = this.state;
    if (tran) {
      this.setState({ isLoading: true });
      let data = {
        tran,
        fileName: fileList[0]?.fileName,
        attachment: fileList[0]?.attachment,
      };

      if (isRemoteStorage()) {
        await uploadFileListToS3(fileList, async (uploadedFiles) => {
          data = {
            tran,
            fileName: uploadedFiles[0]?.fileName,
            documentGUID: uploadedFiles[0]?.documentGuid,
            primaryDocument: "Y",
          };

          await this.props.addAttachment(data);
          if (this.props.journal.addAttachmentSuccess) {
            toast.success(this.props.journal.addAttachmentSuccess);
            let attachments = this.props.journal.addAttachment || [];

            let attachmentSize = 0;
            attachments.map((a, i) => {
              attachmentSize += Number(a.fileSize) || 0;
            });
            if (attachments.length === 1) {
              activeAtchID = attachments[0].id;
              primDocName = attachments[0].fileName;
            }
            this.setState({
              attachments,
              attachmentSize,
              activeAtchID,
              primDocName,
            });
          }
          if (this.props.journal.addAttachmentError) {
            handleAPIErr(this.props.journal.addAttachmentError, this.props);
          }
          this.props.clearJournalStates();

          this.setState({ isLoading: false });
        })
      } else {

        await this.props.addAttachment(data);
        if (this.props.journal.addAttachmentSuccess) {
          toast.success(this.props.journal.addAttachmentSuccess);
          let attachments = this.props.journal.addAttachment || [];
          
          let attachmentSize = 0;
          attachments.map((a, i) => {
            attachmentSize += Number(a.fileSize) || 0;
          });
          if (attachments.length === 1) {
            activeAtchID = attachments[0].id;
            primDocName = attachments[0].fileName;
          }
          this.setState({
            attachments,
            attachmentSize,
            activeAtchID,
            primDocName,
          });
        }
        if (this.props.journal.addAttachmentError) {
          handleAPIErr(this.props.journal.addAttachmentError, this.props);
        }
        this.props.clearJournalStates();
        
        this.setState({ isLoading: false });
      }
    } else {
      toast.error("Tran is missing!");
    }
  };

  //get chart codes
  getChartCodes = async () => {
    await this.props.getChartCodes();
    //success case of Get Chart Codes
    if (this.props.chart.getChartCodesSuccess) {
      // toast.success(this.props.chart.getChartCodesSuccess);
      let getChartCodes = this.props.chart.getChartCodes || "";
      this.setState({
        chartCodesList: getChartCodes.chartCodes || [],
        clonedChartCodesList: getChartCodes.chartCodes || [],
      });
    }

    //success case of Get Chart Codes
    if (this.props.chart.getChartCodesSuccess) {
      // toast.success(this.props.chart.getChartCodesSuccess);
    }
    //error case of Get Chart Codes
    if (this.props.chart.getChartCodesError) {
      handleAPIErr(this.props.chart.getChartCodesError, this.props);
    }
    this.props.clearChartStates();
  };

  //Journal Lines checkbox
  handleJrnlLinesCheckBoxes = async (e, jrnlItem, index) => {
    let { journalLines, checkAllJrnltem } = this.state;
    let { checked } = e.target;

    if (jrnlItem === "all") {
      if (checked) {
        journalLines.map((e, i) => {
          e.checked = true;
          return e;
        });
      } else {
        journalLines.map((e, i) => {
          e.checked = false;
          return e;
        });
      }
      this.setState({
        journalLines,
        checkAllJrnltem: checked,
      });
    } else {
      if (checked) {
        jrnlItem.checked = checked;
        journalLines[index] = jrnlItem;

        let _check = journalLines.findIndex((c) => c.checked === false);
        if (_check === -1) {
          checkAllJrnltem = true;
        }
        this.setState({ journalLines, checkAllJrnltem });
      } else {
        jrnlItem.checked = checked;
        journalLines[index] = jrnlItem;
        this.setState({ checkAllJrnltem: false, journalLines });
      }
    }
  };

  removeJournalLine = () => {
    let { journalLines } = this.state;

    window.$("#tableJournalLines").DataTable().destroy();

    let jrnlItem = journalLines.find((e) => e.checked);
    if (jrnlItem) {
      //remove item from Journal array
      let _journalLines = journalLines.filter((e) => !e.checked);
      this.setState({ journalLines: _journalLines }, () => {
        this.tableJournalLines();
      });
    } else {
      toast.error("Please Select Journal Item To Remove!");
    }
  };

  //handle change journal lines fields
  hanldeJrnlLineFields = (e, jrnl, index, j) => {
    let name = "";
    let value = "";
    let { journalLines, chartCodesList } = this.state;
    let clonedChartCodesList = [...chartCodesList];

    let chartCodeSuggestion = null;
    if (e.target.name === "chartCode") {
      //chart code sugggestion
      name = e.target.name;
      value = e.target.value;
      if (!value) {
        clonedChartCodesList = [];
      } else {
        let chartCodesListFilterdData = clonedChartCodesList.filter((c) => {
          return (
            c.code.toUpperCase().includes(value.toUpperCase()) ||
            (c.description.toUpperCase().includes(value.toUpperCase()) &&
              c.sort.toUpperCase() === jrnl[1].toUpperCase())
          );
        });
        clonedChartCodesList = chartCodesListFilterdData;
      }
      chartCodeSuggestion = j;
    } else {
      name = e.target.name;
      value = e.target.value;
    }

    // jrnl[name] = value;
    jrnl[index] = value;

    // journalLines[index] = jrnl;

    this.setState({ journalLines, clonedChartCodesList, chartCodeSuggestion });
  };

  // when select chart code from suggestions e.g. auto-completion
  changeChartCode = (c, data, i, j) => {
    let { journalLines } = this.state;
    // update in journal lines
    data[j] = c.code || "";
    // jrnl[index] = value;
    // journalLines[index] = line;
    this.setState({ journalLines });
  };

  convertTwoDecimal = (e, line) => {
    let { name, value } = e.target;

    let val = Number(value).toFixed(2) || 0.0;

    let { journalLines } = this.state;
    line[name] = val;
    this.setState({ journalLines });
  };

  //handle change journal lines flags
  handleChangeFlags = (e, line, index) => {
    let { name, value } = e.target;
    let { journalLines } = this.state;

    let trackingCodes = line.trackingCodes || [];
    trackingCodes.map((f, i) => {
      if (f.type && f.type.toLowerCase() == name.toLowerCase()) {
        f.value = value;
      }
      return f;
    });

    this.setState({ journalLines });
  };

  updateJournal = async () => {
    let {
      tran,
      batch,
      description,
      voucher,
      date,
      approvalGroup,
      journalLines,
      formErrors,
      lines,
    } = this.state;
    lines[0].Rows = journalLines;

    formErrors = Validation.handleWholeValidation(
      { approvalGroup: approvalGroup.value, description },
      formErrors
    );

    if (!formErrors.approvalGroup && !formErrors.description) {
      let journal = {
        tran,
        batch,
        description,
        voucher,
        date,
        approvalGroup: approvalGroup.value || "",
        journalLines,
        lines,
      };
      this.setState({ isLoading: true });
      await this.props.updateJournal(journal);
      //success case
      if (this.props.journal.updateJournalSuccess) {
        toast.success(this.props.journal.updateJournalSuccess);

        this.props.history.push("/journals", {
          tallies: "Draft",
          addEditJournalCheck: true,
          addEditJournalTran: tran,
        });
      }
      //error case
      if (this.props.journal.updateJournalError) {
        toast.error(this.props.journal.updateJournalError);
      }

      this.props.clearJournalStates();
      this.setState({ formErrors, isLoading: false });
    }
  };

  //ADD Journal Lines -> when click to + button on Journal Items
  addJournalLines = async () => {
    let { journalLines, defaultUserFlags, filterData2, columns } = this.state;
    window.$("#tableJournalLines").DataTable().destroy();
    let lastLineItem = journalLines[journalLines.length - 1];
    let data = 1;
    if (lastLineItem) {
      if (Number(lastLineItem.data[20]) != NaN) {
        if (Number(lastLineItem.data[20]) < 1) {
          data = 1;
        } else {
          data = Number(lastLineItem.data[20]) + 1;
        }
      } else {
        data = 1;
      }
    } else {
      data = 1;
    }

    //pre-fill the Chart Sort with the user's default chart sort.
    let chartSort =
      (this.props.user.getDefaultValues &&
        this.props.user.getDefaultValues.defaultValues &&
        this.props.user.getDefaultValues.defaultValues.chartSort) ||
      "";

    let flags = defaultUserFlags || []; //user's flags

    let obj = {
      data: [
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        data,
      ],
      parentRefId: "",
      refId: "",
    };

    journalLines = await [...journalLines, obj];
    // journalLines.data.sort();

    //return false;
    //let journalLinesData = [];
    /*    journalLinesData = await this.handleShowHideColumns(
        filterData2,
        journalLines
      );
 
  
      columns.map(c => {
        journalLines.map(l => {
          l.trackingCodes.map(t => {
            if (c.prompt === t.prompt) {
            
              t[t.prompt] = t.value
            }
          })
        })
      })
  */

    this.setState({ journalLines, checkAllJrnltem: false }, () => {
      this.tableJournalLines();
      if (journalLines.length === 1) {
        this.calcMrgnForSuggestion();
      }
    });
  };

  onDiscard = async () => {
    /*Check When Edit Journal and then user Save or Cancel that edit,
    then load the same Journal user just edited/created?.*/

    let state = this.props.history.location
      ? this.props.history.location.state
      : "";

    this.props.history.push("/journals", {
      tallies: "Draft",
      addEditJournalCheck: true,
      addEditJournalTran: this.state.tran,
      defaultData: state.defaultData,
    });
  };

  handleChangeJournalLines = (e, data, i, j, fldName) => {
    let { journalLines } = this.state;
    let value = e;
    if (fldName === "date") {
      value = new Date(e).getTime();
      data[j] = value;
    } else {
      data[j] = e.target.value;
    }

    this.setState({ journalLines });
  };

  render() {
    let {
      isLoading,
      batch,
      description,
      voucher,
      date,
      approvalGroup,
      approvalOptions,
      attachments,
      formErrors,
      defaultUserFlags,
      journalLines,
      sugg_left,
      chartCodeSuggestion,
      clonedChartCodesList,
      checkAllJrnltem,
      primDocName,
      activeAtchID,
      columns,
    } = this.state;
    return (
      <>
        {isLoading ? <div className="se-pre-con"></div> : ""}
        <div className="dashboard">
          {/* top nav bar */}
          <Header props={this.props} journalForm={true} />
          {/* end */}
          {/* body part */}
          <div className="dashboard_body_content">
            {/* top Nav menu*/}
            <TopNav />
            {/* end */}
            <section id="">
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                }}
              >
                <div className="container-fluid ">
                  <div className="main_wrapper mt-md-5 mt-2 sup-main-pad">
                    <div className="row d-flex justify-content-center h-60vh">
                      <div className="col-12 col-md-12 w-100 ">
                        <div className="forgot_form_main report_main sup-inner-pad">
                          <div className="forgot_header">
                            <div className="modal-top-header">
                              <div className="row">
                                <div className="col d-flex justify-content-end s-c-main">
                                  <button
                                    type="button"
                                    className={
                                      this.state.id_save
                                        ? "btn-save btn_focus"
                                        : "btn-save"
                                    }
                                    tabIndex="2236"
                                    id="id_save"
                                    onFocus={this.onFocus}
                                    onBlur={this.onBlur}
                                    onClick={this.updateJournal}
                                  >
                                    <span className="fa fa-check"></span>
                                    Save
                                  </button>

                                  <button
                                    onClick={this.onDiscard}
                                    type="button"
                                    className={
                                      this.state.id_cancel
                                        ? "btn-save btn_focus"
                                        : "btn-save"
                                    }
                                    tabIndex="2237"
                                    id="id_cancel"
                                    onFocus={this.onFocus}
                                    onBlur={this.onBlur}
                                  >
                                    <span className="fa fa-ban"></span>
                                    Cancel
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-6">
                                <div className="form-group custon_select">
                                  <label htmlFor="id_date">Voucher</label>
                                  <div className="modal_input">
                                    <input
                                      autoFocus
                                      tabIndex="1123"
                                      maxlength="25"
                                      type="text"
                                      className="form-control"
                                      name="voucher"
                                      value={voucher}
                                      onChange={this.handleChangeField}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group custon_select">
                                  <label htmlFor="id_dt">Date</label>
                                  <div className="modal_input datePickerUP">
                                    <DatePicker
                                      name="date"
                                      selected={date}
                                      id="id_dt"
                                      tabIndex="1124"
                                      maxlength="25"
                                      onKeyDown={(e) => {
                                        if (e.key == "Tab") {
                                          this.closeDatePicker();
                                        }
                                      }}
                                      onChange={(date) =>
                                        this.setState({
                                          date: new Date(date).getTime(),
                                        })
                                      }
                                      dateFormat="d MMM yyyy"
                                      autoComplete="off"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group custon_select">
                                  <label htmlFor="id_date">Batch</label>
                                  <div className="modal_input">
                                    <input
                                      type="text"
                                      tabIndex="1125"
                                      maxlength="25"
                                      className="form-control"
                                      value={batch}
                                      disabled
                                      onChange={() => {}}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group custon_select">
                                  <label htmlFor="id_date">
                                    Approval Group
                                  </label>
                                  <Select
                                    className="width-selector"
                                    // classNamePrefix="track_menu custon_select-selector-inner"
                                    styles={_customStyles}
                                    classNamePrefix="react-select"
                                    value={approvalGroup}
                                    options={approvalOptions}
                                    onChange={this.handleChangeApprovalGroup}
                                    tabIndex="1126"
                                    maxlength="25"
                                    id="id_appGroup"
                                    theme={(theme) => ({
                                      ...theme,
                                      border: 0,
                                      borderRadius: 0,
                                      colors: {
                                        ...theme.colors,
                                        primary25: "#f2f2f2",
                                        primary: "#f2f2f2",
                                      },
                                    })}
                                  />
                                  <span
                                    className="input_field_icons rit-icon-input"
                                    data-toggle="collapse"
                                    data-target="#asd"
                                  ></span>
                                  <div className="text-danger error-12">
                                    {formErrors.approvalGroup !== ""
                                      ? formErrors.approvalGroup
                                      : ""}
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="form-group custon_select">
                                  <label htmlFor="id_date">Description</label>
                                  <div className="modal_input">
                                    <input
                                      tabIndex="1127"
                                      maxlength="100"
                                      type="text"
                                      className="form-control"
                                      name="description"
                                      value={description}
                                      onChange={this.handleChangeField}
                                    />
                                  </div>
                                  <div className="text-danger error-12">
                                    {formErrors.description !== ""
                                      ? formErrors.description
                                      : ""}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="forgot_body">
                              <div className="col-12 mt-md-3 mb-1">
                                <div className="forgot_header">
                                  <div className="modal-top-header">
                                    <div className="col-auto d-flex justify-content-end s-c-main p-0">
                                      <div className=" d-flex justify-content-end s-c-main w-sm-100">
                                        <button
                                          type="button"
                                          className="btn-save exp-top-btn expxtopbtn1"
                                          onClick={this.addJournalLines}
                                        >
                                          <span className="fa fa-plus-circle"></span>
                                        </button>
                                        <div className=" d-flex justify-content-end s-c-main w-sm-100">
                                          <button
                                            type="button"
                                            className="btn-save exp-top-btn"
                                            onClick={this.removeJournalLine}
                                          >
                                            <span className="fa fa-trash"></span>
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="row mt-3" id="par">
                                <div className="col-12">
                                  <div className="login_form expense-form">
                                    <div className="login_table_list table-reponsive exp-dropdown-ui table_lines">
                                      <table
                                        id="tableJournalLines"
                                        className="table  project_table shadow-none newpo--edit exp_edit"
                                      >
                                        <thead>
                                          <tr>
                                            <th
                                              scope="col"
                                              className="exp_th1 mm_contact-name"
                                            >
                                              <div className="form-group">
                                                <label className="dash_container dash_remember table-check unckeck">
                                                  <input
                                                    type="checkbox"
                                                    name={"chk1"}
                                                    id={"chk1"}
                                                    checked={checkAllJrnltem}
                                                    onChange={(e) =>
                                                      this.handleJrnlLinesCheckBoxes(
                                                        e,
                                                        "all"
                                                      )
                                                    }
                                                  />
                                                  <span
                                                    id="chk1"
                                                    className="dash_checkmark"
                                                  ></span>
                                                </label>
                                              </div>
                                            </th>
                                            {columns.map((m, i) => {
                                              return (
                                                <th
                                                  scope="col"
                                                  key={i}
                                                  className="text-left exp-supplier-th"
                                                >
                                                  <span className="table_lines_hed">
                                                    {" "}
                                                    {m.ColName}
                                                  </span>
                                                </th>
                                              );
                                            })}

                                            <th>
                                              <span
                                                className="user_setup_hed2"
                                                onClick={() =>
                                                  this.openModal(
                                                    "openSettingsModal"
                                                  )
                                                }
                                              >
                                                {" "}
                                                <img
                                                  src="./images/user-setup/bars.png"
                                                  alt="bars"
                                                ></img>
                                              </span>
                                            </th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                        {journalLines.map((item, i) => {
                                            return (
                                              <tr key={i}>
                                                <td>
                                                  <div className="col align-self-center text-center pr-0">
                                                    <div className="form-group mb-0 check-line">
                                                      <label className="dash_container dash_remember table-check unckeck">
                                                        <input
                                                          type="checkbox"
                                                          name={"chk1"}
                                                          id={"chk1" + i}
                                                          checked={item.checked}
                                                          onChange={(e) =>
                                                            this.handleJrnlLinesCheckBoxes(
                                                              e,
                                                              item,
                                                              i
                                                            )
                                                          }
                                                        />
                                                        <span
                                                          id={"chk1" + i}
                                                          className="dash_checkmark"
                                                        ></span>
                                                      </label>
                                                    </div>
                                                  </div>
                                                </td>

                                                {columns.map((c, j) => {
                                                  let ColType = c.ColType;
                                                  let ColName = c.ColName;
                                                  if (
                                                    ColType === "Numeric" ||
                                                    ColType === "Number"
                                                  ) {
                                                    return (
                                                      <td
                                                        className="text-left"
                                                        data-order={
                                                          item.data ? item.data[j] : undefined
                                                        }
                                                      >
                                                        <input
                                                          type="number"
                                                          name="chartSort"
                                                          value={item.data ? item.data[j] : ''}
                                                          className="input_height wd-108"
                                                          readOnly={
                                                            c.Editable
                                                              ? false
                                                              : true
                                                          }
                                                          onChange={(e) =>
                                                            this.handleChangeJournalLines(
                                                              e,
                                                              item.data,
                                                              i,
                                                              j
                                                            )
                                                          }
                                                        />
                                                      </td>
                                                    );
                                                  } else if (
                                                    ColType === "Date"
                                                  ) {
                                                    return (
                                                      <>
                                                        <td
                                                          className="text-left"
                                                          data-order={
                                                            item.data ? item.data[j] : undefined
                                                          }
                                                        >
                                                          <div
                                                            className="input_width m-0"
                                                            style={{
                                                              width: "100px",
                                                            }}
                                                          >
                                                            <DatePicker
                                                              selected={Number(
                                                                item.data ? item.data[j] : undefined
                                                              )}
                                                              onKeyDown={(
                                                                e
                                                              ) => {
                                                                if (
                                                                  e.key == "Tab"
                                                                ) {
                                                                  this.refs[
                                                                    "jrnl" + i
                                                                  ].setOpen(
                                                                    false
                                                                  );
                                                                }
                                                              }}
                                                              ref={"jrnl" + i}
                                                              className="uppercaseText"
                                                              dateFormat="d MMM yyyy"
                                                              autoComplete="off"
                                                              name="date"
                                                              onChange={(e) =>
                                                                this.handleChangeJournalLines(
                                                                  e,
                                                                  item.data,
                                                                  i,
                                                                  j,
                                                                  "date"
                                                                )
                                                              }
                                                            />
                                                          </div>
                                                        </td>
                                                      </>
                                                    );
                                                  } else if (
                                                    ColType === "Lookup"
                                                  )
                                                    if (
                                                      ColName === "Chart Sort"
                                                    ) {
                                                      return (
                                                        <td
                                                          className="text-left"
                                                          data-order={
                                                            item.data ? item.data[j] : undefined
                                                          }
                                                        >
                                                          <input
                                                            type="text"
                                                            name="chartSort"
                                                            value={item.data ? item.data[j] : ''}
                                                            className="input_height wd-108"
                                                            readOnly={
                                                              c.Editable
                                                                ? false
                                                                : true
                                                            }
                                                            onChange={(e) =>
                                                              this.handleChangeJournalLines(
                                                                e,
                                                                item.data,
                                                                i,
                                                                j
                                                              )
                                                            }
                                                          />
                                                        </td>
                                                      );
                                                    } else {
                                                      return (
                                                        <>
                                                          <td
                                                            className="text-left"
                                                            id="cd_id"
                                                            data-order={
                                                              item.data ? item.data[j] : undefined
                                                            }
                                                          >
                                                            <input
                                                              type="text"
                                                              // className={
                                                              //   item.data[j]
                                                              //     .length <= 4
                                                              //     ? "input_height wd-45 uppercaseText"
                                                              //     : item.data[j]
                                                              //         .length <=
                                                              //       8
                                                              //     ? "input_height wd-72 uppercaseText"
                                                              //     : "input_height wd-101 uppercaseText"
                                                              // }
                                                              className="input_height wd-108"
                                                              name="chartCode"
                                                              value={
                                                                item.data ? item.data[j] : undefined
                                                              }
                                                              onChange={(e) =>
                                                                this.hanldeJrnlLineFields(
                                                                  e,
                                                                  item.data,
                                                                  j,
                                                                  i
                                                                )
                                                              }
                                                              id="chartCode"
                                                              autoComplete="off"
                                                              onBlur={() =>
                                                                setTimeout(
                                                                  () => {
                                                                    this.setState(
                                                                      {
                                                                        chartCodeSuggestion:
                                                                          null,
                                                                      }
                                                                    );
                                                                  },
                                                                  200
                                                                )
                                                              }
                                                            />
                                                            {chartCodeSuggestion ==
                                                              i && (
                                                              <div
                                                                className={
                                                                  "chart_menue d-block"
                                                                }
                                                                style={{
                                                                  marginLeft:
                                                                    sugg_left,
                                                                }}
                                                              >
                                                                {clonedChartCodesList.length >
                                                                0 ? (
                                                                  <ul className="invoice_vender_menu">
                                                                    {clonedChartCodesList.map(
                                                                      (
                                                                        c,
                                                                        ind
                                                                      ) => {
                                                                        return (
                                                                          <li
                                                                            className="cursorPointer"
                                                                            onClick={() =>
                                                                              this.changeChartCode(
                                                                                c,
                                                                                item.data,
                                                                                i,
                                                                                j
                                                                              )
                                                                            }
                                                                          >
                                                                            <div className="vender_menu_right chart_new">
                                                                              <h3 className="chart_vender_text">
                                                                                <span>
                                                                                  {" "}
                                                                                  {
                                                                                    c.code
                                                                                  }{" "}
                                                                                </span>{" "}
                                                                                <span className="right_desc">
                                                                                  {" "}
                                                                                  {
                                                                                    c.description
                                                                                  }
                                                                                </span>
                                                                              </h3>
                                                                            </div>
                                                                          </li>
                                                                        );
                                                                      }
                                                                    )}
                                                                  </ul>
                                                                ) : (
                                                                  <div className="sup_nt_fnd text-center">
                                                                    <h6>
                                                                      No Chart
                                                                      Code Found
                                                                    </h6>
                                                                  </div>
                                                                )}
                                                              </div>
                                                            )}
                                                          </td>
                                                        </>
                                                      );
                                                    }
                                                  else {
                                                    return (
                                                      <>
                                                        <td
                                                          className="text-left"
                                                          data-order={
                                                            item.data ? item.data[j] : undefined
                                                          }
                                                        >
                                                          <input
                                                            type="text"
                                                            name="chartSort"
                                                            value={item.data ? item.data[j] : ''}
                                                            className="input_height wd-108"
                                                            readOnly={
                                                              c.Editable
                                                                ? false
                                                                : true
                                                            }
                                                            onChange={(e) =>
                                                              this.handleChangeJournalLines(
                                                                e,
                                                                item.data,
                                                                i,
                                                                j
                                                              )
                                                            }
                                                          />
                                                        </td>
                                                      </>
                                                    );
                                                  }
                                                })}
                                                <td
                                                  className="text-left"
                                                  data-order={""}
                                                ></td>
                                              </tr>
                                            );
                                          })}
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div
                              className="collapse show"
                              id="expenseAttachments"
                            >
                              <div className="forgot_body">
                                <div className="col-12 mt-2">
                                  <div className="form-group custon_select">
                                    <div
                                      id="drop-area-exp"
                                      className="exp_drag_area"
                                    >
                                      <input
                                        type="file"
                                        id="fileElem-attach"
                                        className="form-control d-none"
                                        // accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint
                                        // , application/pdf, image/jpeg,image/jpg,image/png,
                                        //  .csv, .xlsx, .xls,
                                        //  application/vnd.openxmlformats-officedocument.wordprocessingml.document,
                                        //  application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                        onChange={(e) => {
                                          this.uploadAttachment(e.target.files);
                                        }}
                                        onClick={(event) => {
                                          event.currentTarget.value = null;
                                        }} //to upload the same file again
                                      />
                                      <label
                                        className="upload-label"
                                        htmlFor="fileElem-attach"
                                      >
                                        <div className="upload-text">
                                          <img
                                            src="images/drag-file.png"
                                            className="import_icon img-fluid"
                                            alt="upload-attachment"
                                          />
                                        </div>
                                      </label>
                                    </div>
                                    <div className="exp_upload_files">
                                      <ul className="doc-upload-ul">
                                        {attachments.map((a, i) => {
                                          return (
                                            <li
                                              className={
                                                a.recordID === activeAtchID
                                                  ? "green_li cursorPointer"
                                                  : "blue_li cursorPointer"
                                              }
                                            >
                                              <div className="pdf-custom-radio">
                                                <label
                                                  className="check_main remember_check"
                                                  htmlFor={`att` + i}
                                                >
                                                  <input
                                                    type="checkbox"
                                                    className="custom-control-input"
                                                    id={`att` + i}
                                                    name="check"
                                                    checked={
                                                      a.recordID ===
                                                      activeAtchID
                                                    }
                                                    onChange={() => {
                                                      this.setState({
                                                        activeAtchID:
                                                          a.recordID,
                                                        primDocName:
                                                          a.fileName || "",
                                                      });
                                                    }}
                                                  />
                                                  <span className="click_checkmark"></span>
                                                </label>
                                              </div>

                                              <span className="fa fa-file"></span>
                                              <p
                                                onClick={() =>
                                                  this.getAttachment(
                                                    a.recordID,
                                                    a.fileName
                                                  )
                                                }
                                              >
                                                {a.fileName || ""}
                                              </p>
                                              <span
                                                onClick={() => {
                                                  this.deleteAttachment(a);
                                                }}
                                                className="fa fa-times"
                                              ></span>
                                            </li>
                                          );
                                        })}
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                                <div className="exp_radio_pad">
                                  <div className="row">
                                    <div className="col-12">
                                      <div className="form-group">
                                        <label>
                                          Primary Document:{" "}
                                          {primDocName && (
                                            <p className="doc-primary-box">
                                              {" "}
                                              {primDocName}
                                            </p>
                                          )}
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {/* <div className="row">
                              <div className="col-12 mt-2 mb-2">
                                <div className="form-group custon_select  text-center mb-0 border-rad-5">
                                  <div id="drop-area-attach">
                                    <input
                                      type="file"
                                      id="fileElem-attach"
                                      className="form-control d-none uppercaseText"
                                      accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint
                                      , application/pdf, image/jpeg,image/jpg,image/png,
                                       .csv, .xlsx, .xls,
                                       application/vnd.openxmlformats-officedocument.wordprocessingml.document,
                                       application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                      onChange={(e) => {
                                        this.uploadAttachment(e.target.files);
                                      }}
                                      onClick={(event) => {
                                        event.currentTarget.value = null;
                                      }} //to upload the same file again
                                    />
                                    <label
                                      className="upload-label"
                                      htmlFor="fileElem-attach"
                                    >
                                      <div className="upload-text">
                                        <img
                                          src="images/drag-file.png"
                                          className="import_icon img-fluid"
                                          alt="upload-attachment"
                                        />
                                      </div>
                                    </label>
                                  </div>
                                </div>
                              </div>
                              {attachments &&
                                attachments.length > 0 &&
                                attachments.map((a, i) => {
                                  return (
                                    <div key={i} className="col-md-12 mb-md-4">
                                      <span className="del_notes">
                                        <i
                                          onClick={() =>
                                            this.deleteAttachment(a)
                                          }
                                          className="fa fa-times cursorPointer"
                                        ></i>

                                        <span
                                          className="cursorPointer"
                                          onClick={() =>
                                            this.getAttachment(
                                              a.recordID,
                                              a.fileName
                                            )
                                          }
                                        >
                                          {a.fileName || ""}
                                        </span>
                                      </span>
                                    </div>
                                  );
                                })}
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </section>
          </div>
          {/* end */}
          <Settings
            // themeColor={this.state.themeColor}
            openSettingsModal={this.state.openSettingsModal}
            openModal={this.openModal}
            closeModal={this.closeModal}
            columns={this.state.columns}
            pageLength={this.state.pageLength}
            handleChangeSettings={this.handleChangeSettings}
            handleSaveSettings={this.handleSaveSettings}
            handleCloseSettingModal={this.handleCloseSettingModal}
          />
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  journal: state.journal,
  chart: state.chart,
  user: state.user,
});

export default connect(mapStateToProps, {
  insertJournal: JournalActions.insertJournal,
  getJournal: JournalActions.getJournal,
  deleteAttachment: JournalActions.deleteAttachment,
  getAttachment: JournalActions.getAttachment,
  addAttachment: JournalActions.addAttachment,
  updateJournal: JournalActions.updateJournal,
  getDefaultValues: UserActions.getDefaultValues,
  getChartCodes: ChartActions.getChartCodes,
  clearChartStates: ChartActions.clearChartStates,
  clearUserStates: UserActions.clearUserStates,
  clearJournalStates: JournalActions.clearJournalStates,
})(JournalForm);
