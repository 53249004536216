import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Col, Row } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import Select from "react-select";
import { toast } from "react-toastify";
import "./BulkChangeModal.css";

import {
  getPrimeApprovalTransfer,
  getApprovalTransferTallies,
  transferApprovals,
} from "../../../../Actions/SetupRequest/ApprovalTransferActions";

export default function BulkChangeModal({
  openModal,
  onBulkChangeClose,
  onBulkChangeSave,
}) {
  const dispatch = useDispatch();
  const primeApprovalTransferLoading = useSelector(
    (state) => state.approvalTransfer.primeApprovalTransferLoading
  );

  const transferTalliesLoading = useSelector(
    (state) => state.approvalTransfer.transferTalliesLoading
  );

  const transferStatus = useSelector(
    (state) => state.approvalTransfer.transferStatus
  );

  const approverList = useSelector((state) => state.approvalTransfer.userList);

  const approvalModules = useSelector(
    (state) => state.approvalTransfer.modules
  );
  const tallies = useSelector((state) => state.approvalTransfer.tallies);

  const [oldApprover, setOldApprover] = useState(null);
  const [newApprover, setNewApprover] = useState(null);
  const [modules, setModules] = useState([]);

  const waiting = () =>
    primeApprovalTransferLoading ||
    transferTalliesLoading ||
    transferStatus === "TRANSFERRING";

  const getApproverByRecordId = (recordId) =>
    approverList.find((a) => a.recordID === recordId);

  const reset = () => {
    setOldApprover(null);
    setNewApprover(null);
  };

  const handleClose = (event) => {
    reset();
    onBulkChangeClose(event);
  };

  const handleSave = () => {
    if (!oldApprover || !newApprover) {
      toast.warning("Please select both approvers to transfer");
      return;
    }

    const newModules = modules.map(({ module, selected }) => {
      return {
        module,
        selected,
      };
    });

    dispatch(
      transferApprovals(
        oldApprover.userLogin,
        newApprover.userLogin,
        newModules
      )
    );
  };

  const handleSelectOldApprover = (event) => {
    const approver = getApproverByRecordId(event.value);
    setOldApprover(approver);

    dispatch(getApprovalTransferTallies(approver.userLogin));
  };

  const handleSelectNewApprover = (event) => {
    const approver = getApproverByRecordId(event.value);
    setNewApprover(approver);
  };

  const handleSelectionChange = (moduleName, checked) => {
    const newModules = modules.map((m) => {
      let selected = m.selected;
      if (m.module === moduleName) {
        selected = checked ? "Y" : "N";
      }
      return {
        ...m,
        selected,
      };
    });

    setModules(newModules);
  };

  const triggerTransferComplete = () => {
    reset();
    onBulkChangeSave();
  };

  const triggerTransferFailed = () => {
    toast.error("Transfer failed");
  };

  useEffect(() => {
    if (openModal) {
      dispatch(getPrimeApprovalTransfer());
    }
  }, [openModal]);

  useEffect(() => {
    if (!primeApprovalTransferLoading && approvalModules) {
      setModules(approvalModules);
    }
  }, [approvalModules, primeApprovalTransferLoading]);

  useEffect(() => {
    if (!transferTalliesLoading && tallies?.length > 0) {
      const modulesWithTallies = approvalModules.map((m) => {
        const module = tallies.find((ta) => ta.module === m.module);
        let tally = 0;
        if (module) {
          tally = module.tally;
        }

        return {
          ...m,
          tally,
        };
      });

      setModules(modulesWithTallies);
    }
  }, [tallies, transferTalliesLoading]);

  useEffect(() => {
    switch (transferStatus) {
      case "TRANSFER_COMPLETED":
        triggerTransferComplete();
        break;
      case "TRANSFER_FAILED":
        triggerTransferFailed();
        break;
      default:
        break;
    }
  }, [transferStatus]);

  return (
    <>
      <Modal size="lg" centered show={openModal} className="bulk-change-modal">
        <Modal.Body className="p-0">
          <div className="container-fluid p-0">
            <div className="main_wrapper">
              <div className="row d-flex h-100 p-0">
                <div className="col-12 justify-content-center align-self-center">
                  <div className="p-0">
                    {/* <div className="bulk-change-modal-header"> */}
                    <div className="setting_header thead_bg">
                      <h3 className="Indirecttaxcode-poup_heading">Approval Transfer</h3>
                      <div className="Indirecttaxcode-poup_can-sav-btn">
                        <button
                          disabled={waiting()}
                          className="btn can-btn1"
                          onClick={handleSave}
                        >
                          <img
                            src="images/user-setup/check-white.png"
                            alt="check"
                          />
                          Save
                        </button>
                        <button className="btn can-btn1 pl-3" onClick={handleClose}>
                          <img
                            src="images/user-setup/cancel-white.png"
                            alt="cancel"
                          />
                          Cancel
                        </button>
                      </div>
                    </div>
                    <div className="bulk-change-modal-body">
                      {primeApprovalTransferLoading ? (
                        <div className="empty-loading-container">
                          <div className="loading-spinner">Please wait...</div>
                        </div>
                      ) : (
                        <div>
                          <Row className="bulk-change-modal-dropdowns">
                            <Col className="dropdown">
                              <ApproverDropdown
                                label="Old Approver"
                                approvers={approverList}
                                selectedApprover={oldApprover}
                                onChange={handleSelectOldApprover}
                              />
                            </Col>
                            <Col className="dropdown">
                              <ApproverDropdown
                                label="New Approver"
                                approvers={approverList}
                                selectedApprover={newApprover}
                                onChange={handleSelectNewApprover}
                              />
                            </Col>
                          </Row>
                          {transferTalliesLoading && (
                            <Row>
                              <Col>
                                <div className="loading-spinner">
                                  Updating tallies...
                                </div>
                              </Col>
                            </Row>
                          )}
                          <Row>
                            <Col>
                              <ModuleList
                                modules={modules}
                                onSelectionChange={handleSelectionChange}
                              />
                            </Col>
                          </Row>
                        </div>
                      )}
                      {transferStatus === "TRANSFERRING" ? (
                        <div className="loading-spinner">Transferring...</div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );

  function ApproverDropdown({ label, approvers, selectedApprover, onChange }) {
    const selectTheme = (theme) => ({
      ...theme,
      border: 0,
      borderRadius: 0,
      colors: {
        ...theme.colors,
        primary25: "#f2f2f2",
        primary: "#f2f2f2",
      },
    });

    const options = approvers?.map((approver) => {
      return {
        value: approver.recordID,
        label: `${approver.userName} - ${approver.userType}`,
      };
    });

    const selected = selectedApprover
      ? {
          value: selectedApprover.recordID,
          label: `${selectedApprover.userName} - ${selectedApprover.userType}`,
        }
      : {
          label: "Select",
          value: "",
        };

    return (
      <div className="form-group custon_select">
        <label>{label}</label>
        <Select
          value={selected}
          onChange={onChange}
          classNamePrefix="custon_select-selector-inner"
          theme={selectTheme}
          options={options}
        />
      </div>
    );
  }
}

function ModuleList({ modules, onSelectionChange }) {
  return (
    <table className="table modules-table" width="100%">
      <thead className="thead_bg hover-border">
        <tr>
          <th scope="col">Module</th>
          <th scope="col" className="selected-col">
            Selected
          </th>
          <th scope="col" className="tallies-col">
            Tallies
          </th>
        </tr>
      </thead>
      <tbody>
        {modules?.map(({ module, tally }) => (
          <tr key={module}>
            <td>{module}</td>
            <td className="selected-col">
              <input
                type="checkbox"
                onChange={(e) => onSelectionChange(module, e.target.checked)}
              />
            </td>
            <td className="tallies-col">{tally}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}
