import React, { Component } from "react";
import { Tooltip as ReactTooltip } from "react-tooltip";
import Modal from "react-bootstrap/Modal";
import Dropdown from "react-bootstrap/Dropdown";
import { connect } from "react-redux";
import ImportModal from "../../Modals/ImportLines/ImportLines";
import MultipleChanges from "../../Modals/MultipleChanges/MultipleChanges";
import ExpenseLine from "../../Modals/ExpenseLine/ExpenseLine";
import $ from "jquery";

import { toast } from "react-toastify";
import * as ExpenseActions from "../../../Actions/ExpenseActions/ExpenseActions";
import { downloadAttachments, fuzzyMatch, handleAPIErr } from "../../../Utils/Helpers";

class ExpenseDetails extends Component {
  constructor() {
    super();
    this.state = {
      openMultipleChangesModal: false,
      openImportLinesModal: false,
      openLineItemModal: false,
      isLoading: false,
      openExpenseLineData: "",
      flagsPrompts: [],
      sugg_left: "",
    };
  }
  componentWillReceiveProps(np) {
    const arr = [];
    for (let m of np.flagsPrompts) {
      if (m.prompt === "Test 9") {
        m.prompt = "Test9";
      }
      arr.push(m);
    }
    this.setState({ flagsPrompts: arr });
    // if (
    //   np.openExpenseDetailModal &&
    //   $("#par").offset() &&
    //   $("#par").offset().left &&
    //   $("#cd_id") &&
    //   $("#cd_id").offset()
    // ) {
    //   var dist_ho = Math.abs(
    //     $("#par").offset().left - $("#cd_id").offset().left
    //   ); // horizontal distance
    //   if (!this.state.sugg_left) {
    //     this.setState({ sugg_left: dist_ho });
    //   }
    // }
  }

  openModal = (name) => {
    this.setState({ [name]: true });
  };

  closeModal = (name) => {
    this.setState({
      [name]: false,
      openExpenseLineData: "",
    });
  };
  clearStates = () => {
    this.setState({
      openExpenseLineData: "", //contains poLine data for editing
      sugg_left: "",
    });
    this.props.closeModal("openLineItemModal");
  };

  //update Expense lines
  updateNewExpenseLinesItem = async (data) => {
    await this.props.updateNewExpenseLinesItem(data);
    // this.props.closeModal("openExpenseDetailModal");
  };
  // getNewORUpdatedPOLine = async (data) => {
  //   alert("expense")

  //   await this.props.getNewORUpdatedPOLine(data);
  // };

  // update / edit
  editExpenseLine = async (data) => {
    if ((data.type && data.type.trim()) || true) {
      this.setState({ openExpenseLineData: data }, () =>
        this.openModal("openLineItemModal")
      );
    }
  };
  handleMultipleChangesModal = () => {
    let lines = this.props.expenseLines || [];
    let check = lines.find((l) => l.checked);
    if (check) {
      this.openModal("openMultipleChangesModal");
    } else {
      toast.error("Please tick lines for Multiple changes!");
    }
  };

  exportExpense = async () => {
    let { guid } = this.props;
    // let linesToExport = invoiceLines.filter((l) => l.checked);
    if (guid) {
      this.setState({ isLoading: true });
      await this.props.exportList(guid);
      //success case of export PO Lines
      if (this.props.expenseData.exportListSuccess) {
        toast.success(this.props.expenseData.exportListSuccess);
        let attachemnt = this.props.expenseData.exportList || "";

        if (attachemnt) {
          let obj = {
            contentType: "application/vnd.ms-excel",
            attachment: attachemnt || "",
          };
          downloadAttachments(obj, "expenseLines Export");
        }
      }
      //error case of export PO Lines
      if (this.props.expenseData.exportListError) {
        handleAPIErr(this.props.expenseData.exportListError, this.props);
      }
      this.props.clearExpenseStates();
      this.setState({ isLoading: false });
      this.props.closeModal("openExportSuccessModal");
    } else {
      toast.error("Please Select Lines to Export!");
    }
  };
  updateExpenseLines = () => {
    if (this.props.guid) {
      let data = {
        guid: this.props.guid,
        expenseItems: this.props.expenseLines,
      };
      this.props.updateExpenseLines(data);
    }
  };

  /**
   * This function returns the value of the flag based on the current flag header
   * There were a couple of issues with the flags, the `type` `prompt` value changes
   * a lot of times and this is being used in other places too. so this function can handle
   * those cases well. Sometimes we have to match Flag.type with FlagHeader.prompt
   * @param {Array.<Flag>} flags The list of flags of the current expense row
   * @param {FlagHeader} currentFlagHeader The current flag header, for which the value needs to be fetched from flags
   * @returns {string}
   * 
   * @typedef {Object} Flag
   * @property {string} type The type of the flag
   * @property {string} prompt The prompt of the flag
   * @property {string} value The value of the flag
   * @property {number} sequence The order/sequence of the flag
   * 
   * @typedef {FlagHeader}
   * @property {string} type The type of the flag
   * @property {string} prompt The prompt of the flag
   * @property {number} sequence The order/sequence of the flag
   * @property {string} defaultValue The default value of the flag
   * @property {number} length The length of the flag?
   */
  getFlagValue(flags, currentFlagHeader) {
    const flag = flags.find((f) => {
      const currentFlagType = f?.type?.toLowerCase() || "";
      const currentFlagSequence = parseInt(f.sequence, 10) || -1;
      const currentFlagPrompt = f.prompt?.toLowerCase() || "";
      
      const flagHeaderPrompt = currentFlagHeader?.prompt?.toLowerCase() || "";
      const flagHeaderType = currentFlagHeader?.type?.toLowerCase() || "";
      const flagHeaderSequence = parseInt(currentFlagHeader?.sequence, 10) || -1;

      const isPromptMatch = currentFlagPrompt === flagHeaderPrompt;
      const isTypeMatch = currentFlagType === flagHeaderType;
      const isSequenceMatch = currentFlagSequence === flagHeaderSequence;
      const isTypePromptMatch = currentFlagType === flagHeaderPrompt;
      if (currentFlagSequence === 10 && flagHeaderSequence === 10) {
        const isFuzzyMatch = fuzzyMatch(currentFlagPrompt, flagHeaderPrompt);
        return isSequenceMatch && (isPromptMatch || isTypeMatch || isTypePromptMatch || isFuzzyMatch) ;
      }

      return isSequenceMatch && (isPromptMatch || isTypeMatch || isTypePromptMatch);
    });
    return flag?.value || "";
  }

  render() {
    let userType = localStorage.getItem("userType");
    let tab = this.props.tab || "";
    let checkOne = false;
    const canDisplayImportExport = userType?.toLowerCase() !== "approver";

    if (userType && tab) {
      if (userType.toLowerCase() === "approver") {
        /* An Approver can only edit the chart code, tracking codes and item description.
       Everything else in the PO is read-only and cannot be altered.*/
        if (tab != "draft") {
          checkOne = true;
        }
      } else if (userType.toLowerCase() === "op/approver") {
        if (tab != "draft") {
          checkOne = true;
        }
      }
    }
    let flagsPrompts = this.state.flagsPrompts || [];
    let flgLength = flagsPrompts.length;
    let dynamicClass =
      "forgot_email_modal mx-auto order-detail-popup order-detail-popup";
    //increase the order detail popup width as flags increases
    dynamicClass =
      flgLength === 3
        ? `${dynamicClass}3`
        : flgLength === 4
        ? `${dynamicClass}4`
        : flgLength === 5
        ? `${dynamicClass}5`
        : flgLength === 6
        ? `${dynamicClass}6`
        : flgLength === 7
        ? `${dynamicClass}7`
        : flgLength === 8
        ? `${dynamicClass}8`
        : flgLength === 9
        ? `${dynamicClass}9`
        : flgLength === 10
        ? `${dynamicClass}10`
        : "forgot_email_modal mx-auto order-detail-popup";

    return (
      <>
        {this.state.isLoading ? <div className="se-pre-con"></div> : ""}
        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={this.props.openExpenseDetailModal}
          onHide={() => this.props.closeModal("openExpenseDetailModal")}
          // className="forgot_email_modal modal_90_per mx-auto"
          className={dynamicClass}
        >
          <Modal.Body>
            <div className="container-fluid ">
              <div className="main_wrapper p-10">
                <div className="row d-flex h-100">
                  <div className="col-12 justify-content-center align-self-center form_mx_width">
                    <div className="forgot_form_main">
                      <div className="forgot_header">
                        <div className="modal-top-header">
                          <div className="row bord-btm">
                            <div className="col-auto pl-0">
                              <h6 className="text-left def-blue">
                                Expense Detail
                              </h6>
                            </div>
                            <div className="col d-flex justify-content-end s-c-main">
                              <button
                                onClick={() => this.updateExpenseLines()}
                                type="button"
                                className="btn-save"
                              >
                                <span className="fa fa-check"></span>
                                Save
                              </button>
                              <button
                                onClick={() =>
                                  this.props.closeModal(
                                    "openExpenseDetailModal"
                                  )
                                }
                                type="button"
                                className="btn-save"
                              >
                                <span className="fa fa-ban"></span>
                                Cancel
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="forgot_body">
                        <div className="row">
                          <div className="col-12">
                            <div className="model-p move-modal-t">
                              <div className="row">
                                <div className="col-12">
                                  <Dropdown
                                    alignRight="false"
                                    drop="down"
                                    className="analysis-card-dropdwn float-right bg-tp"
                                  >
                                    <Dropdown.Toggle
                                      variant="sucess"
                                      id="dropdown-basic"
                                    >
                                      <img
                                        src="images/order-option.png"
                                        className=" img-fluid"
                                        alt="user"
                                      />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                      <Dropdown.Item
                                        onClick={
                                          this.handleMultipleChangesModal
                                        }
                                      >
                                        Multiple Changes
                                      </Dropdown.Item>
                                      {canDisplayImportExport && <>
                                        <Dropdown.Item
                                          onClick={() =>
                                            this.openModal("openImportLinesModal")
                                          }
                                        >
                                          Import
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          onClick={() => this.exportExpense()}
                                        >
                                          Export
                                        </Dropdown.Item> </>}
                                    </Dropdown.Menu>
                                  </Dropdown>

                                  {/* <div className="col-12" id='par'> */}
                                  <div className="login_form  ">
                                    <div
                                      className="login_table_list order-detail-tabl-main "
                                      // id="horizontal-scroll"
                                    >
                                      <table className="table shadow-none order-table tab-1-line order-detail-popup-table  od_popup_new  order--PDF ">
                                        <thead>
                                          <tr>
                                            <th scope="col"></th>
                                            <th
                                              scope="col"
                                              className=" order-detail-th2-td2"
                                            >
                                              <div className="col align-self-center text-center pr-0 pl-0">
                                                <div className="form-group remember_check mm_check4 pt-0">
                                                  <input
                                                    type="checkbox"
                                                    id="expDetail1"
                                                    onChange={(e) =>
                                                      this.props.handleCheckboxesInExpenseDetails(
                                                        e,
                                                        "all"
                                                      )
                                                    }
                                                  />
                                                  <label
                                                    htmlFor="expDetail1"
                                                    className="mr-0"
                                                  ></label>
                                                </div>
                                              </div>
                                            </th>
                                            <th
                                              scope="col"
                                              className="text-left invoice-detail-desth"
                                            >
                                              Description
                                            </th>
                                            <th
                                              scope="col"
                                              className="text-left pl-3"
                                            >
                                              Chart Sort
                                            </th>
                                            <th
                                              scope="col"
                                              className="text-left pl-3"
                                            >
                                              Chart Code
                                            </th>
                                            {this.props.flagsPrompts.map(
                                              (p, i) => {
                                                return (
                                                  <th
                                                    key={i}
                                                    scope="col"
                                                    className={
                                                      p.type === "Set" ||
                                                      p.type === "set"
                                                        ? "od-flag-last text-left pl-3"
                                                        : "text-left invo-d-flag-pad pl-3"
                                                    }
                                                  >
                                                    {p.prompt}
                                                  </th>
                                                );
                                              }
                                            )}
                                            <>
                                              {/* <th scope="col"></th> */}
                                              {/* <th scope="col"></th>
                                              <th scope="col"></th> 
                                            <th scope="col"></th> */}
                                            </>
                                            <th
                                              className="order-detail-th10-td10 text-right pl-0  od-amount-pad-right"
                                              scope="col"
                                            >
                                              Amount
                                            </th>
                                            <th scope="col"></th>
                                            <th scope="col"></th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {this.props.expenseLines.map(
                                            (d, i) => {
                                              return (
                                                <tr key={i}>
                                                  <td scope="row">
                                                    <Dropdown
                                                      alignRight="false"
                                                      drop="right"
                                                      className="bg-trans"
                                                    >
                                                      <Dropdown.Toggle
                                                        variant="sucess"
                                                        id="dropdown-basic"
                                                      >
                                                        <span className="clr-drop yellow-c"></span>
                                                      </Dropdown.Toggle>
                                                      <Dropdown.Menu>
                                                        <Dropdown.Item>
                                                          These markers indicate{" "}
                                                        </Dropdown.Item>
                                                        <Dropdown.Item>
                                                          budget status.
                                                        </Dropdown.Item>
                                                        <Dropdown.Item>
                                                          {" "}
                                                          <span className="clr-drop green-c mt-2 mr-2"></span>{" "}
                                                          In budget
                                                        </Dropdown.Item>
                                                        <Dropdown.Item>
                                                          {" "}
                                                          <span className="clr-drop yellow-c mt-2 mr-2"></span>{" "}
                                                          Approaching budget
                                                        </Dropdown.Item>
                                                        <Dropdown.Item>
                                                          {" "}
                                                          <span className="clr-drop red-c mt-2 mr-2"></span>{" "}
                                                          Over budget
                                                        </Dropdown.Item>
                                                      </Dropdown.Menu>
                                                    </Dropdown>
                                                  </td>

                                                  <td className=" order-detail-th2-td2">
                                                    <div className="col align-self-center text-center pr-0 pl-0">
                                                      <div className="form-group remember_check mm_check4 pt-0">
                                                        <input
                                                          type="checkbox"
                                                          id={
                                                            "expDetails12" + i
                                                          }
                                                          onChange={(e) =>
                                                            this.props.handleCheckboxesInExpenseDetails(
                                                              e,
                                                              d
                                                            )
                                                          }
                                                          checked={d.checked}
                                                        />
                                                        <label
                                                          htmlFor={
                                                            "expDetails12" + i
                                                          }
                                                          className="mr-0"
                                                        ></label>
                                                      </div>
                                                    </div>
                                                  </td>
                                                  <td className="text-left pl-0 uppercaseText  od-desc-input">
                                                    <div className="modal_input">
                                                      <input
                                                        type="text"
                                                        className="form-control "
                                                        id="usr"
                                                        autoComplete="off"
                                                        name={"description"}
                                                        value={d.description}
                                                        onChange={(e) =>
                                                          this.props.handleChangeInLineFields(
                                                            e,
                                                            d,
                                                            i
                                                          )
                                                        }
                                                      />
                                                    </div>
                                                  </td>
                                                  <td className="text-left">
                                                    <div className="modal_input  invo-chart-sort width-56">
                                                      <input
                                                        type="text"
                                                        className={
                                                          d.chartSort.length <=
                                                          5
                                                            ? " form-control wd-50 uppercaseText"
                                                            : "form-control wd-75 uppercaseText"
                                                        }
                                                        data-tooltip-id={
                                                          "toolTip" + i
                                                        }
                                                        data-tooltip-variant="info"
                                                        id="usr"
                                                        autoComplete="off"
                                                        name={"chartSort"}
                                                        value={d.chartSort}
                                                        onChange={(e) =>
                                                          this.props.handleChangeInLineFields(
                                                            e,
                                                            d,
                                                            i
                                                          )
                                                        }
                                                      />
                                                    </div>
                                                  </td>
                                                   <td
                                                    className="text-left"
                                                    style={{position: 'relative'}}
                                                  >
                                                      <div className="modal_input  invo-chart-sort width-90">
                                                        <input
                                                          type="text"
                                                          className={
                                                            d?.chartCode
                                                              ?.length <= 4
                                                              ? "form-control focus_chartCode wd-45 uppercaseText"
                                                              : d.chartCode
                                                                .length <= 8
                                                                ? "form-control focus_chartCode wd-72 uppercaseText"
                                                                : "form-control focus_chartCode wd-101 uppercaseText"
                                                          }
                                                          id="usr"
                                                          autoComplete="off"
                                                          name={"chartCode"}
                                                          value={d.chartCode}
                                                          data-tooltip-id={
                                                            "toolTip" + i
                                                          }
                                                          data-tooltip-variant="info"
                                                          onChange={(e) =>
                                                          {
                                                            this.props.handleChangeInLineFields(
                                                              e,
                                                              d,
                                                              i
                                                            );
                                                            this.props.hanldeExpenseLineFields(
                                                              e,
                                                              d,
                                                              i
                                                            );
                                                          }
                                                          }
                                                        />
                                                      </div>
                                                      <ReactTooltip
                                                        id={"toolTip" + i}
                                                        place="bottom"
                                                        type="info"
                                                        effect="solid"
                                                      >
                                                        <span>
                                                          {d.chartDescription}
                                                        </span>
                                                      </ReactTooltip>
                                                      {this.props
                                                        .chartCodeSuggestion ==
                                                        i && (
                                                        <div
                                                          className={`chart_menue chart${i}`}
                                                          style={{
                                                            marginLeft:
                                                              this.props
                                                                .sugg_left,
                                                          }}
                                                        >
                                                          {this.props
                                                            .clonedChartCodesList
                                                            .length > 0 ? (
                                                            <ul className="invoice_vender_menu">
                                                              {this.props.clonedChartCodesList.map(
                                                                (c, j) => {
                                                                  return (
                                                                    <li
                                                                      className="cursorPointer"
                                                                      key={j}
                                                                      onClick={() =>
                                                                        this.props.changeChartCode(
                                                                          c,
                                                                          d,
                                                                          i
                                                                        )
                                                                      }
                                                                    >
                                                                      <div className="vender_menu_right chart_new">
                                                                        <h3 className="chart_vender_text">
                                                                          <span>
                                                                            {" "}
                                                                            {
                                                                              c.code
                                                                            }{" "}
                                                                          </span>{" "}
                                                                          <span className="right_desc">
                                                                            {" "}
                                                                            {
                                                                              c.description
                                                                            }
                                                                          </span>
                                                                        </h3>
                                                                      </div>
                                                                    </li>
                                                                  );
                                                                }
                                                              )}
                                                            </ul>
                                                          ) : (
                                                            <div className="sup_nt_fnd text-center">
                                                              <h6>
                                                                No Chart Code
                                                                Found
                                                              </h6>
                                                            </div>
                                                          )}
                                                        </div>
                                                      )}
                                                  </td> 

                                                  {this.props.flagsPrompts.map(
                                                    (p, j) => {
                                                      return (
                                                        <td
                                                          className={
                                                            p.type === "Set" ||
                                                            p.type === "set"
                                                              ? "od-flag-last text-left pad-left"
                                                              : "text-left pad-left"
                                                          }
                                                          key={j}
                                                        >
                                                          <div className="modal_input">
                                                            <input
                                                              type="text"
                                                              className={`form-control uppercaseText flags-w${p.length}`}
                                                              id="usr"
                                                              autoComplete="off"
                                                              name={p.type}
                                                              maxLength={
                                                                p.length
                                                              }
                                                              value={
                                                                this.getFlagValue(d.flags, p)
                                                              }
                                                              onChange={(e) =>
                                                                this.props.handleChangeFlags(
                                                                  e,
                                                                  d,
                                                                  i,
                                                                  p.sequence
                                                                )
                                                              }
                                                            />
                                                          </div>
                                                        </td>
                                                      );
                                                    }
                                                  )}
                                                  <>
                                                    {/* <td></td>
                                                    <td></td>
                                                    <td></td> 
                                                  <td></td> */}
                                                  </>
                                                  <td
                                                    className={
                                                      (d.type === "Insurance" ||
                                                        d.type ===
                                                          "Distribution") &&
                                                      !checkOne
                                                        ? "text-left order-detail-th10-td10 uppercaseText"
                                                        : " text-right od-amount-td uppercaseText"
                                                    }
                                                  >
                                                    {(d.type === "Insurance" ||
                                                      d.type ===
                                                        "Distribution") &&
                                                    !checkOne ? (
                                                      <div className="modal_input order_num_input">
                                                        <input
                                                          type="number"
                                                          className="form-control uppercaseText text-right float-right"
                                                          id="amount"
                                                          autoComplete="off"
                                                          name={"amount"}
                                                          value={d.amount}
                                                          onChange={(e) =>
                                                            this.props.handleChangeInLineFields(
                                                              e,
                                                              d,
                                                              i
                                                            )
                                                          }
                                                          onBlur={(e) =>
                                                            this.props.convertTwoDecimal(
                                                              e,
                                                              d
                                                            )
                                                          }
                                                        />
                                                      </div>
                                                    ) : (
                                                      <div className="modal_input order_num_input">
                                                        <input
                                                          disabled={true}
                                                          type="number"
                                                          className="form-control uppercaseText modal_input_readonly text-right float-right"
                                                          id="amount"
                                                          autoComplete="off"
                                                          name={"amount"}
                                                          value={
                                                            d.amount
                                                              ? d.amount
                                                              : "0.00"
                                                          }
                                                          onChange={(e) =>
                                                            this.props.handleChangeInLineFields(
                                                              e,
                                                              d,
                                                              i
                                                            )
                                                          }
                                                          onBlur={(e) =>
                                                            this.props.convertTwoDecimal(
                                                              e,
                                                              d
                                                            )
                                                          }
                                                        />
                                                      </div>
                                                    )}
                                                  </td>
                                                  <td>
                                                    {/* EDITT  */}
                                                    <img
                                                      onClick={() =>
                                                        this.editExpenseLine(d)
                                                      }
                                                      src="images/pencill.png"
                                                      className="import_icon cursorPointer"
                                                      alt="pencill"
                                                    />
                                                  </td>
                                                </tr>
                                              );
                                            }
                                          )}

                                          {/* subTotal  */}
                                          <tr>
                                            <th scope="row"></th>
                                            <th className="text-left"></th>

                                            <td></td>
                                            <td></td>

                                            {this.props.flagsPrompts.length >
                                            0 ? (
                                              this.props.flagsPrompts.map(
                                                (p, i) => {
                                                  return <td key={i}></td>;
                                                }
                                              )
                                            ) : (
                                              <>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                {/* <td></td>
                                                <td></td>
                                                <td></td> */}
                                              </>
                                            )}
                                            <td className="tbl_total_amount">
                                              Subtotal:&nbsp;&nbsp;
                                            </td>
                                            <td className="tbl_total_amount text-right  od-pr-subtotal">
                                              {this.props.amount}
                                            </td>
                                            <td></td>
                                            <td></td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                  {/* </div> */}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-12"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <ExpenseLine
          openLineItemModal={this.state.openLineItemModal}
          closeModal={this.closeModal}
          taxCodes={this.props.taxCodes || ""} //api response (get tax codes)
          chartSorts={this.props.chartSorts || ""} //api response (get chart sort)
          chartCodes={this.props.chartCodesList || []} //all chart codes
          flags_api={this.props.flags_api} //flags comming from get flags api
          flags={this.props.flags} //restructured flags accordings to requirements
          clonedFlags={this.props.clonedFlags} //a copy of flags
          updateFlags={this.updateFlags} //get updated flags from liine item modal
          updateNewExpenseLinesItem={this.updateNewExpenseLinesItem} //add/edit Exp line
          openExpenseLineData={this.state.openExpenseLineData} //Line for Editing
          accountDetails={this.props.accountDetails}
          tab={this.props.tab}
          basisOptions={this.props.basisOptions || []}
          props={this.props.props || ""}
          getChartCodes={this.props.getChartCodes}
          getChartSorts={this.props.getChartSorts}
          currency={this.props.currency}
          suppliersFlags={this.props.suppliersFlags}
          chartCodesList={this.props.chartCodesList || []}
          description={this.props.description || ""}
        />

        <ImportModal
          import="importexpense"
          openImportLinesModal={this.state.openImportLinesModal}
          closeModal={this.closeModal}
        />
        <MultipleChanges
          openMultipleChangesModal={this.state.openMultipleChangesModal}
          closeModal={this.closeModal}
          flags_api={this.props.flags_api} //flags comming from get flags api
          flags={this.props.flags} //restructured flags accordings to requirements
          clonedFlags={this.props.clonedFlags} //a copy of flags
          chartSorts={this.props.chartSorts || ""} //api response (get chart sort)
          chartCodes={this.props.chartCodesList || []} //all chart codes
          handleMultipleChanges={this.props.handleMultipleChanges}
          lines={this.props.expenseLines}
          props={this.props.props || ""}
          getChartCodes={this.props.getChartCodes}
          getChartSorts={this.props.getChartSorts}
        />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  expenseData: state.expense,
});

export default connect(mapStateToProps, {
  clearExpenseStates: ExpenseActions.clearExpenseStates,
  exportList: ExpenseActions.exportList,
})(ExpenseDetails);
