import React, { Component, Fragment, createContext } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import { Document, Page, pdfjs } from "react-pdf";
import { connect } from "react-redux";
import $ from "jquery";
import Header from "../Common/Header/Header";
import TopNav from "../Common/TopNav/TopNav";
import Activity from "../Modals/Activity/Activity";
import Changes from "../Modals/Changes/Changes";
import Attachments from "../Modals/Attachments/Attachments";
import Comments from "../Modals/Comments/Comments";
import { toast } from "react-toastify";
import CheckApproval from "../Modals/CheckApproval/CheckApproval";

// import DistChangesMoreDetailsModal from "../Modals/DistChangesMoreDetailsModal/DistChangesMoreDetailsModal";
import DistChangesMoreDetailsModal from "../Modals/DistChangesMoreDetails/DistChangesMoreDetails";
import Dropdown from "react-bootstrap/Dropdown";
import Post from "../Modals/Post/Post";
import Import from "../Modals/Import/Import";
import Report from "../Modals/Report/Report";
import { options } from "../../Constants/Constants";
import * as DistchangeActions from "../../Actions/DistChangesActions/DistChangesActions";
import * as Helpers from "../../Utils/Helpers";
import Delete from "../Modals/Delete/Delete";
import MoveToDraft from "../Modals/MoveToDraft/MoveToDraft";
import Decline from "../Modals/Decline/Decline";
import {
  downloadFileFromPresignedUrl,
  getBase64FromPreSignedS3Url,
  isRemoteStorage,
  uploadFileListToS3
} from "../../Utils/S3StorageHelper";
import { downloadAttachments } from "../../Utils/Helpers";
// import { Helpers.handleAPIErr, downloadAttachments } from "../../Utils/Helpers";

const uuidv1 = require("uuid/v1");

class DistChanges extends Component {
  constructor() {
    super();
    this.state = {
      multipleTrans: [],
      dstComments: [],
      id: "",
      isLoading: false,
      distchangeSummary: "",
      approvalDate: "",
      activeDistchange: "",
      approverGroup: "",
      approvers: [],
      dstAttachments: [],
      attachmentSize: 0,
      activity: [],
      locked: "",
      comments: [],
      previewList: [],
      openActivityModal: false,

      getDistchangeList: [],
      clonedGetDistchangeList: [],
      filteredDistchangeList: [],
      distChangeTallies: [],
      activeDistChangeTallis: "",
      toggleRightSidebar: true,
      activePOTallis: 1,
      showDistChangeTabPane: "draft",
      sortFilter: "name",
      sortFilterCheck: "DESC",
      distChangesListSearch: "",
      numPagesArr: [], //it contains number of pages of each PDF
      rotate: 0,
      scaling: 3.4,
      dropdownZoomingValue: { label: "40%", value: "40%" },
      pageNumber: 1,
      openDeleteModal: false,
      tran: "",
      openPostModal: false,
      distChangesMoreDetailsOpenModal: false,
      openCheckApprovalModal: false,
      approvalGroup: {
        label: "Select Approval Group",
        value: "",
      },
      approvalOptions: [],
      description: "",
      userName: "",
      checked: false,
      allSelected: false,
      selectedGuid:null
    };
  }

  async componentDidMount() {
    //right hand side bar setting with PO Image
    window.addEventListener(
      "resize",
      () => {
        $(".mm_ordr1").addClass("mm_order_pdfMain");

        if ($(window).width() > 991) {
          this.setState({ toggleRightSidebar: true });
        }
        if ($(window).width() <= 991) {
          this.setState({ toggleRightSidebar: false });
        }
      },
      false
    );
    // end

    //focus search input field by pressing Tab key
    document.onkeydown = function (evt) {
      evt = evt || window.event;
      if (evt.keyCode == 9) {
        evt.preventDefault();
        let id = document.getElementById("POListSearchId");
        if (id) {
          document.getElementById("POListSearchId").focus();
        }
      }
    };

    $(document).ready(function () {
      var vw = $(window).width();
      var nav = $(".navbar.fixed-top").height();
      var underNav = $(".order-tabs").height();
      var wh = $(window).height();
      var h = wh - nav - 60;
      var rsb = wh - nav - underNav - 20;
      var pdfDiv = wh - nav - underNav - 80;
      var pdfWid = vw - 740;
      $("#overload_image").css("width", pdfWid);
      $("#order--dynamic--height").css("height", h);
      $(".side-attachments,.side-attachments-2").css("height", rsb);
      $("#maped_image").css("height", pdfDiv);
      $(window).on("load", function () {
        var vw = $(window).width();
        var nav = $(".navbar.fixed-top").height();
        var underNav = $(".order-tabs").height();
        var wh = $(window).height();
        var h = wh - nav - 60;
        var rsb = wh - nav - underNav - 20;
        var pdfDiv = wh - nav - underNav - 80;
        var pdfWid = vw - 740;
        $("#overload_image").css("width", pdfWid);
        $("#order--dynamic--height").css("height", h);
        $(".side-attachments,.side-attachments-2").css("height", rsb);
        $("#maped_image").css("height", pdfDiv);
      });
      $(window).resize(function () {
        var vw = $(window).width();
        var nav = $(".navbar.fixed-top").height();
        var underNav = $(".order-tabs").height();
        var wh = $(window).height();
        var h = wh - nav - 60;
        var rsb = wh - nav - underNav - 20;
        var pdfDiv = wh - nav - underNav - 80;
        var pdfWid = vw - 740;
        $("#overload_image").css("width", pdfWid);
        $("#order--dynamic--height").css("height", h);
        $(".side-attachments,.side-attachments-2").css("height", rsb);
        $("#maped_image").css("height", pdfDiv);
      });
    });
    $("#expand").on("click", function (e) {
      e.preventDefault();
      $(".maped_image").addClass("mm_pdf_img");
    });

    $(".cus-arro-div2").on("click", function (e) {
      e.preventDefault();
      $(".order_pdf_new").toggleClass("order_left_auto");
    });
    $("#full_screen").on("click", function (e) {
      e.preventDefault();
      $(".explore_img").addClass("fit_top_bottom");
    });
    // end
    // sideBarAccord
    $(".invoice-inherit").click(function () {
      $(".invoice-inherit .sideBarAccord1 ").toggleClass("rotate_0");
    });
    $(".sideBarAccord").click(function () {
      $(this).toggleClass("rorate_0");
    });
    $(".export_crd").click(function () {
      $(".export_crd .sideBarAccord1").toggleClass("rotate_0");
    });
    $(".invoice-inherit2").click(function () {
      $(".sideBarAccord2 ").toggleClass("rotate_0");
    });

    let {
      tallies,
      dashboard,
      tallType,
      addEditSupplierCheck,
      addEditSupplierTran,
    } =
      (this.props.history &&
        this.props.history.location &&
        this.props.history.location.state) ||
      "";
    if (dashboard && tallType) {
      //when user click on supplier Tallies on Dashboard
      await this.getDistchangeTallies(tallType, true);
    } else {
      if (
        // tallies &&
        // (tallies === "Draft" || tallies === "Approve") &&
        addEditSupplierCheck &&
        addEditSupplierTran
      ) {
        /*Check When Edit supplier and then user Save or Cancel that edit, 
then load the same supplier user just edited?.*/
        await this.getDistchangeTallies(tallies, true);
      } else {
        await this.getDistchangeTallies();
      }
    }
    // await this.getDistchangeList();
  }

  getDistchangeTallies = async (type, check) => {
    this.setState({ isLoading: true, checked: false });

    await this.props.getDistchangeTallies();

    // let { distChangeTallies } = this.state;
    let distChangeTally = "";

    let { activeDistChangeTallis, showDistChangeTabPane } = this.state;
    let distChangeTalliesArr = [];
    //success case of Timecard tallies
    if (this.props.distchange.getDistchangeTalliesSuccess || []) {
      let distChangeTallies = this.props.distchange.distChangeTallies || [];
      let opTypes = [];

      let userType = localStorage.getItem("userType");
      userType = userType ? userType.toLowerCase() : "";

      if (userType == "operator") {
        opTypes = ["draft", "pending", "decline", "approved", "all"];
      } else if (userType == "approver") {
        opTypes = ["approve", "hold", "pending", "decline", "approved", "all"];
      } else if (userType == "op/approver") {
        opTypes = [
          "draft",
          "approve",
          "hold",
          "pending",
          "decline",
          "approved",
          "all",
        ];
      }

      if (opTypes.length > 0) {
        opTypes.map((t, i) => {
          let obj = distChangeTallies.find(
            (tl) => tl.type && tl.type.toLowerCase() === t
          );
          if (obj) {
            distChangeTalliesArr.push(obj);
          }
        });
      } else {
        distChangeTalliesArr = distChangeTallies;
      }

      let _type = "";

      if (type) {
        _type = type;
      } else if (distChangeTalliesArr.length > 0) {
        _type = distChangeTalliesArr[0].type;
      }

      distChangeTalliesArr.map(async (s, i) => {
        if (s.type === _type) {
          let id = uuidv1();
          s.id = id;
          distChangeTally = s;
          activeDistChangeTallis = id;
          showDistChangeTabPane = s.type;
        } else {
          s.id = uuidv1();
        }
        return s;
      });
    }
    //error case of Timecard tallies
    if (this.props.distchange.getDistchangeTalliesError) {
      Helpers.handleAPIErr(
        this.props.distchange.getDistchangeTalliesError,
        this.props
      );
    }
    this.setState({
      isLoading: false,
      distChangeTallies: distChangeTalliesArr,
      activeDistChangeTallis,
      showDistChangeTabPane,
    });
    if (distChangeTally) {
      await this.getDistchangeList(distChangeTally);
    }
    this.props.clearDistChangesStates();
  };

  getDistchangeList = async (distChangeTally) => {
    this.setState({ isLoading: true, activeDistchange: distChangeTally.id });
    await this.props.getDistchangeList(distChangeTally);
    let {
      getDistchangeList,
      activeDistchange,
      clonedGetDistchangeList,
      filteredDistchangeList,
      id,
    } = this.state;

    let firstDst = "";
    if (this.props.distchange.getDistchangeListSuccess) {
      // console.log("this>>>", this.props.distChange)
      getDistchangeList = this.props.distchange.getDistchangeList || [];

      getDistchangeList.map((dst, i) => {
        if (i === 0) {
          // let id = uuidv1();
          // id = dst.id;
          firstDst = dst;
          dst.checked = false;
          activeDistchange = dst.id;
        } else {
          // dst.id = uuidv1();
          dst.checked = false;
          // id = dst.id;
        }
        return dst;
      });

      // getDistchangeList = getDistchangeList;
      clonedGetDistchangeList = getDistchangeList;
      filteredDistchangeList = getDistchangeList;

      let { id } =
        (this.props.history &&
          this.props.history.location &&
          this.props.history.location.state) ||
        "";

      if (id) {
        let checkDst = getDistchangeList.find((l) => l.id === id);
        if (checkDst) {
          firstDst = checkDst;
          activeDistchange = checkDst;
        }
        this.setState({ activeDistchange });
      }
    }

    if (this.props.distchange.getDistchangeListError) {
      Helpers.handleAPIErr(
        this.props.distchange.getDistchangeListError,
        this.props
      );
    }

    this.setState({
      getDistchangeList,
      isLoading: false,
      activeDistchange,
      clonedGetDistchangeList,
      filteredDistchangeList,
      id,
    });
    if (firstDst) {
      this.getDistchangeSummary(firstDst, true);
    }
    var elmnt = document.getElementById(this.state.activeDistchange);
    if (elmnt) {
      elmnt.scrollIntoView();
    }
  };

  getDistchangeSummary = async (dst, check) => {
    if (this.state.activeDistchange !== dst.id || check) {
      this.setState({
        isLoading: true,
        activeDistchange: dst.id,
        id: dst.guid,
      });

      await this.props.getDistchangeSummary(dst.guid);

      let { distchangeSummary } = this.state;

      if (this.props.distchange.getDistchangeSummarySuccess) {
        // distchangeSummary = this.props.distchange.getDistchangeSummary;

        let distchangeSummary =
          (this.props.distchange.getDistchangeSummary &&
            this.props.distchange.getDistchangeSummary &&
            JSON.parse(
              JSON.stringify(this.props.distchange.getDistchangeSummary)
            )) ||
          "";

        // let approverGroup = distchangeSummary.approvalGroup || "";
        let tran = distchangeSummary[0].tranNo || "";
        let userName = distchangeSummary[0].userName || "";
        let description = distchangeSummary[0].description || "";
        let approvalDate = distchangeSummary[0].approvalDate || "";

        let approvalGroup = distchangeSummary[0].approvalGroup || "";

        let approvers = distchangeSummary[0].approvers || [];
        let approvalOptions = distchangeSummary[0].approvalOptions || [];

        let dstAttachments =
          (distchangeSummary && distchangeSummary[0].attachments) || [];
        let attachmentSize = 0;
        dstAttachments.map((a, i) => {
          attachmentSize += Number(a.fileSize) || 0;
        });

        let locked = (distchangeSummary && distchangeSummary[0].locked) || [];

        let activity =
          (distchangeSummary && distchangeSummary[0].activity) || [];
        // let date = "";
        // activity.map((item, i) => {
        //   item.date = item.dateTime;
        //   delete item.dateTime;
        // });

        let i;
        for (i = 0; i < activity.length; i++) {
          activity[i].date = activity[i].dateTime;
          delete activity[i].dateTime;
        }

        let dstComments = distchangeSummary[0].comments || [];

        let previewList =
          (distchangeSummary && distchangeSummary[0].previewList) || [];

        if (isRemoteStorage()) {
          previewList = await Promise.all(previewList.map(async (p) => {
            p.file = await getBase64FromPreSignedS3Url(p.documentPresignedURL);
            return p;
          }))}
        //to show primary PDF first
        previewList = previewList.sort((a, b) =>
          a.primaryDoc.toLowerCase() < b.primaryDoc.toLowerCase() ? 1 : -1
        );

        // let attachments = distchangeSummary.attachments || []
        let selectedGuid = distchangeSummary[0].guid;

        this.setState({
          approvalDate,
          approvalGroup: {
            label: approvalGroup ? approvalGroup : "Select Approval Group",
            value: approvalGroup ? approvalGroup : "",
          },
          approvers,
          dstAttachments,
          attachmentSize,
          activity,
          dstComments,
          locked,
          previewList,
          approvalOptions,
          tran,
          description,
          userName,
          selectedGuid
        });
      }

      if (this.props.distchange.distchangeSummaryError) {
        Helpers.handleAPIErr(
          this.props.distchange.getDistchangeSummaryError,
          this.props
        );
      }

      this.setState({
        isLoading: false,
        distchangeSummary,
      });
    }
  };

  handleMoreDetails = () => {
    this.openModal("distChangesMoreDetailsOpenModal");
  };
  addComment = async (comment, params) => {
    if (this.state.id) {
      if (comment) {
        this.setState({ isLoading: true });
        let data = {
          comment,
          ...params,
          guid: this.state.id,
        };
        await this.props.addComment(data);
        if (this.props.distchange.addCommentSuccess) {
          // toast.success(this.props.poData.addCommentSuccess);
          let dstComments = this.props.distchange.addCommentData || [];
          this.setState({ dstComments });
        }
        if (this.props.distchange.addCommentError) {
          Helpers.handleAPIErr(
            this.props.distchange.addCommentError,
            this.props
          );
        }
        // this.props.clearPOStates();
        this.setState({ isLoading: false });
      } else {
        toast.error("Please Enter Comment!");
      }
    } else {
      toast.error("Please select PO First!");
    }
  };

  exportDistchange = async () => {
    let { id, multipleTrans } = this.state;

    if (multipleTrans.length > 0) {
      this.setState({ isLoading: true });
      await this.props.exportDistchange(id);
      this.setState({ isLoading: false });

      if (this.props.distchange.exportDistchangeSuccess) {
        toast.success(this.props.distchange.exportDistchangeSuccess);

        let obj = {
          contentType: "application/vnd.ms-excel",
          attachment: this.props.distchange.exportDistchange || "",
        };
        Helpers.downloadAttachments(obj, "orders");
      }
      if (this.props.distchange.exportDistchangeError) {
        Helpers.handleAPIErr(
          this.props.distchange.exportDistchangeError,
          this.props
        );
      }
    } else {
      toast.error("Please Select Distchange First!");
    }
  };

  addAttachment = async (fileList) => {
    const {selectedGuid} = this.state;
    if (this.state.multipleTrans) {
      this.setState({ isLoading: true });
      let data = {
        guid: selectedGuid,
        fileName: fileList[0]?.fileName,
        attachment: fileList[0]?.attachment,
        documentGUID:'',
      };
      if (isRemoteStorage()) {
        await uploadFileListToS3(fileList, async (uploadedFiles) => {
          let attachmentData = {
            guid: selectedGuid,
            fileName: uploadedFiles[0]?.fileName,
            documentGUID: uploadedFiles[0]?.documentGuid,
            primaryDoc: "Y"
          }
          await this.props.addDistchangeAttachment(attachmentData);
          if (this.props.distchange.addDistchangeAttachmentSuccess) {
            // toast.success(this.props.distchange.addInvoiceAttachmentSuccess);
            let dstAttachments =
              this.props.distchange.addDistchangeAttachment || [];
            let attachmentSize = 0;
            dstAttachments.map((a, i) => {
              attachmentSize += Number(a.fileSize) || 0;
            });
            this.setState({ dstAttachments, attachmentSize });
          }
          if (this.props.distchange.addDistchangeAttachmentError) {
            Helpers.handleAPIErr(
              this.props.distchange.addDistchangeAttachmentError,
              this.props
            );
          }
        })
        this.setState({ isLoading: false });
      }else{
        await this.props.addDistchangeAttachment(data);
        if (this.props.distchange.addDistchangeAttachmentSuccess) {
          // toast.success(this.props.distchange.addInvoiceAttachmentSuccess);
          let dstAttachments =
            this.props.distchange.addDistchangeAttachment || [];
          let attachmentSize = 0;
          dstAttachments.map((a, i) => {
            attachmentSize += Number(a.fileSize) || 0;
          });
          this.setState({ dstAttachments, attachmentSize });
        }
        if (this.props.distchange.addDistchangeAttachmentError) {
          Helpers.handleAPIErr(
            this.props.distchange.addDistchangeAttachmentError,
            this.props
          );
        }
        this.setState({ isLoading: false });
      }
    } else {
      toast.error("Please Select an DistChange");
    }
  };

  addEditDistchange = () => {
    let { id } = this.state;
    this.props.history.push("/dist-changes-form", {
      id,
    });
  };

  getAttachment = async (recordID, fileName) => {
    this.setState({ isLoading: true });

    await this.props.getAttachment(recordID);
    if (this.props.distchange.getDistchangeAttachmentSuccess) {
      // toast.success(this.props.poData.getPOAttachmentSuccess);
      let resp = this.props.distchange.getDistchangeAttachment;
      if (resp.documentPresignedURL) {
        downloadFileFromPresignedUrl(resp.documentPresignedURL);
      }else {
        await downloadAttachments(resp, fileName);
      }
    }
    if (this.props.distchange.getDistchangeAttachmentError) {
      Helpers.handleAPIErr(
        this.props.poData.getDistchangeAttachmentError,
        this.props
      );
    }
    this.setState({ isLoading: false });
  };

  handleCheckbox = (e, data) => {
    this.setState({ checked: !this.state.checked });
    let { getDistchangeList, multipleTrans } = this.state;
    let { name, checked } = e.target;
    // console.log("checked>>>", checked);
    let allSelected = false;
    if (data === "allCheck" && name === "checkboxAll") {
      let multipleTransCopy = [];
      if (checked) {
        allSelected = true;
        getDistchangeList.map((m) => {
          m.checked = true;
          multipleTransCopy.push(m.id);
          return m;
        });
      } else {
        allSelected = false;
        getDistchangeList.map((m) => {
          m.checked = false;
          return m;
        });
      }
      multipleTrans = [...multipleTransCopy];
    } else {
      if (checked) {
        getDistchangeList.map((dst, i) => {
          if (data.guid === dst.guid) {
            dst.checked = true;
          }
          return dst;
        });
        multipleTrans.push(data.guid);
      } else {
        getDistchangeList.map(async (dst, i) => {
          if (data.guid === dst.guid) {
            dst.checked = false;
          }
          return dst;
        });
        let filteredMultiTrans = multipleTrans.filter((t) => t !== data.guid);
        multipleTrans = filteredMultiTrans;
      }
    }
    console.log("multipleTrans>>>>", multipleTrans);
    this.setState({
      getDistchangeList,
      multipleTrans,
      allSelected,
    });
  };

  handleSortOrderLists = async (name) => {
    let { sortFilterCheck, sortFilter, clonedGetDistchangeList } = this.state;

    if (sortFilter != name) {
      sortFilterCheck = "DESC";
    }

    if (sortFilterCheck === "DESC") {
      sortFilterCheck = "ASC";
    } else {
      sortFilterCheck = "DESC";
    }

    let suppliersListFilterdData = clonedGetDistchangeList.sort(function (
      a,
      b
    ) {
      let valueA = a[name].toString().toUpperCase();
      let valueB = b[name].toString().toUpperCase();
      //for ascending order
      if (sortFilterCheck === "ASC") {
        if (valueA < valueB) {
          return -1;
        }
        if (valueA > valueB) {
          return 1;
        }
        return 0;
        // codes must be equal
      } else {
        //for descending order

        if (valueA > valueB) {
          return -1;
        }
        if (valueA < valueB) {
          return 1;
        }
        return 0;
        // codes must be equal
      }
    });

    this.setState({
      suppliersList: suppliersListFilterdData,
      sortFilter: name,
      sortFilterCheck,
    });
  };

  distChangesListSearch = (e) => {
    let searchedText = e.target.value;
    const clonedGetDistchangeList = JSON.parse(
      JSON.stringify(this.state.clonedGetDistchangeList)
    );
    let {
      getDistchangeList,
      // activeSupplier,
    } = this.state;

    if (!searchedText) {
      getDistchangeList = clonedGetDistchangeList;
      // activeSupplier = getDistchangeList[0].id;
    } else {
      let distChangesListFilterdData = [];
      distChangesListFilterdData = clonedGetDistchangeList.filter((c) => {
        return (
          c.userName.toUpperCase().includes(searchedText.toUpperCase()) ||
          c.description.toUpperCase().includes(searchedText.toUpperCase())
        );
      });
      getDistchangeList = distChangesListFilterdData;
    }
    this.setState(
      { getDistchangeList, distChangesListSearch: searchedText }
      // ()=>{
      //   var elmnt = document.getElementById(activeSupplier);
      //   if (elmnt) {
      //     elmnt.scrollIntoView();
      //   }
      // }
    );
  };

  onDocumentLoadSuccess = (data, index) => {
    this.setState({ isLoading: true });
    let numPages = data.numPages;
    let { numPagesArr } = this.state;
    numPagesArr[index] = numPages;

    if (index === 0) {
      this.setState({ numPages });
    }
    this.setState({ numPagesArr, isLoading: false }, () => {
      this.settPreviewArrows();
    });
  };

  settPreviewArrows = () => {
    // Make the arrows be a bit smarter. Dont appear
    //if only one attachment, hide the left or right arrow if on "first" or "last" document.
    if ($(".carousel-item.active").attr("id") === "0") {
      $(".carousel-control-prev").hide();
      $(".carousel-control-next").show();
    } else if ($(".carousel-inner .carousel-item:last").hasClass("active")) {
      $(".carousel-control-prev").show();
      $(".carousel-control-next").hide();
    } else {
      $(".carousel-control-prev").show();
      $(".carousel-control-next").show();
    }
  };

  handlePDFRotate = () => {
    this.setState({ rotate: this.state.rotate + 90 });
  };
  onSlideChange = () => {
    //carusal get active slide
    this.setState({ pageNumber: 1, rotate: 0 });
    //it takes time to render in DOM
    setTimeout(() => {
      var currentIndex = $(".carousel-item.active").attr("id");
      let numPages = this.state.numPagesArr[currentIndex] || 1;
      this.setState({ numPages });
      this.settPreviewArrows();
    }, 700);
  };
  zoomIn = async () => {
    $(".invoice_pdf_new").removeClass("invoice_carousel_pdf");
    $(".invoice_pdf_new").removeClass("full_screen_convas");

    let { scaling } = this.state;

    let { scale, dropdownZoomingValue } = Helpers.zoomIn(scaling);

    this.setState(
      {
        scaling: scale,
        dropdownZoomingValue,
      },
      () => {
        if (
          scaling == 2.5 ||
          scaling == 2.2 ||
          scaling == 1.9 ||
          scaling == 1.6 ||
          scaling == 1.3
        ) {
          if ($(window).width() >= 2045) {
            $(".invoice_pdf_new").addClass("invoice_pdf_new1");
          }
        } else {
          if ($(window).width() < 2045) {
            $(".invoice_pdf_new").removeClass("invoice_pdf_new1");
          }
        }
      }
    );
  };
  zoomOut = async () => {
    $(".invoice_pdf_new").removeClass("invoice_carousel_pdf");
    $(".invoice_pdf_new").removeClass("full_screen_convas");

    let { scaling } = this.state;

    let { scale, dropdownZoomingValue } = Helpers.zoomOut(scaling);

    this.setState(
      {
        scaling: scale,
        dropdownZoomingValue,
      },
      () => {
        if (
          scaling == 2.5 ||
          scaling == 2.2 ||
          scaling == 1.9 ||
          scaling == 1.6 ||
          scaling == 1.3
        ) {
          if ($(window).width() >= 2045) {
            $(".invoice_pdf_new").addClass("invoice_pdf_new1");
          }
        } else {
          if ($(window).width() < 2045) {
            $(".invoice_pdf_new").removeClass("invoice_pdf_new1");
          }
        }
      }
    );
  };
  handleHorizontalCross = () => {
    $(".mm_invoice_div").addClass("over_auto_remove");
    $(".invoice_pdf_new").addClass("full_screen_convas");
    var vw = $(window).width();
    var nav = $(".navbar.fixed-top").height();
    var underNav = $(".order-tabs").height();
    var wh = $(window).height();
    var pdfDiv = wh - nav;
    var wh = wh - nav - underNav;

    $(".invoice_pdf_new").removeClass("invoice_carousel_pdf");
    $(".invoice_pdf_canvas.invoice_pdf_new").css("height", wh);

    if ($(window).width() > 1500) {
      this.setState({
        scaling: 7,
        dropdownZoomingValue: { label: "100%", value: "100%" },
      });
    } else if ($(window).width() <= 1500) {
      this.setState({
        scaling: 3.1,
        dropdownZoomingValue: { label: "35%", value: "35%" },
      });
    }
  };
  handleHorizontalArrow = () => {
    $(".invoice_pdf_new").addClass("invoice_carousel_pdf");

    $(".invoice_pdf_new").removeClass("full_screen_convas");

    if ($(window).width() > 1500) {
      this.setState({
        scaling: 7,
        dropdownZoomingValue: { label: "100%", value: "100%" },
      });
    } else if ($(window).width() <= 1500) {
      this.setState({
        scaling: 3.4,
        dropdownZoomingValue: { label: "40%", value: "40%" },
      });
    }
  };

  onLoadSuccessPage = (page) => {
    var vw = $(window).width();
    var vw = $(".side-attachments").width();
    var nav = $(".navbar.fixed-top").height();
    var underNav = $(".order-tabs").height();
    var wh = $(window).height();
    var h = wh - nav - 60;
    var rsb = wh - nav - underNav - 20;
    // var pdfDiv = wh - nav - underNav - 80
    var pdfDiv = wh - nav;
    var wh = wh - nav - underNav;
    $("#order--dynamic--height").css("height", h);
    $(".side-attachments,.side-attachments-2").css("height", rsb);
    // $('#maped_image').css('height', pdfDiv)
    $(".invoice_pdf_canvas.invoice_pdf_new").css("height", wh);
  };

  handleDropdownZooming = async (data) => {
    $(".invoice_pdf_new").removeClass("invoice_carousel_pdf");
    $(".invoice_pdf_new").removeClass("full_screen_convas");
    let value = data.value;

    let { scale, dropdownZoomingValue } = Helpers.handleDropdownZooming(value);

    this.setState(
      {
        scaling: scale,
        dropdownZoomingValue,
      },
      () => {
        if (
          value == "25%" ||
          value == "20%" ||
          value == "15%" ||
          value == "10%" ||
          value == "5%"
        ) {
          if ($(window).width() < 2045) {
            $(".invoice_pdf_new").addClass("invoice_pdf_new1");
          }
        } else {
          if ($(window).width() < 2045) {
            $(".invoice_pdf_new").removeClass("invoice_pdf_new1");
          }
        }
      }
    );
  };

  goToPrevPage = () => {
    let { pageNumber } = this.state;
    if (pageNumber - 1 >= 1) {
      this.setState({ pageNumber: pageNumber - 1 });
    }
  };

  goToNextPage = () => {
    let { pageNumber, numPages } = this.state;
    if (pageNumber + 1 <= numPages) {
      this.setState({ pageNumber: pageNumber + 1 });
    }
  };

  handleRightSidebar = () => {
    this.setState((prevState, props) => ({
      toggleRightSidebar: !prevState.toggleRightSidebar,
    }));
  };

  openModal = async (name) => {
    this.setState({ [name]: true });
  };

  closeModal = (name) => {
    this.setState({
      [name]: false,
    });
  };
  moveDistchange = async () => {
    let { id, multipleTrans, showDistChangeTabPane } = this.state;
    if (id || (multipleTrans && multipleTrans.length > 0)) {
      this.setState({
        isLoading: true,
      });
      let _trans = "";
      if (multipleTrans.length > 0) {
        if (multipleTrans.length == 1) {
          _trans = multipleTrans[0];
        } else {
          _trans = multipleTrans;
        }
      } else {
        _trans = id;
      }

      await this.props.moveDistchange(_trans); // move po
      //success case of move PO
      if (this.props.distchange.moveDistchangeSuccess) {
        toast.success(this.props.distchange.moveDistchangeSuccess);
        await this.getDistchangeTallies(showDistChangeTabPane, true); //to refresh the list
      }
      //error case of move PO
      if (this.props.distchange.moveDistchangeError) {
        Helpers.handleAPIErr(
          this.props.distchange.moveDistchangeError,
          this.props
        );
      }
      this.setState({ isLoading: false });
      this.props.clearDistChangesStates();
    } else {
      toast.error("Please select Dist Change First!");
    }
  };

  approveDistchange = async () => {
    let { id, multipleTrans, showDistChangeTabPane } = this.state;
    if (id || (multipleTrans && multipleTrans.length > 0)) {
      this.setState({
        isLoading: true,
      });
      let _trans = "";
      if (multipleTrans.length > 0) {
        if (multipleTrans.length == 1) {
          _trans = multipleTrans[0];
        } else {
          _trans = multipleTrans;
        }
      } else {
        _trans = id;
      }

      await this.props.approveDistchange(_trans); // move po
      //success case of move PO
      if (this.props.distchange.approveDistchnageSuccess) {
        toast.success(this.props.distchange.approveDistchnageSuccess);
        await this.getDistchangeTallies(showDistChangeTabPane, true); //to refresh the list
      }
      //error case of move PO
      if (this.props.distchange.approveDistchnageError) {
        Helpers.handleAPIErr(
          this.props.distchange.approveDistchnageError,
          this.props
        );
      }
      this.setState({ isLoading: false });
      this.props.clearDistChangesStates();
    } else {
      toast.error("Please select Dist Change First!");
    }
  };

  sendForApprovalDistchange = async () => {
    let { id, multipleTrans, showDistChangeTabPane } = this.state;
    if (id || (multipleTrans && multipleTrans.length > 0)) {
      this.setState({
        isLoading: true,
      });
      let _trans = "";
      if (multipleTrans.length > 0) {
        if (multipleTrans.length === 1) {
          _trans = multipleTrans[0];
        } else {
          _trans = multipleTrans;
        }
      } else {
        _trans = id;
      }

      await this.props.sendForApprovalDistchange(_trans); // move po
      //success case of move PO
      if (this.props.distchange.sendForApprovalDistchangeSuccess) {
        toast.success(this.props.distchange.sendForApprovalDistchangeSuccess);
        await this.getDistchangeTallies(showDistChangeTabPane, true); //to refresh the list
      }
      //error case of move PO
      if (this.props.distchange.sendForApprovalDistchangeError) {
        Helpers.handleAPIErr(
          this.props.distchange.sendForApprovalDistchangeError,
          this.props
        );
      }
      this.setState({ isLoading: false });
      this.props.clearDistChangesStates();
    } else {
      toast.error("Please select Dist Change First!");
    }
  };

  postDistchange = async (data) => {
    // let { multipleTrans, id } = this.state;

    let { period, reportID, generateReport } = data;

    let obj = {
      guid: this.state.id,
      // period,
      reportID,
      generateReport: generateReport ? "Y" : "N",
    };

    this.setState({ isLoading: true });
    await this.props.postDistchange(obj);
    if (this.props.distchange.postDistchangeSuccess) {
      // alert("succes");
      toast.success(this.props.distchange.postDistchangeSuccess);

      let jsonData = this.props.distchange.postDistchange.reportJson || "";
      let reportFile =
        this.props.distchange.postDistchange.reportTemplate || "";
      if (jsonData && reportFile) {
        localStorage.setItem("reportFile", reportFile);
        localStorage.setItem("jsonData", jsonData);
        localStorage.setItem("key", "test");
        var path =
          window.location.protocol +
          "//" +
          window.location.host +
          "/report-view";

        window.open(path, "_blank");
      }
      this.closeModal("openPostModal");
    }
    if (this.props.distchange.postDistchangeError) {
      Helpers.handleAPIErr(
        this.props.distchange.postDistchangeError,
        this.props
      );
    }
    this.props.clearDistChangesStates();
    this.setState({ isLoading: false });
  };

  openPostModal = () => {
    let { multipleTrans } = this.state;

    if (multipleTrans.length > 0) {
      if (multipleTrans.length == 1) {
        this.openModal("openPostModal");
      } else {
        toast.error("only one DistChange can be Posted at a time");
      }
    } else {
      toast.error("Please Select DistChange First!");
    }
  };

  declineDistchange = async () => {
    let { id, multipleTrans, showDistChangeTabPane } = this.state;

    this.setState({
      isLoading: true,
    });
    let _trans = "";
    if (multipleTrans.length > 0) {
      if (multipleTrans.length == 1) {
        _trans = multipleTrans[0];
      } else {
        _trans = multipleTrans;
      }
    } else {
      _trans = id;
    }

    await this.props.declineDistchange(_trans); // move po
    //success case of move PO
    if (this.props.distchange.declineDistchangeSuccess) {
      toast.success(this.props.distchange.declineDistchangeSuccess);
      await this.getDistchangeTallies(showDistChangeTabPane, true); //to refresh the list
    }
    //error case of move PO
    if (this.props.distchange.declineDistchangeError) {
      Helpers.handleAPIErr(
        this.props.distchange.declineDistchangeError,
        this.props
      );
    }
    this.setState({ isLoading: false });
    this.props.clearDistChangesStates();
  };

  deleteDistchange = async () => {
    let { multipleTrans, id } = this.state;
    let trans = "";
    if (multipleTrans.length > 0) {
      if (multipleTrans.length === 1) {
        trans = multipleTrans[0];
      } else {
        toast.error("Only One DistChange can be Delete at a Time!");
      }
    } else {
      trans = id;
    }
    console.log("trans:::", trans);
    if (trans) {
      this.setState({ isLoading: true });

      await this.props.deleteDistchange(trans);

      if (this.props.distchange.deleteDistchangeSuccess) {
        toast.success(this.props.distchange.deleteDistchangeSuccess);

        let {
          getDistchangeList,
          // multipleTrans,
          activeDistchange,
          activeDistChangeTallis,
          showDistChangeTabPane,
          distChangeTallies,
          id,
        } = this.state;

        if (getDistchangeList.length === 1) {
          // await this.clearStates();
          //decrease the tallies count also
          distChangeTallies.map((t, i) => {
            if (
              showDistChangeTabPane.toLocaleLowerCase() ===
              t.type.toLocaleLowerCase()
            ) {
              if (t.count === 1) {
                t.count = 0;
              } else {
                t.count = t.count - 1;
              }
            }
            return t;
          });
          this.setState({
            activeDistChangeTallis,
            showDistChangeTabPane,
            distChangeTallies,
          });
        } else if (getDistchangeList.length > 1) {
          if (trans === id) {
            //when user delete the current selected invoice
            //there are two cases if the user deletes the first invoice in the list  then active the very next otherwise highlight invoice above the deleted invoice
            let foundIndex = getDistchangeList.findIndex(
              (l) => l.id === activeDistchange
            );
            if (foundIndex != -1 && foundIndex === 0) {
              let inv = getDistchangeList[foundIndex + 1];
              console.log("ivc::", inv);
              if (inv) {
                await this.getDistchangeList(inv);
              }
            } else {
              let inv = getDistchangeList[foundIndex - 1];
              if (inv) {
                await this.getDistchangeList(inv);
              }
            }
            let list = getDistchangeList.filter((l) => l.id != trans);
            //decrease the tallies count also
            distChangeTallies.map((t, i) => {
              if (
                showDistChangeTabPane.toLocaleLowerCase() ===
                t.type.toLocaleLowerCase()
              ) {
                if (t.count === 1) {
                  t.count = 0;
                } else {
                  t.count = t.count - 1;
                }
              }
              return t;
            });

            this.setState({
              getDistchangeList: list,
              clonedGetDistchangeList: list,
              distChangeTallies,
              multipleTrans: [],
            });
          } else {
            //when user delete other invoice by checking the check box
            let list = getDistchangeList.filter((l) => l.id != trans);
            //decrease the tallies count also
            distChangeTallies.map((t, i) => {
              if (
                showDistChangeTabPane.toLocaleLowerCase() ===
                t.type.toLocaleLowerCase()
              ) {
                if (t.count === 1) {
                  t.count = 0;
                } else {
                  t.count = t.count - 1;
                }
              }
              return t;
            });

            this.setState({
              getDistchangeList: list,
              clonedGetDistchangeList: list,
              distChangeTallies,
              multipleTrans: [],
            });
          }
        }
      }

      if (this.props.distchange.deleteDistchangeError) {
        Helpers.handleAPIErr(
          this.props.distchange.deleteDistchangeError,
          this.props
        );
      }

      this.setState({ isLoading: false });
    }
  };

  closeReportModel = () => {
    this.setState({openReportModal:false})
  }
  openReportModelHandle = () => {
    this.setState({openReportModal:true})
  }
  downloadPreview = () => {
    if ( this.state.previewList?.length > 0) {
      const primaryDocument = this.state.previewList?.filter(document => document.primaryDocument === 'Y');
      if (primaryDocument.length > 0){
        if(primaryDocument[0].documentPresignedURL){
          downloadFileFromPresignedUrl(primaryDocument[0].documentPresignedURL);
        }else {
          const linkSource = `data:application/pdf;base64,${primaryDocument[0].file}`;
          const downloadLink = document.createElement("a");
          const fileName = "attachment.pdf";
          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
        }
      }else{
        toast.error('No Primary Document available for download.')
      }
    }else {
      toast.error('No Primary Document available for download.')
    }
  };

  updateDistchangeSummaryState = async () => {
    if (this.props.distchange.updateApprovalGroupSuccess) {
      toast.success(this.props.distchange.updateApprovalGroupSuccess);
    }
    if (this.props.distchange.updateApprovalGroupError) {
      Helpers.handleAPIErr(
        this.props.distchange.updateApprovalGroupError,
        this.props
      );
    }
  };

  render() {
    let userType = localStorage.getItem("userType");
    let approverCheck = false;
    let checkTwo = false;
    if (userType) {
      if (userType.toLowerCase() === "approver") {
        approverCheck = true;
      }
    }
    let tab =
      this.state.showDistChangeTabPane &&
      this.state.showDistChangeTabPane.toLowerCase();
    if (tab) {
      if (tab === "pending" || tab === "decline") {
        //when tab is pending or declined then everything is read only for Approver
        if (approverCheck) {
          checkTwo = true;
        }
      }
    }
    // console.log("state>>>>>", this.state);
    return (
      <>
        {this.state.isLoading ? <div className="se-pre-con"></div> : ""}

        <div className="dashboard">
          {/* top nav bar */}
          <Header
            props={this.props}
            distChange={true}
            toggleTeamIcon={this.toggleTeamIcon}
            viewTeam={this.state.viewTeam}
          />
          {/* end */}

          {/* body part */}

          <div className="dashboard_body_content dash__invoice--content">
            {/* top Nav menu*/}
            <TopNav />
            {/* end */}

            {/* side menu po*/}
            <aside
              className="side-nav suppliers_side_nav side__content--invoice"
              id="show-side-navigation12"
            >
              <div className="cus-arro-div2">
                <img
                  src="images/arrow-r.png"
                  className=" img-fluid cus-arro-r"
                  alt="user"
                />
              </div>
              {/* <div className="search">
                <div className="row">
                  <div className="col-auto pr-md-0 align-self-center">
                    <Dropdown
                      alignRight={false}
                      drop="down"
                      className="analysis-card-dropdwn custom-my-radio user_drop_options"
                    >
                      <Dropdown.Toggle variant="sucess" id="dropdown-basic">
                        <img src="images/angle-down.png" alt="arrow" />
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          to="#/action-2"
                          className="f-20 flex-container-inner"
                        >
                          <div className="custom-control custom-radio flex-container-inner">
                            <input
                              type="radio"
                              className="custom-control-input flex-container-inner-input"
                              id="total"
                              name="total"
                              onChange={() => {}}
                              checked={this.state.sortFilter === "total"}
                            />
                            <label
                              className="custom-control-label flex-container-inner-input"
                              htmlFor="total"
                            >
                              Amount
                            </label>
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item
                          to="#/action-2"
                          className="f-20 flex-container-inner"
                        >
                          <div className="custom-control custom-radio flex-container-inner">
                            <input
                              type="radio"
                              className="custom-control-input flex-container-inner-input"
                              id="orderBy"
                              name="orderBy"
                              onChange={() => {}}
                              checked={this.state.sortFilter === "orderBy"}
                            />
                            <label
                              className="custom-control-label flex-container-inner-input"
                              htmlFor="orderBy"
                            >
                              Buyer
                            </label>
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item
                          to="#/action-2"
                          className="f-20 flex-container-inner"
                        >
                          <div className="custom-control custom-radio flex-container-inner">
                            <input
                              type="radio"
                              className="custom-control-input flex-container-inner-input"
                              id="date"
                              name="date"
                              onChange={() => {}}
                              checked={this.state.sortFilter === "date"}
                            />
                            <label
                              className="custom-control-label flex-container-inner-input"
                              htmlFor="date"
                            >
                              Date
                            </label>
                          </div>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>{" "}
                  </div>
                  <div className="col input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text" id="basic-addon1">
                        <img
                          src="images/search-icon.png"
                          className="mx-auto"
                          alt="search-icon"
                        />
                      </span>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="What are you looking for"
                      aria-label="What are you looking for"
                      aria-describedby="basic-addon1"
                      name="POListSearch"
                      id="POListSearchId"
                      value={this.state.POListSearch}
                      onChange={this.handleChangePOListSearch}
                      onKeyDown={this.onPOListSearch}
                    />
                  </div>
                </div>
              </div> */}
              <div className="search">
                <div className="row">
                  <div className="col-auto mb-2 pr-0">
                    <div className="form-group remember_check custom-checkbox-ui">
                      <input
                        type="checkbox"
                        id={"order"}
                        name="checkboxAll"
                        checked={this.state.allSelected}
                        onChange={(e) => this.handleCheckbox(e, "allCheck")}
                      />
                      <label
                        htmlFor={"order"}
                        className="mr-0 custom-box"
                      ></label>
                    </div>
                  </div>
                  <div className="col-auto pr-md-0 align-self-center ml-1">
                    <Dropdown
                      alignRight={false}
                      drop="down"
                      className="analysis-card-dropdwn custom-my-radio user_drop_options"
                    >
                      <Dropdown.Toggle
                        variant="sucess"
                        className="custom-angle-down"
                        id="dropdown-basic"
                      >
                        <img src="images/angle-down.png" alt="arrow" />
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        {tab === "approved" ? (
                          <Dropdown.Item
                            to="#/action-2"
                            className="f-20 custom-drp-down"
                          >
                            <div
                              onClick={() =>
                                this.handleSortOrderLists("approvalDate")
                              }
                              className="custom-control custom-radio"
                            >
                              <input
                                type="radio"
                                className="custom-control-input"
                                id="approvalDate"
                                name="approvalDate"
                                onChange={() => {}}
                                checked={
                                  this.state.sortFilter === "approvalDate"
                                }
                              />
                              <label
                                className="custom-control-label flex-container-inner-input"
                                htmlFor="approvalDate"
                              >
                                Approval Date
                              </label>
                            </div>
                          </Dropdown.Item>
                        ) : (
                          ""
                        )}
                        <Dropdown.Item
                          to="#/action-2"
                          className="f-20 flex-container-inner"
                        >
                          <div
                            onClick={() => this.handleSortOrderLists("amount")}
                            className="custom-control custom-radio flex-container-inner"
                          >
                            <input
                              type="radio"
                              className="custom-control-input flex-container-inner-input"
                              id="amount"
                              name="amount"
                              onChange={() => {}}
                              checked={this.state.sortFilter === "amount"}
                            />
                            <label
                              className="custom-control-label flex-container-inner-input"
                              htmlFor="amount"
                            >
                              Amount
                            </label>
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item
                          to="#/action-1"
                          className="flex-container-inner"
                        >
                          <div
                            onClick={() =>
                              this.handleSortOrderLists("description")
                            }
                            className="custom-control custom-radio flex-container-inner"
                          >
                            <input
                              type="radio"
                              className="custom-control-input flex-container-inner-input"
                              id="description"
                              name="description"
                              onChange={() => {}}
                              checked={this.state.sortFilter === "description"}
                            />
                            <label
                              className="custom-control-label flex-container-inner-input"
                              htmlFor="description"
                            >
                              description
                            </label>
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item
                          to="#/action-2"
                          className="f-20 flex-container-inner"
                        >
                          <div
                            onClick={() => this.handleSortOrderLists("date")}
                            className="custom-control custom-radio flex-container-inner"
                          >
                            <input
                              type="radio"
                              className="custom-control-input flex-container-inner-input"
                              id="date"
                              name="date"
                              onChange={() => {}}
                              checked={this.state.sortFilter === "date"}
                            />
                            <label
                              className="custom-control-label flex-container-inner-input"
                              htmlFor="date"
                            >
                              Date
                            </label>
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item
                          to="#/action-2"
                          className="f-20 flex-container-inner"
                        >
                          <div
                            onClick={() => this.handleSortOrderLists("line")}
                            className="custom-control custom-radio flex-container-inner"
                          >
                            <input
                              type="radio"
                              className="custom-control-input flex-container-inner-input"
                              id="line"
                              name="line"
                              onChange={() => {}}
                              checked={this.state.sortFilter === "line"}
                            />
                            <label
                              className="custom-control-label flex-container-inner-input"
                              htmlFor="line"
                            >
                              line
                            </label>
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item
                          to="#/action-2"
                          className="f-20 flex-container-inner"
                        >
                          <div
                            onClick={() =>
                              this.handleSortOrderLists("userName")
                            }
                            className="custom-control custom-radio flex-container-inner"
                          >
                            <input
                              type="radio"
                              className="custom-control-input flex-container-inner-input"
                              id="userName"
                              name="userName"
                              onChange={() => {}}
                              checked={this.state.sortFilter === "userName"}
                            />
                            <label
                              className="custom-control-label flex-container-inner-input"
                              htmlFor="userName"
                            >
                              userName
                            </label>
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item
                          to="#/action-2"
                          className="f-20 flex-container-inner"
                        >
                          <div
                            onClick={() => this.handleSortOrderLists("vendor")}
                            className="custom-control custom-radio flex-container-inner"
                          >
                            <input
                              type="radio"
                              className="custom-control-input flex-container-inner-input"
                              id="vendor"
                              name="vendor"
                              onChange={() => {}}
                              checked={this.state.sortFilter === "vendor"}
                            />
                            <label
                              className="custom-control-label flex-container-inner-input"
                              htmlFor="vendor"
                            >
                              vendor
                            </label>
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item
                          to="#/action-2"
                          className="f-20 flex-container-inner"
                        >
                          <div
                            onClick={() => this.handleSortOrderLists("tranNo")}
                            className="custom-control custom-radio flex-container-inner"
                          >
                            <input
                              type="radio"
                              className="custom-control-input flex-container-inner-input"
                              id="tranNo"
                              name="tranNo"
                              onChange={() => {}}
                              checked={this.state.sortFilter === "tranNo"}
                            />
                            <label
                              className="custom-control-label flex-container-inner-input"
                              htmlFor="tranNo"
                            >
                              tranNo
                            </label>
                          </div>
                        </Dropdown.Item>
                        {/* {usePageLoading === "y" ? (
                          <div className="custom-control custom-radio cutm-pr-right">
                            <div className="settings_display_row flex__wrapper-inner">
                              <label className="labelwrapper__iner lable-inner-wrapper">
                                Display Rows Per Page
                              </label>
                              <input
                                className="form-control input__wrapper--inner "
                                type="number"
                                min="1"
                                defaultValue={display}
                                onBlur={this.handleRowsSetting}
                              />
                            </div>
                          </div>
                        ) : (
                          ""
                        )} */}
                      </Dropdown.Menu>
                    </Dropdown>{" "}
                  </div>
                  <div className="col input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text" id="basic-addon1">
                        <img
                          src="images/search-icon.png"
                          className="mx-auto"
                          alt="search-icon"
                        />
                      </span>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="What are you looking for"
                      aria-label="What are you looking for"
                      aria-describedby="basic-addon1"
                      name="POListSearch"
                      id="POListSearchId"
                      value={this.state.distChangesListSearch}
                      onChange={this.distChangesListSearch}
                      // onKeyDown={this.onPOListSearch}
                      // value={this.state.POListSearch}
                      // onChange={(e) =>
                      //   usePageLoading === "y"
                      //     ? this.handleChangeNewPOListSearch(e)
                      //     : this.handleChangePOListSearch(e)
                      // }
                      // onKeyDown={(e) =>
                      //   usePageLoading === "y"
                      //     ? this.onNewPOListSearch(e)
                      //     : this.onPOListSearch(e)
                      // }
                    />
                  </div>
                </div>
              </div>

              <ul className="suppliers_list">
                {this.state.getDistchangeList.map((l, i) => {
                  return (
                    <li
                      key={i}
                      className={
                        l.teamExpense === "Y"
                          ? this.state.getDistchangeList[i + 1] &&
                            this.state.getDistchangeList[i + 1].teamExpense &&
                            this.state.getDistchangeList[i + 1].teamExpense ===
                              "Y"
                            ? "teamOrdersBg teamOrdersBorder2 cursorPointer"
                            : "teamOrdersBg teamOrdersBorder cursorPointer"
                          : this.state.activeDistchange === l.id
                          ? "active cursorPointer"
                          : "cursorPointer"
                      }
                      id={l.id}
                    >
                      <div className="row">
                        <div className="col-auto mb-2 pr-0">
                          <div className="form-group remember_check">
                            <input
                              type="checkbox"
                              id={"invoice" + i}
                              checked={l.checked}
                              name="checkbox"
                              onChange={(e) => this.handleCheckbox(e, l)}
                            />
                            <label
                              htmlFor={"invoice" + i}
                              className="mr-0"
                            ></label>
                          </div>
                        </div>

                        <div
                          className="col pl-0"
                          onClick={() => {
                            this.getDistchangeSummary(l);
                          }}
                        >
                          <div className="invioce_data pr-sm-3">
                            <h4>{l.vendor || ""}</h4>
                            <div className="row">
                              <div className="col data-i pr-0">
                                <p> {l.stageType}</p>
                              </div>
                              <div className="col data-i pl-0">
                                <p>Date: {l.date}</p>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col data-i pr-0">
                                <p>Tran#: {l.tranNo}</p>
                              </div>
                              <div className="col data-i pl-0">
                                <p> Code: {l.line}</p>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col pr-0">
                                <p>Amount {l.amount}</p>
                              </div>
                              <div className="col pl-0">
                                <p> Desc: {l.description}</p>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col data-i"></div>
                              <div className="col-auto data-i">
                                <div className="text-center cursorPointer">
                                  <span
                                    onClick={() => this.handleMoreDetails("")}
                                  >
                                    <Link className="more-details-color" to="#">
                                      more details
                                    </Link>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </aside>
            {/* {/ end /} */}

            <section id="contents" className="supplier pr-0 pt-0">
              <div className="body_content ordermain-padi body__invoice--top">
                <div className="container-fluid pl-0 ">
                  <div className="main_wrapper " id="order--dynamic--height">
                    <div className="row d-flex pl-15">
                      <div className="col-12 w-100 order-tabs p-md-0">
                        {/* PO Tallies */}
                        <div className="nav_tav_ul">
                          <ul className="nav nav-tabs">
                            {this.state.distChangeTallies.map((t, i) => {
                              return (
                                <li
                                  key={i}
                                  className="cursorPointer nav-item"
                                  onClick={() =>
                                    this.getDistchangeTallies(t.type)
                                  }
                                >
                                  <a
                                    className={
                                      this.state.activeDistChangeTallis === t.id
                                        ? "nav-link active"
                                        : "nav-link"
                                    }
                                  >
                                    {t.type}{" "}
                                    <span className="stats">{t.count}</span>
                                  </a>
                                </li>
                              );
                            })}
                          </ul>
                        </div>

                        <div className="bg-gry w-100 float-left mm_top_nav">
                          <div className="w-100 float-left mm_lr_pad">
                            <div className="mm_tab_left order_left_icons">
                              <div className="tab-content">
                                {this.state.showDistChangeTabPane &&
                                  this.state.showDistChangeTabPane.toLowerCase() ==
                                    "draft" && (
                                    <div className="tab-pane container active">
                                      <ul>
                                        <li
                                          className="cursorPointer"
                                          onClick={() =>
                                            this.props.history.push(
                                              "/dist-changes-form"
                                            )
                                          }
                                        >
                                          <img
                                            src="images/add.png"
                                            className=" img-fluid "
                                            alt="user"
                                          />{" "}
                                          <Link to="#">New</Link>{" "}
                                        </li>
                                        <li
                                          className="cursorPointer"
                                          onClick={this.addEditDistchange}
                                        >
                                          <img
                                            src="images/pencill.png"
                                            className=" img-fluid "
                                            alt="user"
                                          />{" "}
                                          <Link to="#">Edit</Link>{" "}
                                        </li>
                                        <li className="cursorPointer">
                                          <img
                                            src="images/copy1.png"
                                            className=" img-fluid "
                                            alt="user"
                                          />{" "}
                                          <Link to="#"> Copy </Link>
                                        </li>
                                        {this.state.teamOrderCheck != "Team" ? (
                                          <>
                                            <li
                                              className="cursorPointer"
                                              onClick={() =>
                                                this.state.getDistchangeList
                                                  .length > 0
                                                  ? this.openModal(
                                                      "openDeleteModal"
                                                    )
                                                  : () => {}
                                              }
                                            >
                                              <img
                                                src="images/delete.svg"
                                                className="invoice-delete-icon img-fluid "
                                                alt="user"
                                              />{" "}
                                              <Link to="#"> Delete </Link>
                                            </li>
                                            <li
                                              className="cursorPointer"
                                              onClick={() =>
                                                this.state.getDistchangeList
                                                  .length > 0
                                                  ? this.sendForApprovalDistchange()
                                                  : () => {}
                                              }
                                            >
                                              <img
                                                src="images/send.png"
                                                className=" img-fluid "
                                                alt="user"
                                              />{" "}
                                              <Link to="#"> Send </Link>
                                            </li>
                                          </>
                                        ) : (
                                          ""
                                        )}
                                      </ul>
                                    </div>
                                  )}
                                {this.state.showDistChangeTabPane &&
                                  this.state.showDistChangeTabPane.toLowerCase() ==
                                    "approve" && (
                                    <div
                                      className={
                                        1 === 0
                                          ? "tab-pane container"
                                          : "tab-pane container active"
                                      }
                                    >
                                      <ul>
                                        {this.state.teamOrderCheck != "Team" ? (
                                          <>
                                            <li
                                              onClick={this.approveDistchange}
                                              className="cursorPointer"
                                            >
                                              <img
                                                src="images/tick.png"
                                                className="img-fluid "
                                                alt="user"
                                              />{" "}
                                              <Link to="#"> Approve </Link>
                                            </li>
                                            <li
                                              onClick={this.holdPO}
                                              className="cursorPointer"
                                            >
                                              <img
                                                src="images/move.png"
                                                className=" img-fluid"
                                                alt="user"
                                              />{" "}
                                              <Link to="#"> Hold </Link>
                                            </li>
                                            <li
                                              onClick={() =>
                                                this.openModal(
                                                  "openDeclineModal"
                                                )
                                              }
                                              className="cursorPointer"
                                            >
                                              <img
                                                src="images/decline.png"
                                                className=" img-fluid "
                                                alt="user"
                                              />{" "}
                                              <Link to="#"> Decline </Link>
                                            </li>
                                            <li
                                              onClick={
                                                this.openOrderDetailModal
                                              }
                                              className="cursorPointer"
                                            >
                                              <img
                                                src="images/pencill.png"
                                                className=" img-fluid "
                                                alt="user"
                                              />{" "}
                                              <Link to="#"> Edit </Link>
                                            </li>
                                          </>
                                        ) : (
                                          ""
                                        )}
                                      </ul>
                                    </div>
                                  )}
                                {this.state.showDistChangeTabPane &&
                                  this.state.showDistChangeTabPane.toLowerCase() ==
                                    "decline" && (
                                    <div
                                      className={
                                        1 === 0
                                          ? "tab-pane container"
                                          : "tab-pane container active"
                                      }
                                    >
                                      {!approverCheck && (
                                        <ul>
                                          {this.state.teamOrderCheck !=
                                          "Team" ? (
                                            <li
                                              className="cursorPointer"
                                              onClick={this.moveDistchange}
                                            >
                                              <img
                                                src="images/move.png"
                                                className=" img-fluid "
                                                alt="user"
                                              />{" "}
                                              <Link to="#"> Move </Link>
                                            </li>
                                          ) : (
                                            ""
                                          )}
                                        </ul>
                                      )}
                                    </div>
                                  )}
                                {this.state.showDistChangeTabPane &&
                                  this.state.showDistChangeTabPane.toLowerCase() ==
                                    "hold" && (
                                    <div
                                      className={
                                        1 === 0
                                          ? "tab-pane container"
                                          : "tab-pane container active"
                                      }
                                    >
                                      <ul>
                                        {this.state.teamOrderCheck != "Team" ? (
                                          <>
                                            <li
                                              onClick={this.approveDistchange}
                                              className="cursorPointer"
                                            >
                                              <img
                                                src="images/tick.png"
                                                className=" img-fluid "
                                                alt="user"
                                              />{" "}
                                              <Link to="#"> Approve </Link>
                                            </li>
                                            <li
                                              onClick={() =>
                                                this.openModal(
                                                  "openDeclineModal"
                                                )
                                              }
                                              className="cursorPointer"
                                            >
                                              <img
                                                src="images/decline.png"
                                                className=" img-fluid "
                                                alt="user"
                                              />{" "}
                                              <Link to="#"> Decline </Link>
                                            </li>
                                            <li
                                              onClick={
                                                this.openOrderDetailModal
                                              }
                                              className="cursorPointer"
                                            >
                                              <img
                                                src="images/pencill.png"
                                                className=" img-fluid "
                                                alt="user"
                                              />{" "}
                                              <Link to="#"> Edit </Link>
                                            </li>
                                          </>
                                        ) : (
                                          ""
                                        )}
                                      </ul>
                                    </div>
                                  )}
                                {this.state.showDistChangeTabPane &&
                                  this.state.showDistChangeTabPane.toLowerCase() ==
                                    "pending" && (
                                    <div
                                      className={
                                        1 === 0
                                          ? "tab-pane container"
                                          : "tab-pane container active"
                                      }
                                    >
                                      {!approverCheck && (
                                        <ul>
                                          {this.state.teamOrderCheck !=
                                          "Team" ? (
                                            <li
                                              onClick={() =>
                                                checkTwo
                                                  ? this.openModal("")
                                                  : this.openModal(
                                                      "openMoveToDraftModal"
                                                    )
                                              }
                                              className="cursorPointer"
                                            >
                                              <img
                                                src="images/move.png"
                                                className=" img-fluid "
                                                alt="user"
                                              />{" "}
                                              <Link to="#"> Move </Link>
                                            </li>
                                          ) : (
                                            ""
                                          )}
                                          <li
                                            onClick={() =>
                                              checkTwo
                                                ? this.openModal("")
                                                : this.openModal(
                                                    "openCopyModal"
                                                  )
                                            }
                                            className="cursorPointer"
                                          >
                                            <img
                                              src="images/copy1.png"
                                              className=" img-fluid "
                                              alt="user"
                                            />{" "}
                                            <Link to="#"> Copy </Link>
                                          </li>
                                        </ul>
                                      )}
                                    </div>
                                  )}
                                {this.state.showDistChangeTabPane &&
                                  this.state.showDistChangeTabPane.toLowerCase() ==
                                    "approved" && (
                                    <div
                                      className={
                                        1 === 0
                                          ? "tab-pane container"
                                          : "tab-pane container active"
                                      }
                                    >
                                      <ul>
                                        {!approverCheck && (
                                          <li
                                            onClick={() =>
                                              this.openModal("openCopyModal")
                                            }
                                            className="cursorPointer"
                                          >
                                            <img
                                              src="images/copy1.png"
                                              className=" img-fluid "
                                              alt="user"
                                            />{" "}
                                            <Link to="#"> Copy </Link>
                                          </li>
                                        )}
                                        <li
                                          onClick={() =>
                                            this.openModal("openCloseModal")
                                          }
                                          className="cursorPointer"
                                        >
                                          <img
                                            src="images/decline.png"
                                            className="top_0 img-fluid"
                                            alt="user"
                                          />{" "}
                                          <Link to="#"> Close </Link>
                                        </li>
                                        {!approverCheck && (
                                          <li
                                            onClick={() =>
                                              this.openModal("openModifyModal")
                                            }
                                            className="cursorPointer"
                                          >
                                            <img
                                              src="images/pencill.png"
                                              className=" img-fluid "
                                              alt="user"
                                            />{" "}
                                            <Link to="#"> Modify </Link>
                                          </li>
                                        )}
                                      </ul>
                                    </div>
                                  )}
                                {this.state.showDistChangeTabPane &&
                                  this.state.showDistChangeTabPane.toLowerCase() ==
                                    "all" && (
                                    <div
                                      className={
                                        1 === 0
                                          ? "tab-pane container"
                                          : "tab-pane container active"
                                      }
                                    >
                                      <ul>
                                        <li></li>
                                      </ul>
                                    </div>
                                  )}
                              </div>
                            </div>
                            <div className="mm_tab_center order_right_icons">
                              <div className="slider-panel">
                                <Link to="#" className="zom-img">
                                  <img
                                    onClick={this.zoomOut}
                                    src="images/minus.png"
                                    className=" img-fluid float-left"
                                    alt="user"
                                  />{" "}
                                </Link>
                                <Link to="#" className="zom-img">
                                  <img
                                    onClick={this.zoomIn}
                                    src="images/add.png"
                                    className=" img-fluid float-left"
                                    alt="user"
                                  />{" "}
                                </Link>
                                <Select
                                  className="width-selector"
                                  value={this.state.dropdownZoomingValue}
                                  classNamePrefix="custon_select-selector-inner"
                                  options={options}
                                  onChange={this.handleDropdownZooming}
                                  theme={(theme) => ({
                                    ...theme,
                                    border: 0,
                                    borderRadius: 0,
                                    colors: {
                                      ...theme.colors,
                                      primary25: "#f2f2f2",
                                      primary: "#f2f2f2",
                                    },
                                  })}
                                />
                                <Link to="#" className="zom-img">
                                  <img
                                    onClick={this.handleHorizontalCross}
                                    src="images/fulls.png"
                                    className="img-fluid float-left"
                                    alt="user"
                                    id="full_screen"
                                  />{" "}
                                </Link>
                                <Link to="#" className="zom-img">
                                  <img
                                    onClick={this.handleHorizontalArrow}
                                    src="images/twoarow.png"
                                    className="img-fluid float-left"
                                    alt="user"
                                    id="expand"
                                  />{" "}
                                </Link>

                                <Link
                                  to="#"
                                  className="zom-img float-right ml-md-5 pl-2 pr-2 mr-0 more-d mt-0"
                                >
                                  <img
                                    src="images/more.png"
                                    className=" img-fluid"
                                    alt="user"
                                  />{" "}
                                </Link>
                                <Link
                                  to="#"
                                  className="zom-img float-right mt-0"
                                  onClick={this.moveToNextPO}
                                >
                                  <img
                                    src="images/arow-r.png"
                                    className=" img-fluid lr-arrow-up"
                                    alt="user"
                                    href="#demo"
                                    data-slide="next"
                                  />{" "}
                                </Link>
                                <Link
                                  to="#"
                                  className="zom-img float-right mtop-1"
                                  onClick={this.moveToPrevPO}
                                >
                                  <img
                                    src="images/arow-l.png"
                                    className=" img-fluid lr-arrow-up"
                                    alt="user"
                                    href="#demo"
                                    data-slide="prev"
                                  />{" "}
                                </Link>
                                <div className="side-attachments-2 height-2 mm_order_sidebar aside__right--height">
                                  <div
                                    // onClick={this.regenerateSignatures}
                                    className="main-sec-attach main-bg"
                                  >
                                    Regenerate
                                  </div>
                                  <div
                                    onClick={this.downloadPreview}
                                    className="main-sec-attach main-bg"
                                  >
                                    Download Copy
                                    <img
                                      src="images/downlod.png"
                                      className=" img-fluid float-right fa"
                                      alt="user"
                                    />
                                  </div>
                                  <div className="main-sec-attach main-bg">
                                    <span
                                      className="invoice-inherit2"
                                      data-toggle="collapse"
                                      data-target="#Approvalsa"
                                    >
                                      <span className="fa fa-angle-up float-left mr-2 sideBarAccord2"></span>
                                      Approvals
                                    </span>
                                  </div>
                                  <div
                                    className="collapse show"
                                    id="Approvalsa"
                                  >
                                    {[].map((a, i) => {
                                      return (
                                        <div key={i} className="pl-2 mb-3">
                                          <div className="form-group remember_check d-flex">
                                            <div className="checkSide">
                                              <input
                                                type="checkbox"
                                                id={i}
                                                name={a.groupName}
                                                checked={a.checked}
                                                onChange={(e) =>
                                                  this.handleApprovalsFilters(
                                                    e,
                                                    a
                                                  )
                                                }
                                              />
                                              <label htmlFor={i}> </label>
                                            </div>
                                            <span className="text-mar">
                                              {a.groupName}{" "}
                                            </span>
                                          </div>
                                        </div>
                                      );
                                    })}
                                  </div>
                                  <div className="main-sec-attach main-bg">
                                    <span
                                      className="fa fa-angle-up float-left mr-2 sideBarAccord"
                                      data-toggle="collapse"
                                      data-target="#Changes"
                                    ></span>
                                    <span
                                      className="name_attached font-weight-bold"
                                      onClick={this.getPOChanges}
                                    >
                                      Changes
                                      <span className="ml-3 font-weight-bold">
                                        0
                                      </span>
                                    </span>
                                  </div>
                                  <div className="collapse show" id="Changes">
                                    {[].map((c, i) => {
                                      return (
                                        <div
                                          key={i}
                                          className="main-sec-attach1"
                                        >
                                          <p className="m-clr s-bold mb-0">
                                            {c.userName}
                                          </p>
                                          {c.description}
                                          <p className="gry-clr mb-0">
                                            {c.date} {c.time}
                                          </p>
                                        </div>
                                      );
                                    })}
                                  </div>
                                  <div className="main-sec-attach main-bg">
                                    <span
                                      className="fa fa-angle-up float-left mr-2 sideBarAccord"
                                      data-toggle="collapse"
                                      data-target="#Activity"
                                    ></span>
                                    <span
                                      className="name_attached font-weight-bold"
                                      onClick={() =>
                                        this.openModal("openActivityModal")
                                      }
                                    >
                                      Activity
                                    </span>
                                  </div>
                                  <div className="collapse show" id="Activity">
                                    {this.state.activity.map((a, i) => {
                                      return (
                                        <div
                                          key={i}
                                          className="main-sec-attach1"
                                        >
                                          {a.description}
                                          <p className="gry-clr mb-0">
                                            {a.date} {a.time}
                                          </p>
                                        </div>
                                      );
                                    })}
                                  </div>
                                  <div className="main-sec-attach main-bg">
                                    <span
                                      className="export_crd"
                                      data-toggle="collapse"
                                      data-target="#export"
                                    >
                                      <span className="fa fa-angle-up float-left mr-2 sideBarAccord1"></span>
                                      Export
                                    </span>
                                  </div>
                                  <div className="collapse show" id="export">
                                    <div
                                      onClick={this.exportDistchange}
                                      className="pl-2 mb-3"
                                    >
                                      <div className="form-group remember_check d-flex">
                                        <span className="text-mar cursorPointer ml-38">
                                          EXCEL
                                        </span>
                                      </div>
                                    </div>
                                    {/* <div
                                      onClick={this.PSLExport}
                                      className="pl-2 mb-3"
                                    >
                                      <div className="form-group remember_check d-flex">
                                        <span className="text-mar cursorPointer ml-38">
                                          XML
                                        </span>
                                      </div>
                                    </div> */}
                                  </div>
                                  {/* <div className="collapse show" id="export">
                                    {["EXCEL"].map((op, i) => {
                                      return (
                                        <div key={i} className="pl-2 mb-3">
                                          <div className="form-group remember_check d-flex">
                                            <span className="text-mar cursorPointer ml-38">
                                              {op}
                                            </span>
                                          </div>
                                        </div>
                                      );
                                    })}
                                  </div> */}
                                  <div
                                    onClick={() =>
                                      this.openModal("openReportModal")
                                    }
                                    className="main-sec-attach main-bg"
                                  >
                                    Reports
                                  </div>

                                  <div
                                    onClick={() =>
                                      this.openModal("openImportModal")
                                    }
                                    className="main-sec-attach main-bg"
                                  >
                                    Import
                                  </div>
                                  <div
                                    onClick={this.openPostModal}
                                    className="main-sec-attach main-bg"
                                  >
                                    Post
                                  </div>
                                  {/* batch list start here  */}
                                  <div className="main-sec-attach main-bg">
                                    <span
                                      className="invoice-inherit"
                                      data-toggle="collapse"
                                      data-target="#batchlist"
                                    >
                                      <span className="fa fa-angle-up float-left mr-2 sideBarAccord1"></span>
                                      Batch List
                                    </span>
                                  </div>
                                  <div className="collapse show" id="batchlist">
                                    <div className="pl-2 mb-3">
                                      <div className="text-right pb-2 pr-4">
                                        <a class="mr-3 d-none" href="#">
                                          <img
                                            src="images/add.png"
                                            class=" img-fluid "
                                            alt="user"
                                          />
                                        </a>
                                        <a class="d-none" href="#">
                                          <img
                                            src="images/delete.svg"
                                            class="invoice-delete-icon img-fluid "
                                            alt="user"
                                          />
                                        </a>
                                      </div>
                                      <table className="table table-bordered mb-0 order-collapse-table">
                                        <tbody>
                                          <tr>
                                            <th>
                                              <div class="form-group remember_check">
                                                <input
                                                  type="checkbox"
                                                  id="oleftcheck1"
                                                />
                                                <label htmlFor="oleftcheck1"></label>
                                              </div>
                                            </th>
                                            <th>Description</th>
                                            <th>Batch</th>
                                            <th></th>
                                          </tr>
                                          <tr>
                                            <td>
                                              <div class="form-group remember_check">
                                                <input
                                                  type="checkbox"
                                                  id="oleftcheck2"
                                                />
                                                <label htmlFor="oleftcheck2"></label>
                                              </div>
                                            </td>
                                            <td>API Batch</td>
                                            <td>2</td>
                                            <td></td>
                                          </tr>
                                          <tr>
                                            <td>
                                              <div class="form-group remember_check">
                                                <input
                                                  type="checkbox"
                                                  id="oleftcheck3"
                                                />
                                                <label htmlFor="oleftcheck3"></label>
                                              </div>
                                            </td>
                                            <td>Draft Invoice</td>
                                            <td>3</td>
                                            <td></td>
                                          </tr>
                                          <tr>
                                            <td>
                                              <div class="form-group remember_check">
                                                <input
                                                  type="checkbox"
                                                  id="oleftcheck4"
                                                />
                                                <label htmlFor="oleftcheck4"></label>
                                              </div>
                                            </td>
                                            <td>Pending Invoices</td>
                                            <td>4</td>
                                            <td></td>
                                          </tr>
                                          <tr>
                                            <td>
                                              <div class="form-group remember_check">
                                                <input
                                                  type="checkbox"
                                                  id="oleftcheck5"
                                                />
                                                <label htmlFor="oleftcheck5"></label>
                                              </div>
                                            </td>
                                            <td>Approved Invoices</td>
                                            <td>5</td>
                                            <td></td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                  {/* end  Batch list  */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="img-section-t col-12 pl-0 h-100">
                      {this.state.getDistchangeList.length > 0 && (
                        <div
                          id="demo"
                          className={
                            this.state.toggleRightSidebar
                              ? " carousel slide invoice_carousel mm_invoice_div over_auto_remove"
                              : " carousel slide invoice_carousel "
                          }
                          // data-ride="carousel"
                          data-interval={false}
                        >
                          <ul className="carousel-indicators">
                            <li
                              data-target="#demo"
                              data-slide-to="0"
                              className="active"
                            ></li>
                            <li data-target="#demo" data-slide-to="1"></li>
                            <li data-target="#demo" data-slide-to="2"></li>
                          </ul>

                          <div className="carousel-inner">
                            {this.state.previewList.length > 0
                              ? this.state.previewList.map((p, i) => {
                                  return (
                                    <div
                                      key={i}
                                      className={
                                        i === 0
                                          ? "carousel-item active "
                                          : "carousel-item "
                                      }
                                      id={i}
                                    >
                                      <div className="text-center">
                                        <div className="invoice_pdf_canvas invoice_pdf_new pdf--buttons pdf__height--content expensis-home">
                                          {p.file ? (
                                            <>
                                              <Document
                                                file={
                                                  "data:application/pdf;base64," +
                                                  p.file
                                                }
                                                onLoadSuccess={(data) =>
                                                  this.onDocumentLoadSuccess(
                                                    data,
                                                    i
                                                  )
                                                }
                                                rotate={this.state.rotate}
                                              >
                                                <Page
                                                  pageNumber={
                                                    this.state.pageNumber
                                                  }
                                                  scale={this.state.scaling}
                                                  height="372"
                                                  onLoadSuccess={
                                                    this.onLoadSuccessPage
                                                  }
                                                />
                                              </Document>
                                              <div className="page-controls">
                                                <button
                                                  type="button"
                                                  disabled=""
                                                  onClick={this.goToPrevPage}
                                                >
                                                  ‹
                                                </button>
                                                <span>
                                                  {this.state.pageNumber} of{" "}
                                                  {this.state.numPages}
                                                </span>{" "}
                                                <button
                                                  type="button"
                                                  onClick={this.goToNextPage}
                                                >
                                                  ›
                                                </button>
                                              </div>
                                            </>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })
                              : "No Preview Found"}

                            {this.state.getDistchangeList.length > 1 && (
                              <>
                                <a
                                  className="carousel-control-prev"
                                  href="#demo"
                                  data-slide="prev"
                                  onClick={this.onSlideChange}
                                >
                                  <i>
                                    <span className="carousel-control-prev-icon"></span>
                                  </i>
                                </a>
                                <a
                                  className="carousel-control-next"
                                  href="#demo"
                                  data-slide="next"
                                  onClick={this.onSlideChange}
                                >
                                  <i>
                                    <span className="carousel-control-next-icon"></span>
                                  </i>
                                </a>
                              </>
                            )}
                          </div>
                        </div>
                      )}
                      {/* <div
                        className={
                          this.state.toggleRightSidebar
                            ? "pdf__height--content mm_ordr1 order_pdf_new h-100"
                            : "pdf__height--content mm_ordr1 order_pdf_expand1 m_auto h-100"
                        }
                        id="overload_image"
                      >
                        <div
                          id="maped_image "
                          className="order_pfd h-100"
                          style={{ background: "#fff" }}
                        >
                          <div
                            className="maped_image h-100 expand_it "
                            style={{
                              transform: this.state.scaling,
                              transformOrigin: "center top",
                            }}
                          >
                            <img
                              className="explore_img"
                              // src='images/newtest.png'
                              src={"images/image6.png"}
                              useMap="#image-map"
                              id="preview"
                              alt="preview"
                            />
                          </div>
                        </div>
                      </div> */}
                      <div
                        id="right-sidbar"
                        className="side-attachments mm_order_side aside__right--height"
                      >
                        {" "}
                        <div
                          onClick={this.handleRightSidebar}
                          className="cus-arro-div"
                        >
                          <img
                            src="images/arrow-r.png"
                            className=" img-fluid cus-arro-r"
                            alt="user"
                          />
                        </div>
                        <div className="side-attack">
                          <div className="main-sec-attach main-bg">
                            {/* PO Attachments */}
                            <span
                              className="fa fa-angle-up float-left mr-2 sideBarAccord"
                              data-toggle="collapse"
                              data-target="#Attachments"
                            ></span>
                            <span
                              className="name_attached"
                              onClick={() =>
                                this.openModal("openAttachmentsModal")
                              }
                            >
                              Attachments
                              <span className="ml-3 font-weight-bold">
                                {this.state.dstAttachments.length}
                              </span>
                              <a class="float-right mr-3" href="#">
                                <img
                                  src="images/add.png"
                                  class=" img-fluid sidebarr_plus "
                                  alt="user"
                                />
                              </a>
                            </span>
                          </div>
                          <div className="collapse show" id="Attachments">
                            {this.state.dstAttachments.map((a, i) => {
                              return (
                                <div
                                  onClick={() =>
                                    this.getAttachment(
                                      a.recordID,
                                      a.contentType,
                                      a.fileName
                                    )
                                  }
                                  key={i}
                                  className="main-sec-attach"
                                >
                                  {a.fileName}{" "}
                                  <span className="fa fa-angle-right"></span>
                                </div>
                              );
                            })}
                          </div>
                          {/* side menue Approvers / Approvals */}

                          <div className="main-sec-attach main-bg">
                            <span
                              className="invoice-inherit"
                              data-toggle="collapse"
                              data-target="#Approvals"
                            >
                              <span className="fa fa-angle-up float-left mr-2 sideBarAccord1"></span>
                              <span className="name_attached">Approvals</span>
                            </span>
                          </div>
                          <div className="collapse show" id="Approvals_invoice">
                            {this.state.approvalGroup.value &&
                              this.state.approvalGroup.value.trim() && (
                                <div className="main-sec-mid1">
                                  {/* <button
                                    // onClick={sendEmail}
                                    type="button"
                                    className="btn-save1 add_comment_btns1"
                                    onClick={() =>
                                      this.openModal("openCheckApprovalModal")
                                    }
                                  >
                                    <span></span>
                                    Previous
                                  </button> */}
                                  <small>
                                    {this.state.approvalGroup.value}
                                  </small>
                                  {/* <button
                                    // onClick={sendEmail}
                                    type="button"
                                    className="btn-save1 add_comment_btns"
                                    onClick={() =>
                                      this.openModal("openCheckApprovalModal")
                                    }
                                  >
                                    <span></span>
                                    Next
                                  </button> */}
                                </div>
                              )}
                            {this.state.approvers.map((a, i) => {
                              return (
                                <div
                                  key={i}
                                  className="main-sec-attach cus-check"
                                >
                                  <div className="form-group remember_check">
                                    {a.status === "Approved" ? (
                                      <input type="checkbox" id={i} checked />
                                    ) : (
                                      ""
                                    )}
                                    {a.status === "Declined" ? (
                                      <i
                                        className="fa fa-times circle-check1 float-left ml-1"
                                        aria-hidden="true"
                                      ></i>
                                    ) : a.status === "Current" ? (
                                      <i
                                        className="fa fa-circle-thin circle-check float-left ml-1"
                                        aria-hidden="true"
                                        style={{ marginTop: "3px" }}
                                      ></i>
                                    ) : (
                                      ""
                                    )}
                                    <label htmlFor={i}>
                                      {" "}
                                      <span
                                        className={
                                          a.status === "Declined"
                                            ? "order-right-color2 selected mm_lcapp"
                                            : a.status === "Current"
                                            ? "order-right-color1 selected mm_lcapp"
                                            : "text-mar"
                                        }
                                      >
                                        {a.approverName}
                                      </span>
                                      {a.status === "Declined" ? (
                                        <span className="current-approver mm_approre1">
                                          {" "}
                                          (user declined)
                                        </span>
                                      ) : a.status === "Current" ? (
                                        <span className="current-approver mm_approre1">
                                          {" "}
                                          (current approver)
                                        </span>
                                      ) : (
                                        ""
                                      )}
                                    </label>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                          {/* PO Comments */}
                          <div className="main-sec-attach main-bg">
                            <span
                              className="fa fa-angle-up float-left mr-2 sideBarAccord"
                              data-toggle="collapse"
                              data-target="#Comments"
                            ></span>
                            <span
                              className="name_attached"
                              onClick={() =>
                                this.openModal("openCommentsModal")
                              }
                            >
                              Comments
                              <span className="ml-3 font-weight-bold">
                                {this.state.dstComments.length}
                              </span>
                              {this.state.showDistChangeTabPane === "Draft" ||
                              this.state.showDistChangeTabPane === "Pending" ||
                              this.state.showDistChangeTabPane === "Approve" ||
                              this.state.showDistChangeTabPane === "Hold" ||
                              this.state.showDistChangeTabPane ===
                                "Declined" ? (
                                <a className="float-right mr-3" href="#">
                                  <img
                                    src="images/add.png"
                                    className=" img-fluid sidebarr_plus "
                                    alt="user"
                                  />
                                </a>
                              ) : null}
                            </span>
                          </div>
                          <div className="collapse show" id="Comments">
                            {this.state.dstComments.map((c, i) => {
                              return (
                                <div key={i} className="main-sec-attach1">
                                  <p className="m-clr s-bold mb-0">
                                    {c.userName}
                                  </p>
                                  {c.comment}
                                  <p className="gry-clr mb-0">
                                    {c.date} {c.time}
                                  </p>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
          {/* end */}
        </div>

        <Attachments
          openAttachmentsModal={this.state.openAttachmentsModal}
          closeModal={this.closeModal}
          addAttachment={this.addAttachment}
          attachments={this.state.dstAttachments}
          getAttachment={this.getAttachment}
          attachmentSize={this.state.attachmentSize}
          draft={tab === "draft" ? true : false}
          // ordersPage={true}
        />
        <Comments
          openCommentsModal={this.state.openCommentsModal}
          closeModal={this.closeModal}
          comments={this.state.dstComments}
          addComment={this.addComment}
          tab={
            this.state.showDistChangeTabPane &&
            this.state.showDistChangeTabPane.toLowerCase()
          }
        />

        <Activity
          openActivityModal={this.state.openActivityModal}
          closeModal={this.closeModal}
          activity={this.state.activity}
        />
        <Changes
          openChangesModal={this.state.openChangesModal}
          closeModal={this.closeModal}
          changes={this.state.poChanges}
        />
        <Import state={this.state} closeModal={this.closeModal} />

        <Report
          openReportModal={this.state.openReportModal}
          closeModal={this.closeModal}
          reportType="Distribution Change"
          locationProps={this.props}
          closeReportModel={this.closeReportModel}
          openReportModelHandle={this.openReportModelHandle}
        />
        <MoveToDraft
          openMoveToDraftModal={this.state.openMoveToDraftModal}
          closeModal={this.closeModal}
          movePO={this.moveDistchange}
        />
        <Decline
          openDeclineModal={this.state.openDeclineModal}
          closeModal={this.closeModal}
          onDecline={this.declineDistchange}
        />
        <Delete
          openDeleteModal={this.state.openDeleteModal}
          closeModal={this.closeModal}
          onDelete={this.deleteDistchange}
        />

        <Post
          openPostModal={this.state.openPostModal}
          closeModal={this.closeModal}
          postType="DistChange"
          onSave={this.postDistchange}
          locationProps={this.props}
        />
        <DistChangesMoreDetailsModal
          state={this.state}
          openModal={this.openModal}
          closeModal={this.closeModal}
          showDistChangeTabPane={this.state.showDistChangeTabPane}
          distChangesMoreDetailsOpenModal={
            this.state.distChangesMoreDetailsOpenModal
          }
          getDistchangeSummary={this.getDistchangeSummary}
          updateDistchangeSummaryState={this.updateDistchangeSummaryState}
        />
        <CheckApproval
          openCheckApprovalModal={this.state.openCheckApprovalModal}
          closeModal={this.closeModal}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  distchange: state.distchange,
  // setup: state.setup,
});

export default connect(mapStateToProps, {
  getDistchangeTallies: DistchangeActions.getDistchangeTallies,
  getDistchangeList: DistchangeActions.getDistchangeList,
  getDistchangeSummary: DistchangeActions.getDistchangeSummary,
  addComment: DistchangeActions.addComment,
  exportDistchange: DistchangeActions.exportDistchange,
  addDistchangeAttachment: DistchangeActions.addDistchangeAttachment,
  getAttachment: DistchangeActions.getAttachment,
  clearDistChangesStates: DistchangeActions.clearDistChangesStates,
  moveDistchange: DistchangeActions.moveDistchange,
  deleteDistchange: DistchangeActions.deleteDistchange,
  approveDistchange: DistchangeActions.approveDistchange,
  declineDistchange: DistchangeActions.declineDistchange,
  sendForApprovalDistchange: DistchangeActions.sendForApprovalDistchange,
  postDistchange: DistchangeActions.postDistchange,
})(DistChanges);
