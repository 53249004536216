import React, { Component, PureComponent } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import $ from "jquery";
import { _customStyles } from "../../../Constants/Constants";
import DatePicker from "react-datepicker";

import TopNav from "../../Common/TopNav/TopNav";
import * as AccountActions from "../../../Actions/AccountActions/AccountActions";
// import { clearStatesAfterLogout } from "../../Actions/UserActions/UserActions";
import * as Validation from "../../../Utils/Validation";
import {
  toBase64,
  addDragAndDropFileListners,
  removeDragAndDropFileListners,
  handleAPIErr,
  handleHideUnhideRows,
  handleValueOptions,
  downloadAttachments,
} from "../../../Utils/Helpers";
import "../PeriodClose/PeriodClose.css";
import "./FinancialReportsWorksheet.css";
import BudgetImport from "../../Modals/Accounts/BudgetImport/BudgetImport";
import FinancialLayoutModal from "../../Modals/Accounts/FinancReportModal/FinancialLayoutModal/FinancialLayoutModal";
import Import from "../../Modals/Accounts/Import/Import";
import HyperLinkModal from "../../Modals/Accounts/FinancReportModal/HyperLinkModal/HyperLinkModal";
const uuidv1 = require("uuid/v1");

class FinancialReportsWorksheet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      openImportModal: false,
      openHyperLinkModal: false,
      accList: [
        {
          chartAccountID: "1",
          checked: false,
          accountSortCode: "AU.01.000.B .257",
          description: "FLOAT",
          post: "N",
          secLevel: "0",
          format: "0",
          type: "Z",
          level: "2",
          active: "N",
          initBalance: "0",
          extChart: "",
          user: "ALL,TEST",
        },
        {
          chartAccountID: "2",
          checked: false,
          accountSortCode: "AU.01.000.B .257",
          description: "FLOAT",
          post: "N",
          secLevel: "0",
          format: "0",
          type: "Z",
          level: "2",
          active: "N",
          initBalance: "0",
          extChart: "",
          user: "ALL,TEST",
        },
        {
          chartAccountID: "3",
          checked: false,
          accountSortCode: "AU.01.000.B .257",
          description: "FLOAT",
          post: "N",
          secLevel: "0",
          format: "0",
          type: "Z",
          level: "2",
          active: "N",
          initBalance: "0",
          extChart: "",
          user: "ALL,TEST",
        },
        {
          chartAccountID: "5",
          checked: false,
          accountSortCode: "AU.01.000.B .257",
          description: "FLOAT",
          post: "N",
          secLevel: "0",
          format: "0",
          type: "Z",
          level: "2",
          active: "N",
          initBalance: "0",
          extChart: "",
          user: "ALL,TEST",
        },
        {
          chartAccountID: "6",
          checked: false,
          accountSortCode: "AU.01.000.B .257",
          description: "FLOAT",
          post: "N",
          secLevel: "0",
          format: "0",
          type: "Z",
          level: "2",
          active: "N",
          initBalance: "0",
          extChart: "",
          user: "ALL,TEST",
        },
      ],
      importsList: [],
      generateFinancials: [],
      budgetImportCols: [],
      budgetImportRows: [],
      generateCols: [],
      generateRows: [],
      getFromHyperLinkTable: [],
      hyperLinkCols: [],
      hyperLinkRows: [],
      parentTableId: "",
      sort: "",
      tableId: "",
      columns: [],

      newRefId: "",
      refId: [],

      addNewReportToggle: false,
      financReportModal: false,
      openBudgetImportModal: false,
      // reportTypesVal: { label: "", value: "" },
      chartSort: "",
      layout: [],
      reportTypes: [],
      reportTypesModal: [],
      reportOptionsModal: [],
      options: [],
      columns: [],
      categories: [],
      clonedOptions: [],
      reportOptionVal: [{ label: "Select Report Option", value: "" }],
      reportOptions: [],
      advancedList: [],
      advancedList1: [],
      clonnedAdvancedList: [],
      ColumnOptionDetails: [],
      availableReports: [],
      availabletypes: "",
      menuIsOpen: false,
      excelData: "",

      reportFile: "",
      reportName: "",
      private: false,
      showHiddenRows: false,
      reDrawDT: false, //re draw datatable

      reportTypeValue: [{ label: "Select Report Type", value: "" }],
      reportTypesVal: { label: "", value: "" },
      reportLayouts: [],
      reportLayoutsVal: [],

      formErrors: {
        reportTypesVal: "",
        reportOptionVal: "",
        reportFile: "",
        reportName: "",
      },
    };
  }

  async componentDidMount() {
    // this.primePeriodClose();
    $(".icon_dots2").click(function () {
      $(".mm-blok2").toggleClass("doc_sidebar2");
    });
    await this.getLayoutOptions();
  }

  getLayoutOptions = async () => {
    this.setState({ isLoading: true });
    let { reportTypeValue } = this.state;
    await this.props.getLayoutOptions();
    if (this.props.account.getLayoutOptionsSuccess) {
      let reportTypes = this.props?.account?.getLayoutOptions || [];
      let reportOptions = [];

      reportTypes.map((opt, i) => {
        if (opt.option && opt.option.length > 0) {
          reportOptions.push({ label: opt.option, value: opt.option });
        }
        if (opt.selected && opt.selected === 1) {
          reportTypeValue = [{ label: opt.option, value: opt.option }];
        }
      });

      this.setState({ reportTypes: reportOptions, reportTypeValue });
    }
    if (this.props.account.getLayoutOptionsError) {
      handleAPIErr(this.props.account.getLayoutOptionsError, this.props);
    }
    this.setState({ isLoading: false });
  };
  getFinancialLayout = async (name) => {
    this.setState({ isLoading: true });
    let { reportTypesVal } = this.state;
    let data = {
      layout: this.state.reportTypeValue[0].label,
    };

    if (data.layout != "") {
      await this.props.getFinancialLayout(data);
      if (this.props.account.getFinancialLayoutSuccess) {
        let layout = this.props?.account?.getFinancialLayout || [];
        let reportTypesModal = layout[0].availableLayouts || [];
        let reportOptionsModal = layout[0].availableReport || [];
        let options = layout[0].options || [];
        let columns = layout[0].columns || [];
        let categories = layout[0].categories || [];
        let reportType = [];
        let reportOptions = [];
        reportTypesModal.map((opt) => {
          if (opt.reportRefId.length > 0) {
            reportType.push({ label: opt.reportType, value: opt.reportRefId });

            if (data.layout === opt.reportType) {
              reportTypesVal = {
                label: opt.reportType,
                value: opt.reportRefId,
              };
            }
          }
        });
        reportOptionsModal.map((opt) => {
          // if (opt.id.length > 0) {
          reportOptions.push({ label: opt.layout, value: opt.id });

          // }
        });
        options.map((lst, i) => {
          if (
            (lst.valueType && lst.valueType.toLowerCase() === "list") ||
            "multiselect"
          ) {
            let valOptns = [];
            if (lst.options && lst.options.length > 0) {
              lst.options.map((o, i) => {
                valOptns.push({ label: o.value, value: o.value });
              });
            }
            lst.options = valOptns;
          }
          lst.id = uuidv1();
          lst.hide = false;
          return lst;
        });

        this.setState({
          layout,
          reportTypesModal: reportType,
          reportOptionsModal: reportOptions,
          options,
          columns,
          categories,
          clonedOptions: options,
          reportTypesVal,
        });
      }
      if (this.props.account.getFinancialLayoutError) {
        handleAPIErr(this.props.account.getFinancialLayoutError, this.props);
      }
      this.openModal(name);
    } else {
      toast.error("Select a Report Type First");
    }

    this.setState({ isLoading: false });
  };
  primeImportBudget = async (name) => {
    this.setState({ isLoading: true });
    await this.props.primeImportBudget();
    if (this.props.account.primeImportBudgetSuccess) {
      let advancedList1 =
        this.props?.account.primeImportBudget?.importOptions || [];
      let importsList = this.props?.account.primeImportBudget?.imports || [];

      advancedList1.map((lst, i) => {
        if (
          (lst.valueType && lst.valueType.toLowerCase() === "list") ||
          "droplist"
        ) {
          let valOptns = [];
          if (lst.options && lst.options.length > 0) {
            lst.options.map((o, i) => {
              valOptns.push({ label: o.value, value: o.value });
            });
          }
          lst.options = valOptns;
        }
        lst.id = uuidv1();
        lst.hide = false;
        return lst;
      });

      let budgetImportCols = importsList[0].Columns || [];

      let budgetImportRows = importsList[0].Rows || [];

      importsList.map((lst) => (lst.checked = false));
      let columns = [];
      //adding the column names
      columns[0] = { name: "ID" };
      columns[1] = { name: "Chart Sort" };
      columns[2] = { name: "Chart Code" };
      columns[3] = { name: "Description" };
      columns[4] = { name: "Level" };
      columns[5] = { name: "Post" };
      columns[6] = { name: "Est Final Cost" };
      columns[7] = { name: "Budget" };
      columns[8] = { name: "Duplicate" };

      this.setState(
        {
          advancedList1,
          clonedAdvancedList: advancedList1,
          importsList,
          budgetImportCols,
          budgetImportRows,
          isLoading: false,
          columns,
        },
        () => {
          // this.tableSetting();
        }
      );
      this.openModal(name);
    }
    if (this.props.account.primeImportBudgetError) {
      handleAPIErr(this.props.account.primeImportBudgetError, this.props);
    }
    this.setState({ isLoading: false });
  };
  generateFinancials = async (layout) => {
    this.setState({ isLoading: true });
    let data = {
      layout:
        layout != this.state.reportTypeValue[0].label
          ? this.state.reportTypeValue[0].label
          : layout,
    };
    await this.props.generateFinancials(data);
    if (this.props.account.generateFinancialsSuccess) {
      toast.success(this.props.account.generateFinancialsSuccess);
      let generateFinancials =
        this.props.account.generateFinancials.financials || [];
      let tableId = generateFinancials.tableId || "";
      let parentTableId = generateFinancials.parentTableId || "";
      let sort = generateFinancials.sort || "";

      let generateCols = generateFinancials[0].Columns || [];
      let generateRows = generateFinancials[0].Rows || [];

      let columns = [];
      columns[0] = { name: "ID" };
      columns[1] = { name: "Chart Sort" };
      columns[2] = { name: "Chart Code" };
      columns[3] = { name: "Description" };
      columns[4] = { name: "Level" };
      columns[5] = { name: "Period" };
      columns[6] = { name: "PO" };
      columns[7] = { name: "Previous Period" };
      columns[8] = { name: "Initial Balance" };
      columns[9] = { name: "Next Period" };
      columns[10] = { name: "To Date" };
      columns[11] = { name: "Unposted" };
      columns[12] = { name: "Est To Complete" };
      columns[13] = { name: "Fringe%" };
      columns[14] = { name: "Fringe ETC" };
      columns[15] = { name: "Est Final Cost" };
      columns[16] = { name: "Budget" };
      columns[17] = { name: "Variance" };
      columns[18] = { name: "Per Variance" };
      columns[19] = { name: "EFC Last Period" };
      columns[20] = { name: "Notes" };
      this.setState({
        generateFinancials,
        generateCols,
        generateRows,
        columns,
        parentTableId,
        sort,
        tableId,
      });
    }
    if (this.props.account.generateFinancialsError) {
      handleAPIErr(this.props.account.generateFinancialsError, this.props);
      this.setState({ generateCols: [], generateRows: [] });
    }
    this.setState({ isLoading: false });
  };
  updateFinancials = async () => {
    this.setState({ isLoading: true });
    let data = {
      layout: this.state.reportTypeValue[0].label,
      financials: this.state.generateFinancials,
    };
    await this.props.updateFinancials(data);
    if (this.props.account.updateFinancialsSuccess) {
      toast.success(this.props.account.updateFinancialsSuccess);
    }
    if (this.props.account.updateFinancialsError) {
      handleAPIErr(this.props.account.updateFinancialsError);
    }
    this.setState({ isLoading: false });
  };

  handleCheckboxes = (e, data) => {
    let { refId, generateRows, newRefId } = this.state;

    // let bankChequeRows = JSON.parse(JSON.stringify(this.state.bankChequeRows));
    let { name, checked } = e.target;
    if (data === "allCheck" && name === "checkboxAll") {
      let multipleTransCopy = [];
      if (checked) {
        generateRows.map((m) => {
          m.checked = true;
          multipleTransCopy.push(m.refId);
          return m;
        });
      } else {
        generateRows.map((m) => {
          m.checked = false;
          return m;
        });
      }
      refId = [...multipleTransCopy];
    } else {
      if (checked) {
        generateRows.map(async (po, i) => {
          if (data.refId === po.refId) {
            po.checked = true;
          }
          return po;
        });
        refId.push(data.refId);
      } else {
        generateRows.map(async (po, i) => {
          if (data.refId === po.refId) {
            po.checked = false;
          }
          return po;
        });
        let filteredMultiRefId = refId.filter((t) => t != data.refId);
        refId = filteredMultiRefId;
      }
    }
    newRefId = refId + [];

    this.setState({
      generateRows,
      refId,
      newRefId,
    });
  };
  // tableSetting = () => {
  //   let { columns } = this.state;
  //   let aoColumns = [];
  //   //adding the column names
  //   aoColumns[0] = { sName: "checkbox" };
  //   columns.map((c) => aoColumns.push({ sName: c.name }));
  //   aoColumns[columns.length + 1] = { sName: "menus" };
  //   let result = this.tableSetting(columns, aoColumns, "chartofaccounts");
  //   this.setState({ ...result });
  // };
  // primePeriodClose = async () => {
  //   this.setState({ isLoading: true });
  //   await this.props.PrimePeriodClose();
  //   if (this.props.account.PrimePeriodCloseSuccess) {
  //     toast.success(this.props.account.PrimePeriodCloseSuccess);
  //     let primePeriod = this.props.account.PrimePeriodClose || [];
  //     let advancedList = primePeriod[0].advancedOptions;
  //     let availableReports = primePeriod[0].availableReports;
  //     // let reportTypes = availableReports[0].reportType;
  //     let reportTypeOption = [];
  //     availableReports.map((item) => {
  //       reportTypeOption.push({ label: item.reportType, value: item.areaRef });
  //     });

  //     let reportLayoutsVal = availableReports[0].reportLayouts;
  //     let reportOption = [];

  //     reportLayoutsVal.map((item) => {
  //       reportOption.push({ label: item.layout, value: item.id });
  //     });

  //     advancedList.map((lst, i) => {
  //       if ((lst.type && lst.type.toLowerCase() === "list") || "droplist") {
  //         let valOptns = [];
  //         if (lst.options && lst.options.length > 0) {
  //           lst.options.map((o, i) => {
  //             valOptns.push({ label: o.value, value: o.value });
  //           });
  //         }
  //         lst.options = valOptns;
  //       }
  //       lst.id = uuidv1();
  //       lst.hide = false;
  //       return lst;
  //     });

  //     this.setState({
  //       advancedList,
  //       clonnedAdvancedList: advancedList,
  //       reportTypes: reportTypeOption,
  //       reportLayouts: reportOption,
  //       reportLayoutsVal,
  //     });
  //   }

  //   if (this.props.account.PrimePeriodCloseError) {
  //     handleAPIErr(this.props.account.PrimePeriodCloseError, this.props);
  //   }

  //   this.setState({ isLoading: false });
  // };

  // _handleValueOptions = async (type, val, item, index) => {
  //   let { advancedList, clonnedAdvancedList } = this.state;
  //   let result = handleValueOptions(
  //     type,
  //     val,
  //     item,
  //     index,
  //     advancedList,
  //     clonnedAdvancedList
  //   );
  //   this.setState({
  //     ...this.state,
  //     advancedList: result.advancedList,
  //     clonnedAdvancedList: result.clonedAdvancedList,
  //   });
  // };

  // _handleHideUnhideRows = (item) => {
  //   let { advancedList, clonnedAdvancedList, showHiddenRows } = this.state;

  //   let result = handleHideUnhideRows(
  //     item,
  //     "#reportv4-table",
  //     "reports__settings",
  //     advancedList,
  //     clonnedAdvancedList,
  //     showHiddenRows
  //   );

  //   this.setState({
  //     advancedList: result.advancedList,
  //     clonnedAdvancedList: result.clonedAdvancedList,
  //     showHiddenRows: result.showHiddenRows,
  //     reDrawDT: true,
  //   });
  // };

  // handleShowHiddenRows = async () => {
  //   let table = window.$("#reportv4-table").DataTable();
  //   table.destroy();

  //   let { advancedList, clonnedAdvancedList, showHiddenRows } = this.state;

  //   showHiddenRows = !showHiddenRows;

  //   if (showHiddenRows) {
  //     //show hidden rows
  //     advancedList = clonnedAdvancedList;
  //   } else {
  //     //hide again hidden rows
  //     let list = advancedList.filter((l) => !l.hide);
  //     advancedList = list;
  //   }

  //   this.setState({
  //     advancedList,
  //     showHiddenRows,
  //     reDrawDT: true, //to re daraw the datatable
  //   });
  // };

  // handleReportTypes = (type) => {
  //   debugger;
  //   let { formErrors } = this.state;

  //   formErrors = Validation.handleValidation(
  //     "reportTypesVal",
  //     type.value,
  //     formErrors
  //   );

  //   let value = type.value || "";

  //   let reportLayouts = this.state.reportLayouts || [];
  //   let reportOptions = [];

  //   let found = reportLayouts.find((rop) => rop.id === value);

  //   if (found) {
  //     reportOptions.push({
  //       label: found.layout,
  //       value: found.id,
  //       selected: 1,
  //     });
  //   }
  //   this.setState({
  //     reportLayouts: reportOptions,
  //     reportOptionVal: type,
  //     formErrors,
  //   });
  // };
  importExcel = async (type, importData) => {
    this.setState({ isLoading: true });
    let data = {
      importData: importData,
    };
    await this.props.importExcel(data);
    if (this.props.account.importExcelSuccess) {
      toast.success(this.props.account.importExcelSuccess);
    }
    if (this.props.account.importExcelError) {
      handleAPIErr(this.props.account.importExcelError, this.props);
    }
    this.setState({ isLoading: false });
  };

  exportExcel = async () => {
    this.setState({ isLoading: true });
    let data = {
      layout: this.state.reportTypeValue[0].label,
      guidList: this.state.newRefId,
    };
    await this.props.exportExcel(data);
    if (this.props.account.exportExcelSuccess) {
      toast.success(this.props.account.exportExcelSuccess);
      let excelData = this.props.account.exportExcel.excel || "";

      let obj = {
        contentType: "application/vnd.ms-excel",
        attachment: excelData || "",
      };
      downloadAttachments(obj, "exportExcel");
    }
    if (this.props.account.exportExcelError) {
      handleAPIErr(this.props.account.exportExcelError, this.props);
    }

    this.setState({ isLoading: false });
  };

  getFromHyperLink = async (id, hyperlink) => {
    this.setState({ isLoading: true });
    let data = {
      layout: "SUMMARY COST REPORT",
      id: id,
      hyperLink: hyperlink,
    };
    await this.props.getFromHyperLink(data);
    if (this.props.account.getFromHyperLinkSuccess) {
      toast.success(this.props.account.getFromHyperLinkSuccess);
      let getFromHyperLinkTable = this.props.account.getFromHyperLink || [];
      let hyperLinkCols = getFromHyperLinkTable[0].Columns || [];
      let hyperLinkRows = getFromHyperLinkTable[0].Rows || [];

      this.setState(
        { getFromHyperLinkTable, hyperLinkCols, hyperLinkRows },
        () => this.openModal("openHyperLinkModal")
      );
    }
    if (this.props.account.getFromHyperLinkError) {
      handleAPIErr(this.props.account.getFromHyperLinkError);
    }
    this.setState({ isLoading: false });
  };

  _handleReportTypes = (type) => {
    let { formErrors } = this.state;

    formErrors = Validation.handleValidation(
      "reportTypesVal",
      type.value,
      formErrors
    );

    let value = type.value || "";

    let reportLayoutsVal = this.state.reportTypes || [];
    let reportOptions = [];

    let found = reportLayoutsVal.find((rop) => rop.value === value);

    if (found) {
      reportOptions.push({
        label: found.label,
        value: found.value,
        selected: 1,
      });
    }
    this.setState({
      reportTypeValue: reportOptions,
      formErrors,
    });
  };

  openModal = async (name) => {
    // this.setState({ isLoading: true });
    if (
      name === "openChartOfAccountsModal" ||
      name == "openMultipleChangeModal"
    ) {
      if (this.state.checkedList.length > 0) {
        this.setState({ [name]: true });
      } else {
        toast.error("Select Chart Account First");
      }
    } else if (name === "openChartSortModal") {
      this.setState({ [name]: true }, () => {
        this.getChartSorts();
      });
    } else {
      this.setState({ [name]: true });
    }
    // this.setState({ isLoading: false });
  };

  closeModal = (name) => {
    this.setState({ [name]: false });
    if (
      name === "InsertChartModal" ||
      name === "openChartOfAccountsModal" ||
      name === "openImportAccountModal" ||
      name === "openMultipleChangeModal"
    ) {
      this.clearStates();
    }
  };
  handleChangeGenerateFinancial = (e, data, i, j) => {
    data[j] = e.target.value;
  };
  render() {
    return (
      <>
        {this.state.isLoading ? <div className="se-pre-con"></div> : ""}
        <div className="user_setup_main">
          <header>
            <TopNav />

            <div className="user_setup_heading">
              <div className="header_menu">
                <Link to="/dashboard">
                  <img
                    src="images/dash-logo.png"
                    className="img-fluid"
                    alt="logo"
                  />
                </Link>
                <Link
                  className="setup_menu"
                  to="#"
                  data-target="#top_nav_toggle1"
                >
                  <img src="images/top-menu.png" className="" alt="top-menu" />
                </Link>
              </div>
              <h2>FINANCIAL REPORTS WORKSHEET</h2>
              <span>
                <img
                  src="./images/user-setup/lock.png"
                  alt="lock"
                  className="img-fluid"
                />
              </span>
            </div>
            <div className="user_setup_headerbox">
              <div className="user_setup_play_div">
                <ul>
                  <li>
                    <p className="user_setup_play_video">Video</p>
                  </li>
                  <li>
                    <p className="user_setup_play_tuturial">Tutorials</p>
                  </li>
                </ul>
                <span className="user_setup_play_icon">
                  <img
                    src="./images/user-setup/play.png"
                    alt="play"
                    className="img-fluid"
                  />
                </span>
              </div>
              <div className="user_setup_header_rightbox">
                <p>
                  In our{" "}
                  <span>
                    <a href="#">Video</a>
                  </span>{" "}
                  learn how to use Financial Reports Worksheet Read our{" "}
                  <span>
                    <a href="#">help article</a>
                  </span>{" "}
                  to learn More
                </p>
              </div>
              <span>
                <img
                  className="close_top_sec"
                  src="images/user-setup/cross.png"
                  alt="cross"
                ></img>
              </span>
            </div>
          </header>
          <div className="col-sm-12 table_white_box table-zoom-fin">
            <section id="">
              <div className="container-fluid ">
                <div className="main_wrapper p-10">
                  <div className="forgot_body px-3">
                    <div className="row mt-4">
                      <div className="col-xl-3 col-md-3 col-sm-12 mob-order2 ">
                        {/* dropdown coding start */}
                        <div className="form-group custon_select">
                          <label htmlFor="">Report Type</label>
                          <Select
                            className="width-selector"
                            value={
                              // this.state.reportTypeValue[0].value != ""
                              // ?
                              this.state.reportTypeValue
                              // : this.state.reportTypes
                            }
                            classNamePrefix="custon_select-selector-inner"
                            options={this.state.reportTypes}
                            onChange={this._handleReportTypes}
                            theme={(theme) => ({
                              ...theme,
                              border: 0,
                              borderRadius: 0,
                              colors: {
                                ...theme.colors,
                                primary25: "#f2f2f2",
                                primary: "#f2f2f2",
                              },
                            })}
                          />
                          <div className="text-danger error-12">
                            {this.state.formErrors.reportTypesVal !== ""
                              ? this.state.formErrors.reportTypesVal
                              : ""}
                          </div>
                        </div>
                      </div>

                      <div className="col-md-4 mob-order1 mt-4">
                        <div className="user_setup_plus_Icons w-100 ">
                          <ul>
                            <li>
                              <button
                                onClick={this.generateFinancials}
                                type="button"
                                className="btn-save"
                              >
                                <span className="fa fa-check mr-2"></span>
                                Generate
                              </button>
                              {/* <div className="col-1 col-md-2 col-sm-1 d-flex justify-content-end s-c-main"> */}
                              <Link
                                to="#"
                                className="zom-img float-right ml-md-5 pl-2 pr-2 mr-0 more-d mt-0 icon_dots2"
                              >
                                <img
                                  src="images/more.png"
                                  className=" img-fluid"
                                  alt="user"
                                />{" "}
                              </Link>
                              {/* </div> */}
                            </li>
                          </ul>
                        </div>
                        <div className="documents_attatchments2 supplier2_sidetoggle mm-blok2 mm-top-new">
                          <div
                            onClick={() =>
                              this.getFinancialLayout("financReportModal")
                            }
                            className="main-sec-attach main-bg"
                          >
                            Reports
                          </div>
                          <div
                            // onClick={() => this.openModal("openReportModal")}
                            onClick={() => this.openModal("openImportModal")}
                            className="main-sec-attach main-bg"
                          >
                            Import
                          </div>
                          <div
                            // onClick={() => this.openModal("openReportModal")}
                            onClick={() => this.exportExcel()}
                            className="main-sec-attach main-bg"
                          >
                            Export
                          </div>
                          <div
                            className="main-sec-attach main-bg"
                            onClick={() =>
                              this.primeImportBudget("openBudgetImportModal")
                            }
                          >
                            <span
                              className="export_crd"
                              data-toggle="collapse"
                              data-target="#export"
                            >
                              {/* <span className="fa fa-angle-up float-left mr-2 sideBarAccord1"></span> */}
                              Budget Import
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <div className="pb-1 table-genrel login_form table-responsive">
              <table
                id="chartofaccounts"
                className="financial_table  table-bordered"
                width="100%"
              >
                <thead>
                  <tr>
                    <th>
                      <div className="custom-radio">
                        <label
                          className="check_main remember_check"
                          htmlFor="customRadio1109"
                        >
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="customRadio1109"
                            name="checkboxAll"
                            checked={this.state.checkAll}
                            onChange={(e) =>
                              this.handleCheckboxes(e, "allCheck")
                            }
                          />
                          <span className="click_checkmark global_checkmark"></span>
                        </label>
                      </div>
                    </th>

                    {this.state.generateCols &&
                      this.state.generateCols?.map((m, i) => {
                        return (
                          <th
                            scope="col"
                            key={i}
                            // className="text-left exp-supplier-th"
                          >
                            <span className="user_setup_hed"> {m.ColName}</span>
                          </th>
                        );
                      })}
                  </tr>
                </thead>
                <tbody>
                  {this.state.generateRows.length &&
                  this.state.generateCols.length > 0 ? (
                    this.state.generateRows?.map((item, i) => {
                      return (
                        <tr>
                          <td>
                            <div className="custom-radio">
                              <label
                                className="check_main remember_check"
                                htmlFor={`accCheck${i}`}
                              >
                                <input
                                  type="checkbox"
                                  // className="custom-control-input"
                                  id={`accCheck${i}`}
                                  name={"checkboxAll"}
                                  checked={item.checked}
                                  onChange={(e) =>
                                    this.handleCheckboxes(e, item)
                                  }
                                  style={{ width: "74%" }}
                                />
                                <span className="click_checkmark"></span>
                              </label>
                            </div>
                          </td>

                          {this.state.generateCols &&
                            this.state.generateCols?.map((c, j) => {
                              let ColType = c.ColType;
                              let isHyperLink = c.IsHyperLink;
                              if (isHyperLink === 1) {
                                return (
                                  <td
                                    className="text-left"
                                    data-order={item.data[j]}
                                    onClick={() =>
                                      this.getFromHyperLink(1928, "Period")
                                    }
                                    style={{ cursor: "pointer" }}
                                  >
                                    <a href="javascript:void(0)">
                                      {item.data[j]}
                                    </a>
                                  </td>
                                );
                              } else if (
                                ColType === "Number" ||
                                ColType === "Numeric"
                              ) {
                                return (
                                  <td
                                    className="text-left"
                                    data-order={item.data[j]}
                                  >
                                    <input
                                      type="number"
                                      name="chartSort"
                                      value={item.data[j]}
                                      onBlur={this.updateFinancials}
                                      className="input_height wd-108"
                                      readOnly={c.Editable ? false : true}
                                      onChange={(e) => {
                                        this.handleChangeGenerateFinancial(
                                          e,
                                          item.data,
                                          i,
                                          j
                                        );
                                      }}
                                    />
                                  </td>
                                );
                              } else if (ColType === "Date") {
                                return (
                                  <>
                                    <td
                                      className="text-left"
                                      data-order={item.data[j]}
                                    >
                                      <input
                                        type="Date"
                                        name="chartSort"
                                        value={item.data[j]}
                                        className="input_height wd-108"
                                        readOnly={
                                          c.Editable === 1 ? true : false
                                        }
                                        onBlur={this.updateFinancials}
                                        onChange={(e) => {
                                          this.handleChangeGenerateFinancial(
                                            e,
                                            item.data,
                                            i,
                                            j
                                          );
                                        }}
                                      />
                                    </td>
                                  </>
                                );
                              } else {
                                return (
                                  <>
                                    <td
                                      className="text-left"
                                      data-order={item.data[j]}
                                    >
                                      <input
                                        type="text"
                                        name="chartSort"
                                        value={item.data[j]}
                                        className="input_height wd-108"
                                        readOnly={c.Editable ? false : true}
                                        onBlur={this.updateFinancials}
                                        onChange={(e) => {
                                          this.handleChangeGenerateFinancial(
                                            e,
                                            item.data,
                                            i,
                                            j
                                          );
                                        }}
                                      />
                                    </td>
                                  </>
                                );
                              }
                            })}
                        </tr>
                      );
                    })
                  ) : (
                    <div className=" w-100 placeHolder ">
                      {/* <div className="col-1 col-md-2 col-sm-1 d-flex justify-content-end s-c-main"> */}
                      <b>
                        {" "}
                        <h5>Press Generate To Create Table</h5>
                      </b>
                      {/* </div> */}
                    </div>
                  )}
                </tbody>
              </table>
            </div>
            {/* <div className="financial-pagination">
              <div className="container-fluid">
                <div className="show-entries">Showing 1 to 3 of 5 entries</div>
                <ul className="pagination ">
                  <li className="page-item">
                    <a className="page-link" href="#">
                      Previous
                    </a>
                  </li>
                  <li className="page-item active">
                    <a className="page-link" href="#">
                      1
                    </a>
                  </li>

                  <li className="page-item">
                    <a className="page-link" href="#">
                      Next
                    </a>
                  </li>
                </ul>
              </div>
            </div> */}
          </div>
        </div>
        <FinancialLayoutModal
          financReportModal={this.state.financReportModal}
          reportTypesVal={this.state.reportTypesVal}
          reportTypeValue={this.state.reportTypeValue}
          reportTypes={this.state.reportTypesModal}
          reportOptions={this.state.reportOptionsModal}
          options={this.state.options}
          layout={this.state.layout}
          columns={this.state.columns}
          categories={this.state.categories}
          clonedOptions={this.state.clonedOptions}
          getLayoutOptions={this.getLayoutOptions}
          state={this.state}
          closeModal={this.closeModal}
        />
        <BudgetImport
          openBudgetImportModal={this.state.openBudgetImportModal}
          state={this.state}
          locationProps={this.props}
          closeModal={this.closeModal}
        />
        <Import
          openImportModal={this.state.openImportModal}
          state={this.state}
          onImport={this.importExcel}
          closeModal={this.closeModal}
        />
        <HyperLinkModal
          openHyperLinkModal={this.state.openHyperLinkModal}
          state={this.state}
          closeModal={this.closeModal}
        />
        ;
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  account: state.account,
});

export default connect(mapStateToProps, {
  getLayoutOptions: AccountActions.getLayoutOptions,
  getFinancialLayout: AccountActions.getFinancialLayout,
  importExcel: AccountActions.importExcel,
  addColumnOptionDetails: AccountActions.addColumnOptionDetails,
  exportExcel: AccountActions.exportExcel,
  primeImportBudget: AccountActions.primeImportBudget,
  generateFinancials: AccountActions.generateFinancials,
  updateFinancials: AccountActions.updateFinancials,
  getFromHyperLink: AccountActions.getFromHyperLink,
})(FinancialReportsWorksheet);

{
  /* <thead>
                  <tr key={"1"}>
                    <th></th>
                    <th colSpan="3" rowSpan="2" className="text-center">
                      <span className="user_setup_hed">Description</span>
                    </th>
                    <th colSpan="4" className="text-center">
                      <span className="user_setup_hed text-center">Actual</span>
                    </th>
                    <th colSpan="2" className="text-center">
                      <span className="user_setup_hed">
                        estimate to complete
                      </span>
                    </th>
                    <th rowSpan="2" className="text-center">
                      <span className="user_setup_hed">Fringe%</span>
                    </th>
                    <th className="text-center">
                      <span className="user_setup_hed">Fringe</span>
                    </th>
                    <th className="text-center">
                      <span className="user_setup_hed">Estimate</span>
                    </th>
                    <th rowSpan="2" className="text-center">
                      <span className="user_setup_hed">Budget</span>
                    </th>
                    <th colSpan="2" className="text-center">
                      <span className="user_setup_hed">Variance</span>
                    </th>
                    <th rowSpan="2" colSpan="3">
                      <span className="user_setup_hed">note</span>
                    </th>
                  </tr>
                  <tr>
                    <th>
                      <span className="user_setup_hed">Lvl</span>
                    </th>
                    <th>
                      <span className="user_setup_hed">period</span>
                    </th>
                    <th>
                      <span className="user_setup_hed">PO</span>
                    </th>
                    <th>unPosted</th>
                    <th>
                      <span>toDate</span>
                    </th>
                    <th>
                      <span className="user_setup_hed">add/sub</span>
                    </th>
                    <th>
                      <span className="user_setup_hed">replace</span>
                    </th>
                    <th>
                      <span className="user_setup_hed">estToComp</span>
                    </th>
                    <th>
                      <span className="user_setup_hed">FinalCost</span>
                    </th>
                    <th>
                      <span className="user_setup_hed">ToDate</span>
                    </th>
                    <th>
                      <span className="user_setup_hed">thisperiod</span>
                    </th>
                  </tr>
                </thead> */
}
{
  /* <tbody> */
}
{
  /* <!-- lv1--> */
}
{
  /* <tr
                    data-toggle="collapse"
                    className="active-row"
                    data-target=".lv-1"
                  >
                    <td> */
}
{
  // (" ");
}
{
  /* <span className="fa fa-angle-down"></span>
                    </td>
                    <td colSpan="3">Travel & living</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td colSpan="3">000.00</td>
                  </tr> */
}

{
  /* <!-- lv2 --> */
}
{
  /* <tr className="collapse lv-1 show">
                    <td className="level-2">5</td>
                    <td colSpan="3">IT infrastructure & date costs</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td colSpan="3">0.00</td>
                  </tr>
                  <tr className="collapse lv-1 show">
                    <td className="level-2">5</td>
                    <td colSpan="3">Hire of avid</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td colSpan="3">0.00</td>
                  </tr> */
}
{
  /* Level 2 stART */
}
{
  /* <tr
                    data-toggle="collapse"
                    className="active-row"
                    data-target=".lv-2"
                  >
                    <td>
                      {" "}
                      <span className="fa fa-angle-down"></span>
                    </td>
                    <td colSpan="3">INTERACTIVE/PRESS ROOM</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td colSpan="3">000.00</td>
                  </tr> */
}

{
  /* <!-- lv2 --> */
}
{
  /* <tr className="collapse lv-2">
                    <td className="level-2"> 5</td>
                    <td colSpan="3">Hire of avid</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td colSpan="3">0.00</td>
                  </tr>
                  <tr className="collapse lv-2">
                    <td className="level-2">5</td>
                    <td colSpan="3">IT infrastructure & date costs</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td colSpan="3">0.00</td>
                  </tr>
                  <tr className="collapse lv-2">
                    <td className="level-2">5</td>
                    <td colSpan="3">Driver for Dainel & party</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td colSpan="3">0.00</td>
                  </tr> */
}
{
  /* Level 3 strat  */
}
{
  /* <tr
                    data-toggle="collapse"
                    className="active-row"
                    data-target=".lv-3"
                  >
                    <td>
                      {" "}
                      <span className="fa fa-angle-down"></span>
                    </td>
                    <td colSpan="3">ADDITIONALS</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td colSpan="3">000.00</td>
                  </tr> */
}

{
  /* <!-- lv2 --> */
}
{
  /* <tr className="collapse lv-3">
                    <td className="level-2"> 5</td>
                    <td colSpan="3">Driver for Dainel & party</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td colSpan="3">0.00</td>
                  </tr>
                  <tr className="collapse lv-3">
                    <td className="level-2">5</td>
                    <td colSpan="3">Hire of avid</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td colSpan="3">0.00</td>
                  </tr> */
}
{
  /* Level 4 start  */
}
{
  /* <tr
                    data-toggle="collapse"
                    className="active-row"
                    data-target=".lv-4"
                  >
                    <td>
                      {" "}
                      <span className="fa fa-angle-down"></span>
                    </td>
                    <td colSpan="3">VEHICHLES & FUEL</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td colSpan="3">000.00</td>
                  </tr> */
}

{
  /* <!-- lv2 --> */
}
{
  /* <tr className="collapse lv-4">
                    <td className="level-2"> 5</td>
                    <td colSpan="3">IT infrastructure & date costs</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td colSpan="3">0.00</td>
                  </tr>
                  <tr className="collapse lv-4">
                    <td className="level-2">5</td>
                    <td colSpan="3">Hire of avid</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td colSpan="3">0.00</td>
                  </tr> */
}
{
  /* Level 5 start  */
}
{
  /* <tr
                    data-toggle="collapse"
                    className="active-row"
                    data-target=".lv-5"
                  >
                    <td>
                      {" "}
                      <span className="fa fa-angle-down"></span>
                    </td>
                    <td colSpan="3">ACCOMODATION</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td colSpan="3">000.00</td>
                  </tr> */
}

{
  /* <!-- lv2 --> */
}
{
  /* <tr className="collapse lv-5">
                    <td className="level-2">5</td>
                    <td colSpan="3">IT infrastructure & date costs</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td colSpan="3">0.00</td>
                  </tr>
                  <tr className="collapse lv-5">
                    <td className="level-2">5</td>
                    <td colSpan="3">Driver for Dainel & party</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td colSpan="3">0.00</td>
                  </tr> */
}
{
  /* Level 6 start  */
}
{
  /* <tr
                    data-toggle="collapse"
                    className="active-row"
                    data-target=".lv-6"
                  >
                    <td>
                      {" "}
                      <span className="fa fa-angle-down"></span>
                    </td>
                    <td colSpan="3">PRODUCTION COST</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td colSpan="3">000.00</td>
                  </tr> */
}

{
  /* <!-- lv2 --> */
}
{
  /* <tr className="collapse lv-6">
                    <td className="level-2"> 5</td>
                    <td colSpan="3">IT infrastructure & date costs</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td colSpan="3">0.00</td>
                  </tr>
                  <tr className="collapse lv-6">
                    <td className="level-2">5</td>
                    <td colSpan="3">Driver for Dainel & party</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td colSpan="3">0.00</td>
                  </tr> */
}
{
  /* Level 7 start  */
}
{
  /* <tr
                    data-toggle="collapse"
                    className="active-row"
                    data-target=".lv-7"
                  >
                    <td>
                      {" "}
                      <span className="fa fa-angle-down"></span>
                    </td>
                    <td colSpan="3">TRAVEL & LIVING</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td colSpan="3">000.00</td>
                  </tr> */
}

{
  /* <!-- lv2 --> */
}
{
  /* <tr className="collapse lv-7">
                    <td className="level-2"> 5</td>
                    <td colSpan="3">IT infrastructure & date costs</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td colSpan="3">0.00</td>
                  </tr>
                  <tr className="collapse lv-7">
                    <td className="level-2">5</td>
                    <td colSpan="3">Driver for Dainel & party</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td>0.00</td>
                    <td colSpan="3">0.00</td>
                  </tr> */
}
{
  /* </tbody> */
}
