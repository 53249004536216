import React, { Component } from "react";
import { Link } from "react-router-dom";
import * as AccountActions from "../../../../Actions/AccountActions/AccountActions";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import {
  addDragAndDropFileListners,
  downloadAttachments,
  handleAPIErr,
  toBase64,
} from "../../../../Utils/Helpers";
class BankAttachments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bankTransactionColumn: [],
      bankTransactionRow: [],
      attachments: [],
      isLoading: false,
      addNewReportToggle: false,
      reportTypesVal: { label: "", value: "" },
      reportTypes: [{ label: "", value: "" }],
      reportOptionVal: { label: "", value: "" },
      reportOptions: [],
      menuIsOpen: false,
      filtersArr: [
        { filter: "category", condition: "equal", value: "settings" },
        { filter: "description", condition: "equal", value: "abc" },
      ],
      fltrsColmnFirst: [
        { id: "1", filter: "category", name: "Category", checked: false },
        { id: "2", filter: "description", name: "Description", checked: false },
        { id: "3", filter: "value", name: "Value", checked: false },
      ],
      fltrsColmnSecond: [
        { id: "4", condition: "Contains", checked: false },
        { id: "5", condition: "Doesn't contain", checked: false },
        { id: "6", condition: "Equal", checked: false },
        { id: "7", condition: "Not Equal", checked: false },
        { id: "8", condition: "Starts With", checked: false },
        { id: "9", condition: "Over", checked: false },
        { id: "10", condition: "Under", checked: false },
        { id: "11", condition: "Over Equal", checked: false },
        { id: "12", condition: "Under Equal", checked: false },
      ],
      reportFile: "",
      reportName: "",
      private: false,

      formErrors: {
        reportTypesVal: "",
        reportOptionVal: "",
        reportFile: "",
        reportName: "",
      },
      attachmentSize: 0,
    };
  }
  async componentDidMount() {
    addDragAndDropFileListners("drop-area-supplier", this.uploadAttachment);
  }
  componentWillReceiveProps(props) {
    let attachments = props?.attachments || [];
    let attachmentSize = props?.attachmentSize || 0;
    this.setState({ attachments, attachmentSize });
  }
  addAttachment = async (fileList) => {
    this.setState({ isLoading: true });
    let data = {
      bankCode: "B1",
      attachment: fileList[0].attachment,
      fileName: fileList[0].fileName,
    };
    await this.props.addBankRecAttachment(data);
    // if (fileList.length > 1) {
    //   let data = {
    //     bankCode: "B1",
    //     ...fileList,
    //   };
    //   //order --> you can add multiple attachments at a time
    //   await this.props.addBankRecAttachment(data);
    // } else {
    //   let data = {
    //     bankCode: "B1",
    //     attachment: fileList[0].attachment,
    //     fileName: fileList[0].fileName,
    //   };
    //   await this.props.addBankRecAttachment(data);
    // }
    if (this.props.account.addBankRecAttachmentSuccess) {
      toast.success(this.props.account.addBankRecAttachmentSuccess);

      let attachments = this?.props?.account?.addBankRecAttachment;
      await this.props.getBankRecAttachments1();
      this.setState({ attachments });
    }
    if (this.props.account.addBankRecAttachmentError) {
      handleAPIErr(this.props.account.addBankRecAttachmentError, this.props);
    }
    this.setState({ isLoading: false });
  };

  uploadAttachment = async (attachments) => {
    /*
    A user cannot have greater than 29.5MB of attachments 
    in one invoice/po/expenses/documents transaction. 
  */

    let fileList = [];
    let attachmentSize = this.state.attachmentSize || 0;
    for (let i = 0; i < attachments.length; i++) {
      let type = attachments[0].type;
      let file = attachments[0];
      let size = attachments[0].size;
      let name = attachments[0].name;

      if (
        type == "application/pdf" ||
        type ==
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
        type == "image/jpeg" ||
        type == "image/jpg" ||
        type == "image/png" ||
        type == "application/msword" ||
        type == "application/vnd.ms-excel" ||
        type ==
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
        type == "application/vnd.ms-powerpoint" ||
        type == "text/csv"
      ) {
        if (size <= 10485760) {
          //10MB = 10485760 Bytes

          if (attachmentSize < 30932992) {
            //30932992  -> 29.5 MB
            if (Number(size) + Number(attachmentSize) < 30932992) {
              const result = await toBase64(file).catch((e) => e);
              if (result instanceof Error) {
                toast.error(result.message);
                return;
              } else {
                fileList.push({
                  fileName: name,
                  attachment: result.split(",")[1],
                });
                await this.addAttachment(fileList);
              }
            } else {
              let remaining_KBs = (30932992 - attachmentSize) / 1024; //available space

              remaining_KBs = Number(remaining_KBs).toFixed(2);

              toast.error(
                `You can upload a file of size ${remaining_KBs}KB, Attachmnents limit 29.5MB.`
              );
            }
          } else {
            toast.error(
              "You can't add more attachments. Attachments limit 29.5MB! "
            );
          }
        } else {
          toast.error(
            "This file exceeds the 10MB limit. Please upload a smaller file."
          );
        }
      } else {
        toast.error(
          "Please Select only Attachments of type: 'pdf', 'docx', 'CSV', '.xls', '.xlsx', 'spreadsheets' or 'images'"
        );
      }
    }

    // if (attachments.length === fileList.length) {
    //   await this.addAttachment(fileList);
    // }
  };

  getBankRecAttachment = async (recordID, fileName, type) => {
    this.setState({ isLoading: true });
    await this.props.getBankRecAttachment(recordID);
    if (this.props.account.getBankRecAttachmentSuccess) {
      toast.success(this.props.account.getBankRecAttachmentSuccess);
      let attachment =
        this?.props?.account?.getBankRecAttachment?.attachment || [];
      let fileName = this?.props?.account?.getBankRecAttachment?.fileName || [];
      if (type === "PDF") {
        type = "application/pdf";
      }
      let resp = {
        attachment: attachment,
        contentType: type,
        fileName: fileName,
      };
      downloadAttachments(resp, fileName);
    }
    if (this.props.account.getBankRecAttachmentError) {
      handleAPIErr(this.props.account.getBankRecAttachmentError, this.props);
    }
    this.setState({ isLoading: false });
  };
  deleteBankRecAttachment = async (recordID) => {
    this.setState({ isLoading: true });
    await this.props.deleteBankRecAttachment(recordID);
    if (this.props.account.deleteBankRecAttachmentSuccess) {
      toast.success(this.props.account.deleteBankRecAttachmentSuccess);
      await this.props.getBankRecAttachments1();
    }
    if (this.props.account.deleteBankRecAttachmentError) {
      handleAPIErr(this.props.account.deleteBankRecAttachmentError, this.props);
    }
    this.setState({ isLoading: false });
  };
  handleChangeGeneralLedgerList = (e, data, i, j) => {
    data[j] = e.target.value;
  };
  render() {
    let title = `Will you want to read this document? \n So Click on icon or fileName: `;

    return (
      <>
        {this.state.isLoading ? <div className="se-pre-con"></div> : ""}
        {/* Attachments */}
        <div className="forgot_form_main sup-inner-pad position-relative">
          <div className="forgot_header mt-4">
            <div className="modal-top-header">
              <div className="row bord-btm">
                <div className="col-auto pl-0">
                  <h6 className="text-left def-blue">
                    <span>
                      {" "}
                      <img
                        src="images/arrow_up.png"
                        className="import_icon img-fluid pr-3 ml-3 sideBarAccord"
                        alt="arrow_up"
                        data-toggle="collapse"
                        data-target="#attachments"
                      />{" "}
                    </span>
                    Attachment
                  </h6>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-12">
              <div className="collapse show" id="expenseAttachments">
                <div className="forgot_body collapse show" id="attachments">
                  <div className="col-12 mt-2">
                    <div className="form-group custon_select">
                      <div
                        id="drop-area-supplier"
                        className="exp_drag_area"
                        title={`Drag files \nor click to upload`}
                      >
                        <input
                          type="file"
                          id="fileElem-sup"
                          className="form-control d-none"
                          accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint
                                    , application/pdf, image/jpeg,image/jpg,image/png,
                                     .csv, .xlsx, .xls,
                                     application/vnd.openxmlformats-officedocument.wordprocessingml.document,
                                     application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                          onChange={(e) => {
                            this.uploadAttachment(e.target.files);
                          }}
                          onClick={(event) => {
                            event.currentTarget.value = null;
                          }} //to upload the same file again
                          multiple={true}
                        />

                        <label className="upload-label" htmlFor="fileElem-sup">
                          <div className="upload-text">
                            <img
                              src="images/drag-file.png"
                              className="import_icon img-fluid"
                              alt="upload-attachment"
                            />
                          </div>
                        </label>
                      </div>

                      <div className="exp_upload_files">
                        <ul>
                          {this.state.attachments.map((a, i) => {
                            return (
                              <li
                                className={"blue_li  cursorPointer"}
                                key={a.recordID}
                                title={`${title} ${a.fileName}`}
                              >
                                <span
                                  className="fa fa-file"
                                  title={`${title} ${a.fileName}`}
                                  onClick={() =>
                                    this.getBankRecAttachment(
                                      a.recordID,
                                      a.fileName,
                                      a.type
                                    )
                                  }
                                ></span>
                                <p
                                  style={{ color: "#30679A " }}
                                  onClick={() =>
                                    this.getBankRecAttachment(
                                      a.recordID,
                                      a.fileName,
                                      a.type
                                    )
                                  }
                                  title={`${title} ${a.fileName}`}
                                >
                                  {" "}
                                  {a.fileName || ""}
                                </p>
                                <span
                                  onClick={() => {
                                    this.deleteBankRecAttachment(a.recordID);
                                  }}
                                  className="cursorPointer fa fa-times"
                                  title={`Do you really wanna delete ${a.fileName}?`}
                                ></span>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  account: state.account,
  chart: state.chart,
});

export default connect(mapStateToProps, {
  addBankRecAttachment: AccountActions.addBankRecAttachment,
  getBankRecAttachment: AccountActions.getBankRecAttachment,
  getBankRecAttachments: AccountActions.getBankRecAttachments,
  deleteBankRecAttachment: AccountActions.deleteBankRecAttachment,
})(BankAttachments);
