import React, { Component } from "react";
import { connect } from "react-redux";
import Select from "react-select";
import { toast } from "react-toastify";
import Modal from "react-bootstrap/Modal";
import { _customStyles } from "../../../../Constants/Constants";
import DatePicker from "react-datepicker";
import $ from "jquery";
import { Link } from "react-router-dom";
import "./BudgetImport.css";
import {
  tableSetting,
  handleHideUnhideRows,
  handleValueOptions,
  handleAPIErr,
  toBase64,
} from "../../../../Utils/Helpers";
import * as AccountActions from "../../../../Actions/AccountActions/AccountActions";
const uuidv1 = require("uuid/v1");
class BudgetImport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      advancedList: [],
      importsList: [],
      budgetImportCols: [],
      budgetImportRows: [],
      columns: [],
      clonedAdvancedList: [],
      showHiddenRows: false,
      attachment: "",
    };
  }
  async componentDidMount() {
    // await this.primeImportBudget();
  }
  componentWillReceiveProps() {
    let {
      advancedList1,
      importsList,
      budgetImportCols,
      budgetImportRows,
      columns,
    } = this.props.state;

    this.setState({
      importsList,
      budgetImportCols,
      budgetImportRows,
      columns,
      advancedList: advancedList1,
      clonedAdvancedList: advancedList1,
    });
  }
  importBudget = async () => {
    this.setState({ isLoading: true });
    let data = {
      importOptions: this.state.advancedList,
      attachment: this.state.attachment,
    };
    await this.props.importBudget(data);
    if (this.props.account.importBudgetSuccess) {
      toast.success(this.props.account.importBudgetSuccess);
      let advancedList = this.props?.account.importBudget?.importOptions || [];
      let importsList = this.props?.account.importBudget?.imports || [];
      advancedList.map((lst, i) => {
        if (
          (lst.valueType && lst.valueType.toLowerCase() === "list") ||
          "droplist"
        ) {
          let valOptns = [];
          if (lst.options && lst.options.length > 0) {
            lst.options.map((o, i) => {
              valOptns.push({ label: o.value, value: o.value });
            });
          }
          lst.options = valOptns;
        }
        lst.id = uuidv1();
        lst.hide = false;
        return lst;
      });

      let budgetImportCols = importsList[0].Columns || [];

      let budgetImportRows = importsList[0].Rows || [];
      importsList.map((lst) => (lst.checked = false));
      let columns = [];
      //adding the column names
      columns[0] = { name: "ID" };
      columns[1] = { name: "Chart Sort" };
      columns[2] = { name: "Chart Code" };
      columns[3] = { name: "Description" };
      columns[4] = { name: "Level" };
      columns[5] = { name: "Post" };
      columns[6] = { name: "Est Final Cost" };
      columns[7] = { name: "Budget" };
      columns[8] = { name: "Duplicate" };

      this.setState(
        {
          advancedList,
          clonedAdvancedList: advancedList,
          importsList,
          budgetImportCols,
          budgetImportRows,
          isLoading: false,
          columns,
        },
        () => {
          // this.tableSetting();
        }
      );
    }
    if (this.props.account.importBudgetError) {
      handleAPIErr(this.props.account.importBudgetError, this.props);
    }
    this.setState({ isLoading: false });
  };
  updateBudgetImport = async () => {
    this.setState({ isLoading: true });
    let data = {
      imports: [
        {
          tableId: this.state.importsList[0].tableId || "",
          parentTableId: this.state.importsList[0].parentTableId || "",
          sort: this.state.importsList[0].sort || "",
          maximumRows: this.state.importsList[0].maximumRows,
          Columns: this.state.budgetImportCols,
          Rows: this.state.budgetImportRows,
        },
      ],
    };
    await this.props.updateBudgetImport(data);
    if (this.props.account.updateBudgetImportSuccess) {
      toast.success(this.props.account.updateBudgetImportSuccess);
    }
    if (this.props.account.updateBudgetImportError) {
      handleAPIErr(this.props.account.updateBudgetImportError, this.props);
    }
    this.setState({ isLoading: false });
  };
  // handleChangeSettings = (e, i) => {
  //   const { name, value } = e.target;
  //   if (name === "pageLength") {
  //     this.setState({ pageLength: value });
  //   } else {
  //     let { columns } = this.state;
  //     columns[i].hide = e.target.checked;
  //     this.setState({ columns });
  //   }
  // };

  //Advanced List Setting
  _handleValueOptions = async (type, val, item, index) => {
    let { advancedList, clonedAdvancedList } = this.state;
    let result = handleValueOptions(
      type,
      val,
      item,
      index,
      advancedList,
      clonedAdvancedList
    );
    this.setState((prev) => ({
      ...prev,
      advancedList: result.advancedList,
      clonedAdvancedList: result.clonedAdvancedList,
    }));
  };
  //Hide/Unhide Rows
  _handleHideUnhideRows = (item) => {
    let { advancedList, clonedAdvancedList, showHiddenRows } = this.state;

    let result = handleHideUnhideRows(
      item,
      "#reportv4-table",
      "reports__settings",
      advancedList,
      clonedAdvancedList,
      showHiddenRows
    );

    this.setState((prev) => ({
      ...prev,
      advancedList: result.advancedList,
      clonedAdvancedList: result.clonedAdvancedList,
      showHiddenRows: result.showHiddenRows,
      reDrawDT: true,
    }));
  };
  handleShowHiddenRows = async () => {
    let table = window.$("#reportv4-table").DataTable();
    table.destroy();

    let { advancedList, clonedAdvancedList, showHiddenRows } = this.state;

    showHiddenRows = !showHiddenRows;

    if (showHiddenRows) {
      //show hidden rows
      advancedList = clonedAdvancedList;
    } else {
      //hide again hidden rows
      let list = advancedList.filter((l) => !l.hide);
      advancedList = list;
    }

    this.setState((prev) => ({
      ...prev,
      advancedList,
      showHiddenRows,
      reDrawDT: true, //to re daraw the datatable
    }));
  };
  handleChangeGeneralLedgerList = (e, data, i, j) => {
    let { journalLines } = this.state;
    data[j] = e.target.value;

    this.setState({ journalLines });
  };
  uploadAttachment = async (attachments) => {
    /*
    A user cannot have greater than 29.5MB of attachments 
    in one invoice/po/expenses/documents transaction. 
  */

    let fileList = [];
    let attachmentSize = this.state.attachmentSize || 0;
    for (let i = 0; i < attachments.length; i++) {
      let type = attachments[0].type;
      let file = attachments[0];
      let size = attachments[0].size;
      let name = attachments[0].name;

      if (
        type == "application/pdf" ||
        type ==
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
        type == "image/jpeg" ||
        type == "image/jpg" ||
        type == "image/png" ||
        type == "application/msword" ||
        type == "application/vnd.ms-excel" ||
        type ==
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
        type == "application/vnd.ms-powerpoint" ||
        type == "text/csv"
      ) {
        if (size <= 10485760) {
          //10MB = 10485760 Bytes

          if (attachmentSize < 30932992) {
            //30932992  -> 29.5 MB
            if (Number(size) + Number(attachmentSize) < 30932992) {
              const result = await toBase64(file).catch((e) => e);
              if (result instanceof Error) {
                toast.error(result.message);
                return;
              } else {
                fileList.push({
                  fileName: name,
                  attachment: result.split(",")[1],
                });
                this.setState({ attachment: result.split(",")[1] });
              }
            } else {
              let remaining_KBs = (30932992 - attachmentSize) / 1024; //available space

              remaining_KBs = Number(remaining_KBs).toFixed(2);

              toast.error(
                `You can upload a file of size ${remaining_KBs}KB, Attachmnents limit 29.5MB.`
              );
            }
          } else {
            toast.error(
              "You can't add more attachments. Attachments limit 29.5MB! "
            );
          }
        } else {
          toast.error(
            "This file exceeds the 10MB limit. Please upload a smaller file."
          );
        }
      } else {
        toast.error(
          "Please Select only Attachments of type: 'pdf', 'docx', 'CSV', '.xls', '.xlsx', 'spreadsheets' or 'images'"
        );
      }
    }

    // if (attachments.length === fileList.length) {
    //   await this.addAttachment(fileList);
    // }
  };
  clearStates = () => {
    this.props.closeModal("openBudgetImportModal");
  };
  threeDotMenu = () => {
    $(".mm-blok21").toggleClass("doc_sidebar2");
  };

  render() {
    return (
      <>
        {this.state.isLoading ? <div className="se-pre-con"></div> : ""}

        <Modal
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={this.props.openBudgetImportModal}
          onHide={this.clearStates}
          className="mm_report_new_modal mx-auto reports-v4-modal"
        >
          <Modal.Body>
            <div className="container-fluid p-0">
              <div className="main_wrapper">
                <div className="row d-flex h-100 p-0">
                  <div className="col-12 justify-content-center align-self-center">
                    <div className="setting_form_main p-0">
                      <div className="setting_header thead_bg">
                        <h3 className="Indirecttaxcode-poup_heading">
                          Budget Import
                        </h3>
                      </div>
                      <div className="reportv4-modal-inner">
                        <div className="row">
                          <div className="col-xl-6 col-md-6 mob-order2">
                            <div className="user_setup_popup__table new_advance_table m-auto p-3">
                              <h2>Advanced</h2>
                              <table className="table" id="usersteup-modal">
                                <thead className="thead_bg thead_bg_sass hover-border">
                                  <tr>
                                    <th scope="col">
                                      <span className="user_setup_hed">
                                        Category
                                      </span>
                                    </th>
                                    <th scope="col">
                                      <span className="user_setup_hed">
                                        Description
                                      </span>
                                    </th>
                                    <th
                                      scope="col"
                                      className="value__field--wrapperuser"
                                    >
                                      <span className="user_setup_hed">
                                        Value
                                      </span>
                                    </th>
                                    <th
                                      scope="col"
                                      className="text__right__contentuser"
                                    >
                                      <span className="user_setup_hed">
                                        Hide
                                      </span>
                                    </th>
                                    <th className="table__inner--th">
                                      <div className="dropdown">
                                        <button
                                          aria-haspopup="true"
                                          aria-expanded="true"
                                          id=""
                                          type="button"
                                          className="dropdown-toggle btn dept-tbl-menu "
                                          data-toggle="dropdown"
                                        >
                                          <span className="fa fa-bars "></span>
                                        </button>
                                        <div className="dropdown-menu dept-menu-list dropdown-menu-right">
                                          <div className="pr-0 dropdown-item">
                                            <div className="form-group remember_check mm_check4">
                                              <input
                                                type="checkbox"
                                                id="showHiddenRows"
                                                name="showHiddenRows"
                                                checked={
                                                  this.state.showHiddenRows
                                                }
                                                onClick={
                                                  this.handleShowHiddenRows
                                                }
                                                onChange={() => {}}
                                              />
                                              <label
                                                htmlFor="showHiddenRows"
                                                className="mr-0"
                                              >
                                                Show Hidden Rows
                                              </label>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.state.advancedList.map((list, i) => {
                                    return (
                                      <tr key={i}>
                                        <td className=" ">{list.category}</td>
                                        <td>{list.description}</td>
                                        {list.valueType === "List" ? (
                                          <td className="pt-0 pb-0 text-left">
                                            <Select
                                              classNamePrefix="custon_select-selector-inner main__dropdown--wrapper1"
                                              styles={_customStyles}
                                              value={{
                                                label: list.value,
                                                value: list.value,
                                              }}
                                              options={list.options}
                                              onChange={(obj) =>
                                                this._handleValueOptions(
                                                  "list",
                                                  obj,
                                                  list,
                                                  i
                                                )
                                              }
                                              theme={(theme) => ({
                                                ...theme,
                                                border: 0,
                                                borderRadius: 0,
                                                colors: {
                                                  ...theme.colors,
                                                  primary25: "#f2f2f2",
                                                  primary: "#f2f2f2",
                                                },
                                              })}
                                            />
                                          </td>
                                        ) : list.valueType === "MultiList" ? (
                                          <td className="pt-0 pb-0 text-left">
                                            <Select
                                              className="basic-multi-select main__dropdown--wrapper1"
                                              name="multiple-op"
                                              // classNamePrefix="custon_select-selector-inner"
                                              defaultValue={{
                                                label: "Select Approval Group",
                                                value: "",
                                              }}
                                              value={list.multiValue || []}
                                              options={list.valueOptions}
                                              isClearable={false}
                                              onChange={(obj) =>
                                                this._handleValueOptions(
                                                  "multiList",
                                                  obj,
                                                  list,
                                                  i
                                                )
                                              }
                                              isMulti
                                              theme={(theme) => ({
                                                ...theme,
                                                border: 0,
                                                borderRadius: 0,
                                                colors: {
                                                  ...theme.colors,
                                                  primary25: "#f2f2f2",
                                                  primary: "#f2f2f2",
                                                },
                                              })}
                                            />
                                          </td>
                                        ) : list.valueType === "Date" ? (
                                          <td>
                                            <div className="table_input_field">
                                              <DatePicker
                                                selected={Number(list.value)}
                                                dateFormat="d MMM yyyy"
                                                autoComplete="off"
                                                onChange={(date) =>
                                                  this._handleValueOptions(
                                                    "date",
                                                    date,
                                                    list,
                                                    i
                                                  )
                                                }
                                              />
                                            </div>
                                          </td>
                                        ) : list.valueType === "Check" ? (
                                          <td>
                                            <div className="col-auto p-0">
                                              <div className="form-group remember_check  text-center pt-0 float-left">
                                                <input
                                                  type="checkbox"
                                                  id={`chk${i}`}
                                                  checked={
                                                    list.value === "Y" ||
                                                    list.value === "1"
                                                      ? true
                                                      : false
                                                  }
                                                  onChange={(e) =>
                                                    this._handleValueOptions(
                                                      "checkbox",
                                                      e,
                                                      list,
                                                      i
                                                    )
                                                  }
                                                />
                                                <label
                                                  htmlFor={`chk${i}`}
                                                ></label>
                                              </div>
                                            </div>
                                          </td>
                                        ) : list.valueType === "Numeric" ||
                                          list.valueType === "Number" ? (
                                          <td>
                                            <div className="table_input_field">
                                              <input
                                                type="number"
                                                value={list.value}
                                                onChange={(e) =>
                                                  this._handleValueOptions(
                                                    "number",
                                                    e,
                                                    list,
                                                    i
                                                  )
                                                }
                                              />
                                            </div>
                                          </td>
                                        ) : list.valueType === "Text" ? (
                                          <td>
                                            <div className="table_input_field">
                                              <input
                                                type="text"
                                                value={list.value}
                                                onChange={(e) =>
                                                  this._handleValueOptions(
                                                    "text",
                                                    e,
                                                    list,
                                                    i
                                                  )
                                                }
                                              />
                                            </div>
                                          </td>
                                        ) : (
                                          <td>{list.value}</td>
                                        )}
                                        <td className="text__right--user">
                                          <div className="custom-radio">
                                            <label
                                              className="check_main  remember_check"
                                              htmlFor={`hideUnhideRows${i}`}
                                            >
                                              <input
                                                type="checkbox"
                                                className="custom-control-input"
                                                name={"hideUnhideRows"}
                                                id={`hideUnhideRows${i}`}
                                                checked={false}
                                                onChange={(e) =>
                                                  this._handleHideUnhideRows(
                                                    list
                                                  )
                                                }
                                              />

                                              {/* <span className='click_checkmark'></span> */}
                                              <span
                                                // className={
                                                //   list.hide
                                                //     ? "dash_checkmark bg_clr"
                                                //     : "dash_checkmark"
                                                // }

                                                className={
                                                  list.hide
                                                    ? "dash_checkmark bg_clr "
                                                    : "dash_checkmark"
                                                }
                                              ></span>
                                            </label>
                                          </div>
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </table>
                            </div>
                          </div>
                          <div className="col-xl-6 col-md-6 justify-content-end mob-order1">
                            <div className="user_setup_plus_Icons">
                              <ul>
                                <li>
                                  {/* <div className="col-1 col-md-2 col-sm-1 d-flex justify-content-end s-c-main"> */}
                                  <Link
                                    to="#"
                                    onClick={this.threeDotMenu}
                                    className="zom-img float-right ml-md-5 pl-2 pr-2 mr-0 more-d mt-0 icon_dots20"
                                  >
                                    <img
                                      src="images/more.png"
                                      className=" img-fluid"
                                      alt="user"
                                    />{" "}
                                  </Link>
                                  {/* </div> */}
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="documents_attatchments2 supplier2_sidetoggle mm-blok21 ">
                            <div
                              onClick={() =>
                                this.importBudget("financReportModal")
                              }
                              className="main-sec-attach main-bg"
                            >
                              Import
                            </div>
                            <div
                              // onClick={() => ()}
                              className="main-sec-attach main-bg"
                            >
                              Upload
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12">
                            <div
                              className="collapse show"
                              id="expenseAttachments"
                            >
                              <div
                                className="forgot_body collapse show"
                                id="attachments"
                              >
                                <div className="col-12 mt-2">
                                  <div className="form-group custon_select">
                                    <div
                                      id="drop-area-supplier"
                                      className="exp_drag_area"
                                    >
                                      <input
                                        type="file"
                                        id="fileElem-sup"
                                        className="form-control d-none"
                                        accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint
                                    , application/pdf, image/jpeg,image/jpg,image/png,
                                     .csv, .xlsx, .xls,
                                     application/vnd.openxmlformats-officedocument.wordprocessingml.document,
                                     application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                        onChange={(e) => {
                                          this.uploadAttachment(e.target.files);
                                        }}
                                        onClick={(event) => {
                                          event.currentTarget.value = null;
                                        }} //to upload the same file again
                                      />

                                      <label
                                        className="upload-label"
                                        htmlFor="fileElem-sup"
                                      >
                                        <div className="upload-text">
                                          <img
                                            src="images/drag-file.png"
                                            className="import_icon img-fluid"
                                            alt="upload-attachment"
                                          />
                                        </div>
                                      </label>
                                    </div>

                                    <div className="exp_upload_files">
                                      <ul>
                                        {this.state.attachments?.map((a, i) => {
                                          return (
                                            <li className={"blue_li"}>
                                              <span className="fa fa-file"></span>
                                              <p
                                                className="cursorPointer"
                                                onClick={() =>
                                                  this.getAttachment(
                                                    a.recordID,
                                                    a.fileName
                                                  )
                                                }
                                              >
                                                {" "}
                                                {a.fileName || ""}
                                              </p>
                                              <span
                                                onClick={() => {
                                                  this.deleteAttachment(
                                                    a.recordID
                                                  );
                                                }}
                                                className="cursorPointer fa fa-times"
                                              ></span>
                                            </li>
                                          );
                                        })}
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="reportv4-modal-inner">
                          <div className="row">
                            <div class="col-12 col-lg-3 col-md-4 pl-md-0 mt-4 mb-4 ml-3">
                              <div class="d-flex justify-content-center h-100">
                                <div class="searchbar mm-searchbar_new">
                                  <input
                                    class="search_input"
                                    type="text"
                                    name="detailsListSearch"
                                    placeholder="Search..."
                                    value=""
                                  />
                                  <a
                                    href="javascript:void(0)"
                                    class="search_icon search-boredr"
                                  >
                                    <i class="fa fa-search"></i>
                                  </a>
                                  <a
                                    href="javascript:void(0)"
                                    class="search_icon"
                                    id="filter_dropdown"
                                  >
                                    <i class="fa fa-filter"></i>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <body>
                          <div className="pb-1 login_form table-genrel">
                            <table
                              id="chartofaccounts"
                              className=" user_setup_table mm-chart2"
                              width="100%"
                            >
                              <thead>
                                <tr>
                                  <th>
                                    <div className="custom-radio">
                                      <label
                                        className="check_main remember_check"
                                        htmlFor="customRadio1109"
                                      >
                                        <input
                                          type="checkbox"
                                          className="custom-control-input"
                                          id="customRadio1109"
                                          name="all"
                                          checked={this.state.checkAll}
                                          onChange={(event) =>
                                            this.handleMultipleCheckBox(
                                              event,
                                              "all"
                                            )
                                          }
                                        />
                                        <span className="click_checkmark global_checkmark"></span>
                                      </label>
                                    </div>
                                  </th>

                                  {this.state.budgetImportCols?.map((m, i) => {
                                    return (
                                      <th
                                        scope="col"
                                        key={i}
                                        className="text-left exp-supplier-th"
                                      >
                                        <span className="user_setup_hed">
                                          {" "}
                                          {m.ColName}
                                        </span>
                                      </th>
                                    );
                                  })}

                                  <th className="text-right">
                                    <span
                                      className="user_setup_hed2"
                                      onClick={() =>
                                        this.openModal("openSettingsModal")
                                      }
                                    >
                                      {" "}
                                      <img
                                        src="./images/user-setup/bars.png"
                                        alt="bars"
                                      ></img>
                                    </span>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.budgetImportRows?.map((item, i) => {
                                  return (
                                    <tr>
                                      {item.data === null ? (
                                        ""
                                      ) : (
                                        <td>
                                          <div className="custom-radio">
                                            <label
                                              className="check_main remember_check"
                                              htmlFor={`accCheck${i}`}
                                            >
                                              <input
                                                type="checkbox"
                                                // className="custom-control-input"
                                                id={`accCheck${i}`}
                                                name={"accListCheck"}
                                                // checked={item.checked}
                                                onChange={(event) =>
                                                  this.handleMultipleCheckBox(
                                                    event,
                                                    item,
                                                    i
                                                  )
                                                }
                                                style={{ width: "74%" }}
                                              />
                                              <span className="click_checkmark"></span>
                                            </label>
                                          </div>
                                        </td>
                                      )}

                                      {this.state.budgetImportCols?.map(
                                        (c, j) => {
                                          let ColType = c.ColType;
                                          if (
                                            ColType === "Number" ||
                                            ColType === "Numeric"
                                          ) {
                                            return (
                                              <>
                                                {item.data === null ? (
                                                  ""
                                                ) : (
                                                  <td
                                                    className="text-left"
                                                    data-order={item.data[j]}
                                                  >
                                                    <input
                                                      type="number"
                                                      name="chartSort"
                                                      value={item.data[j]}
                                                      onBlur={() =>
                                                        this.updateBudgetImport()
                                                      }
                                                      className="input_height wd-10"
                                                      readOnly={
                                                        c.Editable
                                                          ? false
                                                          : true
                                                      }
                                                      style={{ width: "70%" }}
                                                      onChange={(e) => {
                                                        this.handleChangeGeneralLedgerList(
                                                          e,
                                                          item.data,
                                                          i,
                                                          j
                                                        );
                                                      }}
                                                    />
                                                  </td>
                                                )}
                                              </>
                                            );
                                          } else if (ColType === "Date") {
                                            return (
                                              <>
                                                {item.data === null ? (
                                                  ""
                                                ) : (
                                                  <td
                                                    className="text-left"
                                                    data-order={item.data[j]}
                                                  >
                                                    <input
                                                      type="Date"
                                                      name="chartSort"
                                                      value={item.data[j]}
                                                      // className="input_height wd-10"
                                                      readOnly={
                                                        c.Editable
                                                          ? false
                                                          : true
                                                      }
                                                      style={{
                                                        width: "70%",
                                                      }}
                                                      onBlur={() =>
                                                        this.updateBudgetImport()
                                                      }
                                                      onChange={(e) => {
                                                        this.handleChangeGeneralLedgerList(
                                                          e,
                                                          item.data,
                                                          i,
                                                          j
                                                        );
                                                      }}
                                                    />
                                                  </td>
                                                )}
                                              </>
                                            );
                                          } else {
                                            return (
                                              <>
                                                {item.data === null ? (
                                                  ""
                                                ) : (
                                                  <td
                                                    className="text-left"
                                                    data-order={item.data[j]}
                                                  >
                                                    <input
                                                      type="text"
                                                      name="chartSort"
                                                      value={item.data[j]}
                                                      className="wd-5"
                                                      style={{
                                                        width: "74%",
                                                      }}
                                                      readOnly={
                                                        c.Editable
                                                          ? false
                                                          : true
                                                      }
                                                      onBlur={() =>
                                                        this.updateBudgetImport()
                                                      }
                                                      onChange={(e) => {
                                                        this.handleChangeGeneralLedgerList(
                                                          e,
                                                          item.data,
                                                          i,
                                                          j
                                                        );
                                                      }}
                                                    />
                                                  </td>
                                                )}
                                              </>
                                            );
                                          }
                                        }
                                      )}
                                      <td
                                        className="text-left"
                                        data-order={""}
                                      ></td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                        </body>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}
const mapStateToProps = (state) => ({ account: state.account });

export default connect(mapStateToProps, {
  importBudget: AccountActions.importBudget,
  updateBudgetImport: AccountActions.updateBudgetImport,
})(BudgetImport);
