import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import Select from "react-select";
import { _customStyles } from "../../../Constants/Constants";
import DatePicker from "react-datepicker";
import { connect } from "react-redux";
import * as PaymentActions from "../../../Actions/PaymentActions/PaymentActions";
import {
  handleAPIErr,
  handleHideUnhideRows,
  handleValueOptions,
} from "../../../Utils/Helpers";
const uuidv1 = require("uuid/v1");

class EFTFile extends Component {
  constructor() {
    super();
    this.state = {
      advancedOptions: [],
      clonneAdvanceOptions: [],
      showHiddenRows: false,
      reDrawDT: false, //re draw datatable

      formErrors: {
        reportTypesVal: "",
        reportOptionVal: "",
        reportFile: "",
        reportName: "",
      },
    };
  }

  componentWillReceiveProps() {
    let advancedOptions = this.props.advancedOptions;
    let clonneAdvanceOptions = this.props.clonneAdvanceOptions;
    this.setState({ advancedOptions, clonneAdvanceOptions });
  }

  _handleValueOptions = async (type, val, item, index) => {
    let { advancedOptions, clonneAdvanceOptions } = this.state;
    let result = handleValueOptions(
      type,
      val,
      item,
      index,
      advancedOptions,
      clonneAdvanceOptions
    );
    this.setState({
      ...this.state,
      advancedOptions: result.advancedList,
      clonneAdvanceOptions: result.clonedAdvancedList,
    });
  };

  _handleHideUnhideRows = (item) => {
    let { advancedOptions, clonneAdvanceOptions, showHiddenRows } = this.state;

    let result = handleHideUnhideRows(
      item,
      "#reportv4-table",
      "reports__settings",
      advancedOptions,
      clonneAdvanceOptions,
      showHiddenRows
    );

    this.setState({
      advancedOptions: result.advancedList,
      clonneAdvanceOptions: result.clonedAdvancedList,
      showHiddenRows: result.showHiddenRows,
      reDrawDT: true,
    });
  };

  handleShowHiddenRows = async () => {
    let table = window.$("#reportv4-table").DataTable();
    table.destroy();

    let { advancedOptions, clonneAdvanceOptions, showHiddenRows } = this.state;

    showHiddenRows = !showHiddenRows;

    if (showHiddenRows) {
      //show hidden rows
      advancedOptions = clonneAdvanceOptions;
    } else {
      //hide again hidden rows
      let list = advancedOptions.filter((l) => !l.hide);
      advancedOptions = list;
    }

    this.setState({
      advancedOptions,
      showHiddenRows,
      reDrawDT: true, //to re daraw the datatable
    });
  };

  // handleReportTypes = (type) => {
  //   let { formErrors } = this.state;

  //   formErrors = Validation.handleValidation(
  //     "reportTypesVal",
  //     type.value,
  //     formErrors
  //   );

  //   let value = type.value || "";

  //   let getReportOptions = this.state.reportOptions || [];
  //   let reportOptions = [];

  //   let found = getReportOptions.find((rop) => rop.id === value);

  //   if (found) {
  //     // let reportLayouts = found || [];

  //     reportOptions.push({
  //       label: found.layout,
  //       value: found.id,
  //       selected: 1,
  //     });
  //   }
  //   this.setState({
  //     reportTypesVal: type,
  //     reportOptionVal: reportOptions,
  //     formErrors,
  //   });
  // };

  render() {
    return (
      <>
        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={this.props.openEftFileModal}
          onHide={() => this.props.closeModal("openEftFileModal")}
          className="forgot_email_modal modal_704 mx-auto"
        >
          <Modal.Body>
            <div className="container-fluid ">
              <div className="main_wrapper">
                <div className="row d-flex h-100">
                  <div className="col-12 justify-content-center align-self-center form_mx_width">
                    <div className="forgot_form_main">
                      <div className="forgot_header">
                        <div className="modal-top-header">
                          <div className="row bord-btm">
                            <div className="col-auto pl-0">
                              <h6 className="text-left def-blue">EFT File</h6>
                            </div>

                            <div className="col d-flex justify-content-end s-c-main">
                              <button
                                onClick={() =>
                                  this.props.closeModal("openEftFileModal")
                                }
                                type="button"
                                className="btn-save"
                              >
                                <span className="fa fa-check"></span>
                                Save
                              </button>
                              <button
                                onClick={() =>
                                  this.props.closeModal("openEftFileModal")
                                }
                                type="button"
                                className="btn-save"
                              >
                                <span className="fa fa-ban"></span>
                                Discard
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="forgot_body">
                        <div className="row mt-4">
                          <div className="col-md-6">
                            <div className="form-group">
                              <input
                                type="text"
                                placeholder="Process Date"
                                className="form-control mm-custom-input"
                                value={this.props.processDate}
                              ></input>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <input
                                type="text"
                                placeholder="Layout"
                                className="form-control mm-custom-input"
                                name="layout"
                                // onChange={(e) =>
                                //   this.handleChaneValueAdvanceOpt(e)
                                // }
                                value={this.props.layout}
                              ></input>
                            </div>
                          </div>
                        </div>

                        <div className="remittance-advance-table">
                          <h2>Advanced</h2>
                          <div className="reconciliation-table1 table_white_box">
                            <table
                              className="table"
                              id="reportv4-table"
                              width="100%"
                            >
                              <thead className="thead_bg hover-border">
                                <tr>
                                  <th scope="col"></th>
                                  <th scope="col">
                                    <span className="user_setup_hed">
                                      category
                                    </span>
                                  </th>
                                  <th scope="col">
                                    <span className="user_setup_hed">
                                      Description
                                    </span>
                                  </th>
                                  <th scope="col">
                                    <span className="user_setup_hed">
                                      Value
                                    </span>
                                  </th>

                                  <th scope="col">
                                    <span className="user_setup_hed">Hide</span>
                                  </th>
                                  <th className="table__inner--th">
                                    <div className="dropdown">
                                      <button
                                        aria-haspopup="true"
                                        aria-expanded="true"
                                        id=""
                                        type="button"
                                        className="dropdown-toggle btn dept-tbl-menu "
                                        data-toggle="dropdown"
                                      >
                                        <span className="fa fa-bars "></span>
                                      </button>
                                      <div className="dropdown-menu dept-menu-list dropdown-menu-right">
                                        <div className="pr-0 dropdown-item">
                                          <div className="form-group remember_check mm_check4">
                                            <input
                                              type="checkbox"
                                              id="showHiddenRows"
                                              name="showHiddenRows"
                                              checked={
                                                this.state.showHiddenRows
                                              }
                                              onClick={
                                                this.handleShowHiddenRows
                                              }
                                            />
                                            <label
                                              htmlFor="showHiddenRows"
                                              className="mr-0"
                                            >
                                              Show Hidden Rows
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.advancedOptions.map((list, i) => {
                                  return (
                                    <tr>
                                      <td></td>
                                      <td>{list.category}</td>
                                      <td>{list.description}</td>
                                      {list.type === "List" ||
                                      list.type === "Dropdown" ? (
                                        <td className="pt-0 pb-0 text-left  value__field--wrapperdept ">
                                          <Select
                                            classNamePrefix="custon_select-selector-inner main__dropdown--wrappertwo select__font-wrapper                                        "
                                            value={{
                                              label: list.value,
                                              value: list.value,
                                            }}
                                            options={list.options}
                                            onChange={(obj) =>
                                              this._handleValueOptions(
                                                "list",
                                                obj,
                                                list,
                                                i
                                              )
                                            }
                                            styles={_customStyles}
                                            theme={(theme) => ({
                                              ...theme,
                                              border: 0,
                                              borderRadius: 0,
                                              colors: {
                                                ...theme.colors,
                                                primary25: "#f2f2f2",
                                                primary: "#f2f2f2",
                                              },
                                            })}
                                          />
                                        </td>
                                      ) : list.type === "Date" ? (
                                        <td>
                                          <div className="table_input_field">
                                            <DatePicker
                                              selected={Number(list.value)}
                                              dateFormat="d MMM yyyy"
                                              autoComplete="off"
                                              onChange={(date) =>
                                                this._handleValueOptions(
                                                  "date",
                                                  date,
                                                  list,
                                                  i
                                                )
                                              }
                                            />
                                          </div>
                                        </td>
                                      ) : list.type === "Check" ? (
                                        <td>
                                          <div className="col-auto p-0">
                                            <div className="form-group remember_check text-center pt-0 float-left">
                                              <input
                                                type="checkbox"
                                                id={`chk${i}`}
                                                checked={
                                                  list.value === "Y" ||
                                                  list.value === "1"
                                                    ? true
                                                    : false
                                                }
                                                onChange={(e) =>
                                                  this._handleValueOptions(
                                                    "checkbox",
                                                    e,
                                                    list,
                                                    i
                                                  )
                                                }
                                              />
                                              <label
                                                htmlFor={`chk${i}`}
                                              ></label>
                                            </div>
                                          </div>
                                        </td>
                                      ) : list.type === "Numeric" ? (
                                        <td>
                                          <div className="table_input_field">
                                            <input
                                              type="number"
                                              value={list.value}
                                              onChange={(e) =>
                                                this._handleValueOptions(
                                                  "number",
                                                  e,
                                                  list,
                                                  i
                                                )
                                              }
                                            />
                                          </div>
                                        </td>
                                      ) : list.type === "Range" ||
                                        list.type === "Text" ? (
                                        <td>
                                          <div className="table_input_field">
                                            <input
                                              type="text"
                                              value={list.value}
                                              name="text"
                                              onChange={(e) =>
                                                this._handleValueOptions(
                                                  "text",
                                                  e,
                                                  list,
                                                  i
                                                )
                                              }
                                            />
                                          </div>
                                        </td>
                                      ) : (
                                        <td>{list.value}</td>
                                      )}

                                      <td>
                                        <div className="custom-radio">
                                          <label
                                            className="check_main remember_check"
                                            htmlFor={`hideUnhideRows${i}`}
                                          >
                                            <input
                                              type="checkbox"
                                              className="custom-control-input"
                                              name={"hideUnhideRows"}
                                              id={`hideUnhideRows${i}`}
                                              checked={false}
                                              onChange={(e) =>
                                                this._handleHideUnhideRows(list)
                                              }
                                            />

                                            {/* <span className='click_checkmark'></span> */}
                                            <span
                                              className={
                                                list.hide
                                                  ? "dash_checkmark bg_clr"
                                                  : "dash_checkmark"
                                              }
                                            ></span>
                                          </label>
                                        </div>
                                      </td>
                                      <td></td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  payments: state.payments,
});
export default connect(mapStateToProps, {
  primeEFTFile: PaymentActions.primeEFTFile,
})(EFTFile);
