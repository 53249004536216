import axiosDPFAPIClient from "../../Utils/axiosDPFAPIClient";


// ---------------------****Get Dist Changes Tallies****-----------------------------
export const getDistchangeTallies = (data) => async (dispatch) => {
  dispatch({
    type: "GET_DISTCHANGE_TALLIES_INIT",
  });

  let url = `/DPFAPI/DistChangeRequest?actionType=GetDistChangeTallies`;

  try {
    let result = await axiosDPFAPIClient.get(url);
    let resp = (result && result.data && result.data.DistChangeResponse) || "";
    if (resp && resp.result.length > 0) {
      if (resp.result[0] && resp.result[0].status === "Success") {
        dispatch({
          type: "GET_DISTCHANGE_TALLIES_SUCCESS",
          payload: resp,
        });
      } else {
        dispatch({
          type: "GET_DISTCHANGE_TALLIES_FAIL",
          payload:
            resp.result[0].description ||
            "Error While Getting Distchange Tallies.",
        });
      }
    } else {
      dispatch({
        type: "GET_DISTCHANGE_TALLIES_FAIL",
        payload: "Error While Getting Distchange Tallies.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Getting Distchange Tallies.";
    dispatch({
      type: "GET_DISTCHANGE_TALLIES_FAIL",
      payload: error,
    });
  }
};

// ---------------------****Get Dist Changes List****-----------------------------
export const getDistchangeList = (typeData) => async (dispatch) => {
  dispatch({
    type: "GET_DISTCHANGE_LIST_INIT",
  });
  let stageType = typeData.type;
  let url = `${localStorage.getItem("API_URL")}/DPFAPI/DistChangeRequest`;

  const data = {
    actionType: 'GetDistChangeList',
    stageType
  }

  try {
    let result = await axiosDPFAPIClient.post(url, data);
    let resp = (result && result.data && result.data.DistChangeResponse) || "";
    if (resp && resp.result.length > 0) {
      if (resp.result[0] && resp.result[0].status === "Success") {
        dispatch({
          type: "GET_DISTCHANGE_LIST_SUCCESS",
          payload: resp,
        });
      } else {
        dispatch({
          type: "GET_DISTCHANGE_LIST_FAIL",
          payload:
            resp.result[0].description ||
            "Error While Getting Timecard Tallies.",
        });
      }
    } else {
      dispatch({
        type: "GET_DISTCHANGE_LIST_FAIL",
        payload: "Error While Getting Timecard Tallies.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Getting Dist Change Tallies.";
    dispatch({
      type: "GET_DISTCHANGE_LIST_FAIL",
      payload: error,
    });
  }
};

//----------------------**** Get Dist Change***** ---------------------
export const getDistchange = (guid) => async (dispatch) => {
  dispatch({
    type: "GET_DISTCHANGE_INIT",
  });
  const url = `${localStorage.getItem("API_URL")}/DPFAPI/DistChangeRequest`;

  const data = {
    actionType: 'GetDistChange',
    guid
  }

  try {
    let response = await axiosDPFAPIClient.post(url, data);
    let getDistchange =
      (response && response.data && response.data.DistChangeResponse) || "";
    if (getDistchange && getDistchange.result.length > 0) {
      if (
        getDistchange.result[0] &&
        getDistchange.result[0].status === "Failed"
      ) {
        dispatch({
          type: "GET_DISTCHANGE_FAIL",
          payload:
            getDistchange.result[0].description ||
            "Error While Getting Distchange",
        });
      }
      if (
        getDistchange.result[0] &&
        getDistchange.result[0].status === "Success"
      ) {
        dispatch({
          type: "GET_DISTCHANGE_SUCCESS",
          payload: getDistchange,
        });
      }
    } else {
      dispatch({
        type: "GET_DISTCHANGE_FAIL",
        payload: "Error While Getting Distchange",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Getting Distchange";
    dispatch({
      type: "GET_DISTCHANGE_FAIL",
      payload: error,
    });
  }
};

// ---------------------****Update Dist Changes****-----------------------------
export const updateDistchange = (data) => async (dispatch) => {
  dispatch({
    type: "UPDATE_DISTCHANGE_INIT",
  });

  let url = "/DPFAPI//DistChangeRequest";
  let obj = {
    actionType: "UpdateDistChange",
    ...data,
  };
  try {
    let result = await axiosDPFAPIClient.post(url, obj);
    let resp = (result && result.data && result.data.DistChangeResponse) || "";

    if (resp && resp.result.length > 0) {
      if (resp.result[0] && resp.result[0].status === "Success") {
        dispatch({
          type: "UPDATE_DISTCHANGE_SUCCESS",
          payload: resp,
        });
      } else {
        dispatch({
          type: "UPDATE_DISTCHANGE_FAIL",
          payload:
            resp.result[0].description || "Error While updating DistChange",
        });
      }
    } else {
      dispatch({
        type: "UPDATE_DISTCHANGE_FAIL",
        payload: "Error While updating DistChange",
      });
    }
  } catch (err) {
    const error = err.message || "Error While updating DistChange";
    dispatch({
      type: "UPDATE_DISTCHANGE_FAIL",
      payload: error,
    });
  }
};

//----------------------****Post Dist Change*****------------------------------
export const postDistchange = (data) => async (dispatch) => {
  dispatch({
    type: "POST_DISTCHANGE_INIT",
  });
  const url = "/DPFAPI/DistChangeRequest";
  let obj = {
    actionType: "PostDistChange",
    ...data,
  };

  try {
    let response = await axiosDPFAPIClient.post(url, obj);
    let resp =
      (response && response.data && response.data.DistChangeResponse) || "";
    if (resp && resp.result.length > 0) {
      if (resp.result[0] && resp.result[0].status === "Success") {
        dispatch({
          type: "POST_DISTCHANGE_SUCCESS",
          payload: resp,
        });
      } else {
        dispatch({
          type: "POST_DISTCHANGE_FAIL",
          payload:
            resp.result[0].description || "Error While Posting DistChange.",
        });
      }
    } else {
      dispatch({
        type: "POST_DISTCHANGE_FAIL",
        payload: "Error While Posting DistChange.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Posting DistChange.";
    dispatch({
      type: "POST_DISTCHANGE_FAIL",
      payload: error,
    });
  }
};

//----------------------****sendForApproval Dist Change*****------------------------------
export const sendForApprovalDistchange = (guid) => async (dispatch) => {
  dispatch({
    type: "SEND_FOR_APPROVAL_DISTCHANGE_INIT",
  });
  const url = "/DPFAPI/DistChangeRequest";
  let data = {
    actionType: "SendForApproval",
    guid,
  };

  try {
    let response = await axiosDPFAPIClient.post(url, data);
    let sendForApproval =
      (response && response.data && response.data.DistChangeResponse) || "";
    if (sendForApproval && sendForApproval.result.length > 0) {
      if (
        sendForApproval.result[0] &&
        sendForApproval.result[0].status === "Failed"
      ) {
        dispatch({
          type: "SEND_FOR_APPROVAL_DISTCHANGE_FAIL",
          payload:
            sendForApproval.result[0].description ||
            "Error While Sending For Approval DistChange.",
        });
      }
      if (
        sendForApproval.result[0] &&
        sendForApproval.result[0].status === "Success"
      ) {
        dispatch({
          type: "SEND_FOR_APPROVAL_DISTCHANGE_SUCCESS",
          payload: sendForApproval,
        });
      }
    } else {
      dispatch({
        type: "SEND_FOR_APPROVAL_DISTCHANGE_FAIL",
        payload: "Error While Sending For Approval DistChange.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Sending For Approval DistChange.";
    dispatch({
      type: "SEND_FOR_APPROVAL_DISTCHANGE_FAIL",
      payload: error,
    });
  }
};

//----------------------****Approve Dist Change *****---------------------------
export const approveDistchange = (guid) => async (dispatch) => {
  dispatch({
    type: "APPROVE_DISTCHANGE_INIT",
  });
  const url = "/DPFAPI/DistChangeRequest";
  let data = {
    actionType: "ApproveDistChange",
    guid,
  };

  try {
    let response = await axiosDPFAPIClient.post(url, data);
    let approveDst =
      (response && response.data && response.data.DistChangeResponse) || "";
    if (approveDst && approveDst.result.length > 0) {
      if (approveDst.result[0] && approveDst.result[0].status === "Failed") {
        dispatch({
          type: "APPROVE_DISTCHANGE_FAIL",
          payload:
            approveDst.result[0].description ||
            "Error While Approving DistChange.",
        });
      }
      if (approveDst.result[0] && approveDst.result[0].status === "Success") {
        dispatch({
          type: "APPROVE_DISTCHANGE_SUCCESS",
          payload: approveDst,
        });
      }
    } else {
      dispatch({
        type: "APPROVE_DISTCHANGE_FAIL",
        payload: "Error While Approving DistChange.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Approving DistChange.";
    dispatch({
      type: "APPROVE_DISTCHANGE_FAIL",
      payload: error,
    });
  }
};

//----------------------****Move Dist Change*****-----------------------------
export const moveDistchange = (guid) => async (dispatch) => {
  dispatch({
    type: "MOVE_DISTCHANGE_INIT",
  });
  const url = "/DPFAPI/DistChangeRequest";
  let data = {
    actionType: "MoveDistChange",
    guid,
  };

  try {
    let response = await axiosDPFAPIClient.post(url, data);
    let moveDistchange =
      (response && response.data && response.data.DistChangeResponse) || "";
    if (moveDistchange && moveDistchange.result.length > 0) {
      if (
        moveDistchange.result[0] &&
        moveDistchange.result[0].status === "Failed"
      ) {
        dispatch({
          type: "MOVE_DISTCHANGE_FAIL",
          payload:
            moveDistchange.result[0].description ||
            "Error While Moving DistChange.",
        });
      }
      if (
        moveDistchange.result[0] &&
        moveDistchange.result[0].status === "Success"
      ) {
        dispatch({
          type: "MOVE_DISTCHANGE_SUCCESS",
          payload: moveDistchange,
        });
      }
    } else {
      dispatch({
        type: "MOVE_DISTCHANGE_FAIL",
        payload: "Error While Moving DistChange.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Moving DistChange.";
    dispatch({
      type: "MOVE_DISTCHANGE_FAIL",
      payload: error,
    });
  }
};

//----------------------****Decline Dist Change *****---------------------------
export const declineDistchange = (guid) => async (dispatch) => {
  dispatch({
    type: "DECLINE_DISTCHANGE_INIT",
  });
  const url = "/DPFAPI/DistChangeRequest";
  let data = {
    actionType: "DeclineDistChange",
    guid,
  };

  try {
    let response = await axiosDPFAPIClient.post(url, data);
    let declineDistchange =
      (response && response.data && response.data.DistChangeResponse) || "";
    if (declineDistchange && declineDistchange.result.length > 0) {
      if (
        declineDistchange.result[0] &&
        declineDistchange.result[0].status === "Failed"
      ) {
        dispatch({
          type: "DECLINE_DISTCHANGE_FAIL",
          payload:
            declineDistchange.result[0].description ||
            "Error While Declining DistChange.",
        });
      }
      if (
        declineDistchange.result[0] &&
        declineDistchange.result[0].status === "Success"
      ) {
        dispatch({
          type: "DECLINE_DISTCHANGE_SUCCESS",
          payload: declineDistchange,
        });
      }
    } else {
      dispatch({
        type: "DECLINE_DISTCHANGE_FAIL",
        payload: "Error While Declining DistChange.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Declining DistChange.";
    dispatch({
      type: "DECLINE_DISTCHANGE_FAIL",
      payload: error,
    });
  }
};

//----------------------****get Dist Change Details*****---------------------------
export const getDistchangeDetails = (guid) => async (dispatch) => {
  dispatch({
    type: "GET_DISTCHANGE_DETAILS_INIT",
  });
  const url = "/DPFAPI/DistChangeRequest";
  let data = {
    actionType: "GetDistChangeDetails",
    guid,
  };

  try {
    let response = await axiosDPFAPIClient.post(url, data);
    let distchangeDetails =
      (response && response.data && response.data.DistChangeResponse) || "";
    if (distchangeDetails && distchangeDetails.result.length > 0) {
      if (
        distchangeDetails.result[0] &&
        distchangeDetails.result[0].status === "Failed"
      ) {
        dispatch({
          type: "GET_DISTCHANGE_DETAILS_FAIL",
          payload:
            distchangeDetails.result[0].description ||
            "Error While getting DistChange Details.",
        });
      }
      if (
        distchangeDetails.result[0] &&
        distchangeDetails.result[0].status === "Success"
      ) {
        dispatch({
          type: "GET_DISTCHANGE_DETAILS_SUCCESS",
          payload: distchangeDetails,
        });
      }
    } else {
      dispatch({
        type: "GET_DISTCHANGE_DETAILS_FAIL",
        payload: "Error While getting DistChange Details",
      });
    }
  } catch (err) {
    const error = err.message || "Error While getting DistChange Details.";
    dispatch({
      type: "GET_DISTCHANGE_DETAILS_FAIL",
      payload: error,
    });
  }
};

//----------------------****Update Dist Change Details*****---------------------------
export const updateDistchangeDetails = (id) => async (dispatch) => {
  dispatch({
    type: "UPDATE_DISTCHANGE_DETAILS_INIT",
  });
  const url = "/DPFAPI/DistChangeRequest";
  let data = {
    actionType: "UpdateDistChangeDetails",
  };

  try {
    let response = await axiosDPFAPIClient.post(url, data);
    let distchangeDetails =
      (response && response.data && response.data.DistChangeResponse) || "";
    if (distchangeDetails && distchangeDetails.result.length > 0) {
      if (
        distchangeDetails.result[0] &&
        distchangeDetails.result[0].status === "Failed"
      ) {
        dispatch({
          type: "UPDATE_DISTCHANGE_DETAILS_FAIL",
          payload:
            distchangeDetails.result[0].description ||
            "Error While updating DistChange Details.",
        });
      }
      if (
        distchangeDetails.result[0] &&
        distchangeDetails.result[0].status === "Success"
      ) {
        dispatch({
          type: "UPDATE_DISTCHANGE_DETAILS_SUCCESS",
          payload: distchangeDetails,
        });
      }
    } else {
      dispatch({
        type: "UPDATE_DISTCHANGE_DETAILS_FAIL",
        payload: "Error While updating DistChange Details",
      });
    }
  } catch (err) {
    const error = err.message || "Error While updating DistChange details.";
    dispatch({
      type: "UPDATE_DISTCHANGE_DETAILS_FAIL",
      payload: error,
    });
  }
};

//----------------------****Delete Dist Change*****------------------
export const deleteDistchange = (guid) => async (dispatch) => {
  dispatch({
    type: "DELETE_DISTCHANGE_INIT",
  });
  const url = "/DPFAPI/DistChangeRequest";
  let data = {
    actionType: "DeleteDistChange",
    guid,
  };
  try {
    let response = await axiosDPFAPIClient.post(url, data);
    let deleteDistchange =
      (response && response.data && response.data.DistChangeResponse) || "";
    if (deleteDistchange && deleteDistchange.result.length > 0) {
      if (
        deleteDistchange.result[0] &&
        deleteDistchange.result[0].status === "Failed"
      ) {
        dispatch({
          type: "DELETE_DISTCHANGE_FAIL",
          payload:
            deleteDistchange.result[0].description ||
            "Error While Deleting DistChange .",
        });
      }
      if (
        deleteDistchange.result[0] &&
        deleteDistchange.result[0].status === "Success"
      ) {
        dispatch({
          type: "DELETE_DISTCHANGE_SUCCESS",
          payload: deleteDistchange,
        });
      }
    } else {
      dispatch({
        type: "DELETE_DISTCHANGE_FAIL",
        payload: "Error While Deleting DistChange .",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Deleting DistChange Attachemnt.";
    dispatch({
      type: "DELETE_DISTCHANGE_FAIL",
      payload: error,
    });
  }
};

// ---------------------****Get Dist Change Summary****-----------------------------
export const getDistchangeSummary = (guid) => async (dispatch) => {
  dispatch({
    type: "GET_DISTCHANGE_SUMMARY_INIT",
  });

  let url = '/DPFAPI/DistChangeRequest';

  const data = {
    actionType: 'GetDistChangeSummary',
    guid
  }

  try {
    let result = await axiosDPFAPIClient.post(url, data);
    let resp = (result && result.data && result.data.DistChangeResponse) || "";
    if (resp && resp.result.length > 0) {
      if (resp.result[0] && resp.result[0].status === "Success") {
        dispatch({
          type: "GET_DISTCHANGE_SUMMARY_SUCCESS",
          payload: resp,
        });
      } else {
        dispatch({
          type: "GET_DISTCHANGE_SUMMARY_FAIL",
          payload:
            resp.result[0].description ||
            "Error While Getting Timecard Tallies.",
        });
      }
    } else {
      dispatch({
        type: "GET_DISTCHANGE_SUMMARY_FAIL",
        payload: "Error While Getting Timecard Tallies.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Getting Dist Change Summary.";
    dispatch({
      type: "GET_DISTCHANGE_SUMMARY_FAIL",
      payload: error,
    });
  }
};

//----------------------**** Get Dist Change Attachment***** ---------------------
export const getAttachment = (recordID) => async (dispatch) => {
  dispatch({
    type: "GET_DISTCHANGE_ATTACHMENT_INIT",
  });
  const url =

    `/DPFAPI/DistChangeRequest?actionType=GetAttachment&recordID=${recordID}`;

  try {
    let response = await axiosDPFAPIClient.get(url);
    let getAttachment =
      (response && response.data && response.data.DistChangeResponse) || "";
    if (getAttachment && getAttachment.result.length > 0) {
      if (
        getAttachment.result[0] &&
        getAttachment.result[0].status === "Failed"
      ) {
        dispatch({
          type: "GET_DISTCHANGE_ATTACHMENT_FAIL",
          payload:
            getAttachment.result[0].description ||
            "Error While Getting Distchange Attachemnt.",
        });
      }
      if (
        getAttachment.result[0] &&
        getAttachment.result[0].status === "Success"
      ) {
        dispatch({
          type: "GET_DISTCHANGE_ATTACHMENT_SUCCESS",
          payload: getAttachment,
        });
      }
    } else {
      dispatch({
        type: "GET_DISTCHANGE_ATTACHMENT_FAIL",
        payload: "Error While Getting Distchange Attachemnt.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Getting Distchange Attachemnt.";
    dispatch({
      type: "GET_DISTCHANGE_ATTACHMENT_FAIL",
      payload: error,
    });
  }
};

//----------------------****Delete Dist Change Attachment***** ---------------------
export const deleteAttachment = (recordID) => async (dispatch) => {
  dispatch({
    type: "DELETE_DISTCHANGE_ATTACHMENT_INIT",
  });
  const url = `/DPFAPI/DistChangeRequest?actionType=DeleteAttachment&recordID=${recordID}`;

  try {
    let response = await axiosDPFAPIClient.post(url);
    let deleteAttachment =
      (response && response.data && response.data.DistChangeResponse) || "";
    if (deleteAttachment && deleteAttachment.result.length > 0) {
      if (
        deleteAttachment.result[0] &&
        deleteAttachment.result[0].status === "Failed"
      ) {
        dispatch({
          type: "DELETE_DISTCHANGE_ATTACHMENT_FAIL",
          payload:
            deleteAttachment.result[0].description ||
            "Error While Deleting Distchange Attachemnt.",
        });
      }
      if (
        deleteAttachment.result[0] &&
        deleteAttachment.result[0].status === "Success"
      ) {
        dispatch({
          type: "DELETE_DISTCHANGE_ATTACHMENT_SUCCESS",
          payload: deleteAttachment,
        });
      }
    } else {
      dispatch({
        type: "DELETE_DISTCHANGE_ATTACHMENT_FAIL",
        payload: "Error While Deleting Distchange Attachemnt.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Deleting Distchange Attachemnt.";
    dispatch({
      type: "DELETE_DISTCHANGE_ATTACHMENT_FAIL",
      payload: error,
    });
  }
};

//----------------------****Add Comment****----------------------------
export const addComment = (data) => async (dispatch) => {
  dispatch({
    type: "ADD_DISTCHANGE_COMMENT_INIT",
  });
  const url = "/DPFAPI/DistChangeRequest";
  let obj = {
    actionType: "AddComment",
    ...data,
  };

  try {
    let response = await axiosDPFAPIClient.post(url, obj);
    let addCommentResp =
      (response && response.data && response.data.DistChangeResponse) || "";
    if (addCommentResp && addCommentResp.result.length > 0) {
      if (
        addCommentResp.result[0] &&
        addCommentResp.result[0].status === "Failed"
      ) {
        dispatch({
          type: "ADD_DISTCHANGE_COMMENT_FAIL",
          payload:
            addCommentResp.result[0].description || "Error While Commenting.",
        });
      }
      if (
        addCommentResp.result[0] &&
        addCommentResp.result[0].status === "Success"
      ) {
        dispatch({
          type: "ADD_DISTCHANGE_COMMENT_SUCCESS",
          payload: addCommentResp,
        });
      }
    } else {
      dispatch({
        type: "ADD_DISTCHANGE_COMMENT_FAIL",
        payload: "Error While Commenting.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Commenting.";
    dispatch({
      type: "ADD_DISTCHANGE_COMMENT_FAIL",
      payload: error,
    });
  }
};

//----------------------****Export DST*****----------------------------
export const exportDistchange = (id) => async (dispatch) => {
  dispatch({
    type: "EXPORT_DISTCHANGE_INIT",
  });
  const url = "/DPFAPI/DistChangeRequest";
  let data = {
    actionType: "ExportDistChange",
    guid: id,
  };

  try {
    let response = await axiosDPFAPIClient.post(url, data);
    let resp =
      (response && response.data && response.data.DistChangeResponse) || "";
    if (resp && resp.result.length > 0) {
      if (resp.result[0] && resp.result[0].status === "Success") {
        dispatch({
          type: "EXPORT_DISTCHANGE_SUCCESS",
          payload: resp,
        });
      } else {
        dispatch({
          type: "EXPORT_DISTCHANGE_FAIL",
          payload:
            resp.result[0].description || "Error While Exporting Distchange.",
        });
      }
    } else {
      dispatch({
        type: "EXPORT_DISTCHANGE_FAIL",
        payload: "Error While Exporting Distchange.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Exporting Distchange.";
    dispatch({
      type: "EXPORT_DISTCHANGE_FAIL",
      payload: error,
    });
  }
};

//----------------------**** Add DistChange Attachment *****---------------
export const addDistchangeAttachment = (list) => async (dispatch) => {
  dispatch({
    type: "ADD_DISTCHANGE_ATTACHMENT_INIT",
  });
  const url = "/DPFAPI/DistChangeRequest";
  let data = {
    actionType: "AddAttachment",
    ...list,
  };

  try {
    let response = await axiosDPFAPIClient.post(url, data);
    let addAttachment =
      (response && response.data && response.data.DistChangeResponse) || "";
    if (addAttachment && addAttachment.result.length > 0) {
      if (
        addAttachment.result[0] &&
        addAttachment.result[0].status === "Failed"
      ) {
        dispatch({
          type: "ADD_DISTCHANGE_ATTACHMENT_FAIL",
          payload:
            addAttachment.result[0].description ||
            "Error While Uploading Attachments.",
        });
      }
      if (
        addAttachment.result[0] &&
        addAttachment.result[0].status === "Success"
      ) {
        dispatch({
          type: "ADD_DISTCHANGE_ATTACHMENT_SUCCESS",
          payload: addAttachment,
        });
      }
    } else {
      dispatch({
        type: "ADD_DISTCHANGE_ATTACHMENT_FAIL",
        payload: "Error While Uploading Attachments.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Uploading Attachments.";
    dispatch({
      type: "ADD_DISTCHANGE_ATTACHMENT_FAIL",
      payload: error,
    });
  }
};

export const updateApprovalGroup = (data) => async (dispatch) => {
  dispatch({
    type: "UPDATE_APPROVAL_DISTCHANGES_GROUP_INIT",
  });
  const url = "/DPFAPI/DistChangeRequest";
  let obj = {
    actionType: "UpdateApprovalGroup",
    ...data,
  };

  try {
    let result = await axiosDPFAPIClient.post(url, obj);
    let resp = (result && result.data && result.data.DistChangeResponse) || "";

    if (resp && resp.result.length > 0) {
      if (resp.result[0] && resp.result[0].status === "Success") {
        dispatch({
          type: "UPDATE_APPROVAL_DISTCHANGES_GROUP_SUCCESS",
          payload: resp,
        });
      } else {
        dispatch({
          type: "UPDATE_APPROVAL_DISTCHANGES_GROUP_FAIL",
          payload:
            resp.result[0].description || "Error While Update Approval Group.",
        });
      }
    } else {
      dispatch({
        type: "UPDATE_APPROVAL_DISTCHANGES_GROUP_FAIL",
        payload: "Error While Update Approval Group.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Update Approval Group.";
    dispatch({
      type: "UPDATE_APPROVAL_DISTCHANGES_GROUP_FAIL",
      payload: error,
    });
  }
};
//----------------------****Clear DistCHanges States In Store****----------------

export function clearDistChangesStates() {
  return async (dispatch) => {
    dispatch({
      type: "CLEAR_DistChanges_STATES",
    });
  };
}
