const INIT_STATE = {
  distChangeTallies: [],
  getDistchangeTalliesSuccess: "",
  getDistchangeTalliesError: "",

  getDistchangeList: [],
  getDistchangeListSuccess: "",
  getDistchangeListError: "",

  getDistchange: [],
  getDistchangeSuccess: "",
  getDistchangeError: "",

  updateDistchange: [],
  updateDistchangeSuccess: "",
  updateDistchangeError: "",

  postDistchange: "",
  postDistchangeSuccess: "",
  postDistchangeError: "",

  sendForApprovalDistchangeSuccess: "",
  sendForApprovalDistchangeError: "",

  approveDistchnageSuccess: "",
  approveDistchnageError: "",

  moveDistchangeSuccess: "",
  moveDistchangeError: "",

  declineDistchangeSuccess: "",
  declineDistchangeError: "",

  getdistChangeDetails: [],
  getDistchangeDetailsSuccess: "",
  getDistchangeDetailsError: "",

  updateDistchangeDetailsSuccess: "",
  updateDistchangeDetailsError: "",

  deleteDistchangeSuccess: "",
  deleteDistchangeError: "",

  getDistchangeSummary: "",
  getDistchangeSummarySuccess: "",
  getDistchangeSummaryError: "",

  addCommentData: [],
  addCommentSuccess: "",
  addCommentError: "",

  exportDistchange: "",
  exportDistchangeSuccess: "",
  exportDistchangeError: "",

  addDistchangeAttachment: [],
  addDistchangeAttachmentSuccess: "",
  addDistchangeAttachmentError: "",

  getDistchangeAttachment: "",
  getDistchangeAttachmentSuccess: "",
  getDistchangeAttachmentError: "",

  deleteDistchangeAttachmentSuccess: "",
  deleteDistchangeAttachmentError: "",

  updateApprovalGroup: [],
  updateApprovalGroupSuccess: "",
  updateApprovalGroupError: "",
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = INIT_STATE, action) {
  switch (action.type) {
    //----------------------****Get Dist Tallies****-----------------------------
    case "GET_DISTCHANGE_TALLIES_INIT":
      return {
        ...state,
        distChangeTallies: [],
        getDistchangeTalliesSuccess: "",
        getDistchangeTalliesError: "",
      };
    case "GET_DISTCHANGE_TALLIES_SUCCESS":
      return {
        ...state,
        distChangeTallies: action.payload.distChangeTallies || [],
        getDistchangeTalliesSuccess: action.payload.result[0].description,
      };
    case "GET_DISTCHANGE_TALLIES_FAIL":
      return {
        ...state,
        getDistchangeTalliesError: action.payload,
      };

    //----------------------****Get Dist Change List****-----------------------------
    case "GET_DISTCHANGE_LIST_INIT":
      return {
        ...state,
        getDistchangeList: [],
        getDistchangeListSuccess: "",
        getDistchangeListError: "",
      };
    case "GET_DISTCHANGE_LIST_SUCCESS":
      return {
        ...state,
        getDistchangeList: action.payload.distributionChanges || [],
        getDistchangeListSuccess: action.payload.result[0].description,
      };
    case "GET_DISTCHANGE_LIST_FAIL":
      return {
        ...state,
        getDistchangeListError: action.payload,
      };

    //----------------------****Get Dist Change****-----------------------------
    case "GET_DISTCHANGE_INIT":
      return {
        ...state,
        getDistchange: [],
        getDistchangeSuccess: "",
        getDistchangeError: "",
      };
    case "GET_DISTCHANGE_SUCCESS":
      return {
        ...state,
        getDistchange: action.payload || [],
        getDistchangeSuccess: action.payload.result[0].description,
      };
    case "GET_DISTCHANGE_FAIL":
      return {
        ...state,
        getDistchangeError: action.payload,
      };

    //----------------------****Update Dist Change****-----------------------------
    case "UPDATE_DISTCHANGE_INIT":
      return {
        ...state,
        updateDistchange: [],
        updateDistchangeSuccess: "",
        updateDistchangeError: "",
      };
    case "UPDATE_DISTCHANGE_SUCCESS":
      return {
        ...state,
        updateDistchange: action.payload || [],
        updateDistchangeSuccess: action.payload.result[0].description,
      };
    case "UPDATE_DISTCHANGE_FAIL":
      return {
        ...state,
        updateDistchangeError: action.payload,
      };

    //----------------------****Post DistChange****-----------------------------
    case "POST_DISTCHANGE_INIT":
      return {
        ...state,
        postDistchange: "",
        postDistchangeSuccess: "",
        postDistchangeError: "",
      };
    case "POST_DISTCHANGE_SUCCESS":
      return {
        ...state,
        postDistchange: action.payload,
        postDistchangeSuccess: action.payload.result[0].description,
      };
    case "POST_DISTCHANGE_FAIL":
      return {
        ...state,
        postDistchangeError: action.payload,
      };

    //----------------------****Send For Approval DistChange****-----------------------------
    case "SEND_FOR_APPROVAL_DISTCHANGE_INIT":
      return {
        ...state,

        sendForApprovalDistchangeSuccess: "",
        sendForApprovalDistchangeError: "",
      };
    case "SEND_FOR_APPROVAL_DISTCHANGE_SUCCESS":
      return {
        ...state,
        sendForApprovalDistchangeSuccess: action.payload.result[0].description,
      };
    case "SEND_FOR_APPROVAL_DISTCHANGE_FAIL":
      return {
        ...state,
        sendForApprovalDistchangeError: action.payload,
      };

    //----------------------****Approve Dist Change****-----------------------------
    case "APPROVE_DISTCHANGE_INIT":
      return {
        ...state,
        approveDistchnageSuccess: "",
        approveDistchnageError: "",
      };
    case "APPROVE_DISTCHANGE_SUCCESS":
      return {
        ...state,
        approveDistchnageSuccess: action.payload.result[0].description,
      };
    case "APPROVE_DISTCHANGE_FAIL":
      return {
        ...state,
        approveDistchnageError: action.payload,
      };

    //----------------------****Move Dist Change****-----------------------------
    case "MOVE_DISTCHANGE_INIT":
      return {
        ...state,
        moveDistchangeSuccess: "",
        moveDistchangeError: "",
      };
    case "MOVE_DISTCHANGE_SUCCESS":
      return {
        ...state,
        moveDistchangeSuccess: action.payload.result[0].description,
      };
    case "MOVE_DISTCHANGE_FAIL":
      return {
        ...state,
        moveDistchangeError: action.payload,
      };

    //----------------------****Decline Dist Change ****-----------------------------
    case "DECLINE_DISTCHANGE_INIT":
      return {
        ...state,
        declineDistchangeSuccess: "",
        declineDistchangeError: "",
      };
    case "DECLINE_DISTCHANGE_SUCCESS":
      return {
        ...state,
        declineDistchangeSuccess: action.payload.result[0].description,
      };
    case "DECLINE_DISTCHANGE_FAIL":
      return {
        ...state,
        declineDistchangeError: action.payload,
      };

    //----------------------****Get Dist Change Details****-----------------------------
    case "GET_DISTCHANGE_DETAILS_INIT":
      return {
        ...state,
        getdistChangeDetails: [],
        getDistchangeDetailsSuccess: "",
        getDistchangeDetailsError: "",
      };
    case "GET_DISTCHANGE_DETAILS_SUCCESS":
      return {
        ...state,
        distChangeTallies: action.payload.distChangeDetails || [],
        getDistchangeDetailsSuccess: action.payload.result[0].description,
      };
    case "GET_DISTCHANGE_DETAILS_FAIL":
      return {
        ...state,
        getDistchangeDetailsError: action.payload,
      };

    //----------------------****Get Dist Change Details****-----------------------------
    case "UPDATE_DISTCHANGE_DETAILS_INIT":
      return {
        ...state,

        updateDistchangeDetailsSuccess: "",
        updateDistchangeDetailsError: "",
      };
    case "UPDATE_DISTCHANGE_DETAILS_SUCCESS":
      return {
        ...state,
        updateDistchangeDetailsSuccess: action.payload.result[0].description,
      };
    case "UPDATE_DISTCHANGE_DETAILS_FAIL":
      return {
        ...state,
        updateDistchangeDetailsError: action.payload,
      };

    //----------------------****Delete PO Attachment****-----------------------------
    case "DELETE_DISTCHANGE_INIT":
      return {
        ...state,
        deleteDistchangeSuccess: "",
        deleteDistchangeError: "",
      };
    case "DELETE_DISTCHANGE_SUCCESS":
      return {
        ...state,
        deleteDistchangeSuccess: action.payload.result[0].description,
      };
    case "DELETE_DISTCHANGE_FAIL":
      return {
        ...state,
        deleteDistchangeError: action.payload,
      };

    //----------------------****Get Dist Change Summary****-----------------------------
    case "GET_DISTCHANGE_SUMMARY_INIT":
      return {
        ...state,
        getDistchangeSummary: "",
        getDistchangeSummarySuccess: "",
        getDistchangeSummaryError: "",
      };
    case "GET_DISTCHANGE_SUMMARY_SUCCESS":
      return {
        ...state,
        getDistchangeSummary: action.payload.distributionChangeSummary || "",
        getDistchangeSummarySuccess: action.payload.result[0].description,
      };
    case "GET_DISTCHANGE_SUMMARY_FAIL":
      return {
        ...state,
        getDistchangeSummaryError: action.payload,
      };

    //----------------------****Add DST Comment****-----------------------------
    case "ADD_DISTCHANGE_COMMENT_INIT":
      return {
        ...state,
        addCommentData: [],
        addCommentSuccess: "",
        addCommentError: "",
      };
    case "ADD_DISTCHANGE_COMMENT_SUCCESS":
      return {
        ...state,
        addCommentData: action.payload.comments || [],
        addCommentSuccess: action.payload.result[0].description,
      };
    case "ADD_DISTCHANGE_COMMENT_FAIL":
      return {
        ...state,
        addCommentError: action.payload,
      };

    //----------------------****Export DISTCHANGE****-----------------------------
    case "EXPORT_DISTCHANGE_INIT":
      return {
        ...state,
        exportDistchange: "",
        exportDistchangeSuccess: "",
        exportDistchangeError: "",
      };
    case "EXPORT_DISTCHANGE_SUCCESS":
      return {
        ...state,
        exportDistchange: action.payload.attachment || "",
        exportDistchangeSuccess: action.payload.result[0].description,
      };
    case "EXPORT_DISTCHANGE_FAIL":
      return {
        ...state,
        exportDistchangeError: action.payload,
      };

    //----------------------****Add PO Attachment Lists ****-----------------------------
    case "ADD_DISTCHANGE_ATTACHMENT_INIT":
      return {
        ...state,
        addDistchangeAttachment: [],
        addDistchangeAttachmentSuccess: "",
        addDistchangeAttachmentError: "",
      };
    case "ADD_DISTCHANGE_ATTACHMENT_SUCCESS":
      return {
        ...state,
        addDistchangeAttachment: action.payload.attachments || [],
        addDistchangeAttachmentSuccess: action.payload.result[0].description,
      };
    case "ADD_DISTCHANGE_ATTACHMENT_FAIL":
      return {
        ...state,
        addDistchangeAttachmentError: action.payload,
      };

    //----------------------****Get DistChange Attachment****-----------------------------
    case "GET_DISTCHANGE_ATTACHMENT_INIT":
      return {
        ...state,
        getDistchangeAttachment: "",
        getDistchangeAttachmentSuccess: "",
        getDistchangeAttachmentError: "",
      };
    case "GET_DISTCHANGE_ATTACHMENT_SUCCESS":
      return {
        ...state,
        getDistchangeAttachment: action.payload,
        getDistchangeAttachmentSuccess: action.payload.result[0].description,
      };
    case "GET_DISTCHANGE_ATTACHMENT_FAIL":
      return {
        ...state,
        getDistchangeAttachmentError: action.payload,
      };

    //----------------------****Delete DistChange Attachment****-----------------------------
    case "DELETE_DISTCHANGE_ATTACHMENT_INIT":
      return {
        ...state,

        deleteDistchangeAttachmentSuccess: "",
        deleteDistchangeAttachmentError: "",
      };
    case "DELETE_DISTCHANGE_ATTACHMENT_SUCCESS":
      return {
        ...state,
        deleteDistchangeAttachmentSuccess: action.payload.result[0].description,
      };
    case "DELETE_DISTCHANGE_ATTACHMENT_FAIL":
      return {
        ...state,
        deleteDistchangeAttachmentError: action.payload,
      };

    //----------------------****Update Approval Group ****-----------------------------
    case "UPDATE_APPROVAL_DISTCHANGES_GROUP_INIT":
      return {
        ...state,
        updateApprovalGroup: [],
        updateApprovalGroupSuccess: "",
        updateApprovalGroupError: "",
      };
    case "UPDATE_APPROVAL_DISTCHANGES_GROUP_SUCCESS":
      return {
        ...state,
        updateApprovalGroup: action.DistChangeResponse || [],
        updateApprovalGroupSuccess: action.payload.result[0].description,
      };
    case "UPDATE_APPROVAL_DISTCHANGES_GROUP_FAIL":
      return {
        ...state,
        updateApprovalGroupError: action.payload,
      };

    case "CLEAR_DistChanges_STATES":
      return {
        ...state,

        getDistchangeTalliesSuccess: "",
        getDistchangeTalliesError: "",

        getDistchangeListSuccess: "",
        getDistchangeListError: "",

        getDistchangeSuccess: "",
        getDistchangeError: "",

        updateDistchangeSuccess: "",
        updateDistchangeError: "",

        postDistchangeSuccess: "",
        postDistchangeError: "",

        sendForApprovalDistchangeSuccess: "",
        sendForApprovalDistchangeError: "",

        approveDistchnageSuccess: "",
        approveDistchnageError: "",

        moveDistchangeSuccess: "",
        moveDistchangeError: "",

        declineDistchangeSuccess: "",
        declineDistchangeError: "",

        getDistchangeDetailsSuccess: "",
        getDistchangeDetailsError: "",

        updateDistchangeDetailsSuccess: "",
        updateDistchangeDetailsError: "",

        deleteDistchangeSuccess: "",
        deleteDistchangeError: "",

        getDistchangeSummarySuccess: "",
        getDistchangeSummaryError: "",

        addCommentSuccess: "",
        addCommentError: "",

        exportDistchangeSuccess: "",
        exportDistchangeError: "",

        addDistchangeAttachmentSuccess: "",
        addDistchangeAttachmentError: "",

        getDistchangeAttachmentSuccess: "",
        getDistchangeAttachmentError: "",

        deleteDistchangeAttachmentSuccess: "",
        deleteDistchangeAttachmentError: "",
      };

    default:
      return state;
  }
}
