import React, { useState, useEffect } from "react";
import "./Attachments.css";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import {
  toBase64,
  addDragAndDropFileListners,
  removeDragAndDropFileListners,
} from "../../../Utils/Helpers";
import { deletePOAttachment, updatePOAttachment } from "../../../Actions/POActions/POActions";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

const Attachments = (props) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const poData = useSelector((state) => state.poData);
  const [attachments, setAttachments] = useState(props.attachments)
  const [state, setState] = useState({
    isLoading: false,
  });

  const {pathname} = location;

  useEffect(() => {
    addDragAndDropFileListners("drop-area-attach-modal", uploadAttachment);
  }, [props.openAttachmentsModal]);

  useEffect(()=>{
    if (poData.updatePOAttachmentSuccess){
      props.getPOSummary(props.guid, props.poType)
      toast.success('Attachments updated');
      closeModal();
    }
  },[poData.updatePOAttachmentSuccess]);

  useEffect(()=>{
    if (poData.updatePOAttachmentError){
      toast.error(poData.updatePOAttachmentError);
      setState(prevState => ({
        ...prevState,
        isLoading: false  // Update the value of isLoading to true
      }));
      closeModal();
    }
  },[poData.updatePOAttachmentError])

  useEffect(()=>{
    if (poData.getPOSummarySuccess){
      setState(prevState => ({
        ...prevState,
        isLoading: false  // Update the value of isLoading to true
      }));
    }
  },[poData.getPOSummarySuccess]);

  useEffect( ()=>{
    if (poData.deletePOAttachmentSuccess){
      props.getPOSummary(props.guid, props.poType)
      toast.success("Attachment deleted");
      closeModal();
    }
  },[poData.deletePOAttachmentSuccess]);

  useEffect(()=>{
    if (poData.deletePOAttachmentError){
      setState(prevState => ({
        ...prevState,
        isLoading: false  // Update the value of isLoading to true
      }));
      toast.error(poData.deletePOAttachmentError)
    }
  },[poData.deletePOAttachmentError])

  useEffect(()=>{
    setAttachments(props.attachments);
  },[props.attachments]);

  useEffect(()=>{
    attachments?.forEach((data) =>{
      if(data.sendToSupplier === "Y"){
        data.checked = true;
      }else {
        data.checked = false;
      }
    });
  },[attachments]);

  const closeModal = async () => {
    props.closeModal("openAttachmentsModal");
    removeDragAndDropFileListners("drop-area-attach-modal", uploadAttachment);
  };

  // uplaod po attchments
  const uploadAttachment = async (attachments) => {
    /*
    A user cannot have greater than 29.5MB of attachments
    in one invoice/po/expenses/documents transaction.
  */

    let fileList = [];
    let attachmentSize = props.attachmentSize || 0;
    for (let i = 0; i < attachments.length; i++) {
      let type = attachments[i].type;
      let file = attachments[i];
      let size = attachments[i].size;
      let name = attachments[i].name;

      if (
        type == "application/pdf" ||
        type ==
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
        type == "image/jpeg" ||
        type == "image/jpg" ||
        type == "image/png" ||
        type == "application/msword" ||
        type == "application/vnd.ms-excel" ||
        type ==
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
        type == "application/vnd.ms-powerpoint" ||
        type == "text/csv"
      ) {
        if (size <= 10485760) {
          //10MB = 10485760 Bytes

          if (attachmentSize < 30932992) {
            //30932992  -> 29.5 MB
            if (Number(size) + Number(attachmentSize) < 30932992) {
              const result = await toBase64(file).catch((e) => e);
              if (result instanceof Error) {
                toast.error(result.message);
                return;
              } else {
                fileList.push({
                  fileName: name,
                  attachment: result.split(",")[1],
                  fileBlob: file
                });
              }
            } else {
              let remaining_KBs = (30932992 - attachmentSize) / 1024; //available space

              remaining_KBs = Number(remaining_KBs).toFixed(2);

              toast.error(
                `You can upload a file of size ${remaining_KBs}KB, Attachmnents limit 29.5MB.`
              );
            }
          } else {
            toast.error(
              "You can't add more attachments. Attachments limit 29.5MB! "
            );
          }
        } else {
          toast.error(
            "This file exceeds the 10MB limit. Please upload a smaller file."
          );
        }
      } else {
        toast.error(
          "Please Select only Attachments of type: 'pdf', 'docx', 'CSV', '.xls', '.xlsx', 'spreadsheets' or 'images'"
        );
      }
    }

    if (attachments.length === fileList.length) {
      await addAttachments(fileList);
    }
  };

  const addAttachments = async (fileList) => {
    if (props.ordersPage) {
      //order --> you can add multiple attachments at a time
      await props.addAttachment(fileList);
    } else {
      // await props.addAttachment(fileList[0].attachment, fileList[0].fileName);
      await props.addAttachment(fileList);
    }
    setState((prev) => ({ ...prev, attachment: "" }));

    // setState({
    //   attachment: "",
    // });
    closeModal();
  };

  const showAttachment = async (recordID, type, fileName) => {
    await props.getAttachment(recordID, type, fileName);
  };


  function onSave() {
    setState(prevState => ({
      ...prevState,
      isLoading: true  // Update the value of isLoading to true
    }));
    dispatch( updatePOAttachment(attachments, props.guid))
  }

  function deleteAttachment(recordId) {
    setState(prevState => ({
      ...prevState,
      isLoading: true  // Update the value of isLoading to true
    }));
    dispatch( deletePOAttachment(recordId));
  }

  function handleSendSupplierCheckbox(e, recordId) {
    const { checked } = e.target;
    attachments?.forEach((data) =>{
      if (data.recordID === recordId){
        data.sendToSupplier = checked ? "Y" : "N";
      }
    })
  }

  return (
    <>
      {state.isLoading ? <div className="se-pre-con"></div> : ""}

      <Modal
        // dialogClassName={props.draft ? "modal-90w" : "modal-50w"}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={props.openAttachmentsModal}
        onHide={closeModal}
        size="lg"
        // className="forgot_email_modal modal_704 mx-auto"
      >
        <Modal.Body>
          <div className="container-fluid ">
            <div className="main_wrapper p-10">
              <div className="row d-flex h-100">
                <div className="col-12 justify-content-center align-self-center form_mx_width">
                  <div className="forgot_form_main">
                    <div className="forgot_header">
                      <div className="modal-top-header">
                        <div className="row bord-btm">
                          <div className="col-auto pl-0">
                            <h6 className="text-left def-blue">Attachments</h6>
                          </div>
                          <div className="col d-flex justify-content-end s-c-main">
                            {
                              props.draft && pathname === "/order" ?
                                <button
                                  onClick={onSave}
                                  type="button"
                                  className="btn-save"
                                >
                                  <span className="fa fa-check"></span>
                                  Save
                                </button> : null
                            }

                            <button
                              onClick={closeModal}
                              type="button"
                              className="btn-save"
                            >
                              <span className="fa fa-ban"></span>
                              Close
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="forgot_body">
                      <div className="row mt-4">
                        <div className="col-md-12">

                          {
                            pathname === "/order" ?
                              <div className="row">
                                <div className="col-md-12">
                                  {
                                    attachments?.length > 0 ?
                                      <table className="table table-bordered">
                                        <thead>
                                        <tr className="attachment-adjust">
                                          <th scope="col" className="attachment-heading"> Description</th>
                                          {props.draft ? <th scope="col" className="attachment-heading text-center">Send to Supplier</th>: null}
                                          {props.draft ? <th scope="col" className="attachment-heading text-center">Delete</th>: null }
                                        </tr>
                                        </thead>

                                        <tbody>
                                        {attachments?.map(item => (
                                          <tr key={item.recordID}>
                                            <td className="attachment-td">
                                              <div className="d-flex position-relative">
                                            <span className={props.draft ? "truncate w-150" : "truncate w-500"}
                                                  data-toggle="tooltip" data-placement="right" title={item.fileName}
                                            >{item.fileName}{" "}</span>
                                                <img
                                                  onClick={() =>
                                                    showAttachment(
                                                      item.recordID ||item.fileID || item.id,
                                                      item.contentType,
                                                      item.fileName || ""
                                                    )
                                                  }
                                                  src="images/attach_icon.png"
                                                  className="import_icon img-fluid iicon  cursorPointer"
                                                />
                                              </div>
                                            </td>

                                            {
                                              props.draft ?
                                                <td className="text-center vertical-mid">
                                                  <input
                                                    type="checkbox"
                                                    id="sendSupplier"
                                                    name="sendSupplier"
                                                    className="sendSupplierCheckbox cursorPointer"
                                                    defaultChecked={item.checked}
                                                    onChange={e => handleSendSupplierCheckbox(e, item.recordID)}
                                                  />
                                                </td>:null
                                            }
                                            {
                                              props.draft ?
                                                <td className="text-center vertical-mid">
                                                  <span className="fa fa-trash fa-lg text-danger cursorPointer" onClick={e => deleteAttachment(item.recordID)}></span>
                                                </td>
                                                : null
                                            }

                                          </tr>
                                        ))}
                                        </tbody>
                                      </table>
                                      :
                                      null
                                  }

                                </div>
                              </div>
                              :
                              <div className="row">
                                <div className="col-md-12">
                                  {
                                    attachments?.length > 0 ?
                                      <table className="table table-bordered">
                                        <thead>
                                        <tr className="attachment-adjust">
                                          <th scope="col" className="attachment-heading"> Description</th>
                                        </tr>
                                        </thead>

                                        <tbody>
                                        {attachments?.map(item => (
                                          <tr key={item.recordID}>
                                            <td className="attachment-td">
                                              <div className="d-flex position-relative">
                                            <span data-toggle="tooltip" data-placement="right" title={item.fileName}
                                            >{item.fileName}{" "}</span>
                                                <img
                                                  onClick={() =>
                                                    showAttachment(
                                                      item.recordID ||item.fileID || item.id,
                                                      item.contentType,
                                                      item.fileName || ""
                                                    )
                                                  }
                                                  src="images/attach_icon.png"
                                                  className="import_icon img-fluid iicon  cursorPointer"
                                                />
                                              </div>
                                            </td>
                                          </tr>
                                        ))}
                                        </tbody>
                                      </table>
                                      :
                                      null
                                  }

                                </div>
                              </div>
                          }
                          {props.draft && (
                            <div className="drag-file-img">
                              <div id="drop-area-attach-modal">
                                <input
                                  type="file"
                                  id="fileElem-modal"
                                  className="form-control d-none"
                                  accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint
                                 , application/pdf, image/jpeg,image/jpg,image/png,
                                  .csv, .xlsx, .xls,
                                  application/vnd.openxmlformats-officedocument.wordprocessingml.document,
                                  application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                  onChange={(e) => {
                                    uploadAttachment(e.target.files);
                                  }}
                                  onClick={(event) => {
                                    event.currentTarget.value = null;
                                  }} //to upload the same file again
                                  multiple={props.ordersPage ? true : false}
                                />
                                <label
                                  className="upload-label"
                                  htmlFor="fileElem-modal"
                                >
                                  <div className="upload-text">
                                    <img
                                      src="images/drag-file.png"
                                      className="import_icon img-fluid"
                                      alt="upload-attachment"
                                    />
                                  </div>
                                </label>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Attachments;
