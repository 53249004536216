const dbName = 'appVersionDB';
const storeName = 'versionStore';
const versionKey = 'appVersion';

/**
 * Opens the IndexedDB database.
 * @returns {Promise<IDBDatabase>} A promise that resolves to the database instance.
 */
async function openDB() {
  if (!window.indexedDB) {
    console.error('IndexedDB is not supported in this browser.');
    throw new Error('IndexedDB is not supported in this browser.');
  }

  return new Promise((resolve, reject) => {
    const request = indexedDB.open(dbName, 1);

    request.onupgradeneeded = (event) => {
      const db = event.target.result;
      if (!db.objectStoreNames.contains(storeName)) {
        db.createObjectStore(storeName);
      }
    };

    request.onsuccess = (event) => {
      resolve(event.target.result);
    };

    request.onerror = (event) => {
      console.error('IndexedDB open error:', event.target.error);
      reject(event.target.error);
    };

    request.onblocked = () => {
      console.warn('IndexedDB open blocked');
    };
  });
}

/**
 * Retrieves the current app version from IndexedDB.
 * @returns {Promise<string|null>} A promise that resolves to the stored version or null if not found.
 */
async function getVersion() {
  try {
    const db = await openDB();
    return new Promise((resolve, reject) => {
      const transaction = db.transaction([storeName], 'readonly');
      const store = transaction.objectStore(storeName);
      const request = store.get(versionKey);

      request.onsuccess = (event) => {
        resolve(event.target.result);
      };

      request.onerror = (event) => {
        console.error('IndexedDB getVersion error:', event.target.error);
        reject(event.target.error);
      };
    });
  } catch (error) {
    console.error('Error opening database:', error);
    return null;
  }
}

/**
 * Sets the current app version in IndexedDB.
 * @param {string} version The current app version.
 * @returns {Promise<void>} A promise that resolves when the version is stored.
 */
async function setVersion(version) {
  try {
    const db = await openDB();
    return new Promise((resolve, reject) => {
      const transaction = db.transaction([storeName], 'readwrite');
      const store = transaction.objectStore(storeName);
      const request = store.put(version, versionKey);

      request.onsuccess = () => {
        resolve();
      };

      request.onerror = (event) => {
        console.error('IndexedDB setVersion error:', event.target.error);
        reject(event.target.error);
      };
    });
  } catch (error) {
    console.error('Error opening database:', error);
    throw error;
  }
}

/**
 * Compares two semantic version strings and determines if an update is needed.
 * The comparison is done using the compareVersions function from the semver package.
 * you can more info about semver https://semver.org
 * @param {string|null} storedVersion - The stored version from IndexedDB.
 * @param {string} currentVersion - The current version of the app.
 * @returns {boolean} - Returns true if an update is needed, otherwise false.
 */
function shouldUpdate(storedVersion, currentVersion) {
  if (!storedVersion) {
    return true;
  }

  const compareVersions = (v1, v2) => {
    const v1Parts = v1.split('.').map(Number);
    const v2Parts = v2.split('.').map(Number);

    for (let i = 0; i < Math.max(v1Parts.length, v2Parts.length); i++) {
      const v1Part = v1Parts[i] || 0;
      const v2Part = v2Parts[i] || 0;

      if (v1Part > v2Part) return 1;
      if (v1Part < v2Part) return -1;
    }

    return 0;
  };

  return compareVersions(storedVersion, currentVersion) < 0;
}

async function clearCache () {
  if (!('caches' in window)) {
    console.warn('Caches are not supported in this browser.');
    return;
  }
  try {
    const cacheKeys = await caches.keys();
    const workBoxCacheKeys = cacheKeys.filter(key => key?.toLowerCase()?.startsWith('workbox'));
    if (cacheKeys.length === 0) {
      console.log('[clearCache] No cache to clear');
      return;
    }
    console.log('[clearCache] Detected Workbox cache', workBoxCacheKeys.length);
    console.log('[clearCache] Total cache keys', cacheKeys.length);
    await Promise.all(workBoxCacheKeys.map(key => caches.delete(key)));
  } catch (error) {
    console.error('[clearCache] Error while clearing cache:', error);
  }
}

export { getVersion, setVersion, shouldUpdate, clearCache };
