import React, { Component } from "react";

import Modal from "react-bootstrap/Modal";
import Select from "react-select";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";

import moment from "moment";
import { toast } from "react-toastify";
import momentZones from "moment-timezone";
import $ from "jquery";
import { connect } from "react-redux";

import { _customStyles } from "../../../../../Constants/Constants";

import "../ColumnsPopupForm/ColumnsPopupForm.css";
import * as AccountActions from "../../../../../Actions/AccountActions/AccountActions";
import { handleAPIErr } from "../../../../../Utils/Helpers";

//setting all dates and time in GMT format
momentZones.tz.setDefault("GMT");
class AddColumnsPopupForm extends Component {
  constructor() {
    super();
    this.state = {
      isLoading: false,
      columnDetails: [],
      reportTypeValue: [],
    };
  }

  async componentWillReceiveProps() {
    let { columnDetails, reportTypeValue } = this.props.state;

    this.setState({ columnDetails, reportTypeValue });
  }
  addColumnOptionDetails = async () => {
    this.setState({ isLoading: true });

    let data = {
      layout: this.state.reportTypeValue[0].label,
      column: this.state.columnDetails,
    };
    await this.props.addColumnOptionDetails(data);
    if (this.props.account.addColumnOptionDetailsSuccess) {
      toast.success(this.props.account.addColumnOptionDetailsSuccess);
      let columnDetails = this.props.account.addColumnOptionDetails || [];

      this.props.closeModal("openAddColumnsPopupFormModal", columnDetails);
    }
    if (this.props.account.addColumnOptionDetailsError) {
      handleAPIErr(this.props.account.addColumnOptionDetailsError, this.props);
    }
    this.setState({ isLoading: false });
  };

  handleChangeVCols = (e, i, type) => {
    let { columnDetails } = this.state;

    if (type === "list") {
      columnDetails[i].value = e.value;
    } else {
      columnDetails[i].value = e.target.value;
    }
    this.setState({ columnDetails });
  };

  clearStates = async () => {
    await this.props.closeModal("openAddColumnsPopupFormModal");
  };

  render() {
    return (
      <>
        {this.state.isLoading ? <div className="se-pre-con"></div> : ""}

        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={this.props.openAddColumnsPopupFormModal}
          onHide={this.clearStates}
          className="modal_704 mx-auto  reports-v4-modal"
        >
          <Modal.Body>
            <div className="container-fluid p-0">
              <div className="main_wrapper">
                <div className="row d-flex h-100 p-0">
                  <div className="col-12 justify-content-center align-self-center">
                    <div className="setting_form_main p-0">
                      <div className="setting_header thead_bg">
                        <h3 className="Indirecttaxcode-poup_heading">
                          Add V Columns
                        </h3>
                        <div className="Indirecttaxcode-poup_can-sav-btn">
                          <button
                            className="btn can-btn1"
                            onClick={this.addColumnOptionDetails}
                          >
                            <img
                              src="images/user-setup/check-white.png"
                              alt="check"
                            />
                            Add
                          </button>
                          <button
                            onClick={this.clearStates}
                            className="btn can-btn1 pl-3"
                          >
                            <img
                              src="images/user-setup/cancel-white.png"
                              alt="cancel"
                            />
                            Cancel
                          </button>
                          <button className="btn can-btn1 pl-2">
                            <img
                              src="images/user-setup/dots-h.png"
                              alt="dots"
                            />
                          </button>
                        </div>
                      </div>
                      <div className="user_setup-poup_body user-setup-modal-inner">
                        <div className="row w-100">
                          <div className="col-md-12">
                            <form action="" className="mm-new-form">
                              {this.state.columnDetails?.map((item, i) => {
                                let found =
                                  item.type === "List" ? null : item.value;
                                if (item.type === "List") {
                                  found = item.options.find(
                                    (rop) => rop.value === item.value
                                  );
                                }
                                return (
                                  <>
                                    {item.type === "List" ? (
                                      <div className="form-group d-flex">
                                        <label htmlFor="" className="pr-2 ">
                                          {item.name}
                                        </label>
                                        <Select
                                          classNamePrefix="custon_select-selector-inner main__dropdown--wrappertwo select__font-wrapper                                        "
                                          value={found}
                                          options={item.options}
                                          onChange={(e) =>
                                            this.handleChangeVCols(e, i, "list")
                                          }
                                          styles={_customStyles}
                                          theme={(theme) => ({
                                            ...theme,
                                            border: 0,
                                            borderRadius: 0,
                                            colors: {
                                              ...theme.colors,
                                              primary25: "#f2f2f2",
                                              primary: "#f2f2f2",
                                            },
                                          })}
                                        />
                                      </div>
                                    ) : item.type === "Date" ? (
                                      <div className="table_input_field">
                                        <DatePicker
                                          selected={Number(item.value)}
                                          dateFormat="d MMM yyyy"
                                          autoComplete="off"
                                          //   onChange={(date) =>
                                          //     this._handleValueOptions(
                                          //       "date",
                                          //       date,
                                          //       list,
                                          //       i
                                          //     )
                                          //   }
                                        />
                                      </div>
                                    ) : item.type === "Check" ? (
                                      <div className="form-group remember_check text-center pt-0 float-left d-flex">
                                        <label htmlFor="" className="pr-2 ">
                                          {item.name}
                                        </label>
                                        <input
                                          type="checkbox"
                                          id={`chk${i}`}
                                          checked={
                                            item.value === "Y" ||
                                            item.value === "1"
                                              ? true
                                              : false
                                          }
                                          onChange={(e) =>
                                            this.handleChangeVCols(
                                              e,
                                              i,
                                              "checkbox"
                                            )
                                          }
                                        />
                                        {/* <label htmlFor={`chk${i}`}></label> */}
                                      </div>
                                    ) : item.type === "Number" ? (
                                      <div className="form-group d-flex">
                                        <label htmlFor="" className="pr-2 ">
                                          {item.name}
                                        </label>
                                        <input
                                          style={{
                                            width: "47.2%",
                                            padding: "4px",
                                          }}
                                          readOnly={
                                            item.readOnly === 1 ? true : false
                                          }
                                          onChange={(e) =>
                                            this.handleChangeVCols(
                                              e,
                                              i,
                                              "number"
                                            )
                                          }
                                          value={found}
                                          type="number"
                                          className="form-control"
                                        />
                                      </div>
                                    ) : item.type === "Range" ||
                                      item.type === "Text" ? (
                                      <div className="form-group d-flex">
                                        <label htmlFor="" className="pr-2 ">
                                          {item.name}
                                        </label>
                                        <input
                                          type="text"
                                          style={{
                                            width: "47.2%",
                                            padding: "4px",
                                          }}
                                          onChange={(e) =>
                                            this.handleChangeVCols(e, i, "text")
                                          }
                                          value={found}
                                          className="form-control"
                                        />
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </>
                                );
                              })}
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => ({ account: state.account });
export default connect(mapStateToProps, {
  addColumnOptionDetails: AccountActions.addColumnOptionDetails,
})(AddColumnsPopupForm);
