import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";

const DisplayExpensesMoreDetails = (props) => {
  const [state, setState] = useState({
    approverGroup: true,
    tran: true,
    currency: true,
    SupplierCode: true,
  });

  useEffect(() => {
    if (props.openDisplayExpensesMoreDetailsModal) {
      let displayexpensesSetting = localStorage.getItem(
        "displayexpensesSetting"
      );
      let parseSetting = JSON.parse(displayexpensesSetting);
      if (displayexpensesSetting) {
        setState((prev) => ({ ...prev, ...parseSetting }));
      }
    }
  }, [props]);

  const closeModal = () => {
    let displayexpensesSetting = localStorage.getItem("displayexpensesSetting");
    let parseSetting = JSON.parse(displayexpensesSetting);
    if (displayexpensesSetting) {
      setState((prev) => ({ ...prev, ...parseSetting }));
    } else {
      setState((prev) => ({
        ...prev,
        approvelGroup: true,
        tran: true,
        currency: true,
        tranCheck: true,
        SupplierCode: true,
      }));
    }

    props.setopenDisplayExpensesMoreDetailsModal(false);
  };

  const handleMoreDetailsCheckboxes = (e) => {
    let { name, checked } = e.target;
    setState((prev) => ({ ...prev, [name]: checked }));
  };

  const onSaveDisplaySetting = () => {
    let { approverGroup, tran, currency, SupplierCode } = state;
    let obj = {
      approverGroup,
      tran,
      currency,
      SupplierCode,
    };
    localStorage.setItem("displayexpensesSetting", JSON.stringify(obj));

    closeModal();
  };
  return (
    <>
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={props.openDisplayExpensesMoreDetailsModal}
        onHide={props.openDisplayExpensesMoreDetailsModal}
        className="forgot_email_modal modal_704 mx-auto"
      >
        <Modal.Body>
          <div className="container-fluid ">
            <div className="main_wrapper p-10">
              <div className="row d-flex h-100">
                <div className="col-12 justify-content-center align-self-center form_mx_width">
                  <div className="forgot_form_main">
                    <div className="forgot_header">
                      <div className="modal-top-header">
                        <div className="row bord-btm">
                          <div className="col-auto pl-0">
                            <h6 className="text-left def-blue">
                              Display Expenses Details
                            </h6>
                          </div>
                          <div className="col d-flex justify-content-end s-c-main">
                            <button
                              onClick={onSaveDisplaySetting}
                              type="button"
                              className="btn-save"
                            >
                              <img
                                src="images/save-check.png"
                                className="mr-2"
                                alt="display-icon"
                              />
                              Save
                            </button>
                            <button
                              onClick={() =>
                                props.setopenDisplayExpensesMoreDetailsModal(
                                  false
                                )
                              }
                              type="button"
                              className="btn-save"
                            >
                              <img
                                src="images/cancel.png"
                                className="mr-2"
                                alt="display-icon"
                              />
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="forgot_body">
                      <div className="row mt-3">
                        <div className="col-md-6">
                          <div className="row no-gutters mb-md-3">
                            <div className="col-auto pr-0">
                              <div className="form-group remember_check">
                                <input
                                  type="checkbox"
                                  id="currentApprover"
                                  name="currentApprover"
                                  checked={state.currentApprover}
                                  onChange={handleMoreDetailsCheckboxes}
                                />

                                <label
                                  htmlFor="currentApprover"
                                  className="float-left"
                                ></label>
                              </div>
                            </div>
                            <div className="col pl-0">
                              <p className="f-20 m-0">Approval Group</p>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="row no-gutters mb-md-3">
                            <div className="col-auto pr-0">
                              <div className="form-group remember_check">
                                <input
                                  type="checkbox"
                                  id="dateCheck"
                                  name="dateCheck"
                                  checked={state.dateCheck}
                                  onChange={handleMoreDetailsCheckboxes}
                                />
                                <label
                                  htmlFor="dateCheck"
                                  className="float-left"
                                ></label>
                              </div>
                            </div>
                            <div className="col pl-0">
                              <p className="f-20 m-0">Tran</p>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="row no-gutters mb-md-3">
                            <div className="col-auto pr-0">
                              <div className="form-group remember_check">
                                <input
                                  type="checkbox"
                                  id="approverdCheck"
                                  name="approverdCheck"
                                  checked={state.approverdCheck}
                                  onChange={handleMoreDetailsCheckboxes}
                                />
                                <label
                                  htmlFor="approverdCheck"
                                  className="float-left"
                                ></label>
                              </div>
                            </div>
                            <div className="col pl-0">
                              <p className="f-20 m-0">Currency</p>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="row no-gutters mb-md-3">
                            <div className="col-auto pr-0">
                              <div className="form-group remember_check">
                                <input
                                  type="checkbox"
                                  id="expensesNumberCheck"
                                  name="expensesNumberCheck"
                                  checked={state.expensesNumberCheck}
                                  onChange={handleMoreDetailsCheckboxes}
                                />
                                <label
                                  htmlFor="expensesNumberCheck"
                                  className="float-left"
                                ></label>
                              </div>
                            </div>
                            <div className="col pl-0">
                              <p className="f-20 m-0">SupplierCode</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default DisplayExpensesMoreDetails;
