import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { connect } from "react-redux";

import * as POActions from "../../../Actions/POActions/POActions";
import {
  handleAPIErr,
} from "../../../Utils/Helpers";
import { toast } from "react-toastify";
class Export extends Component {
  constructor() {
    super();
    this.state = {
      excelData: "",
      formErrors: {
        excelData: "",
      },
    };
  }
    //when clcik on Export from menue item
    exportPOLines = async () => {
      let { poLines } = this.props;
      if(poLines){
        let linesToExport = poLines.filter((l) => l.checked);
        if (linesToExport.length > 0) {
          this.setState({ isLoading: true });
          await this.props.exportPOLines(linesToExport);
          //success case of export PO Lines
          if (this.props.poData.exportPOLinesSuccess) {
            toast.success(this.props.poData.exportPOLinesSuccess);
            let attachemnt = this.props.poData.exportPOLines || "";
            if (attachemnt) {
              window.location.href =
                "data:application/vnd.ms-excel;base64," + attachemnt;
            }
          }
          //error case of export PO Lines
          if (this.props.poData.exportPOLinesError) {
            handleAPIErr(this.props.poData.exportPOLinesError, this.props);
          }
          this.props.clearPOStates();
          this.setState({ isLoading: false });
          this.props.closeModal("openExportSuccessModal")
        } else {
          toast.error("Please Select Lines to Export!");
        }
      }else {
        this.props.closeModal("openExportSuccessModal")

      }
    
    };
  render() {
    return (
      <>
        {this.state.isLoading ? <div className="se-pre-con"></div> : ""}

        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={this.props.openExportSuccessModal}
          onHide={() => this.props.closeModal("openExportSuccessModal")}
          className="forgot_email_modal modal_704 mx-auto"
        >
          <Modal.Body>
            <div className="container-fluid ">
              <div className="main_wrapper p-10">
                <div className="row d-flex h-100">
                  <div className="col-12 justify-content-center align-self-center form_mx_width">
                    <div className="forgot_form_main">
                      <div className="forgot_header">
                        <div className="modal-top-header">
                          <div className="row bord-btm">
                            <div className="col-auto pl-0">
                              <h6 className="text-left def-blue">Export</h6>
                            </div>
                            <div className="col d-flex justify-content-end s-c-main">
                              <button
                                onClick={() =>
                                  this.exportPOLines()
                                }
                                type="button"
                                className="btn-save"
                              >
                                <span className="fa fa-check"></span>
                                OK
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="forgot_body">
                        <div className="row">
                          <div className="col-12">
                            <p className="model-p move-modal-t">
                              File Created.
                            </p>
                          </div>
                          <div className="col-12"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  poData: state.poData,
});

export default connect(mapStateToProps, {
  exportPOLines: POActions.exportPOLines,
  clearPOStates: POActions.clearPOStates,
})(Export);
