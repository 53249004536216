import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import {
    getUsersList,
    primeTransferTransactions,
    transferTransactions
} from "../../../Actions/UserActions/UserActions";
import "../../../Containers/Setup/SetupSASS/setupStyle.scss";
import { toast } from "react-toastify";

export const BulkTransferRecords = ({ closeModal, userTallyRecord, onSuccess , userList}) => {
    const dispatch = useDispatch();
    // const userList = useSelector(state => state.user.primeTransferTransaction);
    const user = useSelector(state => state.user);

    const [selectedUser, setSelectedUser] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [selectionState, setSelectionState] = useState({
        orderTally: false,
        invoiceTally: false,
        expenseTally: false,
        documentTally: false,
        timecardTally: false,
        transferDraft: false,
        transferPending: false,
        transferApproved: false,
    })

    useEffect(() => {
        if (!userList) {
            fetchUserList();
        }
    }, []);

    useEffect(() => {
        if (user.transferTransactionSuccess && user.transferTransactionSuccess.length) {
            user.transferTransactionSuccess.forEach(result => {
                    toast[result.status === 'Success' ? 'success' : 'error'](result.description);
            });
            closeModal();
            onSuccess();
        }
    }, [user.transferTransactionSuccess ]);

    useEffect(()=>{
        if (user.transferTransactionError) {
            toast.error(user.transferTransactionError);
            closeModal();
            onSuccess();
        }
    },[user.transferTransactionError])

    const fetchUserList = async () => {
        setIsLoading(true);
        await dispatch(primeTransferTransactions())
        setIsLoading(false);
    }

    const onCheckChange = (e) => {
        setSelectionState({
            ...selectionState,
            [e.target.name]: e.target.checked
        })
    }

    const handleSubmit = async () => {
        if (!selectedUser) {
            return;
        }
        setIsLoading(true);

        const { transferDraft, transferApproved, transferPending, documentTally, expenseTally, invoiceTally, orderTally, timecardTally } = selectionState;

        const params = {
            oldUser: userTallyRecord.userLogin,
            newUser: selectedUser.value,
            transferDraft: transferDraft ? 'Y' : 'N',
            transferApproved: transferApproved ? 'Y' : 'N',
            transferPending: transferPending ? 'Y' : 'N',
            modules: [
                {
                    module: "Orders",
                    selected: orderTally ? 'Y' : 'N'
                },
                {
                    module: "Invoices",
                    selected: invoiceTally ? 'Y' : 'N'
                },
                {
                    module: "Expenses",
                    selected: expenseTally ? 'Y' : 'N'
                },
                {
                    module: "Documents",
                    selected: documentTally ? 'Y' : 'N'
                },
                {
                    module: "Timecards",
                    selected: timecardTally ? 'Y' : 'N'
                }
            ]
        }
        await dispatch(transferTransactions(params));
        setIsLoading(false);
    }

    return (
        <>
            {isLoading ? <div className="se-pre-con"></div> : ""}
            <Modal
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show
                // onHide={clearStates}
                className="forgot_email_modal modal_704 mx-auto"
            >
                <Modal.Body>
                    <div className="container-fluid ">
                        <div className="main_wrapper p-10">
                            <div className="row d-flex h-100">
                                <div className="col-12 justify-content-center align-self-center form_mx_width">
                                    <div className="forgot_form_main">
                                        <div className="forgot_header">
                                            <div className="modal-top-header">
                                                <div className="row bord-btm">
                                                    <div className="col-auto pl-0 d-flex align-items-end">
                                                        <h6 className="text-left def-blue mr-2">Ownership Transfer</h6>
                                                    </div>

                                                    <div className="col d-flex justify-content-end s-c-main">
                                                        <button
                                                            disabled={!selectedUser}
                                                            onClick={handleSubmit}
                                                            type="button"
                                                            className="btn-save"
                                                        >
                                                            <span className="fa fa-check"></span>
                                                            Transfer
                                                        </button>
                                                        <button
                                                            onClick={() => closeModal("openMoveModal")}
                                                            type="button"
                                                            className="btn-save"
                                                        >
                                                            <span className="fa fa-ban"></span>
                                                            Cancel
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="forgot_body px-3">
                                            <div className="row mt-4">
                                                <div className="col-md-12">

                                                    <span>Old User: {userTallyRecord.userLogin}</span>

                                                    <div className="mt-4 mb-4">
                                                        <span>Modules: </span>

                                                        <div className=" mt-1 form-group remember_check form-group_sass remember_check_sass mm_check4">
                                                            <input
                                                                type="checkbox"
                                                                id="orderTally"
                                                                name="orderTally"
                                                                onChange={onCheckChange}
                                                            />
                                                            <label
                                                                htmlFor="orderTally"

                                                            >
                                                                Orders &nbsp;&nbsp;<b className="font-weight-bold">[{userTallyRecord.orderTally}]</b>
                                                            </label>
                                                        </div>

                                                        <div className=" mt-1 form-group remember_check form-group_sass remember_check_sass mm_check4">
                                                            <input
                                                                type="checkbox"
                                                                id="invoiceTally"
                                                                name="invoiceTally"
                                                                onChange={onCheckChange}
                                                            />
                                                            <label
                                                                htmlFor="invoiceTally"

                                                            >
                                                                Invoices&nbsp;&nbsp;<b className="font-weight-bold">[{userTallyRecord.invoiceTally}]</b>
                                                            </label>
                                                        </div>

                                                        <div className=" mt-1 form-group remember_check form-group_sass remember_check_sass mm_check4">
                                                            <input
                                                                type="checkbox"
                                                                id="expenseTally"
                                                                name="expenseTally"
                                                                onChange={onCheckChange}
                                                            />
                                                            <label
                                                                htmlFor="expenseTally"

                                                            >
                                                                Expenses&nbsp;&nbsp;<b className="font-weight-bold">[{userTallyRecord.expenseTally}]</b>
                                                            </label>
                                                        </div>

                                                        <div className=" mt-1 form-group remember_check form-group_sass remember_check_sass mm_check4">
                                                            <input
                                                                type="checkbox"
                                                                id="documentTally"
                                                                name="documentTally"
                                                                onChange={onCheckChange}
                                                            />
                                                            <label
                                                                htmlFor="documentTally"

                                                            >
                                                                Documents&nbsp;&nbsp;<b className="font-weight-bold">[{userTallyRecord.documentTally}]</b>
                                                            </label>
                                                        </div>

                                                        <div className=" mt-1 mb-3 form-group remember_check form-group_sass remember_check_sass mm_check4">
                                                            <input
                                                                type="checkbox"
                                                                id="timecardTally"
                                                                name="timecardTally"
                                                                onChange={onCheckChange}
                                                            />
                                                            <label
                                                                htmlFor="timecardTally"

                                                            >
                                                                Timecards&nbsp;&nbsp;<b className="font-weight-bold">[{userTallyRecord.timecardTally}]</b>
                                                            </label>
                                                        </div>

                                                        <span>Record Type: </span>

                                                        <div className=" mt-1 form-group remember_check form-group_sass remember_check_sass mm_check4">
                                                            <input
                                                                type="checkbox"
                                                                id="transferDraft"
                                                                name="transferDraft"
                                                                onChange={onCheckChange}
                                                            />
                                                            <label
                                                                htmlFor="transferDraft"

                                                            >
                                                                Drafts
                                                            </label>
                                                        </div>

                                                        <div className=" mt-1 form-group remember_check form-group_sass remember_check_sass mm_check4">
                                                            <input
                                                                type="checkbox"
                                                                id="transferPending"
                                                                name="transferPending"
                                                                onChange={onCheckChange}
                                                            />
                                                            <label
                                                                htmlFor="transferPending"

                                                            >
                                                                Pending Approval
                                                            </label>
                                                        </div>

                                                        <div className=" mt-1 form-group remember_check form-group_sass remember_check_sass mm_check4">
                                                            <input
                                                                type="checkbox"
                                                                id="transferApproved"
                                                                name="transferApproved"
                                                                onChange={onCheckChange}
                                                            />
                                                            <label
                                                                htmlFor="transferApproved"

                                                            >
                                                                Fully Approved
                                                            </label>
                                                        </div>
                                                    </div>

                                                    <div className="form-group custon_select">
                                                        <span>New User</span>
                                                        <Select
                                                            className="width-selector"
                                                            value={selectedUser}
                                                            classNamePrefix="custon_select-selector-inner"
                                                            options={userList ? userList.map(u => ({ label: u.userLogin, value: u.userLogin })) : []}
                                                            onChange={(u) => setSelectedUser(u)}
                                                            theme={(theme) => ({
                                                                ...theme,
                                                                border: 0,
                                                                borderRadius: 0,
                                                                colors: {
                                                                    ...theme.colors,
                                                                    primary25: "#f2f2f2",
                                                                    primary: "#f2f2f2",
                                                                },
                                                            })}
                                                        />
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}
