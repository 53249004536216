export default function VideoTutorials() {
  return (
    <div className="user_setup_headerbox">
      <div className="user_setup_play_div">
        <ul>
          <li>
            <p className="user_setup_play_video  user_setup_play_video_sass">
              Video
            </p>
          </li>
          <li>
            <p className="user_setup_play_tuturial user_setup_play_tuturial_sass">
              Tutorials
            </p>
          </li>
        </ul>
        <span className="user_setup_play_icon user_setup_play_icon_svg_sass">
          <svg
            version="1.1"
            id="Capa_1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            width="24px"
            height="24px"
            viewBox="0 0 490.661 490.661"
          >
            <g>
              <g>
                <path
                  d="M453.352,236.091L48.019,1.424c-3.285-1.899-7.36-1.899-10.688,0c-3.285,1.899-5.333,5.419-5.333,9.237v469.333
c0,3.819,2.048,7.339,5.333,9.237c1.643,0.939,3.499,1.429,5.333,1.429c1.856,0,3.691-0.469,5.355-1.429l405.333-234.667
c3.285-1.92,5.312-5.44,5.312-9.237S456.637,237.989,453.352,236.091z"
                />
              </g>
            </g>
          </svg>
        </span>
      </div>
      <div className="user_setup_header_rightbox">
        <p>
          In our{" "}
          <span>
            <a href="#" className="video_hlepArtical_sass">
              Video
            </a>
          </span>{" "}
          learn how to use user setup Read our{" "}
          <span>
            <a href="#" className="video_hlepArtical_sass">
              help article
            </a>
          </span>{" "}
          to learn More
        </p>
      </div>
      <span
        className="round_plus close_top_sec round_plus_sass"
        style={{
          display: "flex",
          width: "22px",
          height: "22px",
          alignItems: "center",
          borderRadius: "50%",
          justifyContent: "center",
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="16px"
          viewBox="0 0 24 24"
          width="16px"
          fill="white"
        >
          <path d="M0 0h24v24H0z" fill="none" />
          <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
        </svg>
      </span>
    </div>
  );
}
