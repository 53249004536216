import React, { useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { userAvatar } from "../../../Constants/Constants";
import { handleAPIErr } from "../../../Utils/Helpers";
import { toast } from "react-toastify";

const SupplierAddress = (props) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [showConfirmation, setShowConfirmation] = React.useState(false);
  const [id, setId] = React.useState(false);
  const [abn_tax, setABN] = React.useState("");
  const [companyAddress, setCompanyAddress] = React.useState({
    caddress: "",
    caddress2: "",
    ccity: "",
    cstate: "",
    cpostcode: "",
    ccountry: "",
  });
  const [postalAddress, setPostalAddress] = React.useState({
    paddress: "",
    paddress2: "",
    pcity: "",
    pstate: "",
    ppostcode: "",
    pcountry: "",
  });
  const { companyAddressDetails, postalAddressDetails, abn } = props;

  useEffect(() => {
    setCompanyAddress(companyAddressDetails);
    setABN(abn);
  }, [companyAddressDetails]);

  useEffect(() => {
    setPostalAddress(postalAddressDetails);
  }, [postalAddressDetails]);

  function clearStates() {
    props.closeModal("openSupplierAddressModal");
  }
  function onFocusButtons(e) {
    let id = e.target.id;
    setId({ [id]: true });
  }

  function onBlurButtons(e) {
    let id = e.target.id;
    setId({ [id]: false });
  }

  function handleCompanyAddress(e) {
    const { name, value } = e.target;
    setCompanyAddress({
      ...companyAddress,
      [name]: value,
    });
  }

  function handleSupplierAddress(e) {
    const { name, value } = e.target;
    setPostalAddress({
      ...postalAddress,
      [name]: value,
    });
  }

  function handleABN(e) {
    const { value } = e.target;
    setABN(value);
  }

  async function onSave() {
    let supplierDetails = {
      currency: props.currency,
      code: props.supplierCode,
    };
    let finalCompanyAddress = {
      address: companyAddress.caddress,
      address2: companyAddress.caddress2,
      city: companyAddress.ccity,
      state: companyAddress.cstate,
      postcode: companyAddress.cpostcode,
      country: companyAddress.ccountry,
    };

    let updatedPostalAddress = {
      address: postalAddress.paddress,
      address2: postalAddress.paddress2,
      city: postalAddress.pcity,
      state: postalAddress.pstate,
      postcode: postalAddress.ppostcode,
      country: postalAddress.pcountry,
    };
    await props.updateSupplierAddress(
      supplierDetails,
      updatedPostalAddress,
      finalCompanyAddress,
      abn_tax
    );
  }

  useEffect(() => {
    if (props.updateSupplierAddressSuccess) {
      clearStates();
      toast.success(props.updateSupplierAddressSuccess);
    }
    if (props.updateSupplierAddressError) {
      handleAPIErr(props.supplier.getSupplierError, props);
    }
  }, [props.updateSupplierAddressSuccess]);

  function handleCopyCompanyAddress() {
    if (
      postalAddressDetails.paddress ||
      postalAddressDetails.paddress2 ||
      postalAddressDetails.pcity ||
      postalAddressDetails.pstate ||
      postalAddressDetails.pcountry ||
      postalAddressDetails.ppostcode
    ) {
      setShowConfirmation(true);
    } else {
      setPostalAddress({
        paddress: companyAddress.caddress,
        paddress2: companyAddress.caddress2,
        pcity: companyAddress.ccity,
        pstate: companyAddress.cstate,
        ppostcode: companyAddress.cpostcode,
        pcountry: companyAddress.ccountry,
      });
    }
  }

  function closeConfirmationModal() {
    setShowConfirmation(false);
  }

  function handleCopyCompanyAddressModal() {
    setPostalAddress({
      paddress: companyAddress.caddress,
      paddress2: companyAddress.caddress2,
      pcity: companyAddress.ccity,
      pstate: companyAddress.cstate,
      ppostcode: companyAddress.cpostcode,
      pcountry: companyAddress.ccountry,
    });
    setShowConfirmation(false);
  }

  return (
    <div>
      {isLoading ? <div className="se-pre-con"></div> : ""}
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={props.openSupplierAddressModal}
        onHide={clearStates}
        className="forgot_email_modal modal_704 mx-auto"
      >
        <Modal.Body>
          <div className="container-fluid ">
            <div className="main_wrapper p-10">
              <div className="row d-flex h-100">
                <div className="col-12 justify-content-center align-self-center form_mx_width">
                  <div className="forgot_form_main">
                    <div className="row mb-3">
                      <div className="col-sm-6 text-black-50">
                        <h4>Company Address</h4>
                      </div>
                      <div className="col d-flex justify-content-end s-c-main">
                        <button
                          onClick={() => clearStates()}
                          type="button"
                          className="btn-save"
                        >
                          <span className="fa fa-ban"></span>
                          Close
                        </button>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-6">
                        <div className="custon_select">
                          <label htmlFor="id_vndr">Address</label>
                          <div className="modal_input">
                            <input
                              type="text"
                              className="form-control focus_vender"
                              id="id_vndr"
                              tabIndex="3333"
                              autoFocus={true}
                              autoComplete="off"
                              name="caddress"
                              value={companyAddress?.caddress}
                              onChange={handleCompanyAddress}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="custon_select">
                          <label htmlFor="id_vndr">Address2</label>
                          <div className="modal_input">
                            <input
                              type="text"
                              className="form-control focus_vender"
                              id="id_vndr"
                              tabIndex="3333"
                              autoFocus={true}
                              autoComplete="off"
                              name={"caddress2"}
                              value={companyAddress?.caddress2}
                              onChange={(e) => handleCompanyAddress(e)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-6">
                        <div className="custon_select">
                          <label htmlFor="id_vndr">City</label>
                          <div className="modal_input">
                            <input
                              type="text"
                              className="form-control focus_vender"
                              id="id_vndr"
                              tabIndex="3333"
                              autoFocus={true}
                              autoComplete="off"
                              name={"ccity"}
                              value={companyAddress?.ccity}
                              onChange={(e) => handleCompanyAddress(e)}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="custon_select">
                          <label htmlFor="id_vndr">State</label>
                          <div className="modal_input">
                            <input
                              type="text"
                              className="form-control focus_vender"
                              id="id_vndr"
                              tabIndex="3333"
                              autoFocus={true}
                              autoComplete="off"
                              name={"cstate"}
                              value={companyAddress?.cstate}
                              onChange={(e) => handleCompanyAddress(e)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-6">
                        <div className="custon_select">
                          <label htmlFor="id_vndr">Post Code</label>
                          <div className="modal_input">
                            <input
                              type="text"
                              className="form-control focus_vender"
                              id="id_vndr"
                              tabIndex="3333"
                              autoFocus={true}
                              autoComplete="off"
                              name={"cpostcode"}
                              value={companyAddress?.cpostcode}
                              onChange={(e) => handleCompanyAddress(e)}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="custon_select">
                          <label htmlFor="id_vndr">Country</label>
                          <div className="modal_input">
                            <input
                              type="text"
                              className="form-control focus_vender"
                              id="id_vndr"
                              tabIndex="3333"
                              autoFocus={true}
                              autoComplete="off"
                              name={"ccountry"}
                              value={companyAddress?.ccountry}
                              onChange={(e) => handleCompanyAddress(e)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-sm-6">
                        <h4>Postal Address</h4>
                      </div>
                      <div className="col-sm-6">
                        <div className="col d-flex justify-content-end s-c-main w-sm-100">
                          <button
                            type="button"
                            className={"btn-save ml-0 btn_focus"}
                            id="id_save"
                            tabIndex={"3346"}
                            onClick={handleCopyCompanyAddress}
                          >
                            <i
                              className="fa fa-arrow-down"
                              aria-hidden="true"
                            ></i>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-6">
                        <div className="custon_select">
                          <label htmlFor="id_vndr">Address</label>
                          <div className="modal_input">
                            <input
                              type="text"
                              className="form-control focus_vender"
                              id="id_vndr"
                              tabIndex="3333"
                              autoFocus={true}
                              autoComplete="off"
                              name="paddress"
                              value={postalAddress?.paddress}
                              onChange={handleSupplierAddress}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="custon_select">
                          <label htmlFor="id_vndr">Address2</label>
                          <div className="modal_input">
                            <input
                              type="text"
                              className="form-control focus_vender"
                              id="id_vndr"
                              tabIndex="3333"
                              autoFocus={true}
                              autoComplete="off"
                              name={"paddress2"}
                              value={postalAddress?.paddress2}
                              onChange={(e) => handleSupplierAddress(e)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-6">
                        <div className="custon_select">
                          <label htmlFor="id_vndr">City</label>
                          <div className="modal_input">
                            <input
                              type="text"
                              className="form-control focus_vender"
                              id="id_vndr"
                              tabIndex="3333"
                              autoFocus={true}
                              autoComplete="off"
                              name={"pcity"}
                              value={postalAddress?.pcity}
                              onChange={(e) => handleSupplierAddress(e)}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="custon_select">
                          <label htmlFor="id_vndr">State</label>
                          <div className="modal_input">
                            <input
                              type="text"
                              className="form-control focus_vender"
                              id="id_vndr"
                              tabIndex="3333"
                              autoFocus={true}
                              autoComplete="off"
                              name="pstate"
                              value={postalAddress?.pstate}
                              onChange={(e) => handleSupplierAddress(e)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-6">
                        <div className="custon_select">
                          <label htmlFor="id_vndr">Post Code</label>
                          <div className="modal_input">
                            <input
                              type="text"
                              className="form-control focus_vender"
                              id="id_vndr"
                              tabIndex="3333"
                              autoFocus={true}
                              autoComplete="off"
                              name={"ppostcode"}
                              value={postalAddress?.ppostcode}
                              onChange={(e) => handleSupplierAddress(e)}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="custon_select">
                          <label htmlFor="id_vndr">Country</label>
                          <div className="modal_input">
                            <input
                              type="text"
                              className="form-control focus_vender"
                              id="id_vndr"
                              tabIndex="3333"
                              autoFocus={true}
                              autoComplete="off"
                              name={"pcountry"}
                              value={postalAddress?.pcountry}
                              onChange={(e) => handleSupplierAddress(e)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row mb-3">
                      <div className="col-sm-6">
                        <h4>Tax ID/ ABN</h4>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-6">
                        <div className="custon_select">
                          <label htmlFor="id_vndr">Tax ID/ ABN#</label>
                          <div className="modal_input">
                            <input
                              type="text"
                              className="form-control focus_vender"
                              id="id_vndr"
                              tabIndex="3333"
                              autoFocus={true}
                              autoComplete="off"
                              name={"supplierName"}
                              defaultValue={abn_tax}
                              onChange={(e) => handleABN(e)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col d-flex justify-content-end s-c-main w-sm-100">
                        <button
                          type="button"
                          className={"btn-save ml-0 btn_focus"}
                          id="id_save"
                          tabIndex={"3346"}
                          onFocus={onFocusButtons}
                          onBlur={onBlurButtons}
                          onClick={onSave}
                        >
                          <span className="fa fa-check"></span>
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showConfirmation}
        onHide={closeConfirmationModal}
        className="forgot_email_modal modal_704 mx-auto"
      >
        <Modal.Body>
          <div className="container-fluid ">
            <div className="main_wrapper p-10">
              <div className="row d-flex h-100">
                <div className="col-12 justify-content-center align-self-center form_mx_width">
                  <div className="forgot_form_main">
                    <div className="forgot_header">
                      <div className="modal-top-header">
                        <div className="row bord-btm">
                          <div className="col-auto pl-0">
                            <h6 className="text-left def-blue">
                              Copy Company Address
                            </h6>
                          </div>
                          <div className="col d-flex justify-content-end s-c-main">
                            <button
                              onClick={handleCopyCompanyAddressModal}
                              type="button"
                              className="btn-save"
                            >
                              <span className="fa fa-check"></span>
                              Yes
                            </button>
                            <button
                              onClick={closeConfirmationModal}
                              type="button"
                              className="btn-save"
                            >
                              <span className="fa fa-ban"></span>
                              No
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="forgot_body">
                      <div className="row">
                        <div className="col-12">
                          <p className="model-p move-modal-t">
                            This will overwrite Postal Address. Do you wish to
                            continue?
                          </p>
                        </div>
                        <div className="col-12"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default SupplierAddress;
