import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Routes from "./Routes/Routes";

import "./App.css";
import { Provider } from "react-redux";
import store from "./Store/index";
import { toast, ToastContainer} from "react-toastify";
import { WebSocketConnector } from "./Containers/WebSocket/WebSocketConnector";
import "rc-time-picker/assets/index.css";
import "react-toastify/dist/ReactToastify.css";
import FileUploadStatus from "./Components/FileUploadStatus/FileUploadStatus";
import { clearCache, getVersion, setVersion, shouldUpdate } from './Utils/version';
import { APP_VERSION } from "./Constants/Constants";

class App extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    clearCache().finally(() => {
      this.updateVersion();
    });    
  }

  async updateVersion() {
    const storedVersion = await getVersion();
    const currentVersion = APP_VERSION;
    if (shouldUpdate(storedVersion, currentVersion)) {
      await setVersion(currentVersion);
      toast.success("Updated the application successfully, please refresh the page");
    }
  }

  render() {
    const childProps = {};
    return (
      <Provider store={store}>
        <Routes childProps={childProps} />
        <ToastContainer />
        <WebSocketConnector />
        <FileUploadStatus />
      </Provider>
    );
  }
}

export default withRouter(App);
