export default function ProductionList({
  productions,
  currentProductionName,
  onProductionClick,
  onProductionDoubleClick,
}) {
  function handleOnProductionClick(productionName) {
    onProductionClick(productionName);
  }

  function handleOnProductionDoubleClick(productionName) {
    onProductionDoubleClick(productionName);
  }
  return (
    <table className="table table-hover mb-0">
      <thead>
        <tr>
          <th scope="col">Business Unit</th>
          <th scope="col">Approve PO</th>
          <th scope="col">Approve Invoices</th>
        </tr>
      </thead>
      <tbody>
        {productions?.map((prod, i) => {
          return (
            <tr
              key={prod.productionName}
              onClick={() => handleOnProductionClick(prod.productionName)}
              onDoubleClick={() =>
                handleOnProductionDoubleClick(prod.productionName)
              }
              className={
                (currentProductionName === prod.productionName) || (productions.length === 1)
                  ? "active cursorPointer"
                  : "cursorPointer"
              }
            >
              <th scope="row">{prod.productionName}</th>
              <td>
                {prod.approveOrders || prod.approveOrders == 0
                  ? prod.approveOrders
                  : ""}
              </td>
              <td>
                {prod.approveInvoices || prod.approveInvoices == 0
                  ? prod.approveInvoices
                  : ""}
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}
