import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import Select from "react-select";
import { options, _customStyles } from "../../../../../Constants/Constants";
import DatePicker from "react-datepicker";
import { connect } from "react-redux";
import * as AccountActions from "../../../../../Actions/AccountActions/AccountActions";
import * as Validation from "../../../../../Utils/Validation";

import {
  handleAPIErr,
  handleHideUnhideRows,
  handleValueOptions,
} from "../../../../../Utils/Helpers";
import { toast } from "react-toastify";
// import $ from "jquery";

class CreateJournal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      advancedOptions: [],
      clonneAdvanceOptions: [],
      newRefId: "",
      showHiddenRows: false,
      reDrawDT: false, //re draw datatable
      companyOptVal: [],
      companyOpt: [],
      companyOptions: [],

      formErrors: {
        reportTypesVal: "",
        reportOptionVal: "",
        reportFile: "",
        reportName: "",
      },
    };
  }

  componentWillReceiveProps() {
    let advancedOptions = this?.props?.options || [];
    let clonneAdvanceOptions = this?.props?.clonedOptions || [];
    let newRefId = this?.props?.newRefId || "";

    this.setState({
      advancedOptions,
      clonneAdvanceOptions,
      newRefId,
    });
  }

  _handleValueOptions = async (type, val, item, index) => {
    let { advancedOptions, clonneAdvanceOptions } = this.state;
    let result = handleValueOptions(
      type,
      val,
      item,
      index,
      advancedOptions,
      clonneAdvanceOptions
    );
    this.setState({
      ...this.state,
      advancedOptions: result.advancedList,
      clonneAdvanceOptions: result.clonedAdvancedList,
    });
  };

  _handleHideUnhideRows = (item) => {
    let { advancedOptions, clonneAdvanceOptions, showHiddenRows } = this.state;

    let result = handleHideUnhideRows(
      item,
      "#reportv4-table",
      "reports__settings",
      advancedOptions,
      clonneAdvanceOptions,
      showHiddenRows
    );

    this.setState({
      advancedOptions: result.advancedList,
      clonneAdvanceOptions: result.clonedAdvancedList,
      showHiddenRows: result.showHiddenRows,
      reDrawDT: true,
    });
  };

  handleShowHiddenRows = async () => {
    let table = window.$("#reportv4-table").DataTable();
    table.destroy();

    let { advancedOptions, clonneAdvanceOptions, showHiddenRows } = this.state;

    showHiddenRows = !showHiddenRows;

    if (showHiddenRows) {
      //show hidden rows
      advancedOptions = clonneAdvanceOptions;
    } else {
      //hide again hidden rows
      let list = advancedOptions.filter((l) => !l.hide);
      advancedOptions = list;
    }

    this.setState({
      advancedOptions,
      showHiddenRows,
      reDrawDT: true, //to re daraw the datatable
    });
  };

  onCreateJournal = async () => {
    this.setState({ isLoading: true });
    let { advancedOptions, newRefId } = this.state;
    let saveJournal = {
      bankCode: "B1",
      guidList: newRefId,
      options: advancedOptions,
    };
    await this.props.createJournal(saveJournal);
    if (this.props.account.createJournalSuccess) {
      toast.success(this.props.account.createJournalSuccess);
    }
    if (this.props.account.createJournalError) {
      handleAPIErr(this.props.account.createJournalError, this.props);
    }
    this.props.closeModal("openCreateJournalModal");
    this.setState({ isLoading: false });
  };

  handleReportTypes = (type) => {
    let { formErrors } = this.state;

    formErrors = Validation.handleValidation(
      "reportTypesVal",
      type.value,
      formErrors
    );

    let value = type.value || "";

    let getReportOptions = this.state.companyOptions || [];
    let reportOptions = [];

    let found = getReportOptions.find((rop) => rop.id === value);

    if (found) {
      // let reportLayouts = found || [];

      reportOptions.push({
        label: found.name,
        value: found.id,
        selected: 1,
      });
    }
    this.setState({
      reportTypesVal: type,
      companyOptVal: reportOptions,
      formErrors,
    });
  };

  render() {
    return (
      <>
        {/* {this.state.isLoading ? <div className="se-pre-con"></div> : ""} */}

        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={this.props.openCreateJournalModal}
          onHide={() => this.props.closeModal("openCreateJournalModal")}
          className="modal_704 mx-auto reports-v4-modal"
        >
          <Modal.Body>
            <div className="container-fluid p-0">
              <div className="main_wrapper">
                <div className="row d-flex h-100 p-0">
                  <div className="col-12 justify-content-center align-self-center">
                    <div className="setting_form_main p-0">
                      <div className="setting_header thead_bg">
                        <h3 className="Indirecttaxcode-poup_heading">
                          Create Journal
                        </h3>
                        <div className="Indirecttaxcode-poup_can-sav-btn">
                          <button
                            onClick={this.onCreateJournal}
                            className="btn can-btn1"
                          >
                            <img
                              src="images/user-setup/check-white.png"
                              alt="check"
                            />
                            Save
                          </button>
                          <button
                            onClick={() =>
                              this.props.closeModal("openCreateJournalModal")
                            }
                            className="btn can-btn1 pl-3"
                          >
                            <img
                              src="images/user-setup/cancel-white.png"
                              alt="cancel"
                            />
                            Cancel
                          </button>
                          <button className="btn can-btn1 pl-2">
                            <img
                              src="images/user-setup/dots-h.png"
                              alt="dots"
                            />
                          </button>
                        </div>
                      </div>
                      <div className="reportv4-modal-inner">
                        <div className="reports_popup__table">
                          <div className="reconciliation-table1 table_white_box">
                            <table
                              className="table"
                              id="reportv4-table"
                              width="100%"
                            >
                              <thead className="thead_bg hover-border">
                                <tr>
                                  <th scope="col"></th>
                                  <th scope="col">
                                    <span className="user_setup_hed">
                                      Category
                                    </span>
                                  </th>
                                  <th scope="col">
                                    <span className="user_setup_hed">
                                      Description
                                    </span>
                                  </th>
                                  <th scope="col">
                                    <span className="user_setup_hed">
                                      Value
                                    </span>
                                  </th>
                                  <th scope="col">
                                    <span className="user_setup_hed">Hide</span>
                                  </th>
                                  <th className="table__inner--th">
                                    <div className="dropdown">
                                      <button
                                        aria-haspopup="true"
                                        aria-expanded="true"
                                        id=""
                                        type="button"
                                        className="dropdown-toggle btn dept-tbl-menu "
                                        data-toggle="dropdown"
                                      >
                                        <span className="fa fa-bars "></span>
                                      </button>
                                      <div className="dropdown-menu dept-menu-list dropdown-menu-right">
                                        <div className="pr-0 dropdown-item">
                                          <div className="form-group remember_check mm_check4">
                                            <input
                                              type="checkbox"
                                              id="showHiddenRows"
                                              name="showHiddenRows"
                                              checked={
                                                this.state.showHiddenRows
                                              }
                                              onClick={
                                                this.handleShowHiddenRows
                                              }
                                            />
                                            <label
                                              htmlFor="showHiddenRows"
                                              className="mr-0"
                                            >
                                              Show Hidden Rows
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.advancedOptions?.map((list, i) => {
                                  return (
                                    <tr>
                                      <td></td>
                                      <td>{list.category}</td>
                                      <td>{list.description}</td>
                                      {list.valueType === "List" ? (
                                        <td className="pt-0 pb-0 text-left  value__field--wrapperdept ">
                                          <Select
                                            classNamePrefix="custon_select-selector-inner main__dropdown--wrappertwo select__font-wrapper                                        "
                                            value={{
                                              label: list.value,
                                              value: list.value,
                                            }}
                                            options={list.options}
                                            onChange={(obj) =>
                                              this._handleValueOptions(
                                                "list",
                                                obj,
                                                list,
                                                i
                                              )
                                            }
                                            styles={_customStyles}
                                            theme={(theme) => ({
                                              ...theme,
                                              border: 0,
                                              borderRadius: 0,
                                              colors: {
                                                ...theme.colors,
                                                primary25: "#f2f2f2",
                                                primary: "#f2f2f2",
                                              },
                                            })}
                                          />
                                        </td>
                                      ) : list.valueType === "Date" ? (
                                        <td>
                                          <div className="table_input_field">
                                            <DatePicker
                                              selected={Number(list.value)}
                                              dateFormat="d MMM yyyy"
                                              autoComplete="off"
                                              onChange={(date) =>
                                                this._handleValueOptions(
                                                  "date",
                                                  date,
                                                  list,
                                                  i
                                                )
                                              }
                                              // readOnly={list.readOnly}
                                            />
                                          </div>
                                        </td>
                                      ) : list.valueType === "Check" ? (
                                        <td>
                                          <div className="col-auto p-0">
                                            <div className="form-group remember_check text-center pt-0 float-left">
                                              <input
                                                type="checkbox"
                                                id={`chk${i}`}
                                                checked={
                                                  list.value === "Y" ||
                                                  list.value === "1"
                                                    ? true
                                                    : false
                                                }
                                                onChange={(e) =>
                                                  this._handleValueOptions(
                                                    "checkbox",
                                                    e,
                                                    list,
                                                    i
                                                  )
                                                }
                                              />
                                              <label
                                                htmlFor={`chk${i}`}
                                              ></label>
                                            </div>
                                          </div>
                                        </td>
                                      ) : list.valueType === "Numeric" ||
                                        list.valueType === "Number" ? (
                                        <td>
                                          <div className="table_input_field">
                                            <input
                                              type="number"
                                              value={list.value}
                                              onChange={(e) =>
                                                this._handleValueOptions(
                                                  "number",
                                                  e,
                                                  list,
                                                  i
                                                )
                                              }
                                            />
                                          </div>
                                        </td>
                                      ) : list.valueType === "Range" ||
                                        list.valueType === "Text" ? (
                                        <td>
                                          <div className="table_input_field">
                                            <input
                                              type="text"
                                              value={list.value}
                                              onChange={(e) =>
                                                this._handleValueOptions(
                                                  "text",
                                                  e,
                                                  list,
                                                  i
                                                )
                                              }
                                            />
                                          </div>
                                        </td>
                                      ) : (
                                        <td>{list.value}</td>
                                      )}

                                      <td>
                                        <div className="custom-radio">
                                          <label
                                            className="check_main remember_check"
                                            htmlFor={`hideUnhideRows${i}`}
                                          >
                                            <input
                                              type="checkbox"
                                              className="custom-control-input"
                                              name={"hideUnhideRows"}
                                              id={`hideUnhideRows${i}`}
                                              checked={false}
                                              onChange={(e) =>
                                                this._handleHideUnhideRows(list)
                                              }
                                            />

                                            {/* <span className='click_checkmark'></span> */}
                                            <span
                                              className={
                                                list.hide
                                                  ? "dash_checkmark bg_clr"
                                                  : "dash_checkmark"
                                              }
                                            ></span>
                                          </label>
                                        </div>
                                      </td>
                                      <td></td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  account: state.account,
});
export default connect(mapStateToProps, {
  createJournal: AccountActions.createJournal,
})(CreateJournal);
