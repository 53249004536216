import { useState } from "react"
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { logoutAndDisconnectWS, request2FANonCognito, verifyPinCodeWithoutDispatch } from "../../../Actions/UserActions/UserActions";
import { isPinCodeSentToEmail } from "../../Login/Login";
import { getMessageDescription, isSuccessRequest } from "../../../Utils/Helpers";
import { toast } from "react-toastify";

/**
 * Verify 2FA code for legacy users
 * @param {{userLogin: string, companyID: string}} param0
 * @returns {JSX.Element}
 */
const Verify2FACode = ({ userLogin, companyID }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [totp, setTotp] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [maskedEmail, setMaskedEmail] = useState("");
    const [isCodeSent, setIsCodeSent] = useState(true);
    const [errors, setErrors] = useState({
        totp: "",
    });

    async function submitTOTP() {
        setIsLoading(true);
        if (!totp || !userLogin || !companyID) {
            setErrors({
                totp: "Please enter OTP/2FA code",
            });
            setIsLoading(false);
            return;
        }
        const data = {
            actionType: "VerifyPinCode",
            userLogin: userLogin,
            companyID: companyID,
            pinCode: totp,
        };
        const response = await verifyPinCodeWithoutDispatch(data);
        const isSuccessful = isSuccessRequest(response.UserResponse);
        if (isSuccessful) {
            toast.success("2FA verified successfully, logging in...");
            setIsLoading(false);
            history.push("/login-table", {
                isCognitoFlow: false,
                companyID,
            });
        } else {
            const errorToast = getMessageDescription(response.UserResponse, 'failed');
            const warningToast = getMessageDescription(response.UserResponse, 'warning');
            const toastMessage = errorToast || warningToast || "unable to verify 2FA";
            toast.error(toastMessage);
            setIsLoading(false);
        }
    }

    async function logout() {
        setIsLoading(true);
        await logoutAndDisconnectWS(dispatch);
        setIsLoading(false);
        history.push("/login");
    }

    async function resendOTP() {
        setIsLoading(true);
        setIsCodeSent(false);
        const data = {
            actionType: "RequestPinCode",
            userLogin: userLogin,
            companyID: companyID,
        };
        const response = await request2FANonCognito(data);
        const isOTPSent = isPinCodeSentToEmail(response.UserResponse.results);
        if (isOTPSent) {
            setIsCodeSent(true);
            setMaskedEmail(response.UserResponse.userContact);
        } else {
            const failedToastMessage = getMessageDescription(response.UserResponse, 'failed');
            const warningToastMessage = getMessageDescription(response.UserResponse, 'warning');
            const toastMesage = failedToastMessage || warningToastMessage || 'Unable to send OTP';
            toast.error(toastMesage);
        }
        setTotp("");
        setIsLoading(false);
    }

    return isLoading ? (
        <div className="se-pre-con"></div>
    ) : (
        <Modal
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={true}
            className="forgot_email_modal modal_704 mx-auto"
            backdrop="static"
        >
            <Modal.Body>
                <div className="container-fluid ">
                    <div className="main_wrapper p-10">
                        <div className="row d-flex h-100">
                            <div className="col-12 justify-content-center align-self-center form_mx_width">
                                <div className="forgot_header">
                                    <div className="modal-top-header">
                                        <div className="row bord-btm">
                                            <div className="col-auto pl-0">
                                                <h6 className="text-left def-blue">
                                                    Verification code
                                                </h6>
                                            </div>
                                            <div className="col d-flex justify-content-end s-c-main">
                                                <button
                                                    onClick={logout}
                                                    type="button"
                                                    className="btn-save"
                                                >
                                                    <span className="fa fa-ban"></span>
                                                    Cancel
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="forgot_body">
                                    <div className="mt-3 d-flex flex-column">
                                        {
                                            isCodeSent ? (
                                                <p>
                                                    Enter the verification code sent to your email{" "}
                                                    {maskedEmail} and the new password below
                                                </p>
                                            ) : (
                                                <></>
                                            )
                                        }
                                        <div className="form-group mt-1">
                                            <input
                                                className="form-control"
                                                name="reset_verification_code"
                                                placeholder="Verification code"
                                                value={totp}
                                                onChange={(e) =>
                                                    setTotp(e.target.value)
                                                }
                                            />
                                            {errors.totp ? (
                                                <div className="text-danger text-md">
                                                    {errors.totp}
                                                </div>
                                            ) : (<></>)}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6">
                                            <div className="login_btn d-flex no-wrap">
                                                <button
                                                    disabled={isLoading}
                                                    onClick={submitTOTP}
                                                    className="btn btn-primary login_blue"
                                                >
                                                    Submit
                                                </button>
                                                <button
                                                    onClick={logout}
                                                    type="submit"
                                                    className="btn btn-primary login_white"
                                                >
                                                    Cancel
                                                </button>
                                            </div>
                                        </div>
                                        <div className="col-6 d-flex justify-content-end align-self-center align-items-center text-center mt-50">
                                            <button
                                                onClick={resendOTP}
                                                className="btn resend_code"
                                            >
                                                Resend Code
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>

    );
}

export default Verify2FACode;