import React, { Component, PureComponent } from "react";
import Modal from "react-bootstrap/Modal";
import Select from "react-select";
import { options, _customStyles } from "../../../../../Constants/Constants";
import DatePicker from "react-datepicker";
import { connect } from "react-redux";
import * as AccountActions from "../../../../../Actions/AccountActions/AccountActions";
import * as Validation from "../../../../../Utils/Validation";
import $, { data } from "jquery";
import {
  downloadAttachments,
  handleAPIErr,
  handleHideUnhideRows,
  handleValueOptions,
} from "../../../../../Utils/Helpers";
import { toast } from "react-toastify";
import ColumnsPopupForm from "../ColumnsPopupForm/ColumnsPopupForm";
import CreateFinancialLayout from "../CreateFinancialLayoutModal/CreateFinancialLayout";
import AddColumnsPopupForm from "../AddColumnsPopupForm/AddColumnsPopupForm";
import CategoryPopupForm from "../CategoryPopupForm/CategoryPopupForm";
import { List } from "antd";
const uuidv1 = require("uuid/v1");
// import $ from "jquery";

class FinancialLayoutModal extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      addNewReportToggle: false,
      layout: [],
      layouts: "",
      reportId: "",
      reportTypesVal: { label: "", value: "" },
      reportTypes: [],
      reportOptionVal: { label: "", value: "" },
      reportOptionVals: [{ label: "Select a Report Option", value: "" }],
      reportOptions: [],
      inheritOptions: [],
      reportTypeValue: [],
      columnDetails: [],
      columnDetailsEdit: [],
      menuIsOpen: false,
      reportFile: "",
      reportName: "",
      privateCheck: false,
      openColumnsPopupFormModal: false,
      openAddColumnsPopupFormModal: false,
      openAccountReportModal: false,
      openCategoryPopupFormModal: false,
      showHiddenRows: false,
      reportSettings: [
        {
          category: "Bank",
          description: "Account",
          valueOptions: [{ label: "Select a value", value: "value" }],
          valueType: "List",
          hide: false,
        },
        {
          category: "Bank",
          description: "Account",
          valueOptions: [{ label: "Select a value", value: "value" }],
          valueType: "List",
          hide: false,
        },
        {
          category: "Bank",
          description: "Account",
          valueOptions: [{ label: "Select a value", value: "value" }],
          valueType: "List",
          hide: false,
        },
        {
          category: "Bank",
          description: "Account",
          valueOptions: [{ label: "Select a value", value: "value" }],
          valueType: "List",
          hide: false,
        },
      ],
      clonedReportSettings: [],
      reportVColumns: [
        {
          col: 1,
          description: "Chart Code",
          chartCodeFrom: "23",
          chartCodeTo: "50",
        },
        {
          col: 2,
          description: "Description",
          chartCodeFrom: "23",
          chartCodeTo: "50",
        },
        {
          col: 3,
          description: " Amount this period",
          chartCodeFrom: "23",
          chartCodeTo: "50",
        },
        {
          col: 4,
          description: "Orders",
          chartCodeFrom: "23",
          chartCodeTo: "50",
        },
        {
          col: 5,
          description: "Amt into Orders",
          chartCodeFrom: "23",
          chartCodeTo: "50",
        },
        {
          col: 6,
          description: "Est to Complete",
          chartCodeFrom: "23",
          chartCodeTo: "50",
        },
        {
          col: 7,
          description: "Est Final Cost",
          chartCodeFrom: "23",
          chartCodeTo: "50",
        },
        {
          col: 8,
          description: "Budget",
          chartCodeFrom: "23",
          chartCodeTo: "50",
        },
        {
          col: 9,
          description: "Variance",
          chartCodeFrom: "23",
          chartCodeTo: "50",
        },
        {
          col: 10,
          description: "Period Variance",
          chartCodeFrom: "23",
          chartCodeTo: "50",
        },
      ],
      reportVCategories: [
        {
          col: 1,
          description: "Chart Code",
          chartCodeFrom: "23",
          chartCodeTo: "50",
        },
        {
          col: 2,
          description: "Description",
          chartCodeFrom: "23",
          chartCodeTo: "50",
        },
        {
          col: 3,
          description: " Amount this period",
          chartCodeFrom: "23",
          chartCodeTo: "50",
        },
        {
          col: 4,
          description: "Orders",
          chartCodeFrom: "23",
          chartCodeTo: "50",
        },
        {
          col: 5,
          description: "Amt into Orders",
          chartCodeFrom: "23",
          chartCodeTo: "50",
        },
        {
          col: 6,
          description: "Est to Complete",
          chartCodeFrom: "23",
          chartCodeTo: "50",
        },
        {
          col: 7,
          description: "Est Final Cost",
          chartCodeFrom: "23",
          chartCodeTo: "50",
        },
        {
          col: 8,
          description: "Budget",
          chartCodeFrom: "23",
          chartCodeTo: "50",
        },
        {
          col: 9,
          description: "Variance",
          chartCodeFrom: "23",
          chartCodeTo: "50",
        },
        {
          col: 10,
          description: "Period Variance",
          chartCodeFrom: "23",
          chartCodeTo: "50",
        },
      ],
      reDrawDT: false, //re draw datatable
      formErrors: {
        reportTypesVal: "",
        reportOptionVal: "",
        reportFile: "",
        reportName: "",
      },
    };
  }

  componentWillReceiveProps() {
    let reportTypes = this?.props?.reportTypes || [];
    let reportOptions = this?.props?.reportOptions || [];
    let reportSettings = this?.props?.options || [];
    let clonedReportSettings = this?.props?.clonedOptions || [];
    let layout = this?.props?.layout || [];
    let reportVColumns = this.props?.columns || [];
    let reportVCategories = this.props?.categories || [];
    let reportTypeValue = this.props?.reportTypeValue || [];
    let reportTypesVal = this.props?.reportTypesVal || "";
    let newRefId = this?.props?.newRefId || "";

    this.setState({
      reportTypes,
      reportOptions,
      reportSettings,
      reportVColumns,
      reportVCategories,
      clonedReportSettings,
      layout,
      reportTypeValue,
      reportTypesVal,
    });
  }
  getFinancialLayout = async (data1) => {
    this.setState({ isLoading: true });
    let data = data1;

    if (data.layout != "") {
      await this.props.getFinancialLayout(data);
      if (this.props.account.getFinancialLayoutSuccess) {
        let layout = this.props?.account?.getFinancialLayout || [];
        let reportTypesModal = layout[0].availableLayouts || [];
        let reportOptionsModal = layout[0].availableReport || [];
        let options = layout[0].options || [];
        let columns = layout[0].columns || [];
        let categories = layout[0].categories || [];
        let reportType = [];
        let reportOptions = [];
        reportTypesModal.map((opt) => {
          if (opt.reportRefId.length > 0) {
            reportType.push({ label: opt.reportType, value: opt.reportRefId });
          }
        });
        reportOptionsModal.map((opt) => {
          // if (opt.id.length > 0) {
          reportOptions.push({ label: opt.layout, value: opt.id });
          // }
        });
        options.map((lst, i) => {
          if (
            (lst.valueType && lst.valueType.toLowerCase() === "list") ||
            "droplist"
          ) {
            let valOptns = [];
            if (lst.options && lst.options.length > 0) {
              lst.options.map((o, i) => {
                valOptns.push({ label: o.value, value: o.value });
              });
            }
            lst.options = valOptns;
          }
          lst.id = uuidv1();
          lst.hide = false;
          return lst;
        });
        this.setState({
          reportTypes: reportType,
          reportOptions: reportOptions,
          reportSettings: options,
          reportVColumns: columns,
          reportVCategories: categories,
          clonedReportSettings: options,
          layout,
        });
      }
      if (this.props.account.getFinancialLayoutError) {
        handleAPIErr(this.props.account.getFinancialLayoutError, this.props);
      }
    } else {
      toast.error("Select a Report Type First");
    }

    this.setState({ isLoading: false });
  };
  createLayout = async (name) => {
    this.setState({ isLoading: true });
    let data = { layout: this.state.reportOptionVal.value };
    if (data.layout != "") {
      await this.props.primeFinancailLayout(data);
      if (this.props.account.primeFinancialLayoutSuccess) {
        let inheritOptions =
          this.props.account?.primeFinancialLayout.inheritOptions || [];

        let options = [];
        let reportOptionVals = [];
        inheritOptions.map((opt) => {
          if (opt.option && opt.option.length > 0) {
            options.push({ label: opt.option, value: opt.option });
          }
          if (opt.selected && opt.selected === 1) {
            reportOptionVals = [{ label: opt.option, value: opt.option }];
          }
        });

        this.setState({ inheritOptions: options, reportOptionVals });
      }
      if (this.props.account.primeFinancialLayoutError) {
        handleAPIErr(this.props.account.primeFinancialLayoutError, this.props);
      }
      this.openModal(name);
    } else {
      toast.error("Select a Report Option First");
    }
    this.setState({ isLoading: false });
  };
  deleteFinancialLayout = async () => {
    this.setState({ isLoaing: true });
    let data = { layout: this.state.reportTypesVal.label };
    if (data.layout != "") {
      await this.props.deleteFinancialLayout(data);
      if (this.props.account.deleteFinancialLayoutSuccess) {
        toast.success(this.props.account.deleteFinancialLayoutSuccess);
        // this.props.closeModal("financReportModal");
      }
      if (this.props.account.deleteFinancialLayoutError) {
        handleAPIErr(this.props.account.deleteFinancialLayoutError, this.props);
      }
    } else {
      toast.error("Select a Report Option First");
    }

    this.setState({ isLoading: false });
  };
  saveAndGenerateReport = async () => {
    this.setState({ isLoading: true });

    let data = {
      layout: this.state.reportTypesVal.label,
      reportId: this.state.reportTypesVal.value,
      options: this.state.reportSettings,
      columns: this.state.reportVColumns,
      categories: this.state.reportVCategories,
    };

    // if(data){
    await this.props.saveAndGenerateReport(data);
    if (this.props.account.saveAndGenerateReportSuccess) {
      let attachment =
        this?.props?.account?.saveAndGenerateReport?.attachment || [];
      let fileName =
        this?.props?.account?.saveAndGenerateReport?.fileName || [];
      let resp = {
        attachment: attachment,
        contentType: "application/pdf",
        fileName: fileName,
      };
      downloadAttachments(resp, fileName);
      // this.props.closeModal("financReportModal");
      toast.success(this.props.account.saveAndGenerateReportSuccess);
    }
    if (this.props.account.saveAndGenerateReportError) {
      handleAPIErr(this.props.account.saveAndGenerateReportError, this.props);
    }
    // }
    // else{
    //   toast.error("Can't Save and Generate Report.")
    // }
    this.setState({ isLoading: false });
  };
  saveFinancialLayout = async () => {
    this.setState({ isLoading: true });

    
    if (this.state.reportTypesVal.label !== "") {
      let data = {
        reportId: this.state.reportTypesVal.value,
        layout: this.state.reportTypesVal.label,
        options: this.state.reportSettings,
        columns: this.state.reportVColumns,
        categories: this.state.categories,
      };
      await this.props.saveFinancialLayout(data);
      if (this.props.account.saveFinancialLayoutSuccess) {
        toast.success(this.props.account.saveFinancialLayoutSuccess);
      }
      if (this.props.account.saveFinancialLayoutError) {
        handleAPIErr(this.props.account.saveFinancialLayoutError);
      }
    } else {
      toast.error(
        "Please Select a Report Type First for Saving Financial Layout"
      );
    }

    this.setState({ isLoading: false });
  };

  primeColumnOptionDetails = async (name) => {
    this.setState({ isLoading: true });
    await this.props.primeColumnOptionDetails();
    if (this.props.account.primeColumnOptionDetailsSuccess) {
      toast.success(this.props.account.primeColumnOptionDetailsSuccess);
      let columnDetails = this.props.account.primeColumnOptionDetails || [];
      columnDetails.map((lst, i) => {
        if ((lst.type && lst.type.toLowerCase() === "list") || "droplist") {
          let valOptns = [];
          if (lst.options && lst.options.length > 0) {
            lst.options.map((o, i) => {
              valOptns.push({ label: o.option, value: o.option });
            });
          }
          lst.options = valOptns;
        }
        return lst;
      });
      this.setState({ columnDetails });
     
      if (columnDetails.length > 0) {
        this.openModal(name);
      }
    }
    if (this.props.account.primeColumnOptionDetailsError) {
      handleAPIErr(
        this.props.account.primeColumnOptionDetailsError,
        this.props
      );
    }
    this.setState({ isLoading: false });
  };
  getColumnOptionDetails = async (name, list) => {
    this.setState({ isLoading: true });
    let data = {
      layout: this.state.reportTypeValue[0].label,
      number: list.number,
    };
    await this.props.getColumnOptionDetails(data);
    if (this.props.account.getColumnOptionDetailsSuccess) {
      toast.success(this.props.account.getColumnOptionDetailsSuccess);
      let columnDetailsEdit = this.props.account.getColumnOptionDetails || [];
      columnDetailsEdit.map((lst, i) => {
        if ((lst.type && lst.type.toLowerCase() === "list") || "droplist") {
          let valOptns = [];
          if (lst.options && lst.options.length > 0) {
            lst.options.map((o, i) => {
              valOptns.push({ label: o.option, value: o.option });
            });
          }
          lst.options = valOptns;
        }
        return lst;
      });
      this.setState({ columnDetailsEdit });
      if (this.state.columnDetailsEdit) {
        this.openModal(name);
      }
    }
    if (this.props.account.getColumnOptionDetailsError) {
      handleAPIErr(this.props.account.getColumnOptionDetailsError, this.props);
    }
    this.setState({ isLoading: false });
  };

  addNewReport = () => {
    // this.setState((prevState) => ({
    //   ...prevState,
    //   addNewReportToggle: !prevState.addNewReportToggle,
    //   reportOptionVal: { label: "", value: "" },
    //   formErrors: {
    //     ...formErrors,
    //     reportOptionVal: "",
    //   },
    // }));
  };

  //Hide/Unhide Rows
  _handleHideUnhideRows = (item) => {
    let { reportSettings, clonedReportSettings, showHiddenRows } = this.state;

    let result = handleHideUnhideRows(
      item,
      "#reportv4-table",
      "reports__settings",
      reportSettings,
      clonedReportSettings,
      showHiddenRows
    );

    this.setState((prev) => ({
      ...prev,
      reportSettings: result.advancedList,
      clonedReportSettings: result.clonedAdvancedList,
      showHiddenRows: result.showHiddenRows,
      reDrawDT: true,
    }));
  };
  handleReportTypes = (type) => {
    let { formErrors } = this.state;

    formErrors = Validation.handleValidation(
      "reportTypesVal",
      type.value,
      formErrors
    );

    let value = type.value || "";

    let getReportOptions = this.props.reportTypes || [];
    let reportTypes = [];
    let reportId = "";
    let found = getReportOptions.find((rop) => rop.value === value);
    if (found) {
      let reportLayouts = found.reportLayouts || [];
      reportLayouts.map((l, i) => {
        reportTypes.push({
          label: l.label,
          value: l.value,
          locked: l.locked,
        });
        reportId = l.value;
      });
    }

    this.setState(
      (prev) => ({
        ...prev,
        reportTypesVal: type,
        reportId,
        reportTypes: getReportOptions,
        formErrors,
      }),
      () => {
        let data = {
          layout: this.state.reportTypesVal.label,
        };
        this.getFinancialLayout(data);
      }
    );
  };
  handleReportOptions = (layout) => {
    let { formErrors } = this.state;
    let getReportOptions = this.state.reportOptions || [];
    let value = layout.value;
    let found = getReportOptions.find((rop) => rop.value === value);
    formErrors = Validation.handleValidation(
      "reportOptionVal",
      layout.value,
      formErrors
    );
    

    this.setState((prev) => ({
      ...prev,
      reportOptionVal: layout,
      reportOptions: getReportOptions,
      addNewReportToggle: false,
      layouts: layout.label,
      formErrors,
    }));
  };
  //Advanced List Setting
  _handleValueOptions = async (type, val, item, index) => {
    let { reportSettings, clonedReportSettings } = this.state;
    let result = handleValueOptions(
      type,
      val,
      item,
      index,
      reportSettings,
      clonedReportSettings
    );
    this.setState({prev:{
      ...this.state.prev,
      reportSettings: result.advancedList,
      clonedReportSettings: result.clonedAdvancedList,
    }});
 
  };
  handleShowHiddenRows = async () => {
    let table = window.$("#reportv4-table").DataTable();
    table.destroy();

    let { reportSettings, clonedReportSettings, showHiddenRows } = this.state;

    showHiddenRows = !showHiddenRows;

    if (showHiddenRows) {
      //show hidden rows
      reportSettings = clonedReportSettings;
    } else {
      //hide again hidden rows
      let list = reportSettings.filter((l) => !l.hide);
      reportSettings = list;
    }

    this.setState((prev) => ({
      ...prev,
      reportSettings,
      showHiddenRows,
      reDrawDT: true, //to re daraw the datatable
    }));
  };
  menuOpened = async () => {
    this.setState((prev) => ({ ...prev, menuIsOpen: true }));
    $(document).ready(async function () {
      $('<i class="fa fa-trash report--del"></i>').appendTo(
        ".report_menu.custon_select-selector-inner__option"
      );
      $(".report_menu.custon_select-selector-inner__option i").on(
        "click",
        async function (e) {
          e.preventDefault();
          var p_id = $(this).parent().attr("id");
          let id_split = p_id && p_id.split("-"); //["react", "select", "3", "option", "1"]
          let id = id_split[id_split.length - 1];

          let { reportOptions } = this.state;
          let rep_opt = reportOptions.length > 0 && reportOptions[id];
          //call delete report options API
        }
      );
    });
  };
  menuClosed = () => {
    this.setState((prev) => ({ ...prev, menuIsOpen: false }));
  };
  clearStates = () => {
    this.setState({
      addNewReportToggle: false,
      reportTypesVal: [{ label: "", value: "" }],
      reportTypes: [],
      reportOptionVal: { label: "", value: "" },
      reportOptions: [],
      menuIsOpen: false,
      reportFile: "",
      reportName: "",
      privateCheck: false,
      reportSettings: [],
      showHiddenRows: false,
      formErrors: {
        reportTypesVal: "",
        reportOptionVal: "",
        reportFile: "",
        reportName: "",
      },
    });
    // props.getLayoutOptions();
    this.props.closeModal("financReportModal");
  };
  onSaveReport = async () => {
    let {
      reportName,
      reportTypesVal,
      reportOptionVal,
      reportFile,
      addNewReportToggle,
      privateCheck,
      formErrors,
      clonedReportSettings,
    } = this.state;

    if (addNewReportToggle) {
      //add new report case
      formErrors = Validation.handleWholeValidation(
        { reportName, reportFile },
        formErrors
      );
    } else {
      //use reports from the drop down list
      formErrors = Validation.handleWholeValidation(
        {
          reportTypesVal: reportTypesVal.value,
          reportOptionVal: reportOptionVal.value,
        },
        formErrors
      );
    }

    let check = false;
    if (addNewReportToggle) {
      if (!formErrors.reportFile && !formErrors.reportName) {
        check = true;
      }
    } else {
      if (!formErrors.reportOptionVal) {
        check = true;
      }
    }
    if (!formErrors.reportTypesVal && check) {
      let data = "";
      if (reportOptionVal.value) {
        //get report data according to available report options
        data = {
          reportType: this.props.reportType,
          reportID: reportOptionVal.value,
          reportSettings: clonedReportSettings,
        };
      } else {
        //create new reports and get data according to that report
        data = {
          reportType: this.props.reportType,
          reportFile,
          reportName,
          private: privateCheck,
          reportSettings: clonedReportSettings,
        };
      }
      if (data) {
        this.setState((prev) => ({ ...prev, isLoading: true }));
      }
    }
    this.setState((prev) => ({ ...prev, formErrors, isLoading: false }));
  };

  openModal = async (name) => {
    this.setState({ [name]: true });
  };
  closeModal = async (name, columnDetails) => {
 
    if (name === "openAddColumnsPopupFormModal" && columnDetails) {
      this.setState({ reportVColumns: columnDetails, [name]: false });
    } else if (name === "openColumnsPopupFormModal" && columnDetails) {
      this.setState({ reportVColumns: columnDetails, [name]: false });
    } else {
      this.setState({ [name]: false });
    }

  };

  rotateClass = () => {
    $(".sideBarAccord1").toggleClass("rorate_0");
  };
  rotateClassTwo = () => {
    $(".sideBarAccord2").toggleClass("rorate_0");
  };
  rotateClassThree = () => {
    $(".sideBarAccord").toggleClass("rorate_0");
  };

  render() {
    return (
      <>
        {this.state.isLoading ? <div className="se-pre-con"></div> : ""}
        <Modal
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={this.props.financReportModal}
          onHide={this.clearStates}
          className="mm_report_new_modal mx-auto reports-v4-modal"
        >
          <Modal.Body>
            <div className="container-fluid p-0">
              <div className="main_wrapper">
                <div className="row d-flex h-100 p-0">
                  <div className="col-12 justify-content-center align-self-center">
                    <div className="setting_form_main p-0">
                      <div className="setting_header thead_bg">
                        <h3 className="Indirecttaxcode-poup_heading">
                          Reports
                        </h3>
                        <div className="Indirecttaxcode-poup_can-sav-btn">
                          <button
                            onClick={this.deleteFinancialLayout}
                            className="btn can-btn1 pr-2 pb-1"
                          >
                            <img src="images/delete-white.png" alt="cancel" />
                          </button>
                          <button
                            onClick={() => this.saveAndGenerateReport()}
                            className="btn can-btn1 pr-3"
                          >
                            Run
                          </button>
                          <button
                            className="btn can-btn1"
                            onClick={this.saveFinancialLayout}
                          >
                            <img
                              src="images/user-setup/check-white.png"
                              alt="check"
                            />
                            Save
                          </button>
                          <button
                            onClick={this.clearStates}
                            className="btn can-btn1 pl-3"
                          >
                            <img
                              src="images/user-setup/cancel-white.png"
                              alt="cancel"
                            />
                            Cancel
                          </button>
                          <button className="btn can-btn1 pl-2">
                            <img
                              src="images/user-setup/dots-h.png"
                              alt="dots"
                            />
                          </button>
                        </div>
                      </div>
                      <div className="reportv4-modal-inner">
                        <div className="row mt-4">
                          <div className="col-xl-3 col-md-3 col-sm-12 ">
                            <div className="form-group custon_select">
                              <label>Select Report Type</label>

                              <Select
                                className="width-selector"
                                value={this.state.reportTypesVal}
                                classNamePrefix="custon_select-selector-inner"
                                options={this.state.reportTypes}
                                onChange={this.handleReportTypes}
                                theme={(theme) => ({
                                  ...theme,
                                  border: 0,
                                  borderRadius: 0,
                                  colors: {
                                    ...theme.colors,
                                    primary25: "#f2f2f2",
                                    primary: "#f2f2f2",
                                  },
                                })}
                              />
                              <div className="text-danger error-12">
                                {/* {formErrors.reportTypesVal !== ""
                                ? formErrors.reportTypesVal
                                : ""} */}
                              </div>
                              <span
                                className="input_field_icons rit-icon-input"
                                onClick={() =>
                                  this.createLayout("openAccountReportModal")
                                }
                              >
                                <i className="fa fa-plus"></i>
                              </span>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="form-group col-xl-3 col-md-3 col-sm-12">
                            <div className="custon_select">
                              <label>Select Report Option</label>

                              <Select
                                className="width-selector"
                                closeMenuOnSelect={true}
                                value={this.state.reportOptionVal}
                                classNamePrefix="report_menu custon_select-selector-inner"
                                onMenuOpen={this.menuOpened}
                                onMenuClose={this.menuClosed}
                                onChange={this.handleReportOptions}
                                options={this.state.reportOptions}
                                theme={(theme) => ({
                                  ...theme,
                                  border: 0,
                                  borderRadius: 0,
                                  colors: {
                                    ...theme.colors,
                                    primary25: "#f2f2f2",
                                    primary: "#f2f2f2",
                                  },
                                })}
                              />
                              <div className="text-danger error-12">
                                {/* {formErrors.reportOptionVal !== ""
                                ? formErrors.reportOptionVal
                                : ""} */}
                              </div>
                              <span
                                className="input_field_icons rit-icon-input"
                                onClick={this.addNewReport}
                              >
                                <i className="fa fa-plus"></i>
                              </span>
                            </div>
                          </div>
                          {this.state.addNewReportToggle && (
                            <div className=" pl-3 pr-3 w-100">
                              <div className="row">
                                <div className="col-12">
                                  <div className="form-group custon_select mt-3">
                                    <div className="modal_input">
                                      <label>Report Name</label>
                                      <input
                                        type="text"
                                        className="form-control pl-0"
                                        id="usr"
                                        name="reportName"
                                        value={this.state.reportName}
                                        // onChange={handleFieldChange}
                                      />
                                      <div className="text-danger error-12">
                                        {this.state.formErrors.reportName !== ""
                                          ? this.state.formErrors.formErrors
                                              .reportName
                                          : ""}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-12 align-self-center mb-2 supp2-roprt-check">
                                  <div className="form-group remember_check">
                                    <input
                                      type="checkbox"
                                      id="remember"
                                      checked={this.state.privateCheck}
                                      //   onChange={(e) => handleReportCheckbox(e)}
                                    />
                                    <label htmlFor="remember"></label>
                                    <p className="reports-excel rc-lineheight">
                                      Private:
                                    </p>
                                  </div>
                                </div>
                                <div className="col-12 mt-2 mb-2">
                                  <div className="form-group custon_select  text-center mb-0 border-rad-5">
                                    <div id="drop-area-report">
                                      <input
                                        type="file"
                                        id="fileElem-rep"
                                        className="form-control d-none"
                                        accept="application/pdf"
                                        onChange={(e) => {
                                          //   uploadReportFile(e.target.files);
                                        }}
                                        onClick={(event) => {
                                          event.currentTarget.value = null;
                                        }} //to upload the same file again
                                      />

                                      <label
                                        className="upload-label"
                                        htmlFor="fileElem-rep"
                                      >
                                        <div className="upload-text">
                                          <img
                                            src="images/drag-file.png"
                                            className="import_icon img-fluid"
                                            alt="upload-report"
                                          />
                                        </div>
                                      </label>
                                    </div>
                                  </div>
                                  <div className="text-danger error-12">
                                    {this.state.formErrors.reportFile !== ""
                                      ? this.state.formErrors.reportFile
                                      : ""}
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="reports_popup__table " id="accordion">
                          <h2>
                            <span>
                              {" "}
                              <img
                                onClick={this.rotateClass}
                                src="images/arrow_up.png"
                                class="import_icon img-fluid pr-3 ml-3 sideBarAccord1"
                                alt="arrow_up"
                                data-toggle="collapse"
                                data-target="#finace1"
                                aria-expanded="false"
                              />
                            </span>
                            Advanced
                          </h2>
                          <div
                            className="reconciliation-table1 table_white_box show collapse"
                            id="finace1"
                            data-parent="#accordion"
                          >
                            <table
                              className="table"
                              id="reportv4-table"
                              width="100%"
                            >
                              <thead className="thead_bg hover-border">
                                <tr>
                                  <th scope="col"></th>
                                  <th scope="col">
                                    <span className="user_setup_hed">
                                      Category
                                    </span>
                                  </th>
                                  <th scope="col">
                                    <span className="user_setup_hed">
                                      Description
                                    </span>
                                  </th>
                                  <th scope="col">
                                    <span className="user_setup_hed">
                                      Value
                                    </span>
                                  </th>
                                  <th scope="col">
                                    <span className="user_setup_hed">From</span>
                                  </th>
                                  <th scope="col">
                                    <span className="user_setup_hed">To</span>
                                  </th>
                                  <th scope="col">
                                    <span className="user_setup_hed">Hide</span>
                                  </th>
                                  <th className="table__inner--th">
                                    <div className="dropdown">
                                      <button
                                        aria-haspopup="true"
                                        aria-expanded="true"
                                        id=""
                                        type="button"
                                        className="dropdown-toggle btn dept-tbl-menu "
                                        data-toggle="dropdown"
                                      >
                                        <span className="fa fa-bars "></span>
                                      </button>
                                      <div className="dropdown-menu dept-menu-list dropdown-menu-right">
                                        <div className="pr-0 dropdown-item">
                                          <div className="form-group remember_check mm_check4">
                                            <input
                                              type="checkbox"
                                              id="showHiddenRows"
                                              name="showHiddenRows"
                                              checked={this.showHiddenRows}
                                              onClick={
                                                this.handleShowHiddenRows
                                              }
                                            />
                                            <label
                                              htmlFor="showHiddenRows"
                                              className="mr-0"
                                            >
                                              Show Hidden Rows
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.reportSettings?.map((list, i) => {
                                  return (
                                    <tr key={i}>
                                      <td></td>
                                      <td>{list.category}</td>
                                      <td>{list.description}</td>
                                      {list.valueType === "List" ? (
                                        <td className="pt-0 pb-0 text-left  value__field--wrapperdept ">
                                          <Select
                                            classNamePrefix="custon_select-selector-inner main__dropdown--wrappertwo select__font-wrapper                                        "
                                            styles={_customStyles}
                                            value={{
                                              label: list.value,
                                              valu: list.value,
                                            }}
                                            options={list.options}
                                            onChange={(obj) =>
                                              this._handleValueOptions(
                                                "list",
                                                obj,
                                                list,
                                                i
                                              )
                                            }
                                            theme={(theme) => ({
                                              ...theme,
                                              border: 0,
                                              borderRadius: 0,
                                              colors: {
                                                ...theme.colors,
                                                primary25: "#f2f2f2",
                                                primary: "#f2f2f2",
                                              },
                                            })}
                                          />
                                        </td>
                                      ) : list.valueType === "Date" ? (
                                        <td>
                                          <div className="table_input_field">
                                            <DatePicker
                                              selected={Number(list.value)}
                                              dateFormat="d MMM yyyy"
                                              autoComplete="off"
                                              onChange={(date) =>
                                                this._handleValueOptions(
                                                  "date",
                                                  date,
                                                  list,
                                                  i
                                                )
                                              }
                                            />
                                          </div>
                                        </td>
                                      ) : list.valueType === "Check" ? (
                                        <td>
                                          <div className="col-auto p-0">
                                            <div className="form-group remember_check text-center pt-0 float-left">
                                              <input
                                                type="checkbox"
                                                id={`chk${i}`}
                                                checked={
                                                  list.value === "Y" ||
                                                  list.value === "1"
                                                    ? true
                                                    : false
                                                }
                                                onChange={(e) =>
                                                  this._handleValueOptions(
                                                    "checkbox",
                                                    e,
                                                    list,
                                                    i
                                                  )
                                                }
                                              />
                                              <label
                                                htmlFor={`chk${i}`}
                                              ></label>
                                            </div>
                                          </div>
                                        </td>
                                      ) : list.valueType === "Number" ? (
                                        <td>
                                          <div className="table_input_field">
                                            <input
                                              type="number"
                                              value={list.value}
                                              onChange={(e) =>
                                                this._handleValueOptions(
                                                  "number",
                                                  e,
                                                  list,
                                                  i
                                                )
                                              }
                                            />
                                          </div>
                                        </td>
                                      ) : list.valueType === "Range" ||
                                        list.valueType === "Text" ? (
                                        <td>
                                          <div className="table_input_field">
                                            <input
                                              type="text"
                                              value={list.value}
                                              onChange={(e) =>
                                                this._handleValueOptions(
                                                  "text",
                                                  e,
                                                  list,
                                                  i
                                                )
                                              }
                                            />
                                          </div>
                                        </td>
                                      ) : (
                                        <td>{list.value}</td>
                                      )}
                                      <td>{list.from}</td>
                                      <td>{list.to}</td>
                                      <td>
                                        <div className="custom-radio">
                                          <label
                                            className="check_main remember_check"
                                            htmlFor={`hideUnhideRows${i}`}
                                          >
                                            <input
                                              type="checkbox"
                                              className="custom-control-input"
                                              name={"hideUnhideRows"}
                                              id={`hideUnhideRows${i}`}
                                              checked={false}
                                              onChange={(e) =>
                                                this._handleHideUnhideRows(list)
                                              }
                                            />

                                            {/* <span className='click_checkmark'></span> */}
                                            <span
                                              className={
                                                list.hide
                                                  ? "dash_checkmark bg_clr"
                                                  : "dash_checkmark"
                                              }
                                            ></span>
                                          </label>
                                        </div>
                                      </td>
                                      <td></td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                        </div>

                        <div className="reports_popup__table bord-btm pt-3">
                          <h2 className="d-inline">
                            {" "}
                            <span>
                              <img
                                onClick={this.rotateClassTwo}
                                src="images/arrow_up.png"
                                class="import_icon img-fluid pr-3 ml-3 sideBarAccord2"
                                alt="arrow_up"
                                data-toggle="collapse"
                                data-target="#finace2"
                                aria-expanded="true"
                              />
                            </span>
                            V Columns
                          </h2>
                          <div className="user_setup_plus_Icons pt-0">
                            <ul>
                              <li>
                                <button
                                  className="btn user_setup_rbtns"
                                  type="button"
                                  onClick={() =>
                                    this.primeColumnOptionDetails(
                                      "openAddColumnsPopupFormModal"
                                    )
                                  }
                                >
                                  <span
                                    className="round_plus"
                                    style={{
                                      display: "flex",
                                      width: "22px",
                                      height: "22px",
                                      alignItems: "center",

                                      background: "#2f73ad",
                                      borderRadius: "50%",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <svg
                                      style={{ fill: "white" }}
                                      version="1.1"
                                      id="Capa_1"
                                      xmlns="http://www.w3.org/2000/svg"
                                      x="0px"
                                      y="0px"
                                      width="14px"
                                      height="14px"
                                      viewBox="0 0 349.03 349.031"
                                    >
                                      <g>
                                        <path
                                          d="M349.03,141.226v66.579c0,5.012-4.061,9.079-9.079,9.079H216.884v123.067c0,5.019-4.067,9.079-9.079,9.079h-66.579
		                          c-5.009,0-9.079-4.061-9.079-9.079V216.884H9.079c-5.016,0-9.079-4.067-9.079-9.079v-66.579c0-5.013,4.063-9.079,9.079-9.079
		                          h123.068V9.079c0-5.018,4.069-9.079,9.079-9.079h66.579c5.012,0,9.079,4.061,9.079,9.079v123.068h123.067
		                          C344.97,132.147,349.03,136.213,349.03,141.226z"
                                        />
                                      </g>
                                    </svg>
                                  </span>
                                </button>
                              </li>
                              <li>
                                <button
                                  className="btn user_setup_rbtns"
                                  type="button"
                                >
                                  <span
                                    className="round_file"
                                    style={{
                                      display: "flex",
                                      width: "22px",
                                      height: "22px",

                                      alignItems: "center",

                                      background: "#2f73ad",
                                      borderRadius: "50%",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <svg
                                      style={{ fill: "white" }}
                                      version="1.1"
                                      id="Capa_1"
                                      xmlns="http://www.w3.org/2000/svg"
                                      x="0px"
                                      y="0px"
                                      width="14px"
                                      height="14px"
                                      viewBox="0 0 384 384"
                                      // style="enable-background:new 0 0 384 384;"
                                    >
                                      <g>
                                        <g>
                                          <g>
                                            <path d="M64,341.333C64,364.907,83.093,384,106.667,384h170.667C300.907,384,320,364.907,320,341.333v-256H64V341.333z" />
                                            <polygon points="266.667,21.333 245.333,0 138.667,0 117.333,21.333 42.667,21.333 42.667,64 341.333,64 341.333,21.333 			" />
                                          </g>
                                        </g>
                                      </g>
                                    </svg>
                                  </span>
                                </button>
                              </li>
                            </ul>
                          </div>
                          <div
                            className="reconciliation-table1 table_white_box w_50 collapse"
                            id="finace2"
                            data-parent="#accordion"
                          >
                            <table
                              className="table"
                              id="reportv4-table"
                              width="60%"
                            >
                              <thead className="thead_bg hover-border">
                                <tr>
                                  <th scope="col">
                                    <span className="user_setup_hed">Col#</span>
                                  </th>
                                  <th scope="col">
                                    <span className="user_setup_hed">
                                      Description
                                    </span>
                                  </th>

                                  <th scope="col">
                                    <span className="user_setup_hed"></span>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.reportVColumns?.map((list, i) => {
                                  return (
                                    <tr>
                                      <td>{list.number}</td>
                                      <td>{list.description}</td>
                                      <td
                                        onClick={() =>
                                          this.getColumnOptionDetails(
                                            "openColumnsPopupFormModal",
                                            list
                                          )
                                        }
                                      >
                                        <img
                                          src="images/p2.png"
                                          class="import_icon cursorPointer"
                                          style={{ marginLeft: "70%" }}
                                          alt="pencill"
                                        />
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                        </div>

                        {/* table V Category's */}
                        <div className="reports_popup__table bord-btm pt-3">
                          <h2 className="d-inline">
                            {" "}
                            <span>
                              <img
                                onClick={this.rotateClassThree}
                                src="images/arrow_up.png"
                                class="import_icon img-fluid pr-3 ml-3 sideBarAccord"
                                alt="arrow_up"
                                data-toggle="collapse"
                                data-target="#finace3"
                                aria-expanded="false"
                              />
                            </span>
                            V Category's
                          </h2>
                          <div className="user_setup_plus_Icons pt-0">
                            <ul>
                              <li>
                                <button
                                  className="btn user_setup_rbtns"
                                  type="button"
                                >
                                  <span
                                    className="round_plus"
                                    style={{
                                      display: "flex",
                                      width: "22px",
                                      height: "22px",
                                      alignItems: "center",

                                      background: "#2f73ad",
                                      borderRadius: "50%",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <svg
                                      style={{ fill: "white" }}
                                      version="1.1"
                                      id="Capa_1"
                                      xmlns="http://www.w3.org/2000/svg"
                                      x="0px"
                                      y="0px"
                                      width="14px"
                                      height="14px"
                                      viewBox="0 0 349.03 349.031"
                                    >
                                      <g>
                                        <path
                                          d="M349.03,141.226v66.579c0,5.012-4.061,9.079-9.079,9.079H216.884v123.067c0,5.019-4.067,9.079-9.079,9.079h-66.579
		                          c-5.009,0-9.079-4.061-9.079-9.079V216.884H9.079c-5.016,0-9.079-4.067-9.079-9.079v-66.579c0-5.013,4.063-9.079,9.079-9.079
		                          h123.068V9.079c0-5.018,4.069-9.079,9.079-9.079h66.579c5.012,0,9.079,4.061,9.079,9.079v123.068h123.067
		                          C344.97,132.147,349.03,136.213,349.03,141.226z"
                                        />
                                      </g>
                                    </svg>
                                  </span>
                                </button>
                              </li>
                              <li>
                                <button
                                  className="btn user_setup_rbtns"
                                  type="button"
                                >
                                  <span
                                    className="round_file"
                                    style={{
                                      display: "flex",
                                      width: "22px",
                                      height: "22px",

                                      alignItems: "center",

                                      background: "#2f73ad",
                                      borderRadius: "50%",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <svg
                                      style={{ fill: "white" }}
                                      version="1.1"
                                      id="Capa_1"
                                      xmlns="http://www.w3.org/2000/svg"
                                      x="0px"
                                      y="0px"
                                      width="14px"
                                      height="14px"
                                      viewBox="0 0 384 384"
                                      // style="enable-background:new 0 0 384 384;"
                                    >
                                      <g>
                                        <g>
                                          <g>
                                            <path d="M64,341.333C64,364.907,83.093,384,106.667,384h170.667C300.907,384,320,364.907,320,341.333v-256H64V341.333z" />
                                            <polygon points="266.667,21.333 245.333,0 138.667,0 117.333,21.333 42.667,21.333 42.667,64 341.333,64 341.333,21.333 			" />
                                          </g>
                                        </g>
                                      </g>
                                    </svg>
                                  </span>
                                </button>
                              </li>
                              {/* <li>
                                <a href="#">
                                  <img
                                    src="images/p2.png"
                                    class="import_icon cursorPointer"
                                    alt="pencill"
                                  />
                                </a>
                              </li> */}
                            </ul>
                          </div>
                          <div
                            className="reconciliation-table1 table_white_box collapse"
                            id="finace3"
                            data-parent="#accordion"
                          >
                            <table
                              className="table"
                              id="reportv4-table"
                              width="100%"
                            >
                              <thead className="thead_bg hover-border">
                                <tr>
                                  <th scope="col">
                                    <span className="user_setup_hed">Seq#</span>
                                  </th>
                                  <th scope="col">
                                    <span className="user_setup_hed">
                                      Description
                                    </span>
                                  </th>
                                  <th scope="col">
                                    <span className="user_setup_hed">
                                      Chart Code From
                                    </span>
                                  </th>
                                  <th scope="col">
                                    <span className="user_setup_hed">
                                      Chart Code To
                                    </span>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.reportVCategories?.map(
                                  (list, i) => {
                                    return (
                                      <tr
                                        onClick={() =>
                                          this.openModal(
                                            "openCategoryPopupFormModal"
                                          )
                                        }
                                      >
                                        <td>{list.sequence}</td>
                                        <td>{list.totalDescription}</td>
                                        <td>{list.chartCodeFrom}</td>
                                        <td>{list.chartCodeTo}</td>
                                      </tr>
                                    );
                                  }
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <ColumnsPopupForm
          openColumnsPopupFormModal={this.state.openColumnsPopupFormModal}
          state={this.state}
          closeModal={this.closeModal}
        />
        <AddColumnsPopupForm
          openAddColumnsPopupFormModal={this.state.openAddColumnsPopupFormModal}
          state={this.state}
          closeModal={this.closeModal}
        />
        <CreateFinancialLayout
          openAccountReportModal={this.state.openAccountReportModal}
          layout={this.state.reportOptionVal.value}
          reportOptions={this.state.inheritOptions}
          reportOptionVal={this.state.reportOptionVals}
          layouts={this.state.layouts}
          getLayoutOptions={this.props.getLayoutOptions}
          closeModal={this.closeModal}
        />
        <CategoryPopupForm
          openCategoryPopupFormModal={this.state.openCategoryPopupFormModal}
          closeModal={this.closeModal}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  account: state.account,
});
export default connect(mapStateToProps, {
  primeFinancailLayout: AccountActions.primeFinancialLayout,
  deleteFinancialLayout: AccountActions.deleteFinancialLayout,
  saveAndGenerateReport: AccountActions.saveAndGenerateReport,
  primeColumnOptionDetails: AccountActions.primeColumnOptionDetails,
  getColumnOptionDetails: AccountActions.getColumnOptionDetails,
  saveFinancialLayout: AccountActions.saveFinancialLayout,
  getFinancialLayout: AccountActions.getFinancialLayout,
})(FinancialLayoutModal);

// import React, { Component, PureComponent } from "react";
// import Modal from "react-bootstrap/Modal";
// import Select from "react-select";
// import { options, _customStyles } from "../../../../../Constants/Constants";
// import DatePicker from "react-datepicker";
// import { connect } from "react-redux";
// import * as AccountActions from "../../../../../Actions/AccountActions/AccountActions";
// import * as Validation from "../../../../../Utils/Validation";
// import $, { data } from "jquery";
// import {
//   downloadAttachments,
//   handleAPIErr,
//   handleHideUnhideRows,
//   handleValueOptions,
// } from "../../../../../Utils/Helpers";
// import { toast } from "react-toastify";
// import ColumnsPopupForm from "../ColumnsPopupForm/ColumnsPopupForm";
// import CreateFinancialLayout from "../CreateFinancialLayoutModal/CreateFinancialLayout";
// import AddColumnsPopupForm from "../AddColumnsPopupForm/AddColumnsPopupForm";
// import { List } from "antd";
// const uuidv1 = require("uuid/v1");
// // import $ from "jquery";

// class FinancialLayoutModal extends PureComponent {
//   constructor(props) {
//     super(props);
//     this.state = {
//       addNewReportToggle: false,
//       layout: [],
//       layouts: "",
//       reportId: "",
//       reportTypesVal: { label: "", value: "" },
//       reportTypes: [],
//       reportOptionVal: { label: "", value: "" },
//       reportOptionVals: [{ label: "Select a Report Option", value: "" }],
//       reportOptions: [],
//       inheritOptions: [],
//       reportTypeValue: [],
//       columnDetails: [],
//       columnDetailsEdit: [],
//       menuIsOpen: false,
//       reportFile: "",
//       reportName: "",
//       privateCheck: false,
//       openColumnsPopupFormModal: false,
//       openAddColumnsPopupFormModal: false,
//       openAccountReportModal: false,
//       openCategoryPopupFormModal: false,
//       showHiddenRows: false,
//       reportSettings: [
//         {
//           category: "Bank",
//           description: "Account",
//           valueOptions: [{ label: "Select a value", value: "value" }],
//           valueType: "List",
//           hide: false,
//         },
//         {
//           category: "Bank",
//           description: "Account",
//           valueOptions: [{ label: "Select a value", value: "value" }],
//           valueType: "List",
//           hide: false,
//         },
//         {
//           category: "Bank",
//           description: "Account",
//           valueOptions: [{ label: "Select a value", value: "value" }],
//           valueType: "List",
//           hide: false,
//         },
//         {
//           category: "Bank",
//           description: "Account",
//           valueOptions: [{ label: "Select a value", value: "value" }],
//           valueType: "List",
//           hide: false,
//         },
//       ],
//       clonedReportSettings: [],
//       reportVColumns: [
//         {
//           col: 1,
//           description: "Chart Code",
//           chartCodeFrom: "23",
//           chartCodeTo: "50",
//         },
//         {
//           col: 2,
//           description: "Description",
//           chartCodeFrom: "23",
//           chartCodeTo: "50",
//         },
//         {
//           col: 3,
//           description: " Amount this period",
//           chartCodeFrom: "23",
//           chartCodeTo: "50",
//         },
//         {
//           col: 4,
//           description: "Orders",
//           chartCodeFrom: "23",
//           chartCodeTo: "50",
//         },
//         {
//           col: 5,
//           description: "Amt into Orders",
//           chartCodeFrom: "23",
//           chartCodeTo: "50",
//         },
//         {
//           col: 6,
//           description: "Est to Complete",
//           chartCodeFrom: "23",
//           chartCodeTo: "50",
//         },
//         {
//           col: 7,
//           description: "Est Final Cost",
//           chartCodeFrom: "23",
//           chartCodeTo: "50",
//         },
//         {
//           col: 8,
//           description: "Budget",
//           chartCodeFrom: "23",
//           chartCodeTo: "50",
//         },
//         {
//           col: 9,
//           description: "Variance",
//           chartCodeFrom: "23",
//           chartCodeTo: "50",
//         },
//         {
//           col: 10,
//           description: "Period Variance",
//           chartCodeFrom: "23",
//           chartCodeTo: "50",
//         },
//       ],
//       reportVCategories: [
//         {
//           col: 1,
//           description: "Chart Code",
//           chartCodeFrom: "23",
//           chartCodeTo: "50",
//         },
//         {
//           col: 2,
//           description: "Description",
//           chartCodeFrom: "23",
//           chartCodeTo: "50",
//         },
//         {
//           col: 3,
//           description: " Amount this period",
//           chartCodeFrom: "23",
//           chartCodeTo: "50",
//         },
//         {
//           col: 4,
//           description: "Orders",
//           chartCodeFrom: "23",
//           chartCodeTo: "50",
//         },
//         {
//           col: 5,
//           description: "Amt into Orders",
//           chartCodeFrom: "23",
//           chartCodeTo: "50",
//         },
//         {
//           col: 6,
//           description: "Est to Complete",
//           chartCodeFrom: "23",
//           chartCodeTo: "50",
//         },
//         {
//           col: 7,
//           description: "Est Final Cost",
//           chartCodeFrom: "23",
//           chartCodeTo: "50",
//         },
//         {
//           col: 8,
//           description: "Budget",
//           chartCodeFrom: "23",
//           chartCodeTo: "50",
//         },
//         {
//           col: 9,
//           description: "Variance",
//           chartCodeFrom: "23",
//           chartCodeTo: "50",
//         },
//         {
//           col: 10,
//           description: "Period Variance",
//           chartCodeFrom: "23",
//           chartCodeTo: "50",
//         },
//       ],
//       reDrawDT: false, //re draw datatable
//       formErrors: {
//         reportTypesVal: "",
//         reportOptionVal: "",
//         reportFile: "",
//         reportName: "",
//       },
//     };
//   }

//   componentWillReceiveProps() {
//     let reportTypes = this?.props?.reportTypes || [];
//     let reportOptions = this?.props?.reportOptions || [];
//     let reportSettings = this?.props?.options || [];
//     let clonedReportSettings = this?.props?.clonedOptions || [];
//     let layout = this?.props?.layout || [];
//     let reportVColumns = this.props?.columns || [];
//     let reportVCategories = this.props?.categories || [];
//     let reportTypeValue = this.props?.reportTypeValue || [];
//     let reportTypesVal = this.props?.reportTypesVal || "";
//     let newRefId = this?.props?.newRefId || "";

//     this.setState({
//       reportTypes,
//       reportOptions,
//       reportSettings,
//       reportVColumns,
//       reportVCategories,
//       clonedReportSettings,
//       layout,
//       reportTypeValue,
//       reportTypesVal,
//     });
//   }
//   getFinancialLayout = async (data1) => {
//     this.setState({ isLoading: true });
//     let data = data1;

//     if (data.layout != "") {
//       await this.props.getFinancialLayout(data);
//       if (this.props.account.getFinancialLayoutSuccess) {
//         let layout = this.props?.account?.getFinancialLayout || [];
//         let reportTypesModal = layout[0].availableLayouts || [];
//         let reportOptionsModal = layout[0].availableReport || [];
//         let options = layout[0].options || [];
//         let columns = layout[0].columns || [];
//         let categories = layout[0].categories || [];
//         let reportType = [];
//         let reportOptions = [];
//         reportTypesModal.map((opt) => {
//           if (opt.reportRefId.length > 0) {
//             reportType.push({ label: opt.reportType, value: opt.reportRefId });
//           }
//         });
//         reportOptionsModal.map((opt) => {
//           // if (opt.id.length > 0) {
//           reportOptions.push({ label: opt.layout, value: opt.id });
//           // }
//         });
//         options.map((lst, i) => {
//           if (
//             (lst.valueType && lst.valueType.toLowerCase() === "list") ||
//             "droplist"
//           ) {
//             let valOptns = [];
//             if (lst.options && lst.options.length > 0) {
//               lst.options.map((o, i) => {
//                 valOptns.push({ label: o.value, value: o.value });
//               });
//             }
//             lst.options = valOptns;
//           }
//           lst.id = uuidv1();
//           lst.hide = false;
//           return lst;
//         });
//         this.setState({
//           reportTypes: reportType,
//           reportOptions: reportOptions,
//           reportSettings: options,
//           reportVColumns: columns,
//           reportVCategories: categories,
//           clonedReportSettings: options,
//           layout,
//         });
//       }
//       if (this.props.account.getFinancialLayoutError) {
//         handleAPIErr(this.props.account.getFinancialLayoutError, this.props);
//       }
//     } else {
//       toast.error("Select a Report Type First");
//     }

//     this.setState({ isLoading: false });
//   };
//   createLayout = async (name) => {
//     this.setState({ isLoading: true });
//     let data = { layout: this.state.reportOptionVal.value };
//     if (data.layout != "") {
//       await this.props.primeFinancailLayout(data);
//       if (this.props.account.primeFinancialLayoutSuccess) {
//         let inheritOptions =
//           this.props.account?.primeFinancialLayout.inheritOptions || [];

//         let options = [];
//         let reportOptionVals = [];
//         inheritOptions.map((opt) => {
//           if (opt.option && opt.option.length > 0) {
//             options.push({ label: opt.option, value: opt.option });
//           }
//           if (opt.selected && opt.selected === 1) {
//             reportOptionVals = [{ label: opt.option, value: opt.option }];
//           }
//         });

//         this.setState({ inheritOptions: options, reportOptionVals });
//       }
//       if (this.props.account.primeFinancialLayoutError) {
//         handleAPIErr(this.props.account.primeFinancialLayoutError, this.props);
//       }
//       this.openModal(name);
//     } else {
//       toast.error("Select a Report Option First");
//     }
//     this.setState({ isLoading: false });
//   };
//   deleteFinancialLayout = async () => {
//     this.setState({ isLoaing: true });
//     let data = { layout: this.state.reportTypesVal.label };
//     if (data.layout != "") {
//       await this.props.deleteFinancialLayout(data);
//       if (this.props.account.deleteFinancialLayoutSuccess) {
//         toast.success(this.props.account.deleteFinancialLayoutSuccess);
//         // this.props.closeModal("financReportModal");
//       }
//       if (this.props.account.deleteFinancialLayoutError) {
//         handleAPIErr(this.props.account.deleteFinancialLayoutError, this.props);
//       }
//     } else {
//       toast.error("Select a Report Option First");
//     }

//     this.setState({ isLoading: false });
//   };
//   saveAndGenerateReport = async () => {
//     this.setState({ isLoading: true });

//     let data = {
//       layout: this.state.reportTypesVal.label,
//       reportId: this.state.reportTypesVal.value,
//       options: this.state.reportSettings,
//       columns: this.state.reportVColumns,
//       categories: this.state.reportVCategories,
//     };

//     // if(data){
//     await this.props.saveAndGenerateReport(data);
//     if (this.props.account.saveAndGenerateReportSuccess) {
//       let attachment =
//         this?.props?.account?.saveAndGenerateReport?.attachment || [];
//       let fileName =
//         this?.props?.account?.saveAndGenerateReport?.fileName || [];
//       let resp = {
//         attachment: attachment,
//         contentType: "application/pdf",
//         fileName: fileName,
//       };
//       downloadAttachments(resp, fileName);
//       // this.props.closeModal("financReportModal");
//       toast.success(this.props.account.saveAndGenerateReportSuccess);
//     }
//     if (this.props.account.saveAndGenerateReportError) {
//       handleAPIErr(this.props.account.saveAndGenerateReportError, this.props);
//     }
//     // }
//     // else{
//     //   toast.error("Can't Save and Generate Report.")
//     // }
//     this.setState({ isLoading: false });
//   };
//   saveFinancialLayout = async () => {
//     this.setState({ isLoading: true });
//     if (this.state.reportTypesVal.label !== "") {
//       let data = {
//         reportId: this.state.reportTypesVal.value,
//         layout: this.state.reportTypesVal.label,
//         options: this.state.reportSettings,
//         columns: this.state.reportVColumns,
//         categories: this.state.categories,
//       };
//       await this.props.saveFinancialLayout(data);
//       if (this.props.account.saveFinancialLayoutSuccess) {
//         toast.success(this.props.account.saveFinancialLayoutSuccess);
//       }
//       if (this.props.account.saveFinancialLayoutError) {
//         handleAPIErr(this.props.account.saveFinancialLayoutError);
//       }
//     } else {
//       toast.error(
//         "Please Select a Report Type First for Saving Financial Layout"
//       );
//     }

//     this.setState({ isLoading: false });
//   };

//   primeColumnOptionDetails = async (name) => {
//     this.setState({ isLoading: true });
//     await this.props.primeColumnOptionDetails();
//     if (this.props.account.primeColumnOptionDetailsSuccess) {
//       toast.success(this.props.account.primeColumnOptionDetailsSuccess);
//       let columnDetails = this.props.account.primeColumnOptionDetails || [];
//       columnDetails.map((lst, i) => {
//         if ((lst.type && lst.type.toLowerCase() === "list") || "droplist") {
//           let valOptns = [];
//           if (lst.options && lst.options.length > 0) {
//             lst.options.map((o, i) => {
//               valOptns.push({ label: o.option, value: o.option });
//             });
//           }
//           lst.options = valOptns;
//         }
//         return lst;
//       });
//       this.setState({ columnDetails });
//       if (columnDetails.length > 0) {
//         this.openModal(name);
//       }
//     }
//     if (this.props.account.primeColumnOptionDetailsError) {
//       handleAPIErr(
//         this.props.account.primeColumnOptionDetailsError,
//         this.props
//       );
//     }
//     this.setState({ isLoading: false });
//   };
//   getColumnOptionDetails = async (name, list) => {
//     this.setState({ isLoading: true });
//     let data = {
//       layout: this.state.reportTypeValue[0].label,
//       number: list.number,
//     };
//     await this.props.getColumnOptionDetails(data);
//     if (this.props.account.getColumnOptionDetailsSuccess) {
//       toast.success(this.props.account.getColumnOptionDetailsSuccess);
//       let columnDetailsEdit = this.props.account.getColumnOptionDetails || [];
//       columnDetailsEdit.map((lst, i) => {
//         if ((lst.type && lst.type.toLowerCase() === "list") || "droplist") {
//           let valOptns = [];
//           if (lst.options && lst.options.length > 0) {
//             lst.options.map((o, i) => {
//               valOptns.push({ label: o.option, value: o.option });
//             });
//           }
//           lst.options = valOptns;
//         }
//         return lst;
//       });
//       this.setState({ columnDetailsEdit });
//       if (columnDetailsEdit) {
//         this.openModal(name);
//       }
//     }
//     if (this.props.account.getColumnOptionDetailsError) {
//       handleAPIErr(this.props.account.getColumnOptionDetailsError, this.props);
//     }
//     this.setState({ isLoading: false });
//   };

//   addNewReport = () => {
//     // this.setState((prevState) => ({
//     //   ...prevState,
//     //   addNewReportToggle: !prevState.addNewReportToggle,
//     //   reportOptionVal: { label: "", value: "" },
//     //   formErrors: {
//     //     ...formErrors,
//     //     reportOptionVal: "",
//     //   },
//     // }));
//   };

//   //Hide/Unhide Rows
//   _handleHideUnhideRows = (item) => {
//     let { reportSettings, clonedReportSettings, showHiddenRows } = this.state;

//     let result = handleHideUnhideRows(
//       item,
//       "#reportv4-table",
//       "reports__settings",
//       reportSettings,
//       clonedReportSettings,
//       showHiddenRows
//     );

//     this.setState((prev) => ({
//       ...prev,
//       reportSettings: result.advancedList,
//       clonedReportSettings: result.clonedAdvancedList,
//       showHiddenRows: result.showHiddenRows,
//       reDrawDT: true,
//     }));
//   };
//   handleReportTypes = (type) => {
//     let { formErrors } = this.state;

//     formErrors = Validation.handleValidation(
//       "reportTypesVal",
//       type.value,
//       formErrors
//     );

//     let value = type.value || "";

//     let getReportOptions = this.props.reportTypes || [];
//     let reportTypes = [];
//     let reportId = "";
//     let found = getReportOptions.find((rop) => rop.value === value);
//     if (found) {
//       let reportLayouts = found.reportLayouts || [];
//       reportLayouts.map((l, i) => {
//         reportTypes.push({
//           label: l.label,
//           value: l.value,
//           locked: l.locked,
//         });
//         reportId = l.value;
//       });
//     }
//     this.setState(
//       (prev) => ({
//         ...prev,
//         reportTypesVal: type,
//         reportId,
//         reportTypes: getReportOptions,
//         formErrors,
//       }),
//       () => {
//         let data = {
//           layout: this.state.reportTypesVal.label,
//         };
//         this.getFinancialLayout(data);
//       }
//     );
//   };
//   handleReportOptions = (layout) => {
//     let { formErrors } = this.state;
//     let getReportOptions = this.state.reportOptions || [];
//     let value = layout.value;
//     let found = getReportOptions.find((rop) => rop.value === value);
//     formErrors = Validation.handleValidation(
//       "reportOptionVal",
//       layout.value,
//       formErrors
//     );

//     this.setState((prev) => ({
//       ...prev,
//       reportOptionVal: layout,
//       reportOptions: getReportOptions,
//       addNewReportToggle: false,
//       layouts: layout.label,
//       formErrors,
//     }));
//   };
//   //Advanced List Setting
//   _handleValueOptions = async (type, val, item, index) => {
//     let { reportSettings, clonedReportSettings } = this.state;
//     let result = handleValueOptions(
//       type,
//       val,
//       item,
//       index,
//       reportSettings,
//       clonedReportSettings
//     );
//     this.setState({
//       ...this.state,
//       reportSettings: result.advancedList,
//       clonedReportSettings: result.clonedAdvancedList,
//     });
//  
//   };
//   handleShowHiddenRows = async () => {
//     let table = window.$("#reportv4-table").DataTable();
//     table.destroy();

//     let { reportSettings, clonedReportSettings, showHiddenRows } = this.state;

//     showHiddenRows = !showHiddenRows;

//     if (showHiddenRows) {
//       //show hidden rows
//       reportSettings = clonedReportSettings;
//     } else {
//       //hide again hidden rows
//       let list = reportSettings.filter((l) => !l.hide);
//       reportSettings = list;
//     }

//     this.setState((prev) => ({
//       ...prev,
//       reportSettings,
//       showHiddenRows,
//       reDrawDT: true, //to re daraw the datatable
//     }));
//   };
//   menuOpened = async () => {
//     this.setState((prev) => ({ ...prev, menuIsOpen: true }));
//     $(document).ready(async function () {
//       $('<i class="fa fa-trash report--del"></i>').appendTo(
//         ".report_menu.custon_select-selector-inner__option"
//       );
//       $(".report_menu.custon_select-selector-inner__option i").on(
//         "click",
//         async function (e) {
//           e.preventDefault();
//           var p_id = $(this).parent().attr("id");
//           let id_split = p_id && p_id.split("-"); //["react", "select", "3", "option", "1"]
//           let id = id_split[id_split.length - 1];

//           let { reportOptions } = this.state;
//           let rep_opt = reportOptions.length > 0 && reportOptions[id];
//           //call delete report options API
//         }
//       );
//     });
//   };
//   menuClosed = () => {
//     this.setState((prev) => ({ ...prev, menuIsOpen: false }));
//   };
//   clearStates = () => {
//     this.setState({
//       addNewReportToggle: false,
//       reportTypesVal: [{ label: "", value: "" }],
//       reportTypes: [],
//       reportOptionVal: { label: "", value: "" },
//       reportOptions: [],
//       menuIsOpen: false,
//       reportFile: "",
//       reportName: "",
//       privateCheck: false,
//       reportSettings: [],
//       showHiddenRows: false,
//       formErrors: {
//         reportTypesVal: "",
//         reportOptionVal: "",
//         reportFile: "",
//         reportName: "",
//       },
//     });
//     // props.getLayoutOptions();
//     this.props.closeModal("financReportModal");
//   };
//   onSaveReport = async () => {
//     let {
//       reportName,
//       reportTypesVal,
//       reportOptionVal,
//       reportFile,
//       addNewReportToggle,
//       privateCheck,
//       formErrors,
//       clonedReportSettings,
//     } = this.state;

//     if (addNewReportToggle) {
//       //add new report case
//       formErrors = Validation.handleWholeValidation(
//         { reportName, reportFile },
//         formErrors
//       );
//     } else {
//       //use reports from the drop down list
//       formErrors = Validation.handleWholeValidation(
//         {
//           reportTypesVal: reportTypesVal.value,
//           reportOptionVal: reportOptionVal.value,
//         },
//         formErrors
//       );
//     }

//     let check = false;
//     if (addNewReportToggle) {
//       if (!formErrors.reportFile && !formErrors.reportName) {
//         check = true;
//       }
//     } else {
//       if (!formErrors.reportOptionVal) {
//         check = true;
//       }
//     }
//     if (!formErrors.reportTypesVal && check) {
//       let data = "";
//       if (reportOptionVal.value) {
//         //get report data according to available report options
//         data = {
//           reportType: this.props.reportType,
//           reportID: reportOptionVal.value,
//           reportSettings: clonedReportSettings,
//         };
//       } else {
//         //create new reports and get data according to that report
//         data = {
//           reportType: this.props.reportType,
//           reportFile,
//           reportName,
//           private: privateCheck,
//           reportSettings: clonedReportSettings,
//         };
//       }
//       if (data) {
//         this.setState((prev) => ({ ...prev, isLoading: true }));
//       }
//     }
//     this.setState((prev) => ({ ...prev, formErrors, isLoading: false }));
//   };

//   openModal = async (name) => {
//     this.setState({ [name]: true });
//   };
//   closeModal = async (name, columnDetails) => {

//     if (name === "openAddColumnsPopupFormModal" && columnDetails) {
//       this.setState({ reportVColumns: columnDetails, [name]: false });
//     } else if (name === "openColumnsPopupFormModal" && columnDetails) {
//       this.setState({ reportVColumns: columnDetails, [name]: false });
//     } else {
//       this.setState({ [name]: false });
//     }
//   };

//   rotateClass = () => {
//     $(".sideBarAccord1").toggleClass("rorate_0");
//   };
//   rotateClassTwo = () => {
//     $(".sideBarAccord2").toggleClass("rorate_0");
//   };
//   rotateClassThree = () => {
//     $(".sideBarAccord").toggleClass("rorate_0");
//   };

//   render() {
//     return (
//       <>
//         {this.state.isLoading ? <div className="se-pre-con"></div> : ""}
//         <Modal
//           size="xl"
//           aria-labelledby="contained-modal-title-vcenter"
//           centered
//           show={this.props.financReportModal}
//           onHide={this.clearStates}
//           className="mm_report_new_modal mx-auto reports-v4-modal"
//         >
//           <Modal.Body>
//             <div className="container-fluid p-0">
//               <div className="main_wrapper">
//                 <div className="row d-flex h-100 p-0">
//                   <div className="col-12 justify-content-center align-self-center">
//                     <div className="setting_form_main p-0">
//                       <div className="setting_header thead_bg">
//                         <h3 className="Indirecttaxcode-poup_heading">
//                           Reports
//                         </h3>
//                         <div className="Indirecttaxcode-poup_can-sav-btn">
//                           <button
//                             onClick={this.deleteFinancialLayout}
//                             className="btn can-btn1 pr-2 pb-1"
//                           >
//                             <img src="images/delete-white.png" alt="cancel" />
//                           </button>
//                           <button
//                             onClick={() => this.saveAndGenerateReport()}
//                             className="btn can-btn1 pr-3"
//                           >
//                             Run
//                           </button>
//                           <button
//                             className="btn can-btn1"
//                             onClick={this.saveFinancialLayout}
//                           >
//                             <img
//                               src="images/user-setup/check-white.png"
//                               alt="check"
//                             />
//                             Save
//                           </button>
//                           <button
//                             onClick={this.clearStates}
//                             className="btn can-btn1 pl-3"
//                           >
//                             <img
//                               src="images/user-setup/cancel-white.png"
//                               alt="cancel"
//                             />
//                             Cancel
//                           </button>
//                           <button className="btn can-btn1 pl-2">
//                             <img
//                               src="images/user-setup/dots-h.png"
//                               alt="dots"
//                             />
//                           </button>
//                         </div>
//                       </div>
//                       <div className="reportv4-modal-inner">
//                         <div className="row mt-4">
//                           <div className="col-xl-3 col-md-3 col-sm-12 ">
//                             <div className="form-group custon_select">
//                               <label>Select Report Type</label>

//                               <Select
//                                 className="width-selector"
//                                 value={this.state.reportTypesVal}
//                                 classNamePrefix="custon_select-selector-inner"
//                                 options={this.state.reportTypes}
//                                 onChange={this.handleReportTypes}
//                                 theme={(theme) => ({
//                                   ...theme,
//                                   border: 0,
//                                   borderRadius: 0,
//                                   colors: {
//                                     ...theme.colors,
//                                     primary25: "#f2f2f2",
//                                     primary: "#f2f2f2",
//                                   },
//                                 })}
//                               />
//                               <div className="text-danger error-12">
//                                 {/* {formErrors.reportTypesVal !== ""
//                                 ? formErrors.reportTypesVal
//                                 : ""} */}
//                               </div>
//                               <span
//                                 className="input_field_icons rit-icon-input"
//                                 onClick={() =>
//                                   this.createLayout("openAccountReportModal")
//                                 }
//                               >
//                                 <i className="fa fa-plus"></i>
//                               </span>
//                             </div>
//                           </div>
//                         </div>

//                         <div className="row">
//                           <div className="form-group col-xl-3 col-md-3 col-sm-12">
//                             <div className="custon_select">
//                               <label>Select Report Option</label>

//                               <Select
//                                 className="width-selector"
//                                 closeMenuOnSelect={true}
//                                 value={this.state.reportOptionVal}
//                                 classNamePrefix="report_menu custon_select-selector-inner"
//                                 onMenuOpen={this.menuOpened}
//                                 onMenuClose={this.menuClosed}
//                                 onChange={this.handleReportOptions}
//                                 options={this.state.reportOptions}
//                                 theme={(theme) => ({
//                                   ...theme,
//                                   border: 0,
//                                   borderRadius: 0,
//                                   colors: {
//                                     ...theme.colors,
//                                     primary25: "#f2f2f2",
//                                     primary: "#f2f2f2",
//                                   },
//                                 })}
//                               />
//                               <div className="text-danger error-12">
//                                 {/* {formErrors.reportOptionVal !== ""
//                                 ? formErrors.reportOptionVal
//                                 : ""} */}
//                               </div>
//                               <span
//                                 className="input_field_icons rit-icon-input"
//                                 onClick={this.addNewReport}
//                               >
//                                 <i className="fa fa-plus"></i>
//                               </span>
//                             </div>
//                           </div>
//                           {this.state.addNewReportToggle && (
//                             <div className=" pl-3 pr-3 w-100">
//                               <div className="row">
//                                 <div className="col-12">
//                                   <div className="form-group custon_select mt-3">
//                                     <div className="modal_input">
//                                       <label>Report Name</label>
//                                       <input
//                                         type="text"
//                                         className="form-control pl-0"
//                                         id="usr"
//                                         name="reportName"
//                                         value={this.state.reportName}
//                                         // onChange={handleFieldChange}
//                                       />
//                                       <div className="text-danger error-12">
//                                         {this.state.formErrors.reportName !== ""
//                                           ? this.state.formErrors.formErrors
//                                               .reportName
//                                           : ""}
//                                       </div>
//                                     </div>
//                                   </div>
//                                 </div>
//                                 <div className="col-12 align-self-center mb-2 supp2-roprt-check">
//                                   <div className="form-group remember_check">
//                                     <input
//                                       type="checkbox"
//                                       id="remember"
//                                       checked={this.state.privateCheck}
//                                       //   onChange={(e) => handleReportCheckbox(e)}
//                                     />
//                                     <label htmlFor="remember"></label>
//                                     <p className="reports-excel rc-lineheight">
//                                       Private:
//                                     </p>
//                                   </div>
//                                 </div>
//                                 <div className="col-12 mt-2 mb-2">
//                                   <div className="form-group custon_select  text-center mb-0 border-rad-5">
//                                     <div id="drop-area-report">
//                                       <input
//                                         type="file"
//                                         id="fileElem-rep"
//                                         className="form-control d-none"
//                                         accept="application/pdf"
//                                         onChange={(e) => {
//                                           //   uploadReportFile(e.target.files);
//                                         }}
//                                         onClick={(event) => {
//                                           event.currentTarget.value = null;
//                                         }} //to upload the same file again
//                                       />

//                                       <label
//                                         className="upload-label"
//                                         htmlFor="fileElem-rep"
//                                       >
//                                         <div className="upload-text">
//                                           <img
//                                             src="images/drag-file.png"
//                                             className="import_icon img-fluid"
//                                             alt="upload-report"
//                                           />
//                                         </div>
//                                       </label>
//                                     </div>
//                                   </div>
//                                   <div className="text-danger error-12">
//                                     {this.state.formErrors.reportFile !== ""
//                                       ? this.state.formErrors.reportFile
//                                       : ""}
//                                   </div>
//                                 </div>
//                               </div>
//                             </div>
//                           )}
//                         </div>
//                         <div className="row">
//                           <div className="col-xl-6 col-md-6 mob-order2">
//                             <div className="user_setup_popup__table new_advance_table m-auto p-3">
//                               <h2>Advanced</h2>
//                               <table className="table" id="usersteup-modal">
//                                 <thead className="thead_bg thead_bg_sass hover-border">
//                                   <tr>
//                                     <th scope="col">
//                                       <span className="user_setup_hed">
//                                         Category
//                                       </span>
//                                     </th>
//                                     <th scope="col">
//                                       <span className="user_setup_hed">
//                                         Description
//                                       </span>
//                                     </th>
//                                     <th
//                                       scope="col"
//                                       className="value__field--wrapperuser"
//                                     >
//                                       <span className="user_setup_hed">
//                                         Value
//                                       </span>
//                                     </th>
//                                     <th
//                                       scope="col"
//                                       className="text__right__contentuser"
//                                     >
//                                       <span className="user_setup_hed">
//                                         Hide
//                                       </span>
//                                     </th>
//                                     <th className="table__inner--th">
//                                       <div className="dropdown">
//                                         <button
//                                           aria-haspopup="true"
//                                           aria-expanded="true"
//                                           id=""
//                                           type="button"
//                                           className="dropdown-toggle btn dept-tbl-menu "
//                                           data-toggle="dropdown"
//                                         >
//                                           <span className="fa fa-bars "></span>
//                                         </button>
//                                         <div className="dropdown-menu dept-menu-list dropdown-menu-right">
//                                           <div className="pr-0 dropdown-item">
//                                             <div className="form-group remember_check mm_check4">
//                                               <input
//                                                 type="checkbox"
//                                                 id="showHiddenRows"
//                                                 name="showHiddenRows"
//                                                 checked={
//                                                   this.state.showHiddenRows
//                                                 }
//                                                 onClick={
//                                                   this.handleShowHiddenRows
//                                                 }
//                                                 onChange={() => {}}
//                                               />
//                                               <label
//                                                 htmlFor="showHiddenRows"
//                                                 className="mr-0"
//                                               >
//                                                 Show Hidden Rows
//                                               </label>
//                                             </div>
//                                           </div>
//                                         </div>
//                                       </div>
//                                     </th>
//                                   </tr>
//                                 </thead>
//                                 <tbody>
//                                   {this.state.reportSettings.map((list, i) => {
//                                     return (
//                                       <tr key={i}>
//                                         <td className=" ">{list.category}</td>
//                                         <td>{list.description}</td>
//                                         {list.valueType === "List" ? (
//                                           <td className="pt-0 pb-0 text-left">
//                                             <Select
//                                               classNamePrefix="custon_select-selector-inner main__dropdown--wrapper1"
//                                               styles={_customStyles}
//                                               value={{
//                                                 label: list.value,
//                                                 value: list.value,
//                                               }}
//                                               options={list.options}
//                                               onChange={(obj) =>
//                                                 this._handleValueOptions(
//                                                   "list",
//                                                   obj,
//                                                   list,
//                                                   i
//                                                 )
//                                               }
//                                               theme={(theme) => ({
//                                                 ...theme,
//                                                 border: 0,
//                                                 borderRadius: 0,
//                                                 colors: {
//                                                   ...theme.colors,
//                                                   primary25: "#f2f2f2",
//                                                   primary: "#f2f2f2",
//                                                 },
//                                               })}
//                                             />
//                                           </td>
//                                         ) : list.valueType === "MultiList" ? (
//                                           <td className="pt-0 pb-0 text-left">
//                                             <Select
//                                               className="basic-multi-select main__dropdown--wrapper1"
//                                               name="multiple-op"
//                                               // classNamePrefix="custon_select-selector-inner"
//                                               defaultValue={{
//                                                 label: "Select Approval Group",
//                                                 value: "",
//                                               }}
//                                               value={list.multiValue || []}
//                                               options={list.valueOptions}
//                                               isClearable={false}
//                                               onChange={(obj) =>
//                                                 this._handleValueOptions(
//                                                   "multiList",
//                                                   obj,
//                                                   list,
//                                                   i
//                                                 )
//                                               }
//                                               isMulti
//                                               theme={(theme) => ({
//                                                 ...theme,
//                                                 border: 0,
//                                                 borderRadius: 0,
//                                                 colors: {
//                                                   ...theme.colors,
//                                                   primary25: "#f2f2f2",
//                                                   primary: "#f2f2f2",
//                                                 },
//                                               })}
//                                             />
//                                           </td>
//                                         ) : list.valueType === "Date" ? (
//                                           <td>
//                                             <div className="table_input_field">
//                                               <DatePicker
//                                                 selected={Number(list.value)}
//                                                 dateFormat="d MMM yyyy"
//                                                 autoComplete="off"
//                                                 onChange={(date) =>
//                                                   this._handleValueOptions(
//                                                     "date",
//                                                     date,
//                                                     list,
//                                                     i
//                                                   )
//                                                 }
//                                               />
//                                             </div>
//                                           </td>
//                                         ) : list.valueType === "Check" ? (
//                                           <td>
//                                             <div className="col-auto p-0">
//                                               <div className="form-group remember_check  text-center pt-0 float-left">
//                                                 <input
//                                                   type="checkbox"
//                                                   id={`chk${i}`}
//                                                   checked={
//                                                     list.value === "Y" ||
//                                                     list.value === "1"
//                                                       ? true
//                                                       : false
//                                                   }
//                                                   onChange={(e) =>
//                                                     this._handleValueOptions(
//                                                       "checkbox",
//                                                       e,
//                                                       list,
//                                                       i
//                                                     )
//                                                   }
//                                                 />
//                                                 <label
//                                                   htmlFor={`chk${i}`}
//                                                 ></label>
//                                               </div>
//                                             </div>
//                                           </td>
//                                         ) : list.valueType === "Numeric" ||
//                                           list.valueType === "Number" ? (
//                                           <td>
//                                             <div className="table_input_field">
//                                               <input
//                                                 type="number"
//                                                 value={list.value}
//                                                 onChange={(e) =>
//                                                   this._handleValueOptions(
//                                                     "number",
//                                                     e,
//                                                     list,
//                                                     i
//                                                   )
//                                                 }
//                                               />
//                                             </div>
//                                           </td>
//                                         ) : list.valueType === "Text" ? (
//                                           <td>
//                                             <div className="table_input_field">
//                                               <input
//                                                 type="text"
//                                                 value={list.value}
//                                                 onChange={(e) =>
//                                                   this._handleValueOptions(
//                                                     "text",
//                                                     e,
//                                                     list,
//                                                     i
//                                                   )
//                                                 }
//                                               />
//                                             </div>
//                                           </td>
//                                         ) : (
//                                           <td>{list.value}</td>
//                                         )}
//                                         <td className="text__right--user">
//                                           <div className="custom-radio">
//                                             <label
//                                               className="check_main  remember_check"
//                                               htmlFor={`hideUnhideRows${i}`}
//                                             >
//                                               <input
//                                                 type="checkbox"
//                                                 className="custom-control-input"
//                                                 name={"hideUnhideRows"}
//                                                 id={`hideUnhideRows${i}`}
//                                                 checked={false}
//                                                 onChange={(e) =>
//                                                   this._handleHideUnhideRows(
//                                                     list
//                                                   )
//                                                 }
//                                               />

//                                               {/* <span className='click_checkmark'></span> */}
//                                               <span
//                                                 // className={
//                                                 //   list.hide
//                                                 //     ? "dash_checkmark bg_clr"
//                                                 //     : "dash_checkmark"
//                                                 // }

//                                                 className={
//                                                   list.hide
//                                                     ? "dash_checkmark bg_clr "
//                                                     : "dash_checkmark"
//                                                 }
//                                               ></span>
//                                             </label>
//                                           </div>
//                                         </td>
//                                       </tr>
//                                     );
//                                   })}
//                                 </tbody>
//                               </table>
//                             </div>
//                           </div>
//                         </div>

//                         {/* <div className="reports_popup__table " id="accordion">
//                           <h2>
//                             <span>
//                               {" "}
//                               <img
//                                 onClick={this.rotateClass}
//                                 src="images/arrow_up.png"
//                                 class="import_icon img-fluid pr-3 ml-3 sideBarAccord1"
//                                 alt="arrow_up"
//                                 data-toggle="collapse"
//                                 data-target="#finace1"
//                                 aria-expanded="false"
//                               />
//                             </span>
//                             Advanced
//                           </h2>
//                           <div
//                             className="reconciliation-table1 table_white_box"
//                             id="finace1"
//                             data-parent="#accordion"
//                           >
//                             <table
//                               className="table"
//                               id="reportv4-table"
//                               width="100%"
//                             >
//                               <thead className="thead_bg hover-border">
//                                 <tr>
//                                   <th scope="col"></th>
//                                   <th scope="col">
//                                     <span className="user_setup_hed">
//                                       category
//                                     </span>
//                                   </th>
//                                   <th scope="col">
//                                     <span className="user_setup_hed">
//                                       Description
//                                     </span>
//                                   </th>
//                                   <th scope="col">
//                                     <span className="user_setup_hed">
//                                       Value
//                                     </span>
//                                   </th>
//                                   <th scope="col">
//                                     <span className="user_setup_hed">From</span>
//                                   </th>
//                                   <th scope="col">
//                                     <span className="user_setup_hed">To</span>
//                                   </th>
//                                   <th scope="col">
//                                     <span className="user_setup_hed">Hide</span>
//                                   </th>
//                                   <th className="table__inner--th">
//                                     <div className="dropdown">
//                                       <button
//                                         aria-haspopup="true"
//                                         aria-expanded="true"
//                                         id=""
//                                         type="button"
//                                         className="dropdown-toggle btn dept-tbl-menu "
//                                         data-toggle="dropdown"
//                                       >
//                                         <span className="fa fa-bars "></span>
//                                       </button>
//                                       <div className="dropdown-menu dept-menu-list dropdown-menu-right">
//                                         <div className="pr-0 dropdown-item">
//                                           <div className="form-group remember_check mm_check4">
//                                             <input
//                                               type="checkbox"
//                                               id="showHiddenRows"
//                                               name="showHiddenRows"
//                                               checked={
//                                                 this.state.showHiddenRows
//                                               }
//                                               onClick={
//                                                 this.handleShowHiddenRows
//                                               }
//                                             />
//                                             <label
//                                               htmlFor="showHiddenRows"
//                                               className="mr-0"
//                                             >
//                                               Show Hidden Rows
//                                             </label>
//                                           </div>
//                                         </div>
//                                       </div>
//                                     </div>
//                                   </th>
//                                 </tr>
//                               </thead>
//                               <tbody>
//                                 {this.state.reportSettings?.map((list, i) => {
//                                   return (
//                                     <tr>
//                                       <td></td>
//                                       <td>{list.category}</td>
//                                       <td>{list.description}</td>
//                                       {list.valueType === "List" ? (
//                                         <td className="pt-0 pb-0 text-left  value__field--wrapperdept ">
//                                           <Select
//                                             classNamePrefix="custon_select-selector-inner main__dropdown--wrappertwo select__font-wrapper                                        "
//                                             value={{
//                                               label: list.value,
//                                               value: list.value,
//                                             }}
//                                             options={list.options}
//                                             onChange={(obj) =>
//                                               this._handleValueOptions(
//                                                 "list",
//                                                 obj,
//                                                 list,
//                                                 i
//                                               )
//                                             }
//                                             styles={_customStyles}
//                                             theme={(theme) => ({
//                                               ...theme,
//                                               border: 0,
//                                               borderRadius: 0,
//                                               colors: {
//                                                 ...theme.colors,
//                                                 primary25: "#f2f2f2",
//                                                 primary: "#f2f2f2",
//                                               },
//                                             })}
//                                           />
//                                         </td>
//                                       ) : list.valueType === "Date" ? (
//                                         <td>
//                                           <div className="table_input_field">
//                                             <DatePicker
//                                               selected={Number(list.value)}
//                                               dateFormat="d MMM yyyy"
//                                               autoComplete="off"
//                                               readOnly={
//                                                 list.readOnly === "Y"
//                                                   ? true
//                                                   : false
//                                               }
//                                               onChange={(date) =>
//                                                 this._handleValueOptions(
//                                                   "date",
//                                                   date,
//                                                   list,
//                                                   i
//                                                 )
//                                               }
//                                             />
//                                           </div>
//                                         </td>
//                                       ) : list.valueType === "Check" ? (
//                                         <td>
//                                           <div className="col-auto p-0">
//                                             <div className="form-group remember_check  text-center pt-0 float-left">
//                                               <input
//                                                 type="checkbox"
//                                                 id={`chk${i}`}
//                                                 checked={
//                                                   list.value === "Y" ||
//                                                   list.value === "1"
//                                                     ? true
//                                                     : false
//                                                 }
//                                                 onChange={(e) =>
//                                                   this._handleValueOptions(
//                                                     "checkbox",
//                                                     e,
//                                                     list,
//                                                     i
//                                                   )
//                                                 }
//                                               />
//                                               <label
//                                                 htmlFor={`chk${i}`}
//                                               ></label>
//                                             </div>
//                                           </div>
//                                         </td>
//                                       ) : list.valueType === "Numeric" ? (
//                                         <td>
//                                           <div className="table_input_field">
//                                             <input
//                                               type="number"
//                                               value={list.value}
//                                               onChange={(e) =>
//                                                 this._handleValueOptions(
//                                                   "number",
//                                                   e,
//                                                   list,
//                                                   i
//                                                 )
//                                               }
//                                             />
//                                           </div>
//                                         </td>
//                                       ) : list.valueType === "Range" ||
//                                         list.valueType === "Text" ? (
//                                         <td>
//                                           <div className="table_input_field">
//                                             <input
//                                               type="text"
//                                               value={list.value}
//                                               onChange={(e) =>
//                                                 this._handleValueOptions(
//                                                   "text",
//                                                   e,
//                                                   list,
//                                                   i
//                                                 )
//                                               }
//                                             />
//                                           </div>
//                                         </td>
//                                       ) : (
//                                         <td>{list.value}</td>
//                                       )}
//                                       <td>{list.from}</td>
//                                       <td>{list.to}</td>
//                                       <td>
//                                         <div className="custom-radio">
//                                           <label
//                                             className="check_main remember_check"
//                                             htmlFor={`hideUnhideRows${i}`}
//                                           >
//                                             <input
//                                               type="checkbox"
//                                               className="custom-control-input"
//                                               name={"hideUnhideRows"}
//                                               id={`hideUnhideRows${i}`}
//                                               checked={false}
//                                               onChange={(e) =>
//                                                 this._handleHideUnhideRows(list)
//                                               }
//                                             />

//                                             {/* <span className='click_checkmark'></span> */}
//                         {/* <span
//                                               className={
//                                                 list.hide
//                                                   ? "dash_checkmark bg_clr"
//                                                   : "dash_checkmark"
//                                               }
//                                             ></span>
//                                           </label>
//                                         </div>
//                                       </td>
//                                       <td></td>
//                                     </tr>
//                                   );
//                                 })}
//                               </tbody>
//                             </table>
//                           </div> */}
//                         {/*</div> */}

//                         <div className="reports_popup__table bord-btm pt-3">
//                           <h2 className="d-inline">
//                             {" "}
//                             <span>
//                               <img
//                                 onClick={this.rotateClassTwo}
//                                 src="images/arrow_up.png"
//                                 class="import_icon img-fluid pr-3 ml-3 sideBarAccord2"
//                                 alt="arrow_up"
//                                 data-toggle="collapse"
//                                 data-target="#finace2"
//                                 aria-expanded="true"
//                               />
//                             </span>
//                             V Columns
//                           </h2>
//                           <div className="user_setup_plus_Icons pt-0">
//                             <ul>
//                               <li>
//                                 <button
//                                   className="btn user_setup_rbtns"
//                                   type="button"

//                                 >
//                                   <span
//                                     className="round_plus"
//                                     style={{
//                                       display: "flex",
//                                       width: "22px",
//                                       height: "22px",
//                                       alignItems: "center",

//                                       background: "#2f73ad",
//                                       borderRadius: "50%",
//                                       justifyContent: "center",
//                                     }}
//                                   >
//                                     <svg
//                                       style={{ fill: "white" }}
//                                       version="1.1"
//                                       id="Capa_1"
//                                       xmlns="http://www.w3.org/2000/svg"
//                                       x="0px"
//                                       y="0px"
//                                       width="14px"
//                                       height="14px"
//                                       viewBox="0 0 349.03 349.031"
//                                     >
//                                       <g>
//                                         <path
//                                           d="M349.03,141.226v66.579c0,5.012-4.061,9.079-9.079,9.079H216.884v123.067c0,5.019-4.067,9.079-9.079,9.079h-66.579
// 		                          c-5.009,0-9.079-4.061-9.079-9.079V216.884H9.079c-5.016,0-9.079-4.067-9.079-9.079v-66.579c0-5.013,4.063-9.079,9.079-9.079
// 		                          h123.068V9.079c0-5.018,4.069-9.079,9.079-9.079h66.579c5.012,0,9.079,4.061,9.079,9.079v123.068h123.067
// 		                          C344.97,132.147,349.03,136.213,349.03,141.226z"
//                                         />
//                                       </g>
//                                     </svg>
//                                   </span>
//                                 </button>
//                               </li>
//                               <li>
//                                 <button
//                                   className="btn user_setup_rbtns"
//                                   type="button"
//                                 >
//                                   <span
//                                     className="round_file"
//                                     style={{
//                                       display: "flex",
//                                       width: "22px",
//                                       height: "22px",

//                                       alignItems: "center",

//                                       background: "#2f73ad",
//                                       borderRadius: "50%",
//                                       justifyContent: "center",
//                                     }}
//                                   >
//                                     <svg
//                                       style={{ fill: "white" }}
//                                       version="1.1"
//                                       id="Capa_1"
//                                       xmlns="http://www.w3.org/2000/svg"
//                                       x="0px"
//                                       y="0px"
//                                       width="14px"
//                                       height="14px"
//                                       viewBox="0 0 384 384"
//                                       // style="enable-background:new 0 0 384 384;"
//                                     >
//                                       <g>
//                                         <g>
//                                           <g>
//                                             <path d="M64,341.333C64,364.907,83.093,384,106.667,384h170.667C300.907,384,320,364.907,320,341.333v-256H64V341.333z" />
//                                             <polygon points="266.667,21.333 245.333,0 138.667,0 117.333,21.333 42.667,21.333 42.667,64 341.333,64 341.333,21.333 			" />
//                                           </g>
//                                         </g>
//                                       </g>
//                                     </svg>
//                                   </span>
//                                 </button>
//                               </li>
//                             </ul>
//                           </div>
//                           <div
//                             className="reconciliation-table1 table_white_box w_50 collapse"
//                             id="finace2"
//                             data-parent="#accordion"
//                           >
//                             <table
//                               className="table"
//                               id="reportv4-table"
//                               width="60%"
//                             >
//                               <thead className="thead_bg hover-border">
//                                 <tr>
//                                   <th scope="col">
//                                     <span className="user_setup_hed">Col#</span>
//                                   </th>
//                                   <th scope="col">
//                                     <span className="user_setup_hed">
//                                       Description
//                                     </span>
//                                   </th>

//                                   <th scope="col">
//                                     <span className="user_setup_hed"></span>
//                                   </th>
//                                 </tr>
//                               </thead>
//                               <tbody>
//                                 {this.state.reportVColumns?.map((list, i) => {
//                                   return (
//                                     <tr>
//                                       <td>{list.col}</td>
//                                       <td>{list.description}</td>
//                                       <td
//                                         onClick={() =>
//                                           this.openModal(
//                                             "openColumnsPopupFormModal"
//                                           )
//                                         }
//                                       >
//                                         <img
//                                           src="images/p2.png"
//                                           class="import_icon cursorPointer"
//                                           style={{ marginLeft: "70%" }}
//                                           alt="pencill"
//                                         />
//                                       </td>
//                                     </tr>
//                                   );
//                                 })}
//                               </tbody>
//                             </table>
//                           </div>
//                         </div>

//                         {/* table V Category's */}
//                         <div className="reports_popup__table bord-btm pt-3">
//                           <h2 className="d-inline">
//                             {" "}
//                             <span>
//                               <img
//                                 onClick={this.rotateClassThree}
//                                 src="images/arrow_up.png"
//                                 class="import_icon img-fluid pr-3 ml-3 sideBarAccord"
//                                 alt="arrow_up"
//                                 data-toggle="collapse"
//                                 data-target="#finace3"
//                                 aria-expanded="false"
//                               />
//                             </span>
//                             V Category's
//                           </h2>
//                           <div className="user_setup_plus_Icons pt-0">
//                             <ul>
//                               <li>
//                                 <button
//                                   className="btn user_setup_rbtns"
//                                   type="button"
//                                 >
//                                   <span
//                                     className="round_plus"
//                                     style={{
//                                       display: "flex",
//                                       width: "22px",
//                                       height: "22px",
//                                       alignItems: "center",

//                                       background: "#2f73ad",
//                                       borderRadius: "50%",
//                                       justifyContent: "center",
//                                     }}
//                                   >
//                                     <svg
//                                       style={{ fill: "white" }}
//                                       version="1.1"
//                                       id="Capa_1"
//                                       xmlns="http://www.w3.org/2000/svg"
//                                       x="0px"
//                                       y="0px"
//                                       width="14px"
//                                       height="14px"
//                                       viewBox="0 0 349.03 349.031"
//                                     >
//                                       <g>
//                                         <path
//                                           d="M349.03,141.226v66.579c0,5.012-4.061,9.079-9.079,9.079H216.884v123.067c0,5.019-4.067,9.079-9.079,9.079h-66.579
// 		                          c-5.009,0-9.079-4.061-9.079-9.079V216.884H9.079c-5.016,0-9.079-4.067-9.079-9.079v-66.579c0-5.013,4.063-9.079,9.079-9.079
// 		                          h123.068V9.079c0-5.018,4.069-9.079,9.079-9.079h66.579c5.012,0,9.079,4.061,9.079,9.079v123.068h123.067
// 		                          C344.97,132.147,349.03,136.213,349.03,141.226z"
//                                         />
//                                       </g>
//                                     </svg>
//                                   </span>
//                                 </button>
//                               </li>
//                               <li>
//                                 <button
//                                   className="btn user_setup_rbtns"
//                                   type="button"
//                                 >
//                                   <span
//                                     className="round_file"
//                                     style={{
//                                       display: "flex",
//                                       width: "22px",
//                                       height: "22px",

//                                       alignItems: "center",

//                                       background: "#2f73ad",
//                                       borderRadius: "50%",
//                                       justifyContent: "center",
//                                     }}
//                                   >
//                                     <svg
//                                       style={{ fill: "white" }}
//                                       version="1.1"
//                                       id="Capa_1"
//                                       xmlns="http://www.w3.org/2000/svg"
//                                       x="0px"
//                                       y="0px"
//                                       width="14px"
//                                       height="14px"
//                                       viewBox="0 0 384 384"
//                                       // style="enable-background:new 0 0 384 384;"
//                                     >
//                                       <g>
//                                         <g>
//                                           <g>
//                                             <path d="M64,341.333C64,364.907,83.093,384,106.667,384h170.667C300.907,384,320,364.907,320,341.333v-256H64V341.333z" />
//                                             <polygon points="266.667,21.333 245.333,0 138.667,0 117.333,21.333 42.667,21.333 42.667,64 341.333,64 341.333,21.333 			" />
//                                           </g>
//                                         </g>
//                                       </g>
//                                     </svg>
//                                   </span>
//                                 </button>
//                               </li>
//                             </ul>
//                           </div>
//                           <div
//                             className="reconciliation-table1 table_white_box collapse"
//                             id="finace3"
//                             data-parent="#accordion"
//                           >
//                             <table
//                               className="table"
//                               id="reportv4-table"
//                               width="100%"
//                             >
//                               <thead className="thead_bg hover-border">
//                                 <tr>
//                                   <th scope="col">
//                                     <span className="user_setup_hed">Seq#</span>
//                                   </th>
//                                   <th scope="col">
//                                     <span className="user_setup_hed">
//                                       Description
//                                     </span>
//                                   </th>
//                                   <th scope="col">
//                                     <span className="user_setup_hed">
//                                       Chart Code From
//                                     </span>
//                                   </th>
//                                   <th scope="col">
//                                     <span className="user_setup_hed">
//                                       Chart Code To
//                                     </span>
//                                   </th>
//                                 </tr>
//                               </thead>
//                               <tbody>
//                                 {this.state.reportVCategories?.map(
//                                   (list, i) => {
//                                     return (
//                                       <tr
//                                         onClick={() =>
//                                           this.openModal(
//                                             "openCategoryPopupFormModal"
//                                           )
//                                         }
//                                       >
//                                         <td>{list.sequence}</td>
//                                         <td>{list.totalDescription}</td>
//                                         <td>{list.chartCodeFrom}</td>
//                                         <td>{list.chartCodeTo}</td>
//                                       </tr>
//                                     );
//                                   }
//                                 )}
//                               </tbody>
//                             </table>
//                           </div>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </Modal.Body>
//         </Modal>
//         <ColumnsPopupForm
//           openColumnsPopupFormModal={this.state.openColumnsPopupFormModal}
//           state={this.state}
//           closeModal={this.closeModal}
//         />
//         <AddColumnsPopupForm
//           openAddColumnsPopupFormModal={this.state.openAddColumnsPopupFormModal}
//           state={this.state}
//           closeModal={this.closeModal}
//         />
//         <CreateFinancialLayout
//           openAccountReportModal={this.state.openAccountReportModal}
//           layout={this.state.reportOptionVal.value}
//           reportOptions={this.state.inheritOptions}
//           reportOptionVal={this.state.reportOptionVals}
//           layouts={this.state.layouts}
//           getLayoutOptions={this.props.getLayoutOptions}
//           closeModal={this.closeModal}
//         />
//         {/* <CategoryPopupForm
//           openCategoryPopupFormModal={this.state.openCategoryPopupFormModal}
//           closeModal={this.closeModal}
//         /> */}
//       </>
//     );
//   }
// }

// const mapStateToProps = (state) => ({
//   account: state.account,
// });
// export default connect(mapStateToProps, {
//   primeFinancailLayout: AccountActions.primeFinancialLayout,
//   deleteFinancialLayout: AccountActions.deleteFinancialLayout,
//   saveAndGenerateReport: AccountActions.saveAndGenerateReport,
//   primeColumnOptionDetails: AccountActions.primeColumnOptionDetails,
//   getColumnOptionDetails: AccountActions.getColumnOptionDetails,
//   saveFinancialLayout: AccountActions.saveFinancialLayout,
//   getFinancialLayout: AccountActions.getFinancialLayout,
// })(FinancialLayoutModal);
