const INIT_STATE = {
  PrimePeriodClose: [],
  PrimePeriodCloseSuccess: "",
  PrimePeriodCloseError: "",

  periodCloseSuccess: "",
  periodCloseError: "",

  getLedgers: [],
  getLedgersSuccess: "",
  getLedgersError: "",

  getExportLedger: [],
  getExportLedgerSuccess: "",
  getExportLedgerError: "",

  updateLedgersSuccess: "",
  updateLedgersError: "",

  createDistChangeSuccess: "",
  createDistChangeError: "",

  getReconcile: [],
  getReconcileSuccess: "",
  getReconcileError: "",

  getAdvancedExcelExport: [],
  getAdvancedExcelExportSuccess: "",
  getAdvancedExcelExportError: "",

  getLedgerHistory: [],
  getLedgerHistorySuccess: "",
  getLedgerHistoryError: "",

  advancedExcelExport: "",
  advancedExcelExportSuccess: "",
  advancedExcelExportError: "",

  primeImportLedger: [],
  primeImportLedgerSuccess: "",
  primeImportLedgerError: "",

  getAccountAttachment: "",
  getAccountAttachmentSuccess: "",
  getAccountAttachmentError: "",

  addAccountAttachment: [],
  addAccountAttachmentSuccess: "",
  addAccountAttachmentError: "",

  getAccountAttachments: [],
  getAccountAttachmentsSuccess: "",
  getAccountAttachmentsError: "",

  getPrimeCashFlow: [],
  getPrimeCashFlowSuccess: "",
  getPrimeCashFlowError: "",

  getExportCashFlow: [],
  getExportCashFlowSuccess: "",
  getExportCashFlowError: "",

  getImportCashFlow: [],
  getImportCashFlowSuccess: "",
  getImportCashFlowError: "",

  getPrimeVatSubmission: [],
  getPrimeVatSubmissionSuccess: "",
  getPrimeVatSubmissionError: "",

  calculateVatSubmission: [],
  calculateVatSubmissionSuccess: "",
  calculateVatSubmissionError: "",

  getBankRecSummary: [],
  getBankRecSummarySuccess: "",
  getBankRecSummaryError: "",

  getUpdateStatement: [],
  getUpdateStatementSuccess: "",
  getUpdateStatementError: "",

  getBankRecAttachments: [],
  getBankRecAttachmentsSuccess: "",
  getBankRecAttachmentsError: "",

  getBankRecAttachment: [],
  getBankRecAttachmentSuccess: "",
  getBankRecAttachmentError: "",

  addBankRecAttachment: [],
  addBankRecAttachmentSuccess: "",
  addBankRecAttachmentError: "",

  deleteBankRecAttachment: [],
  deleteBankRecAttachmentSuccess: "",
  deleteBankRecAttachmentError: "",

  getUpdateStatus: [],
  getUpdateStatusSuccess: "",
  getUpdateStatusError: "",

  getCheques: [],
  getChequesSuccess: "",
  getChequesError: "",

  getUpdateCheques: [],
  getUpdateChequesSuccess: "",
  getUpdateChequesError: "",

  getPrimeCancelReIssue: [],
  getPrimeCancelReIssueSuccess: "",
  getPrimeCancelReIssueError: "",

  getCancelReIssueCheque: [],
  getCancelReIssueChequeSuccess: "",
  getCancelReIssueChequeError: "",

  getJournals: [],
  getJournalsSuccess: "",
  getJournalsChequeError: "",

  updateJournals: [],
  updateJournalsSuccess: "",
  updateJournalsError: "",

  getAdjustments: [],
  getAdjustmentsSuccess: "",
  getAdjustmentsError: "",

  updateAdjustments: [],
  updateAdjustmentsSuccess: "",
  updateAdjustmentsError: "",

  deleteAdjustments: [],
  deleteAdjustmentsSuccess: "",
  deleteAdjustmentsError: "",

  getBankTransactions: [],
  getBankTransactionsSuccess: "",
  getBankTransactionsError: "",

  updateBankTransactions: [],
  updateBankTransactionsSuccess: "",
  updateBankTransactionsError: "",

  deleteBankTransactions: [],
  deleteBankTransactionsSuccess: "",
  deleteBankTransactionsError: "",

  getExportExcel: [],
  getExportExcelSuccess: "",
  getExportExcelError: "",

  getImportExcel: [],
  getImportExcelSuccess: "",
  getImportExcelError: "",

  getPrimeCancelReIssue: [],
  getPrimeCancelReIssueSuccess: "",
  getPrimeCancelReIssueError: "",

  getPrimeCreateJournal: [],
  getPrimeCreateJournalSuccess: "",
  pgetPrimeCreateJournalError: "",

  createJournal: [],
  createJournalSuccess: "",
  createJournalError: "",

  getReconcileBank: [],
  getReconcileBankSuccess: "",
  getReconcileBankError: "",

  getReconcileAdjustments: [],
  getReconcileAdjustmentsSuccess: "",
  getReconcileAdjustmentsError: "",

  getPostBank: [],
  getPostBankSuccess: "",
  getPostBankError: "",

  getLayoutOptions: [],
  getLayoutOptionsSuccess: "",
  getLayoutOptionsError: "",

  getFinancialLayout: [],
  getFinancialLayoutSuccess: "",
  getFinancialLayoutError: "",

  saveFinancialLayout: [],
  saveFinancialLayoutSuccess: "",
  saveFinancialLayoutError: "",

  getColumnOptionDetails: [],
  getColumnOptionDetailsSuccess: "",
  getColumnOptionDetailsError: "",

  primeColumnOptionDetails: [],
  primeColumnOptionDetailsSuccess: "",
  primeColumnOptionDetailsError: "",

  addColumnOptionDetails: [],
  addColumnOptionDetailsSuccess: "",
  addColumnOptionDetailsError: "",

  saveColumnOptionDetails: [],
  saveColumnOptionDetailsSuccess: "",
  saveColumnOptionDetailsError: "",

  getLayoutColumns: [],
  getLayoutColumnsSuccess: "",
  getLayoutColumnsError: "",

  primeFinancialLayout: [],
  primeFinancialLayoutSuccess: "",
  primeFinancialLayoutError: "",

  createFinancialLayout: [],
  createFinancialLayoutSuccess: "",
  createFinancialLayoutError: "",

  deleteFinancialLayout: [],
  deleteFinancialLayoutSuccess: "",
  deleteFinancialLayoutError: "",

  getPrimeImportBudget: [],
  getPrimeImportBudgetSuccess: "",
  getPrimeImportBudgetError: "",

  generateFinancials: [],
  generateFinancialsSuccess: "",
  generateFinancialsError: "",

  getFromHyperLink: [],
  getFromHyperLinkSuccess: "",
  getFromHyperLinkError: "",

  updateFromHyperLink: [],
  updateFromHyperLinkSuccess: "",
  updateFromHyperLinkError: "",

  updateFinancials: [],
  updateFinancialsSuccess: "",
  updateFinancialsError: "",

  exportExcel: [],
  exportExcelSuccess: "",
  exportExcelError: "",

  importExcel: [],
  importExcelSuccess: "",
  importExcelError: "",

  primeImportBudget: [],
  primeImportBudgetSuccess: "",
  primeImportBudgetError: "",

  importBudget: [],
  importBudgetSuccess: "",
  importBudgetError: "",

  updateBudgetImport: [],
  updateBudgetImportSuccess: "",
  updateBudgetImportError: "",

  deleteBudgetImport: [],
  deleteBudgetImportSuccess: "",
  deleteBudgetImportError: "",

  userHMRCAuthorized: [],
  userHMRCAuthorizedSuccess: "",
  userHMRCAuthorizedError: "",

  primeHMRCTokenAuthorization: [],
  primeHMRCTokenAuthorizationSuccess: "",
  primeHMRCTokenAuthorizationError: "",

  authenticateHMRCUser: [],
  authenticateHMRCUserSuccess: "",
  authenticateHMRCUserError: "",
};

export default function (state = INIT_STATE, action) {
  switch (action.type) {
    //----------------------****Get Report Types****-----------------------------
    case "Prime_Period_Close_INIT":
      return {
        ...state,
        PrimePeriodClose: [],
        PrimePeriodCloseSuccess: "",
        PrimePeriodCloseError: "",
      };
    case "Prime_Period_Close_SUCCESS":
      return {
        ...state,
        PrimePeriodClose: action.payload.periodClose || [],
        PrimePeriodCloseSuccess: action.payload.result[0].description,
      };
    case "Prime_Period_Close_FAIL":
      return {
        ...state,
        PrimePeriodCloseError: action.payload,
      };

    case "CLOSE_PERIOD_INIT":
      return {
        ...state,
        periodCloseSuccess: "",
        periodCloseError: "",
      };
    case "CLOSE_PERIOD_SUCCESS":
      return {
        ...state,
        periodCloseSuccess: action.payload.result[0].description,
      };
    case "CLOSE_PERIOD_FAIL":
      return {
        ...state,
        periodCloseError: action.payload,
      };

    // --------------- Get Ledgers --------------------//
    case "Get_Ledgers_INIT":
      return {
        ...state,
        getLedgers: [],
        getLedgersSuccess: "",
        getLedgersError: "",
      };
    case "Get_Ledgers_SUCCESS":
      return {
        ...state,
        getLedgers: action.payload.ledgers || [],
        getLedgersSuccess: action.payload.result[0].description,
      };
    case "Get_Ledgers_FAIL":
      return {
        ...state,
        getLedgersError: action.payload,
      };
    // --------------- getExportLedger --------------------//
    case "EXPORT_LEDGER_INIT":
      return {
        ...state,
        getExportLedger: [],
        getExportLedgerSuccess: "",
        getExportLedgerError: "",
      };
    case "EXPORT_LEDGER_SUCCESS":
      return {
        ...state,
        getExportLedger: action.payload.export || [],
        getExportLedgerSuccess: action.payload.result[0].description,
      };
    case "EXPORT_LEDGER__FAIL":
      return {
        ...state,
        getExportLedgerError: action.payload,
      };
    // --------------- Update Ledgers --------------------//
    case "Update_Ledgers_INIT":
      return {
        ...state,
        updateLedgersSuccess: "",
        updateLedgersError: "",
      };
    case "Update_Ledgers_SUCCESS":
      return {
        ...state,
        updateLedgersSuccess: action.payload.result[0].description,
      };
    case "Update_Ledgers_FAIL":
      return {
        ...state,
        updateLedgersError: action.payload,
      };
    // --------------- CREATE_DIST_CHANGE --------------------//
    case "CREATE_DIST_CHANGE_INIT":
      return {
        ...state,
        createDistChangeSuccess: "",
        createDistChangeError: "",
      };
    case "CREATE_DIST_CHANGE_SUCCESS":
      return {
        ...state,
        createDistChangeSuccess: action.payload.result[0].description,
      };
    case "CREATE_DIST_CHANGE_FAIL":
      return {
        ...state,
        createDistChangeError: action.payload,
      };
    // --------------- getReconcile --------------------//
    case "GET_RECONCILE_INIT":
      return {
        ...state,
        getReconcile: [],
        getReconcileSuccess: "",
        getReconcileError: "",
      };
    case "GET_RECONCILE_SUCCESS":
      return {
        ...state,
        getReconcile: action.payload.export || [],
        getReconcileSuccess: action.payload.result[0].description,
      };
    case "GET_RECONCILE__FAIL":
      return {
        ...state,
        getReconcileError: action.payload,
      };

    // --------------- Get Ledger History--------------------//
    case "GET_LEDGER_HISTORY_INIT":
      return {
        ...state,
        getLedgerHistory: [],
        getLedgerHistorySuccess: "",
        getLedgerHistoryError: "",
      };
    case "GET_LEDGER_HISTORY_SUCCESS":
      return {
        ...state,
        getLedgerHistory: action.payload.ledgerHistory || [],
        getLedgerHistorySuccess: action.payload.result[0].description,
      };
    case "GET_LEDGER_HISTORY_FAIL":
      return {
        ...state,
        getLedgerHistoryError: action.payload,
      };

    //----------------------****Get ADVANCED EXCEL EXPORT Accounts****-----------------------------

    case "GET_ADVANCED_EXCEL_EXPORT_INIT":
      return {
        ...state,
        getAdvancedExcelExport: [],
        getAdvancedExcelExportSuccess: "",
        getAdvancedExcelExportError: "",
      };
    case "GET_ADVANCED_EXCEL_EXPORT_SUCCESS":
      return {
        ...state,
        getAdvancedExcelExport: action.payload || [],
        getAdvancedExcelExportSuccess: action.payload.result[0].description,
      };
    case "GET_ADVANCED_EXCEL_EXPORT_FAIL":
      return {
        ...state,
        getAdvancedExcelExportError: action.payload,
      };
    //----------------------****Advanced Excel Export****-----------------------------

    case "ADVANCED_EXCEL_EXPORT_INIT":
      return {
        ...state,
        advancedExcelExport: "",
        advancedExcelExportSuccess: "",
        advancedExcelExportError: "",
      };
    case "ADVANCED_EXCEL_EXPORT_SUCCESS":
      return {
        ...state,
        advancedExcelExport: action.payload || "",
        advancedExcelExportSuccess: action.payload.result[0].description,
      };
    case "ADVANCED_EXCEL_EXPORT_FAIL":
      return {
        ...state,
        advancedExcelExportError: action.payload,
      };

    //----------------------****PrimeImportLedger****-----------------------------

    case "PRIME_IMPORT_LEDGER_INIT":
      return {
        ...state,
        primeImportLedger: [],
        primeImportLedgerSuccess: "",
        primeImportLedgerError: "",
      };
    case "PRIME_IMPORT_LEDGER_SUCCESS":
      return {
        ...state,
        primeImportLedger: action.payload || [],
        primeImportLedgerSuccess: action.payload.result[0].description,
      };
    case "PRIME_IMPORT_LEDGER_FAIL":
      return {
        ...state,
        primeImportLedgerError: action.payload,
      };

    //----------------------****Get Attachments Accounts****-----------------------------
    case "GET_ATTACHMENTS_INIT":
      return {
        ...state,
        getAccountAttachments: [],
        getAccountAttachmentsSuccess: "",
        getAccountAttachmentsError: "",
      };
    case "GET_ATTACHMENTS_SUCCESS":
      return {
        ...state,
        getAccountAttachments: action.payload.attachments || [],
        getAccountAttachmentsSuccess: action.payload.result[0].description,
      };
    case "GET_ATTACHMENTS_FAIL":
      return {
        ...state,
        getAccountAttachmentsError: action.payload,
      };
    //----------------------****Add Attachment Accounts****-----------------------------
    case "ADD_ATTACHMENT_INIT":
      return {
        ...state,
        addAccountAttachment: [],
        addAccountAttachmentSuccess: "",
        addAccountAttachmentError: "",
      };
    case "ADD_ATTACHMENT_SUCCESS":
      return {
        ...state,
        addAccountAttachment: action.payload.attachments || [],
        addAccountAttachmentSuccess: action.payload.result[0].description,
      };
    case "ADD_ATTACHMENT_FAIL":
      return {
        ...state,
        addAccountAttachmentError: action.payload,
      };
    //----------------------****Get Attachment Accounts****-----------------------------
    case "GET_ATTACHMENT_INIT":
      return {
        ...state,
        getAccountAttachment: "",
        getAccountAttachmentSuccess: "",
        getAccountAttachmentError: "",
      };
    case "GET_ATTACHMENT_SUCCESS":
      return {
        ...state,
        getAccountAttachment: action.payload.attachments || [],
        getAccountAttachmentSuccess: action.payload.result[0].description,
      };
    case "GET_ATTACHMENT_FAIL":
      return {
        ...state,
        getAccountAttachmentError: action.payload,
      };
    //----------------------****GET_PRIME_CASHFLOW_EXPORT_INIT****-----------------------------
    case "GET_PRIME_CASHFLOW_INIT":
      return {
        ...state,

        getPrimeCashFlow: [],
        getPrimeCashFlowSuccess: "",
        getPrimeCashFlowError: "",
      };
    case "GET_PRIME_CASHFLOW_SUCCESS":
      return {
        ...state,
        getPrimeCashFlow: action.payload.options || [],
        getPrimeCashFlowSuccess: action.payload.result[0].description,
      };
    case "GET_PRIME_CASHFLOW_FAIL":
      return {
        ...state,
        getPrimeCashFlowError: action.payload,
      };
    //----------------------**** EXPORT CASHFLOW ****-----------------------------
    case "EXPORT_CASHFLOW_INIT":
      return {
        ...state,
        getExportCashFlow: [],
        getExportCashFlowSuccess: "",
        getExportCashFlowError: "",
      };
    case "EXPORT_CASHFLOW_SUCCESS":
      return {
        ...state,
        getExportCashFlow: action.payload.excelData || [],
        getExportCashFlowSuccess: action.payload.result[0].description,
      };
    case "EXPORT_CASHFLOW__FAIL":
      return {
        ...state,
        getExportCashFlowError: action.payload,
      };

    //----------------------**** Import Cash Flow *******----------------------------
    case "IMPORT_CASHFLOW_INIT":
      return {
        ...state,
        getImportCashFlow: [],
        getImportCashFlowSuccess: "",
        getImportCashFlowError: "",
      };
    case "IMPORT_CASHFLOW_SUCCESS":
      return {
        ...state,
        getImportCashFlow: action.payload,
        getImportCashFlowSuccess: action.payload.result[0].description,
      };
    case "IMPORT_CASHFLOW_FAIL":
      return {
        ...state,
        getImportCashFlowError: action.payload,
      };
    //----------------------**** Get Prime Vat Submission *******----------------------------
    case "GET_PRIME_VAT_SUBMISSION_INIT":
      return {
        ...state,
        getPrimeVatSubmission: [],
        getPrimeVatSubmissionSuccess: "",
        getPrimeVatSubmissionError: "",
      };
    case "GET_PRIME_VAT_SUBMISSION_SUCCESS":
      return {
        ...state,
        getPrimeVatSubmission: action.payload.vatSubmission || [],
        getPrimeVatSubmissionSuccess: action.payload.result[0].description,
      };
    case "GET_PRIME_VAT_SUBMISSION_FAIL":
      return {
        ...state,
        getPrimeVatSubmissionError: action.payload,
      };
    //----------------------**** Calculate Vat Submission *******----------------------------
    case "CALCULATE_VAT_SUBMISSION_INIT":
      return {
        ...state,
        calculateVatSubmission: [],
        calculateVatSubmissionSuccess: "",
        calculateVatSubmissionError: "",
      };
    case "CALCULATE_VAT_SUBMISSION_SUCCESS":
      return {
        ...state,
        calculateVatSubmission: action.payload.vatSubmission || [],
        calculateVatSubmissionSuccess: action.payload.result[0].description,
      };
    case "CALCULATE_VAT_SUBMISSION_FAIL":
      return {
        ...state,
        calculateVatSubmissionError: action.payload,
      };

    //----------------------****    Bank Rec Reducers  ****-----------------

    //----------------------****    GetBankRecSummary ****------------------
    case "GET_BANK_REC_SUMMARY_INIT":
      return {
        ...state,
        getBankRecSummary: [],
        getBankRecSummarySuccess: "",
        getBankRecSummaryError: "",
      };
    case "GET_BANK_REC_SUMMARY_SUCCESS":
      return {
        ...state,
        getBankRecSummary: action?.payload.bankRecSummary || [],
        getBankRecSummarySuccess: action.payload.result[0].description,
      };
    case "GET_BANK_REC_SUMMARY_FAIL":
      return {
        ...state,
        getBankRecSummaryError: action.payload,
      };
    //----------------------****    UpdateStatment ****------------------
    case "GET_UPDATE_STATEMENT_INIT":
      return {
        ...state,
        getUpdateStatement: [],
        getUpdateStatementSuccess: "",
        getUpdateStatementError: "",
      };
    case "GET_UPDATE_STATEMENT_SUCCESS":
      return {
        ...state,
        getUpdateStatement: action.payload.bankRecSummary || [],
        getUpdateStatementSuccess: action.payload.result[0].description,
      };
    case "GET_UPDATE_STATEMENT_FAIL":
      return {
        ...state,
        getUpdateStatementError: action.payload,
      };
    //----------------------****    getBankRecAttachments ****------------------
    case "GET_BANK_REC_ATTACHMENTS_INIT":
      return {
        ...state,
        getBankRecAttachments: [],
        getBankRecAttachmentsSuccess: "",
        getBankRecAttachmentsError: "",
      };
    case "GET_BANK_REC_ATTACHMENTS_SUCCESS":
      return {
        ...state,
        getBankRecAttachments: action.payload.attachments || [],
        getBankRecAttachmentsSuccess: action.payload.result[0].description,
      };
    case "GET_BANK_REC_ATTACHMENTS_FAIL":
      return {
        ...state,
        getBankRecAttachmentsError: action.payload,
      };
    //----------------------****    getBankRecAttachment ****------------------
    case "GET_BANK_REC_ATTACHMENT_INIT":
      return {
        ...state,
        getBankRecAttachment: [],
        getBankRecAttachmentSuccess: "",
        getBankRecAttachmentsError: "",
      };
    case "GET_BANK_REC_ATTACHMENT_SUCCESS":
      return {
        ...state,
        getBankRecAttachment: action.payload || [],
        getBankRecAttachmentSuccess: action.payload.result[0].description,
      };
    case "GET_BANK_REC_ATTACHMENT_FAIL":
      return {
        ...state,
        getBankRecAttachmentError: action.payload,
      };
    //----------------------****    addBankRecAttachment ****------------------
    case "ADD_BANK_REC_ATTACHMENT_INIT":
      return {
        ...state,
        addBankRecAttachment: [],
        addBankRecAttachmentSuccess: "",
        addBankRecAttachmentError: "",
      };
    case "ADD_BANK_REC_ATTACHMENT_SUCCESS":
      return {
        ...state,
        addBankRecAttachment: action.payload.attachments || [],
        addBankRecAttachmentSuccess: action.payload.result[0].description,
      };
    case "ADD_BANK_REC_ATTACHMENT_FAIL":
      return {
        ...state,
        addBankRecAttachmentError: action.payload,
      };
    //----------------------****    deleteBankRecAttachment ****------------------
    case "DELETE_BANK_REC_ATTACHMENT_INIT":
      return {
        ...state,
        deleteBankRecAttachment: [],
        deleteBankRecAttachmentSuccess: "",
        deleteBankRecAttachmentError: "",
      };
    case "DELETE_BANK_REC_ATTACHMENT_SUCCESS":
      return {
        ...state,
        deleteBankRecAttachment: action.payload || [],
        deleteBankRecAttachmentSuccess: action.payload.result[0].description,
      };
    case "DELETE_BANK_REC_ATTACHMENT_FAIL":
      return {
        ...state,
        deleteBankRecAttachmentError: action.payload,
      };
    //----------------------****    getUpdateStatus ****------------------
    case "GET_UPDATE_STATUS_INIT":
      return {
        ...state,
        getUpdateStatus: [],
        getUpdateStatusSuccess: "",
        getUpdateStatusError: "",
      };
    case "GET_UPDATE_STATUS_SUCCESS":
      return {
        ...state,
        getUpdateStatus: action.payload || [],
        getUpdateStatusSuccess: action.payload.result[0].description,
      };
    case "GET_UPDATE_STATUS_FAIL":
      return {
        ...state,
        getUpdateStatusError: action.payload,
      };
    //----------------------****    getCheques ****------------------
    case "GET_CHEQUES_INIT":
      return {
        ...state,
        getCheques: [],
        getChequesSuccess: "",
        getChequesError: "",
      };
    case "GET_CHEQUES_SUCCESS":
      return {
        ...state,
        getCheques: action.payload.cheques || [],
        getChequesSuccess: action.payload.result[0].description,
      };
    case "GET_CHEQUES_FAIL":
      return {
        ...state,
        getChequesError: action.payload,
      };
    //----------------------****    getUpdateCheques ****------------------
    case "GET_UPDATE_CHEQUES_INIT":
      return {
        ...state,
        getUpdateCheques: [],
        getUpdateChequesSuccess: "",
        getUpdateChequesError: "",
      };
    case "GET_UPDATE_CHEQUES_SUCCESS":
      return {
        ...state,
        getUpdateCheques: action.payload || [],
        getUpdateChequesSuccess: action.payload.result[0].description,
      };
    case "GET_UPDATE_CHEQUES_FAIL":
      return {
        ...state,
        getUpdateChequesError: action.payload,
      };
    //----------------------****    getPrimeCancelReIssue ****------------------
    case "GET_PRIME_CANCEL_REISSUE_INIT":
      return {
        ...state,
        getPrimeCancelReIssue: [],
        getPrimeCancelReIssueSuccess: "",
        getPrimeCancelReIssueError: "",
      };
    case "GET_PRIME_CANCEL_REISSUE_SUCCESS":
      return {
        ...state,
        getPrimeCancelReIssue: action.payload.cancelReIssue || [],
        getPrimeCancelReIssueSuccess: action.payload.result[0].description,
      };
    case "GET_PRIME_CANCEL_REISSUE_FAIL":
      return {
        ...state,
        getPrimeCancelReIssueError: action.payload,
      };
    //----------------------****    getCancelReIssueCheque ****------------------
    case "GET_CANCEL_REISSUE_CHEQUE_INIT":
      return {
        ...state,
        getCancelReIssueCheque: [],
        getCancelReIssueChequeSuccess: "",
        getCancelReIssueChequeError: "",
      };
    case "GET_CANCEL_REISSUE_CHEQUE_SUCCESS":
      return {
        ...state,
        getCancelReIssueCheque: action.payload || [],
        getCancelReIssueChequeSuccess: action.payload.result,
      };
    case "GET_CANCEL_REISSUE_CHEQUE_FAIL":
      return {
        ...state,
        getCancelReIssueChequeError: action.payload,
      };
    //----------------------****    getJournals ****------------------
    case "GET_JOURNALS_INIT":
      return {
        ...state,
        getJournals: [],
        getJournalsSuccess: "",
        getJournalsChequeError: "",
      };
    case "GET_JOURNALS_SUCCESS":
      return {
        ...state,
        getJournals: action.payload.journals || [],
        getJournalsSuccess: action.payload.result[0].description,
      };
    case "GET_JOURNALS_FAIL":
      return {
        ...state,
        getJournalsError: action.payload,
      };
    //----------------------****    updateJournals ****------------------
    case "UPDATE_JOURNALS_INIT":
      return {
        ...state,
        updateJournals: [],
        updateJournalsSuccess: "",
        updateJournalsError: "",
      };
    case "UPDATE_JOURNALS_SUCCESS":
      return {
        ...state,
        updateJournals: action.payload || [],
        updateJournalsSuccess: action.payload.result[0].description,
      };
    case "UPDATE_JOURNALS_FAIL":
      return {
        ...state,
        updateJournalsError: action.payload,
      };
    //----------------------****    getAdjustments ****------------------
    case "GET_ADJUSTMENTS_INIT":
      return {
        ...state,
        getAdjustments: [],
        getAdjustmentsSuccess: "",
        getAdjustmentsError: "",
      };
    case "GET_ADJUSTMENTS_SUCCESS":
      return {
        ...state,
        getAdjustments: action.payload.adjustments || [],
        getAdjustmentsSuccess: action.payload.result[0].description,
      };
    case "GET_ADJUSTMENTS_FAIL":
      return {
        ...state,
        getAdjustmentsError: action.payload,
      };
    //----------------------****    updateAdjustments ****------------------
    case "UPDATE_ADJUSTMENTS_INIT":
      return {
        ...state,
        updateAdjustments: [],
        updateAdjustmentsSuccess: "",
        updateAdjustmentsError: "",
      };
    case "UPDATE_ADJUSTMENTS_SUCCESS":
      return {
        ...state,
        updateAdjustments: action.payload || [],
        updateAdjustmentsSuccess: action.payload.result[0].description,
      };
    case "UPDATE_ADJUSTMENTS_FAIL":
      return {
        ...state,
        updateAdjustmentsError: action.payload,
      };
    //----------------------****    deleteAdjustments ****------------------
    case "DELETE_ADJUSTMENTS_INIT":
      return {
        ...state,
        deleteAdjustments: [],
        deleteAdjustmentsSuccess: "",
        deleteAdjustmentsError: "",
      };
    case "DELETE_ADJUSTMENTS_SUCCESS":
      return {
        ...state,
        deleteAdjustments: action.payload || [],
        deleteAdjustmentsSuccess: action.payload.result[0].description,
      };
    case "DELETE_ADJUSTMENTS_FAIL":
      return {
        ...state,
        deleteAdjustmentsError: action.payload,
      };
    //----------------------****    getBankTransactions ****------------------
    case "GET_BANK_TRANSACTION_INIT":
      return {
        ...state,
        getBankTransactions: [],
        getBankTransactionsSuccess: "",
        getBankTransactionsError: "",
      };
    case "GET_BANK_TRANSACTION_SUCCESS":
      return {
        ...state,
        getBankTransactions: action.payload || [],
        getBankTransactionsSuccess: action.payload.result[0].description,
      };
    case "GET_BANK_TRANSACTION_FAIL":
      return {
        ...state,
        getBankTransactionsError: action.payload,
      };
    //----------------------****    updateBankTransactions ****------------------
    case "UPDATE_BANK_TRANSACTIONS_INIT":
      return {
        ...state,
        updateBankTransactions: [],
        updateBankTransactionsSuccess: "",
        updateBankTransactionsError: "",
      };
    case "UPDATE_BANK_TRANSACTIONS_SUCCESS":
      return {
        ...state,
        updateBankTransactions: action.payload || [],
        updateBankTransactionsSuccess: action.payload.result[0].description,
      };
    case "UPDATE_BANK_TRANSACTIONS_FAIL":
      return {
        ...state,
        updateBankTransactionsError: action.payload,
      };
    //----------------------****    deleteBankTransactions ****------------------
    case "DELETE_BANK_TRANSACTIONS_INIT":
      return {
        ...state,
        deleteBankTransactions: [],
        deleteBankTransactionsSuccess: "",
        deleteBankTransactionsError: "",
      };
    case "DELETE_BANK_TRANSACTIONS_SUCCESS":
      return {
        ...state,
        deleteBankTransactions: action.payload || [],
        deleteBankTransactionsSuccess: action.payload.result[0].description,
      };
    case "DELETE_BANK_TRANSACTIONS_FAIL":
      return {
        ...state,
        deleteBankTransactionsError: action.payload,
      };
    //----------------------****    getExportExcel ****------------------
    case "GET_EXPORT_EXCEL_INIT":
      return {
        ...state,
        getExportExcel: [],
        getExportExcelSuccess: "",
        getExportExcelError: "",
      };
    case "GET_EXPORT_EXCEL_SUCCESS":
      return {
        ...state,
        getExportExcel: action.payload.excel || [],
        getExportExcelSuccess: action.payload.result[0].description,
      };
    case "GET_EXPORT_EXCEL_FAIL":
      return {
        ...state,
        getExportExcelError: action.payload,
      };
    //----------------------****    getImportExcel ****------------------
    case "GET_IMPORT_EXCEL_INIT":
      return {
        ...state,
        getImportExcel: [],
        getImportExcelSuccess: "",
        getImportExcelError: "",
      };
    case "GET_IMPORT_EXCEL_SUCCESS":
      return {
        ...state,
        getImportExcel: action.payload || [],
        getImportExcelSuccess: action.payload.result[0].description,
      };
    case "GET_IMPORT_EXCEL_FAIL":
      return {
        ...state,
        getImportExcelError: action.payload,
      };
    //----------------------****    getPrimeCancelReIssue ****------------------
    case "GET_PRIME_CANCEL_RE_ISSUE_INIT":
      return {
        ...state,
        getPrimeCancelReIssue: [],
        getPrimeCancelReIssueSuccess: "",
        getPrimeCancelReIssueError: "",
      };
    case "GET_PRIME_CANCEL_RE_ISSUE_SUCCESS":
      return {
        ...state,
        getPrimeCancelReIssue: action.payload.cancelReIssue || [],
        getPrimeCancelReIssueSuccess: action.payload.result[0].description,
      };
    case "GET_PRIME_CANCEL_RE_ISSUE_FAIL":
      return {
        ...state,
        getPrimeCancelReIssueError: action.payload,
      };
    //----------------------****    Prime Create Journal ****------------------
    case "GET_PRIME_CREATE_JOURNAL_INIT":
      return {
        ...state,
        getPrimeCreateJournal: [],
        getPrimeCreateJournalSuccess: "",
        pgetPrimeCreateJournalError: "",
      };
    case "GET_PRIME_CREATE_JOURNAL_SUCCESS":
      return {
        ...state,
        getPrimeCreateJournal: action.payload.options || [],
        getPrimeCreateJournalSuccess: action.payload.result[0].description,
      };
    case "GET_PRIME_CREATE_JOURNAL_FAIL":
      return {
        ...state,
        pgetPrimeCreateJournalError: action.payload,
      };

    //----------------------****    Create Journal ****------------------
    case "CREATE_JOURNAL_INIT":
      return {
        ...state,
        createJournal: [],
        createJournalSuccess: "",
        createJournalError: "",
      };
    case "CREATE_JOURNAL_SUCCESS":
      return {
        ...state,
        createJournal: action.payload || [],
        createJournalSuccess: action.payload.result[0].description,
      };
    case "CREATE_JOURNAL_FAIL":
      return {
        ...state,
        createJournalError: action.payload,
      };
    //----------------------****    Reconcile Bank ****------------------
    case "GET_RECONCILE_BANK_INIT":
      return {
        ...state,
        getReconcileBank: [],
        getReconcileBankSuccess: "",
        getReconcileBankError: "",
      };
    case "GET_RECONCILE_BANK_SUCCESS":
      return {
        ...state,
        getReconcileBank: action.payload || [],
        getReconcileBankSuccess: action.payload.result[0].description,
      };
    case "GET_RECONCILE_BANK_FAIL":
      return {
        ...state,
        getReconcileBankError: action.payload,
      };
    //----------------------****   Reconcile Adjustments ****------------------
    case "GET_RECONCILE_ADJUSTMENTS_INIT":
      return {
        ...state,
        getReconcileAdjustments: [],
        getReconcileAdjustmentsSuccess: "",
        getReconcileAdjustmentsError: "",
      };
    case "GET_RECONCILE_ADJUSTMENTS_SUCCESS":
      return {
        ...state,
        getReconcileAdjustments: action.payload || [],
        getReconcileAdjustmentsSuccess: action.payload.result[0].description,
      };
    case "GET_RECONCILE_ADJUSTMENTS_FAIL":
      return {
        ...state,
        getReconcileAdjustmentsError: action.payload,
      };
    //----------------------****  get Post Bank****------------------
    case "GET_POST_BANK_INIT":
      return {
        ...state,
        getPostBank: [],
        getPostBankSuccess: "",
        getPostBankError: "",
      };
    case "GET_POST_BANK_SUCCESS":
      return {
        ...state,
        getPostBank: action.payload || [],
        getPostBankSuccess: action.payload.result[1].description,
      };
    case "GET_POST_BANK_FAIL":
      return {
        ...state,
        getPostBankError: action.payload,
      };
    //----------------------**** Reducers of Financial Reports WorkSheet ****------------------

    //----------------------****          getLayoutOptions               ****------------------
    case "GET_LAYOUT_OPTIONS_INIT":
      return {
        ...state,
        getLayoutOptions: [],
        getLayoutOptionsSuccess: "",
        getLayoutOptionsError: "",
      };
    case "GET_LAYOUT_OPTIONS_SUCCESS":
      return {
        ...state,
        getLayoutOptions: action.payload.layoutOptions || [],
        getLayoutOptionsSuccess: action.payload.result[0].description,
      };
    case "GET_LAYOUT_OPTIONS_FAIL":
      return {
        ...state,
        getLayoutOptionsError: action.payload,
      };
    //----------------------**** getFinancialLayout *****----------------------
    case "GET_FINANCIAL_LAYOUT_INIT":
      return {
        ...state,
        getFinancialLayout: [],
        getFinancialLayoutSuccess: "",
        getFinancialLayoutError: "",
      };
    case "GET_FINANCIAL_LAYOUT_SUCCESS":
      return {
        ...state,
        getFinancialLayout: action.payload.layout || [],
        getFinancialLayoutSuccess: action.payload.result[0].description,
      };
    case "GET_FINANCIAL_LAYOUT_FAIL":
      return {
        ...state,
        getFinancialLayoutError: action.payload,
      };
    //----------------------**** saveFinancialLayout *****----------------------
    case "SAVE_FINANCIAL_LAYOUT_INIT":
      return {
        ...state,
        saveFinancialLayout: [],
        saveFinancialLayoutSuccess: "",
        saveFinancialLayoutError: "",
      };
    case "SAVE_FINANCIAL_LAYOUT_SUCCESS":
      return {
        ...state,
        saveFinancialLayout: action.payload || [],
        saveFinancialLayoutSuccess: action.payload.result[0].description,
      };
    case "SAVE_FINANCIAL_LAYOUT_FAIL":
      return {
        ...state,
        saveFinancialLayoutError: action.payload,
      };
    //----------------------**** getColumnOptionDetails *****----------------------
    case "GET_COLUMN_OPTION_DETAILS_INIT":
      return {
        ...state,
        getColumnOptionDetails: [],
        getColumnOptionDetailsSuccess: "",
        getColumnOptionDetailsError: "",
      };
    case "GET_COLUMN_OPTION_DETAILS_SUCCESS":
      return {
        ...state,
        getColumnOptionDetails: action.payload.columnDetails || [],
        getColumnOptionDetailsSuccess: action.payload.result[0].description,
      };
    case "GET_COLUMN_OPTION_DETAILS_FAIL":
      return {
        ...state,
        getColumnOptionDetailsError: action.payload,
      };
    //----------------------**** primeColumnOptionDetails *****----------------------
    case "PRIME_COLUMN_OPTION_DETAILS_INIT":
      return {
        ...state,
        primeColumnOptionDetails: [],
        primeColumnOptionDetailsSuccess: "",
        primeColumnOptionDetailsError: "",
      };
    case "PRIME_COLUMN_OPTION_DETAILS_SUCCESS":
      return {
        ...state,
        primeColumnOptionDetails: action.payload.columnDetails || [],
        primeColumnOptionDetailsSuccess: action.payload.result[0].description,
      };
    case "PRIME_COLUMN_OPTION_DETAILS_FAIL":
      return {
        ...state,
        primeColumnOptionDetailsError: action.payload,
      };
    //----------------------**** addColumnOptionDetails *****----------------------
    case "ADD_COLUMN_OPTION_DETAILS_INIT":
      return {
        ...state,
        addColumnOptionDetails: [],
        addColumnOptionDetailsSuccess: "",
        addColumnOptionDetailsError: "",
      };
    case "ADD_COLUMN_OPTION_DETAILS_SUCCESS":
      return {
        ...state,
        addColumnOptionDetails: action.payload.columns || [],
        addColumnOptionDetailsSuccess: action.payload.result[0].description,
      };
    case "ADD_COLUMN_OPTION_DETAILS_FAIL":
      return {
        ...state,
        addColumnOptionDetailsError: action.payload,
      };
    //----------------------**** saveColumnOptionDetails *****----------------------
    case "SAVE_COLUMN_OPTION_DETAILS_INIT":
      return {
        ...state,
        saveColumnOptionDetails: [],
        saveColumnOptionDetailsSuccess: "",
        saveColumnOptionDetailsError: "",
      };
    case "SAVE_COLUMN_OPTION_DETAILS_SUCCESS":
      return {
        ...state,
        saveColumnOptionDetails: action.payload.columns || [],
        saveColumnOptionDetailsSuccess: action.payload.result[0].description,
      };
    case "SAVE_COLUMN_OPTION_DETAILS_FAIL":
      return {
        ...state,
        saveColumnOptionDetailsError: action.payload,
      };
    //----------------------**** getLayoutColumns *****----------------------
    case "GET_LAYOUT_COLUMNS_INIT":
      return {
        ...state,
        getLayoutColumns: [],
        getLayoutColumnsSuccess: "",
        getLayoutColumnsError: "",
      };
    case "GET_LAYOUT_COLUMNS_SUCCESS":
      return {
        ...state,
        getLayoutColumns: action.payload || [],
        getLayoutColumnsSuccess: action.payload.result[0].description,
      };
    case "GET_LAYOUT_COLUMNS_FAIL":
      return {
        ...state,
        getLayoutColumnsError: action.payload,
      };
    //----------------------**** primeFinancialLayout *****----------------------
    case "PRIME_FINANCIAL_LAYOUT_INIT":
      return {
        ...state,
        primeFinancialLayout: [],
        primeFinancialLayoutSuccess: "",
        primeFinancialLayoutError: "",
      };
    case "PRIME_FINANCIAL_LAYOUT_SUCCESS":
      return {
        ...state,
        primeFinancialLayout: action.payload || [],
        primeFinancialLayoutSuccess: action.payload.result[0].description,
      };
    case "PRIME_FINANCIAL_LAYOUT_FAIL":
      return {
        ...state,
        primeFinancialLayoutError: action.payload,
      };
    //----------------------**** createFinancialLayout *****----------------------
    case "CREATE_FINANCIAL_LAYOUT_INIT":
      return {
        ...state,
        createFinancialLayout: [],
        createFinancialLayoutSuccess: "",
        createFinancialLayoutError: "",
      };
    case "CREATE_FINANCIAL_LAYOUT_SUCCESS":
      return {
        ...state,
        createFinancialLayout: action.payload || [],
        createFinancialLayoutSuccess: action.payload.result[0].description,
      };
    case "CREATE_FINANCIAL_LAYOUT_FAIL":
      return {
        ...state,
        createFinancialLayoutError: action.payload,
      };
    //----------------------**** deleteFinancialLayout *****----------------------
    case "DELETE_FINANCIAL_LAYOUT_INIT":
      return {
        ...state,
        deleteFinancialLayout: [],
        deleteFinancialLayoutSuccess: "",
        deleteFinancialLayoutError: "",
      };
    case "DELETE_FINANCIAL_LAYOUT_SUCCESS":
      return {
        ...state,
        deleteFinancialLayout: action.payload || [],
        deleteFinancialLayoutSuccess: action.payload.result[0].description,
      };
    case "DELETE_FINANCIAL_LAYOUT_FAIL":
      return {
        ...state,
        deleteFinancialLayoutError: action.payload,
      };
    //----------------------**** getPrimeImportBudget *****----------------------
    case "GET_PRIME_IMPORT_BUDGET_INIT":
      return {
        ...state,
        getPrimeImportBudget: [],
        getPrimeImportBudgetSuccess: "",
        getPrimeImportBudgetError: "",
      };
    case "GET_PRIME_IMPORT_BUDGET_SUCCESS":
      return {
        ...state,
        getPrimeImportBudget: action.payload || [],
        getPrimeImportBudgetSuccess: action.payload.result[0].description,
      };
    case "GET_PRIME_IMPORT_BUDGET_FAIL":
      return {
        ...state,
        getPrimeImportBudgetError: action.payload,
      };
    //----------------------**** generateFinancials *****----------------------
    case "GENERATE_FINANCIALS_INIT":
      return {
        ...state,
        generateFinancials: [],
        generateFinancialsSuccess: "",
        generateFinancialsError: "",
      };
    case "GENERATE_FINANCIALS_SUCCESS":
      return {
        ...state,
        generateFinancials: action.payload || [],
        generateFinancialsSuccess: action.payload.result[0].description,
      };
    case "GENERATE_FINANCIALS_FAIL":
      return {
        ...state,
        generateFinancialsError: action.payload,
      };
    //----------------------**** getFromHyperLink *****----------------------
    case "GET_FROM_HYPER_LINK_INIT":
      return {
        ...state,
        getFromHyperLink: [],
        getFromHyperLinkSuccess: "",
        getFromHyperLinkError: "",
      };
    case "GET_FROM_HYPER_LINK_SUCCESS":
      return {
        ...state,
        getFromHyperLink: action.payload.table || [],
        getFromHyperLinkSuccess: action.payload.result[0].description,
      };
    case "GET_FROM_HYPER_LINK_FAIL":
      return {
        ...state,
        getFromHyperLinkError: action.payload,
      };
    //----------------------**** updateFromHyperLink *****----------------------
    case "UPDATE_FROM_HYPER_LINK_INIT":
      return {
        ...state,
        updateFromHyperLink: [],
        updateFromHyperLinkSuccess: "",
        updateFromHyperLinkError: "",
      };
    case "UPDATE_FROM_HYPER_LINK_SUCCESS":
      return {
        ...state,

        updateFromHyperLink: action.payload || [],
        updateFromHyperLinkSuccess: action.payload.result[0].description,
      };
    case "UPDATE_FROM_HYPER_LINK_FAIL":
      return {
        ...state,
        updateFromHyperLinkError: action.payload,
      };
    //----------------------**** updateFinancials *****----------------------
    case "UPDATE_FINANCIALS_INIT":
      return {
        ...state,
        updateFinancials: [],
        updateFinancialsSuccess: "",
        updateFinancialsError: "",
      };
    case "UPDATE_FINANCIALS_SUCCESS":
      return {
        ...state,

        updateFinancials: action.payload || [],
        updateFinancialsSuccess: action.payload.result[0].description,
      };
    case "UPDATE_FINANCIALS_FAIL":
      return {
        ...state,
        updateFinancialsError: action.payload,
      };
    //----------------------**** exportExcel *****----------------------
    case "EXPORT_EXCEL_INIT":
      return {
        ...state,
        exportExcel: [],
        exportExcelSuccess: "",
        exportExcelError: "",
      };
    case "EXPORT_EXCEL_SUCCESS":
      return {
        ...state,

        exportExcel: action.payload || [],
        exportExcelSuccess: action.payload.result[0].description,
      };
    case "EXPORT_EXCEL_FAIL":
      return {
        ...state,
        exportExcelError: action.payload,
      };
    //----------------------**** importExcel *****----------------------
    case "IMPORT_EXCEL_INIT":
      return {
        ...state,
        importExcel: [],
        importExcelSuccess: "",
        importExcelError: "",
      };
    case "IMPORT_EXCEL_SUCCESS":
      return {
        ...state,

        importExcel: action.payload || [],
        importExcelSuccess: action.payload.result[0].description,
      };
    case "IMPORT_EXCEL_FAIL":
      return {
        ...state,
        importExcelError: action.payload,
      };
    //----------------------**** primeImportBudget *****----------------------
    case "PRIME_IMPORT_BUDGET_INIT":
      return {
        ...state,

        primeImportBudget: [],
        primeImportBudgetSuccess: "",
        primeImportBudgetError: "",
      };
    case "PRIME_IMPORT_BUDGET_SUCCESS":
      return {
        ...state,

        primeImportBudget: action.payload || [],
        primeImportBudgetSuccess: action.payload.result[0].description,
      };
    case "PRIME_IMPORT_BUDGET_FAIL":
      return {
        ...state,
        primeImportBudgetError: action.payload,
      };
    //----------------------**** importBudget *****----------------------
    case "IMPORT_BUDGET_INIT":
      return {
        ...state,
        importBudget: [],
        importBudgetSuccess: "",
        importBudgetError: "",
      };
    case "IMPORT_BUDGET_SUCCESS":
      return {
        ...state,

        importBudget: action.payload || [],
        importBudgetSuccess: action.payload.result[0].description,
      };
    case "IMPORT_BUDGET_FAIL":
      return {
        ...state,
        importBudgetError: action.payload,
      };
    //----------------------**** updateBudgetImport *****----------------------
    case "UPDATE_BUDGET_IMPORT_INIT":
      return {
        ...state,
        updateBudgetImport: [],
        updateBudgetImportSuccess: "",
        updateBudgetImportError: "",
      };
    case "UPDATE_BUDGET_IMPORT_SUCCESS":
      return {
        ...state,

        updateBudgetImport: action.payload || [],
        updateBudgetImportSuccess: action.payload.result[0].description,
      };
    case "UPDATE_BUDGET_IMPORT_FAIL":
      return {
        ...state,
        updateBudgetImportError: action.payload,
      };
    //----------------------**** deleteBudgetImport *****----------------------
    case "DELETE_BUDGET_IMPORT_INIT":
      return {
        ...state,
        deleteBudgetImport: [],
        deleteBudgetImportSuccess: "",
        deleteBudgetImportError: "",
      };
    case "DELETE_BUDGET_IMPORT_SUCCESS":
      return {
        ...state,

        deleteBudgetImport: action.payload || [],
        deleteBudgetImportSuccess: action.payload.result[0].description,
      };
    case "DELETE_BUDGET_IMPORT_FAIL":
      return {
        ...state,
        deleteBudgetImportError: action.payload,
      };
    //----------------------**** saveAndGenerateReport *****----------------------
    case "SAVE_AND_GENERATE_REPORT_INIT":
      return {
        ...state,
        saveAndGenerateReport: [],
        saveAndGenerateReportSuccess: "",
        saveAndGenerateReportError: "",
      };
    case "SAVE_AND_GENERATE_REPORT_SUCCESS":
      return {
        ...state,

        saveAndGenerateReport: action.payload || [],
        saveAndGenerateReportSuccess: action.payload.result[0].description,
      };
    case "SAVE_AND_GENERATE_REPORT_FAIL":
    //----------------------**** userHMRCAuthorized *****----------------------
    case "USER_HMRC_AUTHORIZED_INIT":
      return {
        ...state,
        userHMRCAuthorized: [],
        userHMRCAuthorizedSuccess: "",
        userHMRCAuthorizedError: "",
      };
    case "USER_HMRC_AUTHORIZED_SUCCESS":
      return {
        ...state,
        userHMRCAuthorized: action.payload || [],
        userHMRCAuthorizedSuccess: action.payload.result[0].description,
      };
    case "USER_HMRC_AUTHORIZED_FAIL":
    //----------------------**** primeHMRCTokenAuthorization *****----------------------
    case "PRIME_HMRC_TOKEN_AUTHORIZATION_INIT":
      return {
        ...state,
        primeHMRCTokenAuthorization: [],
        primeHMRCTokenAuthorizationSuccess: "",
        primeHMRCTokenAuthorizationError: "",
      };
    case "PRIME_HMRC_TOKEN_AUTHORIZATION_SUCCESS":
      return {
        ...state,
        primeHMRCTokenAuthorization: action.payload || [],
        primeHMRCTokenAuthorizationSuccess:
          action.payload.result[0].description,
      };
    case "PRIME_HMRC_TOKEN_AUTHORIZATION_FAIL":
    //----------------------**** authenticateHMRCUser *****----------------------
    case "AUTHENTICATE_HMRC_USER_INIT":
      return {
        ...state,
        authenticateHMRCUser: [],
        authenticateHMRCUserSuccess: "",
        authenticateHMRCUserError: "",
      };
    case "AUTHENTICATE_HMRC_USER_SUCCESS":
      return {
        ...state,
        authenticateHMRCUser: action.payload || [],
        authenticateHMRCUserSuccess: action.payload.result[0].description,
      };
    case "AUTHENTICATE_HMRC_USER_FAIL":
      return {
        ...state,
        authenticateHMRCUserError: action.payload,
      };

    //----------------------****Clear States When Producton Login****--------------------
    case "CLEAR_STATES_WHEN_LOGIN_PRODUCTION":
      //when user login production on dashboard then remove data of previous Production
      return {
        ...state,
        getReportTypes: [],
        getReportTypesSuccess: "",
        getReportTypesError: "",

        getReportOptions: [],
        getReportOptionsSuccess: "",
        getReportOptionsError: "",

        getReportSettings: [],
        getReportSettingsSuccess: "",
        getReportSettingsError: "",

        getReportData: "",
        getReportDataSuccess: "",
        getReportDataError: "",

        deleteReport: [],
        deleteReportSuccess: "",
        deleteReportError: "",

        primePost: "",
        primePostSuccess: "",
        primePostError: "",

        userHMRCAuthorized: [],
        userHMRCAuthorizedSuccess: "",
        userHMRCAuthorizedError: "",

        primeHMRCTokenAuthorization: [],
        primeHMRCTokenAuthorizationSuccess: "",
        primeHMRCTokenAuthorizationError: "",

        authenticateHMRCUser: [],
        authenticateHMRCUserSuccess: "",
        authenticateHMRCUserError: "",
      };
    //----------------------****Clear States After Logout****-----------------------------
    case "CLEAR_STATES_AFTER_LOGOUT":
      return {
        ...state,
        getReportTypes: [],
        getReportTypesSuccess: "",
        getReportTypesError: "",

        getReportOptions: [],
        getReportOptionsSuccess: "",
        getReportOptionsError: "",

        getReportSettings: [],
        getReportSettingsSuccess: "",
        getReportSettingsError: "",

        getReportData: "",
        getReportDataSuccess: "",
        getReportDataError: "",

        deleteReport: [],
        deleteReportSuccess: "",
        deleteReportError: "",

        primePost: "",
        primePostSuccess: "",
        primePostError: "",

        advancedExcelExport: [],
        advancedExcelExportSuccess: "",
        advancedExcelExportError: "",

        userHMRCAuthorized: [],
        userHMRCAuthorizedSuccess: "",
        userHMRCAuthorizedError: "",

        primeHMRCTokenAuthorization: [],
        primeHMRCTokenAuthorizationSuccess: "",
        primeHMRCTokenAuthorizationError: "",

        authenticateHMRCUser: [],
        authenticateHMRCUserSuccess: "",
        authenticateHMRCUserError: "",
      };
    //----------------------****Clear Report States****-----------------------------
    case "CLEAR_REPORTS_STATES":
      return {
        ...state,
        getReportTypesSuccess: "",
        getReportTypesError: "",

        getReportOptionsSuccess: "",
        getReportOptionsError: "",

        getReportSettingsSuccess: "",
        getReportSettingsError: "",

        getReportDataSuccess: "",
        getReportDataError: "",

        deleteReportSuccess: "",
        deleteReportError: "",

        primePostSuccess: "",
        primePostError: "",

        userHMRCAuthorized: [],
        userHMRCAuthorizedSuccess: "",
        userHMRCAuthorizedError: "",

        primeHMRCTokenAuthorization: [],
        primeHMRCTokenAuthorizationSuccess: "",
        primeHMRCTokenAuthorizationError: "",

        authenticateHMRCUser: [],
        authenticateHMRCUserSuccess: "",
        authenticateHMRCUserError: "",
      };
    default:
      return state;
  }
}
