import axiosDPFAPIClient from "../../Utils/axiosDPFAPIClient";

// ---------------------****Reports Actions****----------------------

//----------------------****Get Report Types****----------------------
export const PrimePeriodClose = () => async (dispatch) => {
  dispatch({
    type: "Prime_Period_Close_INIT",
  });
  const url = "/DPFAPI/PeriodCloseRequest?actionType=PrimePeriodClose";

  try {
    let data = await axiosDPFAPIClient.get(url);
    let resp =
      (data && data.data && data.data.PeriodCloseRequest_response) || "";
    if (resp && resp.result.length > 0) {
      if (resp.result[0] && resp.result[0].status === "Success") {
        dispatch({
          type: "Prime_Period_Close_SUCCESS",
          payload: resp,
        });
      } else {
        dispatch({
          type: "Prime_Period_Close_FAIL",
          payload:
            resp.result[0].description || "Error While PrimePeriodClose Types.",
        });
      }
    } else {
      dispatch({
        type: "Prime_Period_Close_FAIL",
        payload: "Error While PrimePeriodClose Types.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While PrimePeriodClose Types.";
    dispatch({
      type: "Prime_Period_Close_FAIL",
      payload: error,
    });
  }
};

// closePeriod

export const closePeriod = (data) => async (dispatch) => {
  dispatch({
    type: "CLOSE_PERIOD_INIT",
  });
  const url = "/DPFAPI/PeriodCloseRequest";
  let obj = {
    actionType: "ClosePeriod",
    ...data,
  };

  try {
    let response = await axiosDPFAPIClient.post(url, obj);
    let resp =
      (response &&
        response.data &&
        response.data.PeriodCloseRequest_response) ||
      "";
    if (resp && resp.result.length > 0) {
      if (resp.result[0] && resp.result[0].status === "Success") {
        dispatch({
          type: "CLOSE_PERIOD_SUCCESS",
          payload: resp,
        });
      } else {
        dispatch({
          type: "CLOSE_PERIOD_FAIL",
          payload: resp.result[0].description || "Error While Posting Payment.",
        });
      }
    } else {
      dispatch({
        type: "CLOSE_PERIOD_FAIL",
        payload: "Error While Posting Payment.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Posting Payment.";
    dispatch({
      type: "CLOSE_PERIOD_FAIL",
      payload: error,
    });
  }
};

//----------------------****Get General Lengder****-----------

export const getLedgers = (data) => async (dispatch) => {
  dispatch({
    type: "Get_Ledgers_INIT",
  });
  const url = "/DPFAPI/GeneralLedgerRequest";
  let obj = {
    actionType: "GetLedgers",
    ...data,
  };

  try {
    let response = await axiosDPFAPIClient.post(url, obj);
    let resp =
      (response && response.data && response.data.GeneralLedger_response) || "";
    if (resp && resp.result.length > 0) {
      if (resp.result[0] && resp.result[0].status === "Success") {
        dispatch({
          type: "Get_Ledgers_SUCCESS",
          payload: resp,
        });
      } else {
        dispatch({
          type: "Get_Ledgers_FAIL",
          payload: resp.result[0].description || "Error While Get Ledgers.",
        });
      }
    } else {
      dispatch({
        type: "Get_Ledgers_FAIL",
        payload: "Error While Get Ledgers.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Get Ledgers.";
    dispatch({
      type: "Get_Ledgers_FAIL",
      payload: error,
    });
  }
};
//----------------------****Update General Lengder****-----------

export const updateLedgers = (data) => async (dispatch) => {
  dispatch({
    type: "Update_Ledgers_INIT",
  });
  const url = "/DPFAPI/GeneralLedgerRequest";
  let obj = {
    actionType: "UpdateLedgers",
    ...data,
  };

  try {
    let response = await axiosDPFAPIClient.post(url, obj);
    let resp =
      (response && response.data && response.data.GeneralLedger_response) || "";
    if (resp && resp.result.length > 0) {
      if (resp.result[0] && resp.result[0].status === "Success") {
        dispatch({
          type: "Update_Ledgers_SUCCESS",
          payload: resp,
        });
      } else {
        dispatch({
          type: "Update_Ledgers_FAIL",
          payload: resp.result[0].description || "Error While Get Ledgers.",
        });
      }
    } else {
      dispatch({
        type: "Update_Ledgers_FAIL",
        payload: "Error While Get Ledgers.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Get Ledgers.";
    dispatch({
      type: "Update_Ledgers_FAIL",
      payload: error,
    });
  }
};
//----------------------****Create DistChange Lengder****-----------

export const createDistChange = (guid, data) => async (dispatch) => {
  dispatch({
    type: "CREATE_DIST_CHANGE_INIT",
  });
  const url = "/DPFAPI/GeneralLedgerRequest";
  let obj = {
    actionType: "CreateDistChange",
    guidList: guid,
    distributionChange: {
      chartCode: data.chartCode,
      chartSort: data.chartSort,
    },
  };

  try {
    let response = await axiosDPFAPIClient.post(url, obj);
    let resp =
      (response && response.data && response.data.GeneralLedger_response) || "";

    if (resp && resp.result.length > 0) {
      if (resp.result[0] && resp.result[0].status === "Success") {
        dispatch({
          type: "CREATE_DIST_CHANGE_SUCCESS",
          payload: resp,
        });
      } else {
        dispatch({
          type: "CREATE_DIST_CHANGE_FAIL",
          payload: resp.result[0].description || "Error While Get Ledgers.",
        });
      }
    } else {
      dispatch({
        type: "CREATE_DIST_CHANGE_FAIL",
        payload: "Error While Get Ledgers.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Get Ledgers.";
    dispatch({
      type: "CREATE_DIST_CHANGE_FAIL",
      payload: error,
    });
  }
};
//----------------------****Get Reconcile****-----------
export const getReconcile = (guidList) => async (dispatch) => {
  dispatch({
    type: "GET_RECONCILE_INIT",
  });
  const url = "/DPFAPI/GeneralLedgerRequest";
  let obj = {
    actionType: "Reconcile",
    ...guidList,
  };

  try {
    let response = await axiosDPFAPIClient.post(url, obj);
    let resp =
      (response && response.data && response.data.GeneralLedger_response) || "";
    if (resp && resp.result.length > 0) {
      if (resp.result[0] && resp.result[0].status === "Success") {
        dispatch({
          type: "GET_RECONCILE_SUCCESS",
          payload: resp,
        });
      } else {
        dispatch({
          type: "GET_RECONCILE__FAIL",
          payload:
            resp.result[0].description || "Error While Exporting Ledger.",
        });
      }
    } else {
      dispatch({
        type: "GET_RECONCILE__FAIL",
        payload: "Error While Exporting LEDGER.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Exporting Ledger.";
    dispatch({
      type: "GET_RECONCILE__FAIL",
      payload: error,
    });
  }
};

//----------------------****Get Ledger History****-----------

export const getLedgerHistory = (data) => async (dispatch) => {
  dispatch({
    type: "GET_LEDGER_HISTORY_INIT",
  });
  const url = "/DPFAPI/GeneralLedgerRequest";
  let obj = {
    actionType: "GetLedgerHistory",
    ...data,
  };

  try {
    let response = await axiosDPFAPIClient.post(url, obj);
    let resp =
      (response && response.data && response.data.GeneralLedger_response) || "";

    if (resp && resp.result.length > 0) {
      if (resp.result[0] && resp.result[0].status === "Success") {
        dispatch({
          type: "GET_LEDGER_HISTORY_SUCCESS",
          payload: resp,
        });
      } else {
        dispatch({
          type: "GET_LEDGER_HISTORY_FAIL",
          payload:
            resp.result[0].description || "Error While Get Ledgers History.",
        });
      }
    } else {
      dispatch({
        type: "GET_LEDGER_HISTORY_FAIL",
        payload: "Error While Get Ledgers.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Get Ledgers History.";
    dispatch({
      type: "GET_LEDGER_HISTORY_FAIL",
      payload: error,
    });
  }
};
//----------------------****  getExportLedger  ****-----------
export const getExportLedger = (guidList) => async (dispatch) => {
  dispatch({
    type: "EXPORT_LEDGER_INIT",
  });
  const url = "/DPFAPI/GeneralLedgerRequest";
  let obj = {
    actionType: "ExportLedger",
    ...guidList,
  };

  try {
    let response = await axiosDPFAPIClient.post(url, obj);
    let resp =
      (response && response.data && response.data.GeneralLedger_response) || "";
    if (resp && resp.result.length > 0) {
      if (resp.result[0] && resp.result[0].status === "Success") {
        dispatch({
          type: "EXPORT_LEDGER_SUCCESS",
          payload: resp,
        });
      } else {
        dispatch({
          type: "EXPORT_LEDGER__FAIL",
          payload:
            resp.result[0].description || "Error While Exporting Ledger.",
        });
      }
    } else {
      dispatch({
        type: "EXPORT_LEDGER__FAIL",
        payload: "Error While Exporting LEDGER.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Exporting Ledger.";
    dispatch({
      type: "EXPORT_CASHFLOW__FAIL",
      payload: error,
    });
  }
};

//----------------------****Get Advanced Excel Expor tOptions  General Lengder****-----------
export const getAdvancedExcelExportOptions = () => async (dispatch) => {
  dispatch({
    type: "GET_ADVANCED_EXCEL_EXPORT_INIT",
  });
  const url = "/DPFAPI/GeneralLedgerRequest";
  let obj = {
    actionType: "GetAdvancedExcelExportOptions",
  };

  try {
    let response = await axiosDPFAPIClient.post(url, obj);
    let resp =
      (response && response.data && response.data.GeneralLedger_response) || "";
    if (resp && resp.result.length > 0) {
      if (resp.result[0] && resp.result[0].status === "Success") {
        dispatch({
          type: "GET_ADVANCED_EXCEL_EXPORT_SUCCESS",
          payload: resp,
        });
      } else {
        dispatch({
          type: "GET_ADVANCED_EXCEL_EXPORT_FAIL",
          payload:
            resp.result[0].description ||
            "Error While get Advanced Excel Export Options.",
        });
      }
    } else {
      dispatch({
        type: "GET_ADVANCED_EXCEL_EXPORT_FAIL",
        payload: "Error While get Advanced Excel Export Options.",
      });
    }
  } catch (err) {
    const error =
      err.message || "Error While get Advanced Excel Export Options.";
    dispatch({
      type: "GET_ADVANCED_EXCEL_EXPORT_FAIL",
      payload: error,
    });
  }
};
//----------------------****Advanced Excel Export****-----------
export const advancedExcelExport = (id, state) => async (dispatch) => {
  dispatch({
    type: "ADVANCED_EXCEL_EXPORT_INIT",
  });
  const url = "/DPFAPI/GeneralLedgerRequest";
  let obj = {
    actionType: "AdvancedExcelExport",
    guidList: id,
    excelOptions: state,
  };

  try {
    let response = await axiosDPFAPIClient.post(url, obj);
    let resp =
      (response && response.data && response.data.GeneralLedger_response) || "";
    if (resp && resp.result.length > 0) {
      if (resp.result[0] && resp.result[0].status === "Success") {
        dispatch({
          type: "ADVANCED_EXCEL_EXPORT_SUCCESS",
          payload: resp,
        });
      } else {
        dispatch({
          type: "ADVANCED_EXCEL_EXPORT_FAIL",
          payload:
            resp.result[0].description ||
            "Error While Advanced Excel Export Options.",
        });
      }
    } else {
      dispatch({
        type: "ADVANCED_EXCEL_EXPORT_FAIL",
        payload: "Error While Advanced Excel Export Options.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Advanced Excel Export Options.";
    dispatch({
      type: "ADVANCED_EXCEL_EXPORT_FAIL",
      payload: error,
    });
  }
};

//----------------------****PrimeImportLedger****-----------
export const primeImportLedger = () => async (dispatch) => {
  dispatch({
    type: "PRIME_IMPORT_LEDGER_INIT",
  });
  const url = "/DPFAPI/GeneralLedgerRequest";
  let obj = {
    actionType: "PrimeImportLedger ",
  };

  try {
    let response = await axiosDPFAPIClient.post(url, obj);
    let resp =
      (response && response.data && response.data.GeneralLedger_response) || "";
    if (resp && resp.result.length > 0) {
      if (resp.result[0] && resp.result[0].status === "Success") {
        dispatch({
          type: "PRIME_IMPORT_LEDGER_SUCCESS",
          payload: resp,
        });
      } else {
        dispatch({
          type: "PRIME_IMPORT_LEDGER_FAIL",
          payload:
            resp.result[0].description || "Error While Prime Import Ledger.",
        });
      }
    } else {
      dispatch({
        type: "PRIME_IMPORT_LEDGER_FAIL",
        payload: "Error While Prime Import Ledger.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Prime Import Ledger.";
    dispatch({
      type: "PRIME_IMPORT_LEDGER_FAIL",
      payload: error,
    });
  }
};

//----------------------****Add Attachments General Lengder****-----------
export const addAttachment = (data) => async (dispatch) => {
  dispatch({
    type: "ADD_ATTACHMENT_INIT",
  });
  const url = "/DPFAPI/GeneralLedgerRequest";
  let obj = {
    actionType: "AddAttachment",
    ...data,
  };

  try {
    let response = await axiosDPFAPIClient.post(url, obj);
    let resp =
      (response && response.data && response.data.GeneralLedger_response) || "";
    if (resp && resp.result.length > 0) {
      if (resp.result[0] && resp.result[0].status === "Success") {
        dispatch({
          type: "ADD_ATTACHMENT_SUCCESS",
          payload: resp,
        });
      } else {
        dispatch({
          type: "ADD_ATTACHMENT_FAIL",
          payload:
            resp.result[0].description || "Error While Adding Attachments.",
        });
      }
    } else {
      dispatch({
        type: "ADD_ATTACHMENT_FAIL",
        payload: "Error While Adding Attachments.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Adding Attachments.";
    dispatch({
      type: "ADD_ATTACHMENT_FAIL",
      payload: error,
    });
  }
};

//----------------------****Get Attachment General Lengder****-----------
export const getAccountAttachment = (recordID) => async (dispatch) => {
  dispatch({
    type: "GET_ATTACHMENT_INIT",
  });
  const url = "/DPFAPI/GeneralLedgerRequest";
  let obj = {
    actionType: "GetAttachment",
    recordID: recordID,
  };

  try {
    let response = await axiosDPFAPIClient.post(url, obj);
    let resp =
      (response && response.data && response.data.GeneralLedger_response) || "";
    if (resp && resp.result.length > 0) {
      if (resp.result[0] && resp.result[0].status === "Success") {
        dispatch({
          type: "GET_ATTACHMENT_SUCCESS",
          payload: resp,
        });
      } else {
        dispatch({
          type: "GET_ATTACHMENT_FAIL",
          payload: resp.result[0].description || "Error While Get Attachment.",
        });
      }
    } else {
      dispatch({
        type: "GET_ATTACHMENT_FAIL",
        payload: "Error While Get Attachment.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Get Attachmen.";
    dispatch({
      type: "GET_ATTACHMENT_FAIL",
      payload: error,
    });
  }
};
//----------------------****Get Attachments General Lengder****-----------
export const getAttachments = (id) => async (dispatch) => {
  dispatch({
    type: "GET_ATTACHMENTS_INIT",
  });
  const url = "/DPFAPI/GeneralLedgerRequest";
  let obj = {
    actionType: "GetAttachments",
    guid: id,
  };

  try {
    let response = await axiosDPFAPIClient.post(url, obj);
    let resp =
      (response && response.data && response.data.GeneralLedger_response) || "";
    if (resp && resp.result.length > 0) {
      if (resp.result[0] && resp.result[0].status === "Success") {
        dispatch({
          type: "GET_ATTACHMENTS_SUCCESS",
          payload: resp,
        });
      } else {
        dispatch({
          type: "GET_ATTACHMENTS_FAIL",
          payload: resp.result[0].description || "Error While Get Attachments.",
        });
      }
    } else {
      dispatch({
        type: "GET_ATTACHMENTS_FAIL",
        payload: "Error While Get Attachments.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Get Attachments.";
    dispatch({
      type: "GET_ATTACHMENTS_FAIL",
      payload: error,
    });
  }
};
//----------------------****Get Prime CashFlow ****-------------
export const getPrimeCashFlow = () => async (dispatch) => {
  dispatch({
    type: "GET_PRIME_CASHFLOW_INIT",
  });
  const url = "/DPFAPI/CashFlowRequest";
  let obj = {
    actionType: "PrimeCashFlow",
  };

  try {
    let response = await axiosDPFAPIClient.post(url, obj);
    let resp =
      (response && response.data && response.data.CashFlowRequest_response) ||
      "";
    if (resp && resp.result.length > 0) {
      if (resp.result[0] && resp.result[0].status === "Success") {
        dispatch({
          type: "GET_PRIME_CASHFLOW_SUCCESS",
          payload: resp,
        });
      } else {
        dispatch({
          type: "GET_PRIME_CASHFLOW_FAIL",
          payload:
            resp.result[0].description || "Error While Get Prime CashFlow.",
        });
      }
    } else {
      dispatch({
        type: "GET_PRIME_CASHFLOW_FAIL",
        payload: "Error While Get Prime CashFlow.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Get Prime CashFlow Export.";
    dispatch({
      type: "GET_PRIME_CASHFLOW_FAIL",
      payload: error,
    });
  }
};
//----------------------****Get Export CashFlow ****-------------
export const getExportCashFlow = (data) => async (dispatch) => {
  dispatch({
    type: "EXPORT_CASHFLOW_INIT",
  });
  const url = "/DPFAPI/CashFlowRequest";
  let obj = {
    actionType: "ExportCashFlow",
    ...data,
  };

  try {
    let response = await axiosDPFAPIClient.post(url, obj);
    let resp =
      (response && response.data && response.data.CashFlowRequest_response) ||
      "";
    if (resp && resp.result.length > 0) {
      if (resp.result[0] && resp.result[0].status === "Success") {
        dispatch({
          type: "EXPORT_CASHFLOW_SUCCESS",
          payload: resp,
        });
      } else {
        dispatch({
          type: "EXPORT_CASHFLOW__FAIL",
          payload:
            resp.result[0].description || "Error While Exporting CashFlow.",
        });
      }
    } else {
      dispatch({
        type: "EXPORT_CASHFLOW__FAIL",
        payload: "Error While Exporting CashFlow.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Exporting CashFlow.";
    dispatch({
      type: "EXPORT_CASHFLOW__FAIL",
      payload: error,
    });
  }
};

//----------------------****Get Import CashFlow ****-------------
export const getImportCashFlow = (data) => async (dispatch) => {
  dispatch({
    type: "IMPORT_CASHFLOW_INIT",
  });
  const url = "/DPFAPI/CashFlowRequest";
  let obj = {
    actionType: "ImportCashFlow",
    ...data,
  };

  try {
    let response = await axiosDPFAPIClient.post(url, obj);
    let resp =
      (response && response.data && response.data.CashFlowRequest_response) ||
      "";
    if (resp && resp.result.length > 0) {
      if (resp.result[0] && resp.result[0].status === "Success") {
        dispatch({
          type: "IMPORT_CASHFLOW_SUCCESS",
          payload: resp,
        });
      } else {
        dispatch({
          type: "IMPORT_CASHFLOW_FAIL",
          payload:
            resp.result[0].description || "Error While Importing CashFlow.",
        });
      }
    } else {
      dispatch({
        type: "IMPORT_CASHFLOW_FAIL",
        payload: "Error While Importing CashFlow.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Importing CashFlow.";
    dispatch({
      type: "IMPORT_CASHFLOW_FAIL",
      payload: error,
    });
  }
};
//----------------------**** PrimeVatSubmission      ****-------------
//----------------------****Get Prime Vat Submission ****-------------
export const getPrimeVatSubmission = () => async (dispatch) => {
  dispatch({
    type: "GET_PRIME_VAT_SUBMISSION_INIT",
  });
  const url = "/DPFAPI/VATSubmissionRequest";
  let obj = {
    actionType: "PrimeVatSubmission",
  };

  try {
    let response = await axiosDPFAPIClient.post(url, obj);
    let resp =
      (response &&
        response.data &&
        response.data.VatSubmissionRequest_response) ||
      "";
    if (resp && resp.result.length > 0) {
      if (resp.result[0] && resp.result[0].status === "Success") {
        dispatch({
          type: "GET_PRIME_VAT_SUBMISSION_SUCCESS",
          payload: resp,
        });
      } else {
        dispatch({
          type: "GET_PRIME_VAT_SUBMISSION_FAIL",
          payload:
            resp.result[0].description ||
            "Error While Getting Prime Vat Submission.",
        });
      }
    } else {
      dispatch({
        type: "GET_PRIME_VAT_SUBMISSION_FAIL",
        payload: "Error While Getting Prime Vat Submission.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Getting Prime Vat Submission.";
    dispatch({
      type: "GET_PRIME_VAT_SUBMISSION_FAIL",
      payload: error,
    });
  }
};
//----------------------**** calculateVatSubmission ****-------------
export const calculateVatSubmission = (data) => async (dispatch) => {
  dispatch({
    type: "CALCULATE_VAT_SUBMISSION_INIT",
  });
  const url = "/DPFAPI/VATSubmissionRequest";
  let obj = {
    actionType: "CalculateVatSubmission",
    vatSubmission: data,
  };

  try {
    let response = await axiosDPFAPIClient.post(url, obj);
    let resp =
      (response &&
        response.data &&
        response.data.VatSubmissionRequest_response) ||
      "";
    if (resp && resp.result.length > 0) {
      if (resp.result[0] && resp.result[0].status === "Success") {
        dispatch({
          type: "CALCULATE_VAT_SUBMISSION_SUCCESS",
          payload: resp,
        });
      } else {
        dispatch({
          type: "CALCULATE_VAT_SUBMISSION_FAIL",
          payload:
            resp.result[0].description ||
            "Error While Calulation Vat Submission.",
        });
      }
    } else {
      dispatch({
        type: "CALCULATE_PRIME_VAT_SUBMISSION_FAIL",
        payload: "Error While Calulation Vat Submission.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Calulation Vat Submission.";
    dispatch({
      type: "CALCULATE_PRIME_VAT_SUBMISSION_FAIL",
      payload: error,
    });
  }
};

//----------------------****    Bank Rec Actions  ****------------------
//----------------------****    GetBankRecSummary ****------------------
export const getBankRecSummary = (bankCode) => async (dispatch) => {
  dispatch({
    type: "GET_BANK_REC_SUMMARY_INIT",
  });
  const url = "/DPFAPI/BankRecRequest";
  let obj = {
    actionType: "GetBankRecSummary",
    bankCode,
  };

  try {
    let response = await axiosDPFAPIClient.post(url, obj);
    let resp =
      (response && response.data && response.data.BankRequest_response) || "";

    if (resp && resp.result.length > 0) {
      let success = resp.result.find((item) => item.status === "Success");
      if (success) {
        dispatch({
          type: "GET_BANK_REC_SUMMARY_SUCCESS",
          payload: resp,
        });
      } else {
        dispatch({
          type: "GET_BANK_REC_SUMMARY_FAIL",
          payload:
            resp.result[0].description ||
            "Error While Getting Bank Rec Summary.",
        });
      }
    } else {
      dispatch({
        type: "GET_BANK_REC_SUMMARY_FAIL",
        payload: "Error While Getting Bank Rec Summary.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Getting Bank Rec Summary.";
    dispatch({
      type: "GET_BANK_REC_SUMMARY_FAIL",
      payload: error,
    });
  }
};
//----------------------****    UpdateStatment ****---------------------
export const getUpdateStatement =
  (bankCode, newBalance) => async (dispatch) => {
    dispatch({
      type: "GET_UPDATE_STATEMENT_INIT",
    });
    const url = "/DPFAPI/BankRecRequest";
    let obj = {
      actionType: "UpdateStatement",
      bankCode: bankCode,
      newBalance: newBalance,
    };

    try {
      let response = await axiosDPFAPIClient.post(url, obj);
      let resp =
        (response && response.data && response.data.BankRequest_response) || "";
      if (resp && resp.result.length > 0) {
        if (resp.result[0] && resp.result[0].status === "Success") {
          dispatch({
            type: "GET_UPDATE_STATEMENT_SUCCESS",
            payload: resp,
          });
        } else {
          dispatch({
            type: "GET_UPDATE_STATEMENT_FAIL",
            payload:
              resp.result[0].description || "Error While Updating Statement.",
          });
        }
      } else {
        dispatch({
          type: "GET_UPDATE_STATEMENT_FAIL",
          payload: "Error While Updating Statement.",
        });
      }
    } catch (err) {
      const error = err.message || "Error While Updating Statement.";
      dispatch({
        type: "GET_UPDATE_STATEMENT_FAIL",
        payload: error,
      });
    }
  };
//----------------------****    GetAttachments ****---------------------
export const getBankRecAttachments = (bankCode) => async (dispatch) => {
  dispatch({
    type: "GET_BANK_REC_ATTACHMENTS_INIT",
  });
  const url = `/DPFAPI/BankRecRequest`;
  let obj = {
    actionType: "GetAttachments",
    bankCode,
  };

  try {
    let response = await axiosDPFAPIClient.post(url, obj);
    let resp =
      (response && response.data && response.data.BankRequest_response) || "";
    if (resp && resp.result.length > 0) {
      if (resp.result[0] && resp.result[0].status === "Success") {
        dispatch({
          type: "GET_BANK_REC_ATTACHMENTS_SUCCESS",
          payload: resp,
        });
      } else {
        dispatch({
          type: "GET_BANK_REC_ATTACHMENTS_FAIL",
          payload:
            resp.result[0].description ||
            "Error While Getting Bank Rec Attachments.",
        });
      }
    } else {
      dispatch({
        type: "GET_BANK_REC_ATTACHMENTS_FAIL",
        payload: "Error While Getting Bank Rec Attachments.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Getting Bank Rec Attachments.";
    dispatch({
      type: "GET_BANK_REC_ATTACHMENTS_FAIL",
      payload: error,
    });
  }
};
//----------------------****    GetAttachment ****---------------------
export const getBankRecAttachment = (recordID) => async (dispatch) => {
  dispatch({
    type: "GET_BANK_REC_ATTACHMENT_INIT",
  });
  const url =

    `/DPFAPI/BankRecRequest?actionType=GetAttachment&recordId=${recordID}`;
  // let obj = {
  //   actionType: "GetAttachment",
  //   recordID: recordID,
  // };

  try {
    let response = await axiosDPFAPIClient.get(url);
    let resp =
      (response && response.data && response.data.BankRequest_response) || "";
    if (resp && resp.result.length > 0) {
      if (resp.result[0] && resp.result[0].status === "Success") {
        dispatch({
          type: "GET_BANK_REC_ATTACHMENT_SUCCESS",
          payload: resp,
        });
      } else {
        dispatch({
          type: "GET_BANK_REC_ATTACHMENT_FAIL",
          payload:
            resp.result[0].description ||
            "Error While Getting Bank Rec Attachment.",
        });
      }
    } else {
      dispatch({
        type: "GET_BANK_REC_ATTACHMENT_FAIL",
        payload: "Error While Getting Bank Rec Attachment.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Getting Bank Rec Attachment.";
    dispatch({
      type: "GET_BANK_REC_ATTACHMENT_FAIL",
      payload: error,
    });
  }
};
//----------------------****    addBankRecAttachment ****---------------------
export const addBankRecAttachment = (data) => async (dispatch) => {
  dispatch({
    type: "ADD_BANK_REC_ATTACHMENT_INIT",
  });
  const url = "/DPFAPI/BankRecRequest";
  let obj = {
    actionType: "AddAttachment",
    ...data,
  };

  try {
    let response = await axiosDPFAPIClient.post(url, obj);
    let resp =
      (response && response.data && response.data.BankRequest_response) || "";
    if (resp && resp.result.length > 0) {
      if (resp.result[0] && resp.result[0].status === "Success") {
        dispatch({
          type: "ADD_BANK_REC_ATTACHMENT_SUCCESS",
          payload: resp,
        });
      } else {
        dispatch({
          type: "ADD_BANK_REC_ATTACHMENT_FAIL",
          payload:
            resp.result[0].description ||
            "Error While Adding Bank Rec Attachment.",
        });
      }
    } else {
      dispatch({
        type: "ADD_BANK_REC_ATTACHMENT_FAIL",
        payload: "Error While Adding Bank Rec Attachment.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Adding Bank Rec Attachment.";
    dispatch({
      type: "ADD_BANK_REC_ATTACHMENT_FAIL",
      payload: error,
    });
  }
};
//----------------------****    deleteBankRecAttachment ****---------------------
export const deleteBankRecAttachment = (recordID) => async (dispatch) => {
  dispatch({
    type: "DELETE_BANK_REC_ATTACHMENT_INIT",
  });
  const url = "/DPFAPI/BankRecRequest";
  let obj = {
    actionType: "DeleteAttachment",
    recordID: recordID,
  };

  try {
    let response = await axiosDPFAPIClient.post(url, obj);
    let resp =
      (response && response.data && response.data.BankRequest_response) || "";
    if (resp && resp.result.length > 0) {
      if (resp.result[0] && resp.result[0].status === "Success") {
        dispatch({
          type: "DELETE_BANK_REC_ATTACHMENT_SUCCESS",
          payload: resp,
        });
      } else {
        dispatch({
          type: "DELETE_BANK_REC_ATTACHMENT_FAIL",
          payload:
            resp.result[0].description ||
            "Error While Deleting Bank Rec Attachment.",
        });
      }
    } else {
      dispatch({
        type: "DELETE_BANK_REC_ATTACHMENT_FAIL",
        payload: "Error While Deleting Bank Rec Attachment.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Deleting Bank Rec Attachment.";
    dispatch({
      type: "DELETE_BANK_REC_ATTACHMENT_FAIL",
      payload: error,
    });
  }
};
//----------------------****    getUpdateStatus ****---------------------
export const getUpdateStatus = (data) => async (dispatch) => {
  dispatch({
    type: "GET_UPDATE_STATUS_INIT",
  });
  const url = "/DPFAPI/BankRecRequest";
  let obj = {
    actionType: "UpdateStatus",
    ...data,
  };

  try {
    let response = await axiosDPFAPIClient.post(url, obj);
    let resp =
      (response && response.data && response.data.BankRequest_response) || "";
    if (resp && resp.result.length > 0) {
      if (resp.result[0] && resp.result[0].status === "Success") {
        dispatch({
          type: "GET_UPDATE_STATUS_SUCCESS",
          payload: resp,
        });
      } else {
        dispatch({
          type: "GET_UPDATE_STATUS_FAIL",
          payload:
            resp.result[0].description || "Error While Updating Records.",
        });
      }
    } else {
      dispatch({
        type: "GET_UPDATE_STATUS_FAIL",
        payload: "Error While Updating Records.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Updating Records.";
    dispatch({
      type: "GET_UPDATE_STATUS_FAIL",
      payload: error,
    });
  }
};
//----------------------****    getCheques ****---------------------
export const getCheques = (bankCode) => async (dispatch) => {
  dispatch({
    type: "GET_CHEQUES_INIT",
  });
  const url = "/DPFAPI/BankRecRequest";
  let obj = {
    actionType: "GetCheques",
    bankCode,
  };

  try {
    let response = await axiosDPFAPIClient.post(url, obj);
    let resp =
      (response && response.data && response.data.BankRequest_response) || "";
    if (resp && resp.result.length > 0) {
      if (resp.result[0] && resp.result[0].status === "Success") {
        dispatch({
          type: "GET_CHEQUES_SUCCESS",
          payload: resp,
        });
      } else {
        dispatch({
          type: "GET_CHEQUES_FAIL",
          payload: resp.result[0].description || "Error While Getting Cheques.",
        });
      }
    } else {
      dispatch({
        type: "GET_CHEQUES_FAIL",
        payload: "Error While Getting Cheques.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Getting Cheques.";
    dispatch({
      type: "GET_CHEQUES_FAIL",
      payload: error,
    });
  }
};

//----------------------****    getUpdateCheques ****---------------------
export const getUpdateCheques = (data) => async (dispatch) => {
  dispatch({
    type: "GET_UPDATE_CHEQUES_INIT",
  });
  const url = "/DPFAPI/BankRecRequest";
  let obj = {
    actionType: "UpdateCheques",
    ...data,
  };

  try {
    let response = await axiosDPFAPIClient.post(url, obj);
    let resp =
      (response && response.data && response.data.BankRequest_response) || "";
    if (resp && resp.result.length > 0) {
      if (resp.result[0] && resp.result[0].status === "Success") {
        dispatch({
          type: "GET_UPDATE_CHEQUES_SUCCESS",
          payload: resp,
        });
      } else {
        dispatch({
          type: "GET_UPDATE_CHEQUES_FAIL",
          payload:
            resp.result[0].description || "Error While Updating Cheques.",
        });
      }
    } else {
      dispatch({
        type: "GET_UPDATE_CHEQUES_FAIL",
        payload: "Error While Updating Cheques.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Updating Cheques.";
    dispatch({
      type: "GET_UPDATE_CHEQUES_FAIL",
      payload: error,
    });
  }
};
//----------------------****   getCancelReIssueCheque ****---------------------
export const getCancelReIssueCheque = (data) => async (dispatch) => {
  dispatch({
    type: "GET_CANCEL_REISSUE_CHEQUE_INIT",
  });
  const url = "/DPFAPI/BankRecRequest";
  let obj = {
    actionType: "CancelReIssueCheque",
    ...data,
  };

  try {
    let response = await axiosDPFAPIClient.post(url, obj);
    let resp =
      (response && response.data && response.data.BankRequest_response) || "";
    if (resp && resp.result.length > 0) {
      if (resp.result[0] && resp.result[0].status === "Success") {
        dispatch({
          type: "GET_CANCEL_REISSUE_CHEQUE_SUCCESS",
          payload: resp,
        });
      } else {
        dispatch({
          type: "GET_CANCEL_REISSUE_CHEQUE_FAIL",
          payload:
            resp.result[0].description ||
            "Error While Getting Cancel ReIssue Cheque.",
        });
      }
    } else {
      dispatch({
        type: "GET_CANCEL_REISSUE_CHEQUE_FAIL",
        payload: "Error While Getting Cancel ReIssue Cheque.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Getting Cancel ReIssue Cheque.";
    dispatch({
      type: "GET_CANCEL_REISSUE_CHEQUE_FAIL",
      payload: error,
    });
  }
};
//----------------------****   getJournals ****---------------------
export const getJournals = (bankCode) => async (dispatch) => {
  dispatch({
    type: "GET_JOURNALS_INIT",
  });
  const url = "/DPFAPI/BankRecRequest";
  let obj = {
    actionType: "GetJournals",
    bankCode,
  };

  try {
    let response = await axiosDPFAPIClient.post(url, obj);
    let resp =
      (response && response.data && response.data.BankRequest_response) || "";
    if (resp && resp.result.length > 0) {
      if (resp.result[0] && resp.result[0].status === "Success") {
        dispatch({
          type: "GET_JOURNALS_SUCCESS",
          payload: resp,
        });
      } else {
        dispatch({
          type: "GET_JOURNALS_FAIL",
          payload:
            resp.result[0].description || "Error While Getting Journals.",
        });
      }
    } else {
      dispatch({
        type: "GET_JOURNALS_FAIL",
        payload: "Error While Getting Journals.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Getting Journals.";
    dispatch({
      type: "GET_JOURNALS_FAIL",
      payload: error,
    });
  }
};
//----------------------****   updateJournals ****---------------------
export const updateJournals = (data) => async (dispatch) => {
  dispatch({
    type: "UPDATE_JOURNALS_INIT",
  });
  const url = "/DPFAPI/BankRecRequest";
  let obj = {
    actionType: "UpdateJournals",
    ...data,
  };

  try {
    let response = await axiosDPFAPIClient.post(url, obj);
    let resp =
      (response && response.data && response.data.BankRequest_response) || "";
    if (resp && resp.result.length > 0) {
      if (resp.result[0] && resp.result[0].status === "Success") {
        dispatch({
          type: "UPDATE_JOURNALS_SUCCESS",
          payload: resp,
        });
      } else {
        dispatch({
          type: "UPDATE_JOURNALS_FAIL",
          payload:
            resp.result[0].description || "Error While Updating Journals.",
        });
      }
    } else {
      dispatch({
        type: "UPDATE_JOURNALS_FAIL",
        payload: "Error While Updating Journals.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Updating Journals.";
    dispatch({
      type: "UPDATE_JOURNALS_FAIL",
      payload: error,
    });
  }
};
//----------------------****   getAdjustments ****---------------------
export const getAdjustments = (bankCode) => async (dispatch) => {
  dispatch({
    type: "GET_ADJUSTMENTS_INIT",
  });
  const url = "/DPFAPI/BankRecRequest";
  let obj = {
    actionType: "GetAdjustments",
    bankCode,
  };

  try {
    let response = await axiosDPFAPIClient.post(url, obj);
    let resp =
      (response && response.data && response.data.BankRequest_response) || "";
    if (resp && resp.result.length > 0) {
      if (resp.result[0] && resp.result[0].status === "Success") {
        dispatch({
          type: "GET_ADJUSTMENTS_SUCCESS",
          payload: resp,
        });
      } else {
        dispatch({
          type: "GET_ADJUSTMENTS_FAIL",
          payload:
            resp.result[0].description || "Error While Getting Adjustments.",
        });
      }
    } else {
      dispatch({
        type: "GET_ADJUSTMENTS_FAIL",
        payload: "Error While Getting Adjustments.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Getting Adjustments.";
    dispatch({
      type: "GET_ADJUSTMENTS_FAIL",
      payload: error,
    });
  }
};
//----------------------****   updateAdjustments ****---------------------
export const updateAdjustments = (data) => async (dispatch) => {
  dispatch({
    type: "UPDATE_ADJUSTMENTS_INIT",
  });
  const url = "/DPFAPI/BankRecRequest";
  let obj = {
    actionType: "UpdateAdjustments",
    ...data,
  };

  try {
    let response = await axiosDPFAPIClient.post(url, obj);
    let resp =
      (response && response.data && response.data.BankRequest_response) || "";
    if (resp && resp.result.length > 0) {
      if (resp.result[0] && resp.result[0].status === "Success") {
        dispatch({
          type: "UPDATE_ADJUSTMENTS_SUCCESS",
          payload: resp,
        });
      } else {
        dispatch({
          type: "UPDATE_ADJUSTMENTS_FAIL",
          payload:
            resp.result[0].description || "Error While Updating Adjustments.",
        });
      }
    } else {
      dispatch({
        type: "UPDATE_ADJUSTMENTS_FAIL",
        payload: "Error While Updating Adjustments.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Updating Adjustments.";
    dispatch({
      type: "UPDATE_ADJUSTMENTS_FAIL",
      payload: error,
    });
  }
};
//----------------------****   deleteAdjustments ****---------------------
export const deleteAdjustments = (data) => async (dispatch) => {
  dispatch({
    type: "DELETE_ADJUSTMENTS_INIT",
  });
  const url = "/DPFAPI/BankRecRequest";
  let obj = {
    actionType: "DeleteAdjustments",
    ...data,
  };

  try {
    let response = await axiosDPFAPIClient.post(url, obj);
    let resp =
      (response && response.data && response.data.BankRequest_response) || "";
    if (resp && resp.result.length > 0) {
      if (resp.result[0] && resp.result[0].status === "Success") {
        dispatch({
          type: "DELETE_ADJUSTMENTS_SUCCESS",
          payload: resp,
        });
      } else {
        dispatch({
          type: "DELETE_ADJUSTMENTS_FAIL",
          payload:
            resp.result[0].description || "Error While Deleting Adjustments.",
        });
      }
    } else {
      dispatch({
        type: "DELETE_ADJUSTMENTS_FAIL",
        payload: "Error While Deleting Adjustments.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Deleting Adjustments.";
    dispatch({
      type: "DELETE_ADJUSTMENTS_FAIL",
      payload: error,
    });
  }
};
//----------------------**** Get Bank Transactions ****-------------
export const getBankTransactions = (bankCode, data) => async (dispatch) => {
  dispatch({
    type: "GET_BANK_TRANSACTION_INIT",
  });
  const url = "/DPFAPI/BankRecRequest";
  let obj = {
    actionType: "GetBankTransactions",
    bankCode,
    ...data,
  };

  try {
    let response = await axiosDPFAPIClient.post(url, obj);
    let resp =
      (response && response.data && response.data.BankRequest_response) || "";
    if (resp && resp.result.length > 0) {
      if (resp.result[0] && resp.result[0].status === "Success") {
        dispatch({
          type: "GET_BANK_TRANSACTION_SUCCESS",
          payload: resp,
        });
      } else {
        dispatch({
          type: "GET_BANK_TRANSACTION_FAIL",
          payload:
            resp.result[0].description ||
            "Error While Getting get bank transaction.",
        });
      }
    } else {
      dispatch({
        type: "GET_BANK_TRANSACTION_FAIL",
        payload: "Error While Getting get bank transaction.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Getting get bank transaction.";
    dispatch({
      type: "GET_BANK_TRANSACTION_FAIL",
      payload: error,
    });
  }
};
//----------------------**** Update Bank Transactions ****---------------
export const updateBankTransactions = (data) => async (dispatch) => {
  dispatch({
    type: "UPDATE_BANK_TRANSACTIONS_INIT",
  });
  const url = "/DPFAPI/BankRecRequest";
  let obj = {
    actionType: "UpdateBankTransactions",
    ...data,
  };

  try {
    let response = await axiosDPFAPIClient.post(url, obj);
    let resp =
      (response && response.data && response.data.BankRequest_response) || "";
    if (resp && resp.result.length > 0) {
      if (resp.result[0] && resp.result[0].status === "Success") {
        dispatch({
          type: "UPDATE_BANK_TRANSACTIONS_SUCCESS",
          payload: resp,
        });
      } else {
        dispatch({
          type: "UPDATE_BANK_TRANSACTIONS_FAIL",
          payload:
            resp.result[0].description ||
            "Error While Updating Bank transactions.",
        });
      }
    } else {
      dispatch({
        type: "UPDATE_BANK_TRANSACTIONS_FAIL",
        payload: "Error While Updating Bank transactions.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Updating Bank transactions.";
    dispatch({
      type: "UPDATE_BANK_TRANSACTIONS_FAIL",
      payload: error,
    });
  }
};
//----------------------**** Delete Bank Transactions ****---------------
export const deleteBankTransactions = (data) => async (dispatch) => {
  dispatch({
    type: "DELETE_BANK_TRANSACTIONS_INIT",
  });
  const url = "/DPFAPI/BankRecRequest";
  let obj = {
    actionType: "DeleteBankTransactions",
    ...data,
  };

  try {
    let response = await axiosDPFAPIClient.post(url, obj);
    let resp =
      (response && response.data && response.data.BankRequest_response) || "";
    if (resp && resp.result.length > 0) {
      if (resp.result[0] && resp.result[0].status === "Success") {
        dispatch({
          type: "DELETE_BANK_TRANSACTIONS_SUCCESS",
          payload: resp,
        });
      } else {
        dispatch({
          type: "DELETE_BANK_TRANSACTIONS_FAIL",
          payload:
            resp.result[0].description ||
            "Error While Deleting Bank transactions.",
        });
      }
    } else {
      dispatch({
        type: "DELETE_BANK_TRANSACTIONS_FAIL",
        payload: "Error While Deleting Bank transactions.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Deleting Bank transactions.";
    dispatch({
      type: "DELETE_BANK_TRANSACTIONS_FAIL",
      payload: error,
    });
  }
};
//----------------------**** ExportExcel ****---------------
export const getExportExcel = (data) => async (dispatch) => {
  dispatch({
    type: "GET_EXPORT_EXCEL_INIT",
  });
  const url = "/DPFAPI/BankRecRequest";
  let obj = {
    actionType: "ExportExcel",
    ...data,
  };

  try {
    let response = await axiosDPFAPIClient.post(url, obj);
    let resp =
      (response && response.data && response.data.BankRequest_response) || "";
    if (resp && resp.result.length > 0) {
      if (resp.result[0] && resp.result[0].status === "Success") {
        dispatch({
          type: "GET_EXPORT_EXCEL_SUCCESS",
          payload: resp,
        });
      } else {
        dispatch({
          type: "GET_EXPORT_EXCEL_FAIL",
          payload:
            resp.result[0].description || "Error While Getting Export Excel.",
        });
      }
    } else {
      dispatch({
        type: "GET_EXPORT_EXCEL_FAIL",
        payload: "Error While Getting Export Excel.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Getting Export Excel.";
    dispatch({
      type: "GET_EXPORT_EXCEL_FAIL",
      payload: error,
    });
  }
};
//----------------------**** ImportExcel ****---------------
export const getImportExcel = (requestData) => async (dispatch) => {
  dispatch({
    type: "GET_IMPORT_EXCEL_INIT",
  });
  const url = "/DPFAPI/BankRecRequest";
  let obj = {
    actionType: "ImportExcel",
    ...requestData,
  };

  try {
    let response = await axiosDPFAPIClient.post(url, obj);
    let resp =
      (response && response.data && response.data.BankRequest_response) || "";
    if (resp && resp.result.length > 0) {
      if (resp.result[0] && resp.result[0].status === "Success") {
        dispatch({
          type: "GET_IMPORT_EXCEL_SUCCESS",
          payload: resp,
        });
      } else {
        dispatch({
          type: "GET_IMPORT_EXCEL_FAIL",
          payload:
            resp.result[0].description || "Error While Getting Import Excel.",
        });
      }
    } else {
      dispatch({
        type: "GET_IMPORT_EXCEL_FAIL",
        payload: "Error While Getting Import Excel.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Getting Import Excel.";
    dispatch({
      type: "GET_IMPORT_EXCEL_FAIL",
      payload: error,
    });
  }
};
//----------------------**** Prime Cancel ReIssue ****---------------
export const getPrimeCancelReIssue = () => async (dispatch) => {
  dispatch({
    type: "GET_PRIME_CANCEL_RE_ISSUE_INIT",
  });
  const url = "/DPFAPI/BankRecRequest";
  let obj = {
    actionType: "PrimeCancelReIssue",
  };

  try {
    let response = await axiosDPFAPIClient.post(url, obj);
    let resp =
      (response && response.data && response.data.BankRequest_response) || "";
    if (resp && resp.result.length > 0) {
      if (resp.result[0] && resp.result[0].status === "Success") {
        dispatch({
          type: "GET_PRIME_CANCEL_RE_ISSUE_SUCCESS",
          payload: resp,
        });
      } else {
        dispatch({
          type: "GET_PRIME_CANCEL_RE_ISSUE_FAIL",
          payload:
            resp.result[0].description ||
            "Error While Getting Prime Cancel Re Issue.",
        });
      }
    } else {
      dispatch({
        type: "GET_PRIME_CANCEL_RE_ISSUE_FAIL",
        payload: "Error While Getting Prime Cancel Re Issue.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Getting Prime Cancel Re Issue.";
    dispatch({
      type: "GET_PRIME_CANCEL_RE_ISSUE_FAIL",
      payload: error,
    });
  }
};
//----------------------**** Prime Create Journal****----------
export const getPrimeCreateJournal = () => async (dispatch) => {
  dispatch({
    type: "GET_PRIME_CREATE_JOURNAL_INIT",
  });
  const url = "/DPFAPI/BankRecRequest";
  let obj = {
    actionType: "PrimeCreateJournal",
  };

  try {
    let response = await axiosDPFAPIClient.post(url, obj);
    let resp =
      (response && response.data && response.data.BankRequest_response) || "";
    if (resp && resp.result.length > 0) {
      if (resp.result[0] && resp.result[0].status === "Success") {
        dispatch({
          type: "GET_PRIME_CREATE_JOURNAL_SUCCESS",
          payload: resp,
        });
      } else {
        dispatch({
          type: "GET_PRIME_CREATE_JOURNAL_FAIL",
          payload:
            resp.result[0].description ||
            "Error While Getting Prime Create Journal.",
        });
      }
    } else {
      dispatch({
        type: "GET_PRIME_CREATE_JOURNAL_FAIL",
        payload: "Error While Getting Prime Create Journal.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Getting Prime Create Journal.";
    dispatch({
      type: "GET_PRIME_CREATE_JOURNAL_FAIL",
      payload: error,
    });
  }
};
//----------------------**** Create Journal ****---------------
export const createJournal = (data) => async (dispatch) => {
  dispatch({
    type: "CREATE_JOURNAL_INIT",
  });
  const url = "/DPFAPI/BankRecRequest";
  let obj = {
    actionType: "CreateJournal",
    ...data,
  };

  try {
    let response = await axiosDPFAPIClient.post(url, obj);
    let resp =
      (response && response.data && response.data.BankRequest_response) || "";
    if (resp && resp.result.length > 0) {
      if (resp.result[0] && resp.result[0].status === "Success") {
        dispatch({
          type: "CREATE_JOURNAL_SUCCESS",
          payload: resp,
        });
      } else {
        dispatch({
          type: "CREATE_JOURNAL_FAIL",
          payload:
            resp.result[0].description || "Error While Creating Journal.",
        });
      }
    } else {
      dispatch({
        type: "CREATE_JOURNAL_FAIL",
        payload: "Error While Creating Journal.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Creating Journal.";
    dispatch({
      type: "CREATE_JOURNAL_FAIL",
      payload: error,
    });
  }
};
//----------------------**** Reconcile Bank ****---------------
export const getReconcileBank = (bankCode) => async (dispatch) => {
  dispatch({
    type: "GET_RECONCILE_BANK_INIT",
  });
  const url = "/DPFAPI/BankRecRequest";
  let obj = {
    actionType: "ReconcileBank",
    bankCode,
  };

  try {
    let response = await axiosDPFAPIClient.post(url, obj);
    let resp =
      (response && response.data && response.data.BankRequest_response) || "";
    if (resp && resp.result.length > 0) {
      if (resp.result[0] && resp.result[0].status === "Success") {
        dispatch({
          type: "GET_RECONCILE_BANK_SUCCESS",
          payload: resp,
        });
      } else {
        dispatch({
          type: "GET_RECONCILE_BANK_FAIL",
          payload:
            resp.result[0].description || "Error While Getting Reconcile Bank.",
        });
      }
    } else {
      dispatch({
        type: "GET_RECONCILE_BANK_FAIL",
        payload: "Error While Getting Reconcile Bank.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Getting Reconcile Bank.";
    dispatch({
      type: "GET_RECONCILE_BANK_FAIL",
      payload: error,
    });
  }
};
//----------------------**** Reconcile Adjustments ****---------------
export const getReconcileAdjustments = (bankCode) => async (dispatch) => {
  dispatch({
    type: "GET_RECONCILE_ADJUSTMENTS_INIT",
  });
  const url = "/DPFAPI/BankRecRequest";
  let obj = {
    actionType: "ReconcileAdjustments",
    bankCode,
  };

  try {
    let response = await axiosDPFAPIClient.post(url, obj);
    let resp =
      (response && response.data && response.data.BankRequest_response) || "";
    if (resp && resp.result.length > 0) {
      if (resp.result[0] && resp.result[0].status === "Success") {
        dispatch({
          type: "GET_RECONCILE_ADJUSTMENTS_SUCCESS",
          payload: resp,
        });
      } else {
        dispatch({
          type: "GET_RECONCILE_ADJUSTMENTS_FAIL",
          payload:
            resp.result[0].description ||
            "Error While Getting Reconcile Adjustments.",
        });
      }
    } else {
      dispatch({
        type: "GET_RECONCILE_ADJUSTMENTS_FAIL",
        payload: "Error While Getting Reconcile Adjustments.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Getting Reconcile Adjustments.";
    dispatch({
      type: "GET_RECONCILE_ADJUSTMENTS_FAIL",
      payload: error,
    });
  }
};
//----------------------**** Post Bank ****---------------
export const getPostBank = (bankCode) => async (dispatch) => {
  dispatch({
    type: "GET_POST_BANK_INIT",
  });
  const url = "/DPFAPI/BankRecRequest";
  let obj = {
    actionType: "PostBank",
    bankCode: bankCode,
  };

  try {
    let response = await axiosDPFAPIClient.post(url, obj);
    let resp =
      (response && response.data && response.data.BankRequest_response) || "";
    if (resp && resp.result.length > 0) {
      if (resp.result[0] && resp.result[0].status === "Success") {
        dispatch({
          type: "GET_POST_BANK_SUCCESS",
          payload: resp,
        });
      } else {
        dispatch({
          type: "GET_POST_BANK_FAIL",
          payload:
            resp.result[0].description || "Error While Getting Post Bank.",
        });
      }
    } else {
      dispatch({
        type: "GET_POST_BANK_FAIL",
        payload: "Error While Getting Post Bank.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Getting Post Bank.";
    dispatch({
      type: "GET_POST_BANK_FAIL",
      payload: error,
    });
  }
};
//----------------------****  Financial Requests ****-------------------
//----------------------**** GetLayoutOptions***------------------------
export const getLayoutOptions = () => async (dispatch) => {
  dispatch({
    type: "GET_LAYOUT_OPTIONS_INIT",
  });
  const url = "/DPFAPI/FinancialRequest";
  let obj = {
    actionType: "GetLayoutOptions",
  };

  try {
    let response = await axiosDPFAPIClient.post(url, obj);
    let resp =
      (response && response.data && response.data.FinancialRequest_response) ||
      "";
    if (resp && resp.result.length > 0) {
      if (resp.result[0] && resp.result[0].status === "Success") {
        dispatch({
          type: "GET_LAYOUT_OPTIONS_SUCCESS",
          payload: resp,
        });
      } else {
        dispatch({
          type: "GET_LAYOUT_OPTIONS_FAIL",
          payload:
            resp.result[0].description || "Error While Getting Layout Options.",
        });
      }
    } else {
      dispatch({
        type: "GET_LAYOUT_OPTIONS_FAIL",
        payload: "Error While Getting Layout Options.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Getting Layout Options.";
    dispatch({
      type: "GET_LAYOUT_OPTIONS_FAIL",
      payload: error,
    });
  }
};
//----------------------**** getFinancialLayout ****------------------------
export const getFinancialLayout = (data) => async (dispatch) => {
  dispatch({
    type: "GET_FINANCIAL_LAYOUT_INIT",
  });
  const url = "/DPFAPI/FinancialRequest";
  let obj = {
    actionType: "GetFinancialLayout",
    ...data,
  };

  try {
    let response = await axiosDPFAPIClient.post(url, obj);
    let resp =
      (response && response.data && response.data.FinancialRequest_response) ||
      "";
    if (resp && resp.result.length > 0) {
      if (resp.result[0] && resp.result[0].status === "Success") {
        dispatch({
          type: "GET_FINANCIAL_LAYOUT_SUCCESS",
          payload: resp,
        });
      } else {
        dispatch({
          type: "GET_FINANCIAL_LAYOUT_FAIL",
          payload:
            resp.result[0].description ||
            "Error While Getting Financial Layout.",
        });
      }
    } else {
      dispatch({
        type: "GET_FINANCIAL_LAYOUT_FAIL",
        payload: "Error While Getting Financial Layout.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Getting Financial Layout.";
    dispatch({
      type: "GET_FINANCIAL_LAYOUT_FAIL",
      payload: error,
    });
  }
};
//----------------------**** saveFinancialLayout***------------------------
export const saveFinancialLayout = (data) => async (dispatch) => {
  dispatch({
    type: "SAVE_FINANCIAL_LAYOUT_INIT",
  });
  const url = "/DPFAPI/FinancialRequest";
  let obj = {
    actionType: "SaveFinancialLayout",
    ...data,
  };

  try {
    let response = await axiosDPFAPIClient.post(url, obj);
    let resp =
      (response && response.data && response.data.FinancialRequest_response) ||
      "";
    if (resp && resp.result.length > 0) {
      if (resp.result[0] && resp.result[0].status === "Success") {
        dispatch({
          type: "SAVE_FINANCIAL_LAYOUT_SUCCESS",
          payload: resp,
        });
      } else {
        dispatch({
          type: "SAVE_FINANCIAL_LAYOUT_FAIL",
          payload:
            resp.result[0].description ||
            "Error While Saving Financial Layout.",
        });
      }
    } else {
      dispatch({
        type: "SAVE_FINANCIAL_LAYOUT_FAIL",
        payload: "Error While Saving Financial Layout.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Saving Financial Layout.";
    dispatch({
      type: "SAVE_FINANCIAL_LAYOUT_FAIL",
      payload: error,
    });
  }
};
//----------------------**** getColumnOptionDetails ***------------------------
export const getColumnOptionDetails = (data) => async (dispatch) => {
  dispatch({
    type: "GET_COLUMN_OPTION_DETAILS_INIT",
  });
  const url = "/DPFAPI/FinancialRequest";
  let obj = {
    actionType: "GetColumnOptionDetails",
    ...data,
  };

  try {
    let response = await axiosDPFAPIClient.post(url, obj);
    let resp =
      (response && response.data && response.data.FinancialRequest_response) ||
      "";
    if (resp && resp.result.length > 0) {
      if (resp.result[0] && resp.result[0].status === "Success") {
        dispatch({
          type: "GET_COLUMN_OPTION_DETAILS_SUCCESS",
          payload: resp,
        });
      } else {
        dispatch({
          type: "GET_COLUMN_OPTION_DETAILS_FAIL",
          payload:
            resp.result[0].description ||
            "Error While Getting Column Option Details.",
        });
      }
    } else {
      dispatch({
        type: "GET_COLUMN_OPTION_DETAILS_FAIL",
        payload: "Error While Getting Column Option Details.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Getting Column Option Details.";
    dispatch({
      type: "GET_COLUMN_OPTION_DETAILS_FAIL",
      payload: error,
    });
  }
};
//----------------------**** primeColumnOptionDetails ***------------------------
export const primeColumnOptionDetails = () => async (dispatch) => {
  dispatch({
    type: "PRIME_COLUMN_OPTION_DETAILS_INIT",
  });
  const url = "/DPFAPI/FinancialRequest";
  let obj = {
    actionType: "PrimeColumnOptionDetails",
  };

  try {
    let response = await axiosDPFAPIClient.post(url, obj);
    let resp =
      (response && response.data && response.data.FinancialRequest_response) ||
      "";
    if (resp && resp.result.length > 0) {
      if (resp.result[0] && resp.result[0].status === "Success") {
        dispatch({
          type: "PRIME_COLUMN_OPTION_DETAILS_SUCCESS",
          payload: resp,
        });
      } else {
        dispatch({
          type: "PRIME_COLUMN_OPTION_DETAILS_FAIL",
          payload:
            resp.result[0].description ||
            "Error While Getting Prime Column Option Details.",
        });
      }
    } else {
      dispatch({
        type: "PRIME_COLUMN_OPTION_DETAILS_FAIL",
        payload: "Error While Getting Prime Column Option Details.",
      });
    }
  } catch (err) {
    const error =
      err.message || "Error While Getting Prime Column Option Details.";
    dispatch({
      type: "PRIME_COLUMN_OPTION_DETAILS_FAIL",
      payload: error,
    });
  }
};
//----------------------**** addColumnOptionDetails ***------------------------
export const addColumnOptionDetails = (data) => async (dispatch) => {
  dispatch({
    type: "ADD_COLUMN_OPTION_DETAILS_INIT",
  });
  const url = "/DPFAPI/FinancialRequest";
  let obj = {
    actionType: "AddColumnOptionDetails",
    ...data,
  };

  try {
    let response = await axiosDPFAPIClient.post(url, obj);
    let resp =
      (response && response.data && response.data.FinancialRequest_response) ||
      "";
    if (resp && resp.result.length > 0) {
      if (resp.result[0] && resp.result[0].status === "Success") {
        dispatch({
          type: "ADD_COLUMN_OPTION_DETAILS_SUCCESS",
          payload: resp,
        });
      } else {
        dispatch({
          type: "ADD_COLUMN_OPTION_DETAILS_FAIL",
          payload:
            resp.result[0].description ||
            "Error While Getting Adding Column Option Details.",
        });
      }
    } else {
      dispatch({
        type: "ADD_COLUMN_OPTION_DETAILS_FAIL",
        payload: "Error While Getting Adding Column Option Details.",
      });
    }
  } catch (err) {
    const error =
      err.message || "Error While Getting Adding Column Option Details.";
    dispatch({
      type: "ADD_COLUMN_OPTION_DETAILS_FAIL",
      payload: error,
    });
  }
};
//----------------------**** saveColumnOptionDetails ***------------------------
export const saveColumnOptionDetails = (data) => async (dispatch) => {
  dispatch({
    type: "SAVE_COLUMN_OPTION_DETAILS_INIT",
  });
  const url = "/DPFAPI/FinancialRequest";
  let obj = {
    actionType: "SaveColumnOptionDetails",
    ...data,
  };

  try {
    let response = await axiosDPFAPIClient.post(url, obj);
    let resp =
      (response && response.data && response.data.FinancialRequest_response) ||
      "";
    if (resp && resp.result.length > 0) {
      if (resp.result[0] && resp.result[0].status === "Success") {
        dispatch({
          type: "SAVE_COLUMN_OPTION_DETAILS_SUCCESS",
          payload: resp,
        });
      } else {
        dispatch({
          type: "SAVE_COLUMN_OPTION_DETAILS_FAIL",
          payload:
            resp.result[0].description ||
            "Error While Saving Column Option Details.",
        });
      }
    } else {
      dispatch({
        type: "SAVE_COLUMN_OPTION_DETAILS_FAIL",
        payload: "Error While Saving Column Option Details.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Saving Column Option Details.";
    dispatch({
      type: "SAVE_COLUMN_OPTION_DETAILS_FAIL",
      payload: error,
    });
  }
};
//----------------------**** getLayoutColumns ***------------------------
export const getLayoutColumns = () => async (dispatch) => {
  dispatch({
    type: "GET_LAYOUT_COLUMNS_INIT",
  });
  const url = "/DPFAPI/FinancialRequest";
  let obj = {
    actionType: "GetLayoutColumns",
  };

  try {
    let response = await axiosDPFAPIClient.post(url, obj);
    let resp =
      (response && response.data && response.data.FinancialRequest_response) ||
      "";
    if (resp && resp.result.length > 0) {
      if (resp.result[0] && resp.result[0].status === "Success") {
        dispatch({
          type: "GET_LAYOUT_COLUMNS_SUCCESS",
          payload: resp,
        });
      } else {
        dispatch({
          type: "GET_LAYOUT_COLUMNS_FAIL",
          payload:
            resp.result[0].description || "Error While Getting Layout Columns.",
        });
      }
    } else {
      dispatch({
        type: "GET_LAYOUT_COLUMNS_FAIL",
        payload: "Error While Getting Layout Columns.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Getting Layout Columns.";
    dispatch({
      type: "GET_LAYOUT_COLUMNS_FAIL",
      payload: error,
    });
  }
};
//----------------------**** primeFinancialLayout ***------------------------
export const primeFinancialLayout = () => async (dispatch) => {
  dispatch({
    type: "PRIME_FINANCIAL_LAYOUT_INIT",
  });
  const url = "/DPFAPI/FinancialRequest";
  let obj = {
    actionType: "PrimeFinancialLayout",
  };

  try {
    let response = await axiosDPFAPIClient.post(url, obj);
    let resp =
      (response && response.data && response.data.FinancialRequest_response) ||
      "";
    if (resp && resp.result.length > 0) {
      if (resp.result[0] && resp.result[0].status === "Success") {
        dispatch({
          type: "PRIME_FINANCIAL_LAYOUT_SUCCESS",
          payload: resp,
        });
      } else {
        dispatch({
          type: "PRIME_FINANCIAL_LAYOUT_FAIL",
          payload:
            resp.result[0].description ||
            "Error While Getting Prime Financial Layout.",
        });
      }
    } else {
      dispatch({
        type: "PRIME_FINANCIAL_LAYOUT_FAIL",
        payload: "Error While Getting Prime Financial Layout.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Getting Prime Financial Layout.";
    dispatch({
      type: "PRIME_FINANCIAL_LAYOUT_FAIL",
      payload: error,
    });
  }
};
//----------------------**** createFinancialLayout ***------------------------
export const createFinancialLayout = (data) => async (dispatch) => {
  dispatch({
    type: "CREATE_FINANCIAL_LAYOUT_INIT",
  });
  const url = "/DPFAPI/FinancialRequest";
  let obj = {
    actionType: "CreateFinancialLayout",
    ...data,
  };

  try {
    let response = await axiosDPFAPIClient.post(url, obj);
    let resp =
      (response && response.data && response.data.FinancialRequest_response) ||
      "";
    if (resp && resp.result.length > 0) {
      if (resp.result[0] && resp.result[0].status === "Success") {
        dispatch({
          type: "CREATE_FINANCIAL_LAYOUT_SUCCESS",
          payload: resp,
        });
      } else {
        dispatch({
          type: "CREATE_FINANCIAL_LAYOUT_FAIL",
          payload:
            resp.result[0].description ||
            "Error While Creating Financial Layout.",
        });
      }
    } else {
      dispatch({
        type: "CREATE_FINANCIAL_LAYOUT_FAIL",
        payload: "Error While Creating Financial Layout.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Creating Financial Layout.";
    dispatch({
      type: "CREATE_FINANCIAL_LAYOUT_FAIL",
      payload: error,
    });
  }
};
//----------------------**** deleteFinancialLayout ***------------------------
export const deleteFinancialLayout = (data) => async (dispatch) => {
  dispatch({
    type: "DELETE_FINANCIAL_LAYOUT_INIT",
  });
  const url = "/DPFAPI/FinancialRequest";
  let obj = {
    actionType: "DeleteFinancialLayout",
    ...data,
  };

  try {
    let response = await axiosDPFAPIClient.post(url, obj);
    let resp =
      (response && response.data && response.data.FinancialRequest_response) ||
      "";
    if (resp && resp.result.length > 0) {
      if (resp.result[0] && resp.result[0].status === "Success") {
        dispatch({
          type: "DELETE_FINANCIAL_LAYOUT_SUCCESS",
          payload: resp,
        });
      } else {
        dispatch({
          type: "DELETE_FINANCIAL_LAYOUT_FAIL",
          payload:
            resp.result[0].description ||
            "Error While Deleting Financial Layout.",
        });
      }
    } else {
      dispatch({
        type: "DELETE_FINANCIAL_LAYOUT_FAIL",
        payload: "Error While Deleting Financial Layout.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Deleting Financial Layout.";
    dispatch({
      type: "DELETE_FINANCIAL_LAYOUT_FAIL",
      payload: error,
    });
  }
};
//----------------------**** getPrimeImportBudget ***------------------------
export const getPrimeImportBudget = () => async (dispatch) => {
  dispatch({
    type: "GET_PRIME_IMPORT_BUDGET_INIT",
  });
  const url = "/DPFAPI/FinancialRequest";
  let obj = {
    actionType: "PrimeImportBudget",
  };

  try {
    let response = await axiosDPFAPIClient.post(url, obj);
    let resp =
      (response && response.data && response.data.FinancialRequest_response) ||
      "";
    if (resp && resp.result.length > 0) {
      if (resp.result[0] && resp.result[0].status === "Success") {
        dispatch({
          type: "GET_PRIME_IMPORT_BUDGET_SUCCESS",
          payload: resp,
        });
      } else {
        dispatch({
          type: "GET_PRIME_IMPORT_BUDGET_FAIL",
          payload:
            resp.result[0].description ||
            "Error While Getting Prime Import Budget.",
        });
      }
    } else {
      dispatch({
        type: "GET_PRIME_IMPORT_BUDGET_FAIL",
        payload: "Error While Getting Prime Import Budget.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Getting Prime Import Budget.";
    dispatch({
      type: "GET_PRIME_IMPORT_BUDGET_FAIL",
      payload: error,
    });
  }
};
//----------------------**** getPrimeImportBudget ***------------------------
export const generateFinancials = (data) => async (dispatch) => {
  dispatch({
    type: "GENERATE_FINANCIALS_INIT",
  });
  const url = "/DPFAPI/FinancialRequest";
  let obj = {
    actionType: "GenerateFinancials",
    ...data,
  };
  try {
    let response = await axiosDPFAPIClient.post(url, obj);
    let resp =
      (response && response.data && response.data.FinancialRequest_response) ||
      "";
    if (resp && resp.result.length > 0) {
      if (resp.result[0] && resp.result[0].status === "Failed") {
        dispatch({
          type: "GENERATE_FINANCIALS_FAIL",
          payload:
            resp.result[0].description || "Error While Generating Table.",
        });
      }
      if (resp.result[0] && resp.result[0].status === "Success") {
        dispatch({
          type: "GENERATE_FINANCIALS_SUCCESS",
          payload: resp,
        });
      }
    } else {
      dispatch({
        type: "GENERATE_FINANCIALS_FAIL",
        payload: "Error While Generating Table.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Generating Table.";
    dispatch({
      type: "GENERATE_FINANCIALS_FAIL",
      payload: error,
    });
  }

  // try {
  //   let response = await axiosClient.post(url, obj);
  //   let resp =
  //     (response && response.data && response.data.FinancialRequest_response) ||
  //     "";

  //   if (resp && resp.result.length > 0) {
  //     if (resp.result[0] && resp.result[0].status === "Success") {
  //       dispatch({
  //         type: "GENERATE_FINANCIALS_SUCCESS",
  //         payload: resp,
  //       });
  //     } else {
  //       dispatch({
  //         type: "GENERATE_FINANCIALS_FAIL",
  //         payload:
  //           resp.result[0].description || "Error While Generating Table.",
  //       });
  //     }
  //   } else {
  //     dispatch({
  //       type: "GENERATE_FINANCIALS_FAIL",
  //       payload: "Error While Generating Table.",
  //     });
  //   }
  // } catch (err) {
  //   const error = err.message || "Error While Generating Table.";
  //   dispatch({
  //     type: "GENERATE_FINANCIALS_FAIL",
  //     payload: error,
  //   });
  // }
};
//----------------------**** getFromHyperLink ***------------------------
export const getFromHyperLink = (data) => async (dispatch) => {
  dispatch({
    type: "GET_FROM_HYPER_LINK_INIT",
  });
  const url = "/DPFAPI/FinancialRequest";
  let obj = {
    actionType: "GetFromHyperLink",
    ...data,
  };

  try {
    let response = await axiosDPFAPIClient.post(url, obj);
    let resp =
      (response && response.data && response.data.FinancialRequest_response) ||
      "";
    if (resp && resp.result.length > 0) {
      if (resp.result[0] && resp.result[0].status === "Success") {
        dispatch({
          type: "GET_FROM_HYPER_LINK_SUCCESS",
          payload: resp,
        });
      } else {
        dispatch({
          type: "GET_FROM_HYPER_LINK_FAIL",
          payload:
            resp.result[0].description || "Error While Getting From HyperLink.",
        });
      }
    } else {
      dispatch({
        type: "GET_FROM_HYPER_LINK_FAIL",
        payload: "Error While Getting From HyperLink.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Getting From HyperLink.";
    dispatch({
      type: "GET_FROM_HYPER_LINK_FAIL",
      payload: error,
    });
  }
};
//----------------------**** updateFromHyperLink ***------------------------
export const updateFromHyperLink = (data) => async (dispatch) => {
  dispatch({
    type: "UPDATE_FROM_HYPER_LINK_INIT",
  });
  const url = "/DPFAPI/FinancialRequest";
  let obj = {
    actionType: "UpdateFromHyperLink",
    ...data,
  };

  try {
    let response = await axiosDPFAPIClient.post(url, obj);
    let resp =
      (response && response.data && response.data.FinancialRequest_response) ||
      "";
    if (resp && resp.result.length > 0) {
      if (resp.result[0] && resp.result[0].status === "Success") {
        dispatch({
          type: "UPDATE_FROM_HYPER_LINK_SUCCESS",
          payload: resp,
        });
      } else {
        dispatch({
          type: "UPDATE_FROM_HYPER_LINK_FAIL",
          payload:
            resp.result[0].description ||
            "Error While Updating From HyperLink.",
        });
      }
    } else {
      dispatch({
        type: "UPDATE_FROM_HYPER_LINK_FAIL",
        payload: "Error While Updating From HyperLink.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Updating From HyperLink.";
    dispatch({
      type: "UPDATE_FROM_HYPER_LINK_FAIL",
      payload: error,
    });
  }
};
//----------------------**** updateFinancials ***------------------------
export const updateFinancials = (data) => async (dispatch) => {
  dispatch({
    type: "UPDATE_FINANCIALS_INIT",
  });
  const url = "/DPFAPI/FinancialRequest";
  let obj = {
    actionType: "UpdateFinancials",
    ...data,
  };

  try {
    let response = await axiosDPFAPIClient.post(url, obj);
    let resp =
      (response && response.data && response.data.FinancialRequest_response) ||
      "";
    if (resp && resp.result.length > 0) {
      if (resp.result[0] && resp.result[0].status === "Success") {
        dispatch({
          type: "UPDATE_FINANCIALS_SUCCESS",
          payload: resp,
        });
      } else {
        dispatch({
          type: "UPDATE_FINANCIALS_FAIL",
          payload:
            resp.result[0].description || "Error While Updating Financials.",
        });
      }
    } else {
      dispatch({
        type: "UPDATE_FINANCIALS_FAIL",
        payload: "Error While Updating Financials.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Updating Financials.";
    dispatch({
      type: "UPDATE_FINANCIALS_FAIL",
      payload: error,
    });
  }
};
//----------------------**** exportExcel ***------------------------
export const exportExcel = (data) => async (dispatch) => {
  dispatch({
    type: "EXPORT_EXCEL_INIT",
  });
  const url = "/DPFAPI/FinancialRequest";
  let obj = {
    actionType: "ExportExcel",
    ...data,
  };

  try {
    let response = await axiosDPFAPIClient.post(url, obj);
    let resp =
      (response && response.data && response.data.FinancialRequest_response) ||
      "";
    if (resp && resp.result.length > 0) {
      if (resp.result[0] && resp.result[0].status === "Success") {
        dispatch({
          type: "EXPORT_EXCEL_SUCCESS",
          payload: resp,
        });
      } else {
        dispatch({
          type: "EXPORT_EXCEL_FAIL",
          payload: resp.result[0].description || "Error While Exporting Excel.",
        });
      }
    } else {
      dispatch({
        type: "EXPORT_EXCEL_FAIL",
        payload: "Error While Exporting Excel.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Exporting Excel.";
    dispatch({
      type: "EXPORT_EXCEL_FAIL",
      payload: error,
    });
  }
};
//----------------------**** importExcel ***------------------------
export const importExcel = (requestData) => async (dispatch) => {
  dispatch({
    type: "IMPORT_EXCEL_INIT",
  });
  const url = "/DPFAPI/FinancialRequest";
  let obj = {
    actionType: "ImportExcel",
    ...requestData,
  };

  try {
    let response = await axiosDPFAPIClient.post(url, obj);
    let resp =
      (response && response.data && response.data.FinancialRequest_response) ||
      "";
    if (resp && resp.result.length > 0) {
      if (resp.result[0] && resp.result[0].status === "Success") {
        dispatch({
          type: "IMPORT_EXCEL_SUCCESS",
          payload: resp,
        });
      } else {
        dispatch({
          type: "IMPORT_EXCEL_FAIL",
          payload: resp.result[0].description || "Error While Importing Excel.",
        });
      }
    } else {
      dispatch({
        type: "IMPORT_EXCEL_FAIL",
        payload: "Error While Importing Excel.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Importing Excel.";
    dispatch({
      type: "IMPORT_EXCEL_FAIL",
      payload: error,
    });
  }
};
//----------------------**** primeImportBudget ***------------------------
export const primeImportBudget = () => async (dispatch) => {
  dispatch({
    type: "PRIME_IMPORT_BUDGET_INIT",
  });
  const url = "/DPFAPI/FinancialRequest";
  let obj = {
    actionType: "PrimeImportBudget",
  };

  try {
    let response = await axiosDPFAPIClient.post(url, obj);
    let resp =
      (response && response.data && response.data.FinancialRequest_response) ||
      "";
    if (resp && resp.result.length > 0) {
      if (resp.result[0] && resp.result[0].status === "Success") {
        dispatch({
          type: "PRIME_IMPORT_BUDGET_SUCCESS",
          payload: resp,
        });
      } else {
        dispatch({
          type: "PRIME_IMPORT_BUDGET_FAIL",
          payload:
            resp.result[0].description ||
            "Error While Getting Prime Import Budget.",
        });
      }
    } else {
      dispatch({
        type: "PRIME_IMPORT_BUDGET_FAIL",
        payload: "Error While Getting Prime Import Budget.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Getting Prime Import Budget.";
    dispatch({
      type: "PRIME_IMPORT_BUDGET_FAIL",
      payload: error,
    });
  }
};
//----------------------**** importBudget ***------------------------
export const importBudget = (data) => async (dispatch) => {
  dispatch({
    type: "IMPORT_BUDGET_INIT",
  });
  const url = "/DPFAPI/FinancialRequest";
  let obj = {
    actionType: "ImportBudget",
    ...data,
  };

  try {
    let response = await axiosDPFAPIClient.post(url, obj);
    let resp =
      (response && response.data && response.data.FinancialRequest_response) ||
      "";
    if (resp && resp.result.length > 0) {
      if (resp.result[0] && resp.result[0].status === "Success") {
        dispatch({
          type: "IMPORT_BUDGET_SUCCESS",
          payload: resp,
        });
      } else {
        dispatch({
          type: "IMPORT_BUDGET_FAIL",
          payload:
            resp.result[0].description || "Error While Getting Import Budget.",
        });
      }
    } else {
      dispatch({
        type: "IMPORT_BUDGET_FAIL",
        payload: "Error While Getting Import Budget.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Getting Import Budget.";
    dispatch({
      type: "IMPORT_BUDGET_FAIL",
      payload: error,
    });
  }
};
//----------------------**** updateBudgetImport ***------------------------
export const updateBudgetImport = (data) => async (dispatch) => {
  dispatch({
    type: "UPDATE_BUDGET_IMPORT_INIT",
  });
  const url = "/DPFAPI/FinancialRequest";
  let obj = {
    actionType: "UpdateBudgetImport",
    ...data,
  };

  try {
    let response = await axiosDPFAPIClient.post(url, obj);
    let resp =
      (response && response.data && response.data.FinancialRequest_response) ||
      "";
    if (resp && resp.result.length > 0) {
      if (resp.result[0] && resp.result[0].status === "Success") {
        dispatch({
          type: "UPDATE_BUDGET_IMPORT_SUCCESS",
          payload: resp,
        });
      } else {
        dispatch({
          type: "UPDATE_BUDGET_IMPORT_FAIL",
          payload:
            resp.result[0].description || "Error While Updating Budget Import.",
        });
      }
    } else {
      dispatch({
        type: "UPDATE_BUDGET_IMPORT_FAIL",
        payload: "Error While Updating Budget Import.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Updating Budget Import.";
    dispatch({
      type: "UPDATE_BUDGET_IMPORT_FAIL",
      payload: error,
    });
  }
};
//----------------------**** deleteBudgetImport ***------------------------
export const deleteBudgetImport = () => async (dispatch) => {
  dispatch({
    type: "DELETE_BUDGET_IMPORT_INIT",
  });
  const url = "/DPFAPI/FinancialRequest";
  let obj = {
    actionType: "DeleteBudgetImport",
  };

  try {
    let response = await axiosDPFAPIClient.post(url, obj);
    let resp =
      (response && response.data && response.data.FinancialRequest_response) ||
      "";
    if (resp && resp.result.length > 0) {
      if (resp.result[0] && resp.result[0].status === "Success") {
        dispatch({
          type: "DELETE_BUDGET_IMPORT_SUCCESS",
          payload: resp,
        });
      } else {
        dispatch({
          type: "DELETE_BUDGET_IMPORT_FAIL",
          payload:
            resp.result[0].description || "Error While Deleting Budget Import.",
        });
      }
    } else {
      dispatch({
        type: "DELETE_BUDGET_IMPORT_FAIL",
        payload: "Error While Deleting Budget Import.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Deleting Budget Import.";
    dispatch({
      type: "DELETE_BUDGET_IMPORT_FAIL",
      payload: error,
    });
  }
};
//----------------------**** saveAndGenerateReport ***------------------------
export const saveAndGenerateReport = (data) => async (dispatch) => {
  dispatch({
    type: "SAVE_AND_GENERATE_REPORT_INIT",
  });
  const url = "/DPFAPI/FinancialRequest";
  let obj = {
    actionType: "SaveAndGenerateReport",
    layout: data.layout,
    reportId: data.recordId,
    options: data.options,
    columns: data.columns,
    categories: data.categories,
  };

  try {
    let response = await axiosDPFAPIClient.post(url, obj);
    let resp =
      (response && response.data && response.data.FinancialRequest_response) ||
      "";
    if (resp && resp.result.length > 0) {
      if (resp.result[0] && resp.result[0].status === "Success") {
        dispatch({
          type: "SAVE_AND_GENERATE_REPORT_SUCCESS",
          payload: resp,
        });
      } else {
        dispatch({
          type: "SAVE_AND_GENERATE_REPORT_FAIL",
          payload:
            resp.result[0].description ||
            "Error While Saving and Generating Report.",
        });
      }
    } else {
      dispatch({
        type: "SAVE_AND_GENERATE_REPORT_FAIL",
        payload: "Error While Saving and Generating Report.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Saving and Generating Report.";
    dispatch({
      type: "SAVE_AND_GENERATE_REPORT_FAIL",
      payload: error,
    });
  }
};
//----------------------**** userHMRCAuthorized ***------------------------
export const userHMRCAuthorized = (data) => async (dispatch) => {
  dispatch({
    type: "USER_HMRC_AUTHORIZED_INIT",
  });
  const url = "/DPFAPI/VATSubmissionRequest";
  let obj = {
    actionType: "UserHMRCAuthorized",
  };

  try {
    let response = await axiosDPFAPIClient.post(url, obj);
    let resp =
      (response &&
        response.data &&
        response.data.VatSubmissionRequest_response) ||
      "";
    if (resp && resp.result.length > 0) {
      if (resp.result[0] && resp.result[0].status === "Success") {
        dispatch({
          type: "USER_HMRC_AUTHORIZED_SUCCESS",
          payload: resp,
        });
      } else {
        dispatch({
          type: "USER_HMRC_AUTHORIZED_FAIL",
          payload:
            resp.result[0].description || "Error While User HMRC Authorizing.",
        });
      }
    } else {
      dispatch({
        type: "USER_HMRC_AUTHORIZED_FAIL",
        payload: "Error While User HMRC Authorizing.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While User HMRC Authorizing.";
    dispatch({
      type: "USER_HMRC_AUTHORIZED_FAIL",
      payload: error,
    });
  }
};
//----------------------**** primeHMRCTokenAuthorization ***------------------------
export const primeHMRCTokenAuthorization = (data) => async (dispatch) => {
  dispatch({
    type: "PRIME_HMRC_TOKEN_AUTHORIZATION_INIT)",
  });
  const url = "/DPFAPI/VATSubmissionRequest";
  let obj = {
    actionType: "PrimeHMRCTokenAuthorization",
  };

  try {
    let response = await axiosDPFAPIClient.post(url, obj);
    let resp =
      (response &&
        response.data &&
        response.data.VatSubmissionRequest_response) ||
      "";
    if (resp && resp.result.length > 0) {
      if (resp.result[0] && resp.result[0].status === "Success") {
        dispatch({
          type: "PRIME_HMRC_TOKEN_AUTHORIZATION_SUCCESS",
          payload: resp,
        });
      } else {
        dispatch({
          type: "PRIME_HMRC_TOKEN_AUTHORIZATION_FAIL",
          payload:
            resp.result[0].description ||
            "Error While Getting Prime HMRC Token Authorization",
        });
      }
    } else {
      dispatch({
        type: "PRIME_HMRC_TOKEN_AUTHORIZATION_FAIL",
        payload: "Error While Getting Prime HMRC Token Authorization",
      });
    }
  } catch (err) {
    const error =
      err.message || "Error While Getting Prime HMRC Token Authorization";
    dispatch({
      type: "PRIME_HMRC_TOKEN_AUTHORIZATION_FAIL",
      payload: error,
    });
  }
};
//----------------------**** primeHMRCTokenAuthorization ***------------------------
export const authenticateHMRCUser = (data) => async (dispatch) => {
  dispatch({
    type: "AUTHENTICATE_HMRC_USER_INIT",
  });
  const url = "/DPFAPI/VATSubmissionRequest";
  let obj = {
    actionType: "AuthenticateHMRCUser",
  };

  try {
    let response = await axiosDPFAPIClient.post(url, obj);
    let resp =
      (response &&
        response.data &&
        response.data.VatSubmissionRequest_response) ||
      "";
    if (resp && resp.result.length > 0) {
      if (resp.result[0] && resp.result[0].status === "Success") {
        dispatch({
          type: "AUTHENTICATE_HMRC_USER_SUCCESS",
          payload: resp,
        });
      } else {
        dispatch({
          type: "AUTHENTICATE_HMRC_USER_FAIL",
          payload:
            resp.result[0].description ||
            "Error While Authenticating HMRC User.",
        });
      }
    } else {
      dispatch({
        type: "AUTHENTICATE_HMRC_USER_FAIL",
        payload: "Error While Authenticating HMRC User.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Authenticating HMRC User.";
    dispatch({
      type: "AUTHENTICATE_HMRC_USER_FAIL",
      payload: error,
    });
  }
};

//----------------------****Clear Reports States In Store****-----------
export function clearReportsStates() {
  return async (dispatch) => {
    dispatch({
      type: "CLEAR_REPORTS_STATES",
    });
  };
}
