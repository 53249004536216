import React, { useState } from "react";
import { useSelector } from "react-redux";

import "./FileUploadStatus.css";

const FileUploadStatus = () => {
  const { fileUploadQueue } = useSelector((state) => state.fileUpload);
  const [isMinimized, setIsMinimized] = useState(false);

  if (!fileUploadQueue || fileUploadQueue.length === 0) {
    return null;
  }

  return (
    <div
      className={`file-upload-status-wrapper ${isMinimized ? "minimized" : ""}`}
    >
      <div className="d-flex align-items-center justify-content-between mb-2">
        <h5>
          Uploading
          {isMinimized
            ? ` ${fileUploadQueue.length} file${
                fileUploadQueue.length > 1 ? "s" : ""
              }`
            : ""}
          ...
        </h5>
        <button
          className="minimize-btn"
          onClick={() => setIsMinimized(!isMinimized)}
        >
          <i
            class={`fa ${isMinimized ? "fa-expand" : "fa-minus"}`}
            aria-hidden="true"
          ></i>
        </button>
      </div>
      {!isMinimized &&
        fileUploadQueue.map((fileUpload) => (
          <div className="file-upload d-flex flex-column" key={fileUpload.id}>
            <span>{fileUpload.fileName}</span>
            <div
              className="progress"
              role="progressbar"
              aria-valuenow={fileUpload.progress}
              aria-valuemin="0"
              aria-valuemax="100"
            >
              <div
                class="progress-bar progress-bar-striped progress-bar-animated"
                style={{ width: `${fileUpload.progress}%` }}
              >
                {fileUpload.progress}%
              </div>
            </div>
          </div>
        ))}
    </div>
  );
};

export default FileUploadStatus;
