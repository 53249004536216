import React, { useState } from 'react'
import { Modal, ModalBody, ModalFooter } from "react-bootstrap";
import { groupBy } from '../../../../../Utils/Helpers';
import ModalHeader from 'react-bootstrap/esm/ModalHeader';
import ReactSelect from 'react-select';

/**
 * ApprovalGroupSettings component Shows the list of settings for each approval group.
 * @param {ApprovalGroupSettingsProps} props - The props for the component.
 * @returns {JSX.Element} The rendered component.
 */
export default function ApprovalGroupSettings(props) {

    /** @type {Array<GroupedBySettings>} */
    const grouppedApprovalSettings = groupBy(props.approverSettings, 'category', 'settings');
    const clonedProps = props.approverSettings.map(setting => ({ ...setting }));
    const [approvalSetting, setApprovalSettings] = useState(clonedProps);
    const [formErrors, setFormErrors] = useState([]);

    const getSelectProps = (options) => options.map((option) => ({ value: option.option, label: option.option }))

    /**
     *
     * @param {React.ChangeEvent} e
     * @param {Settings} approver
     */
    const onChangeField = (e, approver) => {
        let value = e?.target?.value || e?.value || '';
        const itemIndex = approvalSetting.findIndex((setting) => {
            return (setting.description === approver.description
                && setting.category === approver.category
            )
        });
        const clonedApprovalSettings = [...approvalSetting];
        const item = { ...clonedApprovalSettings[itemIndex] };
        item.value = value;
        if (e?.value && item.valueOptions?.length > 0) {
            const indexOfItem = item.valueOptions.findIndex((valueOption) => valueOption.option === e?.value);
            item.valueOptions = item.valueOptions.map((option, idx) => ({
                ...option,
                selected: idx === indexOfItem ? 'Y' : 'N'
            }));
        }
        clonedApprovalSettings[itemIndex] = item;
        setApprovalSettings(clonedApprovalSettings);
    }

    /**
     * Renders the approver settings form.
     * @param {Array<Settings>} approvers
     */
    const renderApproverForm = (approvers) => {
        return (
            approvers.map((approver, index) => {
                const itemIndex = approvalSetting.findIndex((setting) => {
                    return (setting.description === approver.description
                        && setting.category === approver.category
                    );
                });
                const selectedApprover = approvalSetting[itemIndex];
                return (
                    <div key={index}>
                        <label htmlFor={selectedApprover.description} className='form-label font-weight-bold d-block'>
                            {selectedApprover.description}
                        </label>
                        <div className="form-group">
                            {
                                selectedApprover.valueType === 'List'
                                    ? <ReactSelect
                                        className="width-selector"
                                        value={{ value: selectedApprover.value, label: selectedApprover.value }}
                                        classNamePrefix="custon_select-selector-inner"
                                        options={getSelectProps(selectedApprover.valueOptions)}
                                        onChange={(e) => {
                                            onChangeField(e, selectedApprover);
                                        }}
                                        isDisabled={selectedApprover.readOnly === "Y"}
                                    ></ReactSelect>
                                    : (
                                        <div className='d-flex'>
                                            <input type="text"
                                                className="flex-grow-1 form-input-settings"
                                                name={selectedApprover.description}
                                                value={selectedApprover.value}
                                                disabled={selectedApprover.readOnly === "Y"}
                                                onChange={(e) => {
                                                    onChangeField(e, selectedApprover);
                                                }}
                                            />
                                        </div>
                                    )
                            }
                        </div>
                    </div>
                )
            })
        )
    }

    return (
        <>
            <div>
                <Modal
                    size="sm"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={props.isShown}
                    className="mx-auto"
                >
                    <ModalHeader className='setup-bg'>
                        <div className='d-flex justify-content-between w-100'>
                            <h2 className='text-shadow-sm font-weight-bold text-white d-block w-100 text-center'>
                                Approval Group Settings
                            </h2>
                        </div>
                    </ModalHeader>
                    <ModalBody>
                        <div className="container-fluid">
                            <div>
                                {
                                    props.approverSettings.length === 0 ?
                                        <div>
                                            <p className="text-center">No Setting Available For {props.selectedApprover}</p>
                                        </div>
                                        : <></>
                                }
                                {
                                    grouppedApprovalSettings
                                        .map((approver, index) => (
                                            <div key={index}>
                                                <h4 className='text-center font-weight-bold'>{approver.category}</h4>
                                                {renderApproverForm(approver.settings)}
                                            </div>
                                        ))
                                }
                                {
                                    formErrors.map((error, index) => (
                                        <div key={index} className="text-danger error-12 ">
                                            {error.message}
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <div className="d-flex justify-content-end">
                            {
                                props.approverSettings.length === 0
                                    ? (<></>)
                                    : (<button className='btn btn-primary mx-2' onClick={(e) => {
                                        e?.preventDefault();
                                        e?.stopPropagation();
                                        props.onSave(approvalSetting);
                                    }}>
                                        Save
                                    </button>)
                            }
                            <button
                                onClick={() =>
                                    props.onClose()
                                }
                                className="btn can-btn1 mx-2"
                            >
                                Close
                            </button>
                        </div>
                    </ModalFooter>
                </Modal>
            </div>
        </>
    )
}

/**
 * @typedef {Object} ApprovalGroupSettingsProps
 * @property {Function} onClose - Function to call when the modal is closed.
 * @property {boolean} isShown - Whether the modal is shown or not.
 * @property {Array<Settings>} approverSettings - Array of approver settings.
 * @property {string} selectedApprover - The selected approver group.
 * @property {Function} onSave - Function to call when the save button is clicked.
 */

/**
 * @typedef {Object} Settings
 * @property {string} category - The category of the setting.
 * @property {string} description - The description of the setting.
 * @property {'Y'|'N'} readOnly - Whether the setting is read only or not.
 * @property {string} value - The value of the setting.
 * @property {Array<{option: string, selected: 'Y'|'N'}} valueOptions - The options for the setting, if applicable.
 * @property {'Text'|'List'|string} valueType - The type of the setting.
 */

/**
 * @typedef {Object} GroupedBySettings
 * @property {string} category
 * @property {Array<Settings>} settings - The settings for the group.
 */