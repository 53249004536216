import React, { Component } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import FileSaver from "file-saver";
import $ from "jquery";
import { Document, Page, pdfjs } from "react-pdf";
import ReactPaginate from "react-paginate";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import _ from "lodash";
import Header from "../Common/Header/Header";
import TopNav from "../Common/TopNav/TopNav";
import { connect } from "react-redux";
import store from "../../Store/index";
import { toast } from "react-toastify";
import Dropdown from "react-bootstrap/Dropdown";
import Attachments from "../Modals/Attachments/Attachments";
import Comments from "../Modals/Comments/Comments";
import Activity from "../Modals/Activity/Activity";
import Changes from "../Modals/Changes/Changes";
import Report from "../Modals/Report/Report";
import ExpenseDetails from "../Modals/ExpenseDetails/ExpenseDetails";
import ExpenseMoreDetail from "../Modals/ExpenseMoreDetail/ExpenseMoreDetail";
import Delete from "../Modals/Delete/Delete";
import CreateInvoice from "../Modals/CreateInvoice/CreateInvoice";
import Decline from "../Modals/Decline/Decline";
import Import from "../Modals/Import/Import";
import DebitCards from "../Modals/DebitCards/DebitCards";
import Move from "../Modals/Move/Move";
import Post from "../Modals/Post/Post";
import * as SupplierActions from "../../Actions/SupplierActtions/SupplierActions";
import DebitCardReconciliation from "../Modals/DebitCardReconciliation/DebitCardReconciliation";
import * as SetupActions from "../../Actions/SetupRequest/SetupAction";
import * as ExpenseActions from "../../Actions/ExpenseActions/ExpenseActions";
import * as ChartActions from "../../Actions/ChartActions/ChartActions";
import * as UserActions from "../../Actions/UserActions/UserActions";
import * as PaginationActions from "../../Actions/paginationActions/paginationActions";
import ConfirmationModal from "../Modals/Confirmation/Confirmation";
import {
  handleAPIErr,
  zoomIn,
  zoomOut,
  handleDropdownZooming,
  downloadAttachments,
  downloadZipAttachment,
  getActivatedTab,
  isSafari,
  isValidString,
  isSuccessRequest,
  getToastMessage,
  isFailedRequest
} from "../../Utils/Helpers";
import { options } from "../../Constants/Constants";
import * as Validation from "../../Utils/Validation";
import CheckApproval from "../Modals/CheckApproval/CheckApproval";
import { TransferRecords } from "../Modals/TransferRecords/TransferRecords";
import { downloadFileFromPresignedUrl, getBase64FromPreSignedS3Url, isRemoteStorage, uploadFileListToS3 } from "../../Utils/S3StorageHelper";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
const uuidv1 = require("uuid/v1");

class Expense extends Component {
  constructor(props) {
    super();
    this.state = {
      isLoading: false,
      tran: "", //tran of current selected expenses
      guid: "",
      multipleTrans: [], //when user selects multiple expenses to perform different functionality

      expenseMoreDetails: [], //contains data when click on more details link
      expenseTallies: [], //e.g Draft, Pending, Approved, etc
      expListSearch: props.pageData?.searchTerm ?? "", //search on Expenses list
      getExpenseList: [], //side menu (left side) Expenses list data
      clonedGetExpenseList: [], //a copy of  getExpenseList
      filteredExpenseList: [], //this contains filterd list and used for searching on it
      activeExpense: "", //to add class active in lists of getting Expenses (in left side )
      activeExpTallies: "", //to add class active on Expenses tallis
      showExpTalliesTabPane: "", //to add class active on Expenses tallis below tab pane
      fullyApproved: false,
      approverGroup: "",
      approverOptions: [],
      approvalsGroups: [], //list of approvals Groups checkboxes to filter Expenses list
      approvers: [], //to just show on side menuw bar
      previewList: [],
      numPages: null,
      pageNumber: 1,
      comments: [], //Expenses Comments
      attachments: [], //Expenses Attachments
      previewableAttachments: [],
      attachmentSize: 0, //default 0 Bytes,  attachments should always less than 29.5 MB
      activity: [], //Expenses Activity
      changes: [], //Expenses Changes
      taxCodeList: [],
      taxCode: { label: "Select Tax Code", value: "" },
      bankCodeList: [],
      bankCode: { label: "Select Bank Code", value: "" },
      sortFilterExp: "expenseType",
      sortFilterExpCheck: "ASC", //to check the sort is in Descendending OR descending Order  Descendending -> ASC, Descending -> DESC
      // viewTeam: "N",
      viewTeam: localStorage.getItem("teamOrders") || "N",
      teamExpCheck: "", //to check selected Expense is team Expense or not

      openAttachmentsModal: false,
      openCommentsModal: false,
      openActivityModal: false,
      openChangesModal: false,
      openReportModal: false,
      openExpenseMoreDetailsModal: false,
      openDeleteModal: false,
      openCreateInvoiceModal: false,
      openDebitCardsModal: false,
      openDeclineModal: false,
      openExpenseDetailModal: false,
      openDebitCardReconciliationModal: false,
      openMoveModal: false,
      openPostModal: false,
      openImportModal: false,

      scaling: 3.4,
      dropdownZoomingValue: { label: "40%", value: "40%" },
      toggleRightSidebar: true,
      rotate: 0,
      flags: [], //restructured flags according to select dropdown to just show in Line Items Modal ,comming from get api (tracking codes)
      clonedFlags: [], //a copy of flags

      formErrors: {
        taxCode: "",
        bankCode: "",
      },
      expenseLines: [],
      expenseCodes: [],

      batchList: [],
      batchNo: "", //batch no of current selected batch
      batchListOptions: [], //restructured batch listfor the drop-down when Move popup opens

      bankDetails: "", //supplier bank details
      currency: "", //supplier currency
      supplierCode: "", //supplier code
      expenseLineArr: [],
      flagArr: [],

      totalExpenses: "",
      pageStart: "",
      pageEnd: "",
      page: props.pageData?.pageNumber ?? 1, //The page to retrieve. Returns the first page if left as zero.
      resetPage: true,
      totalPages: "", //getNewPOList return total num of pages
      displayRecords: localStorage.getItem("orderDPR") || 10, //The number of orders to display.
      zero: false,
      close: false,

      ExpenseListSearch: props.pageData?.searchTerm ?? "",
      sortFilter: "Date",
      taxCodes: [],
      chartCodesList: [],
      clonedChartCodesList: [],
      chartCodeSuggestion: "",
      amount: 0,
      description: "",
      gross: 0,
      tax: 0,
      openCheckApprovalModal: false,
      // move approver types
      typeMove: "",
      order: "Ascend",
      allSelected: false,
      editedGuid: null,
      insertExp: null,
      supplierGuid:"",
      multipleSelectedGuid:[],
      collapseIcon:{
        attachment:false,
        approvals:false,
        download:false,
        batchList:false,
        export:false,
        activity:false,
        changes:false
      },
      showTransferRecordsModal: false,
      selectedBatchNos:[],
      clonedBatchList:[],
      selectedGuid: null,
      pageCLicked: false,
      edited:null,
      isSendForApproval:false,
      activatedTab: null,
      isModalOpen: false,
      currentAttachmentIndex: 0,
      modalContent: {
        title: '',
        body: '',
        actionButtonName: '',
        onConfirm: () => {},
      },
      isBankDetailsCollapsed: true,
      bankDetailsFetchedFor: undefined,
    };
  }
  async componentDidMount() {
    window.addEventListener(
      "resize",
      () => {
        $(".mm_ordr1").addClass("mm_order_pdfMain");

        if ($(window).width() > 991) {
          this.setState({ toggleRightSidebar: true });
        }
        if ($(window).width() <= 991) {
          this.setState({ toggleRightSidebar: false });
        }
        if ($(window).width() >= 2045) {
          $(".invoice_pdf_new").addClass("invoice_pdf_new1");
        }
        if ($(window).width() < 2045) {
          $(".invoice_pdf_new").removeClass("invoice_pdf_new1");
        }
      },
      false
    );

    let { viewTeam, sortFilterExp, sortFilterExpCheck } = this.state;
    //Team Expense Check
    viewTeam = localStorage.getItem("teamExpense") || "N";
    //getting default sorting list setting from localstorage
    sortFilterExp = localStorage.getItem("sortFilterExp") || "expenseType";
    sortFilterExpCheck = localStorage.getItem("sortFilterExpCheck") || "ASC";
    this.setState({ viewTeam, sortFilterExp, sortFilterExpCheck });

    $(document).ready(function () {
      var vw = $(window).width();
      var nav = $(".navbar.fixed-top").height();
      var underNav = $(".order-tabs").height();
      var wh = $(window).height();
      var h = wh - nav - 60;
      var rsb = wh - nav - underNav - 20;
      // var pdfDiv = wh - nav - underNav - 80
      var pdfDiv = wh - nav;
      var wh = wh - nav - underNav;
      $("#order--dynamic--height").css("height", h);
      $(".side-attachments,.side-attachments-2").css("height", rsb);
      $(".invoice_pdf_canvas.invoice_pdf_new").css("height", wh);

      $(window).resize(function () {
        var vw = $(window).width();
        // if (vw > 1930) {
        var nav = $(".navbar.fixed-top").height();
        var underNav = $(".order-tabs").height();
        var wh = $(window).height();
        var h = wh - nav - 60;
        var rsb = wh - nav - underNav - 20;
        // var pdfDiv = wh - nav - underNav - 80
        var pdfDiv = wh - nav;
        var wh = wh - nav - underNav;
        $("#order--dynamic--height").css("height", h);
        $(".side-attachments,.side-attachments-2").css("height", rsb);
        $(".invoice_pdf_canvas.invoice_pdf_new").css("height", wh);
        // }
      });
      if ($(window).width() >= 2045) {
        $(".invoice_pdf_new").addClass("invoice_pdf_new1");
      }
      if ($(window).width() < 2045) {
        $(".invoice_pdf_new").addClass("invoice_pdf_new1");
      }
    });

    $(".cus-arro-div2").on("click", function (e) {
      e.preventDefault();
      $(".invoice_carousel").toggleClass("expand_invoice_img");
    });

    $(".cus-arro-div2").on("click", function (e) {
      e.preventDefault();
      $(".order_pdf_new").toggleClass("order_left_auto");
    });
    // end
    // sideBarAccord
    $(".invoice-inherit").click(function () {
      $(".invoice-inherit .sideBarAccord1 ").toggleClass("rotate_0");
    });
    $(".bank_details").click(function () {
      $(".bank_details .sideBarAccord1 ").toggleClass("rotate_0");
    });
    $(".invoice-batchlist").click(function () {
      $(".invoice-batchlist .sideBarAccord1").toggleClass("rotate_0");
    });

    $(".export_crd").click(function () {
      $(".export_crd .sideBarAccord1").toggleClass("rotate_0");
    });
    $(".sideBarAccord").click(function () {
      $(this).toggleClass("rorate_0");
    });
    $(".sideBarAccord").dblclick(function () {
      $(this).toggleClass("rorate_0");
    });
    $(".sideBarAccord1").dblclick(function () {
      $(this).toggleClass("rorate_0");
    });
    $(".sideBarAccord2").dblclick(function () {
      $(this).toggleClass("rorate_0");
    });
    // end

    let {
      dashboard,
      tallType,
      tallies,
      addEditExpCheck,
      expTran,
      noChange,
      defaultData,
      guid,
      insertExp,
      edited
    } =
      (this.props.history &&
        this.props.history.location &&
        this.props.history.location.state) ||
      "";
    if (insertExp) {
      this.setState({ insertExp: insertExp });
    }
    if (guid) {
      this.setState({ editedGuid: guid });
    }

    if (edited){
      this.setState({ edited });
    }
    if (dashboard && tallType) {
      //when user click on Expense Tallies on Dashboard
      await this.getExpenseTallies(tallType, true); // get Expense Tallies
    } else if (
      tallies &&
      tallies === "Draft" &&
      addEditExpCheck &&
      expTran &&
      noChange &&
      defaultData
    ) {
      /*Check When Add/Edit Expense and then user Save or Cancel that edit, 
      then load the same Expense user just edited/created?.*/

      this.setState({ ...defaultData }, () => {
        // scroll to active Exp
        // var elmnt = document.getElementById(defaultData.activeExpense);
        // if (elmnt) {
        //   elmnt.scrollIntoView();
        // }
      });
    } else {
      this.getExpenseTallies("", true);
    }

    let promises = [];
    if (!this.props.user.getDefaultValues) {
      let defVals = localStorage.getItem("getDefaultValues") || "";
      defVals = defVals ? JSON.parse(defVals) : "";
      if (defVals && defVals.defaultValues) {
        //if localstorage contains the default values then update the Redux State no need to call API
        store.dispatch({
          type: "GET_DEFAULT_VALUES_SUCCESS",
          payload: defVals,
        });
      } else {
        promises.push(this.props.getDefaultValues());
      }
    }

    if (!this.props.chart.getFlags) {
      promises.push(this.props.getFlags());
    }
    promises.push(this.getBtachList());

    await Promise.all(promises);
    //success case of get default vaues
    if (this.props.user.getDefaultValuesSuccess) {
      // toast.success(this.props.user.getDefaultValuesSuccess);
    }
    //error case of get default vaues
    if (this.props.user.getDefaultValuesError) {
      handleAPIErr(this.props.user.getDefaultValuesError, this.props);
    }

    //success case of Get Flags List
    if (this.props.chart.getFlagsSuccess) {
      // toast.success(this.props.chart.getFlagsSuccess);
    }
    //error case of Get Flags List
    if (this.props.chart.getFlagsError) {
      handleAPIErr(this.props.chart.getFlagsError, this.props);
    }

    //(Tracking Codes) multiple change modal

    if (
      this.props.user.getDefaultValues.flags &&
      this.props.user.getDefaultValues.flags.length > 0
    ) {
      let flags = [];
      let clonedFlags = [];
      this.props.user.getDefaultValues.flags.map((defVal, i) => {
        flags.push(
          {
            type: defVal.prompt,
            label: defVal.prompt,
            value: "",
            id: i,
            sequence: defVal.sequence,
          },
          {
            type: defVal.prompt,
            label: "",
            value: "",
            id: i,
            sequence: defVal.sequence,
          }
        );
        clonedFlags.push({
          type: defVal.prompt,
          value: "",
          prompt: defVal.prompt,
          sequence: defVal.sequence,
        });
      });
      this.setState({ flags, clonedFlags });
    }
    this.setState({ ...this.state, resetPage: true });
  }

  componentWillUnmount() {
    if (this.state.resetPage) {
      this.props.resetLastPage("expenses");
    }
  }

  handleInvoiceTallies =async (type, check) =>{
    this.clonedBatchListGenerate();
    this.setState({ selectedBatchNos:[] });
    this.getExpenseTallies(type, check);
  }

  //get Expense talleis
  getExpenseTallies = async (type, check) => {
    this.setState({ checked: false, multipleSelectedGuid:[], allSelected: false, batchNo: "" });
    //check -> when a user Perform some actions like send for approval, Approve, Declined etc then updated Expense Tallies
    if(type && !this.state.isSendForApproval ){
      this.setState({ page: 1 })
    }

    if(this.state.isSendForApproval){
      this.setState({ isSendForApproval: false})
    }
    if(!type){
      if (this.state.activatedTab){
        type = this.state.activatedTab
      }
    }
    this.setState({  activatedTab: type })
    let isExpTallies = false; //to check if redux store contain Exp tallies then dont call API again and access the data from state
    let _expenseTallies = this.props.expenseData.expenseTallies || [];
    let _type = "";

    if (_expenseTallies.length === 0 || check) {
      await this.props.getExpenseTallies(); //get Expense Tallies
    } else {
      isExpTallies = true;
    }

    let expTally = "";
    let { activeExpTallies, showExpTalliesTabPane } = this.state;
    let expenseTalliesArr = [];

    //success case of Get Expense Tallies
    if (this.props.expenseData.getExpenseTalliesSuccess || isExpTallies) {
      // toast.success(this.props.expenseData.getExpenseTalliesSuccess);
      if (!this.state.isSendForApproval){
        this.setState({ pageCLicked: false });
      }
      let expenseTallies = this.props.expenseData.expenseTallies || [];
      let expTypes = [];

      let userType = localStorage.getItem("userType");
      userType = userType ? userType.toLowerCase() : "";

      if (userType == "operator") {
        expTypes = ["draft", "pending", "declined", "approved", "all"];
      } else if (userType == "approver") {
        expTypes = [
          "approve",
          "hold",
          "pending",
          "declined",
          "approved",
          "all",
        ];
      } else if (userType == "op/approver") {
        expTypes = [
          "draft",
          "approve",
          "hold",
          "pending",
          "declined",
          "approved",
          "all",
        ];
      }

      if (expTypes.length > 0) {
        expTypes.map((t, i) => {
          let obj = expenseTallies.find(
            (tl) => tl.type && tl.type.toLowerCase() === t
          );
          if (obj) {
            expenseTalliesArr.push(obj);
          }
        });
      } else {
        expenseTalliesArr = expenseTallies;
      }

      if (type) {
        _type = type;
      } else if (expenseTalliesArr.length > 0) {
        _type = expenseTalliesArr[0].type;
      }

      expenseTalliesArr.map(async (t, i) => {
        if (t.type === _type) {
          let id = uuidv1();
          t.id = id;
          expTally = t;
          activeExpTallies = id;
          if (type === this.state.showExpTalliesTabPane && type !== "") {
            showExpTalliesTabPane = this.state.showExpTalliesTabPane;
          } else {
            showExpTalliesTabPane = t.type;
          }
        } else {
          t.id = uuidv1();
        }
        return t;
      });
    }
    //error case of Get Expense Tallies
    if (this.props.expenseData.getExpenseTalliesError) {
      handleAPIErr(this.props.expenseData.getExpenseTalliesError, this.props);
    }
    this.setState({
      isLoading: false,
      expenseTallies: expenseTalliesArr,
      activeExpTallies,
      showExpTalliesTabPane,
    });
    if (type !== _type) {
      //to call get invoices List baseed on first invoice tallies
      this.checkPOList_API(expTally, check);
    } else {
      this.checkPOList_API(expTally, check);
    }
    this.props.clearExpenseStates();
  };

  //getting the Expense list when click on Draft || Pending || Approved etc
  getExpenseList = async (data) => {
    let activeExpense = "";
    let getExpenseList = [];
    let clonedGetExpenseList = [];
    let filteredExpenseList = [];
    this.clearStates();
    this.setState({
      isLoading: true,
      activeExpTallies: data.id,
      showExpTalliesTabPane: data.type,
      expListSearch: "",
    });
    let teamExpCheck = this.state.viewTeam;
    if (teamExpCheck) {
      data.teamExpense = teamExpCheck;
    }
    await this.props.getExpenseList(data); // get Expense list
    let firstExp = "";
    //success case of get Exp List
    if (this.props.expenseData.getExpenseListSuccess) {
      // toast.success(this.props.expenseData.getExpenseListSuccess);
      let _getExpenseList = this.props.expenseData.getExpenseList || [];

      let sortFilterExp = this.state.sortFilterExp;
      let sortFilterExpCheck = this.state.sortFilterExpCheck;
      _getExpenseList
        .sort((a, b) => {
          if (
            sortFilterExp === "advancedAmount" ||
            sortFilterExp === "accountedAmount" ||
            sortFilterExp === "tran"
          ) {
            let valueA = Number(a[sortFilterExp]);
            let valueB = Number(b[sortFilterExp]);
            //for Descendending order
            if (sortFilterExpCheck === "ASC") {
              if (valueA < valueB) {
                return -1;
              }
              if (valueA > valueB) {
                return 1;
              }
              return 0;
              // codes must be equal
            } else {
              //for descending order

              if (valueA > valueB) {
                return -1;
              }
              if (valueA < valueB) {
                return 1;
              }
              return 0;
              // codes must be equal
            }
          } else if (sortFilterExp === "date") {
            let valueA = new Date(a.date);
            let valueB = new Date(b.date);

            //for Descendending order
            if (sortFilterExpCheck === "ASC") {
              if (valueA < valueB) {
                return -1;
              }
              if (valueA > valueB) {
                return 1;
              }
              return 0;
              // codes must be equal
            } else {
              //for descending order

              if (valueA > valueB) {
                return -1;
              }
              if (valueA < valueB) {
                return 1;
              }
              return 0;
              // codes must be equal
            }
            // codes must be equal
          } else if (sortFilterExp) {
            let valueA = a[sortFilterExp].toString().toUpperCase();
            let valueB = b[sortFilterExp].toString().toUpperCase();
            //for Descendending order
            if (sortFilterExpCheck === "ASC") {
              if (valueA < valueB) {
                return -1;
              }
              if (valueA > valueB) {
                return 1;
              }
              return 0;
              // codes must be equal
            } else {
              //for descending order

              if (valueA > valueB) {
                return -1;
              }
              if (valueA < valueB) {
                return 1;
              }
              return 0;
              // codes must be equal
            }
          }
        })
        .map((exp, i) => {
          if (i == 0) {
            let id = uuidv1();
            exp.id = id;
            firstExp = exp;
            exp.checked = false;
            activeExpense = id;
          } else {
            exp.id = uuidv1();
            exp.checked = false;
          }
          return exp;
        });

      getExpenseList = _getExpenseList;

      clonedGetExpenseList = _getExpenseList;
      filteredExpenseList = _getExpenseList;

      /*Check When  Add/Edit Expense  and then user Save or Cancel that edit, 
        then load the same Expense user just created/edited?.*/

      let { tallies, addEditExpCheck, expTran } =
        (this.props.history &&
          this.props.history.location &&
          this.props.history.location.state) ||
        "";

      if (tallies && tallies === "Draft" && addEditExpCheck && expTran) {
        let checkExp = getExpenseList.find((l) => l.tran === expTran);

        if (checkExp) {
          firstExp = checkExp;
          activeExpense = checkExp.id;
        }
      }
    }

    //error case of get Exp List
    if (this.props.expenseData.getExpenseListError) {
      handleAPIErr(this.props.expenseData.getExpenseListError, this.props);
    }
    this.props.clearExpenseStates();
    this.setState({
      activeExpense,
      getExpenseList,
      clonedGetExpenseList,
      filteredExpenseList,
      isLoading: false,
    });

    if (firstExp) {
      // to call get Expense baseed on first Expense in  list

      let promises = [];
      promises.push(this.getExpenseSummary(firstExp, true));

      let isFlgs = false;

      let flgs = this.props.chart.getFlags || "";

      if (!flgs) {
        promises.push(this.props.getFlags());
      } else {
        isFlgs = true;
      }

      await Promise.all(promises);

      //success case of Get Flags List
      if (this.props.chart.getFlagsSuccess || isFlgs) {
        // toast.success(this.props.chart.getFlagsSuccess);
        let taxCodes = this.props.chart.getFlags.tax || [];

        let tc = [];
        taxCodes.map((t, i) => {
          tc.push({
            label: t.description + " (" + t.code + ")",
            value: t.code,
          });
        });
        this.setState({ taxCodeList: tc });
      }
      //error case of Get Flags List
      if (this.props.chart.getFlagsError) {
        handleAPIErr(this.props.chart.getFlagsError, this.props);
      }
    }
    this.props.clearChartStates();
    // scroll to active Expense
    var elmnt = document.getElementById(this.state.activeExpense);
    if (elmnt) {
      elmnt.scrollIntoView();
    }
  };

  checkPOList_API = (data, check) => {
    let usePageLoading = localStorage.getItem("usePageLoading") || "N";
    usePageLoading = usePageLoading.toLocaleLowerCase();

    //determines if the GetPOList (N) or GetNewPOList (Y) is used.
    if (usePageLoading === "y") {
      this.getNewExpenseList(data, check);
    } else {
      this.getExpenseList(data, check);
    }
  };

  // Get New Expense List
  getNewExpenseList = async (data, check) => {
    let activeExpense = "";
    let getExpenseList = [];
    let clonedGetExpenseList = [];
    let filteredExpenseList = [];
    let totalPages = "";
    let pageStart = "";
    let pageEnd = "";
    let totalExpenses = "";

    let {
      zero,
      close,
      page,
      displayRecords,
      sortFilterExp,
      viewTeam,
      order,
      expListSearch,
    } = this.state;

    if (check === "tallies") {
      zero = false;
      close = false;
    }
    // if (check) {
    //   page = this.props.pageData?.pageNumber ?? 1;
    // }
    this.clearStates();
    this.setState({
      isLoading: true,
      activeExpTallies: data.id,
      showExpenseTallisTabPane: data.type,
      zero,
      close,
      page,
    });
    let obj;
    let expenseType = this.state.activatedTab ? this.state.activatedTab : getActivatedTab();
    if (this.state.selectedBatchNos.length > 0) {
      if (this.state.insertExp) {
        obj = {
          expenseType: expenseType,
          displayRecords,
          //expenseOrder: sortFilterExp,
          search: expListSearch,
          teamExpenses: viewTeam,
          sort: sortFilterExp,
          order: order,
          batchList: this.state.selectedBatchNos,
        };
      } else {
        obj = {
          expenseType: expenseType,
          displayRecords,
          //expenseOrder: sortFilterExp,
          search: expListSearch,
          page,
          teamExpenses: viewTeam,
          sort: sortFilterExp,
          order: order,
          batchList:this.state.selectedBatchNos,
        };
      }
    } else {
      if (this.state.insertExp) {
        if (this.state.isSendForApproval){
          obj = {
            expenseType: expenseType,
            displayRecords,
            //expenseOrder: sortFilterExp,
            search: expListSearch,
            page,
            teamExpenses: viewTeam,
            sort: sortFilterExp,
            order: order,
          };
        }else{
          obj = {
            expenseType: expenseType,
            displayRecords,
            //expenseOrder: sortFilterExp,
            search: expListSearch,
            teamExpenses: viewTeam,
            sort: sortFilterExp,
            order: order,
          };
        }

      }
      else {
        if(this.state.guid)
        {
          if (this.state.pageCLicked) {
            obj = {
              expenseType: expenseType,
              displayRecords,
              //expenseOrder: sortFilterExp,
              search: expListSearch,
              page,
              teamExpenses: viewTeam,
              sort: sortFilterExp,
              order: order,
            };
          }
          else{
            if(this.state.refreshedClicked){
              obj = {
                expenseType: expenseType,
                displayRecords,
                //expenseOrder: sortFilterExp,
                search: expListSearch,
                teamExpenses: viewTeam,
                sort: sortFilterExp,
                order: order,
              };
            }
            else{
              //for refresh
              obj = {
                expenseType: expenseType,
                displayRecords,
                //expenseOrder: sortFilterExp,
                search: expListSearch,
                teamExpenses: viewTeam,
                sort: sortFilterExp,
                order: order,
                page,
              };
            }
          }
        }
        else{
          if(this.state.edited){
            obj = {
              expenseType: expenseType,
              displayRecords,
              //expenseOrder: sortFilterExp,
              search: expListSearch,
              teamExpenses: viewTeam,
              sort: sortFilterExp,
              order: order,
            };
          }else{
            obj = {
              expenseType:expenseType,
              displayRecords,
              //expenseOrder: sortFilterExp,
              search: expListSearch,
              page,
              teamExpenses: viewTeam,
              sort: sortFilterExp,
              order: order,
            };
          }
        }
      }
    }

    if(this.state.selectedGuid && this.state.refreshedClicked){
      this.setState({editedGuid: this.state.selectedGuid, edited: false})
    }

    if (this.state.editedGuid) {
      if (this.state.isSendForApproval){
        await this.props.getNewExpenseList(obj); // get Expense list
      }else{
        if(this.state.refreshedClicked){
          await this.props.getNewExpenseList({
            ...obj,
            guid: this.state.editedGuid,
          }); // get Expense list
        }else{
          if(this.state.edited){
            await this.props.getNewExpenseList({
              ...obj,
              guid: this.state.editedGuid,
            }); // get Expense list
          }else{
            await this.props.getNewExpenseList(obj); // get Expense list
          }
        }
      }
    } else {
      console.log("yyyy");
      await this.props.getNewExpenseList(obj); // get Expense list
    }
    let firstPO = "";
    //success case of expense List
    if (this.props.expenseData.getNewExpenseListSuccess) {
      // toast.success(this.props.poData.getNewPOListSuccess);


      let listData = this.props.expenseData.getNewExpenseList || "";

      getExpenseList = listData.expenseList || [];
      totalPages = listData.totalPages || "";

      pageStart = listData.pageStart || "";
      page = listData.page || "";
      pageEnd = listData.pageEnd || "";
      totalExpenses = listData.totalExpenses || "";
      if (this.state.insertExp) {
        this.setState({ page: listData.page });
      }
      let searchPOTran = "";
      let guid;
      if(this.state.refreshedClicked){
        guid = this.state.selectedGuid;
      }else{
        guid = null;
      }
      getExpenseList.map((po, i) => {
        if (i === 0 || po.guid === guid) {
          let id = uuidv1();
          po.id = id;
          firstPO = po;
          po.checked = false;
          activeExpense = id;
        } else {
          po.id = uuidv1();
          po.checked = false;
        }
        return po;
      });

      clonedGetExpenseList = getExpenseList;
      filteredExpenseList = getExpenseList;

      // check if po comming from search page
      let checkPO = getExpenseList.find((l) => l.trans === searchPOTran);
      if (checkPO) {
        firstPO = checkPO;
        activeExpense = checkPO.id;
      }
      let { tallies, addEditExpCheck, expTran } =
        (this.props.history &&
          this.props.history.location &&
          this.props.history.location.state) ||
        "";
      if (
        tallies &&
        tallies === "Draft" &&
        addEditExpCheck &&
        expTran &&
        this.state.editedGuid
      ) {
        let checkExp = getExpenseList.find((l) => l.tran === expTran);
        if (checkExp) {
          firstPO = checkExp;
          activeExpense = checkExp.id;
        }
      }
    }

    //error case of PO List
    if (this.props.expenseData.getNewExpenseListError) {
      handleAPIErr(this.props.expenseData.getNewExpenseListError, this.props);
    }

    await this.props.clearExpenseStates();
    this.setState({
      activeExpense,
      getExpenseList,
      clonedGetExpenseList,
      filteredExpenseList,
      totalPages,
      pageStart,
      pageEnd,
      totalExpenses,
      isLoading: false,
    });
    if (this.state.edited){
      if(this.props.location.state && this.props.location.state.page) {
        this.setState({ page: this.props.location.state.page, edited: false})
      }
    }
    if (firstPO) {
      //to call get po baseed on first po in po list
      await this.getExpenseSummary(firstPO, true);
    }
    await this.props.clearExpenseStates();
    //Can you ensure that checkboxes on the right hand side stay ticked when in the same section for Exclude?
    if (zero || close) {
      this.handleExclude();
    }
    // scroll to active po
    // var elmnt = document.getElementById(this.state.activeExpense);
    // if (elmnt) {
    //   elmnt.scrollIntoView();
    // }
  };

  handleExclude = (e) => {
    let name = e.target.name;
    let checked = e.target.checked;
    this.setState(
      {
        [name]: checked,
      },
      async () => {
        let { zero, close, fullyApproved } = this.state;

        let check = !zero && !close && !fullyApproved ? true : false; //all checkboxes are uncheck
        const clonedGetInvoicesList = JSON.parse(
          JSON.stringify(this.state.clonedGetInvoicesList)
        );

        if (check) {
          //all checkboxes are uncheck
          this.setState(
            {
              getInvoicesList: clonedGetInvoicesList,
              filteredInvoicesList: clonedGetInvoicesList,
            },
            () => {
              this.handleSortInvoiceLists(this.state.sortFilterInvc);
            }
          );
        } else {
          //checkbox chcek case
          let filterdData = [];
          let excludeFilters = [];
          if (zero) {
            excludeFilters = ["close", "fullyApproved", ""];
          }
          if (close) {
            excludeFilters = ["zero", "fullyApproved", ""];
          }
          if (fullyApproved) {
            excludeFilters = ["zero", "close", ""];
          }
          if (zero && close) {
            excludeFilters = ["fullyApproved", ""];
          }

          if (zero && fullyApproved) {
            excludeFilters = ["close", ""];
          }

          if (close && fullyApproved) {
            excludeFilters = ["zero", ""];
          }

          if (close && fullyApproved && zero) {
            excludeFilters = [""];
          }

          excludeFilters.map((f, i) => {
            let InvoiceListFilterdData = [];

            InvoiceListFilterdData = clonedGetInvoicesList.filter((c) => {
              // if (c.excludeStatus.toUpperCase()) {
              return c.excludeStatus.toUpperCase() === f.toUpperCase();
              // }
            });
            filterdData.push(...InvoiceListFilterdData);
          });

          this.setState(
            {
              getInvoicesList: filterdData,
              filteredInvoicesList: filterdData,
            },
            () => {
              this.handleSortInvoiceLists(this.state.sortFilterInvc);
            }
          );
        }
      }
    );
  };

  //Get Expense Summary
  getExpenseSummary = async (exp, check) => {
    if (this.state.activeExpense != exp.id || check) {
      this.setState({
        isLoading: true,
        rotate: 0,
        activeExpense: exp.id,
        previewList: [],
        numPages: null,
        pageNumber: 1,
        taxCode: { label: "Select Tax Code", value: "" },
        bankCodeList: [],
        bankCode: { label: "Select Bank Code", value: "" },
        attachmentSize: 0,
        bankDetails: "", //supplier bank details
        currentAttachmentIndex: 0,
      });
      this.setState({guid:exp.guid, selectedGuid: exp.guid })
      if (this.state.refreshedClicked){
        await this.props.getExpenseSummary(this.state.editedGuid);
      }else{
        await this.props.getExpenseSummary(exp.guid); // get expense summary
      }
      if (this.state.edited){
        this.setState({ edited:false })
      }
      //success case of get expense summary
      if (this.props.expenseData.getExpenseSummarySuccess) {
        // toast.success(this.props.expenseData.getExpenseSummarySuccess);
        let expSummary =
          (this.props.expenseData.getExpenseSummary &&
            this.props.expenseData.getExpenseSummary &&
            JSON.parse(
              JSON.stringify(this.props.expenseData.getExpenseSummary)
            )) ||
          "";

        let supplierGuid = (expSummary && expSummary.supplierGuid) || "";
        let tran = (expSummary && expSummary.tran) || "";
        const guid = expSummary?.guid ?? "";

        let approverGroup = expSummary.approverGroup || "";

        let approvers = expSummary.approvers || [];

        let attachments = (expSummary && expSummary.attachments) || [];
        const previewableAttachments = attachments.filter((a) => a?.preview === 1);
        let attachmentSize = 0;
        attachments.map((a, i) => {
          attachmentSize += Number(a.fileSize) || 0;
        });
        let changes = (expSummary && expSummary.changes) || [];

        let activity = (expSummary && expSummary.activityList) || [];

        let comments = (expSummary && expSummary.comments) || [];

        let previewList = (expSummary && expSummary.previewList) || [];

        //to show primary PDF first
        previewList = previewList.sort((a, b) =>
          a.primaryDoc.toLowerCase() < b.primaryDoc.toLowerCase() ? 1 : -1
        );

        if (isRemoteStorage()) {
          previewList = await Promise.all(previewList.map(async (p) => {
            p.file = await getBase64FromPreSignedS3Url(p.documentPresignedURL);
            return p;
          }));
        }

        let currency = (expSummary && expSummary.currency) || "";
        const bankCodeFilters = {
          currency: currency,
          type: "Expense"
        };
        let bankCodeList = (await ChartActions.getBankOptions(bankCodeFilters)).map(x => ({code: x.accountCode, description: x.description}));
        let supplierCode = (expSummary && expSummary.supplierCode) || "";
        let subTotalal = (expSummary && expSummary.subTotal) || "";

        let bc = [];
        bankCodeList.map((t, i) => {
          bc.push({
            label: t.description + " (" + t.code + ")",
            value: t.code,
          });
        });
        let approverOptions = expSummary.approverOptions || [];

        approverOptions.map((a, i) => {
          a.checked = false;
          a.id = uuidv1();
          return a;
        });
        this.setState(
          {
            tran,
            guid,
            approverGroup: {
              label: approverGroup ? approverGroup : "Select Approval Group",
              value: approverGroup ? approverGroup : "",
            },
            approvers,
            approverOptions,
            attachments,
            previewableAttachments,
            previewList,
            comments,
            attachmentSize,
            activity,
            changes,
            bankCodeList: bc,
            currency,
            supplierCode,
            supplierGuid
          },
        );
        this.setState({ refreshedClicked:false})
      }
      //error case of get expense summary
      if (this.props.expenseData.getExpenseSummaryError) {
        handleAPIErr(this.props.expenseData.getExpenseSummaryError, this.props);
      }
      this.props.clearExpenseStates();
      this.setState({ isLoading: false });
    }
  };

  updateExpenseSummaryState = async () => {
    //Success Case Of Update Approval Group

    if (this.props.expenseData.updateApprovalGroupSuccess) {
      toast.success(this.props.expenseData.updateApprovalGroupSuccess);
    }
    //Error Case Of Update Approval Group
    if (this.props.expenseData.updateApprovalGroupError) {
      handleAPIErr(this.props.expenseData.updateApprovalGroupError, this.props);
    }
  };

  handleSelectFields = async (name, data) => {
    let { formErrors } = this.state;
    formErrors = Validation.handleValidation(name, data.value, formErrors);
    this.setState({ [name]: data, formErrors });
  };

  createInvoice = async () => {
    let { taxCode, bankCode, guid, formErrors } = this.state;
    taxCode = taxCode.value || "";
    bankCode = bankCode.value || "";

    formErrors = Validation.handleWholeValidation(
      { taxCode: taxCode, bankCode: bankCode },
      formErrors
    );

    if (!formErrors.taxCode && !formErrors.bankCode) {
      this.setState({ isLoading: true });
      let obj = {
        guid,
        taxCode,
        bankCode,
      };
      await this.props.createInvoice(obj);
      if (this.props.expenseData.createInvoiceSuccess) {
        toast.success(this.props.expenseData.createInvoiceSuccess);
      }
      if (this.props.expenseData.createInvoiceError) {
        handleAPIErr(this.props.expenseData.createInvoiceError, this.props);
      }
      this.closeModal("openCreateInvoiceModal");
      this.props.clearExpenseStates();
      this.setState({ isLoading: false });
    }
    this.setState({
      formErrors,
    });
  };

  importDebitTransactions = async (data) => {
    this.setState({ isLoading: true });
    let guid = this.state.guid;
    data = { guid, ...data };
    await this.props.importDebitTransactions(data);

    if (this.props.expenseData.impDebitTransSuccess) {
      toast.success(this.props.expenseData.impDebitTransSuccess);
    }
    if (this.props.expenseData.impDebitTransError) {
      handleAPIErr(this.props.expenseData.impDebitTransError, this.props);
    }
    this.props.clearExpenseStates();
    this.setState({ isLoading: false });
  };

  //Delete Expense
  deleteExpense = async () => {
    let { guid, multipleTrans } = this.state;
    let deletableTran = null;

    if (multipleTrans.length > 1) {
      toast.error("Only One Expense can be Deleted at a Time!");
    } else if (multipleTrans.length === 1) {
      deletableTran = multipleTrans[0];
    } else {
      deletableTran = guid;
    }

    if (deletableTran) {
      this.setState({
        isLoading: true,
      });

      await this.props.deleteExpense(deletableTran); // delete expense
      //success case of delete expense
      if (this.props.expenseData.deleteExpenseSuccess) {
        toast.success(this.props.expenseData.deleteExpenseSuccess);
        await this.getExpenseTallies(this.state.showExpTalliesTabPane, true); //to refresh the list
      }
      //error case of delete expense
      if (this.props.expenseData.deleteExpenseError) {
        handleAPIErr(this.props.expenseData.deleteExpenseError, this.props);
      }
      this.setState({ isLoading: false });

      this.props.clearExpenseStates();
    }
  };

  //when click on more details link
  handleMoreDetails = async (exp) => {
    this.openModal("openExpenseMoreDetailsModal");
  };

  openExpenseDetailModal = async () => {
    let { guid } = this.state;
    if (guid) {
      const promises = [];
      this.openModal("openExpenseDetailModal");
      promises.push(this.GetExpenseLines(guid));
      promises.push(this.getChartCodes());
      await Promise.all(promises);
    }
  };

  GetExpenseLines = async (guid) => {
    let { amount, description } = this.state;
    this.setState({
      isLoading: true,
    });
    await this.props.GetExpenseLines(guid); // get expense summary
    //success case of get expense summary
    if (this.props.expenseData.getExpenseLinesSuccess) {
      // toast.success(this.props.expenseData.getExpenseSummarySuccess);
      let expSummary = this.props.expenseData.getExpenseLines || [];

      let amount = Number(expSummary.amount) || 0.0;
      let gross = expSummary.gross || "";
      let tax = expSummary.tax || 0.0;
      let baseOptions = expSummary.flags || [];

      let description = expSummary.description || "";
      // let currency = expSummary.currency || "";

      expSummary.map((m) => {
        m.id = uuidv1();
        expSummary.checked = false;
        expSummary.amount = Number(expSummary.amount).toFixed(2) || 0.0;
        expSummary.toDate = expSummary.endDate;
        expSummary.fromDate = expSummary.startDate;
        return m;
      });
      let expenseLines = expSummary;
      let flagArr = [];
      // expSummary.map(l => {
      //   l.flags.map(m => {
      //     flagArr.push(m)
      //   }
      //   )
      // })
      expSummary.map((m) => {
        flagArr = m.flags;
      });

      this.setState({
        expenseLines,
        flagArr,
        description,
        amount: Number(amount).toFixed(2),
        tax,
        gross,
        baseOptions,
      });
    }
    //error case of get expense summary
    if (this.props.expenseData.getExpenseLinesError) {
      handleAPIErr(this.props.expenseData.getExpenseLinesError, this.props);
    }
    this.props.clearExpenseStates();
    this.setState({
      isLoading: false,
    });
  };

  clearStates = () => {
    this.setState({
      // page:"",
      isLoading: false,
      tran: "", //tran of current selected Expense
      multipleTrans: [], //when user selects multiple expenses to perform different functionality
      expenseMoreDetails: [], //contains data when click on more details link
      // expListSearch: "", //search on Exp list
      getExpenseList: [], //side menu (left side) Exp list data
      clonedGetExpenseList: [], //a copy of  getExpenseList
      activeExpense: "", //to add class active in lists of getting Exp (in left side )
      activeExpTallies: "", //to add class active on Exp tallis
      // showExpTalliesTabPane: "", //to add class active on Exp tallis below tab pane
      filteredExpenseList: [], //this contains filterd list and used for searching on it
      teamExpense: "",

      openAttachmentsModal: false,
      openCommentsModal: false,
      openActivityModal: false,
      openChangesModal: false,
      openReportModal: false,
      openExpenseMoreDetailsModal: false,
      openCreateInvoiceModal: false,
      openDeleteModal: false,
      openDebitCardsModal: false,
      openDeclineModal: false,
      openExpenseDetailModal: false,
      openDebitCardReconciliationModal: false,
      openMoveModal: false,
      openPostModal: false,
      approverGroup: "",
      approvalsGroups: [],
      approvers: [], //to just show on side menuw bar
      previewList: [],
      numPages: null,
      pageNumber: 1,
      comments: [], //Expenses Comments
      attachments: [], //Expenses Attachments
      previewableAttachments: [],
      activity: [], //Expenses Activity
      changes: [], //Expenses Changes
      taxCode: { label: "Select Tax Code", value: "" },
      taxCodeList: [],
      bankCodeList: [],
      bankCode: { label: "Select Bank Code", value: "" },

      bankDetails: "", //supplier bank details
      currency: "", //supplier currency
      supplierCode: "", //supplier code
      checked: false,
      formErrors: {
        taxCode: "",
        bankCode: "",
      },
    });
  };

  openModal = (name, type) => {
    this.setState({ typeMove: type, [name]: true });
  };

  closeModal = async (name) => {
    let { expenseLines } = this.state;
    expenseLines.map((m) => {
      m.checked = false;
    });
    this.setState({
      [name]: false,
      expenseLines,
      expenseMoreDetails: [],
      taxCode: { label: "Select Tax Code", value: "" },
      bankCode: { label: "Select Bank Code", value: "" },
      formErrors: {
        taxCode: "",
        bankCode: "",
      },
    });
  };

  // -------------EXPENSE Detail Popup----------
  //handle expense detail popup checkboxes
  handleCheckboxesInExpenseDetails = (e, line) => {
    let expenseLines = JSON.parse(JSON.stringify(this.state.expenseLines));
    if (e.target.checked) {
      if (line === "all") {
        expenseLines.map(async (l, i) => {
          l.checked = true;
          return l;
        });
      } else {
        expenseLines.map(async (l, i) => {
          if (l.id === line.id) {
            l.checked = true;
          }
          return l;
        });
      }
    } else {
      if (line === "all") {
        expenseLines.map(async (l, i) => {
          l.checked = false;
          return l;
        });
      } else {
        expenseLines.map(async (l, i) => {
          if (l.id === line.id) {
            l.checked = false;
          }
          return l;
        });
      }
    }

    this.setState({
      expenseLines,
    });
  };

  handleChangeInLineFields = (e, line, i) => {
    let name = e.target.name;
    let value = e.target.value;
    // let { expenseLines } = this.state;
    let expenseLines = JSON.parse(JSON.stringify(this.state.expenseLines));

    line[name] = value || "";
    expenseLines[i] = line;
    this.setState({ expenseLines });
  };
  convertTwoDecimal = (e, line) => {
    let val = Number(e.target.value).toFixed(2) || 0.0;

    let { expenseLines } = this.state;
    line["amount"] = val;
    // calculation(subTotal, taxTotal, grossTotal) work start
    let amount = 0.0;
    let tax = 0.0;
    let gross = 0;
    let lines = JSON.parse(JSON.stringify(expenseLines));
    lines.map((poLinedata) => {
      amount = Number(amount) + Number(poLinedata.amount);
      let taxFlag = poLinedata.flags.find(
        (f) => f.type.toLowerCase() === "tax"
      );
      if (taxFlag) {
        let foundTax = this.props.chart.getFlags.tax.find(
          // this is for to get rate of a tax code or value
          (flagValue) =>
            flagValue.code.toLowerCase() == taxFlag.value.toLowerCase()
        );

        if (foundTax) {
          let calculatedTax =
            (Number(poLinedata.amount) * Number(foundTax.rate)) / 100;
          tax += calculatedTax;
        }
      }
    });
    gross = (Math.round((Number(amount) + Number(tax)) * 100) / 100).toFixed(2);
    //calculation(subTotal, taxTotal, grossTotal) work end
    this.setState({
      expenseLines,
      amount: Number(amount).toFixed(2),
      tax: Number(tax).toFixed(2),
      gross,
    });
  };

  handleChangeFlags = (e, line, i, sequence) => {
    let name = e.target.name;
    let value = e.target.value;
    // let { expenseLines } = this.state;
    let expenseLines = JSON.parse(JSON.stringify(this.state.expenseLines));

    let flags = line.flags || [];
    flags.map((f, i) => {
      if (
        f.type &&
        f.type.toLowerCase() == name.toLowerCase() &&
        f.sequence === sequence
      ) {
        f.value = value.toUpperCase();
      }
      return f;
    });

    line.flags = flags;
    expenseLines[i] = line;
    this.setState({ expenseLines });
  };

  handleMultipleChanges = (data) => {
    let { expenseLines } = this.state;
    expenseLines.map((p, i) => {
      if (p.checked) {
        if (data.chartSort) {
          p.chartSort = data.chartSort;
        }
        if (data.chartCode) {
          p.chartCode = data.chartCode || "";
        }
        if (data.trackingCodes && data.trackingCodes.length > 0) {
          // p.flags = data.trackingCodes;
          p.flags.map((m) => {
            data.trackingCodes.map((l) => {
              if (l.value) {
                if (m.value != l.value && m.sequence === l.sequence) {
                  m.value = l.value;
                }
              }
            });
          });
        }
      }
      return p;
    });
    this.setState({ expenseLines });
  };

  //update expense lines item
  updateNewExpenseLinesItem = async (expenseLine) => {
    let { expenseLines } = this.state;

    if (expenseLine.id) {
      //   //update case

      var foundIndex = expenseLines.findIndex((p) => p.id == expenseLine.id);

      // var data = expenseLines.find(p => p.id == expenseLine.id);
      if (foundIndex != -1) {
        expenseLine.customFields = expenseLines[foundIndex].customFields || []; //to add custome fields
      }
      if (expenseLine.customFields && expenseLine.customFields.length > 0) {
        expenseLine.customFields.map((c, i) => {
          if (expenseLine[c.prompt]) {
            return (c.value = expenseLine[c.prompt]);
          }
        });
      }
      expenseLines[foundIndex] = expenseLine;
    } else {
      //add case
      expenseLine.id = uuidv1();
      expenseLine.checked = false;

      expenseLine.customFields = expenseLine.customFields || []; //to add custome fields

      if (expenseLine.customFields && expenseLine.customFields.length > 0) {
        expenseLine.customFields.map((c, i) => {
          if (expenseLine[c.prompt]) {
            return (c.value = expenseLine[c.prompt]);
          }
        });
      }

      expenseLines.push(expenseLine);
    }

    // calculation(netTotal, taxTotal, grossTotal) work start
    let amount = 0;
    let tax = 0.0;
    let gross = 0;
    let lines = JSON.parse(JSON.stringify(expenseLines));
    lines.map((poLinedata) => {
      amount = Number(amount) + Number(poLinedata.amount);
      let taxFlag = poLinedata.flags.find(
        (f) => f.type.toLowerCase() === "tax"
      );
      if (taxFlag) {
        let foundTax = this.props.chart.getFlags.tax.find(
          // this is for to get rate of a tax code or value
          (flagValue) =>
            flagValue.code.toLowerCase() == taxFlag.value.toLowerCase()
        );

        if (foundTax) {
          let calculatedTax =
            (Number(poLinedata.amount) * Number(foundTax.rate)) / 100;
          tax += calculatedTax;
        }
      }
    });

    //calculation(netTotal, taxTotal, grossTotal) work end
    this.setState({
      expenseLines,
      amount: Number(amount).toFixed(2),
      tax: Number(tax).toFixed(2),
      gross,
    });
  };

  getChartSorts = async () => {
    if (!this.props.chart.getChartSorts) {
      this.setState({ isLoading: true });

      await this.props.getChartSorts();

      //success case of Get Chart Sorts
      if (this.props.chart.getChartSortsSuccess) {
        // toast.success(this.props.chart.getChartSortsSuccess);
      }
      //error case of Get Chart Sorts
      if (this.props.chart.getChartSortsError) {
        handleAPIErr(this.props.chart.getChartSortsError, this.props);
      }
      this.props.clearChartStates();
      this.setState({ isLoading: false });
    }
  };

  // ---------------END-----------------
  //when a user searches on Expense List
  handleExpListSearch = (e) => {
    let searchedText = e.target.value;
    this.setState({ expListSearch: searchedText }, () => {
      this.savePageState();
      const filteredExpenseList = JSON.parse(
        JSON.stringify(this.state.filteredExpenseList)
      );
      if (!searchedText) {
        this.setState({ getExpenseList: filteredExpenseList }, () => {
          let { getExpenseList, tran } = this.state;
          //to show selected expense in the list
          let exp = getExpenseList.find((s) => s.tran === tran);
          if (exp) {
            this.setState({ activeExpense: exp.id });
            // scroll to  seleced expense
            var elmnt = document.getElementById(exp.id);
            if (elmnt) {
              elmnt.scrollIntoView();
            }
          }
        });
      }
    });
  };

  handleChangeNewEpxListSearch = (e) => {
    let searchedText = e.target.value;
    this.setState({ expListSearch: searchedText }, () => {
      this.savePageState();
      if (!searchedText) {
        let { activeExpTallies, showExpTalliesTabPane } = this.state;
        let obj = {
          id: activeExpTallies,
          type: showExpTalliesTabPane,
        };
        this.getNewExpenseList(obj);
      }
    });
  };

  onNewEpxListSearch = (e) => {
    if (e.key === "Enter" || e.key === "Tab") {
      let searchedText = e.target.value;
      let usePageLoading = localStorage.getItem("usePageLoading") || "N";
      usePageLoading = usePageLoading.toLocaleLowerCase();
      if (usePageLoading === "y") {
        this.setState(
          {
            expListSearch: searchedText,
            page: 1,
          },
          () => {
            this.savePageState();
            let { activeExpTallies, showExpTalliesTabPane } = this.state;

            let obj = {
              id: activeExpTallies,
              type: showExpTalliesTabPane,
            };
            this.getNewExpenseList(obj);
          }
        );
      }
    }
  };

  onExpListSearch = async (e) => {
    if (e.key === "Enter" || e.key === "Tab") {
      let expListSearch = this.state.expListSearch.trim();

      if (expListSearch) {
        const filteredExpenseList = JSON.parse(
          JSON.stringify(this.state.filteredExpenseList)
        );

        let expListFilterdData = [];
        expListFilterdData = filteredExpenseList.filter((c) => {
          return (
            c.supplier.toUpperCase().includes(expListSearch.toUpperCase()) ||
            c.expenseType.toUpperCase().includes(expListSearch.toUpperCase())
          );
        });
        this.setState({ getExpenseList: expListFilterdData });
      }
    }
  };

  handleChangeNewPOListSearch = (e) => {
    let searchedText = e.target.value;
    this.setState({ POListSearch: searchedText }, () => {
      if (!searchedText) {
        let { activePOTallis, showPOTallisTabPane } = this.state;
        let obj = {
          id: activePOTallis,
          type: showPOTallisTabPane,
        };
        this.getNewPOList(obj);
      }
    });
  };

  //adding comment to the Expense
  addComment = async (comment, params) => {
    let { guid } = this.state;
    if (guid) {
      if (comment) {
        this.setState({ isLoading: true });
        let data = {
          comment,
          ...params,
          guid,
        };
        await this.props.addComment(data);
        //Success Case Of Adding Comment
        if (this.props.expenseData.addExpCommentSuccess) {
          toast.success(this.props.expenseData.addExpCommentSuccess);
          let comments = this.props.expenseData.addExpComments || [];
          this.setState({ comments });
        }
        //Error Case Of Adding Comment
        if (this.props.expenseData.addExpCommentError) {
          handleAPIErr(this.props.expenseData.addExpCommentError, this.props);
        }
        this.props.clearExpenseStates();
        this.setState({ isLoading: false });
      } else {
        toast.error("Please Enter Comment!");
      }
    } else {
      toast.error("Please select Expense First!");
    }
  };

  //add Expense Attachments
  addAttachment = async (fileList) => {
    let { tran } = this.state;

    if (tran) {
      this.setState({ isLoading: true });

      if (isRemoteStorage()) {
        await uploadFileListToS3(fileList, async (uploadedFiles) => {
          const data = {
            guid: this.state.guid,
            fileName: uploadedFiles[0]?.fileName,
            documentGUID: uploadedFiles[0]?.documentGuid,
            primaryDocument: "Y",
          };
          await this.props.addExpAttachment(data);
          if (this.props.expenseData.addExpAttachmentSuccess) {
            // toast.success(this.props.expenseData.addExpAttachmentSuccess);
            let attachments = this.props.expenseData.addExpAttachment || [];
            const previewableAttachments = attachments.filter(a => a?.preview === 1);
            let attachmentSize = 0;
            attachments.map((a, i) => {
              attachmentSize += Number(a.fileSize) || 0;
            });
            this.setState({ attachments, attachmentSize, previewableAttachments });
          }
          if (this.props.expenseData.addExpAttachmentError) {
            handleAPIErr(this.props.expenseData.addExpAttachmentError, this.props);
          }
          this.props.clearExpenseStates();
        })
        this.setState({ isLoading: false });
      } else {

        let obj = {
          guid: this.state.guid,
          attachment: fileList[0]?.attachment,
          fileName: fileList[0]?.fileName,
          primaryAttachment: "N",
        };
        await this.props.addExpAttachment(obj);
        if (this.props.expenseData.addExpAttachmentSuccess) {
          // toast.success(this.props.expenseData.addExpAttachmentSuccess);
          let attachments = this.props.expenseData.addExpAttachment || [];
          const previewableAttachments = attachments.filter(a => a?.preview === 1);
          let attachmentSize = 0;
          attachments.map((a, i) => {
            attachmentSize += Number(a.fileSize) || 0;
          });
          this.setState({ attachments, attachmentSize, previewableAttachments });
        }
        if (this.props.expenseData.addExpAttachmentError) {
          handleAPIErr(this.props.expenseData.addExpAttachmentError, this.props);
        }
        this.props.clearExpenseStates();
        this.setState({ isLoading: false });
      }
    }
  };

  //Get Expense Attachment
  getAttachment = async (id, type, fileName) => {
    if (id) {
      this.setState({ isLoading: true });

      await this.props.getExpAttachment(id);
      if (this.props.expenseData.getExpAttachmentSuccess) {
        // toast.success(this.props.expenseData.getExpAttachmentSuccess);
        let resp = this.props.expenseData.getExpAttachment;
        if (resp.documentPresignedURL) { 
          downloadFileFromPresignedUrl(resp.documentPresignedURL);
        }
        else {
          downloadAttachments(resp, fileName);
        }
      }
      if (this.props.expenseData.getExpAttachmentError) {
        handleAPIErr(this.props.expenseData.getExpAttachmentError, this.props);
      }
      this.props.clearExpenseStates();
      this.setState({ isLoading: false });
    }
  };

  downloadSelectedAttachments = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    this.setState({ isLoading: true });

    let params = {};
    if (this.state.selectedBatchNos) {
      params.batchList = this.state.selectedBatchNos;
    }
    if (this.state.allSelected) {
      params = {
        ...params,
        selectAll: "Y",
        expenseType: this.state.showExpTalliesTabPane,
        search: this.state.expListSearch,
        teamExpenses: this.state.viewTeam,
      };

    } else {
      params = {
        ...params,
        guid: this.state.multipleTrans.length > 0
          ? this.state.multipleTrans
          : [this.state.guid],
      };
    }
    await this.props.downloadAttachments(params);

    if (this.props.expenseData?.downloadExpAttachmentSuccess) {
      let resp = this.props.expenseData.downloadExpAttachment;
      if (resp.documentPresignedURL){
        downloadFileFromPresignedUrl(resp.documentPresignedURL);
      }else{
        downloadZipAttachment(resp.attachment);
      }
    } else if (this.props.expenseData.downloadExpAttachmentError) {
      toast.error(this.props.expenseData.downloadPOAttachmentError);
    } else {
      toast.error("Failed to download expense attachments");
    }
    this.setState({ isLoading: false });
  }

  //sorting on Expense List
  handleSortExpenseLists = (name) => {
    let usePageLoading = localStorage.getItem("usePageLoading") || "N";
    let order = "Descend";
    usePageLoading = usePageLoading.toLocaleLowerCase();
    if (usePageLoading === "y") {
      if (this.state.sortFilterExp === name) {
        if (this.state.order === "Descend") {
          order = "Ascend";
        }
        if (this.state.order === "Ascend") {
          order = "Descend";
        }
      }
      this.setState(
        {
          sortFilterExp: name,
          order,
        },
        () => {
          let { activeExpTallies, showExpTalliesTabPane, sortFilterExpCheck } =
            this.state;

          let obj = {
            id: activeExpTallies,
            type: showExpTalliesTabPane,
          };
          localStorage.setItem("sortFilterExp", name);
          localStorage.setItem("sortFilterExpCheck", sortFilterExpCheck);

          this.getNewExpenseList(obj);
        }
      );
    } else {
      let { sortFilterExpCheck } = this.state;
      if (this.state.sortFilterExp != name) {
        sortFilterExpCheck = "DESC";
      }

      if (sortFilterExpCheck === "DESC") {
        sortFilterExpCheck = "ASC";
      } else {
        sortFilterExpCheck = "DESC";
      }

      localStorage.setItem("sortFilterExp", name);
      localStorage.setItem("sortFilterExpCheck", sortFilterExpCheck);

      const filteredExpenseList = JSON.parse(
        JSON.stringify(this.state.filteredExpenseList)
      );
      let expListFilterdData = [];
      if (
        name === "advancedAmount" ||
        name === "accountedAmount" ||
        name === "tran"
      ) {
        expListFilterdData = filteredExpenseList.sort(function (a, b) {
          let valueA = Number(a[name]);
          let valueB = Number(b[name]);
          //for Descendending order
          if (sortFilterExpCheck === "ASC") {
            if (valueA < valueB) {
              return -1;
            }
            if (valueA > valueB) {
              return 1;
            }
            return 0;
            // codes must be equal
          } else {
            //for descending order

            if (valueA > valueB) {
              return -1;
            }
            if (valueA < valueB) {
              return 1;
            }
            return 0;
            // codes must be equal
          }
        });
      } else if (name === "date") {
        expListFilterdData = filteredExpenseList.sort(function (a, b) {
          let valueA = new Date(a.date);
          let valueB = new Date(b.date);

          //for Descendending order
          if (sortFilterExpCheck === "ASC") {
            if (valueA < valueB) {
              return -1;
            }
            if (valueA > valueB) {
              return 1;
            }
            return 0;
            // codes must be equal
          } else {
            //for descending order

            if (valueA > valueB) {
              return -1;
            }
            if (valueA < valueB) {
              return 1;
            }
            return 0;
            // codes must be equal
          }
          // codes must be equal
        });
      } else {
        expListFilterdData = filteredExpenseList.sort(function (a, b) {
          let valueA = a[name].toString().toUpperCase();
          let valueB = b[name].toString().toUpperCase();
          //for Descendending order
          if (sortFilterExpCheck === "ASC") {
            if (valueA < valueB) {
              return -1;
            }
            if (valueA > valueB) {
              return 1;
            }
            return 0;
            // codes must be equal
          } else {
            //for descending order

            if (valueA > valueB) {
              return -1;
            }
            if (valueA < valueB) {
              return 1;
            }
            return 0;
            // codes must be equal
          }
        });
      }

      this.setState({
        getExpenseList: expListFilterdData,
        sortFilterExp: name,
        sortFilterExpCheck,
      });
    }
  };
  //sendExpForApproval =>Draft -> send
  sendExpForApproval = async () => {
    let { guid, multipleTrans } = this.state;

    let _guid = "";
    if (guid || (multipleTrans && multipleTrans.length > 0)) {
      if (multipleTrans.length > 0) {
        _guid = multipleTrans;
      }else{
        _guid = [guid];
      }
    }
    if (_guid) {
      this.setState({
        isLoading: true,
      });

      if (this.state.allSelected) {
        await this.props.SendForApproval({
          selectAll: "Y",
          expenseType: this.state.showExpTalliesTabPane,
          search: this.state.expListSearch,
          teamExpenses: this.state.teamExpCheck,
        });
      } else {
        await this.props.SendForApproval({ guid: _guid }); // send Expense For Approval
      }
      //success case of send Exp For Approval
      if (this.props.expenseData.sendExpForApprovalSuccess) {
        this.setState({ editedGuid: null , isSendForApproval: true});
        toast.success(this.props.expenseData.sendExpForApprovalSuccess);
        await this.getExpenseTallies(this.state.showExpTalliesTabPane, true); //to refresh the list
      }
      //error case of send Exp For Approval
      if (this.props.expenseData.sendExpForApprovalError) {
        handleAPIErr(
          this.props.expenseData.sendExpForApprovalError,
          this.props
        );
      }
      this.setState({ isLoading: false });
      this.props.clearExpenseStates();
    } else {
      toast.error("Please select Expense First!");
    }
  };

  // Approve Expense => Approve -> Approve
  approveExpense = async () => {
    let { multipleTrans , guid} = this.state;
    if (guid || (multipleTrans && multipleTrans.length > 0)) {
      let _guids = "";
      if (multipleTrans.length > 0) {
        _guids = multipleTrans;
      }else{
        _guids = [guid];
      }

      this.setState({
        isLoading: true,
      });

      if (this.state.allSelected) {
        await this.props.approveExpense({
          selectAll: "Y",
          expenseType: this.state.showExpTalliesTabPane,
          search: this.state.expListSearch,
          teamExpenses: this.state.teamExpCheck,
        });
      } else {
        await this.props.approveExpense({ guid: _guids }); // approve Expense
      }

      //success case of Approve Expense
      if (this.props.expenseData.approveExpSuccess) {
        this.setState({ editedGuid: null , isSendForApproval: true});
        toast.success(this.props.expenseData.approveExpSuccess);
        await this.getExpenseTallies(this.state.showExpTalliesTabPane, true); //to refresh the list
      }
      //error case of Approve Expense
      if (this.props.expenseData.approveExpError) {
        handleAPIErr(this.props.expenseData.approveExpError, this.props);
      }
      this.setState({ isLoading: false });
      this.props.clearExpenseStates();
    } else {
      toast.error("Please select Expense First!");
    }
  };

  // Approve Expense => Approve -> Decline
  declineExpense = async (comment) => {
    const { guid: guid } = this.state;
    if (guid) {
      this.setState({
        isLoading: true,
      });

      await this.props.declineExpense({ guid, comment }); // decline Expense
      //success case of Decline Expense
      if (this.props.expenseData.declineExpSuccess) {
        this.setState({ editedGuid: null , isSendForApproval: true});
        toast.success(this.props.expenseData.declineExpSuccess);
        await this.getExpenseTallies(this.state.showExpTalliesTabPane, true); //to refresh the list
      }
      //error case of Decline Expense
      if (this.props.expenseData.declineExpError) {
        handleAPIErr(this.props.expenseData.declineExpError, this.props);
      }
      this.setState({ isLoading: false });
      this.props.clearExpenseStates();
    } else {
      toast.error("Please select Expense First!");
    }
  };

  closeConfrimationModal = () => {
    this.setState({ isModalOpen: false });
  };

  openModalWithContent = (title, body, actionButtonName, onConfirm) => {
    this.setState({
      modalContent: { title, body, actionButtonName, onConfirm },
      isModalOpen: true,
    });
  };

  getMoveRecord = () => {
    let { multipleTrans } = this.state;
    return multipleTrans.length === 0 ? 1 : multipleTrans.length
  }

  onClickMoveExpense= () => {
    this.openModalWithContent('Move', `This selected ${this.getMoveRecord()} Expenses will be moved to your drafts folder to modify and resend for approval.`, 'Save', this.actionClickMoveExpense)
  }

  actionClickMoveExpense = () => {
    this.moveExpense();
  }

  // Move Expense
  moveExpense = async () => {
    let { multipleTrans, guid } = this.state;
    if (guid || (multipleTrans && multipleTrans.length > 0)) {
      let _trans = "";
      if (multipleTrans.length > 0) {
        _trans = multipleTrans;
      }else {
        _trans = [guid];
      }

      this.setState({
        isLoading: true,
      });

      if (this.state.allSelected) {
        await this.props.moveExpense({
          selectAll: "Y",
          expenseType: this.state.showExpTalliesTabPane,
          search: this.state.expListSearch,
          teamExpenses: this.state.teamExpCheck,
        });
      } else {
        await this.props.moveExpense({ guid: _trans }); // Move Expense
      }

      //success case of Move Expense
      if (this.props.expenseData.moveExpSuccess) {
        toast.success(this.props.expenseData.moveExpSuccess);
        await this.getExpenseTallies(this.state.showExpTalliesTabPane, true); //to refresh the list
      }
      //error case of Move Expense
      if (this.props.expenseData.moveExpError) {
        handleAPIErr(this.props.expenseData.moveExpError, this.props);
      }
      this.setState({ isLoading: false });
      this.props.clearExpenseStates();
    } else {
      toast.error("Please select Expense First!");
    }
  };

  // Hold Expense
  holdExpense = async () => {
    let {  multipleTrans, guid } = this.state;
    if ( guid ||  (multipleTrans && multipleTrans.length > 0)) {
      let _trans = "";
      if (multipleTrans.length > 0) {
        _trans = multipleTrans;
      }else {
        _trans = [guid];
      }

      this.setState({
        isLoading: true,
      });

      if (this.state.allSelected) {
        await this.props.holdExpense({
          selectAll: "Y",
          expenseType: this.state.showExpTalliesTabPane,
          search: this.state.expListSearch,
          teamExpenses: this.state.teamExpCheck,
        });
      } else {
        await this.props.holdExpense({ guid: _trans }); // Hold Expense
      }

      //success case of Hold Expense
      if (this.props.expenseData.holdExpSuccess) {
        this.setState({ editedGuid: null , isSendForApproval: true});
        toast.success(this.props.expenseData.holdExpSuccess);
        await this.getExpenseTallies(this.state.showExpTalliesTabPane, true); //to refresh the list
      }
      //error case of Hold Expense
      if (this.props.expenseData.holdExpError) {
        handleAPIErr(this.props.expenseData.holdExpError, this.props);
      }
      this.setState({ isLoading: false });
      this.props.clearExpenseStates();
    } else {
      toast.error("Please select Expense First!");
    }
  };

  //Export List
  exportList = async () => {
    let { guid, multipleTrans } = this.state;
    if (multipleTrans && multipleTrans.length > 0) {
      let _trans = "";
      if (multipleTrans.length > 0) {
        _trans = multipleTrans;
      }
      this.setState({
        isLoading: true,
      });

      if (this.state.allSelected) {
        await this.props.exportList({
          selectAll: "Y",
          expenseType: this.state.showExpTalliesTabPane,
          search: this.state.expListSearch,
          teamExpenses: this.state.teamExpCheck,
        });
      } else {
        await this.props.exportList({ guid: _trans }); //Export List
      }

      //success case of Export List
      if (this.props.expenseData.exportListSuccess) {
        toast.success(this.props.expenseData.exportListSuccess);
        let attachemnt = this.props.expenseData.exportList || "";
        if (attachemnt) {
          const obj = {
            contentType: "application/vnd.ms-excel",
            attachment: attachemnt,
          }
          downloadAttachments(obj, "expenseList");
        }
      }
      //error case of Export List
      if (this.props.expenseData.exportListError) {
        handleAPIErr(this.props.expenseData.exportListError, this.props);
      }
      this.setState({ isLoading: false });
      this.props.clearExpenseStates();
    } else {
      toast.error("Please select Expense First!");
    }
  };

  getAttachmentString(attachment) {
    if (isValidString(attachment)) {
      return attachment;
    }
    if (isValidString(attachment?.attachment)) {
      return attachment.attachemnt;
    }
    return "";
  }

  //Export Envelope
  exportEnvelope = async () => {
    let { guid } = this.state;
    // let linesToExport = invoiceLines.filter((l) => l.checked);
    if (guid) {
      this.setState({ isLoading: true });
      await this.props.exportEnvelope(guid);
      //success case of export PO Lines
      if (this.props.expenseData.exportEnvelope) {
        toast.success(this.props.expenseData.exportEnvelopeSuccess);
        let attachemnt = this.getAttachmentString(this.props?.expenseData?.exportEnvelope?.attachment);
        if (attachemnt) {
          let obj = {
            contentType: "application/vnd.ms-excel",
            attachment: attachemnt,
          };
          downloadAttachments(obj, "expenseEnvelope");
        }
      }
      if (this.props.expenseData.exportEnvelopeError) {
        handleAPIErr(this.props.expenseData.exportEnvelopeError, this.props);
      }
      this.props.clearExpenseStates();
      this.setState({ isLoading: false });
    } else {
      toast.error("Please Select The Expense First");
    }
  };

  //Email Envelope
  emailEnvelope = async () => {
    let { guid } = this.state;
    if (guid) {
      this.setState({
        isLoading: true,
      });

      await this.props.emailEnvelope(guid); //Email Envelope
      //success case of email envelope
      if (this.props.expenseData.emailEnvelopeSuccess) {
        toast.success(this.props.expenseData.emailEnvelopeSuccess);
      }
      //error case of email envelope
      if (this.props.expenseData.emailEnvelopeError) {
        handleAPIErr(this.props.expenseData.emailEnvelopeError, this.props);
      }
      this.setState({ isLoading: false });
      this.props.clearExpenseStates();
    } else {
      toast.error("Please select Expense First!");
    }
  };

  //Balance Tax
  balanceTax = async () => {
    let { guid, multipleTrans } = this.state;
    if (guid || (multipleTrans && multipleTrans.length > 0)) {
      let _trans = "";
      if (multipleTrans.length > 0) {
        if (multipleTrans.length == 1) {
          _trans = multipleTrans[0];
        } else {
          _trans = multipleTrans;
        }
      } else {
        _trans = guid;
      }

      this.setState({
        isLoading: true,
      });

      if (this.state.allSelected) {
        await this.props.balanceTax({
          selectAll: "Y",
          expenseType: this.state.showExpTalliesTabPane,
          search: this.state.expListSearch,
          teamExpenses: this.state.teamExpCheck,
        });
      } else {
        await this.props.balanceTax({ guid: _trans }); //Balance Tax
      }
      //success case of Balance Tax
      if (this.props.expenseData.balanceTaxSuccess) {
        toast.success(this.props.expenseData.balanceTaxSuccess);
      }
      //error case of Balance Tax
      if (this.props.expenseData.balanceTaxError) {
        handleAPIErr(this.props.expenseData.balanceTaxError, this.props);
      }
      this.setState({ isLoading: false });
      this.props.clearExpenseStates();
    } else {
      toast.error("Please select Expense First!");
    }
  };

  //Draft-> Add
  insertExpense = async () => {
    const { supplierGuid } = this.state; 
    this.props.history.push("/expense-form", {
      tran: "insertExpense",
      guid: "insertExpense",
      supplierGuid
    });
  };

  //Draft-> Edit
  updateExpense = async () => {
    this.setState({ ...this.state, resetPage: false }, () => {
      const { tran, guid, page, supplierGuid } = this.state;
      this.props.history.push("/expense-form", {
        tran, 
        guid,
        page,
        supplierGuid
      });
    });
    
  };
  //download preview
  downloadPreview = () => {
    if (this.state.previewList.length > 0) {
      const primaryDocument = this.state.previewList?.filter(item => item.primaryDoc === 'Y');
      if(primaryDocument[0].documentPresignedURL){
        // still not implement in Main
      }else {
        if (primaryDocument[0].file) {
          const linkSource = `data:application/pdf;base64,${primaryDocument[0]?.file}`;
          const downloadLink = document.createElement("a");
          const fileName = "attachment.pdf";
          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
        } else {
          toast.error('No Primary Document available for download.')
        }
      }
    }else {
      toast.error('No Primary Document available for download.')
    }
  };
  // move to previous Exp
  moveToPrevExpense = async () => {
    let { getExpenseList, activeExpense } = this.state;
    let foundIndex = getExpenseList.findIndex((l) => l.id === activeExpense);

    if (foundIndex != -1 && foundIndex != 0) {
      let exp = getExpenseList[foundIndex - 1];
      if (exp) {
        await this.getExpenseSummary(exp);
      }
    }
  };

  // move to next Exp
  moveToNextExpense = async () => {
    let { getExpenseList, activeExpense } = this.state;
    let foundIndex = getExpenseList.findIndex((l) => l.id === activeExpense);
    if (foundIndex != -1) {
      let exp = getExpenseList[foundIndex + 1];
      if (exp) {
        await this.getExpenseSummary(exp);
      }
    }
  };

  handleCheckbox = (e, data) => {
    this.setState({ checked: !this.state.checked });
    let { getExpenseList, multipleTrans } = this.state;
    let { name, checked } = e.target;
    let allSelected = false;
    if (data === "allCheck" && name === "checkboxAll") {
      let multipleTransCopy = [];
      if (checked) {
        allSelected = true;
        getExpenseList.map((m) => {
          m.checked = true;
          multipleTransCopy.push(m.guid);
          return m;
        });
      } else {
        allSelected = false;
        getExpenseList.map((m) => {
          m.checked = false;
          return m;
        });
      }
      multipleTrans = [...multipleTransCopy];
    } else {
      if (checked) {
        getExpenseList.map(async (exp, i) => {
          if (data.id === exp.id) {
            exp.checked = true;
          }
          return exp;
        });
        multipleTrans.push(data.guid);
      } else {
        getExpenseList.map((exp, i) => {
          if (data.id === exp.id) {
            exp.checked = false;
          }
          return exp;
        });
        let filteredMultiTrans = multipleTrans.filter((t) => t != data.guid);
        multipleTrans = filteredMultiTrans;
      }
    }
    this.setState({
      getExpenseList,
      multipleTrans,
      allSelected,
    });
  };

  zoomIn = async () => {
    $(".invoice_pdf_new").removeClass("invoice_carousel_pdf");
    $(".invoice_pdf_new").removeClass("full_screen_convas");

    let { scaling } = this.state;

    let { scale, dropdownZoomingValue } = zoomIn(scaling);

    this.setState(
      {
        scaling: scale,
        dropdownZoomingValue,
      },
      () => {
        if (
          scaling == 2.5 ||
          scaling == 2.2 ||
          scaling == 1.9 ||
          scaling == 1.6 ||
          scaling == 1.3
        ) {
          if ($(window).width() >= 2045) {
            $(".invoice_pdf_new").addClass("invoice_pdf_new1");
          }
        } else {
          if ($(window).width() < 2045) {
            $(".invoice_pdf_new").removeClass("invoice_pdf_new1");
          }
        }
      }
    );
  };

  zoomOut = async () => {
    $(".invoice_pdf_new").removeClass("invoice_carousel_pdf");
    $(".invoice_pdf_new").removeClass("full_screen_convas");

    let { scaling } = this.state;

    let { scale, dropdownZoomingValue } = zoomOut(scaling);

    this.setState(
      {
        scaling: scale,
        dropdownZoomingValue,
      },
      () => {
        if (
          scaling == 2.5 ||
          scaling == 2.2 ||
          scaling == 1.9 ||
          scaling == 1.6 ||
          scaling == 1.3
        ) {
          if ($(window).width() >= 2045) {
            $(".invoice_pdf_new").addClass("invoice_pdf_new1");
          }
        } else {
          if ($(window).width() < 2045) {
            $(".invoice_pdf_new").removeClass("invoice_pdf_new1");
          }
        }
      }
    );
  };

  handleDropdownZooming = async (data) => {
    $(".invoice_pdf_new").removeClass("invoice_carousel_pdf");
    $(".invoice_pdf_new").removeClass("full_screen_convas");
    let value = data.value;

    let { scale, dropdownZoomingValue } = handleDropdownZooming(value);

    this.setState(
      {
        scaling: scale,
        dropdownZoomingValue,
      },
      () => {
        if (
          value == "25%" ||
          value == "20%" ||
          value == "15%" ||
          value == "10%" ||
          value == "5%"
        ) {
          if ($(window).width() < 2045) {
            $(".invoice_pdf_new").addClass("invoice_pdf_new1");
          }
        } else {
          if ($(window).width() < 2045) {
            $(".invoice_pdf_new").removeClass("invoice_pdf_new1");
          }
        }
      }
    );
  };

  handleRightSidebar = () => {
    this.setState((prevState, props) => ({
      toggleRightSidebar: !prevState.toggleRightSidebar,
    }));
  };

  handleHorizontalArrow = () => {
    $(".invoice_pdf_new").addClass("invoice_carousel_pdf");

    $(".invoice_pdf_new").removeClass("full_screen_convas");

    if ($(window).width() > 1500) {
      this.setState({
        scaling: 7,
        dropdownZoomingValue: { label: "100%", value: "100%" },
      });
    } else if ($(window).width() <= 1500) {
      this.setState({
        scaling: 3.4,
        dropdownZoomingValue: { label: "40%", value: "40%" },
      });
    }
  };

  onLoadSuccessPage = (page) => {
    var vw = $(window).width();
    var vw = $(".side-attachments").width();
    var nav = $(".navbar.fixed-top").height();
    var underNav = $(".order-tabs").height();
    var wh = $(window).height();
    var h = wh - nav - 60;
    var rsb = wh - nav - underNav - 20;
    // var pdfDiv = wh - nav - underNav - 80
    var pdfDiv = wh - nav;
    var wh = wh - nav - underNav;
    $("#order--dynamic--height").css("height", h);
    $(".side-attachments,.side-attachments-2").css("height", rsb);
    // $('#maped_image').css('height', pdfDiv)
    $(".invoice_pdf_canvas.invoice_pdf_new").css("height", wh);
  };

  handlePDFRotate = () => {
    this.setState({ rotate: this.state.rotate + 90 });
  };

  onDocumentLoadSuccess = (data, index) => {
    let numPages = data.numPages;
    this.setState({ numPages, pageNumber: 1 }, () => this.settPreviewArrows());
  };

  goToPrevPage = () => {
    let { pageNumber } = this.state;
    if (pageNumber - 1 >= 1) {
      this.setState({ pageNumber: pageNumber - 1 });
    }
  };

  goToNextPage = () => {
    let { pageNumber, numPages } = this.state;
    if (pageNumber + 1 <= numPages) {
      this.setState({ pageNumber: pageNumber + 1 });
    }
  };

   /**
   * Change the current attachment
   * @param {'next'|'prev'} action Determines if the previous or next attachment should be loaded
   */
  onSlideChange = (action) => {
    const {
      previewableAttachments,
      currentAttachmentIndex,
      previewList,
    } = this.state;
    if (previewList.length === 0) {
      return;
    }
    let newAttachmentIndex = action === 'next' ? currentAttachmentIndex + 1 : currentAttachmentIndex - 1;
    if (newAttachmentIndex < 0 || newAttachmentIndex >= previewableAttachments.length) {
      // As these two can't happen, so we just ignore
      return;
    }
    const isPreviewLoaded = previewList[newAttachmentIndex];
    if (previewList.length === previewableAttachments.length || isPreviewLoaded !== undefined) {
      // if all the previewableAttachments or the requestetd one already loaded, don't fetch
      this.setState({ currentAttachmentIndex: newAttachmentIndex });
      return;
    }
    this.setState({
      isLoading: true,
      currentAttachmentIndex: -1
    });
    const attachmentRecordId = previewableAttachments[newAttachmentIndex].id;
    // using await might be a good choice but this is front-end
    // It should not block the UI with await but with a loading spinner
    let newPreviews = [...previewList];
    this.props.getExpAttachment(attachmentRecordId)
      .then(() => {
        const isSuccess = this.props.expenseData.getExpAttachmentSuccess !== '' && this.props.expenseData.getExpAttachmentError === '';
        const isError = this.props.expenseData.getExpAttachmentError !== '' && !isSuccess;
        if (isSuccess) {
          toast.success(this.props.expenseData.getExpAttachmentSuccess);
          const fetchedAttachment =  this.props.expenseData.getExpAttachment;
          newPreviews.push({
            file: fetchedAttachment.attachment,
            documentPresignedURL: fetchedAttachment.documentPresignedURL,
            primaryDoc: "", // required to make component belive that it same previews array
          });
        } else if (isError) {
          toast.error(this.props.expenseData.getExpAttachmentError);
        } else {
          toast.warn('Loading attachment failed');
        }
        this.setState({
          previewList: newPreviews,
        }, () => this.onNewAttachmentLoaded(newAttachmentIndex));
      })
      .catch((error) => {
        toast.error(error?.message || 'Unable to load attachment');
      }).finally(() => {
        this.setState({
          isLoading: false,
        });
      });
  };

  onNewAttachmentLoaded = (newAttachmentIndex) => {
    this.setState(
      { 
        currentAttachmentIndex: newAttachmentIndex,
        pageNumber: 1, rotate: 0
      },
      () => this.settPreviewArrows()
    );
  }

  settPreviewArrows = () => {
    const {
      previewableAttachments,
      currentAttachmentIndex,
    } = this.state;
    const nextIndex = currentAttachmentIndex + 1;
    const previousDailyTimes = currentAttachmentIndex - 1;
    if (previousDailyTimes < 0) {
      $(".carousel-control-prev").hide();
      $(".carousel-control-next").show();
    } else if (
      nextIndex >= previewableAttachments.length
    ) {
      $(".carousel-control-prev").show();
      $(".carousel-control-next").hide();
    } else {
      $(".carousel-control-prev").show();
      $(".carousel-control-next").show();
    }
  };

  handleHorizontalCross = () => {
    $(".mm_invoice_div").addClass("over_auto_remove");
    $(".invoice_pdf_new").addClass("full_screen_convas");
    var vw = $(window).width();
    var nav = $(".navbar.fixed-top").height();
    var underNav = $(".order-tabs").height();
    var wh = $(window).height();
    var pdfDiv = wh - nav;
    var wh = wh - nav - underNav;

    $(".invoice_pdf_new").removeClass("invoice_carousel_pdf");
    $(".invoice_pdf_canvas.invoice_pdf_new").css("height", wh);

    if ($(window).width() > 1500) {
      this.setState({
        scaling: 7,
        dropdownZoomingValue: { label: "100%", value: "100%" },
      });
    } else if ($(window).width() <= 1500) {
      this.setState({
        scaling: 3.1,
        dropdownZoomingValue: { label: "35%", value: "35%" },
      });
    }
  };

  //call get expense list API
  toggleTeamIcon = (check) => {
    localStorage.setItem("teamExpense", check);
    this.setState({ viewTeam: check }, () => {
      let { activeExpTallies, showExpTalliesTabPane } = this.state;
      let obj = {
        id: activeExpTallies,
        type: showExpTalliesTabPane,
      };

      this.checkPOList_API(obj);
    });
  };

  onExport = (name) => {
    if (name === "LIST") {
      this.exportList();
    } else {
      //Envelope
      this.exportEnvelope();
    }
  };

  clonedBatchListGenerate = () => {
    let batchListOptions = [];
    let batchList = [];
    let clonedBatchList = [];
    batchList = this.props.setup.getBatchList || [];
    batchList.map((b) =>
      batchListOptions.push({
        label: b.description + " (" + b.batchNo + ")",
        value: b.batchNo,
      })
    );
    clonedBatchList = batchList.map((batch) => ({
      ...batch,
      checked: false,
    }));
    this.setState({ batchList, batchListOptions, clonedBatchList });
  }

  getBtachList = async () => {
    await this.props.getBtachList("Expenses");
    if (this.props.setup.getBatchListSuccess) {
      // toast.success(this.props.setup.getBatchListSuccess)
      await this.clonedBatchListGenerate();
    }
    if (this.props.setup.getBatchListError) {
      handleAPIErr(this.props.setup.getBatchListError, this.props);
    }
    this.props.clearSetupStates();
  };

  handleChangeBatchFields = (e, batch, index) => {
    let { batchList } = this.state;
    let { name, value } = e.target;
    batch[name] = value;
    batchList[index] = batch;
    this.setState({ batchList });
  };

  handleBatchCheckbox = (e, bNo) => {
    let { getExpenseList, filteredExpenseList, clonedBatchList,  selectedBatchNos } = this.state;

    let batchNo = "";

    clonedBatchList = clonedBatchList.map((batch) =>
      batch.batchNo === bNo ? { ...batch, checked: e.target.checked } : batch
    );

    // Update the selectedBatchNos array based on the checkbox state
    selectedBatchNos = e.target.checked ? [...selectedBatchNos, bNo] : selectedBatchNos.filter((batch) => batch !== bNo);


    const clonedGetExpenseList = JSON.parse(
      JSON.stringify(this.state.clonedGetExpenseList)
    );

    if (e.target.checked) {
      batchNo = bNo;

      let expListFilterdData = clonedGetExpenseList.filter((c) => {
        return Number(c.batchNo) === Number(bNo);
      });

      getExpenseList = expListFilterdData;
      filteredExpenseList = expListFilterdData;
    } else {
      //uncheck checkbox
      getExpenseList = clonedGetExpenseList;
      filteredExpenseList = clonedGetExpenseList;
    }
    this.setState(
      {
        batchNo,
        getExpenseList,
        filteredExpenseList,
        clonedBatchList,
        selectedBatchNos
      },
      () => this.handleSortExpenseLists(this.state.sortFilterExp)
    );
  };

  insertBatch = async (event) => {
    let { batchList } = this.state;

    let obj = _.maxBy(batchList, "batchNo"); //to fine the highest batch no
    let batchNo = Number(obj ? obj.batchNo : "") + 1;
    batchList = [
      ...batchList,
      {
        batchNo,
        description: "",
        type: "PC",
        notes: "",
        insertBatch: true,
      },
    ];

    this.setState({ batchList });
  };

  collapseIconsChanged = (name, res) => {
    let collapseIconObj = {
      ...this.state.collapseIcon,
      [name] : res
    } ;
    this.setState({ collapseIcon : collapseIconObj  })
  }

  deleteBatch = async () => {
    let { batchList, batchNo, getExpenseList, filteredExpenseList } =
      this.state;

    let batch = batchList.find((b) => b.batchNo === batchNo);
    if (batch && batch.insertBatch) {
      //just remove the newly inserted batch, no need to call API
      batchList = batchList.filter((c) => c.batchNo !== batchNo);
      batchNo = "";

      this.setState({ batchList, batchNo });
    } else if (batchNo === 0 || batchNo) {
      this.setState({ isLoading: true });
      await this.props.deleteBatch(batchNo);
      if (this.props.setup.deleteBatchSuccess) {
        toast.success(this.props.setup.deleteBatchSuccess);

        batchList = batchList.filter((c) => c.batchNo !== batchNo);
        batchNo = "";

        const clonedGetExpenseList = JSON.parse(
          JSON.stringify(this.state.clonedGetExpenseList)
        );

        getExpenseList = clonedGetExpenseList;
        filteredExpenseList = clonedGetExpenseList;
      }
      if (this.props.setup.deleteBatchError) {
        handleAPIErr(this.props.setup.deleteBatchError, this.props);
      }
      this.props.clearSetupStates();
      this.setState({
        isLoading: false,
        batchList,
        batchNo,
        getExpenseList,
        filteredExpenseList,
      });
    } else {
      toast.error("Please Select Batch First!");
    }
  };

  addUpdateBatch = async (e, batch, index) => {
    let { batchList } = this.state;

    let { name, value } = e.target;

    batch[name] = value;

    let bch = batchList[index];

    if (bch.insertBatch) {
      //insert new batch case

      let { batchNo } = batch;

      batchNo = batchNo.toString();
      batchNo = batchNo.trim();
      batchNo = batchNo ? Number(batchNo) : "";

      if (batchNo === 0 || batchNo) {
        let exists = batchList.find(
          (b) => b.batchNo === batchNo && !b.insertBatch
        ); //in case if batch no is already exists

        if (!exists) {
          this.setState({ isLoading: true });

          await this.props.insertBatch({ batch });

          if (this.props.setup.insertBatchSuccess) {
            toast.success(this.props.setup.insertBatchSuccess);
            batch.insertBatch = false;
          }
          if (this.props.setup.insertBatchError) {
            handleAPIErr(this.props.setup.insertBatchError, this.props);
          }
          this.props.clearSetupStates();

          this.setState({ isLoading: false });
        } else {
          toast.error("Batch No Already Exists!");
        }
      } else {
        toast.error("Please Enter Btach No!");
      }
    } else {
      //update batch case

      this.setState({ isLoading: true });
      await this.props.updateBatch({ batch });
      if (this.props.setup.updateBatchSuccess) {
        toast.success(this.props.setup.updateBatchSuccess);
        batchList[index] = batch;
      }
      if (this.props.setup.updateBatchError) {
        handleAPIErr(this.props.setup.updateBatchError, this.props);
      }

      this.props.clearSetupStates();
      this.setState({ isLoading: false, batchList });
    }
  };

  openPostModal = () => {
    let { multipleTrans } = this.state;

    if (multipleTrans.length > 0) {
      this.openModal("openPostModal");
    } else {
      toast.error("Please Select Expense First!");
    }
  };

  postExpense = async (data) => {
    let { multipleTrans } = this.state;
    let { period, reportID, generateReport } = data;

    let obj = {
      guid: multipleTrans,
      period,
      reportID,
      generateReport: generateReport ? "Y" : "N",
    };
    this.setState({ isLoading: true });
    await this.props.postExpense(obj);
    if (this.props.expenseData.postExpSuccess) {
      toast.success(this.props.expenseData.postExpSuccess);

      let jsonData = this.props.expenseData.postExp.reportJSON || "";
      let reportFile = this.props.expenseData.postExp.reportTemplate || "";

      if (jsonData && reportFile) {
        localStorage.setItem("reportFile", reportFile);
        localStorage.setItem("jsonData", jsonData);
        localStorage.setItem("key", "test");
        var path =
          window.location.protocol +
          "//" +
          window.location.host +
          "/report-view";

        window.open(path, "_blank");
      }
      this.closeModal("openPostModal");
    }
    if (this.props.expenseData.postExpError) {
      handleAPIErr(this.props.expenseData.postExpError, this.props);
    }
    this.props.clearExpenseStates();
    this.setState({ isLoading: false });
  };

  openMoveBatchPopup = () => {
    let { multipleTrans } = this.state;
    if (multipleTrans.length > 0) {
      this.openModal("openMoveModal");
    } else {
      toast.error("Please Select Expense First!");
    }
  };

  moveBatch = async (batchNo) => {
    let { multipleTrans } = this.state;
    this.setState({ isLoading: true });
    await this.props.moveBatch({ guid: multipleTrans, batchNo });
    if (this.props.expenseData.moveBatchSuccess) {
      toast.success(this.props.expenseData.moveBatchSuccess);
    }
    if (this.props.expenseData.moveBatchError) {
      handleAPIErr(this.props.expenseData.moveBatchError, this.props);
    }
    this.props.clearExpenseStates();
    this.setState({ isLoading: false });
  };

  onImport = async (excelData, type) => {
    if (type === "List") {
      await this.importList(excelData);
    } else if (type === "Envelope") {
      await this.importEnvelope(excelData);
    } else if (type === "Split Tax Envelope") {
      await this.importSplitExpense(excelData);
    } else if (type === "Fuel Envelope") {
      await this.importFuelExpense(excelData);
    }
  };

  importList = async (excelData) => {
    this.setState({ isLoading: true });
    await this.props.importList(excelData);
    if (this.props.expenseData.importListSuccess) {
      toast.success(this.props.expenseData.importListSuccess);
      this.closeModal("openImportModal");
    }
    if (this.props.expenseData.importListError) {
      handleAPIErr(this.props.expenseData.importListError, this.props);
    }
    this.props.clearExpenseStates();
    this.setState({ isLoading: false });
  };

  importEnvelope = async (excelData) => {
    this.setState({ isLoading: true });
    await this.props.importEnvelope(excelData);
    if (this.props.expenseData.importEnvelopeSuccess) {
      toast.success(this.props.expenseData.importEnvelopeSuccess);
      this.closeModal("openImportModal");
    }
    if (this.props.expenseData.importEnvelopeError) {
      handleAPIErr(this.props.expenseData.importEnvelopeError, this.props);
    }
    this.props.clearExpenseStates();
    this.setState({ isLoading: false });
  };

  importSplitExpense = async (excelData) => {
    this.setState({ isLoading: true });
    await this.props.importSplitExpense(excelData);
    if (this.props.expenseData.importSplitExpSuccess) {
      toast.success(this.props.expenseData.importSplitExpSuccess);
      this.closeModal("openImportModal");
    }
    if (this.props.expenseData.importSplitExpError) {
      handleAPIErr(this.props.expenseData.importSplitExpError, this.props);
    }
    this.props.clearExpenseStates();
    this.setState({ isLoading: false });
  };

  importFuelExpense = async (excelData) => {
    this.setState({ isLoading: true });
    await this.props.importFuelExpense(excelData);
    if (this.props.expenseData.importFuelExpSuccess) {
      toast.success(this.props.expenseData.importFuelExpSuccess);
      this.closeModal("openImportModal");
    }
    if (this.props.expenseData.importFuelExpError) {
      handleAPIErr(this.props.expenseData.importFuelExpError, this.props);
    }
    this.props.clearExpenseStates();
    this.setState({ isLoading: false });
  };

  getExpenseCodes = async () => {
    let { expenseCodes, currency } = this.state;

    if (expenseCodes.length === 0) {
      this.setState({ isLoading: true });
      const bankCodeFilters = {
        currency: currency,
        type: "P"
      };
      const bankCodeList = (await ChartActions.getBankOptions(bankCodeFilters))
        .map(x => ({expenseCode: x.accountCode, description: x.description}));
      const clonedExpenseCodes = [...expenseCodes];
      bankCodeList.map((c) => clonedExpenseCodes.push({ label: c.expenseCode + " (" + c.description + ")", value: c.expenseCode }));
      this.props.clearExpenseStates();
      this.setState({ isLoading: false, expenseCodes: clonedExpenseCodes });
    }
  };

  getSupplier = async () => {
    const {
      supplierGuid,
      isBankDetailsCollapsed,
      bankDetailsFetchedFor,
    } = this.state;
    const canFetch = isBankDetailsCollapsed === true && bankDetailsFetchedFor !== supplierGuid;
    if (canFetch) {
      this.setState({ isLoading: true });
      if (supplierGuid) {
        const supplierResponse = await SupplierActions.getSupplierBankDetails(supplierGuid);
        if (isSuccessRequest(supplierResponse)) {
          const toastMessage = getToastMessage(supplierResponse);
          const bankDetails = supplierResponse.bankDetails || {};
          toast.success(toastMessage);
          this.setState({
            bankDetails,
            bankDetailsFetchedFor: supplierGuid,
          });
        }
        if (isFailedRequest(supplierResponse)) {
          const toastMessage = getToastMessage(supplierResponse);
          toast.error(toastMessage);
        }
        this.props.clearSupplierStates();
      }
    }
    this.setState({ isBankDetailsCollapsed: !isBankDetailsCollapsed, isLoading: false });
  };

  handleRowsSetting = (e) => {
    let { activeExpTallies, showExpTalliesTabPane } = this.state;
    let obj = {
      id: activeExpTallies,
      type: showExpTalliesTabPane,
    };
    localStorage.setItem("orderDPR", e.target.value); // no of display order pages
    this.setState({ displayRecords: e.target.value }, () => {
      this.getNewExpenseList(obj);
    });
    this.closeModal();
  };

  handlePageClick = ({ selected }) => {
    this.setState({ editedGuid: null, insertExp: null, guid:null, selectedGuid:null, pageCLicked: true });
    let { activeExpTallies, showExpTalliesTabPane } = this.state;

    let obj = {
      id: activeExpTallies,
      type: showExpTalliesTabPane,
    };

    this.setState(
      { page: selected + 1, pageStart: "", pageEnd: "", totalExpenses: "" },
      () => {
        this.savePageState();
        this.getNewExpenseList(obj);
      }
    );
  };

  savePageState = () => {
    const { page, expListSearch } = this.state;
    this.props.setLastPage("expenses", {
      pageNumber: page,
      searchTerm: expListSearch,
    });
  };

  getTaxCodes = async () => {
    this.setState({ isLoading: true });
    if (this.props.chart.getTaxCodes.length > 0) {
      this.openModal("openCreateInvoiceModal");
    } else {
      await this.props.getTaxCodes();
      //success case of Get Tax Code
      if (this.props.chart.getTaxCodesSuccess) {
        // toast.success(this.props.supplier.getTaxCodesSuccess);
        let taxCodeList = this.props.chart.getTaxCodes || [];
        let texCodeArray = [];
        taxCodeList.map((m) => {
          texCodeArray.push({
            label: m.code + " (" + m.description + ")",
            value: m.description,
          });
        });
        this.setState(
          {
            taxCodeList: texCodeArray,
          },
          () => {
            this.openModal("openCreateInvoiceModal");
          }
        );
      }
      //error case of Get Tax Code
      if (this.props.chart.getTaxCodesError) {
        handleAPIErr(this.props.chart.getTaxCodesError, this.props);
      }
    }

    this.setState({ isLoading: false });
  };
  //handle change expense lines fields
  hanldeExpenseLineFields = (e, exp, index, fldName) => {
    // debugger
    $(`.chart${index}`).show();

    let name = "";
    let value = "";
    let { expenseLines, chartCodesList } = this.state;
    let clonedChartCodesList = [...chartCodesList];
    const copyArr = [...expenseLines];
    let chartCodeSuggestion = null;

    // name = e.target.name;
    // value = e.target.value;
    // exp.chartCode = value || "";
    // copyArr[index] = exp;

    if (fldName === "date") {
      name = "date";
      value = new Date(e).getTime();
    } else if (e.target.name === "chartCode") {
      //chart code sugggestion
      name = e.target.name;
      value = e.target.value;

      // let foundIndex = copyArr.findIndex((l) => l.id == exp.id);
      // if (foundIndex != -1) {
      exp.chartCode = value || "";
      copyArr[index] = exp;
      // }
      let chartCodesListFilterdData = clonedChartCodesList.filter((c) => {
        return (
          (c.code.toUpperCase().includes(value.toUpperCase()) ||
            c.description.includes(value)) &&
          c.sort.toUpperCase() === exp.chartSort.toUpperCase()
        );
      });
      clonedChartCodesList = chartCodesListFilterdData;
      chartCodeSuggestion = index;
    } else {
      name = e.target.name;
      value = e.target.value;
    }

    exp[name] = value;

    if (fldName === "gross") {
      let flags = exp.flags || [];
      let taxFlg = flags.find((f) => f.type && f.type.toLowerCase() === "tax");
      let taxRate = (taxFlg && taxFlg.value) || "";

      let { taxCodes } = this.state;
      let taxAmount = 0.0;
      if (taxCodes.length > 0) {
        let obj = taxCodes.find(
          (t) => t.code.toLowerCase() === taxRate.toLowerCase()
        );
        taxAmount = obj ? Number(obj.rate).toFixed(2) : 0.0;
      }

      exp["tax"] =
        Number(exp["gross"]) -
        (Number(exp["gross"]) / (1 + Number(taxAmount))).toFixed(2);
      exp["amount"] = (Number(exp["gross"]) - Number(exp["tax"])).toFixed(2);
    } else if (fldName === "tax") {
      exp["amount"] = (Number(exp["gross"]) - Number(exp["tax"])).toFixed(2);
    } else if (fldName === "amount") {
      exp["tax"] = (Number(exp["gross"]) - Number(exp["amount"])).toFixed(2);
    }
    copyArr[index] = exp;
    this.setState({
      expenseLines: copyArr,
      clonedChartCodesList,
      chartCodeSuggestion,
    });
  };

  // when select code from suggestions e.g. auto-completion
  changeChartCode = (chartCode, line, index) => {
    //focus after chart code selection to move next on Tab press
    $(`#chrtCode${index}`).focus();
    let copyArr = JSON.parse(JSON.stringify(this.state.expenseLines));

    // update in invoice lines
    line.chartCode = chartCode.code || "";
    line.chartDescription = chartCode.description || "";
    copyArr[index] = line;
    copyArr[index] = {
      ...copyArr[index],
      errorMessageCode: "",
    };
    this.setState({ expenseLines: copyArr });
    this.onblurCode(index);
  };
  onblurCode = (i) => {
    setTimeout(() => {
      $(`.chart${i}`).hide();
    }, 300);
  };
  handleInLine = async (e, d, i, sequence) => {
    this.onblurCode(i);
    let { name, value } = e.target;
    let {
      expenseLines,
      currencySort,
      locationSort,
      episodeSort,
      getFlags,
      clonedChartCodesList,
    } = this.state;
    const copyArr = [...expenseLines];
    let dotsCounter = "";
    // chartCode part  ------------------------------------------------
    if (name === "chartCode") {
      if (!value) {
        clonedChartCodesList = [];
        copyArr[i].chartDescription = "Required";
        copyArr[i] = {
          ...copyArr[i],
          errorMessageCode: "Required",
        };
      } else {
        copyArr[i] = { ...copyArr[i], errorMessageCode: "" };
        const result = await clonedChartCodesList.findIndex(
          (s) => s.code === value
        );
        if (result === -1) {
          let foundIndex = copyArr.findIndex((l) => l.id == d.id);
          if (foundIndex != -1) {
            // line.chartDescription = "invalid";
            copyArr[i].chartDescription = "***Invalid Account Code***";

            copyArr[i] = {
              ...copyArr[i],
              errorMessageCode: "Invalid",
            };
          }
        } else {
          copyArr[i].chartDescription =
            clonedChartCodesList[result].description;
          copyArr[i] = {
            ...copyArr[i],
            errorMessageCode: "",
          };
        }
      }
    }

    // let a = currencySort.findIndex(
    //   (f) => f.code === value.toUpperCase().slice(0, 2)
    // );
    // let b = locationSort.findIndex(
    //   (f) => f.code === value.toUpperCase().slice(3, 5)
    // );
    // let c = episodeSort.findIndex(
    //   (f) => f.code === value.toUpperCase().slice(6, 9)
    // );
    // let srchIndx = value.search("\\.");
    // if (srchIndx !== -1) {
    //   dotsCounter = value.match(new RegExp("\\.", "g")).length;
    // }

    // if (name === "chartSort") {
    //   if (value.length < 1) {
    //     copyArr[i] = {
    //       ...copyArr[i],
    //       sortErrorMsg: "Required",
    //     };
    //   } else if (dotsCounter !== 2) {
    //     if ("." !== value.slice(2, 3)) {
    //       copyArr[i] = {
    //         ...copyArr[i],
    //         sortErrorMsg: "Dot Missing!",
    //       };
    //     } else if ("." !== value.slice(5, 6)) {
    //       copyArr[i] = {
    //         ...copyArr[i],
    //         sortErrorMsg: "Dot Missing!",
    //       };
    //     }
    //   } else if (a === -1) {
    //     copyArr[i] = {
    //       ...copyArr[i],
    //       sortErrorMsg: "CU Invalid",
    //     };
    //   } else if (b === -1) {
    //     copyArr[i] = {
    //       ...copyArr[i],
    //       sortErrorMsg: "LOC Invalid",
    //     };
    //   } else if (c === -1) {
    //     copyArr[i] = {
    //       ...copyArr[i],
    //       sortErrorMsg: "EPS Invalid",
    //     };
    //   } else {
    //     copyArr[i] = { ...copyArr[i], sortErrorMsg: "" };
    //   }
    // }
    // // chartCode part  ------------------------------------------------
    // if (name === "chartCode") {
    //   if(!value){
    //     clonedChartCodesList = [];
    //     copyArr[i].chartDescription = "Required";
    //     copyArr[i] = {
    //       ...copyArr[i],
    //       errorMessageCode: "Required",
    //     };
    //   }else{
    //     copyArr[i] = { ...copyArr[i], errorMessageCode: "" };
    //     const result = await clonedChartCodesList.findIndex(
    //       (s) => s.code === value
    //     );
    //     if (result === -1) {
    //       let foundIndex = copyArr.findIndex((l) => l.id == d.id);
    //       if (foundIndex != -1) {
    //         // line.chartDescription = "invalid";
    //       copyArr[i].chartDescription = "***Invalid Account Code***";

    //         copyArr[i] = {
    //           ...copyArr[i],
    //           errorMessageCode: "Invalid",
    //         }
    //       }
    //     } else {
    //       copyArr[i].chartDescription = clonedChartCodesList[result].description;
    //       copyArr[i] = {
    //         ...copyArr[i],
    //         errorMessageCode: "",
    //       };
    //     }
    //   }
    // }
    // // insurance part -------------------------------------------------
    // let flags = d.flags || [];
    // let insurance = getFlags.insurance || [];
    // flags.map((f, i) => {
    //   if (
    //     f.type &&
    //     f.type.toLowerCase() == name.toLowerCase() &&
    //     name === "Insurance"
    //   ) {
    //     f.errorMessageIn = "";
    //     const result = insurance.findIndex((m) => m.code === value);
    //     if (result === -1) {
    //       f.errorMessageIn = "Invalid";
    //     } else {
    //       f.errorMessageIn = "";
    //     }
    //   }
    // });
    // d.flags = flags;

    // // flag part ------------------------------------------------
    // flags = d.flags || [];
    // let free = getFlags.free || [];
    // flags.map((f, i) => {
    //   if (
    //     f.type &&
    //     f.type.toLowerCase() == name.toLowerCase() &&
    //     name === "Free"
    //   ) {
    //     f.errorMessageF = "";
    //     const result = free.findIndex((m) => m.code === value);
    //     if (result === -1) {
    //       f.errorMessageF = "Invalid";
    //     } else {
    //       f.errorMessageF = "";
    //     }
    //   }
    // });

    // // update in po lines
    // d.flags = flags;
    // // Tax part ----------------------------------------------------
    // flags = d.flags || [];
    // let tax = getFlags.tax || [];
    // flags.map((f, i) => {
    //   if (
    //     f.type &&
    //     f.type.toLowerCase() == name.toLowerCase() &&
    //     name === "Tax"
    //   ) {
    //     f.errorMessageTax = "";
    //     const result = tax.findIndex((m) => m.code === value);
    //     if (result === -1) {
    //       f.errorMessageTax = "Invalid";
    //     } else {
    //       f.errorMessageTax = "";
    //     }
    //   }
    // });

    //    // Set part ------------------------------------------------
    //    flags = d.flags || [];

    //    flags.map((f, i) => {
    //      if (
    //        f.type &&
    //        f.type.toLowerCase() == name.toLowerCase() &&
    //        name === "Set"
    //      ) {
    //       let set = getFlags.set || [];
    //       const result = set.findIndex((m) => m.code === value);
    //       if (result === -1) {
    //         f.errorMessageSet = "Invalid";
    //       } else {
    //         f.errorMessageSet = "";
    //       }
    //      }
    //    });

    //           // Q to Rb3 part ------------------------------------------------
    //           flags = d.flags || [];
    //           flags.map((f, i) => {
    //             if (
    //               f.type &&
    //               f.type.toLowerCase() == name.toLowerCase() && f.sequence === sequence &&
    //               name === "Rebate"
    //             ) {
    //               let rebate = getFlags.rebate || [];
    //               const result = rebate.findIndex((m) => m.code === value);
    //               if (result === -1) {
    //                 f.errorMessageRebate = "Invalid";
    //               } else {
    //                 f.errorMessageRebate = "";
    //               }
    //             }
    //           });

    //              // test part ------------------------------------------------
    //              flags = d.flags || [];
    //              flags.map((f, i) => {
    //                if (
    //                  f.type &&
    //                  f.type.toLowerCase() == name.toLowerCase() && f.sequence === sequence &&
    //                  name === "Other"
    //                ) {
    //                 let other = getFlags.other || [];
    //                 const result = other.findIndex((m) => m.code === value);
    //                 if (result === -1) {
    //                   f.errorMessageTest = "Invalid";
    //                 } else {
    //                   f.errorMessageTest = "";
    //                 }
    //                }
    //              });

    // // update in po lines
    // d.flags = flags;
    this.setState({
      expenseLines: copyArr,
    });
  };
  getChartCodes = async () => {
    await this.props.getChartCodes();
    //success case of Get Chart Codes
    if (this.props.chart.getChartCodesSuccess) {
      // toast.success(this.props.chart.getChartCodesSuccess);
      let getChartCodes = this.props.chart.getChartCodes || "";
      this.setState({
        chartCodesList: getChartCodes.chartCodes || [],
        clonedChartCodesList: getChartCodes.chartCodes || [],
      });
    }

    //success case of Get Chart Codes
    if (this.props.chart.getChartCodesSuccess) {
      // toast.success(this.props.chart.getChartCodesSuccess);
    }
    //error case of Get Chart Codes
    if (this.props.chart.getChartCodesError) {
      handleAPIErr(this.props.chart.getChartCodesError, this.props);
    }
    this.props.clearChartStates();
  };

  //----------Regenerate Expense ---Signatures
  regenerateExpenseSignatures = async () => {
    let { multipleTrans, tran, getExpenseList, activeExpense } = this.state;
    const foundIndex = getExpenseList.findIndex((l) => l.id === activeExpense);
    const exp = getExpenseList[foundIndex];
    // if ( multipleTrans.length === 0 ) multipleTrans.push(tran);
    this.setState({
      isLoading: true,
    });
    if (multipleTrans.length === 0) {
      await this.props.regenerateExpenseSignature(this.state.guid); // regenerate signature
    } else {
      await this.props.regenerateExpenseSignature(multipleTrans); // regenerate signature
    }

    if (this.props.expenseData.regenerateExpenseSignatureSuccess) {
      await this.getExpenseSummary(exp, true);
      toast.success(this.props.expenseData.regenerateExpenseSignatureSuccess);
    }
    if (this.props.expenseData.regenerateExpenseSignatureError) {
      handleAPIErr(
        this.props.expenseData.regenerateExpenseSignatureError,
        this.props
      );
    }
    this.setState({
      isLoading: false,
    });
    multipleTrans.length = 0;
  };
  //  check approval function
  //moving to next tab
  moveToNextApprover = async (comment, email) => {
    this.setState({ isLoading: true });
    let { guid } = this.state;
    let data = {
      guid,
      comment: comment,
      email,
    };
    if (guid) {
      await this.props.moveToNextApprover(data);
      if (this.props.expenseData.moveToNextApproverSuccess) {
        toast.success(
          this.props.expenseData.moveToNextApproverSuccess[0]?.description
        );
        toast.success(
          this.props.expenseData.moveToNextApproverSuccess[1]?.description
        );
        this.getExpenseTallies(this.state.showExpTalliesTabPane, true);
      }
      if (this.props.expenseData.moveToNextApproverError) {
        handleAPIErr(
          this.props.expenseData.moveToNextApproverError,
          this.props
        );
      }
      this.setState({ isLoading: false });
    }
  };

  // move to pre tab
  moveToPrevApprover = async (comment, email) => {
    this.setState({ isLoading: true });
    let { guid } = this.state;
    let data = {
      guid,
      comment: comment,
      email,
    };
    if (guid) {
      await this.props.moveToPrevApprover(data);
      if (this.props.expenseData.moveToPrevApproverSuccess) {
        toast.success(
          this.props.expenseData.moveToPrevApproverSuccess[0]?.description
        );
        toast.success(
          this.props.expenseData.moveToPrevApproverSuccess[1]?.description
        );
        this.getExpenseTallies(this.state.showExpTalliesTabPane, true);
      }
      if (this.props.expenseData.moveToPrevApproverError) {
        handleAPIErr(
          this.props.expenseData.moveToPrevApproverError,
          this.props
        );
      }
      this.setState({ isLoading: false });
    }
  };

  //----------------------****Send Email Approver*****------------------------------

  resendEmail = async () => {
    this.setState({ isLoading: true });
    let { guid, multipleTrans } = this.state;
    await this.props.resendEmail(multipleTrans.length > 0 ? multipleTrans : guid);
    if (this.props.expenseData.resendEmailSuccess) {
      toast.success(this.props.expenseData.resendEmailSuccess);
    }

    if (this.props.expenseData.resendEmailError) {
      handleAPIErr(this.props.expenseData.resendEmailError, this.props);
    }
    this.setState({ isLoading: false });
  };

  updateExpenseLines = async (data) => {
    this.setState({ isLoading: true });
    if (data) {
      await this.props.updateExpenseLines(data);
      if (this.props.expenseData.updateExpenseLinesSuccess) {
        toast.success(this.props.expenseData.updateExpenseLinesSuccess);
      }
      if (this.props.expenseData.updateExpenseLinesError) {
        handleAPIErr(
          this.props,
          this.props.expenseData.updateExpenseLinesError
        );
      }
    }
    this.closeModal("openExpenseDetailModal");
    this.setState({ isLoading: false });
  };

  handleTransferExpenses = async (newUser) => {
    this.setState({ isLoading: true });
    
     let params = {};

    if (this.state.allSelected) {
      params = {
        newUser,
        selectAll: "Y",
        expenseType: this.state.showExpTalliesTabPane,
        search: this.state.expListSearch,
        teamExpenses: this.state.viewTeam,
      };

      if (this.state.batchNo) {
        params['batchList'] = [this.state.batchNo];
      }

    } else {
      params = {
        newUser,
        guid: this.state.multipleTrans.length > 0
          ? this.state.multipleTrans
          : [this.state.guid],
      };
    }

    await this.props.transferExpenses(params);

    if (this.props.expenseData?.transferExpenseSuccess) {
      toast.success('Successfully transferred expenses');
    } else if (this.props.expenseData.transferExpenseError) {
      toast.error(this.props.expenseData.transferExpenseError);
    } else {
      toast.error("Failed to transfer expenses");
    }
    this.setState({ isLoading: false });
  }

  refreshTalliesAndList = async () => {
    this.setState({refreshedGuid: this.state.selectedGuid, refreshedClicked:true})
    await this.getExpenseTallies('', true);
  }

  getClassName = () => {
    if(isSafari()){
      return "suppliers_list list__color-bottom-safari";
     } else {
      return "suppliers_list list__color-bottom";
     } 
  }

  displayBankDetail(bankDetailItem, bankDetailKey) {
    const keyMappedLabel = {
      paymentType: "TYPE",
      accountName: "ACCOUNT NAME",
      bank: "BANK",
      sort: "SORT",
      accountNo: "ACC NO",
      suffix: "SUFFIX",
      iban: "IBAN",
      bankReference: "REF",
      currencyCode: "CURR CODE",
      swift: "SWIFT",
      countryCode: "CU CODE",
    };
    const bankDetailLabel = keyMappedLabel[bankDetailKey] || "";
    if (isValidString(bankDetailItem)) {
      return (
        <div className="uppercaseText">
          <strong>
            {bankDetailLabel}:
          </strong> &nbsp;
          {bankDetailItem}
        </div>
      );
    }
    return (<></>);
  }

  render() {
    let userType = localStorage.getItem("userType");
    // let userType = "";
    userType = userType?.toLowerCase();

    let {
      isModalOpen, 
      modalContent,
      batchList,
      batchNo,
      showExpTalliesTabPane,
      activeExpense,
      getExpenseList,
      bankDetails,
      clonedBatchList
    } = this.state;

    let usePageLoading = localStorage.getItem("usePageLoading") || "N";
    usePageLoading = usePageLoading.toLowerCase();

    let tab =
      (showExpTalliesTabPane && showExpTalliesTabPane.toLowerCase()) || "";
    let approverNextPrevCheck = false;
    if (tab !== "draft" && tab !== "approved") {
      if (
        (tab === "pending" || tab === "approve") &&
        (userType === "admin" || userType === "sysadmin")
      ) {
        approverNextPrevCheck = true;
      }
    }
    return (
      <>
        {this.state.isLoading ? <div className="se-pre-con"></div> : ""}
        <div className="dashboard">
          {/* top nav bar */}
          <Header
            props={this.props}
            expense={true}
            toggleTeamIcon={this.toggleTeamIcon}
            viewTeam={this.state.viewTeam}
          />
          {/* end */}

          {/* body part */}

          <div className="dashboard_body_content dash__invoice--content">
            {/* top Nav menu*/}
            <TopNav />
            {/* end */}

            {/* side menu Expense*/}

            <aside
              className="side-nav suppliers_side_nav side__content--invoice"
              id="show-side-navigation1"
            >
              <div className="cus-arro-div2">
                <img
                  src="images/arrow-r.png"
                  className=" img-fluid cus-arro-r"
                  alt="user"
                />
              </div>
              <div className="search">
                  <div className="controller-row">
                    <div className="controller-1">  <div className="form-group remember_check custom-checkbox-ui">
                      <input
                        type="checkbox"
                        id={"order"}
                        name="checkboxAll"
                        checked={this.state.allSelected}
                        onChange={(e) => this.handleCheckbox(e, "allCheck")}
                      />
                      <label
                        htmlFor={"order"}
                        className="mr-0 custom-box"
                      ></label>
                    </div></div>
                    <div className="controller-2"><Dropdown
                      alignRight={false}
                      drop="down"
                      className="analysis-card-dropdwn custom-my-radio user_drop_options"
                    >
                      <Dropdown.Toggle
                        variant="sucess"
                        id="dropdown-basic"
                        className="custom-angle-down"
                      >
                        <img src="images/angle-down.png" alt="arrow" />
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          to="#/action-2"
                          className="f-20 flex-container-inner"
                          onClick={() =>
                            this.handleSortExpenseLists("expenseType")
                          }
                        >
                          <div
                            className="custom-control custom-radio flex-container-inner"
                          >
                            <input
                              type="radio"
                              className="custom-control-input flex-container-inner-input"
                              id="expenseType"
                              name="expenseType"
                              onChange={() => {}}
                              checked={
                                this.state.sortFilterExp === "expenseType"
                              }
                            />
                            <label
                              className="custom-control-label flex-container-inner-input"
                              htmlFor="expenseType"
                            >
                              Type
                            </label>
                            {
                              this.state.sortFilterExp === "expenseType" ?
                                (
                                  this.state.order === "Descend" ? <i className="fa fa-angle-up ml-2 mt-1"></i> : <i className="fa fa-angle-down ml-2 mt-1"></i>
                                ) : ("")
                            }
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item
                          to="#/action-2"
                          className="f-20 flex-container-inner"
                          onClick={() => this.handleSortExpenseLists("date")}
                        >
                          <div

                            className="custom-control custom-radio flex-container-inner"
                          >
                            <input
                              type="radio"
                              className="custom-control-input flex-container-inner-input"
                              id="date"
                              name="date"
                              onChange={() => {}}
                              checked={this.state.sortFilterExp === "date"}
                            />
                            <label
                              className="custom-control-label flex-container-inner-input"
                              htmlFor="date"
                            >
                              Date
                            </label>
                            {
                              this.state.sortFilterExp === "date" ?
                                (
                                  this.state.order === "Descend" ? <i className="fa fa-angle-up ml-2 mt-1"></i> : <i className="fa fa-angle-down ml-2 mt-1"></i>
                                ) : ("")
                            }
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item
                          to="#/action-2"
                          className="f-20 flex-container-inner"
                          onClick={() =>
                            this.handleSortExpenseLists("envelope")
                          }
                        >
                          <div

                            className="custom-control custom-radio flex-container-inner"
                          >
                            <input
                              type="radio"
                              className="custom-control-input flex-container-inner-input"
                              id="envelope"
                              name="envelope"
                              onChange={() => {}}
                              checked={this.state.sortFilterExp === "envelope"}
                            />
                            <label
                              className="custom-control-label flex-container-inner-input"
                              htmlFor="envelope"
                            >
                              Envelope
                            </label>
                            {
                              this.state.sortFilterExp === "envelope" ?
                                (
                                  this.state.order === "Descend" ? <i className="fa fa-angle-up ml-2 mt-1"></i> : <i className="fa fa-angle-down ml-2 mt-1"></i>
                                ) : ("")
                            }
                          </div>
                        </Dropdown.Item>

                        <Dropdown.Item
                          to="#/action-2"
                          className="f-20 flex-container-inner"
                          onClick={() =>
                            this.handleSortExpenseLists("expenseCode")
                          }
                        >
                          <div

                            className="custom-control custom-radio flex-container-inner"
                          >
                            <input
                              type="radio"
                              className="custom-control-input flex-container-inner-input"
                              id="expenseCode"
                              name="expenseCode"
                              onChange={() => {}}
                              checked={
                                this.state.sortFilterExp === "expenseCode"
                              }
                            />
                            <label
                              className="custom-control-label flex-container-inner-input"
                              htmlFor="expenseCode"
                            >
                              Code
                            </label>
                            {
                              this.state.sortFilterExp === "expenseCode" ?
                                (
                                  this.state.order === "Descend" ? <i className="fa fa-angle-up ml-2 mt-1"></i> : <i className="fa fa-angle-down ml-2 mt-1"></i>
                                ) : ("")
                            }
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item
                          to="#/action-2"
                          className="f-20 flex-container-inner"
                          onClick={() =>
                            this.handleSortExpenseLists("advancedAmount")
                          }
                        >
                          <div

                            className="custom-control custom-radio flex-container-inner"
                          >
                            <input
                              type="radio"
                              className="custom-control-input flex-container-inner-input"
                              id="advancedAmount"
                              name="advancedAmount"
                              onChange={() => {}}
                              checked={
                                this.state.sortFilterExp === "advancedAmount"
                              }
                            />
                            <label
                              className="custom-control-label flex-container-inner-input"
                              htmlFor="advancedAmount"
                            >
                              Advanced Amount
                            </label>
                            {
                              this.state.sortFilterExp === "advancedAmount" ?
                                (
                                  this.state.order === "Descend" ? <i className="fa fa-angle-up ml-2 mt-1"></i> : <i className="fa fa-angle-down ml-2 mt-1"></i>
                                ) : ("")
                            }
                          </div>
                        </Dropdown.Item>

                        <Dropdown.Item
                          to="#/action-2"
                          className="f-20 flex-container-inner"
                          onClick={() =>
                            this.handleSortExpenseLists("accountedAmount")
                          }
                        >
                          <div

                            className="custom-control custom-radio flex-container-inner"
                          >
                            <input
                              type="radio"
                              className="custom-control-input flex-container-inner-input"
                              id="accountedAmount"
                              name="accountedAmount"
                              onChange={() => {}}
                              checked={
                                this.state.sortFilterExp === "accountedAmount"
                              }
                            />
                            <label
                              className="custom-control-label flex-container-inner-input"
                              htmlFor="accountedAmount"
                            >
                              Accounted Amount
                            </label>
                            {
                              this.state.sortFilterExp === "accountedAmount" ?
                                (
                                  this.state.order === "Descend" ? <i className="fa fa-angle-up ml-2 mt-1"></i> : <i className="fa fa-angle-down ml-2 mt-1"></i>
                                ) : ("")
                            }
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item
                          to="#/action-2"
                          className="f-20 flex-container-inner"
                          onClick={() => this.handleSortExpenseLists("tran")}
                        >
                          <div

                            className="custom-control custom-radio flex-container-inner"
                          >
                            <input
                              type="radio"
                              className="custom-control-input flex-container-inner-input"
                              id="tran"
                              name="tran"
                              onChange={() => {}}
                              checked={this.state.sortFilterExp === "tran"}
                            />
                            <label
                              className="custom-control-label flex-container-inner-input"
                              htmlFor="tran"
                            >
                              Transaction
                            </label>
                            {
                              this.state.sortFilterExp === "tran" ?
                                (
                                  this.state.order === "Descend" ? <i className="fa fa-angle-up ml-2 mt-1"></i> : <i className="fa fa-angle-down ml-2 mt-1"></i>
                                ) : ("")
                            }
                          </div>
                        </Dropdown.Item>
                        {usePageLoading === "y" ? (
                          <div className="custom-control custom-radio cutm-pr-right">
                            <div className="settings_display_row flex__wrapper-inner">
                              <label className="labelwrapper__iner lable-inner-wrapper">
                                Display Rows Per Page
                              </label>
                              <input
                                className="form-control input__wrapper--inner "
                                type="number"
                                min="1"
                                defaultValue={this.state.displayRecords}
                                onBlur={this.handleRowsSetting}
                              />
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </Dropdown.Menu>
                    </Dropdown>{" "}</div>
                    <div className="controller-3">
                      <div className="input-group-prepend">
                        <span className="input-group-text width-45" id="basic-addon1">
                          <img
                            src="images/search-icon.png"
                            className="mx-auto"
                            alt="search-icon"
                          />
                        </span>
                    </div>
                      <input
                        type="text"
                        className="form-control height-38"
                        placeholder="What are you looking for"
                        aria-label="What are you looking for"
                        aria-describedby="basic-addon1"
                        name="expListSearch"
                        value={this.state.expListSearch}
                        onChange={(e) =>
                          usePageLoading === "y"
                            ? this.handleChangeNewEpxListSearch(e)
                            : this.handleExpListSearch(e)
                        }
                        onKeyDown={(e) =>
                          usePageLoading === "y"
                            ? this.onNewEpxListSearch(e)
                            : this.onExpListSearch(e)
                        }
                      /></div>
                    <div className="controller-4">
                        <img
                        src="images/refresh.png"
                        className="cursorPointer"
                        alt="search-icon "
                        onClick={()=> this.refreshTalliesAndList()}
                      />
                    </div>
                  </div>
              </div>

              <ul
                className={
                  Number(this.state.totalPages) !== 0
                    ? usePageLoading === "y"
                      ? this.getClassName()
                      : "suppliers_list"
                    : "suppliers_list"
                }
              >
                {getExpenseList.map((l, i) => {
                  return (
                    <li
                      key={i}
                      className={
                        l.teamExpense === "Y"
                          ? getExpenseList[i + 1] &&
                            getExpenseList[i + 1].teamExpense &&
                            getExpenseList[i + 1].teamExpense === "Y"
                            ? "teamOrdersBg teamOrdersBorder2 cursorPointer"
                            : "teamOrdersBg teamOrdersBorder cursorPointer"
                          : activeExpense === l.id
                          ? "active cursorPointer"
                          : "cursorPointer"
                      }
                      id={l.id}
                    >
                      <div className="row">
                        <div className="col-auto mb-2 pr-0">
                          <div className="form-group remember_check">
                            <input
                              type="checkbox"
                              id={"exp" + i}
                              checked={l.checked}
                              name="checkbox"
                              onChange={(e) => this.handleCheckbox(e, l)}
                            />
                            <label htmlFor={"exp" + i} className="mr-0"></label>
                          </div>
                        </div>

                        <div
                          className="col pl-0"
                          onClick={() => this.getExpenseSummary(l)}
                        >
                          <div className="invioce_data pr-sm-3">
                            <h4>{l.supplier || ""}</h4>
                            <div className="row">
                              <div className="col data-i pr-0">
                                <p> {l.expenseType}</p>
                              </div>
                              <div className="col data-i pl-0">
                                <p>
                                  Date: {l.date}
                                  {/* {moment.unix(l.date).format("DD-MMM-YYYY")} */}
                                </p>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col data-i pr-0">
                                <p>Env#: {l.envelope}</p>
                              </div>
                              <div className="col data-i pl-0">
                                <p> Code: {l.expenseCode}</p>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col pr-0">
                                <p>Adv: {l.advancedAmount}</p>
                              </div>
                              <div className="col pl-0">
                                <p> Acct: {l.accountedAmount}</p>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col data-i"></div>
                              <div className="col-auto data-i">
                                <div className="text-center cursorPointer">
                                  <span
                                    onClick={() => this.handleMoreDetails(l)}
                                  >
                                    <Link className="more-details-color" to="#">
                                      more details
                                    </Link>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  );
                })}
                );
              </ul>
              {Number(this.state.totalPages) !== 0 ? (
                usePageLoading === "y" ? (
                  <div className="pagination__list-custom">
                    <p className="mb__zero pt-top-container">
                      Showing {this.state.pageStart} to {this.state.pageEnd} of{" "}
                      {this.state.totalExpenses} entries
                    </p>
                    <ReactPaginate
                      tabIndex="-1"
                      previousLabel={"prev"}
                      pageLinkClassName={"pagination__list-custom-link   "}
                      pageClassName={"pagination__list-custom-li "}
                      activeLinkClassName={"pagination__tab-custm"}
                      nextLabel={"next"}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={this.state.totalPages}
                      marginPagesDisplayed={1}
                      pageRangeDisplayed={2}
                      onPageChange={this.handlePageClick}
                      containerClassName={
                        "pagination cursorPointer align-center-container"
                      }
                      activeClassName={"active"}
                      forcePage={this.state.page - 1}
                    />
                  </div>
                ) : (
                  ""
                )
              ) : (
                ""
              )}
            </aside>
            {/* {/ end /} */}

            <section id="contents" className="supplier pr-0 pt-0">
              <div className="body_content ordermain-padi body__invoice--top">
                <div className="container-fluid pl-0 ">
                  <div className="main_wrapper ">
                    <div className="row d-flex pl-15">
                      <div className="col-12 w-100 order-tabs p-md-0">
                        {/* Expense Tallies */}
                        <div className="exp_header_bottom">
                          <ul className="nav nav-tabs">
                            {this.state.expenseTallies.map((t, i) => {
                              return (
                                <li
                                  key={i}
                                  className="cursorPointer nav-item"
                                  onClick={() =>
                                    this.handleInvoiceTallies(t.type, true)
                                  }
                                >
                                  <a
                                    className={
                                      this.state.activeExpTallies === t.id
                                        ? "nav-link active"
                                        : "nav-link"
                                    }
                                  >
                                    {t.type}{" "}
                                    <span className="stats">{t.count}</span>
                                  </a>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                        <div className="bg-gry mm_top_nav content__elem--style">
                          <div className="w-100 float-left mm_lr_pad ">
                            <div className="mm_tab_left invoice_left expenses-hed-left">
                              <div className="tab-content">
                                {tab === "draft" && (
                                  <div className="tab-pane container active">
                                    <ul>
                                      <li
                                        onClick={this.insertExpense}
                                        className="cursorPointer"
                                      >
                                        <img
                                          src="images/add.png"
                                          className=" img-fluid "
                                          alt="user"
                                        />{" "}
                                        <Link to="#">New</Link>{" "}
                                      </li>
                                      <li
                                        onClick={() =>
                                          this.state.getExpenseList.length > 0
                                            ? this.updateExpense()
                                            : () => {}
                                        }
                                        className="cursorPointer"
                                      >
                                        <img
                                          src="images/pencill.png"
                                          className=" img-fluid "
                                          alt="user"
                                        />{" "}
                                        <Link to="#"> Edit </Link>{" "}
                                      </li>
                                      <li
                                        onClick={() =>
                                          this.state.getExpenseList.length > 0
                                            ? this.openModal("openDeleteModal")
                                            : () => {}
                                        }
                                        className="cursorPointer"
                                      >
                                        <img
                                          src="images/delete.svg"
                                          className="invoice-delete-icon img-fluid "
                                          alt="user"
                                        />{" "}
                                        <Link to="#">Delete </Link>
                                      </li>
                                      <li
                                        className="cursorPointer"
                                        onClick={() =>
                                          this.state.getExpenseList.length > 0
                                            ? this.sendExpForApproval()
                                            : () => {}
                                        }
                                      >
                                        <img
                                          src="images/send.png"
                                          className=" img-fluid "
                                          alt="user"
                                        />{" "}
                                        <Link to="#"> Send </Link>
                                      </li>
                                    </ul>
                                  </div>
                                )}
                                {tab === "approve" && (
                                  <div
                                    className={
                                      this.state.getExpenseList.length === 0
                                        ? "tab-pane container"
                                        : "tab-pane container active"
                                    }
                                  >
                                    <ul>
                                      <li
                                        className="cursorPointer"
                                        onClick={this.approveExpense}
                                      >
                                        <img
                                          src="images/tick.png"
                                          className=" img-fluid "
                                          alt="user"
                                        />{" "}
                                        <Link to="#"> Approve </Link>
                                      </li>
                                      <li
                                        className="cursorPointer"
                                        onClick={this.holdExpense}
                                      >
                                        <img
                                          src="images/move.png"
                                          className=" img-fluid"
                                          alt="user"
                                        />{" "}
                                        <Link to="#"> Hold </Link>
                                      </li>
                                      <li
                                        className="cursorPointer"
                                        onClick={() =>
                                          this.openModal("openDeclineModal")
                                        }
                                      >
                                        <img
                                          src="images/decline.png"
                                          className=" img-fluid "
                                          alt="user"
                                        />{" "}
                                        <Link to="#"> Decline </Link>
                                      </li>
                                      <li
                                        className="cursorPointer"
                                        onClick={this.openExpenseDetailModal}
                                      >
                                        <img
                                          src="images/pencill.png"
                                          className=" img-fluid "
                                          alt="user"
                                        />{" "}
                                        <Link to="#"> Edit </Link>
                                      </li>
                                    </ul>
                                  </div>
                                )}
                                {tab === "declined" && (
                                  <div
                                    className={
                                      this.state.getExpenseList.lenght === 0
                                        ? "tab-pane container"
                                        : "tab-pane container active d-flex"
                                    }
                                  >
                                    <ul>
                                      {((userType &&
                                        userType.toLowerCase() ===
                                          "approver") ||
                                        (userType &&
                                          userType.toLowerCase() ===
                                            "op/approver")) && (
                                        <li
                                          className="cursorPointer"
                                          onClick={this.approveExpense}
                                        >
                                          <img
                                            src="images/tick.png"
                                            className=" img-fluid "
                                            alt="user"
                                          />{" "}
                                          <Link to="#"> Approve </Link>
                                        </li>
                                      )}
                                    </ul>

                                    <ul>
                                      {((userType &&
                                        userType.toLowerCase() ===
                                          "operator") ||
                                        (userType &&
                                          userType.toLowerCase() === "")) && (
                                        <li
                                          className="cursorPointer"
                                          onClick={this.moveExpense}
                                        >
                                          <img
                                            src="images/move.png"
                                            className=" img-fluid "
                                            alt="user"
                                          />{" "}
                                          <Link to="#"> Move </Link>
                                        </li>
                                      )}
                                    </ul>
                                  </div>
                                )}

                                {tab === "hold" && (
                                  <div
                                    className={
                                      this.state.getExpenseList.length === 0
                                        ? "tab-pane container"
                                        : "tab-pane container active"
                                    }
                                  >
                                    <ul>
                                      <li
                                        className="cursorPointer"
                                        onClick={this.approveExpense}
                                      >
                                        <img
                                          src="images/tick.png"
                                          className=" img-fluid "
                                          alt="user"
                                        />{" "}
                                        <Link to="#"> Approve </Link>
                                      </li>
                                      <li
                                        className="cursorPointer"
                                        onClick={() => {
                                          this.openModal("openDeclineModal");
                                        }}
                                      >
                                        <img
                                          src="images/decline.png"
                                          className=" img-fluid "
                                          alt="user"
                                        />{" "}
                                        <Link to="#"> Decline </Link>
                                      </li>
                                      <li
                                        className="cursorPointer"
                                        onClick={this.openExpenseDetailModal}
                                      >
                                        {" "}
                                        <img
                                          src="images/pencill.png"
                                          className=" img-fluid "
                                          alt="user"
                                        />{" "}
                                        <Link to="#"> Edit </Link>
                                      </li>
                                    </ul>
                                  </div>
                                )}
                                {tab === "pending" && (
                                  <div
                                    className={
                                      this.state.getExpenseList.length === 0
                                        ? "tab-pane container"
                                        : "tab-pane container active"
                                    }
                                  >
                                    <ul>
                                      <li
                                        className="cursorPointer"
                                        onClick={this.onClickMoveExpense}
                                      >
                                        <img
                                          src="images/move.png"
                                          className=" img-fluid "
                                          alt="user"
                                        />{" "}
                                        <Link to="#"> Move </Link>
                                      </li>
                                    </ul>
                                  </div>
                                )}
                                {tab === "approved" && (
                                  <div
                                    className={
                                      this.state.getExpenseList.length === 0
                                        ? "tab-pane container"
                                        : "tab-pane container active"
                                    }
                                  >
                                    <ul></ul>
                                  </div>
                                )}
                                {tab === "all" && (
                                  <div
                                    className={
                                      this.state.getExpenseList.length === 0
                                        ? "tab-pane container"
                                        : "tab-pane container active"
                                    }
                                  >
                                    <ul></ul>
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="mm_tab_center invoice_right expenses-hed-right">
                              <div className="slider-panel">
                                <Link to="#" className="zom-img">
                                  <img
                                    onClick={this.zoomOut}
                                    src="images/minus.png"
                                    className=" img-fluid float-left"
                                    alt="user"
                                  />{" "}
                                </Link>
                                <Link to="#" className="zom-img">
                                  <img
                                    onClick={this.zoomIn}
                                    src="images/add.png"
                                    className=" img-fluid float-left"
                                    alt="user"
                                  />{" "}
                                </Link>
                                <Select
                                  className="width-selector"
                                  value={this.state.dropdownZoomingValue}
                                  classNamePrefix="custon_select-selector-inner"
                                  options={options}
                                  onChange={this.handleDropdownZooming}
                                  theme={(theme) => ({
                                    ...theme,
                                    border: 0,
                                    borderRadius: 0,
                                    colors: {
                                      ...theme.colors,
                                      primary25: "#f2f2f2",
                                      primary: "#f2f2f2",
                                    },
                                  })}
                                />
                                <Link to="#" className="zom-img">
                                  <img
                                    onClick={this.handleHorizontalCross}
                                    src="images/fulls.png"
                                    className=" img-fluid float-left"
                                    alt="user"
                                    id="full_screen"
                                  />{" "}
                                </Link>
                                <Link to="#" className="zom-img">
                                  <img
                                    onClick={this.handleHorizontalArrow}
                                    src="images/twoarow.png"
                                    className=" img-fluid float-left"
                                    alt="user"
                                    id="expand"
                                  />{" "}
                                </Link>
                                <span
                                  onClick={this.handlePDFRotate}
                                  className="cursorPointer"
                                >
                                  <img
                                    src="images/undo.png"
                                    className=" img-fluid float-left undo-img"
                                    alt="user"
                                  />
                                </span>

                                <Link
                                  to="#"
                                  className="zom-img float-right ml-md-5 mr-0 pr-2 pl-2 more-d mt-0"
                                >
                                  <img
                                    src="images/more.png"
                                    className=" img-fluid"
                                    alt="user"
                                  />{" "}
                                </Link>
                                <Link
                                  to="#"
                                  className="zom-img float-right mt-0"
                                  onClick={this.moveToNextExpense}
                                >
                                  <img
                                    src="images/arow-r.png"
                                    className=" img-fluid lr-arrow-up"
                                    alt="user"
                                    href="#demo"
                                    data-slide="next"
                                  />{" "}
                                </Link>
                                <Link
                                  to="#"
                                  className="zom-img float-right mtop-1"
                                  onClick={this.moveToPrevExpense}
                                >
                                  <img
                                    src="images/arow-l.png"
                                    className=" img-fluid lr-arrow-up"
                                    alt="user"
                                    href="#demo"
                                    data-slide="prev"
                                  />{" "}
                                </Link>

                                <div className="side-attachments-2 exp-side-attachment2 aside__right--height">
                                  <div
                                    onClick={this.regenerateExpenseSignatures}
                                    className="main-sec-attach main-bg"
                                  >
                                    Regenerate
                                  </div>
                                  {
                                    this.state.showExpTalliesTabPane === "Approved" ?
                                      <div
                                        onClick={() => this.resendEmail()}
                                        className="main-sec-attach main-bg"
                                      >
                                        Resend Approved Email
                                      </div>
                                      : null
                                  }
                                 {userType === 'admin' && <div
                                    onClick={() => this.setState({showTransferRecordsModal: true})}
                                    className="main-sec-attach main-bg"
                                  >
                                    Transfer Records
                                  </div>}
                                  <div
                                    className="main-sec-attach main-bg"
                                    onClick={() => this.getTaxCodes()}
                                  >
                                    Create Invoice
                                  </div>
                                  <div
                                    onClick={this.downloadPreview}
                                    className="main-sec-attach main-bg"
                                  >
                                    Download Copy
                                    <img
                                      src="images/downlod.png"
                                      className=" img-fluid float-right fa"
                                      alt="user"
                                    />
                                  </div>
                                   <div
                                    onClick={this.downloadSelectedAttachments}
                                    className="main-sec-attach main-bg"
                                  >
                                    Download Attachments
                                    <img
                                      src="images/downlod.png"
                                      className=" img-fluid float-right fa"
                                      alt="user"
                                    />
                                  </div>
                                  <div
                                    onClick={this.balanceTax}
                                    className="main-sec-attach main-bg"
                                  >
                                    Balance Tax
                                  </div>
                                  <div
                                    onClick={() =>
                                      this.openModal("openReportModal")
                                    }
                                    className="main-sec-attach main-bg"
                                  >
                                    Reports
                                  </div>
                                  {tab === "draft" ? (
                                    <div
                                      onClick={() =>
                                        this.openModal("openImportModal")
                                      }
                                      className="main-sec-attach main-bg"
                                    >
                                      Import
                                    </div>
                                  ) : (
                                    ""
                                  )}

                                  <div
                                    onClick={() =>
                                      this.openModal("openDebitCardsModal")
                                    }
                                    className="main-sec-attach main-bg"
                                  >
                                    Debit Cards
                                  </div>
                                  <div
                                    onClick={() =>
                                      this.openModal(
                                        "openDebitCardReconciliationModal"
                                      )
                                    }
                                    className="main-sec-attach main-bg"
                                  >
                                    Debit Card Reconciliation
                                  </div>
                                  <div className="main-sec-attach main-bg" onClick={(e)=> this.collapseIconsChanged('export', !this.state.collapseIcon.export )}>
                                    <span
                                      className="export_crd"
                                      data-toggle="collapse"
                                      data-target="#export"
                                    >
                                      <span  className={ this.state.collapseIcon.export ? "fa fa-angle-up float-left mr-2 sideBarAccord1 " : "fa fa-angle-down float-left mr-2 sideBarAccord1" }></span>
                                      Export
                                    </span>
                                  </div>
                                  <div className="collapse" id="export">
                                    {["LIST", "ENVELOPE"].map((op, i) => {
                                      return (
                                        <div key={i} className="pl-2 mb-3">
                                          <div
                                            className="form-group remember_check d-flex"
                                            onClick={() => this.onExport(op)}
                                          >
                                            <span className="text-mar cursorPointer ml-38">
                                              {op}
                                            </span>
                                          </div>
                                        </div>
                                      );
                                    })}
                                  </div>

                                  <div className="main-sec-attach main-bg">
                                    <span
                                      className="accordian__text bank_details"
                                      data-toggle="collapse"
                                      data-target="#bank_details "
                                      onClick={() => { this.getSupplier(); }}
                                    >
                                      <span className="fa fa-angle-down float-left mr-2 sideBarAccord1"></span>
                                      Bank Details
                                    </span>
                                  </div>
                                  <div
                                    className="collapse"
                                    id="bank_details"
                                  >
                                    <div className="pl-2 mb-3">
                                      <div className="form-group remember_check d-flex pt-0">
                                      <div className="ml-33 pt-1">
                                          {
                                            this.displayBankDetail(bankDetails?.paymentType, 'paymentType')
                                          }
                                          {
                                            this.displayBankDetail(bankDetails?.accountName, 'accountName')
                                          }
                                          {
                                            this.displayBankDetail(bankDetails?.bank, 'bank')
                                          }
                                          {
                                            this.displayBankDetail(bankDetails?.sort, 'sort')
                                          }
                                          {
                                            this.displayBankDetail(bankDetails?.accountNo, 'accountNo')
                                          }
                                          {
                                            this.displayBankDetail(bankDetails?.suffix, 'suffix')
                                          }
                                          {
                                            this.displayBankDetail(bankDetails?.iban, 'iban')
                                          }
                                          {
                                            this.displayBankDetail(bankDetails?.bankReference, 'bankReference')
                                          }
                                          {
                                            this.displayBankDetail(bankDetails?.currencyCode, 'currencyCode')
                                          }
                                          {
                                            this.displayBankDetail(bankDetails?.swift, 'swift')
                                          }
                                          {
                                            this.displayBankDetail(bankDetails?.countryCode, 'countryCode')
                                          }
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="main-sec-attach main-bg" onClick={(e)=> this.collapseIconsChanged('approvals', !this.state.collapseIcon.approvals )}>
                                    <span
                                      className="invoice-inherit"
                                      data-toggle="collapse"
                                      data-target="#Approvals_expense"
                                    >
                                     <span  className={ this.state.collapseIcon.approvals ? "fa fa-angle-up float-left mr-2 sideBarAccord1 " : "fa fa-angle-down float-left mr-2 sideBarAccord1" }></span>
                                      Approvals
                                    </span>
                                  </div>

                                  <div
                                    className="collapse"
                                    id="Approvals_expense"
                                  >
                                    {this.state.approvalsGroups.map((a, i) => {
                                      return (
                                        <div key={i} className="pl-2 mb-3">
                                          <div className="form-group remember_check d-flex">
                                            <div className="checkSide">
                                              <input
                                                type="checkbox"
                                                id={i + "Expense"}
                                                name={a.groupName}
                                                checked={a.checked}
                                                onChange={(e) =>
                                                  this.handleApprovalsFilters(
                                                    e,
                                                    a
                                                  )
                                                }
                                              />
                                              <label htmlFor={i + "Expense"}>
                                                {" "}
                                              </label>
                                            </div>
                                            <span className="text-mar">
                                              {a.groupName}{" "}
                                            </span>
                                          </div>
                                        </div>
                                      );
                                    })}
                                  </div>

                                  <div className="main-sec-attach main-bg"  onClick={(e)=> this.collapseIconsChanged('activity', !this.state.collapseIcon.activity )}>
                                    <span
                                      className="fa fa-angle-down float-left mr-2 sideBarAccord"
                                      data-toggle="collapse"
                                      data-target="#Activity_expense"
                                    ></span>
                                    <span
                                      className="name_attached font-weight-bold"
                                      onClick={() =>
                                        this.openModal("openActivityModal")
                                      }
                                    >
                                      Activity
                                      <span className="fa fa-angle-right"></span>
                                    </span>
                                  </div>
                                  <div
                                    className="collapse"
                                    id="Activity_expense"
                                  >
                                    {this.state.activity.map((a, i) => {
                                      return (
                                        <div
                                          key={i}
                                          className="main-sec-attach1"
                                        >
                                          {a.description || a.comment}
                                          <p className="gry-clr mb-0">
                                            {a.date} {a.time}
                                          </p>
                                        </div>
                                      );
                                    })}
                                  </div>
                                  <div
                                    onClick={this.openMoveBatchPopup}
                                    className="main-sec-attach main-bg"
                                  >
                                    Move
                                  </div>
                                  <div
                                    onClick={this.openPostModal}
                                    className="main-sec-attach main-bg"
                                  >
                                    Post
                                  </div>
                                  {/* batch list start here  */}
                                  <div className="main-sec-attach main-bg" onClick={(e)=> this.collapseIconsChanged('batchList', !this.state.collapseIcon.batchList )}>
                                    <span
                                      className="batchList-inherit"
                                      data-toggle="collapse"
                                      data-target="#batchlist"
                                    >
                                     <span  className={ this.state.collapseIcon.batchList ? "fa fa-angle-up float-left mr-2 sideBarAccord1 " : "fa fa-angle-down float-left mr-2 sideBarAccord1" }></span>
                                      Batch List
                                    </span>
                                  </div>
                                  <div className="collapse" id="batchlist">
                                    <div className="pl-2 mb-3">
                                      <div className="text-right pb-2 pr-4">
                                        <span
                                          class="cursorPointer mr-3 d-none"
                                          href="#"
                                          onClick={this.insertBatch}
                                        >
                                          <img
                                            src="images/add.png"
                                            class=" img-fluid "
                                            alt="user"
                                          />
                                        </span>
                                        <span
                                          class="cursorPointer d-none"
                                          onClick={this.deleteBatch}
                                        >
                                          <img
                                            src="images/delete.svg"
                                            class="invoice-delete-icon img-fluid "
                                            alt="user"
                                          />
                                        </span>
                                      </div>
                                      <table className="table table-bordered mb-0 order-collapse-table batch-list-table">
                                        <tbody>
                                          <tr>
                                            <th></th>
                                            <th>Description</th>
                                            <th>Batch</th>
                                            <th></th>
                                          </tr>
                                          {clonedBatchList.map((b, i) => {
                                            return (
                                              <tr>
                                                <td>
                                                  <div class="form-group remember_check">
                                                    <input
                                                      type="checkbox"
                                                      id={"batch" + i}
                                                      name="batch_checked"
                                                      checked={
                                                        b.checked
                                                      }
                                                      onChange={(e) =>
                                                        this.handleBatchCheckbox(
                                                          e,
                                                          b.batchNo
                                                        )
                                                      }
                                                    />
                                                    <label
                                                      htmlFor={"batch" + i}
                                                    ></label>
                                                  </div>
                                                </td>
                                                <td>
                                                  <input
                                                    className="border-0"
                                                    name="description"
                                                    autoComplete="off"
                                                    value={b.description}
                                                  />
                                                </td>
                                                <td>
                                                  {b.insertBatch ? (
                                                    <input
                                                      className="border-0"
                                                      name="batchNo"
                                                      value={b.batchNo}
                                                      onBlur={(e) =>
                                                        this.addUpdateBatch(
                                                          e,
                                                          b,
                                                          i
                                                        )
                                                      }
                                                      onChange={(e) =>
                                                        this.handleChangeBatchFields(
                                                          e,
                                                          b,
                                                          i
                                                        )
                                                      }
                                                    />
                                                  ) : (
                                                    b.batchNo
                                                  )}
                                                </td>
                                                <td></td>
                                              </tr>
                                            );
                                          })}
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                  {/* end  Batch list  */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="img-section-t col-12 pl-0">
                      {/* <img src="images/image6.png" className=" img-fluid" alt="user" />  */}
                      <div
                        className={
                          this.state.toggleRightSidebar
                            ? " mm_ordr1 order_pdf_new invo_margin"
                            : " mm_ordr1 order_pdf_expand1 m_auto"
                        }
                        id="overload_image--invoice"
                      >
                        <div
                          id="maped_image"
                          className="order_pfd over_auto_remove"
                          style={{ background: "#fff" }}
                        >
                          {this.state.getExpenseList.length > 0 && (
                            <div
                              id="demo"
                              className={
                                this.state.toggleRightSidebar
                                  ? " carousel slide invoice_carousel mm_invoice_div over_auto_remove"
                                  : " carousel slide invoice_carousel "
                              }
                              // data-ride="carousel"
                              data-interval={false}
                            >
                              <ul className="carousel-indicators">
                                <li
                                  data-target="#demo"
                                  data-slide-to="0"
                                  className="active"
                                ></li>
                                <li data-target="#demo" data-slide-to="1"></li>
                                <li data-target="#demo" data-slide-to="2"></li>
                              </ul>

                              <div className="carousel-inner">
                                {this.state.previewList.length > 0
                                  ? this.state.previewList.map((p, i) => {
                                      const currentIndex = this.state.currentAttachmentIndex;
                                      const currentFile = this.state.previewList[currentIndex]?.file;
                                      return (
                                        <div
                                          className={
                                            i === 0
                                              ? "carousel-item active "
                                              : "carousel-item "
                                          }
                                          id={i}
                                        >
                                          <div className="text-center">
                                            <div className="invoice_pdf_canvas invoice_pdf_new pdf--buttons pdf__height--content expensis-home">
                                              {currentFile ? (
                                                <>
                                                  <Document
                                                    file={
                                                      "data:application/pdf;base64," +
                                                      currentFile
                                                    }
                                                    onLoadSuccess={(data) =>
                                                      this.onDocumentLoadSuccess(
                                                        data,
                                                        i
                                                      )
                                                    }
                                                    rotate={this.state.rotate}
                                                  >
                                                    <Page
                                                      pageNumber={
                                                        this.state.pageNumber
                                                      }
                                                      scale={this.state.scaling}
                                                      height="372"
                                                      onLoadSuccess={
                                                        this.onLoadSuccessPage
                                                      }
                                                    />
                                                  </Document>
                                                  <div className="page-controls">
                                                    <button
                                                      type="button"
                                                      disabled=""
                                                      onClick={
                                                        this.goToPrevPage
                                                      }
                                                    >
                                                      ‹
                                                    </button>
                                                    <span>
                                                      {this.state.pageNumber} of{" "}
                                                      {this.state.numPages}
                                                    </span>{" "}
                                                    <button
                                                      type="button"
                                                      onClick={
                                                        this.goToNextPage
                                                      }
                                                    >
                                                      ›
                                                    </button>
                                                  </div>
                                                </>
                                              ) : (
                                                ""
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    })
                                  : "No Preview Found"}

                                {this.state.previewableAttachments.length > 1 && (
                                  <>
                                    <a
                                      className="carousel-control-prev"
                                      href="#demo"
                                      data-slide="prev"
                                      onClick={() => this.onSlideChange('prev')}
                                    >
                                      <i>
                                        <span className="carousel-control-prev-icon"></span>
                                      </i>
                                    </a>
                                    <a
                                      className="carousel-control-next"
                                      href="#demo"
                                      data-slide="next"
                                      onClick={() => this.onSlideChange('next')}
                                    >
                                      <i>
                                        <span className="carousel-control-next-icon"></span>
                                      </i>
                                    </a>
                                  </>
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="side-attachments exp-side-attachment aside__right--height">
                        <div
                          className="cus-arro-div"
                          onClick={this.handleRightSidebar}
                        >
                          <img
                            src="images/arrow-r.png"
                            className=" img-fluid cus-arro-r"
                            alt="user"
                          />
                        </div>
                        <div className="side-attack">
                          <div className="main-sec-attach main-bg">
                            {/*Expense Attachments */}
                            <span
                              className="fa fa-angle-up float-left mr-2 sideBarAccord"
                              data-toggle="collapse"
                              data-target="#Attachments_expense"
                            ></span>
                            <span
                              className="name_attached"
                              onClick={() =>
                                this.openModal("openAttachmentsModal")
                              }
                            >
                              Attachments
                              <span className="ml-3 font-weight-bold">
                                {this.state.attachments.length}
                              </span>
                              <span className="fa fa-angle-right"></span>
                              <a class="float-right mr-3" href="#">
                                <img
                                  src="images/add.png"
                                  class=" img-fluid sidebarr_plus "
                                  alt="user"
                                />
                              </a>
                            </span>
                          </div>
                          <div
                            className="collapse show"
                            id="Attachments_expense"
                          >
                            {this.state.attachments.map((a, i) => {
                              return (
                                <div
                                  onClick={() =>
                                    this.getAttachment(
                                      a.id || a.recordID,
                                      "",
                                      a.fileName
                                    )
                                  }
                                  key={i}
                                  className="main-sec-attach"
                                >
                                  {a.fileName}{" "}
                                  <span className="fa fa-angle-right"></span>
                                </div>
                              );
                            })}
                          </div>

                          {/* side menue Approvers / Approvals */}

                          <div className="main-sec-attach main-bg">
                            <span
                              className="invoice-inherit"
                              data-toggle="collapse"
                              data-target="#Approvals_expense"
                            >
                              <span className="fa fa-angle-up float-left mr-2 sideBarAccord1"></span>
                              Approvals
                            </span>
                          </div>
                          <div className="collapse show" id="Approvals_expense">
                            {this.state.approverGroup.value &&
                              this.state.approverGroup.value.trim() && (
                                <div
                                  className={
                                    approverNextPrevCheck
                                      ? "main-sec-mid"
                                      : "main-sec-mid1"
                                  }
                                >
                                  {approverNextPrevCheck && (
                                    <button
                                      // onClick={sendEmail}
                                      type="button"
                                      className="btn-save1 add_comment_btns1"
                                      onClick={() =>
                                        this.openModal(
                                          "openCheckApprovalModal",
                                          "Prev"
                                        )
                                      }
                                    >
                                      <span></span>
                                      Previous
                                    </button>
                                  )}
                                  <small>
                                    {this.state.approverGroup.value}
                                  </small>
                                  {approverNextPrevCheck && (
                                    <button
                                      // onClick={sendEmail}
                                      type="button"
                                      className="btn-save1 add_comment_btns"
                                      onClick={() =>
                                        this.openModal(
                                          "openCheckApprovalModal",
                                          "Next"
                                        )
                                      }
                                    >
                                      <span></span>
                                      Next
                                    </button>
                                  )}
                                </div>
                              )}
                            {this.state.approvers.map((a, i) => {
                              return (
                                <div
                                  key={i}
                                  className="main-sec-attach cus-check"
                                >
                                  <div className="form-group remember_check">
                                    {a.status === "Approved" ? (
                                      <input type="checkbox" id={i} checked />
                                    ) : (
                                      ""
                                    )}
                                    {a.status === "Declined" ? (
                                      <i
                                        className="fa fa-times circle-check1 float-left ml-1"
                                        aria-hidden="true"
                                      ></i>
                                    ) : a.status === "Current" ? (
                                      <i
                                        className="fa fa-circle-thin circle-check float-left ml-1"
                                        aria-hidden="true"
                                        style={{ marginTop: "3px" }}
                                      ></i>
                                    ) : (
                                      ""
                                    )}
                                    <label htmlFor={i}>
                                      {" "}
                                      <span
                                        title={a.name}
                                        className={`${
                                          a.status === "Declined"
                                            ? "order-right-color2 selected mm_lcapp"
                                            : a.status === "Current"
                                            ? "order-right-color1 selected mm_lcapp"
                                            : "text-mar"
                                        } approver-name-lbl 
                                            ${
                                              a.status === "Current" ||
                                              a.status === "Declined"
                                                ? "approver-name-lbl-wide"
                                                : ""
                                            }
                                            ${
                                              userType === "admin" &&
                                              a.status === "Current"
                                                ? "approver-name-lbl-medium"
                                                : ""
                                            }`}
                                      >
                                        {a.name}
                                      </span>
                                      {a.status === "Declined" ? (
                                        <span className="current-approver mm_approre1">
                                          {" "}
                                          (user declined)
                                        </span>
                                      ) : a.status === "Current" ? (
                                        <span className="current-approver mm_approre1">
                                          {" "}
                                          (current approver)
                                          {userType === "admin" && (
                                            <button
                                              onClick={() => this.resendEmail()}
                                              className="btn-save2"
                                            >
                                              Send Email
                                            </button>
                                          )}
                                        </span>
                                      ) : (
                                        ""
                                      )}
                                    </label>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                          {/* Expense Comments */}
                          <div className="main-sec-attach main-bg">
                            <span
                              className="fa fa-angle-up float-left mr-2 sideBarAccord"
                              data-toggle="collapse"
                              data-target="#Comments_expense"
                            ></span>
                            <span
                              className="name_attached"
                              onClick={() =>
                                this.openModal("openCommentsModal")
                              }
                            >
                              Comments
                              <span className="ml-3 font-weight-bold">
                                {this.state.comments.length}
                              </span>
                              <span className="fa fa-angle-right"></span>
                              {this.state.showExpTalliesTabPane === "Draft" ||
                              this.state.showExpTalliesTabPane === "Pending" ||
                              this.state.showExpTalliesTabPane === "Approve" ||
                              this.state.showExpTalliesTabPane === "Hold" ||
                              this.state.showExpTalliesTabPane ===
                                "Declined" ? (
                                <a className="float-right mr-3" href="#">
                                  <img
                                    src="images/add.png"
                                    className=" img-fluid sidebarr_plus "
                                    alt="user"
                                  />
                                </a>
                              ) : null}
                            </span>
                          </div>
                          <div className="collapse show" id="Comments_expense">
                            {this.state.comments.map((c, i) => {
                              return (
                                <div key={i} className="main-sec-attach1">
                                  <p className="m-clr s-bold mb-0">
                                    {c.userName}
                                  </p>
                                  {c.comment}
                                  <p className="gry-clr mb-0">
                                    {c.date} {c.time}
                                  </p>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
          {/* end */}
        </div>

        <ConfirmationModal
          openConfirmationModal={isModalOpen}
          closeModal={this.closeConfrimationModal}
          title={modalContent.title}
          body={modalContent.body}
          actionButtonName={modalContent.actionButtonName}
          onConfirmation={modalContent.onConfirm}
        />

        <Decline
          openDeclineModal={this.state.openDeclineModal}
          closeModal={this.closeModal}
          onDecline={this.declineExpense}
        />

        <Attachments
          openAttachmentsModal={this.state.openAttachmentsModal}
          closeModal={this.closeModal}
          addAttachment={this.addAttachment}
          attachments={this.state.attachments}
          getAttachment={this.getAttachment}
          attachmentSize={this.state.attachmentSize}
          draft={tab === "draft" ? true : false} //to hide/show "Drag Files in or Click to Upload" box
        />

        <Comments
          openCommentsModal={this.state.openCommentsModal}
          closeModal={this.closeModal}
          comments={this.state.comments}
          addComment={this.addComment}
          tab={tab}
          page="expense"
        />

        <Activity
          openActivityModal={this.state.openActivityModal}
          closeModal={this.closeModal}
          activity={this.state.activity}
        />

        <Changes
          openChangesModal={this.state.openChangesModal}
          closeModal={this.closeModal}
          changes={[]}
        />
        <Report
          openReportModal={this.state.openReportModal}
          closeModal={this.closeModal}
          reportType="Expenses"
          locationProps={this.props}
        />

        <ExpenseDetails
          convertTwoDecimal={this.convertTwoDecimal}
          handleChangeInLineFields={this.handleChangeInLineFields}
          updateExpenseLines={this.updateExpenseLines}
          tran={this.state.tran}
          guid={this.state.guid}
          openExpenseDetailModal={this.state.openExpenseDetailModal}
          closeModal={this.closeModal}
          openModal={this.openModal}
          chartSorts={this.props.chart.getChartSorts || ""} //api response (get chart sort)
          chartCodes={this.state.chartCodesList || []} //api response (all chart codes)
          flags_api={this.props.chart.getFlags} //flags comming from get flags api
          flags={this.state.flags} //restructured flags accordings to requirements
          clonedFlags={this.state.clonedFlags} //a copy of flags
          expenseLines={this.state.expenseLines} //expense lines of an expense
          flagsPrompts={
            this.props.user?.getDefaultValues?.flags?.length > 0
              ? this.props.user.getDefaultValues.flags
              : []
          }
          updateNewExpenseLinesItem={this.updateNewExpenseLinesItem}
          handleMultipleChanges={this.handleMultipleChanges} //update invocie-lines according to multiple change modal
          props={this.props}
          handleCheckboxesInExpenseDetails={
            this.handleCheckboxesInExpenseDetails
          }
          tab={tab}
          getChartSorts={this.getChartSorts} //get chart sorts function
          handleChangeField={this.handleChangeField}
          handleChangeFlags={this.handleChangeFlags}
          hanldeExpenseLineFields={this.hanldeExpenseLineFields}
          handleInLine={this.handleInLine}
          changeChartCode={this.changeChartCode}
          getChartCodes={this.getChartCodes}
          chartCodesList={this.state.chartCodesList}
          clonedChartCodesList={this.state.clonedChartCodesList}
          chartCodeSuggestion={this.state.chartCodeSuggestion}
          amount={this.state.amount}
          // currency={this.state.currency || '' }
          description={this.state.description || ""}
          baseOptions={this.state.baseOptions || []}
        />

        <ExpenseMoreDetail
          openExpenseMoreDetailsModal={this.state.openExpenseMoreDetailsModal}
          updateExpenseSummaryState={this.updateExpenseSummaryState}
          getExpenseSummary={this.getExpenseSummary}
          closeModal={this.closeModal}
          state={this.state}
          showExpTalliesTabPane={this.state.showExpTalliesTabPane}
        />

        <Delete
          openDeleteModal={this.state.openDeleteModal}
          closeModal={this.closeModal}
          onDelete={this.deleteExpense}
        />
        <CreateInvoice
          openCreateInvoiceModal={this.state.openCreateInvoiceModal}
          closeModal={this.closeModal}
          createInvoice={this.createInvoice}
          taxCode={this.state.taxCode}
          taxCodeList={this.state.taxCodeList}
          bankCode={this.state.bankCode}
          bankCodeList={this.state.bankCodeList}
          handleSelectFields={this.handleSelectFields}
          formErrors={this.state.formErrors}
        />

        <Import
          state={this.state}
          closeModal={this.closeModal}
          page="expense"
          onImport={this.onImport}
        />
        <DebitCards
          openDebitCardsModal={this.state.openDebitCardsModal}
          closeModal={this.closeModal}
          importDebitTransactions={this.importDebitTransactions}
          emailEnvelope={this.emailEnvelope}
          getExpenseCodes={this.getExpenseCodes}
          expenseCodes={this.state.expenseCodes}
        />
        <Move
          closeModal={this.closeModal}
          stateDate={this.state}
          moveBatch={this.moveBatch}
        />

        <Post
          openPostModal={this.state.openPostModal}
          closeModal={this.closeModal}
          postType="Expenses"
          onSave={this.postExpense}
          locationProps={this.props}
        />
        <DebitCardReconciliation
          openDebitCardReconciliationModal={
            this.state.openDebitCardReconciliationModal
          }
          closeModal={this.closeModal}
        />
        {userType === "admin" && (
          <CheckApproval
            openCheckApprovalModal={this.state.openCheckApprovalModal}
            closeModal={this.closeModal}
            state={this.state}
            moveToNextApprover={this.moveToNextApprover}
            moveToPrevApprover={this.moveToPrevApprover}
          />
        )}
            {
          userType === 'admin' && this.state.showTransferRecordsModal && (
            <TransferRecords
              closeModal={() => this.setState({ showTransferRecordsModal: false })}
              selectedItemCount={this.state.allSelected ? undefined : this.state.multipleTrans.length || 1}
              itemNameList={this.state.allSelected ? undefined :
                [...new Set( this.state.multipleTrans.length ? this.state.multipleTrans : [...this.state.multipleTrans, this.state.guid])].map(guid => {
                const expense = this.state.getExpenseList.find(expense => expense.guid === guid);
                return `EXP#${expense.guid} ${expense.supplier}`
              }) }
              onSubmit={this.handleTransferExpenses}
            />
          )
        }
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    expenseData: state.expense,
    chart: state.chart,
    user: state.user,
    setup: state.setup,
    supplier: state.supplier,
    pageData: state.pagination.pageData["expenses"],
  };
};

export default connect(mapStateToProps, {
  getNewExpenseList: ExpenseActions.getNewExpenseList,
  getExpenseTallies: ExpenseActions.getExpenseTallies,
  getExpenseList: ExpenseActions.getExpenseList,
  getExpenseSummary: ExpenseActions.getExpenseSummary,
  deleteExpense: ExpenseActions.deleteExpense,
  SendForApproval: ExpenseActions.sendExpForApproval,
  getExpAttachment: ExpenseActions.getExpAttachment,
  addExpAttachment: ExpenseActions.addExpAttachment,
  addComment: ExpenseActions.addComment,
  approveExpense: ExpenseActions.approveExpense,
  declineExpense: ExpenseActions.declineExpense,
  moveExpense: ExpenseActions.moveExpense,
  holdExpense: ExpenseActions.holdExpense,
  exportList: ExpenseActions.exportList,
  createInvoice: ExpenseActions.createInvoice,
  exportEnvelope: ExpenseActions.exportEnvelope,
  emailEnvelope: ExpenseActions.emailEnvelope,
  balanceTax: ExpenseActions.balanceTax,
  importDebitTransactions: ExpenseActions.importDebitTransactions,
  postExpense: ExpenseActions.postExpense,
  moveBatch: ExpenseActions.moveBatch,
  importEnvelope: ExpenseActions.importEnvelope,
  importList: ExpenseActions.importList,
  importSplitExpense: ExpenseActions.importSplitExpense,
  importFuelExpense: ExpenseActions.importFuelExpense,
  clearExpenseStates: ExpenseActions.clearExpenseStates,
  GetExpenseLines: ExpenseActions.GetExpenseLines,
  regenerateExpenseSignature: ExpenseActions.regenerateExpenseSignature,
  moveToNextApprover: ExpenseActions.moveToNextApprover,
  moveToPrevApprover: ExpenseActions.moveToPrevApprover,
  resendEmail: ExpenseActions.resendEmail,
  updateExpenseLines: ExpenseActions.updateExpenseLines,
  downloadAttachments: ExpenseActions.downloadExpAttachment,
  transferExpenses: ExpenseActions.transferExpenses,

  getFlags: ChartActions.getFlags,
  getDefaultValues: UserActions.getDefaultValues,
  getChartSorts: ChartActions.getChartSorts,
  getBtachList: SetupActions.getBtachList,
  deleteBatch: SetupActions.deleteBatch,
  updateBatch: SetupActions.updateBatch,
  insertBatch: SetupActions.insertBatch,
  getSupplier: SupplierActions.getSupplier,
  clearSupplierStates: SupplierActions.clearSupplierStates,
  clearSetupStates: SetupActions.clearSetupStates,
  clearChartStates: ChartActions.clearChartStates,
  getTaxCodes: ChartActions.getTaxCodes,
  getChartCodes: ChartActions.getChartCodes,
  clearUserStates: UserActions.clearUserStates,
  clearStatesAfterLogout: UserActions.clearStatesAfterLogout,
  setLastPage: PaginationActions.setLastPageNumber,
  resetLastPage: PaginationActions.resetLastPage,
})(Expense);
