import React, { Component } from "react";
import { connect } from "react-redux";
import Select from "react-select";

import Modal from "react-bootstrap/Modal";
import Dropdown from "react-bootstrap/Dropdown";
import { _customStyles } from "../../../../Constants/Constants";

import DatePicker from "react-datepicker";
import $ from "jquery";
import { Link } from "react-router-dom";
import {
  tableSetting,
  handleSaveSettings,
  handleCloseSettingModal,
  handleAPIErr,
  filterBox,
  downloadAttachments,
} from "../../../../Utils/Helpers";

import ReactTooltip from "react-tooltip";
import { ButtonGroup } from "react-bootstrap";
import "../BudgetImport/BudgetImport.css";
class EditHistory extends Component {
  constructor() {
    super();
    this.state = {
      isLoading: false,
    };
  }
  async componentDidMount() {
    // this.primePeriodClose();
    filterBox("ledgerhistory");
  }

  clearStates = () => {
    this.props.closeModal("openEditHistoryModal");
  };
  threeDotMenu = () => {
    $(".mm-blok21").toggleClass("doc_sidebar2");
  };

  render() {
    return (
      <>
        {this.state.isLoading ? <div className="se-pre-con"></div> : ""}

        <Modal
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={this.props.openEditHistoryModal}
          onHide={this.clearStates}
          className="mm_report_new_modal mx-auto reports-v4-modal"
        >
          <Modal.Body>
            <div className="container-fluid p-0">
              <div className="main_wrapper">
                <div className="row d-flex h-100 p-0">
                  <div className="col-12 justify-content-center align-self-center">
                    <div className="setting_form_main p-0 table_white_box">
                      <div className="setting_header thead_bg">
                        <h3 className="Indirecttaxcode-poup_heading">
                          Edit History List
                        </h3>
                      </div>
                      <div className="reportv4-modal-inner">
                        <div className="row">
                          <div class="col-12 col-lg-3 col-md-4 pl-md-0 mt-4 mb-4 ml-3">
                            <div class="d-flex justify-content-center h-100">
                              <div class="searchbar mm-searchbar_new">
                                <input
                                  class="search_input"
                                  type="text"
                                  name="detailsListSearch"
                                  placeholder="Search..."
                                  value=""
                                />
                                <a
                                  href="javascript:void(0)"
                                  class="search_icon search-boredr"
                                >
                                  <i class="fa fa-search"></i>
                                </a>
                                <a
                                  href="javascript:void(0)"
                                  class="search_icon"
                                  id="filter_dropdown"
                                >
                                  <i class="fa fa-filter"></i>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>

                        <body>
                          <div className="pb-1 login_form table-genrel">
                            <table
                              id="ledgerhistory"
                              width="100%"
                              className=" user_setup_table ladger-table-new"
                            >
                              <thead>
                                <tr>
                                  <th
                                    scope="col"
                                    className="exp_th1 mm_contact-name"
                                  >
                                    <div className="form-group">
                                      <label className="dash_container dash_remember table-check unckeck">
                                        <input
                                          type="checkbox"
                                          name={"chk1"}
                                          id={"chk1"}
                                          // checked={checkAllJrnltem}
                                          // onChange={(e) =>
                                          //   this.handleCheckboxes(e, "all")
                                          // }
                                        />
                                        <span
                                          id="chk1"
                                          className="dash_checkmark"
                                        ></span>
                                      </label>
                                    </div>
                                  </th>
                                  {this.props?.ledgerHistoryColumns?.map(
                                    (m, i) => {
                                      return (
                                        <th
                                          scope="col"
                                          key={i}
                                          className="text-left exp-supplier-th"
                                        >
                                          <span className="table_lines_hed">
                                            {" "}
                                            {m.ColName}
                                          </span>
                                        </th>
                                      );
                                    }
                                  )}

                                  <th>
                                    <span
                                      className="user_setup_hed2"
                                      // onClick={() =>
                                      //   this.openModal("openSettingsModal")
                                      // }
                                    >
                                      {" "}
                                      <img
                                        src="./images/user-setup/bars.png"
                                        alt="bars"
                                      ></img>
                                    </span>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.props?.ledgerHistoryRows?.map(
                                  (item, i) => {
                                    return (
                                      <tr key={i}>
                                        <td>
                                          <div className="col align-self-center text-center pr-0">
                                            <div className="form-group mb-0 check-line">
                                              <label className="dash_container dash_remember table-check unckeck">
                                                <input
                                                  type="checkbox"
                                                  name={"chk1"}
                                                  id={"chk1" + i}
                                                  checked={item.checked}
                                                  // onChange={(e) =>
                                                  //   this.handleCheckboxes(
                                                  //     e,
                                                  //     item
                                                  //   )
                                                  // }
                                                />
                                                <span
                                                  id={"chk1" + i}
                                                  className="dash_checkmark"
                                                ></span>
                                              </label>
                                            </div>
                                          </div>
                                        </td>

                                        {this.props?.ledgerHistoryColumns?.map(
                                          (c, j) => {
                                            let ColType = c.ColType;
                                            if (
                                              ColType === "Number" ||
                                              ColType === "Numeric"
                                            ) {
                                              return (
                                                <td
                                                  className="text-left"
                                                  data-order={item.data[j]}
                                                >
                                                  <input
                                                    type="number"
                                                    name="chartSort"
                                                    value={item.data[j]}
                                                    className="input_height wd-108"
                                                    readOnly={
                                                      c.Editable ? false : true
                                                    }
                                                    onBlur={this.updateLedgers}
                                                    onChange={(e) => {
                                                      this.handleChangeGeneralLedgerList(
                                                        e,
                                                        item.data,
                                                        i,
                                                        j
                                                      );
                                                    }}
                                                  />
                                                </td>
                                              );
                                            } else if (ColType === "Date") {
                                              return (
                                                <>
                                                  <td
                                                    className="text-left"
                                                    data-order={item.data[j]}
                                                  >
                                                    <input
                                                      type="Date"
                                                      name="chartSort"
                                                      value={item.data[j]}
                                                      className="input_height wd-108"
                                                      readOnly={
                                                        c.Editable
                                                          ? false
                                                          : true
                                                      }
                                                      onBlur={
                                                        this.updateLedgers
                                                      }
                                                      onChange={(e) => {
                                                        this.handleChangeGeneralLedgerList(
                                                          e,
                                                          item.data,
                                                          i,
                                                          j
                                                        );
                                                      }}
                                                    />
                                                  </td>
                                                </>
                                              );
                                            } else {
                                              return (
                                                <>
                                                  <td
                                                    className="text-left"
                                                    data-order={item.data[j]}
                                                  >
                                                    <input
                                                      type="text"
                                                      name="chartSort"
                                                      value={item.data[j]}
                                                      className="input_height wd-108"
                                                      readOnly={
                                                        c.Editable
                                                          ? false
                                                          : true
                                                      }
                                                      onBlur={
                                                        this.updateLedgers
                                                      }
                                                      onChange={(e) => {
                                                        this.handleChangeGeneralLedgerList(
                                                          e,
                                                          item.data,
                                                          i,
                                                          j
                                                        );
                                                      }}
                                                    />
                                                  </td>
                                                </>
                                              );
                                            }
                                          }
                                        )}
                                        <td
                                          className="text-left"
                                          data-order={""}
                                        ></td>
                                      </tr>
                                    );
                                  }
                                )}
                              </tbody>
                            </table>
                          </div>
                        </body>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}
const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(EditHistory);
