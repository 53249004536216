import React, { Component } from "react";

import Modal from "react-bootstrap/Modal";
import "react-datepicker/dist/react-datepicker.css";
import ChartSort from "../ChartSort/ChartSort";
import ChartCode from "../ChartCode/ChartCode";
import { toast } from "react-toastify";
import momentZones from "moment-timezone";
import { connect } from "react-redux";
import { _customStyles } from "../../../../Constants/Constants";
import * as AccountActions from "../../../../Actions/AccountActions/AccountActions";
import { handleAPIErr } from "../../../../Utils/Helpers";
//setting all dates and time in GMT format
momentZones.tz.setDefault("GMT");

class DistributionChange extends Component {
  constructor() {
    super();
    this.state = {
      isLoading: false,
      openTaxCodeModal: false,
      openChartSortModal: false,
      openChartCodeModal: false,
      openTrackingCodeModal: false,

      idList: "",
      chartSort: "",
      chartCode: "",
      chartDescripton: "",
      trackingCodeCheck: false,
      chartCodeCheck: false,
      chartSortCheck: false,
      formErrors: {
        chartSort: "",
        // chartCode: "",
        trackingCode: "",
      },
    };
  }

  componentWillReceiveProps(nextProps) {
    let tableRows = this.props.tableRows || [];
    let find = tableRows?.filter((item) => item.checked);
    let idList = find?.map((item) => item.refId);
    let cloneIdList = idList.toString();
    this.setState({ idList: cloneIdList });
  }
  openModal = (name) => {
    if (name === "openChartCodeModal") {
      this.setState({ openChartCodeModal: true });
    } else if (name === "openChartSortModal") {
      //  this.props.getChartSorts();
      this.setState({ openChartSortModal: true });
    } else {
      this.setState({ [name]: true });
    }
  };
  closeModal = (name) => {
    this.setState({ [name]: false });
  };

  //get new chart sort value through chart sort modal
  getUpdatedChartSort = async (chartSort) => {
    if (chartSort) {
      this.setState({ chartSort });
    }
    this.validateField("chartSort", chartSort);
  };

  //get new chart code value through chart code modal
  getUpdatedChartCode = (chartCode, chartDescription) => {
    this.setState({ chartCode, chartDescription });
  };
  validateField = (name, value) => {
    let formErrors = this.state.formErrors;
    switch (name) {
      case "chartSort":
        if (value.length < 1) {
          formErrors.chartSort = "This Field is Required.";
        } else {
          formErrors.chartSort = "";
        }
        break;
      default:
        break;
    }
    this.setState({
      formErrors: formErrors,
    });
  };
  clearStates = () => {
    this.props.closeModal("openDistributionChangeModal");
    this.setState({
      trackingCodes: [],
      flags: [],
      clonedFlags: [],
      chartSort: "",
      chartCode: "",
      trackingCodeCheck: false,
      chartCodeCheck: false,
      chartSortCheck: false,
      formErrors: {
        chartSort: "",
        // chartCode: "",
        trackingCode: "",
      },
    });
  };

  onSave = async () => {
    this.setState({ isLoading: true });
    let { chartSort, chartCode, idList } = this.state;

    // let formErrors = this.state.formErrors;
    // if (
    //   !formErrors.trackingCode &&
    //   !formErrors.chartSort
    //   // !formErrors.chartCode
    // ) {
    //   let obj = {};

    //   if (chartCodeCheck) {
    //     obj.chartCode = chartCode;
    //   }
    //   if (chartSortCheck) {
    //     obj.chartSort = chartSort;
    //   }

    // }
    let obj = {
      chartCode,
      chartSort,
    };

    await this.props.createDistChange(idList, obj);
    if (this.props.account.createDistChangeSuccess) {
      toast.success(this.props.account.createDistChangeSuccess);
    }
    if (this.props.account.createDistChangeError) {
      handleAPIErr(this.props.account.createDistChangeError, this.props);
    }
    this.setState({ isLoading: false }, () => {
      if (this.props.account.createDistChangeSuccess) {
        this.props.closeModal("openDistributionChangeModal");
      }
    });
  };
  render() {
    return (
      <>
        {this.state.isLoading ? <div className="se-pre-con"></div> : ""}

        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={this.props.openDistributionChangeModal}
          onHide={this.clearStates}
          className="modal_704 mx-auto reports-v4-modal"
        >
          <Modal.Body>
            <div className="container-fluid p-0">
              <div className="main_wrapper">
                <div className="row d-flex h-100 p-0">
                  <div className="col-12 justify-content-center align-self-center ">
                    <div className="setting_form_main p-0">
                      <div className="setting_header thead_bg">
                        <h3 className="Indirecttaxcode-poup_heading">
                          Distribution Change
                        </h3>
                        <div className="Indirecttaxcode-poup_can-sav-btn">
                          <button
                            onClick={this.onSave}
                            type="button"
                            className="btn can-btn1 pl-3"
                          >
                            <span className="fa fa-check"></span>
                            Save
                          </button>

                          <button
                            onClick={this.clearStates}
                            type="button"
                            className="btn can-btn1 pl-3"
                          >
                            <span className="fa fa-ban"></span>
                            Cancel
                          </button>
                        </div>
                      </div>
                      <div className="forgot_body px-3">
                        <div className="row mt-4">
                          <div className="form-group col-12">
                            <div className="custon_select">
                              <label htmlFor="usr">Chart Sort</label>
                              <div className="modal_input">
                                <input
                                  type="text"
                                  className={"uppercaseText"}
                                  placeholder="Chart Sort"
                                  id="usr"
                                  name="chartSort"
                                  onChange={() => {}}
                                  value={this.state.chartSort}
                                />
                                <span className="input_field_icons">
                                  <i
                                    onClick={() =>
                                      this.openModal("openChartSortModal")
                                    }
                                    className="fa fa-search"
                                  ></i>
                                </span>
                              </div>
                            </div>
                            <div className="text-danger error-12">
                              {this.state.formErrors.chartSort !== ""
                                ? this.state.formErrors.chartSort
                                : ""}
                            </div>
                          </div>

                          <div className="form-group col-12">
                            <div className="custon_select">
                              <label htmlFor="usr">Chart Code</label>
                              {/* {ApproveHoldcheck ? ( */}
                              <div className="modal_input">
                                <input
                                  type="text"
                                  className={"focus_chartCode uppercaseText"}
                                  placeholder="Chart Code"
                                  id="chrtCode_id"
                                  autoComplete="off"
                                  name="chartCode"
                                  onChange={this.handleChangeChartCode}
                                  value={this.state.chartCode}
                                />

                                <span className="input_field_icons">
                                  <i
                                    onClick={() =>
                                      this.openModal("openChartCodeModal")
                                    }
                                    className="fa fa-search"
                                  ></i>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <ChartSort
          openChartSortModal={this.state.openChartSortModal}
          closeModal={this.closeModal}
          chartSorts={this.props.chartSorts || ""} //api response (get chart sort)
          defaultChartSort={this.state.chartSort} //chart sort that show on this page e.g 'AU.01.000'
          getUpdatedChartSort={this.getUpdatedChartSort} //get updated chart sort to show on this page
        />
        <ChartCode
          openChartCodeModal={this.state.openChartCodeModal}
          closeModal={this.closeModal}
          chartCodes={this.props.chartCodes || []} //all chart codes
          getUpdatedChartCode={this.getUpdatedChartCode} //get updated chart code to show on this page
          chartCode={this.state.chartCode} //value of chartCode (single value) that is shown in chart code input field
          props={this.props.props || ""}
          chartSort={this.state.chartSort}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  account: state.account,
});
export default connect(mapStateToProps, {
  createDistChange: AccountActions.createDistChange,
})(DistributionChange);
