import React, { useEffect, useRef, useState } from "react";

const SignatureCanvas = (props) => {
  const canvasRef = useRef(null);
  const [isDrawing, setIsDrawing] = useState(false);
  const [isStarted, setIsStarted] = useState(false);
  const [count, setCount] = useState(0);

  // Function to get canvas context
  const getCanvasContext = () => {
    return canvasRef.current.getContext('2d');
  };

  useEffect(() => {
    if (!props.tempImge && isStarted) {
      clearCanvas();
    }
  }, [props.tempImge]);

  // Function to clear the canvas
  const clearCanvas = () => {
    const canvas = canvasRef.current;
    const context = getCanvasContext();
    context.clearRect(0, 0, canvas.width, canvas.height);
  };

  // Function to start drawing
  const startDrawing = (e) => {
    const canvas = canvasRef.current;
    const context = getCanvasContext();
    let { offsetX, offsetY } = e.nativeEvent;
    // offsetX = offsetX  + ((offsetX *67)/100);
    // offsetY = offsetY  + ((offsetY *67)/100);
    // console.log(offsetX  + ((offsetX *67)/100));
    context.beginPath();
    context.moveTo(offsetX, offsetY);
    setIsDrawing(true);
  };

  // Function to continue drawing
  const continueDrawing = (e) => {
    if (!isDrawing) return;
    const canvas = canvasRef.current;
    const context = getCanvasContext();
    const { offsetX, offsetY } = e.nativeEvent;
    context.lineTo(offsetX, offsetY);
    context.stroke();
  };

  // Function to stop drawing
  const stopDrawing = () => {
    props.handleSetSignature(canvasRef.current.toDataURL())
    setIsDrawing(false);
  };

  // Event handlers for mouse events
  const handleMouseDown = (e) => startDrawing(e);
  const handleMouseMove = (e) => continueDrawing(e);
  const handleMouseUp = () => stopDrawing();

  // Event handlers for touch events
  const handleTouchStart = (e) => {
    e.preventDefault();
    startDrawing(e.touches[0]);

  };
  const handleTouchMove = (e) => {
    e.preventDefault();
    continueDrawing(e.touches[0]);
  };
  const handleTouchEnd = (e) => {
    e.preventDefault();
    stopDrawing();

  };

  function handleCLick() {
    setIsStarted(true)
  }
  function handleMouseLeave() {
    // setCount(count + 1);
    // if (count > 1){
    //   props.handleSetSignature(canvasRef.current.toDataURL())
    // }
    props.handleSetSignature(canvasRef.current.toDataURL())
  }

  return (
    <div style={{ transform: 'scale(0.67)', transformOrigin: '0 0' }} onMouseLeave={handleMouseLeave}>
      <canvas
        ref={canvasRef}
        width={props.width}
        height={props.height}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        onMouseMove={handleMouseMove}
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
        onTouchMove={handleTouchMove}
        style={{border:'2px', borderStyle:"solid", borderColor:"black"}}
        onClick={handleCLick}
      />
    </div>
  );
};

export default SignatureCanvas;
