import axiosDPFAPIClient from "../../Utils/axiosDPFAPIClient";

// ---------------------****Reports Actions****----------------------

//----------------------****Get Report Types****----------------------
export const getReportTypes = () => async (dispatch) => {
  dispatch({
    type: "GET_REPORT_TYPES_INIT",
  });
  const url = "/DPFAPI/ReportRequest?actionType=GetReportTypes";

  try {
    let data = await axiosDPFAPIClient.get(url);
    let resp =
      (data && data.data && data.data.Report_response) || "";
    if (resp && resp.result.length > 0) {
      if (
        resp.result[0] &&
        resp.result[0].status === "Success"
      ) {
        dispatch({
          type: "GET_REPORT_TYPES_SUCCESS",
          payload: resp,
        });
      } else {
        dispatch({
          type: "GET_REPORT_TYPES_FAIL",
          payload:
            resp.result[0].description ||
            "Error While Getting Report Types.",
        });
      }
    } else {
      dispatch({
        type: "GET_REPORT_TYPES_FAIL",
        payload: "Error While Getting Report Types.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Getting Report Types.";
    dispatch({
      type: "GET_REPORT_TYPES_FAIL",
      payload: error,
    });
  }
};

//----------------------****Get Report Options****--------------------
export const getReportOptions = (type) => async (dispatch) => {
  dispatch({
    type: "GET_REPORT_OPTIONS_INIT",
  });
  const url = `/DPFAPI/ReportRequest?actionType=GetReportOptions&reportType=${type}`;

  try {
    let data = await axiosDPFAPIClient.get(url);
    let resp =
      (data && data.data && data.data.Report_response) || "";
    if (resp && resp.result.length > 0) {
      if (
        resp.result[0] &&
        resp.result[0].status === "Success"
      ) {
        dispatch({
          type: "GET_REPORT_OPTIONS_SUCCESS",
          payload: resp,
        });
      } else {
        dispatch({
          type: "GET_REPORT_OPTIONS_FAIL",
          payload:
            resp.result[0].description ||
            "Error While Getting Report Options.",
        });
      }
    } else {
      dispatch({
        type: "GET_REPORT_OPTIONS_FAIL",
        payload: "Error While Getting Report Options.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Getting Report Options.";
    dispatch({
      type: "GET_REPORT_OPTIONS_FAIL",
      payload: error,
    });
  }
};
//----------------------****Get Report settings****--------------------
export const getReportSettings = (type) => async (dispatch) => {
  dispatch({
    type: "GET_REPORT_SETTINGS_INIT",
  });
  const url = `/DPFAPI/ReportRequest?actionType=GetReportSettings&reportType=${type}`;

  try {
    let data = await axiosDPFAPIClient.get(url);
    let resp =
      (data && data.data && data.data.Report_response) || "";
    if (resp && resp.result.length > 0) {
      if (
        resp.result[0] &&
        resp.result[0].status === "Success"
      ) {
        dispatch({
          type: "GET_REPORT_SETTINGS_SUCCESS",
          payload: resp,
        });
      } else {
        dispatch({
          type: "GET_REPORT_SETTINGS_FAIL",
          payload:
            resp.result[0].description ||
            "Error While Getting Report Settings.",
        });
      }
    } else {
      dispatch({
        type: "GET_REPORT_SETTINGS_FAIL",
        payload: "Error While Getting Report Settings.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Getting Report Settings.";
    dispatch({
      type: "GET_REPORT_SETTINGS_FAIL",
      payload: error,
    });
  }
};

//----------------------****Get Report Data****-----------------------
export const getReportData = (data) => async (dispatch) => {
  dispatch({
    type: "GET_REPORT_DATA_INIT",
  });
  const url = "/DPFAPI/ReportRequest";
  let obj = {
    actionType: "GetReportData",
    ...data,
  };
  try {
    let data = await axiosDPFAPIClient.post(url, obj);
    let resp =
      (data && data.data && data.data.Report_response) || "";
    if (resp && resp.result.length > 0) {
      if (
        resp.result[0] &&
        resp.result[0].status === "Success"
      ) {
        dispatch({
          type: "GET_REPORT_DATA_SUCCESS",
          payload: resp,
        });
      } else {
        dispatch({
          type: "GET_REPORT_DATA_FAIL",
          payload:
            resp.result[0].description ||
            "Error While Getting Report Data.",
        });
      }
    } else {
      dispatch({
        type: "GET_REPORT_DATA_FAIL",
        payload: "Error While Getting Report Data.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Getting Report Data.";
    dispatch({
      type: "GET_REPORT_DATA_FAIL",
      payload: error,
    });
  }
};


//----------------------****Upload Custom Report Data****-----------------------
export const uploadCustomReportTemplate = (data) => async (dispatch) => {
  dispatch({
    type: "UPLOAD_CUSTOM_REPORT_TEMPLATE_INIT",
  });
  const url = "/DPFAPI/ReportRequest";
  let obj = {
    actionType: "UploadCustomReportTemplate",
    ...data,
  };
  try {
    let data = await axiosDPFAPIClient.post(url, obj);
    let resp =
      (data && data.data && data.data.Report_response) || "";
    if (resp && resp.result.length > 0) {
      if (
        resp.result[0] &&
        resp.result[0].status === "Success"
      ) {
        dispatch({
          type: "UPLOAD_CUSTOM_REPORT_TEMPLATE_SUCCESS",
          payload: resp,
        });
      } else {
        dispatch({
          type: "UPLOAD_CUSTOM_REPORT_TEMPLATE_FAIL",
          payload:
            resp.result[0].description ||
            "Error While Uploading Report Template.",
        });
      }
    } else {
      dispatch({
        type: "UPLOAD_CUSTOM_REPORT_TEMPLATE_FAIL",
        payload: "Error While Uploading Report Template.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Uploading Report Template.";
    dispatch({
      type: "UPLOAD_CUSTOM_REPORT_TEMPLATE_FAIL",
      payload: error,
    });
  }
};

//----------------------****Delete Report****--------------------------
export const deleteReport = ( reportID) => async (dispatch) => {
  dispatch({
    type: "DELETE_REPORT_INIT",
  });
  const url = "/DPFAPI/ReportRequest";
  let obj = {
    actionType: "DeleteReport",
    reportID:reportID,
  };
  try {
    let data = await axiosDPFAPIClient.post(url, obj);
    let resp =
      (data && data.data && data.data.Report_response) || "";
    if (resp && resp.result.length > 0) {
      if (
        resp.result[0] &&
        resp.result[0].status === "Success"
      ) {
        dispatch({
          type: "DELETE_REPORT_SUCCESS",
          payload: resp,
        });
      } else {
        dispatch({
          type: "DELETE_REPORT_FAIL",
          payload:
            resp.result[0].description ||
            "Error While Deleting Report.",
        });
      }
    } else {
      dispatch({
        type: "DELETE_REPORT_FAIL",
        payload: "Error While Deleting Report.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Deleting Report.";
    dispatch({
      type: "DELETE_REPORT_FAIL",
      payload: error,
    });
  }
};
//----------------------****Prime Post****-----------------------------
export const primePost = (reportType) => async (dispatch) => {
  dispatch({
    type: "PRIME_POST_INIT",
  });
  const url = "/DPFAPI/ReportRequest";
  let obj = {
    actionType: "PrimePost",
    reportType
  };
  try {
    let result = await axiosDPFAPIClient.post(url, obj);
    let resp =
      (result && result.data && result.data.Report_response) || "";
    if (resp && resp.result.length > 0) {
      if (
        resp.result[0] &&
        resp.result[0].status === "Success"
      ) {
        dispatch({
          type: "PRIME_POST_SUCCESS",
          payload: resp,
        });
      } else {
        dispatch({
          type: "PRIME_POST_FAIL",
          payload:
            resp.result[0].description ||
            "Error While Priming The Post.",
        });
      }
    } else {
      dispatch({
        type: "PRIME_POST_FAIL",
        payload: "Error While Priming The Post.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Priming The Post.";
    dispatch({
      type: "PRIME_POST_FAIL",
      payload: error,
    });
  }
};

export const setPrivateLock = (data) => async (dispatch) => {
  dispatch({
    type: "SET_PRIVATE_LOCK_INIT",
  });
  const url = "/DPFAPI/ReportRequest";
  let obj = {
    actionType: "SetReportPrivateLock",
    reportID: data.id,
    isPrivate: data.isPrivate
  };

  try {
    let result = await axiosDPFAPIClient.post(url, obj);
    let resp = (result && result.data && result.data.Report_response) || "";
    if (resp && resp.result.length > 0) {
      if (resp.result[0] && resp.result[0].status === "Success") {
        dispatch({
          type: "SET_PRIVATE_LOCK_SUCCESS",
          payload: resp,
        });
      }else {
        dispatch({
          type: "SET_PRIVATE_LOCK_FAIL",
          payload:
            resp.result[0].description ||
            "Error While Update Po Approval Group.",
        });
      }
    } else {
      dispatch({
        type: "SET_PRIVATE_LOCK_FAIL",
        payload: "Error While Update Po Approval Group.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Update Po Approval Group.";
    dispatch({
      type: "SET_PRIVATE_LOCK_FAIL",
      payload: error,
    });
  }
};


//----------------------****Clear Reports States In Store****-----------
export function clearReportsStates() {
  return async (dispatch) => {
    dispatch({
      type: "CLEAR_REPORTS_STATES",
    });
  };
}
