import { Link } from "react-router-dom";

export default function AdminHeader({ title }) {
  function openModal(modalName) {
    console.log("This is not yet implemented");
  }

  return (
    <div className="user_setup_heading">
      <div className="header_menu">
        <Link to="/dashboard">
          <img src="images/dash-logo.png" className="img-fluid" alt="logo" />
        </Link>
        <Link className="setup_menu" to="#" data-target="#top_nav_toggle1">
          <img src="images/top-menu.png" className="" alt="top-menu" />
        </Link>
      </div>
      <h2>{title}</h2>
      <span>
        <img
          onClick={() => openModal()}
          src="./images/user-setup/lock.png"
          alt="lock"
          className="img-fluid"
        />
      </span>
    </div>
  );
}
