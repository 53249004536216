import React, { Component } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import Dropdown from "react-bootstrap/Dropdown";
import $ from "jquery";
import { _customStyles } from "../../../Constants/Constants";
import DatePicker from "react-datepicker";

import TopNav from "../../Common/TopNav/TopNav";
// import { clearStatesAfterLogout } from "../../Actions/UserActions/UserActions";
import * as Validation from "../../../Utils/Validation";
import {
  toBase64,
  addDragAndDropFileListners,
  removeDragAndDropFileListners,
  handleAPIErr,
  handleHideUnhideRows,
  handleValueOptions,
  downloadAttachments,
} from "../../../Utils/Helpers";
import "../PeriodClose/PeriodClose.css";
import * as AccountActions from "../../../Actions/AccountActions/AccountActions";
import Import from "../../Modals/Accounts/Import/Import";
const uuidv1 = require("uuid/v1");
class CashflowForecasting extends Component {
  constructor() {
    super();
    this.state = {
      isLoading: false,
      addNewReportToggle: false,
      reportTypesVal: { label: "", value: "" },
      reportTypes: [{ label: "", value: "" }],

      reportOptionVal: { label: "", value: "" },
      reportOptions: [],
      menuIsOpen: false,

      advancedOptions: [],
      clonedAdvanceOptions: [],
      showHiddenRows: false,
      reDrawDT: false, //re draw datatable

      optionsImport: [],
      formErrors: {
        reportTypesVal: "",
        reportOptionVal: "",
        reportFile: "",
        reportName: "",
      },
      excelData: "",
      openImportModal: false,

      reportFile: "",
      reportName: "",
      private: false,
    };
  }

  async componentDidMount() {
    $(document).ready(function () {
      $(".icon_dots").click(function () {
        $(".documents_attatchments2").toggleClass("doc_sidebar2");
      });
    });
    await this.getPrimeCashFlow();
    if (this.state.reDrawDT) {
      this.initializeTable();
      this.setState({ ...this.state, reDrawDT: false });
    }
  }
  componentWillUnmount() {
    //removing drag and drop attachments listeners
    removeDragAndDropFileListners("drop-area-report", this.uploadReportFile);
  }

  initializeTable = () => {
    window.$("#usersteup-modal").DataTable({
      dom: "Rlfrtip",
      searching: false,
      colResize: true,
      paging: false,
      info: false,
      order: [[1, "asc"]],
      colReorder: {
        fixedColumnsRight: 5,
        fixedColumnsLeft: 5,
      },
    });
  };
  getPrimeCashFlow = async () => {
    this.setState({ isLoading: true });
    let { advancedOptions, clonedAdvanceOptions, options } = this.state;
    await this.props.getPrimeCashFlow();
    if (this.props.account.getPrimeCashFlowSuccess) {
      toast.success(this.props.account.getPrimeCashFlowSuccess);

      advancedOptions = this.props.account.getPrimeCashFlow;
      advancedOptions.map((opt, i) => {
        if ((opt.type && opt.type.toLowerCase() === "list") || "dropdown") {
          if (opt.options && opt.options.length > 0) {
            options = opt.options;
            let optionsVal = [];
            opt.options.map((o, i) => {
              optionsVal.push({ label: o.value, value: o.value });
            });
            opt.options = optionsVal;
          }
        }
        opt.id = uuidv1();
        opt.hide = false;
        return opt;
      });
    }
    if (this.props.account.getPrimeCashFlowError) {
      handleAPIErr(this.props.account.getPrimeCashFlowError, this.props);
    }
    this.setState({
      isLoading: false,
      advancedOptions,
      clonedAdvanceOptions: advancedOptions,
      options,
    });
  };
  getExportCashFlow = async () => {
    this.setState({ isLoading: true });
    let { advancedOptions } = this.state;

    let data = {
      options: advancedOptions,
    };
    await this.props.getExportCashFlow(data);

    if (this.props.account.getExportCashFlowSuccess) {
      toast.success(this.props.account.getExportCashFlowSuccess);
      let excelData = this.props.account.getExportCashFlow;
      let obj = {
        contentType: "application/vnd.ms-excel",
        attachment: excelData || "",
      };
      downloadAttachments(obj, "ExportCashFlow");
    }
    if (this.props.account.getExportCashFlowError) {
      handleAPIErr(this.props.account.getExportCashFlowError, this.props);
    }

    this.setState({ isLoading: false });
  };
  onImport = async (type, importDataForReq) => {
    this.setState({ isLoading: true });
    let { advancedOptions } = this.state;

    let data = {
      options: advancedOptions,
      importData: importDataForReq,
    };
    await this.props.getImportCashFlow(data);

    if (this.props.account.getImportCashFlowSuccess) {
      toast.success(this.props.account.getImportCashFlowSuccess);
    }
    if (this.props.account.getImportCashFlowError) {
      handleAPIErr(this.props.account.getImportCashFlowError, this.props);
    }
    // this.openModal("openImportModal");

    this.setState({ isLoading: false });
  };
  menuOpened = async () => {
    this.setState({ menuIsOpen: true });
    let _this = this;
    $(document).ready(async function () {
      $('<i class="fa fa-trash report--del"></i>').appendTo(
        ".report_menu.custon_select-selector-inner__option"
      );
      $(".report_menu.custon_select-selector-inner__option i").on(
        "click",
        async function (e) {
          e.preventDefault();
          var p_id = $(this).parent().attr("id");
          let id_split = p_id && p_id.split("-"); //["react", "select", "3", "option", "1"]
          let id = id_split[id_split.length - 1];

          let { reportOptions } = _this.state;
          let rep_opt = reportOptions.length > 0 && reportOptions[id];
          //call delete report options API
          await _this.deleteReport(rep_opt.value, rep_opt.locked);
        }
      );
    });
  };

  menuClosed = () => {
    this.setState({ menuIsOpen: false });
  };

  //   //a function that checks  api error
  //   handleApiRespErr = async (error) => {
  //     if (
  //       error === "Session has expired. Please login again." ||
  //       error === "User has not logged in."
  //     ) {
  //       this.props.clearStatesAfterLogout();
  //       this.props.history.push("/login");
  //       toast.error(error);
  //     } else if (error === "User has not logged into a production.") {
  //       toast.error(error);
  //       this.props.history.push("/login-table");
  //     } else {
  //       //Netork Error || api error
  //       toast.error(error);
  //     }
  //   };

  //   handleReportTypes = async (type) => {
  //     let { formErrors } = this.state;

  //     formErrors = Validation.handleValidation(
  //       "reportTypesVal",
  //       type.value,
  //       formErrors
  //     );

  //     this.setState({
  //       reportTypesVal: type,
  //       reportOptionVal: { label: "Select Report Options", value: "" },
  //       reportOptions: [],
  //       formErrors,
  //     });
  //     if (type.value) {
  //       //call API to get report options according to selected type
  //       this.setState({ isLoading: true });
  //       await this.props.getReportOptions(type.value);
  //       //success case of get Report Options
  //       if (this.props.report.getReportOptionsSuccess) {
  //         // toast.success(this.props.report.getReportOptionsSuccess);
  //         //report options List
  //         if (
  //           this.props.report.getReportOptions &&
  //           this.props.report.getReportOptions.length > 0
  //         ) {
  //           let getReportOptions = this.props.report.getReportOptions;
  //           let getReportOptionsArr = [];
  //           getReportOptions.map((t, i) => {
  //             getReportOptionsArr.push({
  //               label: t.layout,
  //               value: t.id,
  //               locked: t.locked,
  //             });
  //           });
  //           this.setState({
  //             reportOptions: getReportOptionsArr,
  //           });
  //         }
  //       }
  //       //error case of get Report Options
  //       if (this.props.report.getReportOptionsError) {
  //         this.handleApiRespErr(this.props.report.getReportOptionsError);
  //       }
  //       this.props.clearReportsStates();
  //       this.setState({ isLoading: false });
  //     }
  //   };

  //   handleReportOptions = (layout) => {
  //     let { formErrors } = this.state;
  //     formErrors = Validation.handleValidation(
  //       "reportOptionVal",
  //       layout.value,
  //       formErrors
  //     );

  //     this.setState({ reportOptionVal: layout, formErrors });

  //     if (layout.value) {
  //       //clear states on file and report name
  //       this.setState({
  //         addNewReportToggle: false,
  //         reportFile: "",
  //         reportName: "",
  //         private: false,

  //         formErrors: {
  //           reportFile: "",
  //           reportName: "",
  //         },
  //       });
  //     }
  //   };

  addNewReport = async () => {
    this.setState((prevState) => ({
      addNewReportToggle: !prevState.addNewReportToggle,
    }));

    if (this.state.addNewReportToggle) {
      //add new report case then report options set to default

      this.setState((prevState) => ({
        reportOptionVal: { label: "Select Report Options", value: "" },
        formErrors: {
          ...prevState.formErrors,
          reportOptionVal: "",
        },
      }));

      //adding drag and drop attachments listeners
      addDragAndDropFileListners("drop-area-report", this.uploadReportFile);
      //end
    } else {
      //clear states on file and report name
      this.setState({
        reportFile: "",
        reportName: "",
        private: false,

        formErrors: {
          reportFile: "",
          reportName: "",
        },
      });
    }
  };

  handleCheckbox = async (e) => {
    this.setState({ private: e.target.checked });
  };

  handleFieldChange = (e) => {
    let { formErrors } = this.state;
    let { name, value } = e.target;
    formErrors = Validation.handleValidation(name, value, formErrors);
    this.setState({ [name]: value, formErrors });
  };

  uploadReportFile = async (f) => {
    let { formErrors } = this.state;
    let type = f[0].type;
    let file = f[0];
    let size = f[0].size;

    if (type == "mrt") {
      if (size <= 2000000) {
        const result = await toBase64(file).catch((e) => e);
        if (result instanceof Error) {
          toast.error(result.message);
          return;
        } else {
          formErrors = Validation.handleValidation(
            "reportFile",
            result,
            formErrors
          );
          this.setState({
            reportFile: result,
            formErrors,
          });
        }
      } else {
        toast.error("Maximum Image Size 2MB");
      }
    } else {
      toast.error("Please Select only Images of type: '.mrt'");
    }
  };

  deleteReport = async (reportID, locked) => {
    let reportType = this.state.reportTypesVal.value || "";
    if (locked != "Y") {
      if (reportType && reportID) {
        this.setState({ isLoading: true });
        await this.props.deleteReport(reportType, reportID);

        //success case of delete Report success
        if (this.props.report.deleteReportSuccess) {
          toast.success(this.props.report.deleteReportSuccess);
          //report options List
          if (
            this.props.report.deleteReport &&
            this.props.report.deleteReport.length > 0
          ) {
            let reportOptions = this.props.report.deleteReport;
            let reportOptionsArr = [];
            reportOptions.map((t, i) => {
              reportOptionsArr.push({
                label: t.layout,
                value: t.id,
                locked: t.locked,
              });
            });
            this.setState({
              reportOptions: reportOptionsArr,
            });
          }

          this.setState({
            addNewReportToggle: false,
            reportFile: "",
            reportName: "",
            private: false,
            reportOptionVal: { label: "Select Report Options", value: "" },
            formErrors: {
              reportFile: "",
              reportName: "",
            },
          });
        }
        //error case of Delete Report
        if (this.props.report.deleteReportError) {
          this.handleApiRespErr(this.props.report.deleteReportError);
        }

        this.setState({ isLoading: false });
      } else {
        toast.error("Report Type OR Report ID is Missing!");
      }
    } else {
      toast.error("You can't delete this Report!");
    }
  };

  onSave = async () => {
    let formErrors = this.state.formErrors;
    if (!this.state.reportTypesVal.value) {
      formErrors.reportTypesVal = "This Field is Required.";
    }
    if (this.state.addNewReportToggle) {
      //add new report case
      if (!this.state.reportName) {
        formErrors.reportName = "This Field is Required.";
      }
      if (!this.state.reportFile) {
        formErrors.reportFile = "MRT File is Required.";
      }
    } else {
      //use reports from the drop down list
      if (!this.state.reportOptionVal.value) {
        formErrors.reportOptionVal = "This Field is Required.";
      }
    }
    this.setState({
      formErrors: formErrors,
    });

    let check = false;

    if (this.state.addNewReportToggle) {
      if (!formErrors.reportFile && !formErrors.reportName) {
        check = true;
      }
    } else {
      if (!formErrors.reportOptionVal) {
        check = true;
      }
    }

    if (!formErrors.reportTypesVal && check) {
      let {
        reportTypesVal,
        reportOptionVal,
        reportFile,
        reportName,
        private: privateCheck,
      } = this.state;
      let data = "";
      if (reportOptionVal.value) {
        //get report data according to available report options
        data = {
          reportType: reportTypesVal.value,
          reportID: reportOptionVal.value,
        };
      } else {
        //create new reports and get data according to that report
        data = {
          reportType: reportTypesVal.value,
          reportFile,
          reportName,
          private: privateCheck,
        };
      }
      if (data) {
        this.setState({ isLoading: true });
        await this.props.getReportData(data);
        //success case of get Report Data
        if (this.props.report.getReportDataSuccess) {
          toast.success(this.props.report.getReportDataSuccess);
          let jsonData = this.props.report.getReportData.jsonData || "";
          let reportFile = this.props.report.getReportData.reportFile || "";
          let key = this.props.report.getReportData.key || "";

          if (jsonData && reportFile && key) {
            localStorage.setItem("reportFile", reportFile);
            localStorage.setItem("jsonData", jsonData);
            localStorage.setItem("key", key);
            var path =
              window.location.protocol +
              "//" +
              window.location.host +
              "/report-view";

            window.open(path, "_blank");
          }
        }
        //error case of get Report Data
        if (this.props.report.getReportDataError) {
          this.handleApiRespErr(this.props.report.getReportDataError);
        }

        this.setState({ isLoading: false });
      }
    }
  };

  _handleValueOptions = async (type, val, item, index) => {
    let { advancedOptions, clonedAdvanceOptions } = this.state;
    let result = handleValueOptions(
      type,
      val,
      item,
      index,
      advancedOptions,
      clonedAdvanceOptions
    );

    this.setState({
      ...this.state,
      advancedOptions: result.advancedList,
      clonedAdvanceOptions: result.clonedAdvancedList,
    });
  };

  _handleHideUnhideRows = (item) => {
    let { advancedOptions, clonedAdvanceOptions, showHiddenRows } = this.state;

    let result = handleHideUnhideRows(
      item,
      "#usersteup-modal",
      "reports__settings",
      advancedOptions,
      clonedAdvanceOptions,
      showHiddenRows
    );

    this.setState({
      advancedOptions: result.advancedList,
      clonedAdvanceOptions: result.clonedAdvancedList,
      showHiddenRows: result.showHiddenRows,
      reDrawDT: true,
    });
  };

  handleShowHiddenRows = async () => {
    let table = window.$("#usersteup-modal").DataTable();
    table.destroy();

    let { advancedOptions, clonedAdvanceOptions, showHiddenRows } = this.state;

    showHiddenRows = !showHiddenRows;

    if (showHiddenRows) {
      //show hidden rows
      advancedOptions = clonedAdvanceOptions;
    } else {
      //hide again hidden rows
      let list = advancedOptions.filter((l) => !l.hide);
      advancedOptions = list;
    }

    this.setState({
      advancedOptions,
      showHiddenRows,
      reDrawDT: true, //to re daraw the datatable
    });
  };
  openModal = async (name) => {
    this.setState({ [name]: true });
  };

  closeModal = (name) => {
    this.setState({
      [name]: false,
    });
  };
  render() {
    let { advancedOptions, showHiddenRows } = this.state;
    return (
      <>
        {this.state.isLoading ? <div className="se-pre-con"></div> : ""}

        <div className="user_setup_main">
          <header>
            <TopNav />

            <div className="user_setup_heading">
              <div className="header_menu">
                <Link to="/dashboard">
                  <img
                    src="images/dash-logo.png"
                    className="img-fluid"
                    alt="logo"
                  />
                </Link>
                <Link
                  className="setup_menu"
                  to="#"
                  data-target="#top_nav_toggle1"
                >
                  <img src="images/top-menu.png" className="" alt="top-menu" />
                </Link>
              </div>
              <h2>Cashflow/Forecasting</h2>
              <span>
                <img
                  src="./images/user-setup/lock.png"
                  alt="lock"
                  className="img-fluid"
                />
              </span>
            </div>
            <div className="user_setup_headerbox">
              <div className="user_setup_play_div">
                <ul>
                  <li>
                    <p className="user_setup_play_video">Video</p>
                  </li>
                  <li>
                    <p className="user_setup_play_tuturial">Tutorials</p>
                  </li>
                </ul>
                <span className="user_setup_play_icon">
                  <img
                    src="./images/user-setup/play.png"
                    alt="play"
                    className="img-fluid"
                  />
                </span>
              </div>
              <div className="user_setup_header_rightbox">
                <p>
                  In our{" "}
                  <span>
                    <a href="#">Video</a>
                  </span>{" "}
                  learn how to use cashflow/forecasting Read our{" "}
                  <span>
                    <a href="#">help article</a>
                  </span>{" "}
                  to learn More
                </p>
              </div>
              <span>
                <img
                  className="close_top_sec"
                  src="images/user-setup/cross.png"
                  alt="cross"
                ></img>
              </span>
            </div>
          </header>
          <div className="col-sm-12 table_white_box">
            <section id="">
              <div className="container-fluid ">
                <div className="main_wrapper p-10">
                  <div className="forgot_body px-3">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="user_setup_plus_Icons">
                          <div className="col-12 col-md-auto d-flex justify-content-end s-c-main">
                            <Link
                              to="#"
                              className="zom-img float-right ml-md-5 pl-2 pr-2 mr-0 more-d mt-0 icon_dots"
                            >
                              <img
                                src="images/more.png"
                                className=" img-fluid"
                                alt="user"
                              />{" "}
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <div className="documents_attatchments2 supplier2_sidetoggle">
              <div
                onClick={() => this.openModal("openImportModal")}
                className="main-sec-attach main-bg"
              >
                Import
              </div>
              <div
                onClick={this.getExportCashFlow}
                className="main-sec-attach main-bg"
              >
                Export
              </div>
            </div>

            <div className="col-xl-8 col-md-12 user_setup_popup__table new_advance_table  m-auto">
              <h2>Advanced</h2>
              <table className="table" width="100%" id="usersteup-modal">
                <thead className="thead_bg thead_bg_sass hover-border">
                  <tr>
                    <th scope="col">
                      <span className="user_setup_hed">Category</span>
                    </th>
                    <th scope="col">
                      <span className="user_setup_hed">Description</span>
                    </th>
                    <th scope="col" className="value__field--wrapperuser">
                      <span className="user_setup_hed">Value</span>
                    </th>
                    <th scope="col" className="text__right__contentuser">
                      <span className="user_setup_hed">Hide</span>
                    </th>
                    <th className="table__inner--th">
                      <div className="dropdown">
                        <button
                          aria-haspopup="true"
                          aria-expanded="true"
                          id=""
                          type="button"
                          className="dropdown-toggle btn dept-tbl-menu "
                          data-toggle="dropdown"
                        >
                          <span className="fa fa-bars "></span>
                        </button>
                        <div className="dropdown-menu dept-menu-list dropdown-menu-right">
                          <div className="pr-0 dropdown-item">
                            <div className="form-group remember_check mm_check4">
                              <input
                                type="checkbox"
                                id="showHiddenRows"
                                name="showHiddenRows"
                                checked={showHiddenRows}
                                onClick={this.handleShowHiddenRows}
                                onChange={() => {}}
                              />
                              <label htmlFor="showHiddenRows" className="mr-0">
                                Show Hidden Rows
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {advancedOptions.map((list, i) => {
                    return (
                      <tr key={list.id}>
                        <td className=" ">{list.category}</td>
                        <td>{list.description}</td>
                        {list.valueType === "List" ? (
                          <td className="pt-0 pb-0 text-left">
                            <Select
                              classNamePrefix="custon_select-selector-inner main__dropdown--wrapper1"
                              // className={
                              //   i == 0
                              //     ? "width-selector only--one input_width2"
                              //     : i == 1
                              //     ? "width-selector only--one input_width2"
                              //     : "width-selector input_width"
                              // }
                              styles={_customStyles}
                              value={{
                                label: list.value,
                                value: list.value,
                              }}
                              options={list.options}
                              onChange={(obj) =>
                                this._handleValueOptions("list", obj, list, i)
                              }
                              theme={(theme) => ({
                                ...theme,
                                border: 0,
                                borderRadius: 0,
                                colors: {
                                  ...theme.colors,
                                  primary25: "#f2f2f2",
                                  primary: "#f2f2f2",
                                },
                              })}
                            />
                          </td>
                        ) : list.valueType === "MultiList" ? (
                          <td className="pt-0 pb-0 text-left">
                            <Select
                              className="basic-multi-select main__dropdown--wrapper1"
                              name="multiple-op"
                              // classNamePrefix="custon_select-selector-inner"
                              defaultValue={{
                                label: "Select Approval Group",
                                value: "",
                              }}
                              value={list.multiValue || []}
                              options={list.valueOptions}
                              isClearable={false}
                              onChange={(obj) =>
                                this._handleValueOptions(
                                  "multiList",
                                  obj,
                                  list,
                                  i
                                )
                              }
                              isMulti
                              theme={(theme) => ({
                                ...theme,
                                border: 0,
                                borderRadius: 0,
                                colors: {
                                  ...theme.colors,
                                  primary25: "#f2f2f2",
                                  primary: "#f2f2f2",
                                },
                              })}
                            />
                          </td>
                        ) : list.valueType === "Date" ? (
                          <td>
                            <div className="table_input_field">
                              <DatePicker
                                selected={Number(list.value)}
                                dateFormat="d MMM yyyy"
                                autoComplete="off"
                                onChange={(date) =>
                                  this._handleValueOptions(
                                    "date",
                                    date,
                                    list,
                                    i
                                  )
                                }
                              />
                            </div>
                          </td>
                        ) : list.valueType === "Check" ? (
                          <td>
                            <div className="col-auto p-0">
                              <div className="form-group remember_check  text-center pt-0 float-left">
                                <input
                                  type="checkbox"
                                  id={`chk${i}`}
                                  checked={
                                    list.value === "Y" || list.value === "1"
                                      ? true
                                      : false
                                  }
                                  onChange={(e) =>
                                    this._handleValueOptions(
                                      "checkbox",
                                      e,
                                      list,
                                      i
                                    )
                                  }
                                />
                                <label htmlFor={`chk${i}`}></label>
                              </div>
                            </div>
                          </td>
                        ) : list.valueType === "Number" ? (
                          <td>
                            <div className="table_input_field">
                              <input
                                type="number"
                                value={list.value}
                                onChange={(e) =>
                                  this._handleValueOptions("number", e, list, i)
                                }
                              />
                            </div>
                          </td>
                        ) : list.valueType === "Text" ? (
                          <td>
                            <div className="table_input_field">
                              <input
                                type="text"
                                value={list.value}
                                onChange={(e) =>
                                  this._handleValueOptions("text", e, list, i)
                                }
                              />
                            </div>
                          </td>
                        ) : (
                          <td>{list.value}</td>
                        )}
                        <td className="text__right--user">
                          <div className="custom-radio">
                            <label
                              className="check_main  remember_check"
                              htmlFor={`hideUnhideRows${i}`}
                            >
                              <input
                                type="checkbox"
                                className="custom-control-input"
                                name={"hideUnhideRows"}
                                id={`hideUnhideRows${i}`}
                                checked={false}
                                onChange={(e) =>
                                  this._handleHideUnhideRows(list)
                                }
                              />

                              {/* <span className='click_checkmark'></span> */}
                              <span
                                // className={
                                //   list.hide
                                //     ? "dash_checkmark bg_clr"
                                //     : "dash_checkmark"
                                // }

                                className={
                                  list.hide
                                    ? "dash_checkmark bg_clr "
                                    : "dash_checkmark"
                                }
                              ></span>
                            </label>
                          </div>
                        </td>
                        <td></td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <Import
          openImportModal={this.state.openImportModal}
          state={this.state}
          onImport={this.onImport}
          closeModal={this.closeModal}
          importOptions={this.state.optionsImport}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({ account: state.account });

export default connect(mapStateToProps, {
  getPrimeCashFlow: AccountActions.getPrimeCashFlow,
  getExportCashFlow: AccountActions.getExportCashFlow,
  getImportCashFlow: AccountActions.getImportCashFlow,
})(CashflowForecasting);
