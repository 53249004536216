import { useEffect, useState } from "react";
import { useHistory, redirect } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { handleAPIErr } from "../../Utils/Helpers";
import {
  logInProduction,
  clearUserStates,
  loginAPIGatewayProduction,
  getStorageType,
  getDefaultValues,
  loginInToProduction,
} from "../../Actions/UserActions/UserActions";

export function useProductionLogin() {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const [productionName, setProductionName] = useState("");
  const [companyId, setCompanyId] = useState("");
  const storageType = useSelector((state) => state.user.getStorageType);
  const loginProductionSuccess = useSelector(
    (state) => state.user.loginProductionSuccess
  );
  const loginProductionError = useSelector(
    (state) => state.user.loginProductionError
  );
  const loginProductionData = useSelector(
    (state) => state.user.loginProductionData
  );
  const storageTypeSuccess = useSelector(
    (state) => state.user.getStorageTypeSuccess
  );
  const storageTypeError = useSelector(
    (state) => state.user.getStorageTypeError
  );
  const loginAPIGatewayProductionSuccess = useSelector(
    (state) => state.user.loginAPIGatewayProductionSuccess
  );
  const loginAPIGatewayProductionError = useSelector(
    (state) => state.user.loginAPIGatewayProductionError
  );
  const defaultValuesError = useSelector(
    (state) => state.user.getDefaultValuesError
  );

  const history = useHistory();

  const navigateToNextPage = () => {
    if (history.location.pathname === "/dashboard") {
      //Reload current dashboard 
      history.go(0);
      return;
    }

    const lastPageLogin = localStorage.getItem("lastPageLogin");
    if (lastPageLogin) {
      history.push(lastPageLogin);
    } else {
      history.push("/dashboard");
    }
  };

  const login = async (production, companyIdArg) => {
    if (production) {
      //Remove product tokens from the history
      localStorage.removeItem("PRODUCT_ACCESS_TOKEN");
      localStorage.removeItem("PRODUCT_REFRESH_TOKEN");

      setLoading(true);
      setProductionName(production);
      setCompanyId(companyIdArg);
      dispatch(logInProduction(production, companyIdArg)); //call api to login production
    } else {
      toast.error("Please Select A Production For Login!");
    }
  };

  const handleOnLoginSuccess = async () => {
    if (loginProductionSuccess) {
      let {
        userType,
        blockChart,
        blockPO,
        lockPONumber,
        blockInvoice,
        blockDocuments,
        blockExpense,
        blockPayments,
        blockTimecards,
        blockJournals,
        blockDistChange,
        supplierApproval,
        blockSupplier,
        usePageLoading,
        useTCPageLoading,
        forceValidChartCodePO,
      } = loginProductionData || "";

      //set default setting
      let displayAddInvoiceSettings = {
        receivedDateCheck: false,
        descriptionCheck: true,
        paymentReferenceCheck: false,
        paymentDateCheck: false,
      };

      localStorage.setItem(
        "displayAddInvoiceSettings",
        JSON.stringify(displayAddInvoiceSettings)
      );

      //Default values are saved in LocalStorage in reducer
      dispatch(getDefaultValues());

      if (productionName) {
        // these all are removed in user reducer when "CLEAR_STATES_AFTER_LOGOUT" is dispatched
        localStorage.setItem("loginProduction", productionName); //to show on dashboard
      }

      userType = userType || "N";
      blockChart = blockChart || "N";
      blockPO = blockPO || "N";
      lockPONumber = lockPONumber || "N";
      blockInvoice = blockInvoice || "N";
      blockDocuments = blockDocuments || "N";
      blockExpense = blockExpense || "N";
      blockPayments = blockPayments || "N";
      blockTimecards = blockTimecards || "N";
      blockJournals = blockJournals || "N";
      blockDistChange = blockDistChange || "N";
      supplierApproval = supplierApproval || "N";
      blockSupplier = blockSupplier || "N";
      usePageLoading = usePageLoading || "N";
      useTCPageLoading = useTCPageLoading || "N";

      localStorage.setItem("userType", userType); //to show the email or phone of the user after login "code has been send to '...'""
      localStorage.setItem("blockChart", blockChart); //if 'Y' then chart code + button will show otherwise not
      localStorage.setItem("blockPO", blockPO);
      localStorage.setItem("lockPONumber", lockPONumber); //if 'Y' then user not able to edit the PO Number in the PO Page (when PO Reference modal opens)
      localStorage.setItem("blockInvoice", blockInvoice);
      localStorage.setItem("blockDocuments", blockDocuments);
      localStorage.setItem("blockExpense", blockExpense);
      localStorage.setItem("blockPayments", blockPayments);
      localStorage.setItem("blockTimecards", blockTimecards);
      localStorage.setItem("blockJournals", blockJournals);
      localStorage.setItem("blockDistChange", blockDistChange);
      localStorage.setItem("supplierApproval", supplierApproval);
      localStorage.setItem("usePageLoading", usePageLoading);
      localStorage.setItem("useTCPageLoading", useTCPageLoading);
      localStorage.setItem("blockSupplier", blockSupplier); //if 'Y' then user not able to add/edit the supplier
      localStorage.setItem("forceValidChartCodePO", forceValidChartCodePO);

      const isCognito = localStorage.getItem("isCognito");
      if (isCognito === "true") {
        const response = await loginInToProduction(
          productionName, userType,
          companyId, dispatch,
        );
        if (response.status === "Failed") {
          toast.error(response.description);
          setLoading(false);
        }
        sessionStorage.clear();
      } else {
        navigateToNextPage();
      }
    }
  };

  useEffect(() => {
    handleOnLoginSuccess();
  }, [loginProductionSuccess, loginProductionData]);

  useEffect(() => {
    if (productionName && loginAPIGatewayProductionSuccess) {
      dispatch(getStorageType(productionName.replace(/"/g, "")));
      setLoading(false);
    }
  }, [loginAPIGatewayProductionSuccess, productionName]);

  useEffect(() => {
    if (loginAPIGatewayProductionError) {
      handleAPIErr(loginAPIGatewayProductionError);
      setLoading(false);
    }
  }, [loginAPIGatewayProductionError]);

  useEffect(() => {
    if (loginProductionError) {
      handleAPIErr(loginProductionError);
      dispatch(clearUserStates());
      setLoading(false);
    }
  }, [loginProductionError]);

  useEffect(() => {
    if (storageTypeSuccess) {
      localStorage.setItem("storageType", storageType);
      navigateToNextPage();
    }
  }, [storageTypeSuccess /*, storageType*/]);

  useEffect(() => {
    if (storageTypeError) {
      toast.error(storageTypeError);
    }
  }, [storageTypeError]);

  useEffect(() => {
    if (defaultValuesError) {
      handleAPIErr(defaultValuesError);
    }
  }, [defaultValuesError]);
  return {
    login: login,
    isLoading,
  };
}
