import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import "./stimulsoft.designer.office2013.whiteblue.css";
import "./stimulsoft.viewer.office2013.whiteblue.css";


const ReportView = () => {
  const history = useHistory();
  const { search } = useLocation();

  useEffect(() => {
    let jsonData = localStorage.getItem("jsonData");
    let key = localStorage.getItem("key");
    let reportFile = localStorage.getItem("reportFile");

    if (jsonData && key && reportFile) {
      var previewData = jsonData;
      window.Stimulsoft.Base.StiLicense.Key = key;

      const report = new window.Stimulsoft.Report.StiReport();

      report.loadFile("data:application/octet-stream;base64," + reportFile);
      report.dictionary.databases.clear();
      report.regData("currency", "currency", previewData);

      const searchParams = new URLSearchParams(search ?? '');

      if (searchParams.has('designer')) {
        // design mode

        const designerOptions = new window.Stimulsoft.Designer.StiDesignerOptions();
        designerOptions.appearance.fullScreenMode = true;

        const designer = new window.Stimulsoft.Designer.StiDesigner(designerOptions, "StiDesigner", false);
        designer.report = report;
        designer.renderHtml('viewer');
      } else {
        // viewer mode
        var viewerOptions = new window.Stimulsoft.Viewer.StiViewerOptions();
        viewerOptions.toolbar.showDesignButton = true;

        const viewer = new window.Stimulsoft.Viewer.StiViewer(
          viewerOptions,
          "StiViewer",
          false
        );

        viewer.onDesignReport = function (args) {
          history.push('/report-view?designer=true');
        }

        viewer.report = report;
        viewer.renderHtml('viewer');
      }



    } else {
      history.push("/report");
    }

  }, [search]);


  return <div id="viewer"></div>;

}

export default ReportView;
