const INITIAL_STATE = {
  fileUploadQueue: [],
};

export const FILE_UPLOAD_STATUS = {
  STARTED: "STARTED",
  PENDING: "PENDING",
  COMPLETED: "COMPLETED",
};

function FileUploadReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "FILE_UPLOAD_STARTED":
      return {
        ...state,
        fileUploadQueue: [
          ...state.fileUploadQueue,
          {
            ...action.payload,
            progress: 0,
            status: FILE_UPLOAD_STATUS.STARTED,
          },
        ],
      };

    case "UPDATE_FILE_UPLOAD_PROGRESS":
      return {
        ...state,
        fileUploadQueue: state.fileUploadQueue.map((upload) =>
          upload.id === action.payload.id
            ? {
                ...upload,
                progress: action.payload.progress,
                status: FILE_UPLOAD_STATUS.PENDING,
              }
            : upload
        ),
      };

    case "FILE_UPLOAD_SUCCESS":
      return {
        ...state,
        fileUploadQueue: state.fileUploadQueue.map((upload) =>
          upload.id === action.payload.id
            ? { ...upload, progress: 100, status: FILE_UPLOAD_STATUS.COMPLETED }
            : upload
        ),
      };

    case "CLEAR_FILE_UPLOAD_STATE":
      return INITIAL_STATE;

    default:
      return state;
  }
}

export default FileUploadReducer;
