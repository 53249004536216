import React, { Component } from "react";
import { connect } from "react-redux";

import Modal from "react-bootstrap/Modal";

import { _customStyles } from "../../../../../Constants/Constants";

import $ from "jquery";

import {
  tableSetting,
  handleSaveSettings,
  handleCloseSettingModal,
  handleAPIErr,
  filterBox,
  downloadAttachments,
} from "../../../../../Utils/Helpers";

import "../../BudgetImport/BudgetImport.css";
import "../../../../../Containers/Accounts/FinancialReportsWorksheet/FinancialReportsWorksheet.css";
import * as AccountActions from "../../../../../Actions/AccountActions/AccountActions";
import { toast } from "react-toastify";
class HyperLinkModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      hyperLinkCols: [],
      hyperLinkRows: [],
      getFromHyperLinkTable: [],
      refId: [],
      newRefId: "",
    };
  }
  async componentDidMount() {
    // this.primePeriodClose();
    filterBox("ledgerhistory");
  }
  componentWillReceiveProps() {
    let { hyperLinkCols, hyperLinkRows, getFromHyperLinkTable } =
      this.props.state;
    this.setState({ hyperLinkCols, hyperLinkRows, getFromHyperLinkTable });
  }
  clearStates = () => {
    this.props.closeModal("openHyperLinkModal");
  };

  updateFromHyperLink = async () => {
    this.setState({ isLoading: true });
    let data = {
      table: this.state.getFromHyperLinkTable,
    };
    await this.props.updateFromHyperLink(data);
    if (this.props.account.updateFromHyperLinkSuccess) {
      toast.success(this.props.account.updateFromHyperLinkSuccess);
    }
    if (this.props.account.updateFromHyperLinkError) {
      handleAPIErr(this.props.account.updateFromHyperLinkError, this.props);
    }
    this.setState({ isLoading: false });
  };
  handleChangeHyperLink = (e, data, i, j) => {
    data[j] = e.target.value;
  };

  handleCheckboxess = (e, data) => {
    let { refId, hyperLinkRows, newRefId } = this.state;

    // let bankChequeRows = JSON.parse(JSON.stringify(this.state.bankChequeRows));
    let { name, checked } = e.target;
    if (data === "allCheck") {
      let multipleTransCopy = [];
      if (checked) {
        hyperLinkRows.map((m) => {
          m.checked = true;
          multipleTransCopy.push(m.refId);
          return m;
        });
      } else {
        hyperLinkRows.map((m) => {
          m.checked = false;
          return m;
        });
      }
      refId = [...multipleTransCopy];
    } else {
      if (checked) {
        hyperLinkRows.map(async (po, i) => {
          if (data.refId === po.refId) {
            po.checked = true;
          }
          return po;
        });
        refId.push(data.refId);
      } else {
        hyperLinkRows.map(async (po, i) => {
          if (data.refId === po.refId) {
            po.checked = false;
          }
          return po;
        });
        let filteredMultiRefId = refId.filter((t) => t != data.refId);
        refId = filteredMultiRefId;
      }
    }
    newRefId = refId + [];

    this.setState({
      hyperLinkRows,
      refId,
      newRefId,
    });
  };
  threeDotMenu = () => {
    $(".mm-blok21").toggleClass("doc_sidebar2");
  };

  render() {
    return (
      <>
        {this.state.isLoading ? <div className="se-pre-con"></div> : ""}

        <Modal
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={this.props.openHyperLinkModal}
          onHide={this.clearStates}
          className="mm_report_new_modal mx-auto reports-v4-modal"
        >
          <Modal.Body>
            <div className="container-fluid p-0">
              <div className="main_wrapper">
                <div className="row d-flex h-100 p-0">
                  <div className="col-12 justify-content-center align-self-center">
                    <div className="setting_form_main p-0 table_white_box">
                      <div className="setting_header thead_bg">
                        <h3 className="Indirecttaxcode-poup_heading">
                          HyperLink
                        </h3>
                      </div>
                      <div className="reportv4-modal-inner">
                        <div className="pb-1 table-genrel login_form table-responsive">
                          <table
                            id="chartofaccounts"
                            className="financial_table  table-bordered"
                            width="100%"
                          >
                            <thead>
                              <tr>
                                {this.state.hyperLinkCols?.map((m, i) => {
                                  return (
                                    <th
                                      scope="col"
                                      key={i}
                                      // className="text-left exp-supplier-th"
                                    >
                                      <span className="user_setup_hed">
                                        {" "}
                                        {m.ColName}
                                      </span>
                                    </th>
                                  );
                                })}
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.hyperLinkRows?.map((item, i) => {
                                return (
                                  <tr>
                                    {this.state.hyperLinkCols?.map((c, j) => {
                                      let ColType = c.ColType;
                                      let isHyperLink = c.IsHyperLink;
                                      if (isHyperLink === 1) {
                                        return (
                                          <td
                                            className="text-left"
                                            data-order={item.data[j]}
                                            onClick={() =>
                                              this.getFromHyperLink(
                                                1928,
                                                "Period"
                                              )
                                            }
                                            style={{ cursor: "pointer" }}
                                          >
                                            <a href="javascript:void(0)">
                                              {item.data[j]}
                                            </a>
                                          </td>
                                        );
                                      } else if (
                                        ColType === "Number" ||
                                        ColType === "Numeric"
                                      ) {
                                        return (
                                          <td
                                            className="text-left"
                                            data-order={item.data[j]}
                                          >
                                            <input
                                              type="number"
                                              name="chartSort"
                                              value={item.data[j]}
                                              onBlur={this.updateFromHyperLink}
                                              className="input_height wd-108"
                                              readOnly={
                                                c.Editable ? false : true
                                              }
                                              onChange={(e) => {
                                                this.handleChangeHyperLink(
                                                  e,
                                                  item.data,
                                                  i,
                                                  j
                                                );
                                              }}
                                            />
                                          </td>
                                        );
                                      } else if (ColType === "Date") {
                                        return (
                                          <>
                                            <td
                                              className="text-left"
                                              data-order={item.data[j]}
                                            >
                                              <input
                                                type="Date"
                                                name="chartSort"
                                                value={item.data[j]}
                                                className="input_height wd-108"
                                                readOnly={
                                                  c.Editable === 1
                                                    ? true
                                                    : false
                                                }
                                                onBlur={
                                                  this.updateFromHyperLink
                                                }
                                                onChange={(e) => {
                                                  this.handleChangeHyperLink(
                                                    e,
                                                    item.data,
                                                    i,
                                                    j
                                                  );
                                                }}
                                              />
                                            </td>
                                          </>
                                        );
                                      } else {
                                        return (
                                          <>
                                            <td
                                              className="text-left"
                                              data-order={item.data[j]}
                                            >
                                              <input
                                                type="text"
                                                name="chartSort"
                                                value={item.data[j]}
                                                className="input_height wd-108"
                                                readOnly={
                                                  c.Editable ? false : true
                                                }
                                                onBlur={
                                                  this.updateFromHyperLink
                                                }
                                                onChange={(e) => {
                                                  this.handleChangeHyperLink(
                                                    e,
                                                    item.data,
                                                    i,
                                                    j
                                                  );
                                                }}
                                              />
                                            </td>
                                          </>
                                        );
                                      }
                                    })}
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}
const mapStateToProps = (state) => ({ account: state.account });

export default connect(mapStateToProps, {
  updateFromHyperLink: AccountActions.updateFromHyperLink,
})(HyperLinkModal);

//  <div className="   pb-1 table-genrel login_form table-responsive ">
//    <table
//      id="chartofaccounts"
//      width="100%"
//      className=" financial_table  table-bordered"
//    >
//      <thead>
//        <tr>
//          <th>
//            <div className="custom-radio">
//              <label
//                className="check_main remember_check"
//                htmlFor="customRadio1109"
//              >
//                <input
//                  type="checkbox"
//                  className="custom-control-input"
//                  id="customRadio1109"
//                  name="checkboxAll"
//                  checked={this.state.checkAll}
//                  onChange={(e) => this.handleCheckboxes(e, "allCheck")}
//                />
//                <span className="click_checkmark global_checkmark"></span>
//              </label>
//            </div>
//          </th>
//          {this.state?.hyperLinkCols?.map((m, i) => {
//            return (
//              <th
//                scope="col"
//                key={i}
//                // className="text-left exp-supplier-th"
//              >
//                <span className="user_setup_hed"> {m.ColName}</span>
//              </th>
//            );
//          })}
//        </tr>
//      </thead>
//      <tbody>
//        {this.state?.hyperLinkRows?.map((item, i) => {
//          return (
//            <tr key={i}>
//              <td>
//                <div className="custom-radio">
//                  <label
//                    className="check_main remember_check"
//                    htmlFor={`accCheck${i}`}
//                  >
//                    <input
//                      type="checkbox"
//                      // className="custom-control-input"
//                      id={`accCheck${i}`}
//                      name={"checkboxAll"}
//                      checked={item.checked}
//                      onChange={(e) => this.handleCheckboxes(e, item)}
//                      style={{ width: "74%" }}
//                    />
//                    <span className="click_checkmark"></span>
//                  </label>
//                </div>
//              </td>

//              {this.props?.hyperLinkCols?.map((c, j) => {
//                let ColType = c.ColType;
//                if (ColType === "Number" || ColType === "Numeric") {
//                  return (
//                    <td className="text-left" data-order={item.data[j]}>
//                      <input
//                        type="number"
//                        name="chartSort"
//                        value={item.data[j]}
//                        className="input_height wd-108"
//                        readOnly={c.Editable ? false : true}
//                        onBlur={this.updateLedgers}
//                        onChange={(e) => {
//                          this.handleChangeGeneralLedgerList(e, item.data, i, j);
//                        }}
//                      />
//                    </td>
//                  );
//                } else if (ColType === "Date") {
//                  return (
//                    <>
//                      <td className="text-left" data-order={item.data[j]}>
//                        <input
//                          type="Date"
//                          name="chartSort"
//                          value={item.data[j]}
//                          className="input_height wd-108"
//                          readOnly={c.Editable ? false : true}
//                          onBlur={this.updateLedgers}
//                          onChange={(e) => {
//                            this.handleChangeGeneralLedgerList(
//                              e,
//                              item.data,
//                              i,
//                              j
//                            );
//                          }}
//                        />
//                      </td>
//                    </>
//                  );
//                } else {
//                  return (
//                    <>
//                      <td className="text-left" data-order={item.data[j]}>
//                        <input
//                          type="text"
//                          name="chartSort"
//                          value={item.data[j]}
//                          className="input_height wd-108"
//                          readOnly={c.Editable ? false : true}
//                          onBlur={this.updateLedgers}
//                          onChange={(e) => {
//                            this.handleChangeGeneralLedgerList(
//                              e,
//                              item.data,
//                              i,
//                              j
//                            );
//                          }}
//                        />
//                      </td>
//                    </>
//                  );
//                }
//              })}
//            </tr>
//          );
//        })}
//      </tbody>
//    </table>
//  </div>;
