import React, { useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import "./Modify.css";

const ModifyTimeCard = (props) => {
  const [message, setMessage] = React.useState();
  const [title, setTitle] = React.useState();

  useEffect(()=>{
    if (props.tab === "declined"){
      setTitle("Move")
      if (props.action === "moveToDraft"){
        setMessage("The selected timecards will be moved to your Draft folder for editing. ")
      }else {
        setMessage("The selected timecards will be moved to your Approve folder for editing. ")
      }
    }
    else {
      setTitle("Modify")
      setMessage("This Timecard will be copied to your drafts folder to modify and send for approval.")
    }
  },[props])

  const modifyTimecard = async () => {
    if (props.tab === "declined"){
      if (props.action === "moveToDraft"){
        await props.moveTimecard();
      }else{
        await props.moveToApproveTimecard()
      }
    }else if (props.tab === "pending"){
      if (props.action === "moveToDraftByPendingOperator"){
        await props.moveTimecard();
      }
    }
    else{
      await props.modifyTimecard();
    }

    closeModal();
  };

  const closeModal = async () => {
    await props.closeModal("openModifyModal");
  };

  return (
    <>
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={props.openModifyModal}
        onHide={closeModal}
        className="forgot_email_modal modal_704 mx-auto"
      >
        <Modal.Body>
          <div className="container-fluid ">
            <div className="main_wrapper p-10">
              <div className="row d-flex h-100">
                <div className="col-12 justify-content-center align-self-center form_mx_width">
                  <div className="forgot_form_main">
                    <div className="forgot_header">
                      <div className="modal-top-header">
                        <div className="row bord-btm">
                          <div className="col-auto pl-0">
                            <h6 className="text-left def-blue">{title}</h6>
                          </div>
                          <div className="col d-flex justify-content-end s-c-main">
                            <button
                              onClick={modifyTimecard}
                              type="button"
                              className="btn-save"
                            >
                              <span className="fa fa-check"></span>
                              Save
                            </button>
                            <button
                              onClick={closeModal}
                              type="button"
                              className="btn-save"
                            >
                              <span className="fa fa-ban"></span>
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="forgot_body">
                      <div className="row">
                        <div className="col-12">
                          <p className="model-p move-modal-t">
                            {
                             message
                            }

                          </p>
                        </div>
                        <div className="col-12"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ModifyTimeCard;
