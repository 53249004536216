import React, { useState, useEffect } from "react";
import "./CheckApproval.css";
import Modal from "react-bootstrap/Modal";

const CheckApproval = (props) => {
  const [state, setState] = useState({
    isLoading: false,
    comment: "",
    email: "N",
    formErrors: {
      comment: "",
    },
  });

  const handleChangeComment = (e) => {
    let fieldValue = e.target.value;
    setState((prev) => ({
      ...prev,
      comment: fieldValue,
    }));
  };

  const handleChangeCheckBox = (e) => {
    let { checked } = e.target;
    if (checked) {
      setState((pre) => ({
        ...pre,
        email: "Y",
      }));
    }
    if (!checked) {
      setState((pre) => ({
        ...pre,
        email: "N",
      }));
    }
  };
  let checkedValue;
  if (state.email == "N") {
    checkedValue = false;
  }
  if (state.email == "Y") {
    checkedValue = true;
  }

  const closeModal = async () => {
    props.closeModal("openCheckApprovalModal");
  };

  const onSave = () => {
    let { comment, email } = state;
    setState({ isLoading: true });
    if (props.state.typeMove === "Prev") {
      props.moveToPrevApprover(comment, email);
      setState({ comment: "" });
      props.closeModal("openCheckApprovalModal");
    } else if (props.state.typeMove === "Next") {
      props.moveToNextApprover(comment, email);
      setState({ comment: "" });
      props.closeModal("openCheckApprovalModal");
    }
    setState({ isLoading: false });
  };

  return (
    <>
      {state.isLoading ? <div className="se-pre-con"></div> : ""}

      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={props.openCheckApprovalModal}
        onHide={closeModal}
        className="forgot_email_modal modal_704 mx-auto"
      >
        <Modal.Body>
          <div className="container-fluid ">
            <div className="main_wrapper p-10">
              <div className="row d-flex h-100">
                <div className="col-12 justify-content-center align-self-center form_mx_width">
                  <div className="forgot_form_main">
                    <div className="forgot_header">
                      <div className="modal-top-header">
                        <div className="row bord-btm">
                          <div className="col-auto pl-0">
                            <h6 className="text-left def-blue">Reasons</h6>
                          </div>

                          <div className="col d-flex justify-content-end s-c-main">
                            <button
                              // onClick={onSaveDisplaySetting}
                              type="button"
                              className="btn-save"
                              onClick={onSave}
                            >
                              <img
                                src="images/save-check.png"
                                className="mr-2"
                                alt="display-icon"
                              />
                              Save
                            </button>
                            <button
                              onClick={closeModal}
                              type="button"
                              className="btn-save"
                            >
                              <img
                                src="images/cancel.png"
                                className="mr-2"
                                alt="display-icon"
                              />
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="forgot_body">
                      <div className="row mt-4">
                        <div className="col-md-12">
                          <div className="row">
                            <div className="col-md-12">
                              <label> Write the Reason: </label>

                              <div className="comment_section ">
                                <textarea
                                  className="form-control form-control-sm mb-3 comment_textarea"
                                  rows="3"
                                  placeholder=""
                                  name="comment"
                                  value={state.comment}
                                  onChange={handleChangeComment}
                                />

                                <div className=" pt-1">
                                  <label> Resend Email: </label>
                                  {/* <input
                                  type="checkbox"
                                  className={`form-control `}
                                  id={"orderDetails"}
                                  onChange={(e) => {}}
                                /> */}

                                  <input
                                    className="chk-appr-input"
                                    type="checkbox"
                                    // id={`chk${i}`}
                                    checked={checkedValue ? true : false}
                                    onClick={handleChangeCheckBox}
                                  />
                                  {/* <label></label> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CheckApproval;

// import React, { useState, useEffect } from "react";
// import "./CheckApproval.css";
// import Modal from "react-bootstrap/Modal";
// import { toast } from "react-toastify";
// import {
//   toBase64,
//   addDragAndDropFileListners,
//   removeDragAndDropFileListners,
// } from "../../../Utils/Helpers";

// const CheckApproval = (props) => {
//   const [state, setState] = useState({
//     isLoading: false,
//     email: "N",
//     check: false,
//   });

//   useEffect(() => {
//
//   }, [props.openCheckApprovalModal]);

//   const closeModal = async () => {
//     props.closeModal("openCheckApprovalModal");
//   };
//   const handleChangeApprov = (event, type) => {
//     // let { check } = state;
//     // check = event.target.checked;
//     // if (check) {
//     //   setState((prev) =>
//  ({
//     //     ...prev,
//     //     email: "Y",
//     //   }));
//     // } else {
//     //   setState((prev) => ({
//     //     ...prev,
//     //     email: "N",
//     //   }));
//     // }
//     setState((prev) => ({
//       ...prev,
//       check: !state.check,
//       email: state.check ? "Y" : "N",
//     }));
//   };
//   const onSave = () => {
//     setState({ isLoading: true });
//     if (props.state.typeMove === "Prev") {
//       props.moveToPrevApprover();
//       props.closeModal("openCheckApprovalModal");
//     } else if (props.state.typeMove === "Next") {
//       props.moveToNextApprover();
//       props.closeModal("openCheckApprovalModal");
//     }
//     setState({ isLoading: false });
//   };

//   return (
//     <>
//       {state.isLoading ? <div className="se-pre-con"></div> : ""}

//       <Modal
//         size="lg"
//         aria-labelledby="contained-modal-title-vcenter"
//         centered
//         show={props.openCheckApprovalModal}
//         onHide={closeModal}
//         className="forgot_email_modal modal_704 mx-auto"
//       >
//         <Modal.Body>
//           <div className="container-fluid ">
//             <div className="main_wrapper p-10">
//               <div className="row d-flex h-100">
//                 <div className="col-12 justify-content-center align-self-center form_mx_width">
//                   <div className="forgot_form_main">
//                     <div className="forgot_header">
//                       <div className="modal-top-header">
//                         <div className="row bord-btm">
//                           <div className="col-auto pl-0">
//                             <h6 className="text-left def-blue">Reasons</h6>
//                           </div>

//                           <div className="col d-flex justify-content-end s-c-main">
//                             <button
//                               // onClick={onSaveDisplaySetting}
//                               type="button"
//                               className="btn-save"
//                               onClick={onSave}
//                             >
//                               <img
//                                 src="images/save-check.png"
//                                 className="mr-2"
//                                 alt="display-icon"
//                               />
//                               Save
//                             </button>
//                             <button
//                               onClick={closeModal}
//                               type="button"
//                               className="btn-save"
//                             >
//                               <img
//                                 src="images/cancel.png"
//                                 className="mr-2"
//                                 alt="display-icon"
//                               />
//                               Cancel
//                             </button>
//                           </div>
//                         </div>
//                       </div>
//                     </div>
//                     <div className="forgot_body">
//                       <div className="row mt-4">
//                         <div className="col-md-12">
//                           <div className="row">
//                             <div className="col-md-12">
//                               <label> Write the Reason: </label>

//                               <div className="comment_section ">
//                                 <textarea
//                                   className="form-control form-control-sm mb-3 comment_textarea"
//                                   rows="3"
//                                   placeholder=""
//                                   name="comment"
//                                   // value={state.comment}
//                                   // onChange={handleChangeComment}
//                                 />

//                                 <div className=" pt-1">
//                                   <label> Resend Email: </label>

//                                   {/* <input
//                                   type="checkbox"
//                                   className={`form-control `}
//                                   id={"orderDetails"}
//                                   onChange={(e) => {}}
//                                 /> */}

//                                   <input
//                                     className="chk-appr-input"
//                                     type="checkbox"
//                                     // id={`chk${i}`}
//                                     checked={state.check}
//                                     onClick={(e) =>
//                                       handleChangeApprov(e, "checkBox")
//                                     }
//                                   />
//                                   {/* <label></label> */}
//                                 </div>
//                               </div>
//                             </div>
//                           </div>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </Modal.Body>
//       </Modal>
//     </>
//   );
// };

// export default CheckApproval;
