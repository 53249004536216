const initialState = {
    pageData: {
        orders: {},
        invoices: {},
        expenses: {},
        documents: {},
        suppliers: {},
        journals: {},
        payments: {},
        timecards: {},
        reports: {},
        accounts: {}
    },
}

const paginationReducer = function (state = initialState, action) {
    switch (action.type) {
        case "SET_LAST_PAGE":
            state = {
                ...state,
                pageData: {
                    ...state.pageData,
                    [action.payload.page]: action.payload.pageData
                }
            }
            break;

        case "RESET_LAST_PAGE":
            state = {
                ...state,
                pageData: {
                    ...state.pageData,
                    [action.payload.page]: initialState.pageData[action.payload.page]
                }
            }
            break;

        default:
            break;
    }
    return state;
}

export default paginationReducer;