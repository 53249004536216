import DPFClient from "../../Utils/axiosDPFAPIClient";

function isSessionTimeout(error) {
  return (
    error === "Session has expired. Please login again." ||
    error === "User has not logged in."
  );
}

export const resetInbox = () => async (dispatch) => {
  dispatch({
    type: "EMAIL_INBOX_RESET",
  });
};

export const fetchEmails =
  (
    type,
    keyword,
    from,
    to,
    sort,
    sortOrder = "Ascend",
    index = 1,
    numberOfRecords = 10
  ) =>
  async (dispatch) => {
    dispatch({
      type: "EMAIL_INBOX_INIT",
    });

    const url = "/DPFAPI/AdminRequest";
    const actionType =
      type === "incoming" ? "GetIncomingEmails" : "GetOutgoingEmails";

    function toUnixDate(date) {
      return date ? Math.floor(new Date(date).getTime() / 1000) : "";
    }

    try {
      const {
        data: { AdminRequest_response },
      } = await DPFClient.post(url, {
        actionType,
        page: index,
        displayRecords: numberOfRecords,
        search: keyword,
        fromDateUnix: toUnixDate(from),
        toDateUnix: toUnixDate(to),
        sort,
        order: sortOrder,
      });

      if (isSessionTimeout(AdminRequest_response.results[0]?.description)) {
        dispatch({
          type: "EMAIL_INBOX_FETCHED_FAIL",
          payload: AdminRequest_response.results[0]?.description,
          loading: false,
        });
      } else {
        dispatch({
          type: "EMAIL_INBOX_FETCHED",
          payload: AdminRequest_response,
          loading: false,
        });
      }
    } catch (err) {
      const error = err.message || `Error loading ${type} emails.`;
      dispatch({
        type: "EMAIL_INBOX_FETCHED_FAIL",
        payload: error,
        loading: false,
      });
    }
  };

export const fetchEmailSortOptions = () => async (dispatch) => {
  dispatch({
    type: "EMAIL_INBOX_OPTIONS_INIT",
  });

  const url = "/DPFAPI/AdminRequest?actionType=GetEmailSortOptions";

  try {
    const {
      data: { AdminRequest_response },
    } = await DPFClient.get(url);

    dispatch({
      type: "EMAIL_INBOX_OPTIONS_FETCHED",
      payload: AdminRequest_response,
    });
  } catch (err) {
    const error = err.message || "Error loading email sort options.";
    dispatch({
      type: "EMAIL_INBOX_OPTIONS_FETCHED_FAIL",
      payload: error,
    });
  }
};

export const downloadEmailAttachments = (id, type) => async (dispatch) => {
  dispatch({
    type: "EMAIL_INBOX_DOWNLOAD_ATTACHMENT_INIT",
  });

  const url = "/DPFAPI/AdminRequest";
  const downloadType = type === "incoming" ? "Incoming" : "Outgoing";

  try {
    const {
      data: { AdminRequest_response },
    } = await DPFClient.post(url, {
      actionType: "DownloadEmailAttachments",
      recordId: id,
      type: downloadType,
    });
    if (AdminRequest_response.zipAttachment) {
      dispatch({
        type: "EMAIL_INBOX_DOWNLOAD_ATTACHMENT_FETCHED",
        payload: AdminRequest_response,
      });
    } else {
      throw new Error("EMPTY_ATTACHMENTS");
    }
  } catch (err) {
    const error = err.message || `Error downloading ${type} email attachments.`;
    dispatch({
      type: "EMAIL_INBOX_DOWNLOAD_ATTACHMENT_FETCHED_FAIL",
      payload: error,
    });
  }
};

export const approvalEmail = (emailTo, emailFrom, subject) => async (dispatch) => {
  dispatch({
    type: "EMAIL_INBOX_APPROVAL_INIT",
  });

  const url = "/DPFAPI/EmailRequest";
  try {
    const {
      data: { EmailResponse },
    } = await DPFClient.post(url, {
      emailTo: emailTo,
      emailFrom: emailFrom,
      subject: subject,
    });
    if (EmailResponse.result) {
      dispatch({
        type: "EMAIL_INBOX_APPROVAL_FETCHED",
        payload: EmailResponse,
      });
    } else {
      throw new Error("EMPTY_APPROVAL");
    }
  } catch (err) {
    const error = err.message || `Error approval email.`;
    dispatch({
      type: "EMAIL_INBOX_APPROVAL_FAIL",
      payload: error,
    });
  }
};
