import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Modal from "react-bootstrap/Modal";
import Select from "react-select";
import DatePicker from "react-datepicker";
import Dropdown from "react-bootstrap/Dropdown";
import { updateApprovalGroup } from "../../../Actions/PaymentActions/PaymentActions";
const PaymentsMoreDetails = (props) => {
  const [openPaymentDisplay, setOpenPaymentDisplay] = useState(false);
  const [state, setState] = useState(null);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  let { showPaymentTallisTabPane, closeModal, updatePaymentSummaryState } =
    props;
  showPaymentTallisTabPane = showPaymentTallisTabPane
    ? showPaymentTallisTabPane.toLowerCase()
    : "";

  const periodOption = () => {
    if (props.state.approvalOptions.length > 0) {
      let newArray = [];
      props.state.approvalOptions.map((item) =>
        newArray.push({ value: item.option, label: item.option })
      );
      return newArray;
    }
  };
  const handleChangeType = (data) => {
    setState((prev) => ({
      ...prev,
      approvalGroup: data.value,
    }));
  };

  //   const onChangeHandlerDate = (date) => {
  //     setState((prev) => ({
  //       ...prev,
  //       poDate: new Date(date).getTime(),
  //     }));
  //   };
  const onChangeHandler = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const onSave = async (e) => {
    e.preventDefault();
    let paymentSummary = {
      approvalGroup: state.approvalGroup,

      tran: state.tran,
    };

    setIsLoading(true);

    await dispatch(updateApprovalGroup(paymentSummary));

    updatePaymentSummaryState();

    let { getPaymentsList, activePayment } = props.state;
    let activePaymentToUpdate = getPaymentsList.find(
      (fi) => fi.id === activePayment
    );

    await props.getPaymentSummary(activePaymentToUpdate, true);
    closeModal("openPaymentsMoreDetailsModal");

    setIsLoading(false);
  };

  useEffect(() => {
    setState((prev) => ({
      ...prev,
      ...props?.state,
    }));
  }, [props]);

  return (
    <>
      {isLoading ? <div className="se-pre-con"></div> : ""}
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={state?.openPaymentsMoreDetailsModal}
        onHide={() => closeModal("openPaymentsMoreDetailsModal")}
        className="forgot_email_modal modal_704 mx-auto"
      >
        <Modal.Body>
          <div className="container-fluid ">
            <div className="main_wrapper p-10">
              <div className="row d-flex h-100">
                <div className="col-12 justify-content-center align-self-center form_mx_width">
                  <div className="forgot_form_main">
                    <div className="forgot_header">
                      <div className="modal-top-header">
                        <div className="row bord-btm">
                          <div className="col d-flex justify-content-end s-c-main">
                            {/* <h6 className="text-left def-blue">
                              Payment Details
                            </h6> */}
                            {/* 
                            <button
                              onClick={onSave}
                              type="button"
                              className="btn-save"
                            >
                              <img
                                src="images/save-check.png"
                                className="mr-2"
                                alt="display-icon"
                              />
                              Save
                            </button> */}
                            <button
                              onClick={() =>
                                props.closeModal("openPaymentsMoreDetailsModal")
                              }
                              type="button"
                              className="btn-save"
                            >
                              <img
                                src="images/cancel.png"
                                className="mr-2"
                                alt="display-icon"
                              />
                              Cancel
                            </button>

                            <div className="order-display-main ">
                              <Dropdown
                                alignRight="false"
                                drop="down"
                                className="border-0"
                              >
                                <Dropdown.Toggle id="dropdown-basic-two">
                                  <img
                                    src="images/more.png"
                                    className="img-fluid"
                                    alt="user"
                                  />
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                  <Dropdown.Item
                                    variant="success"
                                    onClick={() => setOpenPaymentDisplay(true)}
                                  >
                                    <button
                                      type="button"
                                      className="btn-save def-blue"
                                    >
                                      <img
                                        src="images/display-icon.png"
                                        className="mr-2"
                                        alt="display-icon"
                                      />
                                      Display
                                    </button>
                                  </Dropdown.Item>

                                  {/* <Dropdown.Item variant="success">
                                    <button
                                      onClick={() => {}}
                                      type="button"
                                      className="btn-save def-blue"
                                    >
                                      <img
                                        src="images/save-check.png"
                                        className="mr-2"
                                        alt="display-icon"
                                      />
                                      Save
                                    </button>
                                  </Dropdown.Item> */}
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="forgot_body">
                      <div className="row mt-4">
                        <div className="col-12">
                          <div className="form-group custon_select">
                            <label>
                              Account Name
                              <span className="fa fa-check check_blue"></span>
                            </label>
                            <div className="modal_input">
                              <input
                                name="accountName"
                                type="text"
                                className={
                                  showPaymentTallisTabPane !== "draft"
                                    ? "disable_bg disable_border"
                                    : "form-control"
                                }
                                id="usr"
                                value={state?.accountName || ""}
                                onChange={
                                  showPaymentTallisTabPane === "draft"
                                    ? onChangeHandler
                                    : null
                                }
                                disabled={showPaymentTallisTabPane !== "draft"}
                              />
                            </div>
                          </div>
                        </div>
                        {/* <div className=" col-12">
                          <div className="form-group custon_select">
                            <label htmlFor="id_dt">Date</label>
                            <div className="modal_input datePickerUP">
                              <DatePicker
                                name="poDate"
                                id="periodEndingDate"
                                tabIndex="3334"
                                dateFormat="d MMM yyyy"
                                autoComplete="off"
                                onChange={
                                  showPaymentTallisTabPane === "draft"
                                    ? onChangeHandlerDate
                                    : null
                                }
                                className={
                                  showPaymentTallisTabPane !== "draft"
                                    ? "disable_bg disable_border"
                                    : ""
                                }
                                disabled={showPaymentTallisTabPane !== "draft"}
                                selected={state?.poDate || ""}
                              />
                            </div>
                          </div>
                        </div>  */}

                        <div className="col-12">
                          <div className="form-group custon_select">
                            <label>Approval Group</label>
                            <Select
                              className={
                                showPaymentTallisTabPane !== "draft"
                                  ? "disable_bg disable_border"
                                  : "width-selector"
                              }
                              value={{
                                label: state?.approvalGroup,
                                value: state?.approvalGroup,
                              }}
                              classNamePrefix="custon_select-selector-inner"
                              options={periodOption()}
                              onChange={(e) =>
                                showPaymentTallisTabPane === "draft"
                                  ? handleChangeType(e)
                                  : () => {}
                              }
                              isDisabled={showPaymentTallisTabPane !== "draft"}
                              theme={(theme) => ({
                                ...theme,
                                border: 0,
                                borderRadius: 0,
                                colors: {
                                  ...theme.colors,
                                  primary25: "#f2f2f2",
                                  primary: "#f2f2f2",
                                },
                              })}
                            />
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="form-group custon_select">
                            <label> Supplier </label>
                            <div className="modal_input">
                              <input
                                name="supplier"
                                type="text"
                                className={
                                  showPaymentTallisTabPane !== "draft"
                                    ? "disable_bg disable_border"
                                    : "form-control"
                                }
                                id="payment-order"
                                value={state?.supplier || ""}
                                onChange={
                                  showPaymentTallisTabPane === "draft"
                                    ? onChangeHandler
                                    : null
                                }
                                disabled={showPaymentTallisTabPane !== "draft"}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="form-group custon_select">
                            <label>User Name </label>
                            <div className="modal_input">
                              <input
                                name="userName"
                                type="text"
                                className={
                                  showPaymentTallisTabPane !== "draft"
                                    ? "disable_bg disable_border"
                                    : "form-control"
                                }
                                id="payment-userName"
                                value={state?.userName || ""}
                                onChange={
                                  showPaymentTallisTabPane === "draft"
                                    ? onChangeHandler
                                    : null
                                }
                                disabled={showPaymentTallisTabPane !== "draft"}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-12">
                          <div className="form-group custon_select">
                            <label>Supplier Code</label>
                            <div className="modal_input">
                              <input
                                type="text"
                                className={
                                  showPaymentTallisTabPane !== "draft"
                                    ? "disable_bg disable_border"
                                    : "form-control"
                                }
                                value={state?.supplierCode || ""}
                                name="supplierCode"
                                id="payment-supplierCode"
                                onChange={
                                  showPaymentTallisTabPane === "draft"
                                    ? onChangeHandler
                                    : null
                                }
                                disabled={showPaymentTallisTabPane !== "draft"}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-12">
                          <div className="form-group custon_select">
                            <label>Tran</label>
                            <div className="modal_input">
                              <input
                                name="tran"
                                type="text"
                                className={
                                  showPaymentTallisTabPane !== "draft"
                                    ? "disable_bg disable_border"
                                    : "form-control"
                                }
                                onChange={
                                  showPaymentTallisTabPane === "draft"
                                    ? onChangeHandler
                                    : null
                                }
                                id="payment-tran"
                                value={state?.tran || ""}
                                disabled={showPaymentTallisTabPane !== "draft"}
                              />
                            </div>
                          </div>
                        </div>

                        {/* paid  */}
                        <div className="col-12">
                          <div className="form-group custon_select">
                            <label> Paid </label>
                            <div className="modal_input">
                              <input
                                type="text"
                                className={
                                  showPaymentTallisTabPane !== "draft"
                                    ? "disable_bg disable_border"
                                    : "form-control"
                                }
                                name="paid"
                                value={state?.paid || ""}
                                onChange={
                                  showPaymentTallisTabPane === "draft"
                                    ? onChangeHandler
                                    : null
                                }
                                disabled={showPaymentTallisTabPane !== "draft"}
                                id="payment-paid"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="form-group custon_select">
                            <label>Description</label>
                            <div className="modal_input">
                              <input
                                name="description"
                                type="text"
                                autoComplete="off"
                                className={
                                  showPaymentTallisTabPane !== "draft"
                                    ? "disable_bg disable_border"
                                    : "form-control"
                                }
                                onChange={
                                  showPaymentTallisTabPane === "draft"
                                    ? onChangeHandler
                                    : null
                                }
                                id="usr"
                                value={state?.description || ""}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="form-group custon_select">
                            <label> Account Code </label>
                            <div className="modal_input">
                              <input
                                name="accountCode"
                                type="text"
                                className={
                                  showPaymentTallisTabPane !== "draft"
                                    ? "disable_bg disable_border"
                                    : "form-control"
                                }
                                id="payment-accountcode"
                                value={state?.accountCode || ""}
                                onChange={
                                  showPaymentTallisTabPane === "draft"
                                    ? onChangeHandler
                                    : null
                                }
                                disabled={showPaymentTallisTabPane !== "draft"}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default PaymentsMoreDetails;
