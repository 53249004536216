export const setLastPageNumber = (page, pageData) => async (dispatch) => {
    dispatch({
        type: "SET_LAST_PAGE",
        payload: { page, pageData }
    });
};

export const resetLastPage = (page) => async (dispatch) => {
    dispatch({
        type: "RESET_LAST_PAGE",
        payload: { page }
    });
};
