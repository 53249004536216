import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { toast } from "react-toastify";

import {
  logOutUser,
  getProductions,
  clearStatesAfterLogout,
} from "../../Actions/UserActions/UserActions";
import { disconnectWebSocket } from "../../Actions/WebSocketActions/WebSocketActions";
import ProductionList from "./ProductionList";
import { useProductionLogin } from "./useProductionLogin";
import { useLocation } from 'react-router-dom';

export default function LoginTable() {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const productionSwitch = useProductionLogin();
  const [isLoading, setLoading] = useState(false);
  const [productionName, setProductionName] = useState("");
  const productions = useSelector((state) => state.user.productions);
  const getProductionsSuccess = useSelector(
    (state) => state.user.getProductionsSuccess
  );
  const [productionList, setProductionList] = useState([])
  const getProductionsError = useSelector(
    (state) => state.user.getProductionsError
  );
  const logoutSuccess = useSelector((state) => state.user.logoutSuccess);
  const logoutError = useSelector((state) => state.user.logoutError);

  function loginProduction(production, companyId) {
    productionSwitch.login(production, companyId);
  }

  function handleLoginClick() {
    const { companyID } = location.state || {};
    // There are chances that this might not be present
    // There are chances that the user might not have selected a production
    // and simply opened an URL in other tab, as we set the companyID in localstorage
    // This will be picked up. need to remove this from localStorage as many places as I can
    const companyIdFromLocalStorage = localStorage.getItem("companyID");
    const companyId = companyID || companyIdFromLocalStorage;
    if (productionName) {
      loginProduction(productionName, companyId);
    } else {
      toast.error("Please select a production");
    }
  }

  function handleLogout() {
    setLoading(true);
    dispatch(logOutUser());
    dispatch(disconnectWebSocket());
  }

  function handleProductionClick(selectedProduction) {
    setProductionName(selectedProduction);
  }

  function handleProductionDoubleClick(selectedProduction) {
    setProductionName(selectedProduction);
    loginProduction(selectedProduction);
  }

  function handleChangeSearch(e) {
    const filterText = e.target.value;
    if (filterText) {
      const filteredProductions = productions.filter(production =>
        production.productionName.toLowerCase().includes(filterText.toLowerCase())
      );
      setProductionList(filteredProductions);
      if (filteredProductions && filteredProductions.length === 1) {
        setProductionName(filteredProductions[0].productionName);
      }
    } else {
      setProductionList(productions);
    }
  }

  useEffect(() => {
    setLoading(true);
    dispatch(getProductions());
  }, []);

  useEffect(() => {
    if (getProductionsSuccess) {
      setLoading(false);
    }
    if (productions) {
      setProductionList(productions)
      if (productions.length === 1) {
        setProductionName(productions[0].productionName);
        const { companyID } = location.state || {};
        loginProduction(productions[0].productionName, companyID);
      }
    }
  }, [getProductionsSuccess]);

  useEffect(() => {
    if (getProductionsError) {
      setLoading(false);
    }
  }, [getProductionsError]);

  /** LOGOUT */
  useEffect(() => {
    if (logoutSuccess) {
      dispatch(clearStatesAfterLogout());
      history.push("/login");
    }
  }, [logoutSuccess]);

  useEffect(() => {
    if (logoutError === "User has not logged in.") {
      history.push("/login");
      dispatch(clearStatesAfterLogout());
    } else if (logoutError !== "") {
      toast.error(logoutError);
    }
  }, [logoutError]);

  useEffect(() => {
    if (getProductionsSuccess) {
      setLoading(productionSwitch.isLoading);
    }
  }, [productionSwitch.isLoading]);

  return (
    <>
      <div className="container-fluid pl-0">
        {isLoading ? <div className="se-pre-con"></div> : ""}
        <div className="main-wrap">
          <div className="row">
            <div className="col-12 col-md-6 pr-sm-0 pr-xs-0 order2">
              <div className="left_slider">
                <div
                  id="login_left_slider"
                  className="carousel slide mm_login_slider"
                  data-ride="carousel"
                >
                  <ol className="carousel-indicators">
                    <li
                      data-target="#login_left_slider"
                      data-slide-to="0"
                      className="active"
                    ></li>
                    <li data-target="#login_left_slider" data-slide-to="1"></li>
                    <li data-target="#login_left_slider" data-slide-to="2"></li>
                  </ol>

                  <div className="carousel-inner">
                    <div className="carousel-item active">
                      <img
                        src="images/1st_card.png"
                        className="d-block img-fluid mx-auto"
                        alt="slide"
                      />
                      <div className="carousel-caption">
                        <h5>Dashboard Page</h5>
                        <p>
                          Your dashboard quickly shows you recent activity and a
                          summary of orders and invoices.
                        </p>
                      </div>
                    </div>
                    <div className="carousel-item">
                      <img
                        src="images/2nd_card.png"
                        className="d-block img-fluid mx-auto"
                        alt="slide"
                      />
                      <div className="carousel-caption">
                        <h5>Orders Screen</h5>
                        <p>Visually see orders when drafting and approving.</p>
                      </div>
                    </div>
                    <div className="carousel-item">
                      <img
                        src="images/3rd_card.png"
                        className="d-block img-fluid mx-auto"
                        alt="slide"
                      />
                      <div className="carousel-caption">
                        <h5>Invoices Screen</h5>
                        <p>OCR Invoices for quick entry and PO matching.</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="login-badges">
                  <a
                    href="https://play.google.com/store/apps/details?id=com.digital_paper_flow&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
                    target="_blank"
                  >
                    <img
                      src="images/google-play-store.png"
                      className="img-fluid"
                      alt="google-play"
                    />
                  </a>
                  <a
                    href="https://apps.apple.com/ng/app/digital-paper-flow/id1504166985"
                    style={{
                      display: "inline-block",
                    }}
                    target="_blank"
                  >
                    <img
                      src="images/apple-store.png"
                      className="img-fluid"
                      alt="google-play"
                    />
                  </a>
                </div>
                <p className="copy_right">@ Copyright TPH Technologies 2018</p>
              </div>
            </div>

            <div className="col-12 col-md-6 order1">
              <div className="login_side tabel">
                <div className="row">
                  <div className="col-12">
                    <div className="site_log">
                      <img
                        src="images/logo.png"
                        className="d-block img-fluid mx-auto pr-sm-5"
                        alt="Logo"
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="label__wrapper2">
                      <input
                        className="form-control search_input"
                        type="text"
                        id="search"
                        name="search"
                        placeholder="  Search field here "
                        onChange={handleChangeSearch}
                        onBlur={handleChangeSearch}
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="login_form">
                      <div className="login_table_list scroll-inner-container">
                        <ProductionList
                          productions={productionList}
                          currentProductionName={productionName}
                          onProductionClick={handleProductionClick}
                          onProductionDoubleClick={handleProductionDoubleClick}
                        />
                      </div>
                      <div className="login_btn">
                        <button
                          type="submit"
                          className="btn btn-primary login_blue"
                          onClick={handleLoginClick}
                        >
                          Login
                        </button>
                        <button
                          type="submit"
                          className="btn btn-primary login_white"
                          onClick={handleLogout}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row no-gutters login_table_btns">{""}</div>
              </div>
              <p className="term"><a href="https://tphglobal.com/terms-of-service/" target="_blank">Term of use</a>. <a href="https://tphglobal.com/privacy-policy-and-data-protection-information" target="_blank">Privacy policy.</a></p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
