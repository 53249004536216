import { isValidString } from "./Helpers";

export function handleValidation(inputName, inputValue, formErrors) {
    switch (inputName) {
        case "email":
            let email_pattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (inputValue?.length < 1) {
                formErrors.email = "This Field is Required.";
            } else if (!email_pattern.test(inputValue)) {
                formErrors.email = "Please enter valid email format.";
            } else {
                formErrors.email = "";
            }
            break;
        case "userLogin":
            if (!isValidString (inputValue, true)) {
                formErrors.userLogin = "This Field is Required.";
            } else if (String(inputValue).includes(" ")) {
                formErrors.userLogin = "No Spaces Allowed";
            } else {
                formErrors.userLogin = "";
            }
            break;
        default:
            if (
                inputValue?.length < 1
            ) {
                formErrors[inputName] = 'This Field is Required.'
            } else {
                formErrors[inputName] = ''
            }
    }

    return formErrors
}
export function handleWholeValidation(states, formErrors) {
    Object.keys(states).map(function (key) {
        formErrors = handleValidation(
            key, //name of field
            states[key], //value of field
            formErrors
        )
    })
    return formErrors
}

/**
 * Returns a valid ASCII string allows only letters, numbers, hyphens, periods and underscores
 * Replaces accented characters with their non-accented counterparts
 * Removes spaces and leading/trailing whitespaces
 * Removes all non-ASCII characters
 * @param {string} input 
 * @returns {string|null}
 */
export function sanitizeToASCII(input) {
    if (!isValidString(input)) {
        return null;
    }
    let normalized = input.normalize('NFD');
    let ascii = normalized.replace(/[\u0300-\u036f]/g, '').replace(/[^\x00-\x7F]/g, '');
    ascii = ascii.replace(/[^a-zA-Z0-9._\-\s]/g, '');
    return ascii.replace(/\s+/g, '').trim();
}