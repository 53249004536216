import React, { Component } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import Header from "../Common/Header/Header";
import TopNav from "../Common/TopNav/TopNav";
import Dropdown from "react-bootstrap/Dropdown";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import ReactPaginate from "react-paginate";
import $ from "jquery";
import { Document, Page } from "react-pdf";
import FileSaver from "file-saver";
import CheckApproval from "../Modals/CheckApproval/CheckApproval";

import Delete from "../Modals/Delete/Delete";
import Attachments from "../Modals/Attachments/Attachments";
import Comments from "../Modals/Comments/Comments";
import Activity from "../Modals/Activity/Activity";
import DocumentMoreDetails from "../Modals/DocumentMoreDetaiils/DocumentMoreDetails";

import * as DocumentActions from "../../Actions/DocumentActions/DocumentActions";
import * as PaginationActions from "../../Actions/paginationActions/paginationActions";
import { clearStatesAfterLogout } from "../../Actions/UserActions/UserActions";
import {
  handleAPIErr,
  zoomIn,
  zoomOut,
  handleDropdownZooming,
  downloadAttachments,
  downloadZipAttachment,
  getActivatedTab,
  isSafari
} from "../../Utils/Helpers";
import { options } from "../../Constants/Constants";
import Decline from "../Modals/Decline/Decline";
import moment from "moment";
import { TransferRecords } from "../Modals/TransferRecords/TransferRecords";
import { downloadFileFromPresignedUrl, getBase64FromPreSignedS3Url, isRemoteStorage, uploadFileListToS3 } from "../../Utils/S3StorageHelper";
import { resendEmail } from "../../Actions/DocumentActions/DocumentActions";
import ConfirmationModal from "../Modals/Confirmation/Confirmation";
const uuidv1 = require("uuid/v1");

class Documents extends Component {
  constructor(props) {
    super();
    this.state = {
      isLoading: false,
      tran: "", //tran of current selected Docmunet
      multipleTrans: [], //when user selects multiple Documents to perform different functionality

      documentsTallies: [], //e.g Draft, Pending, Approved, etc
      docListSearch: props.pageData?.searchTerm ?? "", //search on DOC list
      getDocList: [], //side menu (left side) DOC list data
      clonedgetDocList: [], //a copy of  getDocList
      activeDoc: "", //to add class active in lists of getting DOC (in left side )
      activeDocTallis: "", //to add class active on DOC tallis
      showDocTallisTabPane: "", //to add class active on Doc tallis below tab pane
      filteredDocList: [], //this contains filterd list and used for searching on it
      approvalsGroups: [],
      approvers: [], //to just show on side menuw bar
      previews: [],
      currentAttachmentIndex: 0,
      rotate: 0,
      numPages: null,
      pageNumber: 1,
      page: props.pageData?.pageNumber ?? 1, //The page to retrieve. Returns the first page if left as zero.
      resetPage: true,
      pageStart: "",
      docState: "", //draft, pending, declined, all etc
      pageEnd: "",
      totalDocuments: "",
      totalPages: "", //getNewDocumentsList return total num of pages
      display: localStorage.getItem("orderDPR") || 10, //The number of Timecards to display.
      zero: false,
      close: false,
      order: "Ascend",
      numPagesArr: [], //it contains number of pages of each PDF
      docComments: [],
      docAttachments: [],
      previewableAttachments: [],
      attachmentSize: 0, //default 0 Bytes,  attachments should always less than 29.5 MB
      docActivity: [],
      sortFilterDoc: "docType",
      sortFilterDocCheck: "ASC", //to check the sort is in ascending OR descending Order  Ascending -> ASC, Descending -> DESC
      openAttachmentsModal: false,
      openCommentsModal: false,
      openDeleteModal: false,
      openActivityModal: false,
      viewTeam: "N",
      teamDocCheck: "", //to check selected document is team document or not
      scaling: 3.4,
      dropdownZoomingValue: { label: "40%", value: "40%" },
      toggleRightSidebar: true,
      docDate: "",
      docDateUnix: "",
      docDescription: "",
      openDocumentMoreModal: false,
      openCheckApprovalModal: false,
      openDeclineModal: false,
      approverGroup: {
        label: "Select Approver Group",
        value: "",
      },
      allSelected: false,
      guid: null,
      activePage: null,
      checked: false,
      selectedGuid: null,
      multipleSelectedGuid: [],
      showTransferRecordsModal: false,
      refreshedGuid:null,
      refreshedClicked:false,
      isSendForApproval:false,
      activatedTab: null,
      edited:false,
      isModalOpen: false,
      modalContent: {
        title: '',
        body: '',
        actionButtonName: '',
        onConfirm: () => {},
      },
    };
  }
  async componentDidMount() {
    //right hand side bar setting with PO Image
    window.addEventListener(
      "resize",
      () => {
        $(".mm_ordr1").addClass("mm_order_pdfMain");

        if ($(window).width() > 991) {
          this.setState({ toggleRightSidebar: true });
          $(".documents_attatchments1").removeClass("wid-0");
        }
        if ($(window).width() <= 991) {
          this.setState({ toggleRightSidebar: false });
          $(".documents_attatchments1").addClass("wid-0");
        }
      },
      false
    );
    // end
    //focus search input field by pressing Tab key
    // document.onkeydown = function (evt) {
    //   evt = evt || window.event;
    //   if (evt.keyCode == 9) {
    //     evt.preventDefault();
    //     let id = document.getElementById("docListSearchId");
    //     if (id) {
    //       document.getElementById("docListSearchId").focus();
    //     }
    //   }
    // };

    let { sortFilterDoc, sortFilterDocCheck, viewTeam } = this.state;

    sortFilterDoc = localStorage.getItem("sortFilterDoc") || sortFilterDoc;
    sortFilterDocCheck =
      localStorage.getItem("sortFilterDocCheck") || sortFilterDocCheck;

    //Team Document Check
    viewTeam = localStorage.getItem("teamDocuments") || viewTeam;

    this.setState({ sortFilterDoc, sortFilterDocCheck, viewTeam });

    $(document).ready(function () {
      var vw = $(window).width();
      var nav = $(".navbar.fixed-top").height();
      var underNav = $(".order-tabs").height();
      var wh = $(window).height();
      var h = wh - nav - 60;
      var rsb = wh - nav - underNav - 20;
      // var pdfDiv = wh - nav - underNav - 80
      var pdfDiv = wh - nav;
      var wh = wh - nav - underNav;
      $("#order--dynamic--height").css("height", h);
      $(".side-attachments,.side-attachments-2").css("height", rsb);
      $(".invoice_pdf_canvas.invoice_pdf_new").css("height", wh);

      $(window).resize(function () {
        var vw = $(window).width();
        // if (vw > 1930) {
        var nav = $(".navbar.fixed-top").height();
        var underNav = $(".order-tabs").height();
        var wh = $(window).height();
        var h = wh - nav - 60;
        var rsb = wh - nav - underNav - 20;
        // var pdfDiv = wh - nav - underNav - 80
        var pdfDiv = wh - nav;
        var wh = wh - nav - underNav;
        $("#order--dynamic--height").css("height", h);
        $(".side-attachments,.side-attachments-2").css("height", rsb);
        $(".invoice_pdf_canvas.invoice_pdf_new").css("height", wh);
        // }
      });
      if ($(window).width() >= 2045) {
        $(".invoice_pdf_new").addClass("invoice_pdf_new1");
      }
      if ($(window).width() < 2045) {
        $(".invoice_pdf_new").addClass("invoice_pdf_new1");
      }
    });

    $(".cus-arro-div2").on("click", function (e) {
      e.preventDefault();
      $(".invoice_carousel").toggleClass("expand_invoice_img");
    });

    $(".cus-arro-div2").on("click", function (e) {
      e.preventDefault();
      $(".order_pdf_new").toggleClass("order_left_auto");
    });

    // sideBarAccord
    $(".invoice-inherit").click(function () {
      $(".invoice-inherit .sideBarAccord1 ").toggleClass("rotate_0");
    });
    $(".sideBarAccord").click(function () {
      $(this).toggleClass("rorate_0");
    });
    $(".sideBarAccord").dblclick(function () {
      $(this).toggleClass("rorate_0");
    });
    $(".sideBarAccord1").dblclick(function () {
      $(this).toggleClass("rorate_0");
    });
    $(".sideBarAccord2").dblclick(function () {
      $(this).toggleClass("rorate_0");
    });
    // end

    $(".icon_dots").click(function () {
      $(".documents_attatchments2").toggleClass("doc_sidebar2");
    });
    $(".cus-arro-div2").on("click", function (e) {
      e.preventDefault();
      $(".invoice_pdf_canvas").toggleClass("dicrease_invoice_pdf");
    });
    // end

    let {
      dashboard,
      tallType,
      tallies,
      addEditDocCheck,
      docTran,
      guid,
      activePage,
      edited
    } =
      (this.props.history &&
        this.props.history.location &&
        this.props.history.location.state) ||
      "";

    if (guid) {
      this.setState({ guid: guid, refreshedClicked:true, refreshedGuid:guid });
    }
    if (activePage) {
      this.setState({ activePage: activePage });
    }
    if (edited){
      this.setState({ edited });
    }
    if (dashboard && tallType) {
      //when user click on Document Tallies on Dashboard
      await this.getDocumentTallies(tallType, true); // get Document Tallies
    } else if (tallies && tallies === "Draft" && addEditDocCheck && docTran) {
      /*Check When Add/Edit Document and then user Save or Cancel that edit, 
      then load the same  Document user just edited/created?.*/
      await this.getDocumentTallies(getActivatedTab(), true);
    } else {
      await this.getDocumentTallies();
    }
  }

  componentWillUnmount() {
    if (this.state.resetPage) {
      this.props.resetLastPage("documents");
    }
  }

  //get document talleis
  getDocumentTallies = async (type, check) => {
    this.setState({ checked: false, multipleSelectedGuid:[], allSelected: false });
    //check -> when a user Perform some actions like send for approval, Approve, Declined etc then updated Document Tallies
    this.setState({ isLoading: true });
    if(type && !this.state.isSendForApproval ){
      this.setState({ page: 1 })
    }
    if(!type){
      if (this.state.activatedTab){
        type = this.state.activatedTab
      }
    }
    this.setState({  activatedTab: type })
    let isDocTallies = false; //to check if redux store contain Doc tallies then dont call API again
    let _docTallies = this.props.document.documentsTallies || [];

    if (_docTallies.length === 0 || check) {
      await this.props.getDocumentTallies(); //get Document Tallies
    } else {
      isDocTallies = true;
    }

    let docTally = "";
    let { activeDocTallis, showDocTallisTabPane } = this.state;
    let docTalliesArr = [];

    //success case of DOC tallies
    if (this.props.document.getDocumentsTalliesSuccess || isDocTallies) {
      let documentsTallies = this.props.document.documentsTallies || [];
      let docTypes = [];

      let userType = localStorage.getItem("userType");
      userType = userType ? userType.toLowerCase() : "";

      if (userType == "operator") {
        docTypes = ["draft", "pending", "declined", "approved", "all"];
      } else if (userType == "approver") {
        docTypes = [
          "approve",
          "hold",
          "pending",
          "declined",
          "approved",
          "all",
        ];
      } else if (userType == "op/approver") {
        docTypes = [
          "draft",
          "approve",
          "hold",
          "pending",
          "declined",
          "approved",
          "all",
        ];
      }

      if (docTypes.length > 0) {
        docTypes.map((t, i) => {
          let obj = documentsTallies.find(
            (tl) => tl.docState && tl.docState.toLowerCase() === t
          );
          if (obj) {
            docTalliesArr.push(obj);
          }
        });
      } else {
        docTalliesArr = documentsTallies;
      }

      let _type = "";
      if (type) {
        _type = type;
      } else if (docTalliesArr.length > 0) {
        _type = docTalliesArr[0].docState;
      }

      docTalliesArr.map(async (t, i) => {
        if (t.docState === _type) {
          let id = uuidv1();
          t.id = id;
          docTally = t;
          activeDocTallis = id;
          showDocTallisTabPane = t.docState;
        } else {
          t.id = uuidv1();
        }
        return t;
      });
    }
    //error case of DOC tallies
    if (this.props.document.getDocumentsTalliesError) {
      handleAPIErr(this.props.document.getDocumentsTalliesError, this.props);
    }

    this.setState({
      isLoading: false,
      documentsTallies: docTalliesArr,
      activeDocTallis,
      showDocTallisTabPane,
    });
    if (docTally) {
      //to call getDocumentsList baseed on first DOC tallies
      await this.checkDOCList_API(docTally); //docTally => draft || pending || approved || hold || all etc
    }
    this.props.clearDocumentStates();
  };

  //check getDocumentsList OR getNewDocumentsList API should be called
  checkDOCList_API = (data, check) => {
    let usePageLoading = localStorage.getItem("usePageLoading") || "N";
    usePageLoading = usePageLoading.toLocaleLowerCase();

    // determines if the getDocList (N) or getNewDocumentsList (Y) is used.

    // this.getDocumentsList(data, check);
    if (usePageLoading === "y") {
      this.getNewDocumentsList(data, check);
    } else {
      this.getDocumentsList(data, check);
    }
  };

  //getting the Documents list when click on Draft || Pending || Approved etc
  getDocumentsList = async (data, check) => {
    let { zero, close } = this.state;

    if (check === "tallies") {
      zero = false;
      close = false;
    }

    let activeDoc = "";
    let getDocList = [];
    let clonedgetDocList = [];
    let filteredDocList = [];

    this.clearStates();
    this.setState({
      isLoading: true,
      activeDocTallis: data.id,
      showDocTallisTabPane: data.docState,
      docListSearch: "",
      zero,
      close,
    });

    let teamDocCheck = this.state.viewTeam;
    if (teamDocCheck) {
      data.teamDocuments = teamDocCheck;
    }
    await this.props.getDocumentsList(data); // get DOC list

    let firstDoc = "";
    //success case of get DOC List
    if (this.props.document.getDocumentsListSuccess) {
      // toast.success(this.props.document.getDocumentsListSuccess);
      let _getDocList = this.props.document.getDocumentsList || [];

      let sortFilterDoc = this.state.sortFilterDoc || "";
      let sortFilterDocCheck = this.state.sortFilterDocCheck;
      _getDocList
        .sort((a, b) => {
          if (sortFilterDoc === "tran") {
            let valueA = Number(a[sortFilterDoc]);
            let valueB = Number(b[sortFilterDoc]);
            //for ascending order
            if (sortFilterDocCheck === "ASC") {
              if (valueA < valueB) {
                return -1;
              }
              if (valueA > valueB) {
                return 1;
              }
              return 0;
              // codes must be equal
            } else {
              //for descending order

              if (valueA > valueB) {
                return -1;
              }
              if (valueA < valueB) {
                return 1;
              }
              return 0;
              // codes must be equal
            }
          } else if (sortFilterDoc === "date") {
            let valueA = new Date(a.date);
            let valueB = new Date(b.date);

            //for ascending order
            if (sortFilterDocCheck === "ASC") {
              if (valueA < valueB) {
                return -1;
              }
              if (valueA > valueB) {
                return 1;
              }
              return 0;
              // codes must be equal
            } else {
              //for descending order

              if (valueA > valueB) {
                return -1;
              }
              if (valueA < valueB) {
                return 1;
              }
              return 0;
              // codes must be equal
            }
            // codes must be equal
          } else if (sortFilterDoc) {
            let valueA = a[sortFilterDoc].toString();
            let valueB = b[sortFilterDoc].toString();
            //for ascending order
            if (sortFilterDocCheck === "ASC") {
              if (valueA < valueB) {
                return -1;
              }
              if (valueA > valueB) {
                return 1;
              }
              return 0;
              // codes must be equal
            } else {
              //for descending order

              if (valueA > valueB) {
                return -1;
              }
              if (valueA < valueB) {
                return 1;
              }
              return 0;
              // codes must be equal
            }
          }
        })
        .map(async (doc, i) => {
          if (i == 0) {
            let id = uuidv1();
            doc.id = id;
            firstDoc = doc;
            doc.checked = false;
            activeDoc = id;
          } else {
            doc.id = uuidv1();
            doc.checked = false;
          }
          return doc;
        });
      getDocList = _getDocList;
      clonedgetDocList = _getDocList;
      filteredDocList = _getDocList;

      /*Check When  Add/Edit Document  and then user Save or Cancel that edit, 
      then load the same Document user just created/edited?.*/

      let { tallies, addEditDocCheck, docTran } =
        (this.props.history &&
          this.props.history.location &&
          this.props.history.location.state) ||
        "";

      if (tallies && tallies === "Draft" && addEditDocCheck && docTran) {
        let checkDoc = getDocList.find((l) => l.tran === docTran);
        if (checkDoc) {
          firstDoc = checkDoc;
          activeDoc = checkDoc.id;
        }
      }
    }
    //error case of get DOC List
    if (this.props.document.getDocumentsListError) {
      handleAPIErr(this.props.document.getDocumentsListError, this.props);
    }
    this.props.clearDocumentStates();
    this.setState({
      activeDoc,
      getDocList,
      clonedgetDocList,
      filteredDocList,
      isLoading: false,
    });
    if (firstDoc) {
      // to call get Document baseed on first Document in  list
      await this.getDocument(firstDoc, true);
    }
    this.props.clearDocumentStates();
    // scroll to active Document
    var elmnt = document.getElementById(this.state.activeDoc);
    if (elmnt) {
      elmnt.scrollIntoView();
    }
  };

  //getting the new documents list when usePageLoading = Y
  getNewDocumentsList = async (data, check) => {
    let activeDoc = "";
    let getDocList = [];
    let clonedgetDocList = [];
    let filteredDocList = [];
    let totalPages = "";
    let pageStart = "";
    let pageEnd = "";
    let totalDocuments = "";

    let {
      zero,
      close,
      page,
      display,
      order,
      sortFilterDoc,
      viewTeam,
      docListSearch,
    } = this.state;
    if (check === "tallies") {
      zero = false;
      close = false;
    }
    // if (check) {
    //   page = this.props.pageData?.pageNumber ?? 1;
    // }

    this.clearStates();
    this.setState({
      isLoading: true,
      activeDocTallis: data.id,
      showDocTallisTabPane: data.docState,
      zero,
      close,
      page,
    });
    let obj;
    let docType = this.state.activatedTab ? this.state.activatedTab : getActivatedTab();
    if (this.state.activePage) {
      obj = {
        docState: docType,
        displayRecords: display,
        order: order,
        sort: sortFilterDoc,
        search: docListSearch,
        teamDocuments: viewTeam,
      };
    } else {
      obj = {
        docState:docType,
        displayRecords: display,
        order: order,
        sort: sortFilterDoc,
        search: docListSearch,
        teamDocuments: viewTeam,
      };
    }

    let firstDoc = "";
    if(this.state.refreshedGuid && this.state.refreshedClicked){
      this.setState({ guid: this.state.guid})
    }else{
      this.setState({guid: null})
    }

    if (this.state.guid) {
      await this.props.getNewDocumentsList({ ...obj, guid: this.state.guid }); // get PO list
    } else {
      await this.props.getNewDocumentsList({ ...obj, page:  page }); // get PO list
    }
    //success case of Timecard List
    if (this.props.document.getNewDocumentsListSuccess) {
      // toast.success(this.props.document.getNewDocumentsListSuccess);
      let listData = this.props.document.getNewDocumentsList || "";
      getDocList = listData.documentsList || [];

      totalPages = listData.totalPages || "";
      pageStart = listData.pageStart || "";
      pageEnd = listData.pageEnd || "";
      totalDocuments = listData.totalDocuments || "";
      page = listData.page || "";

      if (this.state.activePage) {
        this.setState({ page: listData.page });
      }
      let searchPOTran = "";
      let guid;
      if(this.state.refreshedClicked){
        guid = this.state.selectedGuid;
      }else{
        guid = null;
      }
      getDocList.map(async (doc, i) => {
        if (i == 0 || doc.guid === guid ) {
          let id = uuidv1();
          doc.id = id;
          firstDoc = doc;
          doc.checked = false;
          activeDoc = id;
        } else {
          doc.id = uuidv1();
          doc.checked = false;
        }
        return doc;
      });

      clonedgetDocList = getDocList;
      filteredDocList = getDocList;

      // check if po comming from search page
      let checkDoc = getDocList.find((l) => l.trans === searchPOTran);
      if (checkDoc) {
        firstDoc = checkDoc;
        activeDoc = checkDoc.id;
      }


      /*Check When  Add/Edit Document  and then user Save or Cancel that edit, 
      then load the same Document user just created/edited?.*/

      let { tallies, addEditDocCheck, docTran } =
        (this.props.history &&
          this.props.history.location &&
          this.props.history.location.state) ||
        "";

      if (
        tallies &&
        tallies === "Draft" &&
        addEditDocCheck &&
        docTran &&
        this.state.guid
      ) {
        let checkDoc = getDocList.find((l) => l.tran === docTran);
        if (checkDoc) {
          firstDoc = checkDoc;
          activeDoc = checkDoc.id;
        }
      }
    }

    //error case of Timecard List
    if (this.props.document.getNewDocumentsListError) {
      handleAPIErr(this.props.document.getNewDocumentsListError, this.props);
    }

    await this.props.clearDocumentStates();
    this.setState({
      activeDoc,
      getDocList,
      clonedgetDocList,
      filteredDocList,
      totalPages,
      pageStart,
      pageEnd,
      totalDocuments,
      isLoading: false,
      guid: null,
      activePage: null,
      selectedGuid:this.state.guid
    });

    if (this.state.edited){
      this.setState({ page: this.props.location.state.page, edited: false})
    } else {
      this.setState({ page: page, edited: false})
    }
    if (firstDoc) {
      // to call get Document baseed on first Document in  list
      await this.getDocument(firstDoc, true);
    }
    this.props.clearDocumentStates();
    // scroll to active Document
    // var elmnt = document.getElementById(this.state.activeDoc);
    // if (elmnt) {
    //   elmnt.scrollIntoView();
    // }
  };

  /**
   * Gets the previewable documents
   * @param {Array.<object>} documentAttachments 
   * @param {boolean} useContentType
   * @returns {Array.<object>}
   */
  getPreviewableDocuments = (documentAttachments, useContentType = false) => {
    return documentAttachments.filter(
      (documentAttachment) => {
        const isPreview = documentAttachment?.preview === 1;
        const isPdf = documentAttachment?.contentType === 'application/pdf';
        return isPreview || (useContentType && isPdf);
      }
    );
  }

  //getting the single Document
  getDocument = async (doc, check) => {
    if (this.state.activeDoc != doc.id || check) {
      this.setState({
        isLoading: true,
        activeDoc: doc.id,
        tran: "",
        rotate: 0,
        previews: [],
        currentAttachmentIndex: 0,
        numPages: null,
        pageNumber: 1,
        numPagesArr: [], //it contains number of pages of each PDF
        docComments: [],
        docAttachments: [],
        previewableAttachments: [],
        attachmentSize: 0,
        docActivity: [],
        docDate: "",
        docDateUnix: "",
        docDescription: "",
        guid:doc.guid,
        selectedGuid: doc.guid
      });
      if (this.state.refreshedClicked) {
        await this.props.getDocumentSummary(this.state.refreshedGuid);
      }else{
        await this.props.getDocumentSummary(doc.guid); // get document
      }
      //success case of get document
      if (this.props.document.getDocumentSuccess) {
        let docDetails =
          (this.props.document.getDocument &&
            this.props.document.getDocument &&
            JSON.parse(JSON.stringify(this.props.document.getDocument))) ||
          "";
        this.setState({refreshedClicked:false})
        let approverGroup = docDetails.approverGroup || "";

        let approvalsGroups =
          (docDetails &&
            JSON.parse(JSON.stringify(docDetails.approvalOptions))) ||
          [];
        approvalsGroups.map((a, i) => {
          a.checked = false;
          a.id = uuidv1();
          return a;
        });

        let tran = (docDetails && docDetails.tran) || "";

        let approvers = (docDetails && docDetails.approvers) || [];

        let previews =
          (this.props.document &&
            this.props.document.getDocument &&
            this.props.document.getDocument.previews) ||
          [];

        //to show primary PDF first
        previews = previews.sort((a, b) =>
          a.primaryDoc.toLowerCase() < b.primaryDoc.toLowerCase() ? 1 : -1
        );


        if (isRemoteStorage()) {
          previews = await Promise.all(
            previews.map(async (p) => {
              p.file = await getBase64FromPreSignedS3Url(
                p.documentPresignedURL
              );
              return p;
            })
          );
        }

        let docAttachments = (docDetails && docDetails.attachments) || [];
        const previewableAttachments = this.getPreviewableDocuments(docAttachments);
        let attachmentSize = 0;
        docAttachments.map((a, i) => {
          attachmentSize += Number(a.fileSize) || 0;
        });

        let docComments = (docDetails && docDetails.comments) || [];
        let docDescription = (docDetails && docDetails.docDescription) || "";
        let docDate = (docDetails && docDetails.docDate) || "";
        let docDateUnix = doc.docDateUnix ?? "";

        let docActivity = (docDetails && docDetails.activity) || [];

        this.setState({
          approverGroup: {
            label: approverGroup ? approverGroup : "Select Approver Group",
            value: approverGroup ? approverGroup : "",
          },
          tran,
          approvalsGroups,
          docAttachments,
          previewableAttachments,
          attachmentSize,
          docComments,
          approvers,
          previews,
          docActivity,
          docDescription,
          docDate,
          docDateUnix,
        });
      }
      //error case of get document
      if (this.props.document.getDocumentError) {
        handleAPIErr(this.props.document.getDocumentError, this.props);
      }
      this.props.clearDocumentStates();
      this.setState({ isLoading: false });
    }
  };

  // opening Docment Moree details Modal heree
  handleDocDetails = async () => {
    this.openModal("openDocumentMoreModal");
  };

  //call getDocumentsList API
  toggleTeamIcon = (check) => {
    localStorage.setItem("teamDocuments", check);
    this.setState({ viewTeam: check }, () => {
      let { activeDocTallis, showDocTallisTabPane } = this.state;
      let obj = {
        id: activeDocTallis,
        docState: showDocTallisTabPane,
      };
      this.checkDOCList_API(obj);
    });
  };

  handleRowsSetting = (e) => {
    let { activeDocTallis, showDocTallisTabPane } = this.state;
    let obj = {
      id: activeDocTallis,
      docState: showDocTallisTabPane,
    };
    localStorage.setItem("orderDPR", e.target.value); // no of display order pages
    this.setState({ display: e.target.value }, () => {
      this.getNewDocumentsList(obj);
    });
    this.closeModal();
  };

  handlePageClick = ({ selected }) => {
    this.setState({ guid: null, activePage: null });
    let { activeDocTallis, showDocTallisTabPane } = this.state;

    let obj = {
      id: activeDocTallis,
      docState: showDocTallisTabPane,
    };

    this.setState(
      { page: selected + 1, pageStart: "", pageEnd: "", totalDocuments: "" },
      () => {
        this.savePageState();
        this.getNewDocumentsList(obj);
      }
    );
  };

  savePageState = () => {
    const { page, docListSearch } = this.state;
    this.props.setLastPage("documents", {
      pageNumber: page,
      searchTerm: docListSearch,
    });
  };

  //when a user searches on Documents list
  handleChangeDocListSearch = (e) => {
    let searchedText = e.target.value;
    this.setState({ docListSearch: searchedText }, () => {
      this.savePageState();
      const filteredDocList = JSON.parse(
        JSON.stringify(this.state.filteredDocList)
      );
      if (!searchedText) {
        this.setState({ getDocList: filteredDocList }, () => {
          // scroll to active Document
          var elmnt = document.getElementById(this.state.activeDoc);
          if (elmnt) {
            elmnt.scrollIntoView();
          }
          // let sortFilterDocCheck = this.state.sortFilterDocCheck;

          // if (sortFilterDocCheck === "ASC") {
          //   sortFilterDocCheck = "DESC";
          // } else {
          //   sortFilterDocCheck = "ASC";
          // }
          // await this.setState({ sortFilterDocCheck });

          // await this.handleSortDocumentsList(this.state.sortFilterDoc);
        });
      }
    });
  };

  handleChangeNewDocListSearch = (e) => {
    let searchedText = e.target.value;
    this.setState({ docListSearch: searchedText }, () => {
      this.savePageState();
      if (!searchedText) {
        let { activeDocTallis, showDocTallisTabPane } = this.state;
        let obj = {
          id: activeDocTallis,
          docState: showDocTallisTabPane,
        };
        this.getNewDocumentsList(obj);
      }
    });
  };

  onDocListSearch = async (e) => {
    if (e.key === "Enter" || e.key === "Tab") {
      let docListSearch = this.state.docListSearch.trim();

      if (docListSearch) {
        const filteredDocList = JSON.parse(
          JSON.stringify(this.state.filteredDocList)
        );

        let docListFilterdData = [];
        docListFilterdData = filteredDocList.filter((c) => {
          return (
            c.type.toUpperCase().includes(docListSearch.toUpperCase()) ||
            c.description.toUpperCase().includes(docListSearch.toUpperCase())
          );
        });
        this.setState({ getDocList: docListFilterdData });
      }
    }
  };

  onNewDocListSearch = (e) => {
    if (e.key === "Enter" || e.key === "Tab") {
      let searchedText = e.target.value;
      let usePageLoading = localStorage.getItem("usePageLoading") || "N";
      usePageLoading = usePageLoading.toLocaleLowerCase();
      if (usePageLoading === "y") {
        this.setState(
          {
            docListSearch: searchedText,
            page: 1,
          },
          () => {
            this.savePageState();
            let { activeDocTallis, showDocTallisTabPane } = this.state;

            let obj = {
              id: activeDocTallis,
              docState: showDocTallisTabPane,
            };
            this.getNewDocumentsList(obj);
          }
        );
      }
    }
  };

  //Draft-> Add
  addDocument = async () => {
    this.props.history.push("/documents-form", {
      tran: "addNewdocument",
      activePage: this.state.page,
    });
  };

  //Draft-> Edit
  updateDocument = async () => {
    this.setState({ ...this.state, resetPage: false }, () => {
      this.props.history.push("/documents-form", {
        tran: this.state.tran,
        selectedGuid: this.state.selectedGuid,
        page: this.state.page
      });
    });
  };

  //adding comment to the document
  addComment = async (comment, params) => {
    let { tran, selectedGuid } = this.state;
    if (selectedGuid) {
      if (comment) {
        this.setState({ isLoading: true });
        let data = {
          comment,
          ...params,
          guid: selectedGuid,
        };
        await this.props.addComment(data);
        //Success Case Of Adding Comment
        if (this.props.document.addDocCommentSuccess) {
          // toast.success(this.props.document.addDocCommentSuccess);
          let docComments = this.props.document.addDocComments || [];
          this.setState({ docComments });
        }
        //Error Case Of Adding Comment
        if (this.props.document.addDocCommentError) {
          handleAPIErr(this.props.document.addDocCommentError, this.props);
        }
        this.props.clearDocumentStates();
        this.setState({ isLoading: false });
      } else {
        toast.error("Please Enter Comment!");
      }
    } else {
      toast.error("Please select Document First!");
    }
  };

  //Delete Document
  deleteDocument = async () => {
    let { tran, multipleTrans, selectedGuid } = this.state;

    let deletableTran = null;

    if (multipleTrans.length > 1) {
      toast.error("Only One Document can be Deleted at a Time!");
    } else if (multipleTrans.length === 1) {
      deletableTran = multipleTrans[0];
    } else {
      deletableTran = tran;
    }

    if (selectedGuid) {
      this.setState({
        isLoading: true,
      });

      await this.props.deleteDocument(selectedGuid); // delete document
      //success case of delete document
      if (this.props.document.deleteDocumentSuccess) {
        // toast.success(this.props.document.deleteDocumentSuccess);
        await this.getDocumentTallies(this.state.showDocTallisTabPane, true); //to refresh the list
      }
      //error case of delete document
      if (this.props.document.deleteDocumentError) {
        handleAPIErr(this.props.document.deleteDocumentError, this.props);
      }
      this.setState({ isLoading: false });

      this.props.clearDocumentStates();
    }
  };

  //sendDocForApproval =>Draft -> send
  sendDocForApproval = async () => {
    let {  multipleTrans, docAttachments,  multipleSelectedGuid , selectedGuid} = this.state;

    // check if the document has any attachments, if not abort sending for approval
    if (docAttachments.length === 0) {
      toast.error("A document must be attached before sending for approval.");
      return;
    }

    let _trans = "";
    if (multipleTrans.length > 0) {
      _trans = multipleSelectedGuid;
    }else{
      _trans = [selectedGuid];
    }

    if (_trans) {
      this.setState({
        isLoading: true,
      });

      if (this.state.allSelected) {
        await this.props.sendDocForApproval({
          selectAll: "Y",
          documentType: this.state.showDocTallisTabPane,
          search: this.state.docListSearch,
          teamDocuments: this.state.teamDocCheck,
        });
      } else {
        await this.props.sendDocForApproval({ guid: _trans }); // send Doc For Approval
      }

      //success case of send Doc For Approval
      if (this.props.document.sendDocForApprovalSuccess) {
        toast.success(this.props.document.sendDocForApprovalSuccess);
        await this.getDocumentTallies(this.state.showDocTallisTabPane, true); //to refresh the list
      }
      //error case of send Doc For Approval
      if (this.props.document.sendDocForApprovalError) {
        handleAPIErr(this.props.document.sendDocForApprovalError, this.props);
      }
      this.setState({ isLoading: false });

      this.props.clearDocumentStates();
    } else {
      toast.error("Please select Document First!");
    }
  };

  // Approve Document => Approve -> Approve
  approveDocument = async () => {
    let { multipleSelectedGuid , selectedGuid} = this.state;
    if ( selectedGuid || (multipleSelectedGuid && multipleSelectedGuid.length > 0)) {
      let _trans = "";
      if (multipleSelectedGuid.length > 0) {
        _trans = multipleSelectedGuid;
      }else {
        _trans = [selectedGuid];
      }
      this.setState({
        isLoading: true,
      });

      if (this.state.allSelected) {
        await this.props.approveDocument({
          selectAll: "Y",
          documentType: this.state.showDocTallisTabPane,
          search: this.state.docListSearch,
          teamDocuments: this.state.teamDocCheck,
        });
      } else {
        await this.props.approveDocument({ guid: _trans }); // approve document
      }

      //success case of approve document
      if (this.props.document.approveDocSuccess) {
        toast.success(this.props.document.approveDocSuccess);
        await this.getDocumentTallies(this.state.showDocTallisTabPane, true); //to refresh the list
      }
      //error case of approve document
      if (this.props.document.approveDocError) {
        handleAPIErr(this.props.document.approveDocError, this.props);
      }
      this.setState({ isLoading: false });

      this.props.clearDocumentStates();
    } else {
      toast.error("Please select Document First!");
    }
  };

  // Approve Document => Approve -> Decline
  declineDocument = async (declineReason) => {
    let { tran, selectedGuid } = this.state;
    if (selectedGuid) {
      this.setState({
        isLoading: true,
      });

      await this.props.declineDocument(selectedGuid, declineReason); // decline document
      //success case of decline document
      if (this.props.document.declineDocSuccess) {
        toast.success(this.props.document.declineDocSuccess);
        await this.getDocumentTallies(this.state.showDocTallisTabPane, true); //to refresh the list
      }
      //error case of decline document
      if (this.props.document.declineDocError) {
        handleAPIErr(this.props.document.declineDocError, this.props);
      }
      this.setState({ isLoading: false });

      this.props.clearDocumentStates();
    } else {
      toast.error("Please select Document First!");
    }
  };

  //Hold Document
  holdDocument = async () => {
    let { multipleSelectedGuid, selectedGuid } = this.state;
    if (selectedGuid || (multipleSelectedGuid && multipleSelectedGuid.length > 0)) {
      let _trans = "";
      if (multipleSelectedGuid.length > 0) {
        _trans = multipleSelectedGuid;
      }else {
        _trans = [selectedGuid];
      }

      this.setState({
        isLoading: true,
      });

      if (this.state.allSelected) {
        await this.props.holdDocument({
          selectAll: "Y",
          documentType: this.state.showDocTallisTabPane,
          search: this.state.docListSearch,
          teamDocuments: this.state.teamDocCheck,
        });
      } else {
        await this.props.holdDocument({ guid: _trans}); // hold document
      }

      //success case of hold document
      if (this.props.document.holdDocumentSuccess) {
        toast.success(this.props.document.holdDocumentSuccess);
        await this.getDocumentTallies(this.state.showDocTallisTabPane, true); //to refresh the list
      }
      //error case of hold document
      if (this.props.document.holdDocumentError) {
        handleAPIErr(this.props.document.holdDocumentError, this.props);
      }
      this.setState({ isLoading: false });

      this.props.clearDocumentStates();
    } else {
      toast.error("Please Select Document First!");
    }
  };

  resendEmail = async () => {
    this.setState({ isLoading: true });

    let { selectedGuid, multipleSelectedGuid } = this.state;
    await this.props.resendEmail( multipleSelectedGuid.length > 0 ? multipleSelectedGuid : selectedGuid);
    if (this.props.document.resendEmailSuccess) {
      toast.success(this.props.document.resendEmailSuccess);
    }

    if (this.props.document.resendEmailError) {
      handleAPIErr(this.props.document.resendEmailError, this.props);
    }
    this.setState({ isLoading: false });
  };
  regenerateDocumentsSignature = async () => {
    let {  multipleSelectedGuid, selectedGuid } = this.state;
    this.setState({
      isLoading: true,
    });
    const guid = multipleSelectedGuid.length > 0 ? multipleSelectedGuid : selectedGuid;
    await this.props.regenerateDocumentsSignature(guid);
    if (this.props.document.regenerateDocumentsSignatureSuccess) {
      toast.success(this.props.document.regenerateDocumentsSignatureSuccess);
    }
    if (this.props.document.regenerateDocumentsSignatureError) {
      handleAPIErr(
        this.props.document.regenerateDocumentsSignatureError,
        this.props
      );
    }
    this.setState({
      isLoading: false,
    });
  };

  closeConfrimationModal = () => {
    this.setState({ isModalOpen: false });
  };

  openModalWithContent = (title, body, actionButtonName, onConfirm) => {
    this.setState({
      modalContent: { title, body, actionButtonName, onConfirm },
      isModalOpen: true,
    });
  };

  getMoveRecord = () => {
    let { multipleSelectedGuid } = this.state;
    return multipleSelectedGuid.length === 0 ? 1 : multipleSelectedGuid.length
  }

  onClickMoveDocument= () => {
    this.openModalWithContent('Move', `This selected ${this.getMoveRecord()} Documents will be moved to your drafts folder to modify and resend for approval.`, 'Save', this.moveDocument)
  }

  //Move Document
  moveDocument = async () => {
    let { multipleSelectedGuid, selectedGuid  } = this.state;
    if ( selectedGuid || (multipleSelectedGuid && multipleSelectedGuid.length > 0)) {
      let _trans = "";
      if (multipleSelectedGuid.length > 0) {
        _trans = multipleSelectedGuid;
      }else {
        _trans = [selectedGuid];
      }
      this.setState({
        isLoading: true,
      });

      if (this.state.allSelected) {
        await this.props.moveDocument({
          selectAll: "Y",
          documentType: this.state.showDocTallisTabPane,
          search: this.state.docListSearch,
          teamDocuments: this.state.teamDocCheck,
        });
      } else {
        await this.props.moveDocument({ guid: _trans }); // move document
      }

      //success case of move document
      if (this.props.document.moveDocumentSuccess) {
        toast.success(this.props.document.moveDocumentSuccess);
        await this.getDocumentTallies(this.state.showDocTallisTabPane, true); //to refresh the list
      }
      //error case of move document
      if (this.props.document.moveDocumentError) {
        handleAPIErr(this.props.document.moveDocumentError, this.props);
      }
      this.setState({ isLoading: false });

      this.props.clearDocumentStates();
    } else {
      toast.error("Please Select Document First!");
    }
  };

  //moving to next tab
  moveToNextApprover = async (comment, email) => {
    this.setState({ isLoading: true });
    let { tran, selectedGuid } = this.state;
    let data = {
      guid: selectedGuid,
      comment: comment,
      email,
    };
    if (selectedGuid) {
      await this.props.moveToNextApprover(data);
      if (this.props.document.moveToNextApproverSuccess) {
        toast.success(
          this.props.document.moveToNextApproverSuccess[0]?.description
        );
        toast.success(
          this.props.document.moveToNextApproverSuccess[1]?.description
        );
        this.getDocumentTallies(this.state.showDocTallisTabPane, true);
      }
      if (this.props.document.moveToNextApproverError) {
        handleAPIErr(this.props.document.moveToNextApproverError, this.props);
      }
      this.setState({ isLoading: false });
    }
  };

  //moving to previous tab
  moveToPrevApprover = async (comment, email) => {
    this.setState({ isLoading: true });
    let { tran, selectedGuid } = this.state;
    let data = {
      guid: selectedGuid,
      comment: comment,
      email,
    };
    if (selectedGuid) {
      await this.props.moveToPrevApprover(data);
      if (this.props.document.moveToPrevApproverSuccess) {
        toast.success(
          this.props.document.moveToPrevApproverSuccess[0]?.description
        );
        toast.success(
          this.props.document.moveToPrevApproverSuccess[1]?.description
        );
        this.getDocumentTallies(this.state.showDocTallisTabPane, true);
      }
      if (this.props.document.moveToPrevApproverError) {
        handleAPIErr(this.props.document.moveToPrevApproverError, this.props);
      }
      this.setState({ isLoading: false });
    }
  };

  //add Document Attachments
  addAttachment = async (fileList) => {
    let { tran, selectedGuid } = this.state;

    if (selectedGuid) {
      this.setState({ isLoading: true });
      let obj = {
        guid: selectedGuid,
        attachment: fileList[0].attachment,
        fileName: fileList[0].fileName,
        primaryAttachment: "N",
        documentGUID:""
      };

      if (isRemoteStorage()) {
        await uploadFileListToS3(fileList, async (uploadedFiles) => {
          obj = {
            guid: selectedGuid,
            documentGUID: uploadedFiles[0].documentGuid,
            fileName: uploadedFiles[0].fileName,
            primaryAttachment: "Y",
          };
          await this.props.addDocAttachments(obj);
          if (this.props.document.addDocAttachmentSuccess) {
            // toast.success(this.props.document.addDocAttachmentSuccess);
            let docAttachments = this.props.document.addDocAttachment || [];
            const previewableAttachments = this.getPreviewableDocuments(docAttachments);

            let attachmentSize = 0;
            docAttachments.map((a, i) => {
              attachmentSize += Number(a.fileSize) || 0;
            });

            this.setState({ docAttachments, previewableAttachments, attachmentSize });
          }
          if (this.props.document.addDocAttachmentError) {
            handleAPIErr(
              this.props.document.addDocAttachmentError,
              this.props
            );
          }
          this.props.clearDocumentStates();

        });
          this.setState({ isLoading: false });
      } else {
        await this.props.addDocAttachments(obj);
        if (this.props.document.addDocAttachmentSuccess) {
          // toast.success(this.props.document.addDocAttachmentSuccess);
          let docAttachments = this.props.document.addDocAttachment || [];
          const previewableAttachments = this.getPreviewableDocuments(docAttachments);

          let attachmentSize = 0;
          docAttachments.map((a, i) => {
            attachmentSize += Number(a.fileSize) || 0;
          });

          this.setState({ docAttachments, previewableAttachments, attachmentSize });
        }
        if (this.props.document.addDocAttachmentError) {
          handleAPIErr(this.props.document.addDocAttachmentError, this.props);
        }
        this.props.clearDocumentStates();

        this.setState({ isLoading: false });
      }
    }
  };

  //Get Document Attachment
  getAttachment = async (fileID, type, fileName) => {
    if (fileID) {
      this.setState({ isLoading: true });

      await this.props.getAttachment(fileID);
      if (this.props.document.getDocAttachmentSuccess) {
        // toast.success(this.props.document.getDocAttachmentSuccess);
        let resp = this.props.document.getDocAttachment;
         if (resp.documentPresignedURL) {
           downloadFileFromPresignedUrl(resp.documentPresignedURL);
         } else {
           downloadAttachments(resp, fileName);
         }
      }
      if (this.props.document.getDocAttachmentError) {
        handleAPIErr(this.props.document.getDocAttachmentError, this.props);
      }
      this.props.clearDocumentStates();
      this.setState({ isLoading: false });
    }
  };

  downloadSelectedAttachments = async (e) => {

    e.preventDefault();
    e.stopPropagation();

    this.setState({ isLoading: true });

    let params = {};

    if (this.state.allSelected) {
      params = {
        selectAll: "Y",
        docState: this.state.showDocTallisTabPane,
        search: this.state.docListSearch,
        teamDocs: this.state.viewTeam,
      };

    } else {
      params = {
        guid: this.state.multipleSelectedGuid.length > 0
          ? this.state.multipleSelectedGuid
          : [this.state.selectedGuid],
      };
    }

    await this.props.downloadAttachments(params);

    if (this.props.document?.downloadDocAttachmentSuccess) {
      let linkSource;
      if (localStorage.getItem('storageType') === "remote"){
        linkSource = this.props.document.downloadDocAttachment.documentPresignedURL;
      }else {
        linkSource = `data:text/plain;base64,${this.props.document.downloadDocAttachment.attachment}`;
      }
      const downloadLink = document.createElement("a");
      const fileName = "attachment.zip";
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    } else if (this.props.document.downloadDocAttachmentError) {
      toast.error(this.props.document.downloadDocAttachmentError);
    } else {
      toast.error("Failed to download Document attachments");
    }
    this.setState({ isLoading: false });
  }

  //approvals filter
  handleApprovalsFilters = (e, obj) => {
    let checked = e.target.checked;
    obj.checked = checked;

    let approvalsGroups = this.state.approvalsGroups;
    let foundIndex = approvalsGroups.findIndex((a) => a.id == obj.id);
    approvalsGroups[foundIndex] = obj;

    this.setState({
      approvalsGroups,
    });
    let check = false;
    let count = 0;
    approvalsGroups.map((a, i) => {
      if (!a.checked) {
        count += 1;
      }
    });
    if (approvalsGroups.length === count) {
      check = true;
    }
    const clonedgetDocList = JSON.parse(
      JSON.stringify(this.state.clonedgetDocList)
    );

    if (check) {
      //all checkboxes are uncheck
      this.setState(
        {
          getDocList: clonedgetDocList,
          filteredDocList: clonedgetDocList,
        },
        () => this.handleSortDocumentsList(this.state.sortFilterDoc)
      );
    } else {
      let filterdData = [];

      approvalsGroups.map((a, i) => {
        let docListFilterdData = [];
        if (a.checked) {
          docListFilterdData = clonedgetDocList.filter((c) => {
            return (
              c.approvalGroup &&
              c.approvalGroup.toUpperCase() === a.approvalGroup.toUpperCase()
            );
          });
        }
        filterdData.push(...docListFilterdData);
      });

      this.setState(
        {
          getDocList: filterdData,
          filteredDocList: filterdData,
        },
        () => this.handleSortDocumentsList(this.state.sortFilterDoc)
      );
    }
  };
  //download preview
  downloadPreview = () => {
    if (this.state.previews.length > 0) {
      const primaryDocument = this.state.previews?.filter(item => item.primaryDoc === "Y");
      const docId = this.state.docAttachments.find(
        (item) => item.primaryDoc === "Y"
      )?.fileID;

      if (primaryDocument.length > 0){
        if(primaryDocument[0].documentPresignedURL ){
          // still not implement in Main
        }else{
          if (primaryDocument[0].file){
            const linkSource = `data:application/pdf;base64,${primaryDocument[0]?.file}`;
            const downloadLink = document.createElement("a");
            downloadLink.href = linkSource;
            downloadLink.download = docId ?? "Documents.pdf";
            downloadLink.click();
          }else{
            toast.error('No Primary Document available for download.')
          }
        }
      }else {
        toast.error('No Primary Document available for download.');
      }
    }else{
      toast.error('No Primary Document available for download.');
    }
  };

  updateDocumentSummaryState = async () => {
    if (this.props.document.updateApprovalGroupSuccess) {
      toast.success(this.props.document.updateApprovalGroupSuccess);
    }
    if (this.props.document.updateApprovalGroupError) {
      handleAPIErr(this.props.document.updateApprovalGroupError, this.props);
    }
  };
  //sorting on documents list
  handleSortDocumentsList = async (name) => {
    let usePageLoading = localStorage.getItem("usePageLoading") || "N";
    let order = "Descend";
    usePageLoading = usePageLoading.toLocaleLowerCase();
    if (usePageLoading === "y") {
      if (this.state.sortFilterDoc === name) {
        if (this.state.order === "Descend") {
          order = "Ascend ";
        }
        if (this.state.order === "Ascend ") {
          order = "Descend";
        }
      }
      this.setState(
        {
          sortFilterDoc: name,
          order,
        },
        () => {
          let { activeDocTallis, showDocTallisTabPane, sortFilterDocCheck } =
            this.state;

          let obj = {
            id: activeDocTallis,
            docState: showDocTallisTabPane,
          };
          localStorage.setItem("sortFilterDoc", name);
          localStorage.setItem("sortFilterDocCheck", sortFilterDocCheck);

          this.getNewDocumentsList(obj);
        }
      );
    } else {
      let { sortFilterDocCheck } = this.state;
      if (this.state.sortFilterDoc != name) {
        sortFilterDocCheck = "DESC";
      }

      if (sortFilterDocCheck === "DESC") {
        sortFilterDocCheck = "ASC";
      } else {
        sortFilterDocCheck = "DESC";
      }

      localStorage.setItem("sortFilterDoc", name);
      localStorage.setItem("sortFilterDocCheck", sortFilterDocCheck);

      const filteredDocList = JSON.parse(
        JSON.stringify(this.state.filteredDocList)
      );
      let docListFilterdData = [];
      if (name === "tran") {
        docListFilterdData = filteredDocList.sort(function (a, b) {
          let valueA = Number(a[name]);
          let valueB = Number(b[name]);
          //for ascending order
          if (sortFilterDocCheck === "ASC") {
            if (valueA < valueB) {
              return -1;
            }
            if (valueA > valueB) {
              return 1;
            }
            return 0;
            // codes must be equal
          } else {
            //for descending order

            if (valueA > valueB) {
              return -1;
            }
            if (valueA < valueB) {
              return 1;
            }
            return 0;
            // codes must be equal
          }
        });
      } else if (name === "date") {
        docListFilterdData = filteredDocList.sort(function (a, b) {
          let valueA = new Date(a.date);
          let valueB = new Date(b.date);

          //for ascending order
          if (sortFilterDocCheck === "ASC") {
            if (valueA < valueB) {
              return -1;
            }
            if (valueA > valueB) {
              return 1;
            }
            return 0;
            // codes must be equal
          } else {
            //for descending order

            if (valueA > valueB) {
              return -1;
            }
            if (valueA < valueB) {
              return 1;
            }
            return 0;
            // codes must be equal
          }
          // codes must be equal
        });
      } else if (name) {
        docListFilterdData = filteredDocList.sort(function (a, b) {
          let valueA = a[name].toString().toUpperCase();
          let valueB = b[name].toString().toUpperCase();
          //for ascending order
          if (sortFilterDocCheck === "ASC") {
            if (valueA < valueB) {
              return -1;
            }
            if (valueA > valueB) {
              return 1;
            }
            return 0;
            // codes must be equal
          } else {
            //for descending order

            if (valueA > valueB) {
              return -1;
            }
            if (valueA < valueB) {
              return 1;
            }
            return 0;
            // codes must be equal
          }
        });
      }

      this.setState({
        getDocList: docListFilterdData,
        sortFilterDoc: name,
        sortFilterDocCheck,
      });
    }
  };

  // move to previous Doc
  moveToPrevDOC = async () => {
    let { getDocList, activeDoc } = this.state;
    let foundIndex = getDocList.findIndex((l) => l.id === activeDoc);

    if (foundIndex != -1 && foundIndex != 0) {
      let doc = getDocList[foundIndex - 1];
      if (doc) {
        await this.getDocument(doc);
      }
    }
  };

  // move to next Doc
  moveToNextDOC = async () => {
    let { getDocList, activeDoc } = this.state;
    let foundIndex = getDocList.findIndex((l) => l.id === activeDoc);

    if (foundIndex != -1) {
      let doc = getDocList[foundIndex + 1];
      if (doc) {
        await this.getDocument(doc);
      }
    }
  };

  onTabPane = (tab) => {
    this.setState({ showDocTallisTabPane: tab });
  };

  openModal = (name, type) => {
    this.setState({ [name]: true });
    if (type) {
      this.setState({ typeMove: type });
    }
  };

  closeModal = async (name) => {
    this.setState({ [name]: false });
  };

  clearStates = async () => {
    this.setState({
      isLoading: false,
      tran: "", //tran of current selected Docmunet
      multipleTrans: [], //when user selects multiple Documents to perform different functionality

      // docListSearch: "", //search on DOC list
      getDocList: [], //side menu (left side) DOC list data
      clonedgetDocList: [], //a copy of  getDocList
      activeDoc: "", //to add class active in lists of getting DOC (in left side )
      activeDocTallis: "", //to add class active on DOC tallis
      showDocTallisTabPane: "", //to add class active on DOC tallis below tab pane
      filteredDocList: [], //this contains filterd list and used for searching on it
      docState: "", //draft, pending, declined, all etc
      openAttachmentsModal: false,
      openCommentsModal: false,
      openDeleteModal: false,
      openActivityModal: false,
      approverGroup: "",
      approvalsGroups: [],
      approvers: [], //to just show on side menuw bar
      previews: [],
      numPages: null,
      pageNumber: 1,
      numPagesArr: [], //it contains number of pages of each PDF
      docComments: [],
      docAttachments: [],
      previewableAttachments: [],
      docActivity: [],
      currentAttachmentIndex: 0,
    });
  };

  handleCheckbox = async (e, data) => {
    this.setState({ checked: !this.state.checked });
    let { getDocList, multipleTrans, multipleSelectedGuid } = this.state;
    let { name, checked } = e.target;
    let allSelected = false;

    if (data === "allCheck" && name === "checkboxAll") {
      let multipleTransCopy = [];
      let multipleGuidCopy = [];
      if (checked) {
        allSelected = true;
        getDocList.map((m) => {
          m.checked = true;
          multipleTransCopy.push(m.tran);
          multipleGuidCopy.push(m.guid);
          return m;
        });
      } else {
        allSelected = false;
        getDocList.map((m) => {
          m.checked = false;
          return m;
        });
      }
      multipleTrans = [...multipleTransCopy];
      multipleSelectedGuid = [...multipleGuidCopy];
    } else {
      if (checked) {
        getDocList.map(async (doc, i) => {
          if (data.id === doc.id) {
            doc.checked = true;
          }
          return doc;
        });
        multipleTrans.push(data.tran);
        multipleSelectedGuid.push(data.guid);
      } else {
        getDocList.map(async (doc, i) => {
          if (data.id === doc.id) {
            doc.checked = false;
          }
          return doc;
        });
        let filteredMultiTrans = multipleTrans.filter((t) => t != data.tran);
        multipleTrans = filteredMultiTrans;
        let filteredMultiGuid = multipleSelectedGuid.filter(
          (t) => t != data.guid
        );
        multipleTrans = filteredMultiTrans;
        multipleSelectedGuid = filteredMultiGuid;
      }
    }
    this.setState({
      getDocList,
      multipleTrans,
      allSelected,
      multipleSelectedGuid,
    });
  };

  zoomIn = async () => {
    $(".invoice_pdf_new").removeClass("invoice_carousel_pdf");
    $(".invoice_pdf_new").removeClass("full_screen_convas");

    let { scaling } = this.state;

    let { scale, dropdownZoomingValue } = zoomIn(scaling);

    this.setState(
      {
        scaling: scale,
        dropdownZoomingValue,
      },
      () => {
        if (
          scaling == 2.5 ||
          scaling == 2.2 ||
          scaling == 1.9 ||
          scaling == 1.6 ||
          scaling == 1.3
        ) {
          if ($(window).width() >= 2045) {
            $(".invoice_pdf_new").addClass("invoice_pdf_new1");
          }
        } else {
          if ($(window).width() < 2045) {
            $(".invoice_pdf_new").removeClass("invoice_pdf_new1");
          }
        }
      }
    );
  };

  zoomOut = async () => {
    $(".invoice_pdf_new").removeClass("invoice_carousel_pdf");
    $(".invoice_pdf_new").removeClass("full_screen_convas");

    let { scaling } = this.state;
    let { scale, dropdownZoomingValue } = zoomOut(scaling);

    this.setState(
      {
        scaling: scale,
        dropdownZoomingValue,
      },
      () => {
        if (
          scaling == 2.5 ||
          scaling == 2.2 ||
          scaling == 1.9 ||
          scaling == 1.6 ||
          scaling == 1.3
        ) {
          if ($(window).width() >= 2045) {
            $(".invoice_pdf_new").addClass("invoice_pdf_new1");
          }
        } else {
          if ($(window).width() < 2045) {
            $(".invoice_pdf_new").removeClass("invoice_pdf_new1");
          }
        }
      }
    );
  };

  handleDropdownZooming = async (data) => {
    $(".invoice_pdf_new").removeClass("invoice_carousel_pdf");
    $(".invoice_pdf_new").removeClass("full_screen_convas");
    let value = data.value;

    let { scale, dropdownZoomingValue } = handleDropdownZooming(value);

    this.setState(
      {
        scaling: scale,
        dropdownZoomingValue,
      },
      () => {
        if (
          value == "25%" ||
          value == "20%" ||
          value == "15%" ||
          value == "10%" ||
          value == "5%"
        ) {
          if ($(window).width() < 2045) {
            $(".invoice_pdf_new").addClass("invoice_pdf_new1");
          }
        } else {
          if ($(window).width() < 2045) {
            $(".invoice_pdf_new").removeClass("invoice_pdf_new1");
          }
        }
      }
    );
  };

  handleRightSidebar = () => {
    this.setState((prevState, props) => ({
      toggleRightSidebar: !prevState.toggleRightSidebar,
    }));
  };

  handleHorizontalArrow = () => {
    $(".invoice_pdf_new").addClass("invoice_carousel_pdf");

    $(".invoice_pdf_new").removeClass("full_screen_convas");

    if ($(window).width() > 1500) {
      this.setState({
        scaling: 7,
        dropdownZoomingValue: { label: "100%", value: "100%" },
      });
    } else if ($(window).width() <= 1500) {
      this.setState({
        scaling: 3.4,
        dropdownZoomingValue: { label: "40%", value: "40%" },
      });
    }
  };

  handleHorizontalCross = () => {
    $(".mm_invoice_div").addClass("over_auto_remove");
    $(".invoice_pdf_new").addClass("full_screen_convas");
    var vw = $(window).width();
    var nav = $(".navbar.fixed-top").height();
    var underNav = $(".order-tabs").height();
    var wh = $(window).height();
    var pdfDiv = wh - nav;
    var wh = wh - nav - underNav;

    $(".invoice_pdf_new").removeClass("invoice_carousel_pdf");
    $(".invoice_pdf_canvas.invoice_pdf_new").css("height", wh);

    if ($(window).width() > 1500) {
      this.setState({
        scaling: 7,
        dropdownZoomingValue: { label: "100%", value: "100%" },
      });
    } else if ($(window).width() <= 1500) {
      this.setState({
        scaling: 3.1,
        dropdownZoomingValue: { label: "35%", value: "35%" },
      });
    }
  };

  onLoadSuccessPage = () => {
    var vw = $(window).width();
    var vw = $(".side-attachments").width();
    var nav = $(".navbar.fixed-top").height();
    var underNav = $(".order-tabs").height();
    var wh = $(window).height();
    var h = wh - nav - 60;
    var rsb = wh - nav - underNav - 20;
    // var pdfDiv = wh - nav - underNav - 80
    var pdfDiv = wh - nav;
    var wh = wh - nav - underNav;
    $("#order--dynamic--height").css("height", h);
    $(".side-attachments,.side-attachments-2").css("height", rsb);
    // $('#maped_image').css('height', pdfDiv)
    $(".invoice_pdf_canvas.invoice_pdf_new").css("height", wh);
  };

  handlePDFRotate = () => {
    this.setState({ rotate: this.state.rotate + 90 });
  };

  onDocumentLoadSuccess = (data) => {
    let numPages = data.numPages;
    this.setState({ numPages, pageNumber: 1 }, () => {
      this.settPreviewArrows();
    });
  };

  settPreviewArrows = () => {
    const {
      previewableAttachments,
      currentAttachmentIndex,
    } = this.state;
    const nextIndex = currentAttachmentIndex + 1;
    const previousIndex = currentAttachmentIndex - 1;
    if (previousIndex < 0) {
      $(".carousel-control-prev").hide();
      $(".carousel-control-next").show();
    } else if (nextIndex >= previewableAttachments.length) {
      $(".carousel-control-prev").show();
      $(".carousel-control-next").hide();
    } else {
      $(".carousel-control-prev").show();
      $(".carousel-control-next").show();
    }
  };

  goToPrevPage = () => {
    let { pageNumber } = this.state;
    if (pageNumber - 1 >= 1) {
      this.setState({ pageNumber: pageNumber - 1 });
    }
  };

  goToNextPage = () => {
    let { pageNumber, numPages } = this.state;
    if (pageNumber + 1 <= numPages) {
      this.setState({ pageNumber: pageNumber + 1 });
    }
  };

  onSlideChange = (action) => {
    const {
      previewableAttachments,
      currentAttachmentIndex,
      previews,
    } = this.state;
    if (previews.length === 0) {
      return;
    }
    let newAttachmentIndex = action === 'next' ? currentAttachmentIndex + 1 : currentAttachmentIndex - 1;
    if (newAttachmentIndex < 0 || newAttachmentIndex >= previewableAttachments.length) {
      // As these two can't happen, so we just ignore
      return;
    }
    const isPreviewLoaded = previews[newAttachmentIndex];
    if (previews.length === previewableAttachments.length || isPreviewLoaded !== undefined) {
      // if all the previewableAttachments or the requestetd one already loaded, don't fetch
      this.setState({ currentAttachmentIndex: newAttachmentIndex });
      return;
    }
    this.setState({
      isLoading: true,
      currentAttachmentIndex: -1
    });
    const attachmentRecordId = previewableAttachments[newAttachmentIndex]?.fileID;
    let newPreviews = [...previews];
    this.props.getAttachment(attachmentRecordId)
      .then(() => {
        const isSuccess = this.props.document.getDocAttachmentSuccess !== '' && this.props.document.getDocAttachmentError === '';
        const isError = this.props.document.getDocAttachmentError !== '' && !isSuccess;
        if (isSuccess) {
          toast.success(this.props.document.getDocAttachmentSuccess);
          const fetchedAttachment = this.props.document.getDocAttachment;
          newPreviews.push({
            file: fetchedAttachment.attachment,
            primaryDoc: fetchedAttachment.primaryDoc,
            documentPresignedURL: fetchedAttachment.documentPresignedURL,
          });
        } else if (isError) {
          handleAPIErr(this.props.document.getDocAttachmentError, this.props);
        } else {
          toast.warn('Loading attachment failed');
        }
        this.setState({
          previews: newPreviews,
        }, () => this.onNewAttachmentLoaded(newAttachmentIndex));
      })
      .catch((error) => {
        toast.error(error?.message || 'Unable to load attachment');
      })
      .finally(() => {
        this.setState({
          isLoading: false,
        });
      });
  };

  onNewAttachmentLoaded = (newAttachmentIndex) => {
    this.setState(
      { 
        currentAttachmentIndex: newAttachmentIndex,
        pageNumber: 1, rotate: 0
      },
      () => this.settPreviewArrows()
    );
  }

   handleTransferDocuments = async (newUser) => {
    this.setState({ isLoading: true });
    
     let params = {};

    if (this.state.allSelected) {
      params = {
        newUser,
        selectAll: "Y",
        docState: this.state.showDocTallisTabPane,
        search: this.state.docListSearch,
        teamDocs: this.state.viewTeam,
      };

    } else {
      params = {
        newUser,
        guid: this.state.multipleSelectedGuid.length > 0
          ? this.state.multipleSelectedGuid
          : [this.state.selectedGuid],
      };
    }

    await this.props.transferDocuments(params);

    if (this.props.document.transferDocumentSuccess) {
      toast.success('Successfully transferred documents');
    } else if (this.props.document.transferDocumentError) {
      toast.error(this.props.document.transferDocumentError);
    } else {
      toast.error("Failed to transfer documents");
    }
    this.setState({ isLoading: false });
  }


  refreshTalliesAndList = async () => {
    console.log(this.state.guid);
    this.setState({refreshedGuid: this.state.guid, refreshedClicked:true})
    await this.getDocumentTallies('', true);
  }

  getClassName = () => {
    if(isSafari()){
      return "suppliers_list list__color-bottom-safari order-ul";
     } else {
      return "suppliers_list list__color-bottom order-ul";
     } 
  }

  render() {
    let usePageLoading = localStorage.getItem("usePageLoading") || "N";
    usePageLoading = usePageLoading.toLowerCase();
    let userType = localStorage.getItem("userType") || "";
    userType = userType.toLowerCase();
    let tab =
      this.state.showDocTallisTabPane &&
      this.state.showDocTallisTabPane.toLowerCase();

    let { getDocList, activeDoc } = this.state;
    let approverNextPrevCheck = false;
    if (tab !== "draft" && tab !== "approved") {
      if (
        (tab === "pending" || tab === "approve") &&
        (userType === "admin" || userType === "sysadmin")
      ) {
        approverNextPrevCheck = true;
      }
    }
   
    return (
      <>
        {this.state.isLoading ? <div className="se-pre-con"></div> : ""}

        <div className="dashboard">
          {/* top nav bar */}
          <Header
            props={this.props}
            documents={true}
            toggleTeamIcon={this.toggleTeamIcon}
            viewTeam={this.state.viewTeam}
          />
          {/* end */}

          {/* body part */}

          <div className="dashboard_body_content dash__invoice--content">
            {/* top Nav menu*/}
            <TopNav />
            {/* end */}

            {/* side menu suppliers*/}
            <aside
              className="side-nav suppliers_side_nav side__content--invoice"
              id="show-side-navigation1"
            >
              <div className="cus-arro-div2">
                <img
                  src="images/arrow-r.png"
                  className=" img-fluid cus-arro-r"
                  alt="user"
                />
              </div>
              <div className="search">
                <div className="controller-row">
                  <div className="controller-1">  <div className="form-group remember_check custom-checkbox-ui">
                    <input
                      type="checkbox"
                      id={"order"}
                      name="checkboxAll"
                      checked={this.state.allSelected}
                      onChange={(e) => this.handleCheckbox(e, "allCheck")}
                    />
                    <label
                      htmlFor={"order"}
                      className="mr-0 custom-box"
                    ></label>
                  </div></div>
                  <div className="controller-2">   <Dropdown
                    alignRight={false}
                    drop="down"
                    className="analysis-card-dropdwn custom-my-radio user_drop_options"
                  >
                    <Dropdown.Toggle
                      variant="sucess"
                      id="dropdown-basic"
                      className="custom-angle-down"
                    >
                      <img src="images/angle-down.png" alt="arrow" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        to="#/action-2"
                        className="f-20 flex-container-inner"
                        onClick={() =>
                          this.handleSortDocumentsList("docType")
                        }
                      >
                        <div

                          className="custom-control custom-radio flex-container-inner"
                        >
                          <input
                            type="radio"
                            className="custom-control-input flex-container-inner-input"
                            id="type"
                            name="type"
                            onChange={() => {}}
                            checked={this.state.sortFilterDoc === "docType"}
                          />
                          <label
                            className="custom-control-label flex-container-inner-input"
                            htmlFor="type"
                          >
                            Type
                          </label>
                          {
                            this.state.sortFilterDoc === "docType" ?
                              (
                                this.state.order === "Descend" ? <i className="fa fa-angle-up ml-2 mt-1"></i> : <i className="fa fa-angle-down ml-2 mt-1"></i>
                              ) : ("")
                          }
                        </div>
                      </Dropdown.Item>
                      <Dropdown.Item
                        to="#/action-2"
                        className="f-20 flex-container-inner"
                        onClick={() => this.handleSortDocumentsList("date")}
                      >
                        <div

                          className="custom-control custom-radio flex-container-inner"
                        >
                          <input
                            type="radio"
                            className="custom-control-input flex-container-inner-input"
                            id="date"
                            name="date"
                            onChange={() => {}}
                            checked={this.state.sortFilterDoc === "date"}
                          />
                          <label
                            className="custom-control-label flex-container-inner-input"
                            htmlFor="date"
                          >
                            Date
                          </label>
                          {
                            this.state.sortFilterDoc === "date" ?
                              (
                                this.state.order === "Descend" ? <i className="fa fa-angle-up ml-2 mt-1"></i> : <i className="fa fa-angle-down ml-2 mt-1"></i>
                              ) : ("")
                          }
                        </div>
                      </Dropdown.Item>

                      <Dropdown.Item
                        to="#/action-2"
                        className="f-20 flex-container-inner"
                        onClick={() =>
                          this.handleSortDocumentsList("description")
                        }
                      >
                        <div

                          className="custom-control custom-radio flex-container-inner"
                        >
                          <input
                            type="radio"
                            className="custom-control-input flex-container-inner-input"
                            id="description"
                            name="description"
                            onChange={() => {}}
                            checked={
                              this.state.sortFilterDoc === "description"
                            }
                          />
                          <label
                            className="custom-control-label flex-container-inner-input"
                            htmlFor="description"
                          >
                            Description
                          </label>
                          {
                            this.state.sortFilterDoc === "description" ?
                              (
                                this.state.order === "Descend" ? <i className="fa fa-angle-up ml-2 mt-1"></i> : <i className="fa fa-angle-down ml-2 mt-1"></i>
                              ) : ("")
                          }
                        </div>
                      </Dropdown.Item>
                      <Dropdown.Item
                        to="#/action-2"
                        className="f-20 flex-container-inner"
                        onClick={() => this.handleSortDocumentsList("tran")}
                      >
                        <div

                          className="custom-control custom-radio flex-container-inner"
                        >
                          <input
                            type="radio"
                            className="custom-control-input flex-container-inner-input"
                            id="tran"
                            name="tran"
                            onChange={() => {}}
                            checked={this.state.sortFilterDoc === "tran"}
                          />
                          <label
                            className="custom-control-label flex-container-inner-input"
                            htmlFor="tran"
                          >
                            Transaction
                          </label>
                          {
                            this.state.sortFilterDoc === "tran" ?
                              (
                                this.state.order === "Descend" ? <i className="fa fa-angle-up ml-2 mt-1"></i> : <i className="fa fa-angle-down ml-2 mt-1"></i>
                              ) : ("")
                          }
                        </div>
                      </Dropdown.Item>
                      {usePageLoading === "y" ? (
                        <div className="custom-control custom-radio cutm-pr-right">
                          <div className="settings_display_row flex__wrapper-inner">
                            <label className="labelwrapper__iner lable-inner-wrapper">
                              Display Rows Per Page
                            </label>
                            <input
                              className="form-control input__wrapper--inner "
                              type="number"
                              min="1"
                              defaultValue={this.state.display}
                              onBlur={this.handleRowsSetting}
                            />
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </Dropdown.Menu>
                  </Dropdown>{" "}</div>
                  <div className="controller-3">
                    <div className="input-group-prepend">
                      <span className="input-group-text width-45" id="basic-addon1">
                        <img
                          src="images/search-icon.png"
                          className="mx-auto"
                          alt="search-icon"
                        />
                      </span>
                    </div>
                    <input
                      type="text"
                      className="form-control height-38"
                      placeholder="What are you looking for"
                      aria-label="What are you looking for"
                      aria-describedby="basic-addon1"
                      name="docListSearch"
                      value={this.state.docListSearch}
                      onChange={(e) =>
                        usePageLoading === "y"
                          ? this.handleChangeNewDocListSearch(e)
                          : this.handleChangeDocListSearch(e)
                      }
                      onKeyDown={(e) =>
                        usePageLoading === "y"
                          ? this.onNewDocListSearch(e)
                          : this.onDocListSearch(e)
                      }
                    />
                  </div>
                  <div className="controller-4">
                    <img
                      src="images/refresh.png"
                      className="cursorPointer"
                      alt="search-icon "
                      onClick={()=> this.refreshTalliesAndList()}
                    />
                  </div>
                </div>
              </div>
              <ul
                className={
                  Number(this.state.totalPages) !== 0
                    ? usePageLoading === "y"
                      ? this.getClassName()
                      : "suppliers_list"
                    : "suppliers_list"
                }
              >
                {getDocList.map((l, i) => {
                  const formattedDate = moment(l.docDateUnix).format('DD MMM YYYY').toUpperCase();;

                  return (
                    <li
                      key={i}
                      className={
                        l.teamDocument === "Y"
                          ? getDocList[i + 1] &&
                            getDocList[i + 1].teamDocument &&
                            getDocList[i + 1].teamDocument === "Y"
                            ? "teamOrdersBg teamOrdersBorder2 cursorPointer"
                            : "teamOrdersBg teamOrdersBorder cursorPointer"
                          : activeDoc === l.id
                          ? "active cursorPointer"
                          : "cursorPointer"
                      }
                      id={l.id}
                      onClick={()=>this.setState({selectedGuid: l.guid})}
                    >
                      <div className="row">
                        <div className="col-auto mb-2 pr-0">
                          <div className="form-group remember_check">
                            <input
                              type="checkbox"
                              id={"doc" + i}
                              checked={l.checked}
                              name="checkbox"
                              onChange={(e) => this.handleCheckbox(e, l)}
                            />
                            <label htmlFor={"doc" + i} className="mr-0"></label>
                          </div>
                        </div>

                        <div
                          onClick={() => this.getDocument(l)}
                          className="col pl-0"
                        >
                          <div className="invioce_data pr-sm-3">
                            <div className="row">
                              <div className="col data-i">
                                <p> Type:</p>
                              </div>
                              <div className="col-auto data-i">
                                <p>{l.docType || ""}</p>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col data-i">
                                <p>Date:</p>
                              </div>
                              <div className="col-auto data-i">
                                <p>
                                  {formattedDate}
                                </p>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col ">
                                <p className="mb-0">Description:</p>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col">
                                <p> {l.description}</p>
                              </div>
                              <div className="col-auto data-i">
                                <div className="text-center cursorPointer">
                                  <p onClick={() => this.handleDocDetails(l)}>
                                    <Link className="more-details-color" to="#">
                                      more details
                                    </Link>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  );
                })}
                );
              </ul>
              {Number(this.state.totalPages) !== 0 ? (
                usePageLoading === "y" ? (
                  <div className="pagination__list-custom">
                    <p className="mb__zero pt-top-container">
                      Showing {this.state.pageStart} to {this.state.pageEnd} of{" "}
                      {this.state.totalDocuments} entries
                    </p>
                    <ReactPaginate
                      tabIndex="-1"
                      previousLabel={"prev"}
                      pageLinkClassName={"pagination__list-custom-link   "}
                      pageClassName={"pagination__list-custom-li "}
                      activeLinkClassName={"pagination__tab-custm"}
                      nextLabel={"next"}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={this.state.totalPages}
                      marginPagesDisplayed={1}
                      pageRangeDisplayed={2}
                      onPageChange={this.handlePageClick}
                      containerClassName={
                        "pagination cursorPointer align-center-container"
                      }
                      activeClassName={"active"}
                      forcePage={this.state.page - 1}
                    />
                  </div>
                ) : (
                  ""
                )
              ) : (
                ""
              )}
            </aside>
            {/* {/ end /} */}

            <section id="contents" className="supplier pr-0 pt-0">
              <div className="body_content ordermain-padi body__invoice--top">
                <div className="container-fluid pl-0 ">
                  <div className="main_wrapper ">
                    <div className="row d-flex pl-15">
                      <div className="col-12 w-100 order-tabs p-md-0">
                        {/* Documents Tallies */}

                        <div className="nav_tav_ul">
                          <ul className="nav nav-tabs">
                            {this.state.documentsTallies.map((t, i) => {
                              return (
                                <li
                                  key={i}
                                  className="cursorPointer nav-item"
                                  onClick={() =>
                                    this.getDocumentTallies(t.docState, true)
                                  }
                                >
                                  <a
                                    className={
                                      this.state.activeDocTallis === t.id
                                        ? "nav-link active"
                                        : "nav-link"
                                    }
                                  >
                                    {t.docState}{" "}
                                    <span className="stats">{t.tally}</span>
                                  </a>
                                </li>
                              );
                            })}
                          </ul>
                        </div>

                        <div className="bg-gry mm_top_nav">
                          <div className="w-100 float-left mm_lr_pad ">
                            <div className="mm_tab_left invoice_left">
                              <div className="tab-content">
                                {this.state.showDocTallisTabPane &&
                                  this.state.showDocTallisTabPane.toLowerCase() ==
                                    "draft" && (
                                    <div className="tab-pane container active">
                                      <ul>
                                        <li
                                          onClick={this.addDocument}
                                          className="cursorPointer"
                                        >
                                          <img
                                            src="images/add.png"
                                            className=" img-fluid "
                                            alt="user"
                                          />{" "}
                                          <Link to="#">New</Link>{" "}
                                        </li>
                                        <li
                                          onClick={() =>
                                            this.state.getDocList.length > 0
                                              ? this.updateDocument()
                                              : () => {}
                                          }
                                          className="cursorPointer"
                                        >
                                          <img
                                            src="images/pencill.png"
                                            className=" img-fluid "
                                            alt="user"
                                          />{" "}
                                          <Link to="#">Edit</Link>{" "}
                                        </li>
                                        <li
                                          onClick={() =>
                                            this.state.getDocList.length > 0
                                              ? this.openModal(
                                                  "openDeleteModal"
                                                )
                                              : () => {}
                                          }
                                          className="cursorPointer"
                                        >
                                          <img
                                            src="images/delete.svg"
                                            className="invoice-delete-icon img-fluid "
                                            alt="user"
                                          />{" "}
                                          <Link to="#">Delete </Link>
                                        </li>
                                        <li
                                          className="cursorPointer"
                                          onClick={() =>
                                            this.state.getDocList.length > 0
                                              ? this.sendDocForApproval()
                                              : () => {}
                                          }
                                        >
                                          <img
                                            src="images/send.png"
                                            className=" img-fluid "
                                            alt="user"
                                          />{" "}
                                          <Link to="#"> Send </Link>
                                        </li>
                                      </ul>
                                    </div>
                                  )}
                                {this.state.showDocTallisTabPane &&
                                  this.state.showDocTallisTabPane.toLowerCase() ==
                                    "approve" && (
                                    <div
                                      className={
                                        this.state.getDocList.length === 0
                                          ? "tab-pane container"
                                          : "tab-pane container active"
                                      }
                                    >
                                      <ul>
                                        <li
                                          onClick={this.approveDocument}
                                          className="cursorPointer"
                                        >
                                          <img
                                            src="images/tick.png"
                                            className=" img-fluid "
                                            alt="user"
                                          />{" "}
                                          <Link to="#"> Approve </Link>
                                        </li>
                                        <li
                                          onClick={this.holdDocument}
                                          className="cursorPointer"
                                        >
                                          <img
                                            src="images/move.png"
                                            className=" img-fluid"
                                            alt="user"
                                          />{" "}
                                          <Link to="#"> Hold </Link>
                                        </li>
                                        <li
                                          onClick={() =>
                                            this.openModal("openDeclineModal")
                                          }
                                          className="cursorPointer"
                                        >
                                          <img
                                            src="images/decline.png"
                                            className=" img-fluid "
                                            alt="user"
                                          />{" "}
                                          <Link to="#"> Decline </Link>
                                        </li>
                                        {/* <li className="cursorPointer">
                                          <img
                                            src="images/pencill.png"
                                            className=" img-fluid "
                                            alt="user"
                                          />{" "}
                                          <Link to="#"> Edit </Link>
                                        </li> */}
                                      </ul>
                                    </div>
                                  )}
                                {tab === "declined" && (
                                  <div
                                    className={
                                      this.state.getDocList.lenght === 0
                                        ? "tab-pane container"
                                        : "tab-pane container active d-flex"
                                    }
                                  >
                                    <ul>
                                      {((userType &&
                                        userType.toLowerCase() ===
                                          "operator") ||
                                        (userType &&
                                          userType.toLowerCase() ===
                                            "op/approver")) && (
                                        <li
                                          className="cursorPointer"
                                          onClick={this.moveDocument}
                                        >
                                          <img
                                            src="images/move.png"
                                            className=" img-fluid "
                                            alt="user"
                                          />{" "}
                                          <Link to="#"> Move </Link>
                                        </li>
                                      )}
                                    </ul>
                                    <ul>
                                      {((userType &&
                                        userType.toLowerCase() ===
                                          "approver") ||
                                        (userType &&
                                          userType.toLowerCase() ===
                                            "op/approver")) && (
                                        <li
                                          className="cursorPointer"
                                          onClick={this.approveDocument}
                                        >
                                          <img
                                            src="images/tick.png"
                                            className=" img-fluid "
                                            alt="user"
                                          />{" "}
                                          <Link to="#"> Approve </Link>
                                        </li>
                                      )}
                                    </ul>
                                  </div>
                                )}

                                {this.state.showDocTallisTabPane &&
                                  this.state.showDocTallisTabPane.toLowerCase() ==
                                    "hold" && (
                                    <div
                                      className={
                                        this.state.getDocList.length === 0
                                          ? "tab-pane container"
                                          : "tab-pane container active"
                                      }
                                    >
                                      <ul>
                                        <li
                                          onClick={this.approveDocument}
                                          className="cursorPointer"
                                        >
                                          <img
                                            src="images/tick.png"
                                            className=" img-fluid "
                                            alt="user"
                                          />{" "}
                                          <Link to="#"> Approve </Link>
                                        </li>
                                        <li
                                          onClick={() =>
                                            this.openModal("openDeclineModal")
                                          }
                                          className="cursorPointer"
                                        >
                                          <img
                                            src="images/decline.png"
                                            className=" img-fluid "
                                            alt="user"
                                          />{" "}
                                          <Link to="#"> Decline </Link>
                                        </li>
                                        {/* <li className="cursorPointer">
                                          {" "}
                                          <img
                                            src="images/pencill.png"
                                            className=" img-fluid "
                                            alt="user"
                                          />{" "}
                                          <Link to="#"> Edit </Link>
                                        </li> */}
                                      </ul>
                                    </div>
                                  )}
                                {this.state.showDocTallisTabPane &&
                                  this.state.showDocTallisTabPane.toLowerCase() ==
                                    "pending" && (
                                    <div
                                      className={
                                        this.state.getDocList.length === 0
                                          ? "tab-pane container"
                                          : "tab-pane container active"
                                      }
                                    >
                                      <ul>
                                        <li
                                          onClick={this.onClickMoveDocument}
                                          className="cursorPointer"
                                        >
                                          <img
                                            src="images/move.png"
                                            className=" img-fluid "
                                            alt="user"
                                          />{" "}
                                          <Link to="#"> Move </Link>
                                        </li>
                                      </ul>
                                    </div>
                                  )}
                                {this.state.showDocTallisTabPane &&
                                  this.state.showDocTallisTabPane.toLowerCase() ==
                                    "approved" && (
                                    <div
                                      className={
                                        this.state.getDocList.length === 0
                                          ? "tab-pane container"
                                          : "tab-pane container active"
                                      }
                                    >
                                      <ul></ul>
                                    </div>
                                  )}
                                {this.state.showDocTallisTabPane &&
                                  this.state.showDocTallisTabPane.toLowerCase() ==
                                    "all" && (
                                    <div
                                      className={
                                        this.state.getDocList.length === 0
                                          ? "tab-pane container"
                                          : "tab-pane container active"
                                      }
                                    >
                                      <ul></ul>
                                    </div>
                                  )}
                              </div>
                            </div>
                            <div className="mm_tab_center invoice_right">
                              <div className="slider-panel">
                                <Link to="#" className="zom-img">
                                  <img
                                    onClick={this.zoomOut}
                                    src="images/minus.png"
                                    className=" img-fluid float-left"
                                    alt="user"
                                  />{" "}
                                </Link>
                                <Link to="#" className="zom-img">
                                  <img
                                    onClick={this.zoomIn}
                                    src="images/add.png"
                                    className=" img-fluid float-left"
                                    alt="user"
                                  />{" "}
                                </Link>
                                <Select
                                  className="width-selector"
                                  value={this.state.dropdownZoomingValue}
                                  classNamePrefix="custon_select-selector-inner"
                                  options={options}
                                  onChange={this.handleDropdownZooming}
                                  theme={(theme) => ({
                                    ...theme,
                                    border: 0,
                                    borderRadius: 0,
                                    colors: {
                                      ...theme.colors,
                                      primary25: "#f2f2f2",
                                      primary: "#f2f2f2",
                                    },
                                  })}
                                />
                                <Link to="#" className="zom-img">
                                  <img
                                    onClick={this.handleHorizontalCross}
                                    src="images/fulls.png"
                                    className=" img-fluid float-left"
                                    alt="user"
                                    id="full_screen"
                                  />{" "}
                                </Link>
                                <Link to="#" className="zom-img">
                                  <img
                                    onClick={this.handleHorizontalArrow}
                                    src="images/twoarow.png"
                                    className=" img-fluid float-left"
                                    alt="user"
                                    id="expand"
                                  />{" "}
                                </Link>

                                <span
                                  onClick={this.handlePDFRotate}
                                  className="cursorPointer"
                                >
                                  <img
                                    src="images/undo.png"
                                    className=" img-fluid float-left undo-img"
                                    alt="user"
                                  />
                                </span>
                                <Link
                                  to="#"
                                  className="zom-img float-right ml-md-5 pl-2 pr-2 mr-0 more-d mt-0 icon_dots"
                                >
                                  <img
                                    src="images/more.png"
                                    className=" img-fluid"
                                    alt="user"
                                  />{" "}
                                </Link>
                                <Link
                                  to="#"
                                  className="zom-img float-right mt-0"
                                  onClick={this.moveToNextDOC}
                                >
                                  <img
                                    src="images/arow-r.png"
                                    className=" img-fluid lr-arrow-up"
                                    alt="user"
                                    data-slide="next"
                                  />{" "}
                                </Link>
                                <Link
                                  to="#"
                                  className="zom-img float-right mtop-1"
                                  onClick={this.moveToPrevDOC}
                                >
                                  <img
                                    src="images/arow-l.png"
                                    className=" img-fluid lr-arrow-up"
                                    alt="user"
                                    data-slide="prev"
                                  />{" "}
                                </Link>

                                <div className="documents_attatchments2">
                                  <div
                                    onClick={this.regenerateDocumentsSignature}
                                    className="main-sec-attach main-bg"
                                  >
                                    Regenerate
                                  </div>
                                  {
                                    this.state.showDocTallisTabPane === "Approved" ?
                                      <div
                                        onClick={() => this.resendEmail()}
                                        className="main-sec-attach main-bg"
                                      >
                                        Resend Approved Email
                                      </div>
                                      : null
                                  }
                                  {userType === 'admin' && <div
                                    onClick={() => this.setState({showTransferRecordsModal: true})}
                                    className="main-sec-attach main-bg"
                                  >
                                    Transfer Records
                                  </div>}
                                  <div className="main-sec-attach main-bg" onClick={this.downloadPreview}>
                                    Download Copy
                                    <img
                                      src="images/downlod.png"
                                      className=" img-fluid float-right fa"
                                      alt="user"
                                    />
                                  </div>

                                   <div
                                    onClick={this.downloadSelectedAttachments}
                                    className="main-sec-attach main-bg"
                                  >
                                    Download Attachments
                                    <img
                                      src="images/downlod.png"
                                      className=" img-fluid float-right fa"
                                      alt="user"
                                    />
                                  </div>

                                  <div className="main-sec-attach main-bg">
                                    <span
                                      className="invoice-inherit"
                                      data-toggle="collapse"
                                      data-target="#Approvals_invoice1"
                                    >
                                      <span className="fa fa-angle-down float-left mr-2 sideBarAccord1"></span>
                                      Approvals
                                    </span>
                                  </div>
                                  <div
                                    className="collapse"
                                    id="Approvals_invoice1"
                                  >
                                    {this.state.approvalsGroups.map((a, i) => {
                                      return (
                                        <div key={i} className="pl-2 mb-3">
                                          <div className="form-group remember_check d-flex">
                                            <div className="checkSide">
                                              <input
                                                type="checkbox"
                                                id={i + "doc"}
                                                name={a.approvalGroup}
                                                checked={a.checked}
                                                onChange={(e) =>
                                                  this.handleApprovalsFilters(
                                                    e,
                                                    a
                                                  )
                                                }
                                              />
                                              <label htmlFor={i + "doc"}>
                                                {" "}
                                              </label>
                                            </div>
                                            <span className="text-mar">
                                              {a.approvalGroup}{" "}
                                            </span>
                                          </div>
                                        </div>
                                      );
                                    })}
                                  </div>

                                  <div
                                    className="collapse"
                                    id="Changes_invoice"
                                  >
                                    {[].map((c, i) => {
                                      return (
                                        <div
                                          key={i}
                                          className="main-sec-attach1"
                                        >
                                          <p className="m-clr s-bold mb-0">
                                            {c.userName}
                                          </p>
                                          {c.description}
                                          <p className="gry-clr mb-0">
                                            {c.date} {c.time}
                                          </p>
                                        </div>
                                      );
                                    })}
                                  </div>

                                  <div className="main-sec-attach main-bg">
                                    <span
                                      className="fa fa-angle-down float-left mr-2 sideBarAccord"
                                      data-toggle="collapse"
                                      data-target="#Activity_invoice"
                                    ></span>
                                    <span
                                      onClick={() =>
                                        this.openModal("openActivityModal")
                                      }
                                      className="name_attached font-weight-bold"
                                    >
                                      Activity
                                      <span className="fa fa-angle-right"></span>
                                    </span>
                                  </div>
                                  <div
                                    className="collapse"
                                    id="Activity_invoice"
                                  >
                                    {this.state.docActivity.map((a, i) => {
                                      return (
                                        <div
                                          key={i}
                                          className="main-sec-attach1"
                                        >
                                          {a.description}
                                          <p className="gry-clr mb-0">
                                            {a.date} {a.time}
                                          </p>
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="img-section-t col-12">
                      <div
                        className={
                          this.state.toggleRightSidebar
                            ? " mm_ordr1 order_pdf_new invo_margin"
                            : " mm_ordr1 order_pdf_expand1 m_auto"
                        }
                        id="overload_image--invoice"
                      >
                        <div
                          id="maped_image"
                          className="order_pfd over_auto_remove"
                          style={{ background: "#fff" }}
                        >
                          {this.state.getDocList.length > 0 && (
                            <div
                              id="demo"
                              className={
                                this.state.toggleRightSidebar
                                  ? " carousel slide invoice_carousel mm_invoice_div over_auto_remove"
                                  : " carousel slide invoice_carousel "
                              }
                              // data-ride="carousel"
                              data-interval={false}
                            >
                              <ul className="carousel-indicators">
                                <li
                                  data-target="#demo"
                                  data-slide-to="0"
                                  className="active"
                                ></li>
                                <li data-target="#demo" data-slide-to="1"></li>
                                <li data-target="#demo" data-slide-to="2"></li>
                              </ul>
                              <div className="carousel-inner">
                                {this.state.previews.length > 0
                                  ? this.state.previews.map((p, i) => {
                                    const currentIndex = this.state.currentAttachmentIndex;
                                    const currentFile = this.state.previews[currentIndex]?.file;
                                      return (
                                        <div
                                          className={
                                            i === 0
                                              ? "carousel-item active "
                                              : "carousel-item "
                                          }
                                          id={i}
                                          key={i}
                                        >
                                          <div className="text-center">
                                            <div className="invoice_pdf_canvas invoice_pdf_new pdf--buttons pdf__height--content doc-main-page">
                                              {currentFile ? (
                                                <>
                                                  <Document
                                                    file={
                                                      "data:application/pdf;base64," +
                                                      currentFile
                                                    }
                                                    onLoadSuccess={(data) =>
                                                      this.onDocumentLoadSuccess(
                                                        data,
                                                      )
                                                    }
                                                    rotate={this.state.rotate}
                                                  >
                                                    <Page
                                                      pageNumber={
                                                        this.state.pageNumber
                                                      }
                                                      scale={this.state.scaling}
                                                      height={372}
                                                      onLoadSuccess={
                                                        this.onLoadSuccessPage
                                                      }
                                                    />
                                                  </Document>
                                                  <div className="page-controls">
                                                    <button
                                                      type="button"
                                                      disabled=""
                                                      onClick={
                                                        this.goToPrevPage
                                                      }
                                                    >
                                                      ‹
                                                    </button>
                                                    <span>
                                                      {this.state.pageNumber} of{" "}
                                                      {this.state.numPages}
                                                    </span>
                                                    <button
                                                      type="button"
                                                      onClick={
                                                        this.goToNextPage
                                                      }
                                                    >
                                                      ›
                                                    </button>
                                                  </div>
                                                </>
                                              ) : (
                                                ""
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    })
                                  : "No Preview Found"}
                                {this.state.previewableAttachments.length > 1 && (
                                  <>
                                    <a
                                      className="carousel-control-prev"
                                      href="#demo"
                                      data-slide="prev"
                                      onClick={() => this.onSlideChange('prev')}
                                    >
                                      <i>
                                        {" "}
                                        <span className="carousel-control-prev-icon"></span>
                                      </i>
                                    </a>
                                    <a
                                      className="carousel-control-next"
                                      href="#demo"
                                      data-slide="next"
                                      onClick={() => this.onSlideChange('next')}
                                    >
                                      <i>
                                        {" "}
                                        <span className="carousel-control-next-icon"></span>
                                      </i>
                                    </a>
                                  </>
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="documents_attatchments1 aside__right--height">
                        <div
                          className="cus-arro-div"
                          onClick={this.handleRightSidebar}
                        >
                          <img
                            src="images/arrow-r.png"
                            className=" img-fluid cus-arro-r"
                            alt="user"
                          />
                        </div>
                        <div className="side-attack">
                          <div className="main-sec-attach main-bg">
                            {/*Docmuents Attachments */}
                            <span
                              className="fa fa-angle-up float-left mr-2 sideBarAccord"
                              data-toggle="collapse"
                              data-target="#Attachments_invoice"
                            ></span>
                            <span
                              className="name_attached"
                              onClick={() =>
                                this.openModal("openAttachmentsModal")
                              }
                            >
                              Attachments
                              <span className="ml-3 font-weight-bold">
                                {this.state.docAttachments.length}
                              </span>
                              <span className="fa fa-angle-right"></span>
                              <a class="float-right mr-3" href="#">
                                <img
                                  src="images/add.png"
                                  class=" img-fluid sidebarr_plus "
                                  alt="user"
                                />
                              </a>
                            </span>
                          </div>
                          <div
                            className="collapse show"
                            id="Attachments_invoice"
                          >
                            {this.state.docAttachments.map((a, i) => {
                              return (
                                <div
                                  key={i}
                                  className="main-sec-attach"
                                  onClick={() =>
                                    this.getAttachment(a.fileID, "", a.fileName)
                                  }
                                >
                                  {a.fileName}{" "}
                                  <span className="fa fa-angle-right"></span>
                                </div>
                              );
                            })}
                          </div>

                          {/* side menue Approvers / Approvals */}

                          <div className="main-sec-attach main-bg">
                            <span
                              className="invoice-inherit"
                              data-toggle="collapse"
                              data-target="#Approvals_invoice"
                            >
                              <span className="fa fa-angle-up float-left mr-2 sideBarAccord1"></span>
                              Approvals
                            </span>

                            {/* <span className="fa fa-angle-right"></span> */}
                          </div>
                          <div className="collapse show" id="Approvals_invoice">
                            {this.state.approverGroup.value &&
                              this.state.approverGroup.value.trim() && (
                                <div className="main-sec-mid">
                                  {approverNextPrevCheck && (
                                    <button
                                      type="button"
                                      className="btn-save1 add_comment_btns1"
                                      onClick={() =>
                                        this.openModal(
                                          "openCheckApprovalModal",
                                          "Prev"
                                        )
                                      }
                                    >
                                      <span></span>
                                      Previous
                                    </button>
                                  )}
                                  <span>{this.state.approverGroup.value}</span>
                                  {approverNextPrevCheck && (
                                    <button
                                      type="button"
                                      className="btn-save1 add_comment_btns"
                                      onClick={() =>
                                        this.openModal(
                                          "openCheckApprovalModal",
                                          "Next"
                                        )
                                      }
                                    >
                                      <span></span>
                                      Next
                                    </button>
                                  )}
                                </div>
                              )}
                            {this.state.approvers.map((a, i) => {
                              return (
                                <div
                                  key={i}
                                  className="main-sec-attach cus-check"
                                >
                                  <div className="form-group remember_check">
                                    {a.status === "Approved" ? (
                                      <input type="checkbox" id={i} checked />
                                    ) : (
                                      ""
                                    )}
                                    {a.status === "Declined" ? (
                                      <i
                                        className="fa fa-times circle-check1 float-left ml-1"
                                        aria-hidden="true"
                                      ></i>
                                    ) : a.status === "Current" ? (
                                      <i
                                        className="fa fa-circle-thin circle-check float-left ml-1"
                                        aria-hidden="true"
                                        style={{ marginTop: "3px" }}
                                      ></i>
                                    ) : (
                                      ""
                                    )}
                                    <label htmlFor={i}>
                                      {" "}
                                      <span
                                        className={
                                          a.status === "Declined"
                                            ? "order-right-color2 selected mm_lcapp"
                                            : a.status === "Current"
                                            ? "order-right-color1 selected mm_lcapp"
                                            : "text-mar"
                                        }
                                      >
                                        {a.approverName}
                                      </span>
                                      {a.status === "Declined" ? (
                                        <span className="current-approver mm_approre1">
                                          {" "}
                                          (user declined)
                                        </span>
                                      ) : a.status === "Current" ? (
                                        <span className="current-approver mm_approre1">
                                          {" "}
                                          (current approver)
                                          {userType === "admin" && (
                                            <button
                                              onClick={() => this.resendEmail()}
                                              className="btn-save2"
                                            >
                                              Send Email
                                            </button>
                                          )}
                                        </span>
                                      ) : (
                                        ""
                                      )}
                                    </label>
                                  </div>
                                </div>
                              );
                            })}
                          </div>

                          {/* Invoice Comments */}
                          <div className="main-sec-attach main-bg">
                            <span
                              className="fa fa-angle-up float-left mr-2 sideBarAccord"
                              data-toggle="collapse"
                              data-target="#Comments_invoice"
                            ></span>
                            <span
                              className="name_attached"
                              onClick={() =>
                                this.openModal("openCommentsModal")
                              }
                            >
                              Comments
                              <span className="ml-3 font-weight-bold">
                                {this.state.docComments.length}
                              </span>
                              <span className="fa fa-angle-right"></span>
                              {this.state.showDocTallisTabPane === "Draft" ||
                              this.state.showDocTallisTabPane === "Pending" ||
                              this.state.showDocTallisTabPane === "Approve" ||
                              this.state.showDocTallisTabPane === "Hold" ||
                              this.state.showDocTallisTabPane === "Declined" ? (
                                <a className="float-right mr-3" href="#">
                                  <img
                                    src="images/add.png"
                                    className=" img-fluid sidebarr_plus "
                                    alt="user"
                                  />
                                </a>
                              ) : null}
                            </span>
                          </div>
                          <div className="collapse show" id="Comments_invoice">
                            {this.state.docComments.map((c, i) => {
                              return (
                                <div key={i} className="main-sec-attach1">
                                  <p className="m-clr s-bold mb-0">
                                    {c.userName}
                                  </p>
                                  {c.comment}
                                  <p className="gry-clr mb-0">
                                    {c.date} {c.time}
                                  </p>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
          {/* end */}
        </div>

        <ConfirmationModal
          openConfirmationModal={this.state.isModalOpen}
          closeModal={this.closeConfrimationModal}
          title={this.state.modalContent.title}
          body={this.state.modalContent.body}
          actionButtonName={this.state.modalContent.actionButtonName}
          onConfirmation={this.state.modalContent.onConfirm}
        />

        <Decline
          openDeclineModal={this.state.openDeclineModal}
          closeModal={this.closeModal}
          onDecline={this.declineDocument}
        />

        <Attachments
          openAttachmentsModal={this.state.openAttachmentsModal}
          closeModal={this.closeModal}
          addAttachment={this.addAttachment}
          attachments={this.state.docAttachments}
          attachmentSize={this.state.attachmentSize}
          getAttachment={this.getAttachment}
          draft={
            this.state.showDocTallisTabPane &&
            this.state.showDocTallisTabPane.toLowerCase() === "draft"
              ? true
              : false
          } //to hide/show "Drag Files in or Click to Upload" box
        />

        <Delete
          openDeleteModal={this.state.openDeleteModal}
          closeModal={this.closeModal}
          onDelete={this.deleteDocument}
        />

        <Comments
          openCommentsModal={this.state.openCommentsModal}
          closeModal={this.closeModal}
          comments={this.state.docComments}
          addComment={this.addComment}
          tab={
            this.state.showDocTallisTabPane &&
            this.state.showDocTallisTabPane.toLowerCase()
          }
        />

        <Activity
          openActivityModal={this.state.openActivityModal}
          closeModal={this.closeModal}
          activity={this.state.docActivity}
        />
        <DocumentMoreDetails
          state={this.state}
          openModal={this.openModal}
          closeModal={this.closeModal}
          openDocumentMoreModal={this.state.openDocumentMoreModal}
          showDocTallisTabPane={this.state.showDocTallisTabPane}
          updateDocumentSummaryState={this.updateDocumentSummaryState}
          getDocument={this.getDocument}
        />
        <CheckApproval
          openCheckApprovalModal={this.state.openCheckApprovalModal}
          closeModal={this.closeModal}
          state={this.state}
          moveToPrevApprover={this.moveToPrevApprover}
          moveToNextApprover={this.moveToNextApprover}
        />
           {
          userType === 'admin' && this.state.showTransferRecordsModal && (
            <TransferRecords
              closeModal={() => this.setState({ showTransferRecordsModal: false })}
              selectedItemCount={this.state.allSelected ? undefined : this.state.multipleSelectedGuid.length || 1}
              itemNameList={this.state.allSelected ? undefined :
                [...new Set(this.state.multipleSelectedGuid.length ? this.state.multipleSelectedGuid : [...this.state.multipleSelectedGuid, this.state.selectedGuid])].map(guid => {
                const doc = this.state.getDocList.find(doc => doc.guid === guid);
                return `DOC#${doc.guid}`
              })}
              onSubmit={this.handleTransferDocuments}
            />
          )
        }
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  document: state.document,
  pageData: state.pagination.pageData["documents"],
});

export default connect(mapStateToProps, {
  getDocumentTallies: DocumentActions.getDocumentTallies,
  getDocumentsList: DocumentActions.getDocumentsList,
  getNewDocumentsList: DocumentActions.getNewDocumentsList,
  getDocument: DocumentActions.getDocument,
  getDocumentSummary: DocumentActions.getDocumentSummary,
  deleteDocument: DocumentActions.deleteDocument,
  sendDocForApproval: DocumentActions.sendDocForApproval,
  addDocAttachments: DocumentActions.addDocAttachments,
  getAttachment: DocumentActions.getAttachment,
  addComment: DocumentActions.addComment,
  approveDocument: DocumentActions.approveDocument,
  declineDocument: DocumentActions.declineDocument,
  holdDocument: DocumentActions.holdDocument,
  moveDocument: DocumentActions.moveDocument,
  regenerateDocumentsSignature: DocumentActions.regenerateDocumentsSignature,
  clearDocumentStates: DocumentActions.clearDocumentStates,
  clearStatesAfterLogout,
  moveToNextApprover: DocumentActions.moveToNextApprover,
  moveToPrevApprover: DocumentActions.moveToPrevApprover,
  setLastPage: PaginationActions.setLastPageNumber,
  resetLastPage: PaginationActions.resetLastPage,
  downloadAttachments: DocumentActions.downloadDocAttachments,
  transferDocuments: DocumentActions.transferDocuments,
  resendEmail: DocumentActions.resendEmail
})(Documents);
